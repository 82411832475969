/**
 * 定数：ラベル名
 */
export const LabelName = Object.freeze({
  VehicleDispatch: "配車日",
  LoadingSchedule: "積込予定日",
  UnloadingSchedule: "納品予定日"
});

/**
 * 定数：省略記号、アイコンなど、既定表示内容
 */
export const FixedDisplay = Object.freeze({
  Abbreviation: "...",
  NewLine: "\n"
});

/**
 * 定数：閾値
 */
export const LimitNumber = Object.freeze({
  LengthNumber: 200,
  ReceiptMaxLength: 5,
  ScrollNumber: 4
});
