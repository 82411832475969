import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  status: "",
  loginCd: "",
  user: {}
};

const getters = {
  isLoggedIn: state => !!state.loginCd,
  authStatus: state => state.status
};

const actions = {
  // ログイン処理
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request", user);
      const params = new URLSearchParams();
      params.append("loginCd", user.loginCd);
      params.append("password", user.password);
      $axios
        .post(axiosBase.getUrls().loginAuth, params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          commit("auth_success", user.loginCd, user.password);
          resolve(response);
        })
        .catch(error => {
          commit("auth_error");
          reject(error);
        });
    });
  },
  // ログアウト処理
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      $axios
        .get(axiosBase.getUrls().logout)
        .then(response => {
          commit("logout");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // TODO:パスワードリマインダー
  reminder({ commit }, mail) {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      params.append("loginCd", "ssp");
      params.append("password", "password");
      $axios
        .post(axiosBase.getUrls().loginAuth, params, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, loginCd, user) {
    state.status = "success";
    state.loginCd = loginCd;
    state.user = user;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.loginCd = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
