<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-tabs(
            color="primary darken-2"
            dark slider-color="yellow darken-2"
            v-model="tabIndex")
            v-tab(ripple) 一覧
            v-tab(ripple) 個人月別カレンダー
            v-tab-item
              DriverScheduleList(ref="DriverScheduleList" :postUrl="$config.urls.postEditDriverSchedule" ,
              :getListActionName="'driver/fetchScheduleList'")
            v-tab-item
              DriverMonthlySchedule(ref="DriverMonthlySchedule" :postUrl="$config.urls.postEditDriverScheduleList")
</template>

<script>
import DriverMonthlySchedule from "@/components/management/driver/schedule/monthly-calendar/MonthlyDriverSchedule";
import DriverScheduleList from "@/components/management/driver/schedule/driver-list/DriverScheduleList";

export default {
  components: {
    DriverMonthlySchedule,
    DriverScheduleList
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  watch: {
    tabIndex() {
      if (this.tabIndex === 0) this.$refs.DriverScheduleList.loadData();
      if (this.tabIndex === 1) this.$refs.DriverMonthlySchedule.loadListData();
    }
  }
};
</script>
