<template lang="pug">
  table.paging-table(class="sticky_table")
    thead
      tr
        th(v-if="getTableDataList.length") Excel行
        th(v-if="getTableDataList.length")
        th 1次配送委託先CD
        th 1次配送委託先
        th 適用開始日
        th 支払タリフCD
        th 支払タリフ名
        th 支払タリフ区分ID
        th 支払タリフ区分名
        th 状態
        th 備考

    tbody
      tr(v-if="getTableDataList.length" v-for="item in getTableDataList"
        :active="selected" :class="{'record-disabled':isValidError(item.validationError)}")

        td.text-xs-center {{ item.lineNo }}
        td.text-xs-center
          ValidationErrorTips(:validation-error="item.validationError")
        td.text-xs-left {{ item.primaryDeliveryCompanyCd }}
        td.text-xs-left {{ item.primaryDeliveryCompanyName }}
        td.text-xs-center(width="130px") {{ item.applicableStartDateInput }}
        td.text-xs-left {{ item.paymentTariffCd }}
        td.text-xs-left {{ item.paymentTariffName }}
        td.text-xs-right {{ item.paymentTariffTypeIdInput }}
        td.text-xs-left {{ item.paymentTariffTypeName }}

        td.text-xs-left {{ item.statusInput }}
        td.text-xs-left
          div
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                p(v-on="on").remark.whitespace-pre {{ item.remark }}
              span.whitespace-pre {{ item.remark }}

      tr(v-if="!getTableDataList.length")
        td(:colspan="11") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import EventDispatcher from "@/mixin/EventDispatcher";
import InfiniteScrollHandler from "@/mixin/InfiniteScrollHandler";
import ValidationErrorTips from "@/components/common/ValidationErrorTips";
import * as Enum from "@/assets/js/enum";
import { mapGetters } from "vuex";
import AxiosBase from "@/assets/js/axios-base";

let axiosBase = new AxiosBase();

export default {
  components: {
    ValidationErrorTips
  },
  mixins: [EventDispatcher, InfiniteScrollHandler],
  props: {
    isFilterError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
      dialog: false,
      fetchActionParams: {
        url: axiosBase.getUrls().getPaymentTariffRuleWorkList
      }
    };
  },
  computed: {
    ...mapGetters("site", {
      isValidError: "isValidError"
    }),
    ...mapGetters("financeImport", {
      getTableDataList: "workList",
      workVehicleList: "workVehicleList"
    }),
    tableHeader: {
      get() {
        return [
          { text: "行番号", value: "lineNo", centerAlign: false },
          { text: "タリフCD", value: "tariffCd", centerAlign: false },
          { text: "タリフ名", value: "tariffName", centerAlign: false },
          { text: "タリフ略称", value: "tariffShortName", centerAlign: false },
          { text: "適用開始日", value: "applicableStartDateMin", centerAlign: false },
          { text: "距離", value: "distance", centerAlign: false },
          { text: "状態", value: "status", centerAlign: false },
          { text: "備考", value: "remark", centerAlign: false }
        ];
      }
    }
  },
  methods: {
    isDisabled(item) {
      if (item.status === undefined) return false;
      return item.status === Enum.ActiveFlag.InActive.code;
    },
    loadUploadData() {
      this.resetPageCount();
      this.loadData();
    }
  }
};
</script>

<style lang="stylus" scoped>

table.paging-table {
  tbody tr:hover {
    td, th {
      background-color: #f5f5f5;
      transition: 0.2s;
    }
  }

  th, td {
    border-right 1px solid #E0E0E0;
    height: 48px;
  }

  th:last-child,
  td:last-child {
    border-right none;
  }
}

.sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 1;
}

table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
}


td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.th-text {
  margin-top: 20px;
  font-size: 13px;
}

.v-text-field {
  padding-top: 8px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 14px;
  font-weight: 400;
}
</style>
