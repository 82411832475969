<template lang="pug">
  v-select(
    v-on:change="change"
    v-model="p_code"
    :items="masterList"
    item-text="billingTypeName"
    item-value="id"
    :readonly="readonly"
    label="請求区分"
    :box="readonly"
    :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")
</template>

<script>
export default {
  props: {
    // TODO:type:Number 必須でありながらNullがはいる可能性があるため回避。（※初期値）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default: () => null
    },
    errorHandlerMethodArg: {
      type: String,
      default() {
        return null;
      }
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    financeSetting: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "billingTypeId",
      billingTypes: []
    };
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return this.billingTypes;
      }
    },
    errorHandlerMethodArgComputed: {
      get() {
        if (this.errorHandlerMethodArg == null) {
          return this.defaultErrorHandlerMethodArg;
        }
        return this.errorHandlerMethodArg;
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const self = this;
      await this.$store.dispatch("site/fetchAllBillingType").then(value => {
        self.billingTypes = value;
      });
    },
    change(value) {
      if (this.financeSetting) {
        this.$emit("changeFinance", value);
      }
      this.$emit("changeItem");
    }
  }
};
</script>
