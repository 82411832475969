<template lang="pug">
  v-menu(offset-x v-model="display" :position-x="x",:position-y="y")
    v-list(dense)
      v-list-tile(@click="setMark(Mark1)")
        v-list-tile-action
          v-icon() filter_1
        v-list-tile-content マーク1
      v-list-tile(@click="setMark(Mark2)")
        v-list-tile-action
          v-icon() filter_2
        v-list-tile-content マーク2
      v-list-tile(@click="setMark(Mark3)")
        v-list-tile-action
          v-icon() filter_3
        v-list-tile-content マーク3
      v-list-tile(@click="setMark(Mark4)")
        v-list-tile-action
          v-icon() filter_4
        v-list-tile-content マーク4
      v-divider
      v-list-tile(@click="setMark(MarkClear)")
        v-list-tile-action
          v-icon() clear
        v-list-tile-content クリア
</template>

<script>
import CardMarkTypeHandler from "@/mixin/CardMarkTypeHandler";
export default {
  mixins: [CardMarkTypeHandler],
  props: {
    isDynamic: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      display: false,
      x: 0,
      y: 0,
      item: {}
    };
  },
  computed: {
    cardMarkAction: {
      get() {
        if (this.isDynamic) {
          return "dynamic/saveCardMark";
        } else {
          return "dispatch/saveCardMark";
        }
      }
    }
  },
  methods: {
    open(data) {
      this.item = data.item;
      // TODO tickイベント実装？（既に開いていると別のが開かない。二回押下で開くので）
      this.display = false;
      this.x = data.event.clientX;
      this.y = data.event.clientY;
      this.display = true;
    },
    setMark(markCd) {
      let req = {
        orderId: this.item.orderId,
        orderType: this.item.orderType,
        markType: markCd
      };

      this.$store
        .dispatch(this.cardMarkAction, req)
        .then(() => {
          this.item.cardMark = markCd;
        })
        .catch(reason => {
          this.$emit("error", reason.message);
        });
    }
  }
};
</script>

<style lang="stylus"></style>
