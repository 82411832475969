import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

import qs from "qs";
import Sse from "sse.js";

const axiosBase = new AxiosBase();
const $axios = Axios.http;
const $download = Axios.download;

/** STATE */
const state = {
  list: [],
  serverMessage: null,
  csvFileSize: 0,
  totalCount: 0,
  currentCount: 0,
  loading: false,
  loadingMessage: "",
  searchCount: 0
};

/** GETTERS */
const getters = {
  /** GETTER：取得済みリスト（※スクロール状況によってデータが追加されていく） */
  getList: state => state.list,
  getLoadingProgress: state => state.loading,
  getTotalCount: state => state.totalCount,
  getCurrentCount: state => state.currentCount,
  getCsvFileSize: state => state.csvFileSize,
  getLoadingMessage: state => state.loadingMessage,
  getCsvSearchCount: state => state.searchCount
};

/** ACTIONS */
const actions = {
  /**
   * Download：伝票明細CSVファイルのダウンロードリクエスト
   * @param { state } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadSlipDetailCsv({ commit, state }, searchCondition) {
    return $axios
      .get(axiosBase.getUrls().getExportOrderCsvValidate, {
        params: searchCondition,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return this.dispatch("exportCsv/downloadSlipDetailCsvEvent", searchCondition);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },

  /**
   * GET：伝票明細CSVファイルの検索件数
   * @param { state } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadSlipDetailCsvCount({ commit, state }, searchCondition) {
    return $axios
      .get(axiosBase.getUrls().getExportOrderCsvCount, {
        params: searchCondition,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("writeCsvCount", value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },

  async downloadTest() {
    // const url = process.env.VUE_APP_API_URL + axiosBase.getUrls().getExportOrderCsvDownload;
    const url = process.env.VUE_APP_API_URL + "api/export/fuga";

    const source = new Sse.SSE(url, {
      withCredentials: true,
      payload: {
        cardId: 112233,
        driverId: 9786
      }
    });

    source.addEventListener("totalCount", function (e) {
      console.log("totalCount: " + e.data);
    });

    source.addEventListener("progress", function (e) {
      console.log("progress: " + e.data);
    });

    source.addEventListener("complete", function (e) {
      console.log("complete: " + e);
      source.close();
    });

    source.addEventListener("token", function (e) {
      //token << e.data
      source.close();
    });

    source.onerror = function (evt) {
      source.close();
    };

    source.stream();
  },

  /**
   * Download：伝票明細CSVファイルのダウンロードをSSEでリクエスト
   * @param { state } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadSlipDetailCsvEvent({ commit, state }, searchCondition) {
    const params = qs.stringify(searchCondition);
    const url = process.env.VUE_APP_API_URL + axiosBase.getUrls().getExportOrderCsvDownload;

    commit("clearDownloadData");
    commit("changeLoadingProgress", true);
    commit("setLoadingMessage", "ダウンロードファイルを要求しています");

    // this.$store.dispatch("exportCsv/testSse");
    const sse = new EventSource(url + "?" + params.toString(), {
      withCredentials: true
    });
    state.serverMessage = sse;

    sse.addEventListener("fileSize", ev => {
      commit("writeCsvFileSize", ev.data);
    });

    sse.addEventListener("totalCount", ev => {
      commit("writeTotalCount", ev.data);
      commit("setLoadingMessage", "ダウンロードファイルを準備しています");
    });

    sse.addEventListener("progress", ev => {
      commit("writeCurrentCount", ev.data);
    });

    sse.addEventListener("error", ev => {
      const event = new CustomEvent(window.Common.Config.observer.onErrorResponse400, {
        detail: {
          message: ev.data
        }
      });
      window.dispatchEvent(event);
    });

    sse.onerror = function (evt) {
      sse.close();
      commit("changeLoadingProgress", false);
    };

    sse.addEventListener("complete", ev => {
      sse.close();
      const condition = {
        fileName: ev.data,
        templateId: searchCondition.exportTemplateId
      };
      this.dispatch("exportCsv/downloadExportCsvFile", condition).finally(() => {
        commit("changeLoadingProgress", false);
        commit("setLoadingMessage", "ダウンロードファイル準備完了");
      });
    });
  },
  async downloadExportCsvFile({ state }, condition) {
    return await $download.get(
      axiosBase.getUrls().getExportCsvFileDownload + condition.fileName + "/" + condition.templateId
    );
  },
  /**
   * CSV件数結果の初期化
   *
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async resetCsvCount({ commit, state }) {
    commit("writeCsvCount", 0);
  }
};

/** MUTATIONS */
const mutations = {
  /**
   * COMMIT：リスト初期化
   * @param {*} state Store
   */
  clearList(state) {
    state.list = [];
  },
  /**
   * COMMIT：リスト追加
   * @param {*} state Store
   * @param {*} data 追加データ
   */
  writeList(state, data) {
    if (data != null) {
      state.list = state.list.concat(data.list);
    }
  },
  clearDownloadData(state) {
    state.totalCount = 0;
    state.currentCount = 0;
    state.csvFileSize = 0;
  },
  changeLoadingProgress(state, flag) {
    state.loading = flag;
  },
  writeCurrentCount(state, data) {
    state.currentCount = data;
  },
  writeTotalCount(state, data) {
    state.totalCount = data;
  },
  writeCsvFileSize(state, data) {
    state.csvFileSize = data;
  },
  setLoadingMessage(state, data) {
    state.loadingMessage = data;
  },
  writeCsvCount(state, data) {
    state.searchCount = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
