import * as Enum from "@/assets/js/enum";
export default {
  methods: {
    isUnsent(mailStatus) {
      return mailStatus === Enum.MailStatusType.Unsent.code;
    },
    isSent(mailStatus) {
      return mailStatus === Enum.MailStatusType.Sent.code;
    },
    isRead(mailStatus) {
      return mailStatus === Enum.MailStatusType.Read.code;
    },
    isConfirmed(mailStatus) {
      return mailStatus === Enum.MailStatusType.Confirm.code;
    },
    isProcessed(mailStatus) {
      return mailStatus === Enum.MailStatusType.Processed.code;
    },
    isSendFailed(mailStatus) {
      return mailStatus === Enum.MailStatusType.SendFailed.code;
    }
  }
};
