import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  deliveryCompanyList: []
};

const actions = {
  // 委託先の一覧を検索
  async fetchAllDeliveryCompany({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllDeliveryCompanyList, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllDeliveryCompanyData");
        }
        commit("appendDeliveryCompanyData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 委託先の一覧を検索(ダイアログ用)
  async fetchAllDeliveryCompanyForSelect({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllDeliveryCompanyListForSelect, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllDeliveryCompanyData");
        }
        commit("appendDeliveryCompanyData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },

  // 選択された委託先の情報を取得
  async fetchDeliveryCompany({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllDeliveryCompany + id).then(value => {
      return Promise.resolve(value.data.data);
    });
  },
  // 実績出力
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportDeliveryCompany, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllDeliveryCompanyData(state) {
    state.deliveryCompanyList = [];
  },
  appendDeliveryCompanyData(state, data) {
    if (data != null) {
      state.deliveryCompanyList = state.deliveryCompanyList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
