<template lang="pug">
  section.table-scroll
    table.rate-table
      tr(v-if="diffArrayData && diffArrayData.length > 0")
        th.column
          span 距離(Km)
        template(v-for="rec in diffArrayData[0].tariffColumns")
          th.column {{ rec.vehicleTypeName }}

      template(v-for="(row,indexRow) in diffArrayData")
        tr(:class="getHistoryDiffCssClass(row.changeStatus)")
          td(:class="getHistoryDiffCssClass(row.changeStatus)").distance {{row.distance | addComma}}
          template(v-for="(col, indexCol) in row.tariffColumns")
            td(:class="getHistoryDiffCssClass(col.changeStatus)").price
              DigitSeparatorsLabel(:value="col.price")
</template>

<script>
import HistoryDiffCss from "@/mixin/HistoryDiffCss";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";

export default {
  mixins: [HistoryDiffCss],
  components: {
    DigitSeparatorsLabel
  },
  props: {
    diffArrayData: {
      type: Array,
      required: false,
      default: () => null
    }
  }
};
</script>

<style scoped lang="stylus">
.rate-table {
  table-layout fixed
  width 100%

  .column{
    width 100px
  }
}
</style>
