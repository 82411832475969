<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      label="荷主"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :clearable="clearable"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="'client/fetchSelectGeneralClient'")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    filterSrc: {
      type: Boolean,
      default() {
        return false;
      }
    },
    clientIdList: {
      type: Array,
      required: false,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      config: {
        title: "荷主選択",
        headers: [
          {
            text: "荷主CD",
            key: "clientCd",
            align: ""
          },
          {
            text: "荷主名",
            key: "clientDisplayName",
            align: false
          }
        ]
      },
      initSearchCond: () => {
        return {
          clientCd: "",
          clientDisplayName: "",
          clientIdList: this.filterSrc ? this.clientIdList : []
        };
      }
    };
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      let isChanged = this.p_code !== data.id;
      this.p_code = data.id;
      this.p_label = data.clientDisplayName;
      if (isChanged) this.$emit("changeItem", data);
    }
  }
};
</script>
