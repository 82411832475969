import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import { ReportExtractor } from "@/plugins/report-extractor";

import qs from "qs";

const axiosBase = new AxiosBase();
const $axios = Axios.http;
const $download = Axios.download;

/** STATE */
const state = {
  list: [],
  capitalBlankList: [],
  statusMapList: {},
  isSelectedAll: false,
  isDeselectedAll: false
};

/** GETTERS */
const getters = {
  /** GETTER：取得済みリスト（※スクロール状況によってデータが追加されていく） */
  getList: state => state.list,
  /** GETTER：全選択状態フラグ */
  getIsSelectedAll: state => state.list.length != 0 && state.list.every(element => element.selectTarget),
  /** GETTER：全解除状態フラグ */
  getIsDeselectedAll: state => state.list.length == 0 || state.list.every(element => !element.selectTarget),
  /** GETTER：出力対象かつ署名未選択を含む */
  getIsDeselectedSignature: (state, getters) => {
    if (getters.getIsDeselectedAll) return false;
    var filteredList = state.list.filter(record => record.selectTarget && record.signatureId === 0);
    return filteredList.length !== 0;
  },
  /** GETTER：定型文など更新用情報のIDによる取得 */
  getMetaInfoById: state => id => {
    const info = state.list.find(element => element.id === id);
    if (info === void 0) return void 0;
    return ReportExtractor.formatMetaRequest(info);
  },
  getPrimaryDeliveryCompanyThatCapitalBlank: state => {
    return state.capitalBlankList;
  },
  isCapitalBlankDeliveryCompany: state => companyId => {
    return state.capitalBlankList.find(company => company.id === companyId) !== undefined;
  }
};

/** ACTIONS */
const actions = {
  /**
   * GET：車番連絡表出力用データを検索して取得
   * @param { commit } param0 Store
   * @param {*} getParams 検索条件
   */
  async fetchList({ commit }, getParams) {
    let pageCount = getParams.pageCount;
    return await $axios
      .get(axiosBase.getUrls().getServiceContactList, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("backUpMap");
          commit("clearList");
        }
        commit("writeList", value.data.data);
      });
  },
  /**
   * GET：輸送依頼（指示）書出力用データを検索して取得
   * @param { commit } param0 Store
   * @param {*} getParams 検索条件
   */
  async fetchTransportOrderList({ commit }, getParams) {
    const pageCount = getParams.pageCount;
    return await $axios
      .get(axiosBase.getUrls().getTransportOrderList, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("backUpMap");
          commit("clearList");
        }
        commit("writeList", value.data.data);
      });
  },
  /**
   * Download：車番連絡表PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadServiceContactReport({ state, dispatch }, searchCondition) {
    // 取得：出力対象
    const targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatClientRequired(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition
    };
    await $download.get(axiosBase.getUrls().getServiceContactReportDownload, {
      params: condition,
      paramsSerializer: params => {
        return qs.stringify(params, { allowDots: true });
      }
    });
  },
  /**
   * FAX送信 車番連絡表
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async sendServiceContactReport({ state, dispatch }, searchCondition) {
    // 取得：出力対象
    const targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatClientRequired(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition
    };
    await $download.get(axiosBase.getUrls().getServiceContactReportSend, {
      params: condition,
      paramsSerializer: params => {
        return qs.stringify(params, { allowDots: true });
      }
    });
  },
  /**
   * Download：輸送依頼書PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadTransportRequestReport({ state, dispatch }, searchCondition) {
    // 取得：出力対象
    const targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatDeliveryRequired(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition
    };
    await $download.get(axiosBase.getUrls().getTransportRequestReportDownload, {
      params: condition,
      paramsSerializer: params => {
        return qs.stringify(params, { allowDots: true });
      }
    });
  },
  /**
   * Download：運行指示書PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadTransportInstructionReport({ state, dispatch }, searchCondition) {
    // 取得：出力対象
    const targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatDeliveryRequired(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition
    };
    await $download.get(axiosBase.getUrls().getTransportInstructionReportDownload, {
      params: condition,
      paramsSerializer: params => {
        return qs.stringify(params, { allowDots: true });
      }
    });
  },
  /**
   * fax 輸送依頼書送信
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async sendTransportRequestReport({ state, dispatch }, searchCondition) {
    // 取得：出力対象
    const targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatDeliveryRequired(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition
    };
    await $download.get(axiosBase.getUrls().getTransportRequestReportSend, {
      params: condition,
      paramsSerializer: params => {
        return qs.stringify(params, { allowDots: true });
      }
    });
  },
  /**
   * fax 運行指示書送信
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async sendTransportInstructionReport({ state, dispatch }, searchCondition) {
    // 取得：出力対象
    const targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatDeliveryRequired(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition
    };
    await $download.get(axiosBase.getUrls().getTransportInstructionReportSend, {
      params: condition,
      paramsSerializer: params => {
        return qs.stringify(params, { allowDots: true });
      }
    });
  },
  /**
   * POST：車番連絡表にて署名や定型文、コメントの登録を行う
   * @param { commit } param0 Store
   * @param {*} data 更新情報
   */
  async postServiceContactReportEditMeta({ commit }, data) {
    await $axios
      .post(axiosBase.getUrls().postServiceContactReportEditMeta, ReportExtractor.formatClient(data))
      .then(value => {
        const commitCondition = {
          searchFunction: ReportExtractor.searchClient,
          mappingFunction: ReportExtractor.formatMeta
        };
        commit("mergeList", { response: value.data.data, commitCondition });
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：輸送依頼（指示）書にて署名や定型文、コメントの登録を行う
   * @param { commit } param0 Store
   * @param {*} data 更新情報
   */
  async postTransportOrderEditMeta({ commit }, data) {
    await $axios
      .post(axiosBase.getUrls().postTransportOrderEditMeta, ReportExtractor.formatDelivery(data))
      .then(value => {
        const commitCondition = {
          searchFunction: ReportExtractor.searchDelivery,
          mappingFunction: ReportExtractor.formatMeta
        };
        commit("mergeList", { response: value.data.data, commitCondition });
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：車番連絡表にて署名・定型文 一括登録
   * @param { state, commit } param0 Store
   * @param {*} param1 { 検索条件, 更新情報 }
   */
  async postServiceContactReportBulkEditMeta({ state, commit }, { searchCondition, ...updateCondition }) {
    // 取得：登録対象
    const targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatClient(element));
    const condition = {
      list: targetList,
      ...updateCondition
    };
    await $axios
      .post(axiosBase.getUrls().postServiceContactReportBulkEditMeta, condition)
      .then(value => {
        const commitCondition = {
          searchFunction: ReportExtractor.searchClient,
          mappingFunction: ReportExtractor.formatMeta
        };
        commit("mergeList", { response: value.data.data, commitCondition });
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：輸送依頼（指示）書にて署名・定型文 一括登録
   * @param { state, commit } param0 Store
   * @param {*} param1 { 検索条件, 更新情報 }
   */
  async postTransportOrderBulkEditMeta({ state, commit }, { searchCondition, ...updateCondition }) {
    // 取得：登録対象
    const targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatDelivery(element));
    const condition = {
      list: targetList,
      ...updateCondition
    };
    await $axios
      .post(axiosBase.getUrls().postTransportOrderBulkEditMeta, condition)
      .then(value => {
        const commitCondition = {
          searchFunction: ReportExtractor.searchDelivery,
          mappingFunction: ReportExtractor.formatMeta
        };
        commit("mergeList", { response: value.data.data, commitCondition });
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * FETCH：レポートリスト初期化
   * @param { commit } param0 Store
   */
  async fetchClearList({ commit }) {
    await commit("clearList");
  },
  /**
   * FETCH：選択状態切替
   * @param { commit } param0 Store
   * @param { Boolean } isSelected 選択フラグ
   */
  async fetchToggleSelected({ commit }, isSelected) {
    await commit("toggleSelected", isSelected);
  },
  /**
   * 一覧用リストと状態（アコーディオンの開閉トグルやチェックボックスの選択状態）を一括クリア
   *
   * @param commit
   * @returns {Promise<void>}
   */
  async clearFetchList({ commit }) {
    await commit("clearList");
    await commit("clearBackUpStatus");
  }
};

/** MUTATIONS */
const mutations = {
  /**
   * COMMIT：リスト初期化
   * @param {*} state Store
   */
  clearList(state) {
    state.list = [];
    state.capitalBlankList = [];
  },
  /**
   * アコーディオンの開閉トグルやチェックボックスの選択状態をバックアップ
   * @param state
   */
  backUpMap(state) {
    state.list.map(d => {
      state.statusMapList[d.uniqueKey] = {
        isDisplayCell: d.isDisplayCell === undefined ? false : d.isDisplayCell,
        selectTarget: d.selectTarget === undefined ? false : d.selectTarget
      };
    });
  },
  /**
   * アコーディオンの開閉トグルやチェックボックスの選択状態をバックアップしたものをクリア
   * @param state
   */
  clearBackUpStatus() {
    state.statusMapList = {};
  },
  /**
   * COMMIT：リスト追加
   * @param {*} state Store
   * @param {*} data 追加データ
   */
  writeList(state, data) {
    if (data != null) {
      data.list.forEach(rec => {
        if (state.statusMapList[rec.uniqueKey] !== undefined) {
          rec.isDisplayCell = state.statusMapList[rec.uniqueKey].isDisplayCell;
          rec.selectTarget = state.statusMapList[rec.uniqueKey].selectTarget;
        }
      });

      state.list = state.list.concat(data.list);
      // 資本金設定無しの一次委託先リストを格納
      if (data.capitalBlankList) {
        state.capitalBlankList = data.capitalBlankList;
      } else {
        state.capitalBlankList = [];
      }
    }
  },
  /**
   * COMMIT：対象情報のマージ 車番連絡表用/輸送依頼（指示）書用
   * ※デフォルトはサロゲートキー利用検索, 選択状態を維持
   * ※検索時のデータ構造と変わっていた場合、ネスト部分は上書きされる（ShallowCopy）
   * @param {*} state Store
   * @param {*} param1 { 更新情報, { searchFunction:マージ対象検索関数, mappingFunction:マージ対象データ構造関数 }}
   */
  mergeList(
    state,
    {
      response,
      commitCondition = {
        searchFunction: ReportExtractor.searchSerial,
        mappingFunction: ReportExtractor.deleteSelectTarget
      }
    }
  ) {
    state.list = ReportExtractor.mergeList(
      state.list,
      "list" in response ? response.list : response,
      commitCondition
    );
  },
  /**
   * COMMIT：取得済みリスト 選択状態全変更
   * @param { Store } state Store
   * @param { Boolean } isSelected true:全選択 false:全解除
   */
  toggleSelected(state, isSelected) {
    const toggleList = state.list.map(element => {
      return { ...element, ...{ selectTarget: isSelected } };
    });
    state.list = toggleList;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
