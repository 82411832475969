<template lang="pug">
  v-menu(
    v-model="display"
    absolute
    offset-y
    :position-x="x",
    :position-y="y"
    :disabled="unChangeable")
    v-list
      v-list-tile(v-for="item in shiftItems" :key="item.code" @click="selectList(item)")
        v-list-tile-content
          v-list-tile-title
            v-icon(:color="item.color") {{ item.icon }}
        div {{ item.label }}
</template>

<script>
import * as Enum from "@/assets/js/enum";
export default {
  props: {
    unChangeable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: false,
      x: 0,
      y: 0
    };
  },
  computed: {
    shiftItems: {
      get() {
        return Enum.Utils.enumToList(Enum.ShiftType);
      }
    }
  },
  methods: {
    selectList(item) {
      this.$emit("selectShiftType", item);
    },
    open(event) {
      event.preventDefault();
      this.display = false;
      this.x = event.clientX;
      this.y = event.clientY;

      this.$nextTick(() => {
        this.display = true;
      });
    }
  }
};
</script>

<style scoped lang="stylus"></style>
