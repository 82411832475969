import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  paymentTariffTypeList: []
};

const getters = {};

const actions = {
  async fetchAllPaymentTariffType({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllPaymentTariffTypeList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllPaymentTariffTypeData");
        }
        commit("appendAllPaymentTariffTypeData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchPaymentTariffTypeForSelect({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getPaymentTariffTypeForSelect, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllPaymentTariffTypeData");
        }
        commit("appendAllPaymentTariffTypeData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchPaymentTariffType({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllPaymentTariffType + id).then(value => {
      return value.data.data;
    });
  }
};

const mutations = {
  clearAllPaymentTariffTypeData(state) {
    state.paymentTariffTypeList = [];
  },
  appendAllPaymentTariffTypeData(state, data) {
    if (data != null) {
      state.paymentTariffTypeList = state.paymentTariffTypeList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
