<template lang="pug">
  section
    table.basic_table
      tr
        th 適用ルール
        td {{getRule}}
        th 基本料金(自動計算)
        td
          DigitSeparatorsLabel(:value="getPrice").price
</template>

<script>
import { mapGetters } from "vuex";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";

export default {
  components: {
    DigitSeparatorsLabel
  },
  props: {},
  computed: {
    ...mapGetters({
      billingCardBasicPrice: "finance/billingCardBasicPrice"
    }),
    getRule() {
      return this.billingCardBasicPrice ? this.billingCardBasicPrice.basicPriceCalculationTypeLabel : null;
    },
    getPrice() {
      return this.billingCardBasicPrice ? this.billingCardBasicPrice.basicPrice : null;
    }
  },
  methods: {}
};
</script>

<style scoped lang="stylus">
table.basic_table {
  th, td {
    padding 8px
  }

  th, td {
    white-space nowrap
  }
}
</style>
