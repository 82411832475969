<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond"
    @onChangeSearchConditionsTriggerBefore="onChangeSearchConditionsTriggerBefore"
    @onChangeSearchConditions="onChangeSearchConditions" ref="cond"
    :customLoading="loading" :enableCustomLoading="true")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="荷主名" v-model="conditions.clientName" hideDetails)
        v-flex(md2)
          v-text-field(label="荷主枝番" v-model="conditions.clientBranchName" hideDetails)
        v-flex(md2)
          v-text-field(label="積地CD" v-model="conditions.loadingBaseCd" hideDetails)
        v-flex(md2)
          v-text-field(label="積地名" v-model="conditions.loadingBaseName" hideDetails)
        v-flex(md2)
          v-text-field(label="積地エリア名" v-model="conditions.loadingBaseAreaName" hideDetails)
      v-layout(wrap)
        v-flex(md2  offset-xs4)
          v-checkbox(label="第一運行必須指定" v-model="conditions.isOnlyFirst" hideDetails)
      v-layout(wrap)
        v-flex(md2 offset-xs4)
          v-text-field(label="卸地CD" v-model="conditions.unloadingBaseCd" hideDetails)
        v-flex(md2)
          v-text-field(label="卸地名" v-model="conditions.unloadingBaseName" hideDetails)
        v-flex(md2)
          v-text-field(label="卸地エリア名" v-model="conditions.unloadingBaseAreaName" hideDetails)
      v-layout(wrap)
        v-flex(md2  offset-xs4)
          v-checkbox(label="最終運行必須指定" v-model="conditions.isOnlyLast" hideDetails)
      v-layout(wrap)
        v-flex(sm3 xl2)
          VehicleTypeMultiSelect(labelDisp="車格" :code.sync="conditions.vehicleTypeIdList" :clearable="true")
    template(v-slot:progress)
      v-flex(sm8 md9)
        v-progress-linear(v-model="dispatchPatternProgress" )
        div
          span 運行パターンマスタ数：
          span {{dispatchPatternTotalCount}}

</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import { mapActions, mapGetters } from "vuex";
import VehicleTypeMultiSelect from "@/components/common/select/VehicleTypeMultiSelect.vue";

export default {
  components: {
    VehicleTypeMultiSelect,
    SearchConditions
  },
  computed: {
    ...mapGetters({
      pageSize: "dispatchPattern/getDispatchPatternPageSize",
      dispatchPatternTotalCount: "dispatchPattern/getDispatchPatternTotalCount",
      dispatchPatternCurrentPage: "dispatchPattern/getDispatchPatternCurrentPage",
      loading: "dispatchPattern/getProgressDispatchPatternSelect",
      dispatchPatternProgress: "dispatchPattern/getDispatchPatternProgress"
    })
  },
  methods: {
    ...mapActions({
      removeEmptyPageNo: "dispatchPattern/removeEmptyPageNo"
    }),
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    onChangeSearchConditionsTriggerBefore() {
      //検索条件が変更された場合、前検索でHITしなかったページノリスとをクリアする
      this.removeEmptyPageNo();
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          clientBranchName: "",
          loadingBaseCd: "",
          loadingBaseName: "",
          loadingBaseAreaName: "",
          unloadingBaseCd: "",
          unloadingBaseName: "",
          unloadingBaseAreaName: "",
          vehicleTypeIdList: [],
          isOnlyFirst: false,
          isOnlyLast: false
        }
      };
    },
    submit() {
      this.$refs.cond.submit();
    }
  }
};
</script>
