<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FilterConditions(@onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              LoadingBaseTable(ref="LoadingBaseTable" :prop-header="customerTableHeader" :prop-data="loadingBaseList",
                :getListActionName="getListActionName",detail-url="base/fetchLoadingBase" @open-detail="openDetail"
                :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-left {{ body.client.clientDisplayName }}
                  td.text-xs-left {{ body.baseClassName }}
                  td.text-xs-left {{ body.baseCd }}
                  td.text-xs-left {{ body.baseName }}
                  td.text-xs-left {{ body.area.name }}
                  td.text-xs-left {{ body.prefecture.name }}
                  td {{ body.city }}
                  td.text-xs-left
                    v-chip(v-for="(optionName, index) in body.optionNameList" :key="index") {{ optionName }}
                  td.text-xs-left {{ formatYmd(body.createdAt)  }}
                  td.text-xs-left {{ formatYmd(body.updatedAt) }}

    LoadingBaseEditDialog(ref="LoadingBaseEditDialog" :postUrl="$config.urls.postEditLoadingBase" :deleteUrl="$config.urls.deleteLoadingBase",
      @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('base/exportLoadingBase')")
</template>

<script>
import FilterConditions from "@/components/management/base/LoadingBaseFilterConditions";
import LoadingBaseEditDialog from "@/components/management/base/LoadingBaseEdit";
import LoadingBaseTable from "@/components/common/PagingListTable";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    FilterConditions,
    LoadingBaseEditDialog,
    LoadingBaseTable,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    getListActionName: {
      get() {
        return "base/fetchAllLoadingBase";
      }
    },
    loadingBaseList: {
      get() {
        return this.$store.state.base.loadingBaseList;
      }
    },
    customerTableHeader: {
      get() {
        return [
          { text: "荷主" },
          { text: "積地分類" },
          { text: "積地CD" },
          { text: "積地名" },
          { text: "エリア名" },
          { text: "都道府県" },
          { text: "市区町村" },
          { text: "オプション" },
          { text: "登録日" },
          { text: "更新日" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openAddDialog() {
      this.$refs.LoadingBaseEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.LoadingBaseEditDialog.openDialog(item);
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     **/
    itemUpdated(response, data) {
      this.$refs.LoadingBaseTable.resetPageCount();
      this.loadListData();
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.LoadingBaseTable.loadData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.LoadingBaseTable.setSearchConditions(searchCond);
      this.$refs.LoadingBaseTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力確認ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    },
    formatYmd(dateTimeString) {
      return window.Common.Util.getYearMonthDayIsoFormat(new Date(Date.parse(dateTimeString)));
    }
  }
};
</script>
