<template lang="pug">
  section
    LoadingWrapper(:isDisplay="sumLoading" :isShow="false" :positionFixed="false")
    div.count-table
      table(class="header-summary-table")
        tr.whitespace-nowrap(v-show="sumCardCount>0" )
          th 表示カード:
          td
            DigitSeparatorsLabel(:value="sum.cardCount" prefix="" postfix=" 枚" ).price

          th 請求金額:
          td
            span.summary-title 金額累計（税抜）
            DigitSeparatorsLabel(:value="sum.billingTotalPriceSum").price

          //th 請求高速代（税込）累計:
          td
            span.summary-title 高速代（税込）
            DigitSeparatorsLabel(:value="sum.billingTollSubtotalPrice").price

          th 支払金額:
          td
            span.summary-title 金額累計（税抜）
            DigitSeparatorsLabel(:value="sum.paymentTotalPriceSum").price

          td
            span.summary-title 高速代（税込）
            DigitSeparatorsLabel(:value="sum.paymentTollSubtotalPrice").price


</template>

<script>
import { mapGetters } from "vuex";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import LoadingWrapper from "@/components/common/LoadingWrapper";

export default {
  components: {
    DigitSeparatorsLabel,
    LoadingWrapper
  },
  computed: {
    ...mapGetters({
      sum: "finance/getListSum",
      hasSum: "finance/hasListSum",
      sumLoading: "finance/getSumLoading",
      sumCardCount: "finance/getSumCardCount"
    })
  }
};
</script>

<style scoped lang="stylus">
.count {
  font-size: 1.3em;
}

.count-unit {
  margin-right: 8px;
}

table.header-summary-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
  font-size: 0.9em;
  margin-top: 8px;
  display: inline-block;

  td {
    height: 48px;
    border: solid #dcdcdc;
    border-width: 1px 0px;
    vertical-align: middle;
    padding-left: 16px;
    padding-right: 16px;
    text-align right
  }

  tr {
    height: 54px;
    transition: 0.3s;
  }

  th {
    background-color: #fff;
    top: 45px;
    z-index: 1;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    width: 170px;
  }

  .summary-title {
    font-size 12px
    display block
    color grey

  }


  .price {
    font-size 1.5em
  }
}
</style>
