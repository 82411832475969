<template lang="pug">
  EditDialog(ref="EditDialog" title="荷主卸地分類" :recordId="record.id" @delete="erase" @save="save"
      :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            ClientSelect(:label.sync="record.client.clientDisplayName" :code.sync="record.client.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'client.id'")

          v-flex(xs12 md12)
            v-text-field( v-model="record.baseClassName" label="卸地分類名" :error-messages="getErrorMessages('baseClassName')")</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ClientSelect from "@/components/common/select/ClientSelect";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    EditDialog,
    ClientSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      title: "荷主卸地分類編集",
      record: {
        id: null,
        client: {
          id: null,
          clientName: null
        },
        baseClassName: null
      },
      initValues: {
        id: null,
        client: {
          id: null,
          clientName: null
        },
        baseClassName: null
      }
    };
  },
  methods: {
    initData() {
      return {
        id: null,
        client: {
          id: null,
          clientName: null
        },
        baseClassName: null
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
