import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  itemList: []
};

const getters = {};

const actions = {
  async fetchAllItemType({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllItemTypeList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllItemTypeData");
        }
        commit("appendAllItemTypeData", value.data.data);
      });
  },
  async fetchItemType({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllItemType + id).then(value => {
      return value.data.data;
    });
  },
  async fetchSelectItemType({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllItemTypeForSelect, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllItemTypeData");
        }
        commit("appendAllItemTypeData", value.data.data);
        return Promise.resolve(value.data.data === null ? [] : value.data.data);
      });
  }
};

const mutations = {
  clearAllItemTypeData(state) {
    state.itemList = [];
  },
  appendAllItemTypeData(state, data) {
    if (data != null) {
      state.itemList = state.itemList.concat(data);
    }
  },
  writeAllItemTypeData(state, data) {
    state.itemList = data == null ? [] : data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
