<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      DispatchPatternFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              DispatchPatternTable(ref="ListTable" :prop-header="tableHeader" :prop-data="dataList" displaySlotBody
                @open-detail="openDetail" detail-url="dispatchPattern/fetchOne" :getListActionName="getListActionName")
                template(v-slot:body="{body,index}" )
                  td.no-box
                    span {{ index+1 }}
                  td
                    span {{ body.priority }}
                  td
                    section.pattern-card-list-holder
                      div.id-box {{ body.id }}
                      template(v-for="(card,index) in body.cards" )
                        div.separate-allow(v-if="index !== 0")
                          v-icon() arrow_forward_ios
                        PatternCard(:card="card").card

                    section(v-if="body.remark")
                      span {{body.remark}}

    DispatchPatternEdit(ref="DataEditDialog"
      :postUrl="$config.urls.postEditDispatchPattern" :deleteUrl="$config.urls.deleteDispatchPattern"
      @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('area/export')")
</template>

<script>
import DispatchPatternEdit from "@/components/management/dispatch-pattern/DispatchPatternEdit";
import DispatchPatternTable from "@/components/common/PagingListTable";
import DispatchPatternFilterConditions from "@/components/management/dispatch-pattern/DispatchPatternFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";
import { mapGetters } from "vuex";
import PatternCard from "@/components/dispatch-pattern/PatternCard.vue";

export default {
  components: {
    DispatchPatternTable,
    DispatchPatternFilterConditions,
    ExportConfirmDialog,
    PatternCard,
    DispatchPatternEdit
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      dataList: "dispatchPattern/getList"
    }),
    getListActionName: {
      get() {
        return "dispatchPattern/fetchAll";
      }
    },
    tableHeader: {
      get() {
        return [
          { text: "連番", value: "", centerAlign: true, width: "50px" },
          { text: "優先度", value: "priority", centerAlign: true, width: "50px" },
          { text: "配送パターンカード", value: "order", centerAlign: false }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.DataEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.DataEditDialog.openDialog(item);
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.ListTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.ListTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.ListTable.setSearchConditions(searchCond);
      this.$refs.ListTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  }
};
</script>

<style scoped lang="stylus">
.no-box{
  position relative
}

.id-box{
  position absolute
  right 0;
  top 0;
  color white
}
</style>
