<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加
                  ExportTemplateEdit(ref="ExportTemplateEdit" @updated="itemUpdated" @deleted="itemUpdated")
            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      ExportTemplateFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              ExportTemplateTable(ref="ExportTemplateTable" :prop-header="listHeader" :prop-data="templateList" :displaySlotBody="true"
                @open-detail="openDetail" :detail-url="listDetailUrl" :getListActionName="listActionName")
                template(v-slot:body="{ body }")
                  td(width="200px") {{ body.templateName }}
                  td
                    v-layout(v-for="category in body.outputTargetsWithCategoryList" :key="category.mainCategoryId")
                      v-flex(xs1) {{ category.mainCategoryName }}
                      v-flex(xs11) {{ getTargetNames(category.outputTargetWithSubCategoryList) }}
                  td
                    v-chip(v-for="(roleId, index) in body.roleIds" :key="index") {{ getRoleNames(roleId) }}
                  td
                    p.ma-0.table-remark {{ body.remark }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ExportTemplateFilterConditions from "@/components/management/export-template/ExportTemplateFilterConditions";
import ExportTemplateEdit from "@/components/management/export-template/ExportTemplateEdit";
import ExportTemplateTable from "@/components/common/PagingListTable";

export default {
  components: {
    ExportTemplateFilterConditions,
    ExportTemplateEdit,
    ExportTemplateTable
  },
  computed: {
    // テーブルヘッダー
    listHeader: () => [{ text: "テンプレート名" }, { text: "出力対象" }, { text: "権限" }, { text: "備考" }],
    // 選択行詳細取得Action
    listDetailUrl: () => "exportTemplate/fetchExportTemplate",
    // テーブル一覧取得Action
    listActionName: () => "exportTemplate/fetchAll",
    /** Getter：Vuex */
    ...mapGetters({
      templateList: "exportTemplate/getExportTemplateList",
      roleList: "role/getList"
    })
  },
  methods: {
    ...mapActions("role", {
      fetchAllRole: "fetchAllRole"
    }),
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCondition 検索条件
     */
    onChangeSearchConditions(searchCondition) {
      // InfiniteScrollHandler処理
      this.$refs.ExportTemplateTable.setSearchConditions(searchCondition);
      this.$refs.ExportTemplateTable.resetPageCount();
      // データ取得
      this.$refs.ExportTemplateTable.loadData();
    },
    /**
     * 新規作成ダイアログの表示
     */
    openAddDialog() {
      this.$refs.ExportTemplateEdit.openDialog();
    },
    /**
     * 更新ダイアログの表示
     * @param detail 選択行情報
     */
    openDetail(detail) {
      this.$refs.ExportTemplateEdit.openDialog(detail);
    },
    /**
     * Mixin ApiHandler：レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.ExportTemplateTable.resetPageCount();
      this.$refs.ExportTemplateTable.loadData();
    },
    /**
     * Bind Event：テーブル行表示 出力対象
     */
    getTargetNames(subCategoryList) {
      var targetNames = [];
      if (subCategoryList === null) return "";
      for (var key in subCategoryList) {
        for (var target in subCategoryList[key].list) {
          targetNames.push(subCategoryList[key].list[target].codeName);
        }
      }
      return targetNames.join(" / ");
    },
    /**
     * 権限名称取得
     */
    getRoleNames(roleId) {
      let roleName = "";
      this.roleList
        .filter(role => role.id === roleId)
        .forEach(v => {
          roleName = v.roleName;
        });
      return roleName;
    }
  },
  mounted() {
    // データ取得
    this.$refs.ExportTemplateTable.loadData();
    this.fetchAllRole();
  }
};
</script>

<style scoped lang="stylus">
.table-remark  {
  max-width : 30vw;
  word-wrap : break-word;
  overflow-wrap : break-word;
  white-space : pre-wrap;
}
</style>
