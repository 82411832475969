import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  fixedPaymentRateList: [],
  fixedPaymentRateHistoryList: [],
  fixedPaymentRateHistoryDiff: null,
  //ページング用
  paging: {
    historyTablePageCount: 0
  }
};

const getters = {
  fixedPaymentRateHistoryList: () => state.fixedPaymentRateHistoryList,
  fixedPaymentRateHistoryDiff: () => state.fixedPaymentRateHistoryDiff
};

const actions = {
  async fetchAll({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllFixedPaymentRateList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllFixedPaymentRateData");
        }
        commit("appendAllFixedPaymentRateData", value.data.data);
      });
  },
  async fetchFixedPaymentRate({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllFixedPaymentRate + id).then(value => {
      return value.data.data;
    });
  },
  /**
   * 特定固定請求料金IDの履歴を一括で取得する
   *
   * @param commit
   * @param state
   * @param fixedPaymentRateId
   * @returns {Promise<*[] | *>}
   */
  async fetchFixedPaymentRateHistoryList({ commit, state }, fixedPaymentRateId) {
    let getParams = {};
    const mergedParams = Object.assign(getParams, {
      pageCount: state.paging.historyTablePageCount
    });
    return $axios
      .get(axiosBase.getUrls().getAllFixedPaymentRateHistoryList + fixedPaymentRateId, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.historyTablePageCount === 0) {
          commit("clearAllFixedPaymentRateHistoryList");
        }
        commit("appendFixedPaymentRateHistoryList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * 固定請求料金履歴IDを元にそれとその直前固定請求料金の履歴差分データを取得する
   * @param commit
   * @param state
   * @param fixedPaymentRateHistoryId
   * @returns {Promise<*[] | *>}
   */
  async fetchFixedPaymentRateDiff({ commit, state }, fixedPaymentRateHistoryId) {
    return $axios
      .get(axiosBase.getUrls().getFixedPaymentRateHistoryDiff + fixedPaymentRateHistoryId)
      .then(value => {
        let data = value.data.data == null ? [] : value.data.data;
        commit("saveFixedPaymentRateHistoryDiff", data);
        return Promise.resolve(data);
      });
  },
  /**
   * 履歴表示ダイアログ用のデータをクリア
   * @param commit
   * @returns {Promise<void>}
   */
  async clearHistoryData({ commit }) {
    commit("clearFixedPaymentRateHistoryDiff");
    commit("clearFixedPaymentRateHistoryList");
  },
  // 実績出力
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getFixedPaymentRateExport, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllFixedPaymentRateData(state) {
    state.fixedPaymentRateList = [];
  },
  appendAllFixedPaymentRateData(state, data) {
    if (data != null) {
      state.fixedPaymentRateList = state.fixedPaymentRateList.concat(data);
    }
  },
  clearAllFixedPaymentRateHistoryList(state) {
    state.fixedPaymentRateHistoryList = [];
  },
  appendFixedPaymentRateHistoryList(state, data) {
    if (data != null) {
      state.fixedPaymentRateHistoryList = state.fixedPaymentRateHistoryList.concat(data);
    }
  },
  saveFixedPaymentRateHistoryDiff(state, data) {
    state.fixedPaymentRateHistoryDiff = data;
  },
  clearFixedPaymentRateHistoryList(state) {
    state.fixedPaymentRateHistoryList = [];
  },
  clearFixedPaymentRateHistoryDiff(state) {
    state.fixedPaymentRateHistoryDiff = null;
  },
  incrementHistoryTablePageCount(state) {
    state.paging.historyTablePageCount++;
  },
  clearHistoryTablePageCount(state) {
    state.paging.historyTablePageCount = 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
