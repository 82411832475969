<template lang="pug">
  v-dialog(v-model="dialog" persistent scrollable width="60%" @keydown.esc="closeDialog")
    v-card
      DialogHeader(title="メール送信" @close-dialog="closeDialog")
      v-card-text(ref="scrollTarget")
        alert-box(ref="errorMsg")
        v-container(glid-list-md)
          v-layout(wrap)
            v-spacer
            v-menu(bottom left)
              v-btn(@click="" color="success" slot="activator") 第1
              v-list(dense)
                v-list-tile(@click="allSelectTarget(targetType.one)")
                  v-list-tile-action
                    v-icon() done_all
                  v-list-tile-content 全選択
                v-list-tile(@click="allNotSelectTarget(targetType.one)")
                  v-list-tile-action
                    v-icon() clear
                  v-list-tile-content 全解除

            v-menu(bottom left)
              v-btn(@click="" color="warning" slot="activator") 全運
              v-list(dense)
                v-list-tile(@click="allSelectTarget(targetType.all)")
                  v-list-tile-action
                    v-icon() done_all
                  v-list-tile-content 全選択
                v-list-tile(@click="allNotSelectTarget(targetType.all)")
                  v-list-tile-action
                    v-icon() clear
                  v-list-tile-content 全解除

          v-layout(row wrap)
            v-flex(xs12 md12)
              table(class="sticky_table")
                thead
                  tr
                    th(class="py-3 th-text font-weight-thin grey--text text--darken-1") No
                    th(class="py-3 th-text font-weight-thin grey--text text--darken-1") 乗務員名
                    th(class="py-3 th-text font-weight-thin grey--text text--darken-1") 所属会社
                    th(class="py-3 th-text font-weight-thin grey--text text--darken-1") 前回送信時からの変更
                    th(class="py-3 th-text font-weight-thin grey--text text--darken-1" width="60px") 第1
                    th(class="py-3 th-text font-weight-thin grey--text text--darken-1" width="60px") 全運

                tbody
                  tr(v-for="(item, index) in record.dataList")
                    td.text-xs-right {{ index + 1 }}
                    td {{ item.name }}
                    td {{ item.deliveryCompanyName }}
                    td {{ item.isChangedLabel }}
                    td
                      v-checkbox.targetSelectCell(:disabled="isNotSendTarget(item)" v-model="item.isMailSendTarget" :value="targetType.one")
                    td
                      v-checkbox.targetSelectCell(v-model="item.isMailSendTarget" :value="targetType.all")
      v-toolbar.footer
        v-spacer
        v-btn(color="primary" @click="sendConfirm" :loading="loadingSend") 送信
    SendMailConfirm(ref="SendMailConfirm" :message="confirmMessage" @yes="sendMail")
</template>

<script>
import SendMailConfirm from "@/components/common/dialog/ConfirmDialog";
import AlertBox from "@/components/common/AlertBox";
import DialogHeader from "@/components/common/EditDialogHeader";

import EditForm from "../../mixin/EditForm";
import { mapGetters } from "vuex";
import Axios from "@/assets/js/axios";

let $http = Axios.http;

export default {
  components: {
    DialogHeader,
    SendMailConfirm,
    AlertBox
  },
  mixins: [EditForm],
  data() {
    return {
      dialog: false,
      loadingSend: false,
      record: {
        dataList: []
      },
      sendTarget: {
        sendList: []
      },
      targetType: {
        one: 10,
        all: 20
      }
    };
  },
  props: {
    // TODO:利用していないが値は渡されてくるため保留。
    // eslint-disable-next-line vue/require-prop-types
    loadingDate: {
      default: window.Common.Util.getYearMonthDayIsoFormat()
    }
  },
  methods: {
    openDialog(searchCond) {
      this.sendTarget.sendList = [];
      this.record.dataList = [];
      this.reloadData(searchCond);
      this.dialog = true;
    },
    closeDialog() {
      this.$emit("send");
      this.$refs.errorMsg.nonDisplayMessage();
      this.dialog = false;
    },
    reloadData(searchCond) {
      this.$store.dispatch("driver/fetchMailSendDriver", searchCond).then(value => {
        this.record.dataList = value;
      });
    },
    allSelectTarget(value) {
      if (this.record.dataList != []) {
        this.record.dataList.forEach(data => {
          if (value === this.targetType.all || !this.isNotSendTarget(data)) {
            this.$set(data, "isMailSendTarget", value);
          }
        });
      }
    },
    allNotSelectTarget(value) {
      if (this.record.dataList != []) {
        this.record.dataList.forEach(data => {
          if (data.isMailSendTarget === value) {
            this.$set(data, "isMailSendTarget", false);
          }
        });
      }
    },
    sendConfirm() {
      this.$refs.SendMailConfirm.openDialog();
    },
    sendMail() {
      if (this.getSendTargetList !== []) {
        this.loadingSend = true;
        $http
          .post(this.$config.urls.postSendMail, this.getSendTargetList)
          .then(value => {
            this.closeDialog();
          })
          .catch(error => {
            this.$refs.errorMsg.displayMessage(error.response.data.message);
          })
          .finally(() => {
            this.loadingSend = false;
          });
      }
    },
    initTargetData() {
      return {
        mailType: "",
        dispatchId: ""
      };
    },
    /**
     * 追加：SendList
     * ※computedで他要素を操作することをESLintは許容していないため
     */
    pushSendList(data) {
      this.sendTarget.sendList.push(data);
    }
  },
  computed: {
    ...mapGetters({
      getMailType: "site/mailTypeStringFromKey"
    }),
    getSendTargetList: {
      get() {
        this.record.dataList
          .filter(data => data.isMailSendTarget)
          .forEach(data => {
            let targetData = {};
            Object.assign(targetData, this.initTargetData);
            targetData.mailType = data.isMailSendTarget;
            targetData.dispatchId = data.dispatchId;
            this.pushSendList(targetData);
          });
        return this.sendTarget;
      }
    },
    confirmMessage: {
      get() {
        return "選択されている乗務員に配車メールを送信しますか？";
      }
    },
    isNotSendTarget: function () {
      return function (driver) {
        return driver.existsFirstDispatch ? false : true;
      };
    }
  }
};
</script>

<style lang="stylus" scoped>
.sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.v-card__text {
  padding-top: 0;
}

.footer {
  position: sticky;
  bottom: 0;
}

table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.th-text {
  font-size: 13px;
}

.targetSelectCell {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
