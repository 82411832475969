<template lang="pug">
  EditDialog(ref="EditDialog" title="車格" :recordId="record.id" @delete="erase" @save="save" width="60%"
      :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md8)
            v-card
              v-toolbar(dark :color="$store.getters['site/siteColor']")
                v-toolbar-title 車格
              v-card-text
                v-container(grid-list-md)
                  v-layout(row wrap)
                    v-flex(xs12 md3)
                      v-text-field(v-model="record.vehicleTypeCd" label="車格CD" :box="record.mainVehicleType" :readonly="record.mainVehicleType" :error-messages="getErrorMessages('vehicleTypeCd')")

                    v-flex(xs12 md3)
                      v-text-field(v-model="record.vehicleTypeName" label="名称" :box="record.mainVehicleType" :readonly="record.mainVehicleType" :error-messages="getErrorMessages('vehicleTypeName')")

                    v-flex(xs12 md3)
                      v-text-field.text-align-right(v-model="record.displayOrder" label="表示順" :error-messages="getErrorMessages('displayOrder')")

                  v-layout(row wrap)
                    v-flex(xs12 md3)
                      v-checkbox(v-model="record.useChassis" label="シャーシ利用" :readonly="record.mainVehicleType" :error-messages="getErrorMessages('useChassis')")

                    v-flex(xs12 md4)
                      v-checkbox(v-model="record.displayResourceTarget" label="リソース表示対象" :error-messages="getErrorMessages('displayResourceTarget')")

                  v-layout(row wrap)
                    v-flex(xs12 md12 v-if="record.mainVehicleType")
                      div ※主要な車格の場合、車格CD・名称・シャーシ利用の変更、車格の削除はできません。

          v-flex(xs12 md4)
            v-card
              v-toolbar(dark :color="$store.getters['site/siteColor']")
                v-toolbar-title DriverName
              v-card-text
                v-btn(color="primary" @click="openDriverNameAddDialog") 追加
                DriverNameListTable(:prop-data="record.driverNameList" :errorMessage="driverListErrorMessage" @delete="deleteDriverName")

      DriverNameAddDialog(ref="DriverNameAddDialog" @save="addDriverNameList" :postUrl="$config.urls.checkDriverName")
    EmergencyErrorDialog(ref="EmergencyErrorDialog" @clickClose="")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import DriverNameListTable from "@/components/management/vehicle-type/DriverNameListTable";
import DriverNameAddDialog from "@/components/management/vehicle-type/DriverNameAddDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    EditDialog,
    EmergencyErrorDialog,
    DriverNameListTable,
    DriverNameAddDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {}
    };
  },
  computed: {
    recordId: {
      get() {
        return this.record.mainVehicleType ? null : this.record.id;
      }
    },
    driverListErrorMessage: {
      get() {
        return this.getErrorMessages("driverNameList");
      }
    }
  },
  methods: {
    initData() {
      return {
        id: null,
        vehicleTypeCd: null,
        vehicleTypeName: null,
        displayOrder: null,
        useChassis: false,
        displayResourceTarget: false,
        mainVehicleType: false,
        driverNameList: []
      };
    },
    openDialog(item) {
      let _item = item;
      if (_item == null) {
        _item = Object.assign({}, this.initData());
      }
      this.initForm(_item);

      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    },
    /**
     * DriverName追加ダイアログを表示
     */
    openDriverNameAddDialog() {
      this.$refs.DriverNameAddDialog.openDialog();
    },
    /**
     * 入力したDriverNameをテーブルに表示
     */
    addDriverNameList(item) {
      this.record.driverNameList.push(item);
    },
    /**
     * 選択したDriverNameを削除
     */
    deleteDriverName(index) {
      this.record.driverNameList.splice(index, 1);
    }
  },
  created() {
    this.record = this.initData();
  }
};
</script>
