<template lang="pug">
  section
    table.list-table.border-table
      thead.sticky
        tr
          th No
          th 荷主/荷主枝番
          th ShipmentNo
          th 積込日/納品日
          th 積地
          th 卸地
          th 車格
          th 1次委託先会社 / 実運行会社 / 車番 / 乗務員名
          th 備考
          th(width="130px")
            FinanceMetaSetting(ref="FinanceMetaSetting" :searchCond="searchConditions")
      tbody
        template(v-if="cardList.length")
          ReportMetaEditDialog(ref="ReportMetaEditDialog" :actionName="actionName")
          template(v-for="(rec, idx) in cardList")
            tr(:data-row="idx % 2 === 0 ? 'even' : 'odd'" @mouseenter="onHoverRow" @mouseleave="onHoverOutRow" @dblclick="onDbClick(rec)")
              td(rowspan=2) {{idx + 1}}
              td
                span {{ rec.clientName }} /
                span {{ rec.clientBranchName }}
              td {{ rec.shipmentNo }}
              td {{ getDisplayDate(rec) }}
              td.text-whitespace-pre {{ rec.loadingBases | commaToNr }}
              td.text-whitespace-pre {{ rec.unloadingBases | commaToNr }}
              td {{ rec.vehicleTypeName }}
              td.linebreak {{ getDisplayDispatch(rec) }}
              template
                td.remark-box
                  v-tooltip(top)
                    template(v-slot:activator="{ on }")
                      p(v-on="on").remark.whitespace-pre {{ rec.financeRemark }}
                    span.whitespace-pre {{ rec.financeRemark }}
                  v-tooltip(top :disabled="!rec.slideRemark")
                    template(v-slot:activator="{ on }")
                      v-icon(v-if="rec.slided" v-on="on").warning-box-icon warning
                    span.whitespace-pre {{ rec.slideRemark }}
              td
                v-checkbox.justify-center.align-center(v-model="rec.selectTarget" v-on:change="onClickTarget")
            tr(:data-row="idx % 2 === 0 ? 'even' : 'odd'"  @mouseenter="onHoverRow" @mouseleave="onHoverOutRow" @dblclick="onDbClick(rec)")
              td(colspan="9")
                v-layout
                  v-flex(md6)
                    table.list-table
                      thead
                        tr.billing-bg-color
                          th 請求状況
                          th 請求対象年月
                          th 請求金額合計
                          th 基本運賃
                          th 高速代(税込)
                          th その他
                      tbody
                        tr
                          td.text-xs-center {{getBillingStatusLabel(rec.billingStatus) }}
                          td.text-xs-center {{rec.billingTargetYearMonth}}
                          td.text-xs-right
                            DigitSeparatorsLabel(:value="rec.billingCard.totalFare").price
                          td.text-xs-right
                            DigitSeparatorsLabel(:value="rec.billingCard.basicRate").price
                          td.text-xs-right
                            DigitSeparatorsLabel(:value="rec.billingCard.toll").price
                          td.text-xs-right
                            DigitSeparatorsLabel(:value="rec.billingCard.totalOther").price
                  v-flex(md6)
                    table.list-table
                      thead
                        tr.payment-bg-color
                          th 支払状況
                          th 支払対象年月
                          th 支払金額合計
                          th 基本運賃
                          th 高速代(税込)
                          th その他
                      tbody
                        tr
                          td.text-xs-center {{getPaymentStatusLabel(rec.paymentStatus) }}
                          td.text-xs-center {{rec.paymentTargetYearMonth}}
                          td.text-xs-right
                            DigitSeparatorsLabel(:value="rec.paymentCard.totalFare").price
                          td.text-xs-right
                            DigitSeparatorsLabel(:value="rec.paymentCard.basicRate").price
                          td.text-xs-right
                            DigitSeparatorsLabel(:value="rec.paymentCard.toll").price
                          td.text-xs-right
                            DigitSeparatorsLabel(:value="rec.paymentCard.totalOther").price
        template(v-else)
          tr
            td(:colspan="10") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
    DispatchDetail(ref="DispatchDetail" :isFinance="true" :postUrl="$config.urls.postSaveCardEdit")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import InfiniteScrollHandler from "@/mixin/InfiniteScrollHandler";
import FinanceMetaSetting from "@/components/finance/FinanceMetaSetting";
import ReportMetaEditDialog from "@/components/report/ReportMetaEditDialog";
import DispatchDetail from "@/components/dispatch/DispatchDetail";
import ConbineUtils from "@/mixin/ConbineUtils";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import * as Enum from "@/assets/js/enum";

export default {
  /**
   * props
   * getListActionName：InfiniteScrollHandlerにて必須
   */
  props: {
    getListActionName: {
      type: String,
      default: "finance/fetchBillingAndPaymentList"
    }
  },
  mixins: [EventDispatcher, InfiniteScrollHandler, ConbineUtils],
  components: {
    FinanceMetaSetting,
    ReportMetaEditDialog,
    DispatchDetail,
    DigitSeparatorsLabel
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      cardList: "finance/getList"
    }),
    actionName: () => "postServiceContactReportEditMeta"
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("finance", {
      fetchClearList: "fetchClearList",
      fetchBillingAndPaymentListSum: "fetchBillingAndPaymentListSum"
    }),
    /** Event：△▽による開閉 */
    toggleDetail(contents) {
      contents.isDisplayCell = !contents.isDisplayCell;
    },
    /** 変換：日付フォーマット */
    formatFullDateTime(datetime) {
      return window.Common.Util.getFullDateTimeIsoFormat(new Date(Date.parse(datetime)));
    },
    /** グレーアウト対象かどうか */
    isDisabled(item) {
      // if (item.clientStatus === undefined || item.clientBranchStatus === undefined) return false;
      // return (
      //   item.clientStatus === Enum.ActiveFlag.InActive.code ||
      //   item.clientBranchStatus === Enum.ActiveFlag.InActive.code
      // );
      return false;
    },
    onHoverRow(event) {
      this.toggleClass(event, true);
    },
    onHoverOutRow(event) {
      this.toggleClass(event, false);
    },
    toggleClass(event, flg) {
      const HOVER_CLASS = "hover";
      const element = event.target;
      const next_element = element.nextElementSibling;
      const prev_element = element.previousElementSibling;

      const hoverElementRowType = element.dataset.row;
      if (!hoverElementRowType) {
        return;
      }

      if (flg) {
        element.classList.add(HOVER_CLASS);

        if (next_element) {
          if (next_element.dataset.row === hoverElementRowType) {
            next_element.classList.add(HOVER_CLASS);
          }
        }

        if (prev_element.dataset.row === hoverElementRowType) {
          prev_element.classList.add(HOVER_CLASS);
        }
      } else {
        element.classList.remove(HOVER_CLASS);

        if (next_element) {
          if (next_element.dataset.row === hoverElementRowType) {
            next_element.classList.remove(HOVER_CLASS);
          }
        }

        if (prev_element.dataset.row === hoverElementRowType) {
          prev_element.classList.remove(HOVER_CLASS);
        }
      }
    },
    /** Event：テーブル行のダブルクリック */
    onDbClick(record) {
      this.$refs.DispatchDetail.openDialog({
        orderType: "10",
        orderId: 1,
        cardId: record.cardId
      });
    },
    onClickTarget() {
      this.$refs.FinanceMetaSetting.onIndeterminate();
    },
    resetAllSelect() {
      this.$refs.FinanceMetaSetting.resetSelectAll();
    },
    getBillingStatusLabel(code) {
      return Enum.Utils.findLabel(code, Enum.BillingStatusType);
    },
    getPaymentStatusLabel(code) {
      return Enum.Utils.findLabel(code, Enum.PaymentStatusType);
    },
    loadSum() {
      this.fetchBillingAndPaymentListSum(this.searchConditions);
    },
    getDisplayDate(rec) {
      return !rec.deliveryDate ? rec.shipmentDate : rec.shipmentDate + " / " + rec.deliveryDate;
    },
    getDisplayDispatch(rec) {
      if (rec.driverNameLast || rec.deliveryCompanyNameLast) {
        if (
          rec.deliveryCompanyName === rec.deliveryCompanyNameLast &&
          rec.displayNumber === rec.displayNumberLast &&
          rec.driverName === rec.driverNameLast
        ) {
          return (
            rec.primaryDeliveryCompanyName +
            " / " +
            rec.deliveryCompanyName +
            " / " +
            rec.displayNumber +
            " / " +
            rec.driverName
          );
        }

        return (
          "積：" +
          rec.primaryDeliveryCompanyName +
          " / " +
          rec.deliveryCompanyName +
          " / " +
          rec.displayNumber +
          " / " +
          rec.driverName +
          " \n " +
          "卸：" +
          rec.primaryDeliveryCompanyName +
          " / " +
          rec.deliveryCompanyNameLast +
          " / " +
          rec.displayNumberLast +
          " / " +
          rec.driverNameLast
        );
      }

      if (rec.primaryDeliveryCompanyName || rec.deliveryCompanyName || rec.entryNumber || rec.driverName) {
        return (
          rec.primaryDeliveryCompanyName +
          " / " +
          rec.deliveryCompanyName +
          " / " +
          rec.displayNumber +
          " / " +
          rec.driverName
        );
      }
      return "";
    }
  },
  mounted() {
    this.fetchClearList();
  },
  destroyed() {
    this.fetchClearList();
  }
};
</script>

<style lang="stylus" scoped>
.border-table {
  .sticky {
    th {
      background-color: #fff;
      position: sticky;
      top: 45px;
      z-index: 1;
    }
  }

  th, td {
    border-right: 1px solid #E0E0E0;
  }
  td {
    //height: 48px;
    border: solid #dcdcdc;
    border-width: 1px 0;
    border-right: 1px solid #E0E0E0;
    vertical-align: middle;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }
}

>>>.v-input--selection-controls__input {
  margin-right: 0;
}

.block {
  display block
}

.list-table{
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;

  tr {
    transition: 0.3s;
  }
  tr.hover {
    background-color #ECEFF1
  }

  th,td {
    padding 8px 8px
  }
}

//.inner-table {
//  width: 100%;
//
//  tr:hover {
//    background-color #03A9F4
//  }
//
//  thead {
//    th {
//      background none;
//      position: static;
//      z-index: -50;
//    }
//  }
//
//  th {
//    padding-top: 5px;
//    padding-bottom: 5px;
//  }
//}

.warning-box-icon {
  color: #FF5722;
}
.linebreak {
  white-space: pre-line;
}
</style>
