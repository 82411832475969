<template lang="pug">
  div
    table
      thead
        tr
          th(v-for="(header, index) in maintenanceTableHeader")
            div.th-text.font-weight-thin.grey--text.text--darken-1 {{ header.text }}
          th
            div.th-text.font-weight-thin.grey--text.text--darken-1 操作

      tbody
        tr(v-for="(item, index) in maintenanceTableData")
          td(v-for="header in maintenanceTableHeader" v-bind:class="header.align") {{ item[header.value] }}
          td.text-xs-center
            v-btn(icon @click="editMaintenance(item)")
              v-icon edit
            v-btn(icon @click="deleteMaintenance(item)")
              v-icon delete

      tfoot.footer

</template>

<script>
import * as Enum from "@/assets/js/enum";
export default {
  components: {},
  props: {
    propData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      maintenanceTableHeader: [
        { text: "開始", value: "startDate", align: "text-xs-center" },
        { text: "終了", value: "endDate", align: "text-xs-center" },
        { text: "理由", value: "reasonLabel", align: "" },
        { text: "備考", value: "remark", align: "" }
      ]
    };
  },
  computed: {
    vehicleMaintenanceTypes: {
      get() {
        return Enum.Utils.enumToList(Enum.VehicleMaintenanceType);
      }
    },
    /**
     * メンテナンスデータの開始時間でソートしたデータ
     */
    maintenanceTableData: {
      get() {
        if (this.propData != null) {
          return this.propData
            .slice()
            .map((vehicleMaintenance, index) => {
              vehicleMaintenance.index = index;
              vehicleMaintenance.reasonLabel = this.vehicleMaintenanceTypes.find(
                vehicleMaintenanceType => vehicleMaintenanceType.code === vehicleMaintenance.reason
              ).label;
              return vehicleMaintenance;
            })
            .sort((a, b) => {
              return a.startDate < b.startDate ? 1 : -1;
            });
        } else {
          return null;
        }
      }
    }
  },
  methods: {
    /**
     * メンテナンス編集アイコン押下時
     */
    editMaintenance(item) {
      this.$emit("editMaintenance", item);
    },
    /**
     * メンテナンス削除アイコン押下時
     */
    deleteMaintenance(item) {
      this.$emit("deleteMaintenance", item);
    }
  }
};
</script>

<style lang="stylus" scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
}

tbody tr:hover {
  background-color: #f5f5f5;
  transition: 0.2s;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 5px;
  padding-right: 5px;
}

.th-text {
  margin-top: 20px;
  font-size: 13px;
}
</style>
