<template lang="pug">
  table.accordion-table.order-table.sticky-table
    thead
      tr
        th.toggle-icon-cell.no-right-border
        th 1次委託先会社
        th 定型文
        th 署名
        th 手書きメモ
        th(width="160px") 最終出力日
        th 最終出力者
        th FAX送信状況
        th(width="120px")
          ReportMetaSetting(@onClickEvent="onClickEvent")
    tbody
      template(v-if="reportList.length")
        ReportMetaEditDialog(ref="ReportMetaEditDialog" :actionName="actionName")
        template(v-for="(contents, index) in reportList")
          tr.pointer-dblclick(@dblclick="onDbClick(contents)" :class="{'record-disabled':isDisabled(contents), selected : contents.isDisplayCell}")
            td.toggle-cell.toggle-icon-cell.no-right-border(@click.stop="toggleDetail(contents)" @dblclick.stop="")
              v-btn(flat icon)
                v-icon {{ contents.isDisplayCell ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            td
              template(v-if="isCapitalBlankDeliveryCompany(contents.primaryDeliveryCompanyId)")
                v-tooltip(top )
                  template(v-slot:activator="{ on }")
                    v-icon(v-on="on").warning-box-icon warning
                  span.whitespace-pre {{ $config.messages.Common.Warn.CapitalBlankDeliveryCompanyShort }}


              span {{ contents.companyName }}
            td {{ contents.boilerplateTitle }}
            td {{ contents.signatureTitle }}
            td.table-column-comment
              p.ma-0.table-comment {{ contents.comment }}
            td.text-sm-center {{ formatFullDateTime(contents.publishDateTime) }}
            td.text-sm-center {{ contents.publishUserName }}
            td.text-whitespace-pre.text-sm-center {{ formatFullDateTime(contents.faxSendDateTime) }} <br/> {{ contents.faxSendStatus }}
            td
              v-checkbox.justify-center.align-center(v-model="contents.selectTarget")
          tr(v-show="contents.isDisplayCell")
            td.inner-cell(:colspan="9")
              table.order-table
                thead(:class="{ selected : contents.isDisplayCell }")
                  tr
                    th(width="140px") 受付番号<br/>(Shipment)
                    th(width="120px") 積込日 / 納品日
                    th 積地
                    th 卸地
                    th(width="100px") 車両車格 / <br/>最大積載重量
                    th 運送会社 / 車番
                    th 乗務員名
                    th(width="110px") 備考
                    th 出力状態
                tbody
                  tr(v-for="(detail, index) in contents.cardViewHolderList")
                    td.text-whitespace-pre {{ getShimentNos(detail.targetCard.shipmentNos) | commaToNr }}
                    td.text-whitespace-pre.text-sm-center {{ getCombineDate(detail.targetCard.shipmentDate, detail.targetCard.deliveryDate) | commaToNr }}
                    td.text-whitespace-pre {{ detail.targetCard.loadingBaseNames | commaToNr }}
                    td.text-whitespace-pre {{ detail.targetCard.unloadingBaseNames | commaToNr }}
                    td.text-whitespace-pre.text-sm-center {{ detail.vehicleColumnDisp | commaToNr }}
                    td.text-whitespace-pre {{ detail.deliveryCompanyColumnDisp | commaToNr }}
                    td.text-whitespace-pre {{ detail.driverName | commaToNr }}
                    td
                      div
                        v-tooltip(top)
                          template(v-slot:activator="{ on }")
                            p(v-on="on").remark.whitespace-pre {{ detail.targetCard.remark }}
                          span.whitespace-pre {{ detail.targetCard.remark }}
                    td.text-whitespace-pre {{ detail.targetCard.dispatchReportStatusType }}
      template(v-else)
        tr
          td(:colspan="9") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import InfiniteScrollHandler from "@/mixin/InfiniteScrollHandler";
import ReportMetaSetting from "@/components/report/ReportMetaSetting";
import ReportMetaEditDialog from "@/components/report/ReportMetaEditDialog";
import ConbineUtils from "@/mixin/ConbineUtils";
import * as Enum from "@/assets/js/enum";

export default {
  /**
   * props
   * getListActionName：InfiniteScrollHandlerにて必須
   */
  props: {
    getListActionName: {
      type: String,
      default: "report/fetchTransportOrderList"
    }
  },
  mixins: [EventDispatcher, InfiniteScrollHandler, ConbineUtils],
  components: {
    ReportMetaSetting,
    ReportMetaEditDialog
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      reportList: "report/getList",
      getBoilerplateInfo: "boilerplate/getSelectInfo",
      getSignatureeInfo: "signature/getSelectInfo",
      isCapitalBlankDeliveryCompany: "report/isCapitalBlankDeliveryCompany"
    }),
    actionName: () => "postTransportOrderEditMeta"
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("report", {
      postReportBulkEditMeta: "postTransportOrderBulkEditMeta",
      fetchClearList: "fetchClearList"
    }),
    /**
     * Emit：定型文、署名一括変更
     */
    onClickEvent(condition) {
      // InfiniteScrollHandlerにsearchConditionsは設定されている。
      const searchCondition = this.searchConditions;
      this.postReportBulkEditMeta({ searchCondition, ...condition }).catch(error => {
        // mixin:EventDispatcherに情報を設定
        this.dispatchCommonEventWithParam(window.Common.Config.observer.onErrorResponse400, {
          message: error.response.data.message
        });
      });
    },
    /** Event：△▽による開閉 */
    toggleDetail(contents) {
      contents.isDisplayCell = !contents.isDisplayCell;
    },
    /** 変換：日付フォーマット */
    formatFullDateTime(datetime) {
      return window.Common.Util.getFullDateTimeIsoFormat(new Date(Date.parse(datetime)));
    },
    /** グレーアウト対象かどうか */
    isDisabled(item) {
      if (item.companyStatus === undefined) return false;
      return item.companyStatus === Enum.ActiveFlag.InActive.code;
    },
    /** 仮カードの場合Null→空に変更 */
    getShimentNos(item) {
      return item === null ? "" : item;
    },
    /** Event：テーブル行のダブルクリック */
    onDbClick(record) {
      this.$refs.ReportMetaEditDialog.openDialog(Object.assign({}, record));
    }
  },
  mounted() {
    this.fetchClearList();
  },
  destroyed() {
    this.fetchClearList();
  }
};
</script>

<style lang="stylus" scoped>
.order-table {
  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }
}

>>> .v-input--selection-controls__input {
  margin-right: 0;
}

.warning-box-icon {
  color: #FF5722;
}
</style>
