<template lang="pug">
  EditDialog(ref="EditDialog" title="支払区分" :disabledDelete="record.mainPaymentType" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md6)
            v-text-field(v-model="record.paymentCd" label="支払CD" :error-messages="getErrorMessages('paymentCd')")

          v-flex(xs12 md12)
            v-text-field(v-model="record.paymentTypeName" label="名称" :error-messages="getErrorMessages('paymentTypeName')")

          v-flex(xs12 md3)
            FinanceTypeSelect(:code.sync="record.financeType" :readonly="record.mainPaymentType" :errorHandlerMethod="getErrorMessages")

        v-layout(row wrap)
          v-flex(xs12 md12 v-if="record.mainPaymentType")
            div ※主要な支払区分の場合、会計種別の変更、支払区分の削除はできません。
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import FinanceTypeSelect from "@/components/common/select/FinanceTypeSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    FinanceTypeSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: this.initData()
    };
  },
  methods: {
    initData() {
      return {
        id: null,
        paymentCd: null,
        paymentTypeName: null,
        financeType: Enum.FinanceType.Normal.code
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
