export const Utils = {
  enumToList: obj => {
    return Object.keys(obj).map(value => {
      return obj[value];
    });
  },
  findLabel: (code, obj, defaultLabel) => {
    const find = Object.keys(obj)
      .filter(value => {
        return obj[value].code === code;
      })
      .pop();

    const def = defaultLabel !== undefined ? defaultLabel : "";

    if (find === undefined) {
      return def;
    } else {
      return obj[find].label;
    }
  }
};

export const OrderType = {
  BjiImport: {
    code: "10",
    label: "インポート"
  },
  Manual: {
    code: "20",
    label: "手入力"
  },
  Relay: {
    code: "30",
    label: "中継"
  },
  TempCard: {
    code: "40",
    label: "仮カード"
  }
};

export const ActiveFlag = {
  Active: {
    code: "10",
    label: "有効"
  },
  InActive: {
    code: "00",
    label: "無効"
  }
};

export const DisplayFlag = {
  Active: {
    code: true,
    label: "表示"
  },
  InActive: {
    code: false,
    label: "非表示"
  }
};

export const BjiTransportType = {
  Delivery: {
    code: "10",
    label: "納品"
  },
  Transfer: {
    code: "20",
    label: "移管"
  }
};

export const BjiWeightUnitType = {
  G: {
    code: "G",
    label: "g"
  },
  Kg: {
    code: "KG",
    label: "kg"
  },
  Tn: {
    code: "TN",
    label: "t"
  }
};

export const BjiBillingType = {
  Normal: {
    code: "1",
    label: "通常"
  },
  Special: {
    code: "2",
    label: "特別"
  }
};

export const ClientType = {
  Bji: {
    code: "10",
    label: "BJI"
  },
  General: {
    code: "20",
    label: "一般"
  }
};

export const ClientBranchType = {
  Import: {
    code: "10",
    label: "インポート"
  },
  Manual: {
    code: "20",
    label: "手入力"
  }
};

export const ContractType = {
  Employee: {
    code: "10",
    label: "直接雇用"
  },
  Exclusive: {
    code: "20",
    label: "専属"
  },
  Spot: {
    code: "30",
    label: "スポット"
  }
};

export const DispatchType = {
  Normal: {
    code: "00",
    label: "通常"
  },
  NightLoading: {
    code: "10",
    label: "宵積み"
  },
  NightLoadingDest: {
    code: "11",
    label: "宵積み先"
  },
  DayExceed: {
    code: "20",
    label: "日跨ぎ"
  },
  DayExceedRelay: {
    code: "21",
    label: "日跨ぎ中継"
  },
  DayExceedDest: {
    code: "22",
    label: "日跨ぎ先"
  },
  NightLoadingAndDayExceed: {
    code: "30",
    label: "日跨ぎ+宵積み"
  }
};

export const DistanceType = {
  Short: {
    code: "10",
    label: "地場"
  },
  Long: {
    code: "20",
    label: "中長距離"
  }
};

export const HandlingUnitType = {
  Item: {
    code: "10",
    label: "バラ"
  },
  Palette: {
    code: "20",
    label: "パレット"
  }
};

export const ImportResultType = {
  SkippedHasError: {
    code: "00",
    label: "スキップ"
  },
  Registered: {
    code: "10",
    label: "登録"
  },
  Updated: {
    code: "20",
    label: "更新"
  },
  Skipped: {
    code: "40",
    label: "スキップ"
  }
};

export const ImportTargetType = {
  Import: {
    code: "10",
    label: "取込対象"
  },
  Delete: {
    code: "20",
    label: "削除対象"
  }
};

export const LiftType = {
  Reach: {
    code: "10",
    label: "リーチ"
  },
  Counter: {
    code: "20",
    label: "カウンター"
  },
  Both: {
    code: "30",
    label: "リーチ・カウンター両方"
  },
  UnManaged: {
    code: "90",
    label: "管理外"
  },
  None: {
    code: "00",
    label: "なし"
  }
};

export const MailStatusType = {
  Unsent: {
    code: "00",
    label: "未配信"
  },
  Sent: {
    code: "10",
    label: "配信済"
  },
  Read: {
    code: "20",
    label: "開封済"
  },
  Confirm: {
    code: "30",
    label: "承認済"
  },
  Processed: {
    code: "40",
    label: "処理済"
  },
  SendFailed: {
    code: "90",
    label: "送信失敗"
  }
};

export const MailType = {
  First: {
    code: "10",
    label: "第１運行"
  },
  All: {
    code: "20",
    label: "全運行"
  }
};

export const RoleType = {
  None: {
    code: 0,
    label: "なし"
  },
  Readable: {
    code: 1,
    label: "読み取り可"
  },
  editable: {
    code: 2,
    label: "編集可"
  }
};

export const SexType = {
  Female: {
    code: "1",
    label: "女性"
  },
  Male: {
    code: "2",
    label: "男性"
  },
  None: {
    code: "0",
    label: "不詳"
  }
};

export const ShiftType = {
  Day: {
    code: "10",
    label: "日勤",
    icon: "wb_sunny",
    color: "deep-orange darken-2",
    lightColor: "deep-orange lighten-2",
    shift: true
  },
  Night: {
    code: "20",
    label: "夜勤",
    icon: "brightness_3",
    color: "yellow darken-2",
    lightColor: "yellow lighten-2",
    shift: true
  },
  Rest: {
    code: "00",
    label: "非番",
    icon: "clear",
    color: "red lighten-2",
    lightColor: "red lighten-3",
    shift: false
  }
};

export const PrimaryVehicleType = {
  IncreaseTon: {
    id: 1,
    code: "10",
    label: "贈トン"
  },
  Trailer: {
    id: 2,
    code: "20",
    label: "トレーラー"
  },
  Container: {
    id: 3,
    code: "30",
    label: "コンテナ"
  },
  Middle: {
    id: 4,
    code: "40",
    label: "中型"
  },
  MiddleLarge: {
    id: 5,
    code: "50",
    label: "大中"
  }
};

export const VehicleFloorType = {
  Low: {
    code: "10",
    label: "低床"
  },
  High: {
    code: "20",
    label: "高床"
  }
};

export const VehicleMaintenanceType = {
  CarInspection: {
    code: "10",
    label: "車検"
  },
  MonthInspection: {
    code: "20",
    label: "３ヶ月点検"
  },
  Repair: {
    code: "30",
    label: "修理"
  }
};

export const DispatchTempCardType = {
  EmptyForwarding: {
    code: "10",
    label: "空車回送"
  },
  Temporary: {
    code: "20",
    label: "仮カード"
  },
  TemporaryDayExceed: {
    code: "30",
    label: "仮カード（日跨ぎ）"
  }
};

export const SpecifiedTimeType = {
  None: {
    code: "00",
    label: "指定なし"
  },
  BelongMaster: {
    code: "10",
    label: "マスタ指定"
  },
  Specified: {
    code: "20",
    label: "直接指定"
  },
  MorningDesignation: {
    code: "30",
    label: "朝指定"
  },
  NoonDesignation: {
    code: "40",
    label: "昼指定"
  },
  AfterNoonDesignation: {
    code: "50",
    label: "午後指定"
  }
};

export const DeleteType = {
  Exclusion: {
    code: "10",
    label: "取込時除外"
  },
  Optional: {
    code: "20",
    label: "任意削除"
  }
};

export const CardMarkType = {
  Clear: {
    code: "00",
    label: "クリア"
  },
  MarkOne: {
    code: "10",
    label: "マーク1"
  },
  MarkTwo: {
    code: "20",
    label: "マーク2"
  },
  MarkThree: {
    code: "30",
    label: "マーク3"
  },
  MarkFour: {
    code: "40",
    label: "マーク4"
  }
};

export const UseType = {
  Use: {
    code: true,
    label: "利用する"
  },
  UnUse: {
    code: false,
    label: "利用しない"
  }
};

export const ExistType = {
  Use: {
    code: true,
    label: "あり"
  },
  UnUse: {
    code: false,
    label: "なし"
  }
};

export const AccountLockType = {
  Use: {
    code: true,
    label: "ロック"
  },
  UnUse: {
    code: false,
    label: "正常"
  }
};

// 受注の配車状況検索が重いので、暫定対応としてデフォルト選択なし
export const DispatchStatusList = {
  None: {
    code: "00",
    label: "未配車",
    default: false
  },
  Dispatched: {
    code: "10",
    label: "配車済",
    default: false
  },
  Delivered: {
    code: "20",
    label: "配送済",
    default: false
  },
  Relayed: {
    code: "30",
    label: "中継済",
    default: false
  }
};

export const operatingConditionList = {
  isNotFirst: {
    code: "10",
    label: "第1運行未設定"
  },
  isNightLoading: {
    code: "20",
    label: "宵積みあり"
  }
};

export const ArrivalStatusType = {
  None: {
    code: "00",
    label: "未着 - 時間内見込"
  },
  Blue: {
    code: "10",
    label: "到着 - 時間内見込"
  },
  Green: {
    code: "20",
    label: "全着 - 時間内"
  },
  Yellow: {
    code: "30",
    label: "遅延見込"
  },
  Orange: {
    code: "40",
    label: "到着 - 遅延"
  },
  Red: {
    code: "50",
    label: "未着 - 遅延"
  }
};

export const ReportOutputStatusType = {
  NOT_OUTPUT: {
    code: "00",
    label: "未出力"
  },
  COMPLETED: {
    code: "10",
    label: "出力済"
  },
  MODIFIED: {
    code: "20",
    label: "変更あり"
  }
};

export const SendStatusType = {
  Unsent: {
    code: "00",
    label: "送信準備中"
  },
  Sending: {
    code: "10",
    label: "送信中"
  },
  Sent: {
    code: "20",
    label: "送信完了"
  },
  Error: {
    code: "30",
    label: "送信エラー"
  }
};

export const CarryWeightType = {
  CarryWeightType10: {
    code: "10",
    from: 0,
    to: 11000,
    label: "~11,000Kg"
  },
  CarryWeightType20: {
    code: "20",
    from: 11000,
    to: 13000,
    label: "11,000~13,000Kg"
  },
  CarryWeightType30: {
    code: "30",
    from: 13000,
    to: 14000,
    label: "13,000~14,000Kg"
  },
  CarryWeightType40: {
    code: "40",
    from: 14000,
    to: 99000,
    label: "14,000Kg~"
  }
};

export const FunctionList = {
  ImportOrder: {
    code: "ImportOrder",
    label: "受注インポート"
  },
  OrderList: {
    code: "OrderList",
    label: "受注一覧"
  },
  OrderDeleteList: {
    code: "OrderDeleteList",
    label: "受注一覧(取込時削除分)"
  },
  ImportShipmentNo: {
    code: "ImportShipmentNo",
    label: "シップメントNo取込み"
  },
  VehicleList: {
    code: "VehicleList",
    label: "車両マスタ"
  },
  VehicleTypeList: {
    code: "VehicleTypeList",
    label: "車格マスタ"
  },
  VehicleGarageList: {
    code: "VehicleGarageList",
    label: "車庫マスタ"
  },
  VehicleCarryWeightList: {
    code: "VehicleCarryWeightList",
    label: "車格積載重量マスタ"
  },
  DriverList: {
    code: "DriverList",
    label: "乗務員マスタ"
  },
  UserList: {
    code: "UserList",
    label: "利用者マスタ"
  },
  DriverSchedule: {
    code: "DriverSchedule",
    label: "乗務員スケジュール"
  },
  OperationResource: {
    code: "OperationResource",
    label: "運行可能リソース"
  },
  LoadingBaseList: {
    code: "LoadingBaseList",
    label: "積地マスタ"
  },
  UnloadingBaseList: {
    code: "UnloadingBaseList",
    label: "卸地マスタ"
  },
  ClientBranch: {
    code: "ClientBranchList",
    label: "荷主枝番マスタ"
  },
  DeliveryCompany: {
    code: "DeliveryCompanyList",
    label: "配送委託先会社マスタ"
  },
  AreaList: {
    code: "AreaList",
    label: "エリアマスタ"
  },
  BoilerplateList: {
    code: "BoilerplateList",
    label: "定型文マスタ"
  },
  SignatureList: {
    code: "SignatureList",
    label: "署名マスタ"
  },
  ExportTemplateList: {
    code: "ExportTemplateList",
    label: "テンプレートマスタ"
  },
  ItemTypeList: {
    code: "ItemTypeList",
    label: "積荷種別マスタ"
  },
  BillingTypeList: {
    code: "BillingTypeList",
    label: "請求区分マスタ"
  },
  RoleList: {
    code: "RoleList",
    label: "権限管理マスタ"
  },
  GeneralClientList: {
    code: "GeneralClientList",
    label: "荷主マスタ"
  },
  TariffList: {
    code: "TariffList",
    label: "タリフマスタ"
  },
  TariffImport: {
    code: "TariffImport",
    label: "タリフマスタインポート"
  },
  BillingItemList: {
    code: "BillingItemList",
    label: "請求項目マスタ"
  },
  PaymentItemList: {
    code: "PaymentItemList",
    label: "支払項目マスタ"
  },
  BaseDistanceList: {
    code: "BaseDistanceList",
    label: "距離マスタ"
  },
  BaseDistanceImport: {
    code: "BaseDistanceImport",
    label: "距離マスタインポート"
  },
  PaymentTariffTypeList: {
    code: "PaymentTariffTypeList",
    label: "支払タリフ区分マスタ"
  },
  PaymentTariffRuleList: {
    code: "PaymentTariffRuleList",
    label: "支払タリフルールマスタ"
  },
  PaymentTariffRuleImport: {
    code: "PaymentTariffRuleImport",
    label: "支払タリフルールインポート"
  },
  FixedBillingRateList: {
    code: "FixedBillingRateList",
    label: "固定請求料金マスタ"
  },
  FixedBillingRateImport: {
    code: "FixedBillingRateImport",
    label: "固定請求料金マスタインポート"
  },
  FixedPaymentRateList: {
    code: "FixedPaymentRateList",
    label: "固定支払料金マスタ"
  },
  FixedPaymentRateImport: {
    code: "FixedPaymentRateImport",
    label: "固定支払料金マスタインポート"
  },
  PaymentTypeList: {
    code: "PaymentTypeList",
    label: "支払区分マスタ"
  },
  DispatchSchedule: {
    code: "DispatchSchedule",
    label: "配車表"
  },
  DispatchMailList: {
    code: "DispatchMailList",
    label: "配車メール"
  },
  DynamicTracking: {
    code: "DynamicTracking",
    label: "動態管理"
  },
  ClientLoadingBaseClass: {
    code: "ClientLoadingBaseClassList",
    label: "荷主積地分類マスタ"
  },
  ClientUnloadingBaseClass: {
    code: "ClientUnloadingBaseClassList",
    label: "荷主卸地分類マスタ"
  },
  ReportServiceContact: {
    code: "ServiceContactReport",
    label: "車番連絡表出力"
  },
  TransportOrderReport: {
    code: "TransportOrderReport",
    label: "輸送依頼(指示)書出力"
  },
  ExportOrderAndCard: {
    code: "ExportOrderAndCard",
    label: "CSV出力(伝票)"
  },
  BillingAndPayment: {
    code: "BillingAndPayment",
    label: "請求と支払管理"
  },
  ExpensesImport: {
    code: "ExpensesImport",
    label: "高速料金インポート"
  },
  ExportBillingStatement: {
    code: "ExportBillingStatement",
    label: "請求書出力"
  },
  ExportPaymentStatement: {
    code: "ExportPaymentStatement",
    label: "支払書出力"
  },
  ExportPaymentPrompt: {
    code: "ExportPaymentPrompt",
    label: "支払書速報出力"
  },
  DispatchPatternList: {
    code: "DispatchPatternList",
    label: "配車パターンマスタ"
  }
};

export const FaxTargetFlag = {
  Unset: {
    code: "00",
    label: "未設定"
  },
  Target: {
    code: "20",
    label: "送信対象"
  },
  Exclusion: {
    code: "10",
    label: "送信対象外"
  }
};

export const SaleTaxTargetType = {
  Addition: {
    code: "10",
    label: "加算対象"
  },
  Added: {
    code: "00",
    label: "加算済対象"
  }
};

export const SpecialPaymentType = {
  Normal: {
    code: "00",
    label: "通常"
  },
  Special: {
    code: "10",
    label: "特別支払"
  }
};

export const FinanceType = {
  None: {
    code: "00",
    label: "なし"
  },
  Normal: {
    code: "10",
    label: "通常"
  },
  Special: {
    code: "20",
    label: "特殊"
  }
};

export const BillingOutputUnitType = {
  Client: {
    code: "10",
    label: "荷主"
  },
  ClientBranch: {
    code: "20",
    label: "荷主枝番"
  }
};

export const CutOffDateType = {
  MiddleDay: {
    code: "10",
    label: "10日"
  },
  Fifteen: {
    code: "15",
    label: "15日"
  },
  EndMonth: {
    code: "20",
    label: "20日"
  },
  EndNextMonth: {
    code: "30",
    label: "当月末"
  }
};

export const BaseDateType = {
  ShippingDate: {
    code: "10",
    label: "積込日"
  },
  DeliveryDate: {
    code: "20",
    label: "納品日"
  }
};

export const PriceCalculationType = {
  INACTIVE: {
    code: "00",
    label: "該当なし"
  },
  FIXED_BILLING: {
    code: "10",
    label: "固定請求料金"
  },
  BILLING_TARIFF: {
    code: "20",
    label: "請求タリフ"
  },
  FIXED_PAYMENT: {
    code: "30",
    label: "固定支払料金"
  },
  PAYMENT_TARIFF: {
    code: "40",
    label: "支払タリフ"
  }
};

export const BillingStatusType = {
  PENDING: {
    code: "00",
    label: "未確定"
  },
  TEMPORARY_CONFIRMED: {
    code: "10",
    label: "仮確定"
  },
  PUBLISHED: {
    code: "20",
    label: "発行済"
  },
  CONFIRMED: {
    code: "30",
    label: "確定済"
  }
};

export const PaymentStatusType = {
  PENDING: {
    code: "00",
    label: "未確定"
  },
  TEMPORARY_CONFIRMED: {
    code: "10",
    label: "仮確定"
  },
  PUBLISHED: {
    code: "20",
    label: "発行済"
  },
  CONFIRMED: {
    code: "30",
    label: "確定済"
  }
};

export const FinanceControlCodeType = {
  BASIC_RATE: {
    code: "BASIC_RATE",
    label: "基本運賃"
  },
  TOLL: {
    code: "TOLL",
    label: "高速代"
  }
};

export const TargetReportType = {
  PaymentStatement: {
    code: "10",
    label: "支払書"
  },
  PaymentPrompt: {
    code: "20",
    label: "速報"
  }
};

export const DispatchPatternDecisionType = {
  Base: {
    code: "10",
    label: "拠点"
  },
  Area: {
    code: "20",
    label: "エリア"
  }
};

export const CheckOnOffType = {
  On: {
    code: true,
    label: "チェックあり"
  },
  Off: {
    code: false,
    label: "チェックなし"
  }
};
