import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  generalClientList: []
};

const getters = {};

const actions = {
  // 荷主一覧を検索
  async fetchAllGeneralClient({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllGeneralClient, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllGeneralClientData");
        }
        commit("appendAllGeneralClientData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => Promise.reject(reason));
  },
  async fetchSelectGeneralClient({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;

    return $axios
      .get(axiosBase.getUrls().getAllGeneralClientForSelect, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllGeneralClientData");
        }
        commit("appendAllGeneralClientData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  // BJIインポート用の荷主枝番を取得
  async fetchAllBjiImportBranchType({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getAllBjiImportBranchType).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 荷主枝番を荷主IDを元に取得
  // CCBJI(id=1)の場合、インポート用の荷主枝番は除外して取得
  async fetchAllBranchTypeByClientId({ commit, state }, clientId) {
    return $axios.get(axiosBase.getUrls().getAllBranchTypeByClientId + "/" + clientId).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 荷主枝番を荷主IDを元に取得
  async fetchAllBranchTypeByClientIdAll({ commit, state }, clientId) {
    return $axios.get(axiosBase.getUrls().getAllBranchTypeByClientIdAll + "/" + clientId).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 選択された荷主情報の取得
  async fetchClient({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getClient + "/" + id).then(value => {
      return Promise.resolve(value.data.data);
    });
  },
  // 実績出力
  async exportClient({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportClient, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllGeneralClientData(state) {
    state.generalClientList = [];
  },
  appendAllGeneralClientData(state, data) {
    if (data != null) {
      state.generalClientList = state.generalClientList.concat(data);
    }
  },
  writeAllGeneralClientData(state, data) {
    state.generalClientList = data == null ? [] : data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
