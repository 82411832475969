export default {
  methods: {
    getHistoryDiffCssClass(status) {
      switch (status) {
        case "INSERT":
          return "insert";
        case "CHANGE":
          return "change";
        case "DELETE":
          return "delete";
        default:
          return "";
      }
    }
  }
};
