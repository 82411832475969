import EventDispatcher from "@/mixin/EventDispatcher";

export default {
  mixins: [EventDispatcher],
  props: {
    eventName: {
      type: String,
      required: false,
      default() {
        return "scroll";
      }
    },
    eventTriggerTarget: {
      required: false,
      default() {
        return window;
      }
    },
    getListActionName: {
      type: String,
      required: true
    },
    activeTab: {
      required: false,
      default() {
        return null;
      }
    },
    triggerEnable: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      pageCount: 0,
      searchConditions: {},
      fetchActionParams: {},
      progress: false
    };
  },
  methods: {
    async loadData() {
      this.progress = true;
      return this.$store
        .dispatch(
          this.getListActionName,
          Object.assign(this.searchConditions, {
            ...this.fetchActionParams,
            ...{ pageCount: this.pageCount }
          })
        )
        .finally(() => {
          //一覧の検索やクリアボタンローディング制御の為にイベント発行。
          this.dispatchCommonEvent(window.Common.Config.observer.onPagingListTableAjaxCompleted);
          this.progress = false;
        });
    },
    resetPageCount() {
      this.pageCount = 0;
    },
    addPageCount() {
      this.pageCount++;
    },
    setPageCount(cnt) {
      this.pageCount = cnt;
    },
    setSearchConditions(condition) {
      this.searchConditions = condition;
    },
    getProgress() {
      return this.progress;
    },
    /**
     * スクロール操作を監視して、末尾まで行くと
     * ページング用変数の値をカウントアップ
     */
    handleScroll() {
      if (this.activeTab == null || this.activeTab === true) {
        if (this.getScrollBottom() < 1 && !this.progress && this.triggerEnable) {
          this.pageCount++;
          this.loadData();
        }
      }
    },
    /**
     * ブラウザのスクロールバーの位置を取得
     * @returns {number}
     */
    getScrollBottom() {
      const body = window.document.body;
      const html = window.document.documentElement;
      const scrollTop = body.scrollTop || html.scrollTop;
      return html.scrollHeight - html.clientHeight - scrollTop;
    }
  },
  mounted() {
    this.eventTriggerTarget.addEventListener(this.eventName, this.handleScroll);
  },
  destroyed() {
    this.eventTriggerTarget.removeEventListener(this.eventName, this.handleScroll);
  }
};
