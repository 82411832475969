<template lang="pug">
  HistoryDialog(ref="HistoryDialog" title="変更履歴"
    :leftData="paymentHistoryList" :oldUpdateUser="oldUpdateUser" :newUpdateUser="newUpdateUser" @incrementPageCount="incrementHistoryTablePageCount"
    @selectLine="selectLine" @terminate="terminate" load-url="finance/fetchPaymentHistoryList" :targetId="targetId")
    template(v-slot:rightTop)
      PaymentCardHistoryTable(:diffArrayData="newRows" :paymentBasicPrice="newPaymentBasicPrice")

    template(v-slot:rightBottom)
      PaymentCardHistoryTable(:diffArrayData="oldRows" :paymentBasicPrice="oldPaymentBasicPrice")

</template>

<script>
import HistoryDialog from "@/components/common/dialog/HistoryDialog";
import PaymentCardHistoryTable from "@/components/finance/PaymentCardHistoryTable";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    HistoryDialog,
    PaymentCardHistoryTable
  },
  data() {
    return {
      targetId: null
    };
  },
  computed: {
    ...mapGetters({
      paymentHistoryList: "finance/paymentCardHistoryList",
      paymentHistoryDiff: "finance/paymentCardHistoryDiff"
    }),
    newRows: {
      get() {
        return this.paymentHistoryDiff != null ? this.paymentHistoryDiff.newRows : null;
      }
    },
    oldRows: {
      get() {
        return this.paymentHistoryDiff != null ? this.paymentHistoryDiff.oldRows : null;
      }
    },
    newPaymentBasicPrice: {
      get() {
        return this.paymentHistoryDiff != null ? this.paymentHistoryDiff.newPaymentBasicPrice : null;
      }
    },
    oldPaymentBasicPrice: {
      get() {
        return this.paymentHistoryDiff != null ? this.paymentHistoryDiff.oldPaymentBasicPrice : null;
      }
    },
    oldUpdateUser: {
      get() {
        return this.paymentHistoryDiff != null ? this.paymentHistoryDiff.oldUpdateUser : null;
      }
    },
    newUpdateUser: {
      get() {
        return this.paymentHistoryDiff != null ? this.paymentHistoryDiff.newUpdateUser : null;
      }
    }
  },
  methods: {
    ...mapMutations({
      incrementHistoryTablePageCount: "finance/incrementPaymentHistoryTablePageCount",
      clearHistoryTablePageCount: "finance/clearPaymentHistoryTablePageCount"
    }),
    ...mapActions("finance", {
      fetchPaymentHistoryList: "fetchPaymentHistoryList",
      fetchPaymentDiff: "fetchPaymentDiff",
      clearHistoryData: "clearPaymentHistoryData"
    }),
    openDialog(cardId) {
      this.clearHistoryTablePageCount();
      this.fetchPaymentHistoryList(cardId).then(value => {
        this.$refs.HistoryDialog.initialize();
      });
      this.$refs.HistoryDialog.openDialog();
    },
    closeDialog() {
      this.$refs.HistoryDialog.closeDialog();
    },
    selectLine(data) {
      this.fetchPaymentDiff(data.id);
    },
    terminate() {
      this.clearHistoryData();
    }
  }
};
</script>
