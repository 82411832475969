<template lang="pug">
  v-menu(v-model="display" :position-x="x",:position-y="y")
    v-list(dense)
      v-list-tile(@click="updateTag")
        v-list-tile-content 名称変更
      v-list-tile(@click="deleteTag")
        v-list-tile-content 一括削除
</template>

<script>
export default {
  data() {
    return {
      display: false,
      x: 0,
      y: 0,
      item: {}
    };
  },
  computed: {},
  methods: {
    open(data, event) {
      this.item = data;
      this.display = false;
      this.x = event.x + 20;
      this.y = event.y - 20;
      this.display = true;
    },
    updateTag() {
      this.$emit("updateTag", this.item);
    },
    deleteTag() {
      this.$emit("deleteTag", this.item);
    }
  }
};
</script>
