<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      VehicleCarryWeightFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              VehicleCarryWeightTable(
                ref="VehicleCarryWeightTable"
                :prop-header="vehicleCarryWeightTableHeader"
                :prop-data="vehicleCarryWeightList"
                :getListActionName="getListActionName"
                detail-url="vehicle/fetchVehicleCarryWeight"
                @open-detail="openDetail"
                :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-left {{ body.vehicleTypeName }}
                  td.text-xs-right(v-if="body.carryWeight != 0") {{ body.carryWeight | addComma }}kg
                  td(v-else)
                  td.text-xs-right(v-if="body.maxCarryWeight != 0") {{ body.maxCarryWeight | addComma }}kg
                  td(v-else)
                  td.text-xs-right {{ body.displayOrder }}

    VehicleCarryWeightEditDialog(ref="VehicleCarryWeightEditDialog" :postUrl="$config.urls.postEditVehicleCarryWeight" :deleteUrl="$config.urls.deleteVehicleCarryWeight" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import VehicleCarryWeightEditDialog from "@/components/management/vehicle-carry-weight/VehicleCarryWeightEdit";
import VehicleCarryWeightTable from "@/components/common/PagingListTable";
import VehicleCarryWeightFilterConditions from "@/components/management/vehicle-carry-weight/VehicleCarryWeightFilterConditions";

import _ from "lodash";

export default {
  components: {
    VehicleCarryWeightEditDialog,
    VehicleCarryWeightTable,
    VehicleCarryWeightFilterConditions
  },
  filters: {
    addComma: function (val) {
      return val.toLocaleString();
    }
  },
  data() {
    return {
      searchCond: {}
    };
  },
  computed: {
    getListActionName: {
      get() {
        return "vehicle/fetchAllVehicleCarryWeight";
      }
    },
    vehicleCarryWeightList: {
      get() {
        if (_.isNil(this.$store.state.vehicle.vehicleCarryWeightList)) return [];
        return this.$store.state.vehicle.vehicleCarryWeightList;
      }
    },
    vehicleCarryWeightTableHeader: {
      get() {
        return [
          { text: "車格", value: "vehicleTypeName", centerAlign: "true" },
          { text: "積載重量", value: "carryWeightDisp", centerAlign: "true" },
          { text: "最大積載重量", value: "maxCarryWeightDisp", centerAlign: "true" },
          { text: "表示順", value: "displayOrder", centerAlign: "true" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.VehicleCarryWeightEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.VehicleCarryWeightEditDialog.openDialog(item);
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     **/
    itemUpdated(response, data) {
      this.$refs.VehicleCarryWeightTable.resetPageCount();
      this.loadListData();
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.VehicleCarryWeightTable.loadData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.VehicleCarryWeightTable.setSearchConditions(searchCond);
      this.$refs.VehicleCarryWeightTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>
