import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  roleList: []
};

const getters = {
  getList: state => {
    return state.roleList;
  }
};

const actions = {
  async fetchAll({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllRoleList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllRoleData");
        }
        commit("appendAllRoleData", value.data.data);
      });
  },
  async fetchAllRole({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getAllRoleList).then(value => {
      commit("writeAllRole", value.data.data);
    });
  },
  async fetchRole({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getRole + id).then(value => {
      return value.data.data;
    });
  },
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportUserRole, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllRoleData(state) {
    state.roleList = [];
  },
  appendAllRoleData(state, data) {
    if (data != null) {
      state.roleList = state.roleList.concat(data);
    }
  },
  writeAllRole(state, data) {
    state.roleList = data == null ? [] : data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
