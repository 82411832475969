<template lang="pug">
  EditDialog(ref="EditDialog" title="出力情報" width="60%" :recordId="detail.id" :loadingSave="loading.save" @save="onSave" :displayDeleteButton="false")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          template(v-if="detail.clientId")
            v-flex(xs12 md6)
              v-text-field(label="荷主" :value="detail.clientName" box readonly)
            v-flex(xs12 md6)
              v-text-field(label="荷主枝番" :value="detail.clientBranchName" box readonly)
          template(v-if="detail.primaryDeliveryCompanyId")
            v-flex(xs12 md6)
              v-text-field(label="1次委託先会社" :value="detail.companyName" box readonly)
        v-layout(row wrap)
          v-flex(xs12 md6)
            SelectBoilerplate(:title="labelName.boilerplate" :code.sync="detail.boilerplateId" :label.sync="detail.boilerplateTitle" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="boilerplateId")
          v-flex(xs12 md6)
            SelectSignature(:title="labelName.signature" :code.sync="detail.signatureId" :label.sync="detail.signatureTitle" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="signatureId")
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-textarea(v-model="detail.comment" :label="labelName.comment" :error-messages="getErrorMessages('comment')" maxlength=1000)
</template>

<script>
import { mapActions } from "vuex";
import ApiHandler from "@/mixin/ApiHandler";
import EventDispatcher from "@/mixin/EventDispatcher";
import EditDialog from "@/components/common/dialog/EditDialog";
import SelectMetaList from "@/components/report/SelectMetaList";
import SelectBoilerplate from "@/components/report/SelectBoilerplate";
import SelectSignature from "@/components/report/SelectSignature";

export default {
  props: {
    // 登録時のStore Actions reportメソッド名
    actionName: {
      type: String,
      requird: true,
      default: () => ""
    }
  },
  components: {
    EditDialog,
    SelectMetaList,
    SelectBoilerplate,
    SelectSignature
  },
  mixins: [ApiHandler, EventDispatcher],
  data: () => ({
    // 更新情報
    detail: {}
  }),
  computed: {
    // ラベル名
    labelName: () => ({ boilerplate: "定型文", signature: "署名", comment: "手書きメモ" })
  },
  methods: {
    /** Action：Vuex */
    ...mapActions({
      postReportEditMeta(dispatch, payload) {
        return dispatch("report/" + this.actionName, payload);
      }
    }),
    /**
     * Event：ダイアログを開く
     * ※親→子（このコンポーネント）→孫（slot利用）すべて参照利用。
     */
    openDialog(detail) {
      // mixin:ApiHandlerに情報を設定
      this.errors = {};
      this.detail = this.initData().detail;
      // 選択された行情報を取得/設定
      this.detail = detail;
      this.$refs.EditDialog.openDialog();
    },
    /**
     * 初期データ
     */
    initData() {
      return {
        detail: {
          boilerplateTitle: null,
          boilerplateId: null,
          signatureTitle: null,
          signatureId: null
        }
      };
    },
    /**
     * 保存：署名・定型文・手書きメモ
     */
    async onSave() {
      this.loading.save = true;
      const refsDialog = this.$refs.EditDialog;
      // mixin:ApiHandler.saveRecordを利用するとcommit処理がキャンセルされるため利用しない。
      await this.postReportEditMeta(this.detail)
        .then(() => {
          this.errors = {};
          refsDialog.closeDialog();
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            // mixin:ApiHandlerに情報を設定
            this.setErrorMessages(error);
          } else {
            // mixin:EventDispatcherに情報を設定
            this.dispatchCommonEventWithParam(window.Common.Config.observer.onErrorResponse400, {
              message: error.response.data.message
            });
          }
        })
        .finally(() => {
          this.loading.save = false;
        });
    }
  },
  beforeMount() {
    this.detail = this.initData().detail;
  }
};
</script>
