<template lang="pug">
  div.one-char-chip.vehicle-color(:class="cssClass")
    span {{ label }}
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: () => ""
    },
    code: {
      type: String,
      required: false,
      default: () => ""
    }
  },
  computed: {
    cssClass: {
      get() {
        return "vehicle-color-" + this.code;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
$size = 24px;

.one-char-chip {
  white-space: pre-wrap;
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: 50%;
  text-align: center;
  line-height: $size;
  overflow: hidden;
}
</style>
