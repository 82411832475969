<template lang="pug">
  v-snackbar(:value="display" bottom color="success" auto-height vertical)
    span {{ text }}
    v-btn(color="white" flat icon @click="closeSnack")
      v-icon(flat icon) close
</template>

<script>
export default {
  data() {
    return {
      display: false,
      text: ""
    };
  },
  methods: {
    openSnack(msg) {
      this.text = msg;
      this.display = true;
    },
    closeSnack() {
      this.display = false;
    }
  }
};
</script>
