<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="テンプレート名" v-model="conditions.templateName")
        v-flex(md2)
          v-text-field(label="出力対象" v-model="conditions.outputTarget")
        v-flex(md2)
          RoleSelect(:label.sync="conditions.roleName" :code.sync="conditions.roleId" :errorHandlerMethod="getErrorMessages" :clearable="true")

</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import RoleSelect from "@/components/common/select/RoleSelect";

export default {
  components: {
    SearchConditions,
    RoleSelect
  },
  methods: {
    getErrorMessages() {
      return "";
    },
    /**
     * Emit：検索条件 変更
     */
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    /**
     * 初期設定：子コンポーネント情報
     */
    initSearchCond() {
      return {
        conditions: {
          templateName: "",
          outputTarget: "",
          roleId: null
        }
      };
    }
  }
};
</script>
