<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md3)
          v-card
            v-card-title 基本情報
            v-card-text
              v-layout(wrap)
                v-flex(md6)
                  VehicleTypeSelect(:code.sync="conditions.vehicleTypeId" :clearable="true")

                v-flex(md6)
                  VehicleOptionMultiSelect(:code.sync="conditions.optionIdList" :clearable="true")
                v-flex(md6)
                  v-text-field(label="車庫エリア" v-model="conditions.vehicleGarageName")
                v-flex(md6)
                  v-text-field(label="所属企業" v-model="conditions.deliveryCompanyName")
                v-flex(md6)
                  StatusSelect(:code.sync="conditions.status" :clearable="true")
        v-flex(md3)
          v-card
            v-card-title 車番(ヘッド)
            v-card-text
              v-layout(wrap)
                v-flex(md3)
                  v-text-field(label="4ケタ" v-model="conditions.entryNumber")
                v-flex(md3)
                  v-text-field(label="地区" v-model="conditions.entryLocation")
                v-flex(md3)
                  v-text-field(label="かな" v-model="conditions.entryKana")
                v-flex(md3)
                  v-text-field(label="枝番" v-model="conditions.entryControlNumber")
            v-card-title 車番(シャーシ)
            v-card-text
              v-layout(wrap)
                v-flex(md3)
                  v-text-field(label="4ケタ" v-model="conditions.chassisNumber")
                v-flex(md3)
                  v-text-field(label="地区" v-model="conditions.chassisLocation")
                v-flex(md3)
                  v-text-field(label="かな" v-model="conditions.chassisKana")
                v-flex(md3)
                  v-text-field(label="枝番" v-model="conditions.chassisControlNumber")

        v-flex(md6)
          v-card
            v-card-title 詳細情報
            v-card-text
              v-layout(wrap)
                v-flex(md2)
                  v-text-field.text-align-right(label="積載重量(最小)" v-model="conditions.carryWeightMin" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md2)
                  v-text-field.text-align-right(label="積載重量(最大)" v-model="conditions.carryWeightMax" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md4)
                  MonthPicker(pickerLabel="初年度登録(開始)" :inputDate.sync="conditions.firstRegisteredMin" initDate="" :clearable="true")
                v-flex(md4)
                  MonthPicker(pickerLabel="初年度登録(終了)" :inputDate.sync="conditions.firstRegisteredMax" initDate="" :clearable="true")
              v-layout(wrap)
                v-flex(md3)
                  v-text-field.text-align-right(label="車幅(最小)" v-model="conditions.widthMin" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md3)
                  v-text-field.text-align-right(label="車幅(最大)" v-model="conditions.widthMax" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md3)
                  v-text-field.text-align-right(label="車高(最小)" v-model="conditions.heightMin" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md3)
                  v-text-field.text-align-right(label="車高(最大)" v-model="conditions.heightMax" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md3)
                  v-text-field.text-align-right(label="全長(最小)" v-model="conditions.lengthMin" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md3)
                  v-text-field.text-align-right(label="全長(最大)" v-model="conditions.lengthMax" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md3)
                  v-text-field.text-align-right(label="地上高(最小)" v-model="conditions.groundHeightMin" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
                v-flex(md3)
                  v-text-field.text-align-right(label="地上高(最大)" v-model="conditions.groundHeightMax" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";
import FloorTypeSelect from "@/components/common/select/FloorTypeSelect";
import VehicleOptionMultiSelect from "@/components/common/select/VehicleOptionMultiSelect";
import MonthPicker from "@/components/common/MonthPicker";
import StatusSelect from "@/components/common/select/StatusSelect";

export default {
  components: {
    SearchConditions,
    VehicleTypeSelect,
    FloorTypeSelect,
    VehicleOptionMultiSelect,
    MonthPicker,
    StatusSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          deliveryCompanyName: "",
          vehicleTypeId: "",
          entryControlNumber: "",
          entryLocation: "",
          entryKana: "",
          entryNumber: "",
          chassisControlNumber: "",
          chassisLocation: "",
          chassisKana: "",
          chassisNumber: "",
          floorSpec: "",
          carryWeightMin: "",
          carryWeightMax: "",
          widthMin: "",
          widthMax: "",
          heightMin: "",
          heightMax: "",
          lengthMin: "",
          lengthMax: "",
          groundHeightMin: "",
          groundHeightMax: "",
          optionIdList: [],
          firstRegisteredMin: "",
          firstRegisteredMax: "",
          vehicleGarageName: "",
          status: ""
        }
      };
    }
  }
};
</script>
