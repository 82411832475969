<template lang="pug">
  section
    v-text-field(
      readonly
      v-if="!enableDelete"
      v-model="p_label"
      label="卸地"
      append-outer-icon="search"
      :clearable="clearable"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    v-text-field(
      readonly
      v-if="enableDelete"
      v-model="p_label"
      label="卸地"
      prepend-icon="close"
      @click:prepend="deleteUnloadingBaseList"
      append-outer-icon="search"
      :clearable="clearable"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="'base/fetchUnloadingBaseForSelect'")
      template(v-slot:body="{body}")
        td {{ body.baseCd }}
        td {{ body.displayBaseName }}
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    enableDelete: {
      type: Boolean,
      required: true,
      default: true
    },
    clientId: {
      type: Number,
      default: null
    },
    clientBranchId: {
      type: Number,
      default: null
    },
    excludeBaseIdList: {
      type: Array,
      required: false,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "unloadingBaseId",
      config: {
        title: "拠点選択",
        headers: [
          {
            text: "拠点CD",
            key: "baseCd",
            align: false
          },
          {
            text: "拠点名",
            key: "baseName",
            align: false
          }
        ]
      },
      initSearchCond: () => {
        return {
          clientId: this.clientId,
          clientBranchId: this.clientBranchId,
          excludeBaseIdList: this.excludeBaseIdList
        };
      }
    };
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      if (this.selectItemCallback == null) {
        this.p_code = data.id;
        this.p_label = data.nameAndCd;
        this.$emit("setSelectData", data);
      } else {
        this.selectItemCallback(data);
      }
    },
    deleteUnloadingBaseList() {
      this.$emit("deleteList");
    }
  }
};
</script>
