<template lang="pug">
  section
    v-text-field(
      readonly clearable
      @click:clear="clear"
      v-model="p_label"
      :label="titleLabel"
      :append-outer-icon="SearchIcon"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)"
      :prepend-icon="prependIcon"
      :box="readonly"
      :hint="hint" persistent-hint
      @click:prepend="prependClick")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond"
      :getListActionName="DispatchName")
      template(v-slot:headerRight)
        v-flex(xs2).dialog-header-right-box
          v-btn(color="primary" @click="openAddDialog") 新規追加
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";
import * as Enum from "@/assets/js/enum";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    excludeCompanyIdList: {
      type: Array,
      required: false,
      default: () => []
    },
    primaryDeliveryCompanyId: {
      type: Number,
      required: false,
      default: () => null
    },
    filterPrimaryMode: {
      type: Boolean,
      required: false,
      default: () => true
    },
    titleLabel: {
      type: String,
      default: "配送委託先会社"
    },
    prependIcon: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ""
    }
  },
  computed: {
    /**
     * @return {string}
     */
    SearchIcon() {
      return this.readonly ? "" : "search";
    },
    /**
     * @return {string}
     */
    DispatchName() {
      return "deliveryCompany/fetchAllDeliveryCompanyForSelect";
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "deliveryCompanyId",
      config: {
        title: this.titleLabel,
        width: "60%",
        headers: [
          {
            text: "会社CD",
            key: "companyCd",
            align: "",
            search: ""
          },
          {
            text: "会社名",
            key: "companyName",
            align: "",
            search: ""
          },
          {
            text: "本社住所",
            key: "headOfficeAddress",
            align: "",
            search: ""
          },
          {
            text: "電話番号",
            key: "transportTel",
            align: "",
            search: ""
          }
        ]
      },
      initSearchCond: () => {
        return {
          companyCd: "",
          companyName: "",
          excludeCompanyIdList: this.excludeCompanyIdList,
          primaryDeliveryCompanyId: this.filterPrimaryMode ? this.primaryDeliveryCompanyId : null,
          status: Enum.ActiveFlag.Active.code
        };
      }
    };
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      this.p_code = data.id;
      this.p_label = data.companyName;
      this.$emit("selectItem");
    },
    prependClick() {
      this.$emit("prependIconClick");
    },
    clear() {
      this.$emit("clear");
    },
    findResult() {
      return this.$store.dispatch(
        this.DispatchName,
        Object.assign({}, this.initSearchCond(), {
          unlimited: true
        })
      );
    },
    openAddDialog() {
      this.$emit("openAddDialog");
    },
    closeDialog() {
      this.$refs.dialog.closeDialog();
    }
  }
};
</script>

<style lang="stylus" scoped>
.dialog-header-right-box{
  margin-left auto
}
</style>
