<template lang="pug">
  v-progress-circular.progress-bar(:value="getProgressPercent" indeterminate :rotate="-90" size="80" width=8 color="teal" v-if="getLoadingStatus") {{getProgressPercent}} %
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getTotalCount: "indicator/getTotalCount",
      getLoadingStatus: "indicator/getLoadingStatus",
      getCurrentCount: "indicator/getCurrentCount"
    }),
    getProgressPercent() {
      if (this.getTotalCount < 1) {
        return 0;
      }
      return Math.floor((this.getCurrentCount / this.getTotalCount) * 100);
    }
  }
};
</script>
