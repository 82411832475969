<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="車庫名称" v-model="conditions.name")
        v-flex(md2)
          v-text-field(label="都道府県" v-model="conditions.prefectureName")
        v-flex(md2)
          v-text-field(label="エリア" v-model="conditions.areaName")
        v-flex(md6)
          v-text-field(label="住所" v-model="conditions.address")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
export default {
  components: {
    SearchConditions
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          name: "",
          prefectureName: "",
          areaName: "",
          address: ""
        }
      };
    }
  }
};
</script>
