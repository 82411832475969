<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      label="車庫"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="'vehicle/fetchAllVehicleGarage'")
</template>

<script>
import ApiHandler from "../../../mixin/ApiHandler";
import MasterSelectDialog from "../../../mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  data() {
    return {
      defaultErrorHandlerMethodArg: "vehicleGarageId",
      config: {
        title: "車庫",
        headers: [
          {
            text: "車庫名",
            key: "name",
            align: ""
          },
          {
            text: "エリア名",
            key: "areaName",
            align: ""
          },
          {
            text: "都道府県",
            key: "prefectureName",
            align: ""
          },
          {
            text: "住所",
            key: "address",
            align: ""
          }
        ]
      },
      initSearchCond: () => {
        return {
          name: "",
          areaName: "",
          prefectureName: "",
          address: ""
        };
      }
    };
  }
};
</script>
