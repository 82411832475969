export default {
  methods: {
    isNone(cardMarkType) {
      return cardMarkType === null || cardMarkType === this.MarkClear;
    },
    isMark1(cardMarkType) {
      return cardMarkType === this.Mark1;
    },
    isMark2(cardMarkType) {
      return cardMarkType === this.Mark2;
    },
    isMark3(cardMarkType) {
      return cardMarkType === this.Mark3;
    },
    isMark4(cardMarkType) {
      return cardMarkType === this.Mark4;
    }
  },
  computed: {
    MarkClear: () => "00",
    Mark1: () => "10",
    Mark2: () => "20",
    Mark3: () => "30",
    Mark4: () => "40"
  }
};
