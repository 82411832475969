<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      BoilerplateFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              BoilerplateTable.whitespace-pre(
                ref="BoilerplateTable"
                :prop-header="boilerplateTableHeader"
                :prop-data="boilerplateList"
                @open-detail="openDetail"
                detail-url="boilerplate/fetchBoilerplate"
                :displayFilter="false"
                :getListActionName="getListActionName"
                :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td(width="200px") {{ body.title }}
                  td.align-center
                    span(v-if="body.serviceContactUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.transportUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.billingStatementCoveringLetterUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.paymentStatementCoveringLetterUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.paymentPromptReportCoveringLetterUseDefault")
                      v-icon(color="green") check_circle
                  td.max-text {{ body.body | ommitedText }}

    BoilerplateEditDialog(ref="BoilerplateEditDialog" :postUrl="$config.urls.postEditBoilerplate" :deleteUrl="$config.urls.deleteBoilerplate" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import BoilerplateEditDialog from "@/components/management/boilerplate/BoilerplateEdit";
import BoilerplateTable from "@/components/common/PagingListTable";
import BoilerplateFilterConditions from "@/components/management/boilerplate/BoilerplateFilterConditions";
import { LimitNumber } from "@/assets/js/constants";

export default {
  components: {
    BoilerplateEditDialog,
    BoilerplateTable,
    BoilerplateFilterConditions
  },
  data() {
    return {};
  },
  filters: {
    ommitedText(text) {
      return text.length > LimitNumber.LengthNumber ? text.slice(0, LimitNumber.LengthNumber) + "..." : text;
    }
  },
  computed: {
    boilerplateList: {
      get() {
        return this.$store.state.boilerplate.boilerplateList;
      }
    },
    getListActionName: {
      get() {
        return "boilerplate/fetchAllBoilerplate";
      }
    },
    boilerplateTableHeader: {
      get() {
        return [
          { text: "タイトル", value: "title", width: "300px" },
          { text: "車番連絡デフォルト利用", value: "serviceContactUseDefault", width: "200px" },
          { text: "輸送依頼デフォルト利用", value: "transportUseDefault", width: "200px" },
          {
            text: "請求書送付状デフォルト利用",
            value: "billingStatementCoveringLetterUseDefault",
            width: "200px"
          },
          {
            text: "支払書送付状デフォルト利用",
            value: "paymentStatementCoveringLetterUseDefault",
            width: "200px"
          },
          {
            text: "支払書速報送付状デフォルト利用",
            value: "paymentPromptReportCoveringLetterUseDefault",
            width: "200px"
          },
          { text: "内容", value: "body" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.BoilerplateEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.BoilerplateEditDialog.openDialog(item);
    },
    /**
     * データ読み込み
     * @param params
     */
    loadListData() {
      this.$refs.BoilerplateTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.BoilerplateTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.BoilerplateTable.setSearchConditions(searchCond);
      this.$refs.BoilerplateTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>

<style lang="stylus" scoped>
.max-text{
  white-space: pre-wrap;
}
.align-center{
  text-align center
}
</style>
