import Axios from "@/assets/js/axios";

let $http = Axios.http;

export default {
  data() {
    return {
      errors: {},
      record: {
        itemTypeName: null
      },
      loading: {
        save: false,
        delete: false
      }
    };
  },
  props: {
    postUrl: {
      type: String,
      required: false
    },
    deleteUrl: {
      type: String,
      required: false
    }
  },
  methods: {
    getErrorMessages(fieldId) {
      if (this.errors[fieldId] == null) {
        return "";
      } else {
        return this.errors[fieldId].join("\n");
      }
    },
    setErrorMessages(error) {
      this.errors = error.response.data["fieldErrors"];
    },
    createParams(params) {
      return {
        vm: this,
        params: params
      };
    },
    async saveRecord(data) {
      const self = this;
      self.loading.save = true;
      return $http
        .post(self.getPostUrl, data === undefined ? self.record : data)
        .then(response => {
          self.$emit("updated", response, data === undefined ? self.record : data);
          return Promise.resolve(response);
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            self.setErrorMessages(error);
          } else {
            this.openErrorMsgBox(error.response.data.message);
          }
          return Promise.reject(error);
        })
        .finally(() => {
          self.loading.save = false;
        });
    },
    async deleteRecord(id) {
      const self = this;
      self.loading.delete = true;
      return $http
        .delete(self.deleteUrl + "/" + (id === undefined ? self.record.id : id))
        .then(response => {
          this.$emit("deleted", response);
          return Promise.resolve(response);
        })
        .catch(error => {
          this.openErrorMsgBox(error.response.data.message);
          return Promise.reject(error);
        })
        .finally(() => {
          self.loading.delete = false;
        });
    }
  },
  computed: {
    getPostUrl: {
      get() {
        return this.postUrl;
      }
    }
  }
};
