<template lang="pug">
  div
    span(v-if="!isChangedVehicle")
      v-icon(flat) arrow_forward_ios
    span(v-if="isChangedVehicle")
      v-icon(flat ) local_shipping
      br
      v-icon(flat ) unfold_more
      br
      v-icon(flat ) local_shipping
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    isChangedVehicle() {
      if (this.item === undefined || this.item == null) {
        return false;
      }
      return this.item.isChangedVehicle;
    }
  }
};
</script>
