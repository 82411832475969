<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      PaymentItemFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              PaymentItemTable(ref="PaymentItemTable" :prop-header="paymentItemTableHeader" :prop-data="paymentItemList" @open-detail="openDetail" detail-url="paymentItem/fetchPaymentItem" :getListActionName="getListActionName" :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-left {{ body.paymentItemName }}
                  td.text-xs-left {{ getSaleTaxTarget(body.saleTaxTarget) }}
                  td.text-xs-left {{ body.sortOrder }}
                  td.text-xs-left {{ displayFlagLabel(body.displayTarget) }}

    PaymentItemEditDialog(ref="PaymentItemEditDialog" :postUrl="$config.urls.postEditPaymentItem" :deleteUrl="$config.urls.deletePaymentItem" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import { mapGetters } from "vuex";
import PaymentItemEditDialog from "@/components/management/payment-item/PaymentItemEdit";
import PaymentItemTable from "@/components/common/PagingListTable";
import PaymentItemFilterConditions from "@/components/management/payment-item/PaymentItemFilterConditions";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    PaymentItemEditDialog,
    PaymentItemTable,
    PaymentItemFilterConditions
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getSaleTaxTarget: "site/saleTaxTargetLabel"
    }),
    getListActionName: {
      get() {
        return "paymentItem/fetchAllPaymentItem";
      }
    },
    paymentItemList: {
      get() {
        return this.$store.state.paymentItem.paymentItemList;
      }
    },
    paymentItemTableHeader: {
      get() {
        return [{ text: "支払項目名" }, { text: "消費税対象" }, { text: "表示順" }, { text: "表示対象" }];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    openAddDialog() {
      this.$refs.PaymentItemEditDialog.openDialog();
    },
    openDetail(item) {
      this.$refs.PaymentItemEditDialog.openDialog(item);
    },
    loadListData() {
      this.$refs.PaymentItemTable.loadData();
    },
    itemUpdated(response, data) {
      this.$refs.PaymentItemTable.resetPageCount();
      this.loadListData();
    },
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.PaymentItemTable.setSearchConditions(searchCond);
      this.$refs.PaymentItemTable.resetPageCount();
      this.loadListData();
    },
    displayFlagLabel(value) {
      return Enum.Utils.findLabel(value, Enum.DisplayFlag);
    }
  }
};
</script>
