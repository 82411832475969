<template lang="pug">
  EditDialog(ref="EditDialog" :title="title()" @save="save" :displayDeleteButton="false" :hasTitleSuffix="false")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12)
            v-textarea(label="備考" auto-grow v-model="record.resultRemark" :error-messages="getErrorMessages('remark')")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  props: {
    release: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      controls: {
        index: null
      },
      record: {},
      tranData: {},
      hasDispatchResult: false
    };
  },
  methods: {
    /**
     * 初期データ
     */
    initData(data) {
      return {
        driverId: "driverId" in data.item ? data.item.driverId : null,
        resultRemark: "resultRemark" in data.item ? data.item.resultRemark : null,
        scheduledLoadingDate: data.scheduledLoadingDate
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = {
        driverId: this.record.driverId,
        scheduledLoadingDate: this.record.scheduledLoadingDate,
        remark: this.record.resultRemark
      };
    },
    /**
     * フォームの初期化
     * @param values
     */
    initForm(values) {
      this.record = Object.assign({}, this.initData(values));
      this.errors = {};
    },
    openDialog(item) {
      this.initForm(item);
      this.hasDispatchResult = item.item.hasDispatchResult;
      this.$store
        .dispatch("dynamic/getDispatchResultDetail", {
          scheduledLoadingDate: this.record.scheduledLoadingDate,
          driverId: this.record.driverId
        })
        .then(value => {
          if (value) {
            this.record.resultRemark = "remark" in value ? value.remark : null;
          }
        });
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      this.setTranData();
      this.saveRecord(this.tranData).then(() => {
        this.$refs.EditDialog.closeDialog();
        this.$emit("saved");
      });
    },
    title() {
      return this.hasDispatchResult ? "業務完了変更" : "業務完了登録";
    }
  }
};
</script>
