<template lang="pug">
  section
    v-text-field(
      readonly clearable
      @click:clear="clear"
      v-model="p_label"
      :label="titleLabel"
      :append-outer-icon="SearchIcon"
      @click:append-outer="openDialog"
      :box="readonly"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond"
      :getListActionName="DispatchName")
      template(v-slot:headerRight)
        v-flex(xs2).dialog-header-right-box
          v-btn(color="primary" @click="openAddDialog") 新規追加
      template(v-slot:body="{body}")
        td.text-xs-center {{ body.vehicleTypeName }}
        td {{ body.vehicleNumber }}
        td.text-xs-right {{ body.carryWeight }}
        td
          v-chip(v-for="(name,index) in body.optionNameList" :key="index") {{ name }}
        td {{ body.vehicleGarageName }}

</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";
import * as Enum from "@/assets/js/enum";
import { mapGetters } from "vuex";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    companyId: {
      type: Number,
      required: false,
      default: () => null
    },
    titleLabel: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "regularUseVehicleId",
      initSearchCond: () => {
        return {
          vehicleTypeCd: null,
          fullNumberDisp: null,
          floorSpecCd: null,
          carryWeightType: null,
          optionId: null,
          vehicleGarageName: null,
          deliveryCompanyId: this.companyId,
          scheduledLoadingDate: this.getScheduledLoadingDate
        };
      }
    };
  },
  computed: {
    ...mapGetters({
      getScheduledLoadingDate: "dispatch/getScheduledLoadingDate"
    }),
    config() {
      return {
        title: this.titleLabel,
        width: "60%",
        headers: [
          {
            text: "車格",
            key: "vehicleTypeCd",
            align: "",
            select: true,
            items: this.$store.state.site.primaryVehicleTypes
          },
          {
            text: "車番",
            key: "fullNumberDisp",
            align: ""
          },
          {
            text: "積載重量",
            key: "carryWeightType",
            select: true,
            items: Enum.Utils.enumToList(Enum.CarryWeightType)
          },
          {
            text: "オプション",
            key: "optionId",
            align: "",
            select: true,
            multipleSelect: true,
            items: this.$store.state.site.vehicleOptionTypes
          },
          {
            text: "車庫",
            key: "vehicleGarageName",
            align: ""
          }
        ]
      };
    },
    /**
     * @return {string}
     */
    DispatchName() {
      return "vehicle/fetchAllUsableVehicleForDispatch";
    },
    /**
     * @return {string}
     */
    SearchIcon() {
      return this.readonly ? "" : "search";
    }
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      this.p_code = data.id;
      this.p_label = data.fullNumberDisp;
      this.$emit("selectItem", data);
    },
    clear() {
      this.$emit("clear");
    },
    findResult() {
      return this.$store.dispatch(
        this.DispatchName,
        Object.assign({}, this.initSearchCond(), {
          unlimited: true
        })
      );
    },
    openAddDialog() {
      this.$emit("openAddDialog");
    },
    closeDialog() {
      this.$refs.dialog.closeDialog();
    }
  }
};
</script>
<style lang="stylus" scoped>
.dialog-header-right-box{
  margin-left auto
}
</style>
