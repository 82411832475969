import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  transportTypeList: []
};

const getters = {
  allTransportType: state => {
    return state.transportTypeList;
  }
};

const actions = {
  fetchAll({ commit, state }) {
    $axios.get(axiosBase.getUrls().getAllTransportTypeList).then(value => {
      commit("writeAllData", value.data.data);
    });
  }
};

const mutations = {
  writeAllData(state, data) {
    state.transportTypeList = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
