<template>
  <v-toolbar fixed class="header" dark :color="$store.getters['site/siteColor']">
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn icon dark @click="closeDialog">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      controls: {
        dialog: true
      }
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    }
  }
};
</script>

<style scoped>
.header {
  position: sticky;
  top: 0;
}
</style>
