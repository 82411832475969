<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="氏名" v-model="conditions.name")
        v-flex(md2)
          ContractTypeSelect(:code.sync="conditions.contractType" labelDisp="契約" :clearable="true")
        v-flex(md2)
          v-text-field(label="所属企業" v-model="conditions.deliveryCompanyName")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="基本車番4ケタ" v-model="conditions.entryNumber")
        v-flex(md2)
          v-text-field(label="基本車番地区" v-model="conditions.entryLocation")
        v-flex(md2)
          v-text-field(label="基本車番かな" v-model="conditions.entryKana")
        v-flex(md2)
          v-text-field(label="基本車番枝番" v-model="conditions.entryControlNumber")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="基本車番(シャーシ) 4ケタ" v-model="conditions.chassisNumber")
        v-flex(md2)
          v-text-field(label="基本車番(シャーシ) 地区" v-model="conditions.chassisLocation")
        v-flex(md2)
          v-text-field(label="基本車番(シャーシ) かな" v-model="conditions.chassisKana")
        v-flex(md2)
          v-text-field(label="基本車番(シャーシ) 枝番" v-model="conditions.chassisControlNumber")
      v-layout(wrap)
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
        v-flex(md2)
          v-checkbox(v-model="conditions.useTemporary" label="仮乗務員")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import ContractTypeSelect from "@/components/common/select/ContractTypeSelect";
import StatusSelect from "@/components/common/select/StatusSelect";

export default {
  components: {
    SearchConditions,
    ContractTypeSelect,
    StatusSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          name: "",
          contractType: "",
          deliveryCompanyName: "",
          entryControlNumber: "",
          entryLocation: "",
          entryKana: "",
          entryNumber: "",
          chassisControlNumber: "",
          chassisLocation: "",
          chassisKana: "",
          chassisNumber: "",
          status: "",
          useTemporary: ""
        }
      };
    }
  }
};
</script>
