<template lang="pug">
  v-content
    EmergencyErrorDialog
    v-container(fluid grid-list-md)
      v-layout
        v-flex(md12)
          v-card
            v-card-text
              PaymentPromptFilterConditions(ref="PaymentPromptFilterConditions" @onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              v-layout(fluid)
                v-flex(xs12)
                  v-btn(@click="exportConfirm(exportType.sendUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll || !searchCond.onlyFaxTarget") {{ exportType.sendLabel }}
                  v-btn(@click="exportConfirm(exportType.downloadUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll") {{ exportType.downloadLabel }}
                  ProgressBar
                  ExportConfirmDialog(ref="ExportConfirmDialog" :message="confirmMessage" @yes="exportReport")
      v-layout
        v-flex(xs12)
          v-card
            PaymentPromptListTable(ref="PaymentPromptListTable")
</template>

<script>
import { mapGetters } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import ExportHandler from "@/mixin/ExportHandler";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import PaymentPromptFilterConditions from "@/components/report/PaymentPromptFilterConditions";
import PaymentPromptListTable from "@/components/report/PaymentPromptListTable";
import ProgressBar from "@/components/common/ProgressBar";

export default {
  components: {
    PaymentPromptListTable,
    PaymentPromptFilterConditions,
    EmergencyErrorDialog,
    ExportConfirmDialog,
    ProgressBar
  },
  mixins: [EventDispatcher, ExportHandler],
  data: () => ({
    // 検索エラーフラグ
    isError: false,
    confirmMessage: "現在の選択内容を出力しますか？",
    // ExportHandler処理用Store URL
    dispatchUrl: "",
    exportType: {
      downloadLabel: "支払書速報ダウンロード",
      sendLabel: "支払書速報送信(FAX)",
      downloadUrl: "statement/downloadPaymentPrompt",
      sendUrl: "statement/sendPaymentPrompt"
    }
  }),
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      isDeselectedAll: "statement/getIsDeselectedAll"
    })
  },
  methods: {
    /**
     * Event：検索条件 変更
     */
    onChangeSearchConditions(searchCondition) {
      // ExportHandler条件設定
      this.searchCond = searchCondition;
      // InfiniteScrollHandler処理
      this.$refs.PaymentPromptListTable.setSearchConditions(searchCondition);
      this.$refs.PaymentPromptListTable.resetPageCount();
      this.$refs.PaymentPromptListTable.resetAllSelect();
      // データ取得
      this.$refs.PaymentPromptListTable.loadData()
        .then(result => (this.isError = false))
        .catch(error => {
          this.isError = true;
          // alertBox表示
          this.$refs.PaymentPromptFilterConditions.displayMessage(error.response.data.message);
        });
    },
    /**
     * Event：出力ダイアログの表示
     * ※PDFダウンロード
     */
    exportConfirm(url) {
      this.$refs.PaymentPromptFilterConditions.nonDisplayMessage();
      if (this.isError) return;
      this.dispatchUrl = url;
      this.$refs.ExportConfirmDialog.openDialog();
    },
    exportReport() {
      this.exportData(this.dispatchUrl).then(() => {
        this.$refs.PaymentPromptFilterConditions.submit();
      });
    }
  },
  mounted() {
    // 初期ロード時に初期検索情報にて結果を表示
    this.$store.dispatch("statement/clearFetchList");
    this.$refs.PaymentPromptFilterConditions.submit();
  }
};
</script>
