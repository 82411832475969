<template lang="pug">
  section
    v-layout(row wrap class="file-upload-box")
      v-flex(xs12 md4)
        FileUploadBox(v-bind:selected-file.sync="uploadFile" v-bind:errors="getErrors")

      v-flex(xs12 md4)
        v-btn(color="success" @click="upload" :loading="loading.upload") アップロード

    AlertBox(ref="errorMsg")
</template>

<script>
import FileUploadBox from "../../components/common/FileUploadBox";
import ApiHandler from "../../mixin/ApiHandler";
import Axios from "@/assets/js/axios";
import AlertBox from "@/components/common/AlertBox";
import { mapGetters } from "vuex";

let $http = Axios.http;

export default {
  components: {
    FileUploadBox,
    AlertBox
  },
  mixins: [ApiHandler],
  data: function () {
    return {
      files: [],
      uploadFile: {
        file: "",
        fileName: "",
        fileUrl: ""
      },
      loading: {
        upload: false
      }
    };
  },
  computed: {
    ...mapGetters({
      bjiMaxUploadFileSize: "site/bjiMaxUploadFileSize"
    }),
    getErrors() {
      return this.errors;
    }
  },
  methods: {
    selectedFile: function (e) {
      // 選択された File の情報を保存しておく
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
    },
    upload: function () {
      if (this.uploadFile.file.size > this.bjiMaxUploadFileSize) {
        this.openErrorMsgBox(window.Common.Messages.Common.Error.bjiUploadFileMaxError);
        return;
      }

      this.errors = {};
      this.loading.upload = true;
      // FormData を利用して File を POST する
      let self = this;
      let formData = new FormData();
      if (this.uploadFile.file !== "") {
        formData.append("uploadFile", this.uploadFile.file);
      }

      let config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      $http
        .post(this.$config.urls.importShipmentNoFile, formData, config)
        .then(value => {
          this.$emit("imported", value.data.data);
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            this.errors = error.response.data["fieldErrors"];
          } else if (error.response.data.message) {
            this.openErrorMsgBox(error.response.data.message);
          }
        })
        .finally(() => {
          self.loading.upload = false;
        });
    },
    openErrorMsgBox(message) {
      this.$refs.errorMsg.displayMessage(message);
    }
  }
};
</script>

<style lang="stylus">
.file-upload-box {
  align-items: baseline;
}
</style>
