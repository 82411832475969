<template lang="pug">
  EditDialog(ref="EditDialog" title="荷主" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md3)
            v-text-field(
              v-model="record.clientCd"
              label="荷主CD"
              :error-messages="getErrorMessages('clientCd')")

        v-layout(row wrap)
          v-flex(xs12 md6)
            v-text-field(
              v-model="record.clientName"
              label="名称"
              :error-messages="getErrorMessages('clientName')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.clientShortName"
              label="略称"
              :error-messages="getErrorMessages('clientShortName')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.department"
              label="部署"
              :error-messages="getErrorMessages('department')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.staff"
              label="担当者"
              :error-messages="getErrorMessages('staff')")

          v-flex(xs12 md2)
            v-text-field(
              v-model="record.zipCode"
              label="郵便番号"
              :error-messages="getErrorMessages('zipCode')")

          v-flex(xs12 md2)
            PrefSelect(:label.sync="record.prefecture.name" :code.sync="record.prefecture.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'prefecture.id'")

          v-flex(xs12 md8)
            v-text-field(
              v-model="record.address"
              label="住所"
              :error-messages="getErrorMessages('address')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.tel"
              label="電話番号"
              :error-messages="getErrorMessages('tel')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.fax"
              label="FAX"
              :error-messages="getErrorMessages('fax')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.mail"
              label="メールアドレス"
              :error-messages="getErrorMessages('mail')")

        v-layout(row wrap)
          v-card
            v-card-text
              v-layout(row wrap)
                v-flex(xs12 md3)
                  BillingOutputUnitTypeSelect(:code.sync="record.billingHeadOutputUnit" labelDisp="請求鑑出力単位" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="billingHeadOutputUnit")

                v-flex(xs12 md3)
                  BillingOutputUnitTypeSelect(:code.sync="record.billingDetailOutputUnit" labelDisp="請求明細出力単位" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="billingDetailOutputUnit")

                v-flex(xs12 md3)
                  BillingCutOffDateTypeSelect(:code.sync="record.billingCutOffDateType" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="billingCutOffDateType")

                v-flex(xs12 md3)
                  BillingBaseDateTypeSelect(:code.sync="record.billingBaseDateType" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="billingBaseDateType")

                // FAX送信対象
                v-flex(xs12 md3)
                  FaxTargetSelect(labelDisp="請求書FAX送信対象" :code.sync="record.billingStatementFaxTarget" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md3)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md12)
            v-textarea(v-model="record.remark" label="備考" :error-messages="getErrorMessages('remark')" maxlength=2000)

</template>

<script>
import PrefSelect from "../../common/select/PrefSelect";
import StatusSelect from "@/components/common/select/StatusSelect";
import EditDialog from "@/components/common/dialog/EditDialog";
import BillingOutputUnitTypeSelect from "@/components/common/select/BillingOutputUnitTypeSelect";
import BillingCutOffDateTypeSelect from "@/components/common/select/CutOffDateTypeSelect";
import BillingBaseDateTypeSelect from "@/components/common/select/BaseDateTypeSelect";
import FaxTargetSelect from "@/components/common/select/FaxTargetSelect";

import ApiHandler from "../../../mixin/ApiHandler";
import EditForm from "../../../mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    PrefSelect,
    StatusSelect,
    EditDialog,
    BillingOutputUnitTypeSelect,
    BillingCutOffDateTypeSelect,
    BillingBaseDateTypeSelect,
    FaxTargetSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: this.initData()
    };
  },
  methods: {
    initData() {
      return {
        id: null,
        clientCd: null,
        clientName: null,
        clientShortName: null,
        zipCode: null,
        prefecture: {
          id: "",
          name: ""
        },
        address: null,
        tel: null,
        fax: null,
        mail: null,
        billingStatementFaxTarget: Enum.FaxTargetFlag.Unset.code,
        department: null,
        staff: null,
        billingHeadOutputUnit: Enum.BillingOutputUnitType.ClientBranch.code,
        billingDetailOutputUnit: Enum.BillingOutputUnitType.ClientBranch.code,
        billingCutOffDateType: Enum.CutOffDateType.EndNextMonth.code,
        billingBaseDateType: Enum.BaseDateType.ShippingDate.code,
        remark: null,
        status: Enum.ActiveFlag.Active.code,
        updatedAt: null
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    closeEditDialog() {
      this.record = this.initData();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
