<template lang="pug">
  table.paging-table(class="sticky_table")
    thead
      tr
        slot(v-if="displaySlotHeader" name="head")
        template(v-if="!displaySlotHeader")
          th(v-for="(header, index) in propHeader" :width="header.width")
            div(class="py-3 font-weight-thin grey--text text--darken-1 whitespace-pre") {{ header.text }}

    tbody
      tr.pointer-dblclick(v-if="getTableDataList.length" v-for="(item, index) in getTableDataList"
        :active="selected" :class="{'record-disabled':isDisabled(item)}"
        @dblclick="openDetail(item)")
        td(v-if="!displaySlotBody" v-for="header in propHeader" v-bind:class="{'text-xs-center': header.centerAlign, 'text-xs-right': header.rightAlign}") {{ getItemValue(item, header) }}
        slot(v-if="displaySlotBody" name="body" v-bind:body="item" v-bind:index="index")

      tr(v-if="!getTableDataList.length")
        td(:colspan="propHeader.length") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import _ from "lodash";
import EventDispatcher from "@/mixin/EventDispatcher";
import InfiniteScrollHandler from "@/mixin/InfiniteScrollHandler";
import * as Enum from "@/assets/js/enum";

export default {
  mixins: [EventDispatcher, InfiniteScrollHandler],
  props: {
    propHeader: {
      type: Array,
      required: true
    },
    propData: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    detailUrl: {
      type: String,
      default: () => ""
    },
    displaySlotHeader: {
      type: Boolean,
      default() {
        return false;
      }
    },
    displaySlotBody: {
      type: Boolean,
      default() {
        return false;
      }
    },
    disableOpenDetail: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      selected: [],
      dialog: false
    };
  },
  computed: {
    getTableDataList: {
      get() {
        return this.propData;
      }
    }
  },
  methods: {
    /**
     * 詳細ダイアログを開く
     **/
    openDetail(item) {
      if (_.isEmpty(this.detailUrl) || this.disableOpenDetail) {
        this.$emit("triggerSelect", item);
        return;
      }

      this.$store
        .dispatch(this.detailUrl, item.id)
        .then(value => {
          this.$emit("open-detail", value);
        })
        .catch(reason => {
          const event = document.createEvent("Event");
          event.initEvent(window.Common.Config.observer.onErrorResponse404, false, true);
          window.dispatchEvent(event);
        });
    },
    getDataFromKey(item, key) {
      let rslt = item;
      const keyArray = key.split(".");
      for (let i = 0; i < keyArray.length; i++) {
        if (rslt == null) break;
        rslt = rslt[keyArray[i]];
      }
      return rslt == null ? "" : rslt;
    },
    getItemValue(item, header) {
      if (header.displayFunc != null && header.displayFunc instanceof Function) {
        return header.displayFunc(item, header);
      } else {
        return this.getDataFromKey(item, header.value);
      }
    },
    isDisabled(item) {
      if (item.status === undefined) return false;

      return item.status === Enum.ActiveFlag.InActive.code;
    }
  }
};
</script>

<style lang="stylus" scoped>

table.paging-table{
  tbody tr:hover {
    td,th{
      background-color: #f5f5f5;
      transition: 0.2s;
    }

    >>> .pattern-card-box{
      .base-name {
        background-color: #eeeeee;
      }
    }
  }

  th,td{
    border-right 1px solid #E0E0E0;
  }

  th:last-child,
  td:last-child{
    border-right none;
  }
}

.sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 1;
}

table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
}


td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.th-text {
  margin-top: 20px;
  font-size: 13px;
}

.v-text-field {
  padding-top: 8px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 14px;
  font-weight: 400;
}
</style>
