<template lang="pug">
  SearchConditions(ref="SearchConditions" :initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions"
   @onChangeSearchConditionsTriggerBefore="onChangeSearchConditionsTriggerBefore"
   :autoTrigger="false" :isValid="true")
    template(v-slot:conditions="{ conditions }")
      alert-box(ref="errorMsg")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="荷主" v-model="conditions.clientName")
        v-flex(md2)
          v-text-field(label="荷主枝番" v-model="conditions.clientBranchName")
        v-flex(md2)
          v-text-field(label="1次委託先会社" v-model="conditions.primaryDeliveryCompanyName")
        v-flex(md6)
          v-layout(wrap)
            v-flex(md3)
              v-text-field(label="所属会社" v-model="conditions.deliveryCompanyName")
            v-flex(md3)
              v-text-field(label="乗務員名" v-model="conditions.driverName")
            v-flex(md3)
              v-text-field(label="車番" v-model="conditions.vehicleNumber")
            v-flex(md3)
              VehicleTypeSelect(label="車両車格" :code.sync="conditions.vehicleTypeId" :clearable="true")
      v-layout(wrap)
        v-flex(md2)
          DatePicker(pickerLabel="配車日(開始)" :initDate="conditions.scheduledLoadingDateStart" :inputDate.sync="conditions.scheduledLoadingDateStart" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="配車日(終了)" :initDate="conditions.scheduledLoadingDateStart" :inputDate.sync="conditions.scheduledLoadingDateEnd" :isClearable="true")

        v-flex(md2)
          DatePicker(pickerLabel="積込日(開始)" initDate="" :inputDate.sync="conditions.shipmentDateStart" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="積込日(終了)" initDate="" :inputDate.sync="conditions.shipmentDateEnd" :isClearable="true")

        v-flex(md2)
          DatePicker(pickerLabel="納品日(開始)" initDate="" :inputDate.sync="conditions.deliveryDateStart" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="納品日(終了)" initDate="" :inputDate.sync="conditions.deliveryDateEnd" :isClearable="true")

        v-flex(md2)
          DatePicker(pickerLabel="積込予定日(開始)" initDate="" :inputDate.sync="conditions.loadingDateStart" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="積込予定日(終了)" initDate="" :inputDate.sync="conditions.loadingDateEnd" :isClearable="true")

        v-flex(md2)
          DatePicker(pickerLabel="納品予定日(開始)" initDate="" :inputDate.sync="conditions.unloadingDateStart" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="納品予定日(終了)" initDate="" :inputDate.sync="conditions.unloadingDateEnd" :isClearable="true")
        v-flex(md2)
          MonthPicker(pickerLabel="請求対象年月" initDate=""  :inputDate.sync="conditions.billingTargetYearMonth" :clearable="true")
        v-flex(md2)
          MonthPicker(pickerLabel="支払対象年月" initDate=""  :inputDate.sync="conditions.paymentTargetYearMonth" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import MonthPicker from "@/components/common/MonthPicker";
import AlertBox from "@/components/common/AlertBox";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";

export default {
  components: {
    SearchConditions,
    DatePicker,
    MonthPicker,
    AlertBox,
    VehicleTypeSelect
  },
  data() {
    return {
      dateErrorMsg: "日付は3カ月以内で指定してください。"
    };
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$refs.errorMsg.nonDisplayMessage();
      if (this.validDate(item)) {
        this.displayMessage(this.dateErrorMsg);
        return;
      }
      this.$refs.SearchConditions.setLoadingUp();
      this.$emit("onChangeSearchConditions", item);
    },
    onChangeSearchConditionsTriggerBefore(item) {
      this.$refs.errorMsg.nonDisplayMessage();
      this.$emit("onChangeSearchConditionsTriggerBefore", item);
    },
    /**
     * アラートボックス表示
     */
    displayMessage(errorMsg) {
      this.$refs.errorMsg.displayMessage(errorMsg);
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          clientBranchName: "",
          primaryDeliveryCompanyName: "",
          deliveryCompanyName: "",
          driverName: "",
          vehicleNumber: "",
          vehicleTypeId: "",
          scheduledLoadingDateStart: window.Common.Util.getTomorrowYearMonthDayIsoFormat(),
          scheduledLoadingDateEnd: window.Common.Util.getTomorrowYearMonthDayIsoFormat(),
          shipmentDateStart: "",
          shipmentDateEnd: "",
          deliveryDateStart: "",
          deliveryDateEnd: "",
          loadingDateStart: "",
          loadingDateEnd: "",
          unloadingDateStart: "",
          unloadingDateEnd: "",
          exportTemplateId: "",
          billingTargetYearMonth: "",
          paymentTargetYearMonth: ""
        }
      };
    },
    validDate(item) {
      return (
        !this.validScheduledLoadingDate(item) ||
        !this.validShipmentDate(item) ||
        !this.validDeliveryDate(item) ||
        !this.validLoadingDate(item) ||
        !this.validUnloadingDate(item)
      );
    },
    validScheduledLoadingDate(item) {
      if (!item.scheduledLoadingDateStart && !item.scheduledLoadingDateEnd) return true;
      if (item.scheduledLoadingDateStart && item.scheduledLoadingDateEnd) {
        var endDate = new Date(item.scheduledLoadingDateEnd);
        var startDate = new Date(item.scheduledLoadingDateStart);
        startDate.setMonth(startDate.getMonth() + 3);
        return endDate <= startDate;
      }
      return false;
    },
    validShipmentDate(item) {
      if (!item.shipmentDateStart && !item.shipmentDateEnd) return true;
      if (item.shipmentDateStart && item.shipmentDateEnd) {
        var endDate = new Date(item.shipmentDateEnd);
        var startDate = new Date(item.shipmentDateStart);
        startDate.setMonth(startDate.getMonth() + 3);
        return endDate <= startDate;
      }
      return false;
    },
    validDeliveryDate(item) {
      if (!item.deliveryDateStart && !item.deliveryDateEnd) return true;
      if (item.deliveryDateStart && item.deliveryDateEnd) {
        var endDate = new Date(item.deliveryDateEnd);
        var startDate = new Date(item.deliveryDateStart);
        startDate.setMonth(startDate.getMonth() + 3);
        return endDate <= startDate;
      }
      return false;
    },
    validLoadingDate(item) {
      if (!item.loadingDateStart && !item.loadingDateEnd) return true;
      if (item.loadingDateStart && item.loadingDateEnd) {
        var endDate = new Date(item.loadingDateEnd);
        var startDate = new Date(item.loadingDateStart);
        startDate.setMonth(startDate.getMonth() + 3);
        return endDate <= startDate;
      }
      return false;
    },
    validUnloadingDate(item) {
      if (!item.unloadingDateEnd && !item.unloadingDateEnd) return true;
      if (item.unloadingDateEnd && item.unloadingDateEnd) {
        var endDate = new Date(item.unloadingDateEnd);
        var startDate = new Date(item.unloadingDateStart);
        startDate.setMonth(startDate.getMonth() + 3);
        return endDate <= startDate;
      }
      return false;
    },
    submit() {
      this.$refs.SearchConditions.submit();
    }
  }
};
</script>
