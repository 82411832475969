<template lang="pug">
  EditDialog(ref="EditDialog" title="メール開封状況" :hasTitleSuffix="false" :displayFooter="false" :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      alert-box(ref="errorMsg")
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            div 該当乗務員の第一運行、全運行の開封状況を表示しています。
          v-flex(xs6 md6)
            v-text-field(box readonly v-model="loadingDate" :label="labelName.VehicleDispatch")

          v-flex(xs6 md6)
            v-text-field(box readonly v-model="driverName" label="乗務員名")

          v-flex(xs12 md12)
            table(class="sticky_table")
              thead
                tr
                  th(class="py-3 th-text font-weight-thin grey--text text--darken-1") 運行
                  th(class="py-3 th-text font-weight-thin grey--text text--darken-1") 状態
                  th(class="py-3 th-text font-weight-thin grey--text text--darken-1") 状態更新日時
                  th(class="py-3 th-text font-weight-thin grey--text text--darken-1") メール送信日時
                  th(class="py-3 th-text font-weight-thin grey--text text--darken-1") 再送
                  th(class="py-3 th-text font-weight-thin grey--text text--darken-1") 処理済
              tbody
                tr(v-for="item in record")
                  td.text-xs-left {{ getMailType(item.mailType) }}
                  td.text-xs-left {{ getMailStatus(item.mailStatus)}}
                  td.text-xs-center {{ item.updateAtValue }}
                  td.text-xs-center {{ item.mailSendAtValue }}
                  td.text-xs-center
                    v-btn(icon @click="sendConfirm(item.id)" :disabled="!hasEmail(item.email)")
                      v-icon reply
                  td.text-xs-center
                    v-btn(icon @click="changeStatusConfirm(item.id)" v-if="!isMailProcessed(item.mailStatus)")
                      v-icon check_circle_outline
                    v-icon(v-else color="green") check

        ConfirmDialog(ref="SendMailConfirmDialog" @yes="resendMail")
        ConfirmDialog(ref="ChangeStatusConfirmDialog" @yes="changeProcessed" :title="changeStatusConfirmTitle" :message="changeStatusConfirmMessage" yesButtonTitle="保存")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ConfirmDialog from "@/components/common/dialog/ConfirmDialog";
import AlertBox from "@/components/common/AlertBox";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import { mapGetters } from "vuex";
import Axios from "@/assets/js/axios";
import * as Enum from "@/assets/js/enum";
import { LabelName } from "@/assets/js/constants";

let $http = Axios.http;

export default {
  components: {
    EditDialog,
    ConfirmDialog,
    AlertBox
  },
  mixins: [ApiHandler, EditForm],
  props: {
    loadingDate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      labelName: LabelName,
      changeStatusConfirmTitle: "処理済変更",
      changeStatusConfirmMessage: "メール配信状態を処理済に変更しますか？",
      record: {
        id: "",
        driverId: "",
        driverName: "",
        dispatchId: "",
        dispatchSnapshot: {
          details: {},
          dispatchMailId: "",
          driver: {},
          id: "",
          remark: "",
          scheduleDate: "",
          temporaryName: "",
          vehicle: {},
          vehicleId: {}
        },
        mailStatus: "",
        mailType: "",
        token: "",
        expired: "",
        updateAtValue: "",
        mailSendAtValue: ""
      },
      resendMailId: ""
    };
  },
  methods: {
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    },
    // メール再送
    resendMail() {
      const self = this;
      if (this.resendMailId !== "") {
        $http
          .post(this.$config.urls.postResendMail + this.resendMailId)
          .then(value => {
            this.reloadData();
            this.$emit("send");
          })
          .catch(error => {
            if (error.response.data["fieldErrors"]) {
              self.setErrorMessages(error);
            } else {
              this.$refs.errorMsg.displayMessage(error.response.data.message);
            }
          });
      }
    },
    // メール状態を処理済にする
    changeProcessed() {
      const self = this;
      if (this.resendMailId !== "") {
        $http
          .post(this.$config.urls.postMailChangeProcessed + this.resendMailId)
          .then(value => {
            this.reloadData();
            this.$emit("send");
          })
          .catch(error => {
            if (error.response.data["fieldErrors"]) {
              self.setErrorMessages(error);
            } else {
              this.$refs.errorMsg.displayMessage(error.response.data.message);
            }
          });
      }
    },
    // メール送信確認のダイアログを表示
    sendConfirm(mailId) {
      this.resendMailId = mailId;
      this.$refs.SendMailConfirmDialog.openDialog();
    },
    changeStatusConfirm(mailId) {
      this.resendMailId = mailId;
      this.$refs.ChangeStatusConfirmDialog.openDialog();
    },
    // 表示データの再取得
    reloadData() {
      this.record = this.$store
        .dispatch("dispatch/fetchMailByDriver", {
          loadingDate: this.loadingDate,
          driverId: this.driverId
        })
        .then(value => {
          if (value == null) {
            return;
          }
          this.record = value;
        });
    },
    //乗務員のメールアドレスが登録されているか
    hasEmail(email) {
      if (email === undefined) {
        return false;
      }

      return email.length > 0;
    },
    //メールステータスが処理済みかどうか
    isMailProcessed(mailStatusType) {
      if (mailStatusType === undefined) {
        return false;
      }

      return mailStatusType === Enum.MailStatusType.Processed.code;
    }
  },
  computed: {
    ...mapGetters({
      getMailType: "site/mailTypeStringFromKey",
      getMailStatus: "site/mailStatusTypeStringFromKey"
    }),
    driverName: {
      get() {
        if (this.record != null && this.record.length > 0) {
          return this.record[0].driverName;
        }
        return "";
      }
    },
    driverId: {
      get() {
        if (this.record != null && this.record.length > 0) {
          return this.record[0].driverId;
        }
        return "";
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  font-size: 15px;
}

.th-text {
  font-size: 13px;
}
</style>
