<template lang="pug">
  table.accordion-table.order-table.sticky-table
    thead
      tr
        th.toggle-icon-cell.no-right-border
        th 1次配送委託先
        th 基準日
        th 合計金額(税)
        th 課税料金
        th 課税済料金
        th 運賃料合計
        th 通行料
        th(width="160px") 最終出力日
        th 最終出力者
        th FAX送信状況
        th(width="120px")
          StatementMetaSetting(ref="StatementMetaSetting" @onClickEvent="onClickEvent" :isShow="false" :isDisabled="true")
    tbody
      template(v-if="paymentPromptList.length")
        template(v-for="(contents, index) in paymentPromptList")
          tr(:class="{'record-disabled':isDisabled(contents), selected : contents.isDisplayCell}")
            td.toggle-cell.toggle-icon-cell.no-right-border(@click.stop="toggleDetail(contents, index)" @dblclick.stop="")
              v-btn(flat icon)
                v-icon {{ contents.isDisplayCell ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            td {{ contents.primaryDeliveryCompanyName }}
            td.text-sm-center.text-whitespace-pre {{ getCombineDate(contents.paymentBaseDateStart, contents.paymentBaseDateEnd) | commaToNr }}
            td.text-sm-right
              DigitSeparatorsLabel(:value="contents.totalPrice")
              div
                span (
                DigitSeparatorsLabel(:value="contents.saleTaxSubtotalPrice")
                span )
            td.text-sm-right
              DigitSeparatorsLabel(:value="contents.taxTargetSubtotalPrice")
            td.text-sm-right
              DigitSeparatorsLabel(:value="contents.taxedSubtotalPrice")
            td.text-sm-right
              DigitSeparatorsLabel(:value="contents.totalFareSubtotalPrice")
            td.text-sm-right
              DigitSeparatorsLabel(:value="contents.tollSubtotalPrice")
            td.text-sm-center {{ formatFullDateTime(contents.publishDateTime) }}
            td.text-sm-center {{ contents.publishUserName }}
            td.text-whitespace-pre.text-sm-center {{ formatFullDateTime(contents.faxSendDateTime) }} <br/> {{ contents.faxSendStatus }}
            td
              v-checkbox.justify-center.align-center(v-model="contents.selectTarget" v-on:change="onClickTarget")
          tr(v-show="contents.isDisplayCell")
            td.inner-cell(:colspan="12")
              PaymentPromptDetailListTable(ref="PaymentPromptDetailListTable" :index="index" :contents="contents" :searchConditions="searchConditions")
      template(v-else)
        tr
          td(:colspan="12") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import InfiniteScrollHandler from "@/mixin/InfiniteScrollHandler";
import StatementMetaSetting from "@/components/report/StatementMetaSetting";
import ConbineUtils from "@/mixin/ConbineUtils";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import * as Enum from "@/assets/js/enum";
import PaymentPromptDetailListTable from "@/components/report/PaymentPromptDetailListTable";

export default {
  /**
   * props
   * getListActionName：InfiniteScrollHandlerにて必須
   */
  props: {
    getListActionName: {
      type: String,
      default: "statement/fetchPaymentPromptList"
    }
  },
  mixins: [EventDispatcher, InfiniteScrollHandler, ConbineUtils],
  components: {
    StatementMetaSetting,
    DigitSeparatorsLabel,
    PaymentPromptDetailListTable
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      paymentPromptList: "statement/getList"
    })
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("statement", {
      // postReportBulkEditMeta: "postServiceContactReportBulkEditMeta",
      fetchClearList: "fetchClearList",
      fetchBillingDetailList: "fetchBillingDetailList"
    }),
    /**
     * Emit：定型文、署名一括変更
     */
    onClickEvent(condition) {
      // InfiniteScrollHandlerにsearchConditionsは設定されている。
      const searchCondition = this.searchConditions;
      this.postReportBulkEditMeta({ searchCondition, ...condition }).catch(error => {
        // mixin:EventDispatcherに情報を設定
        this.dispatchCommonEventWithParam(window.Common.Config.observer.onErrorResponse400, {
          message: error.response.data.message
        });
      });
    },
    /** Event：△▽による開閉 */
    async toggleDetail(contents, index) {
      if (!contents.isDisplayCell) {
        await this.$refs.PaymentPromptDetailListTable[index].loadDetail();
        this.$nextTick(() => (contents.isDisplayCell = true));
      }

      contents.isDisplayCell = !contents.isDisplayCell;
    },
    /** 変換：日付フォーマット */
    formatFullDateTime(datetime) {
      return window.Common.Util.getFullDateTimeIsoFormat(new Date(Date.parse(datetime)));
    },
    /** グレーアウト対象かどうか */
    isDisabled(item) {
      if (item.deliveryCompanyStatus === undefined) return false;
      return item.deliveryCompanyStatus === Enum.ActiveFlag.InActive.code;
    },
    onClickTarget() {
      this.$refs.StatementMetaSetting.onIndeterminate();
    },
    resetAllSelect() {
      this.$refs.StatementMetaSetting.resetSelectAll();
    }
  },
  mounted() {
    this.fetchClearList();
  },
  destroyed() {
    this.fetchClearList();
  }
};
</script>

<style lang="stylus" scoped>
.sticky-table {
  width: 100%;

  thead {
    background-color: #fff;
    position: sticky;
    top: 45px;
    z-index: 1;
  }

  th {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.order-table {
  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }
}

>>>.v-input--selection-controls__input {
  margin-right: 0;
}
</style>
