<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FilterConditions(@onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              VehicleGarageTable(
                ref="VehicleGarageTable"
                :prop-header="vehicleGarageTableHeader"
                :prop-data="vehicleGarageList"
                :getListActionName="getListActionName"
                detail-url="vehicle/fetchVehicleGarage"
                @open-detail="openDetail")

    VehicleGarageEditDialog(ref="VehicleGarageEditDialog" :postUrl="$config.urls.postEditVehicleGarage" :deleteUrl="$config.urls.deleteVehicleGarage" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import FilterConditions from "@/components/management/vehicle-garage/VehicleGarageFilterConditions";
import VehicleGarageEditDialog from "@/components/management/vehicle-garage/VehicleGarageEdit";
import VehicleGarageTable from "@/components/common/PagingListTable";

export default {
  components: {
    FilterConditions,
    VehicleGarageEditDialog,
    VehicleGarageTable
  },
  data() {
    return {
      searchCond: {}
    };
  },
  computed: {
    getListActionName: {
      get() {
        return "vehicle/fetchAllVehicleGarage";
      }
    },
    vehicleGarageList: {
      get() {
        return this.$store.state.vehicle.vehicleGarageList;
      }
    },
    vehicleGarageTableHeader: {
      get() {
        return [
          {
            text: "車庫名称",
            value: "name",
            centerAlign: ""
          },
          {
            text: "都道府県",
            value: "prefectureName",
            centerAlign: ""
          },
          {
            text: "エリア",
            value: "areaName",
            centerAlign: ""
          },
          {
            text: "住所",
            value: "address",
            centerAlign: ""
          }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openAddDialog() {
      this.$refs.VehicleGarageEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.VehicleGarageEditDialog.openDialog(item);
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     **/
    itemUpdated(response, data) {
      this.$refs.VehicleGarageTable.resetPageCount();
      this.loadListData();
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.VehicleGarageTable.loadData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.VehicleGarageTable.setSearchConditions(searchCond);
      this.$refs.VehicleGarageTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>
