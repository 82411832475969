<template lang="pug">
  v-dialog(v-model="dialog" persistent width="500" @keydown.esc="closeDialog")
    v-card
      v-toolbar(dark :color="$store.getters['site/siteColor']")
        v-icon(left color="white darken-2" large) help
        span  {{ title }}
        v-spacer
        v-btn(flat icon @click="closeDialog")
          v-icon close

      v-card-text
        v-layout
          v-flex
            div(class="ma-3") {{ message }}

      v-toolbar.footer
        v-spacer
        v-btn(color="" @click="no") {{ noButtonTitle }}
        v-btn(color="primary" @click="yes") {{ yesButtonTitle }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "送信確認"
    },
    message: {
      type: String,
      required: false,
      default: "配車メールを送信しますか？"
    },
    yesButtonTitle: {
      type: String,
      required: false,
      default: "送信"
    },
    noButtonTitle: {
      type: String,
      required: false,
      default: "キャンセル"
    }
  },
  data() {
    return {
      dialog: false,
      text: null
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.$emit("close");
      this.dialog = false;
    },
    no() {
      this.$emit("no");
      this.closeDialog();
    },
    yes() {
      this.$emit("yes");
      this.closeDialog();
    }
  }
};
</script>
