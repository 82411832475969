<template lang="pug">
  section
    AlertBox(ref="errorMsg")
    v-flex(xs12)
      v-alert(v-if="hadResultMessage" type="info" value="true") {{ resultList.resultMessageForExpenses }}
    v-flex(xs12 v-if="isFilterError || (hadWork && !hadResultMessage && !noWorkImportRow)")
      v-card
        v-card-title(class="header-summary")
          div.mr-2(v-show="!hadResultMessage")
            span 件数：{{ workListAllCount }} 件
            span.ml-2 エラー件数：{{ workListErrorCount }} 件
          v-checkbox(v-show="!hadResultMessage" label="エラーのみ表示" :value="isFilterError" v-on:change="toggleFilterError")
          v-btn(v-show="!hadResult && !hadResultMessage && !noWorkImportRow" color="success" @click="commit" class="commit-btn" :loading="getLoadingStatus") 取込確定
        v-card-text
          section
            table(class="order-table")
              thead
                tr
                  th(rowspan=2) 該当行
                  th(rowspan=2) 納品日
                  th(rowspan=2) 受付番号
                  th(rowspan=2 width="230px") プラン/ルート(シップメントNo)
                  th(colspan=2) 1次配送委託先(卸/配車)
                  th(rowspan=2) 実運送会社名<br/>(卸/配車)
                  th(rowspan=2) 車両車格<br/>(卸/配車)
                  th(rowspan=2) 車番(卸/配車)
                  th(rowspan=2) シャーシ番号<br/>(卸/配車)
                  th(colspan=2) 高速代(請求)
                  th(colspan=2) 高速代(支払)
                  th(rowspan=2 width="65px") 取込対象
                tr
                  th.second-row 現在
                  th.second-row 入力
                  th.second-row(width="100px") 現在
                  th.second-row(width="100px") 入力
                  th.second-row(width="100px") 現在
                  th.second-row.right-border(width="100px") 入力
              tbody
                template(v-if="workList.length")
                  tr(v-for="(orderRec,index) in workList")
                    td.text-md-center {{ orderRec.detail.lineNo }}
                    td.text-md-center {{ orderRec.detail.deliveryDate }}
                    td.text-md-left {{ orderRec.detail.receiptNo }}
                    td.text-md-left {{ getDisplayKey(orderRec.detail) }}
                    td.text-md-left {{ getDispDeliveryCompanyCard(orderRec.detail) }}
                    td.text-md-left(:class="{ updateCell : orderRec.detail.isPrimaryDeliveryCompanyDiff}") {{ getDispDeliveryCompany(orderRec.detail) }}
                    td.text-md-left {{ orderRec.detail.deliveryCompanyName }}
                    td.text-md-left {{ orderRec.detail.vehicleType }}
                    td.text-md-left {{ orderRec.detail.vehicleNo }}
                    td.text-md-left {{ orderRec.detail.chassisNo }}

                    td.text-xs-right
                      DigitSeparatorsLabel(:value="orderRec.detail.billingExpensesCurrent")
                    td.text-xs-right(:class="{ updateCell : orderRec.detail.isBillingDifferent}")
                      DigitSeparatorsLabel(:value="orderRec.detail.billingExpenses")
                    td.text-xs-right
                      DigitSeparatorsLabel(:value="orderRec.detail.paymentExpensesCurrent")
                    td.text-xs-right(:class="{ updateCell : orderRec.detail.isPaymentDifferent }")
                      DigitSeparatorsLabel(:value="orderRec.detail.paymentExpenses")

                    td.text-md-center.targetSelectCell(v-if="!hadResultMessage && !isValidError(orderRec)")
                      v-checkbox.justify-center.align-center(v-if="orderRec.detail.isBillingDifferent || orderRec.detail.isPaymentDifferent" v-model="orderRec.detail.isImportTarget")
                      template(v-if="validStatus(orderRec) != ''")
                        div
                          v-tooltip(top)
                            template(v-slot:activator="{ on }")
                              v-icon(v-on="on").warning-box-icon warning
                            span.whitespace-pre {{ validStatus(orderRec) }}
                    td.text-md-center(v-else-if="isValidError(orderRec)")
                      template
                        div
                          v-tooltip(top)
                            template(v-slot:activator="{ on }")
                              v-icon(v-on="on").warning-box-icon warning
                            span.whitespace-pre {{ displayValidate(orderRec.detail.validationError) }}
                    td(v-else)
                template(v-else)
                  tr
                    td(:colspan="15") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}

    v-flex(xs12 v-if="hadResultError")
      v-card
        v-card-text
          section
            table(class="order-table")
              thead
                tr
                  th(width="65px") 該当行
                  th エラー内容
              tbody
                tr(v-for="(data,index) in resultList.errorList")
                  td.text-md-center {{ data.lineNo }}
                  td.text-md-center {{ data.message }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { LabelName } from "@/assets/js/constants";
import AlertBox from "@/components/common/AlertBox";
import ScrollHandler from "@/mixin/InfiniteScrollHandler";
import BillingStatusTypeHandler from "@/mixin/BillingStatusTypeHandler";
import PaymentStatusTypeHandler from "@/mixin/PaymentStatusTypeHandler";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";

export default {
  components: {
    AlertBox,
    DigitSeparatorsLabel
  },
  data() {
    return {
      labelName: LabelName
    };
  },
  mixins: [ScrollHandler, BillingStatusTypeHandler, PaymentStatusTypeHandler],
  computed: {
    ...mapGetters("finance", {
      workListAllCount: "workListAllCount",
      workListErrorCount: "workListErrorCount",
      noWorkImportRow: "noWorkImportRow",
      isFilterError: "errorOnly"
    }),
    ...mapGetters("indicator", {
      getLoadingStatus: "getLoadingStatus"
    }),
    workList: {
      get() {
        return this.$store.state.finance.workList;
      }
    },
    resultList: {
      get() {
        return this.$store.state.finance.workResultList;
      }
    },
    hadWork: {
      get() {
        return !this.workList || this.workList.length < 1 ? false : true;
      }
    },
    hadResult: {
      get() {
        return !this.resultList || this.resultList.length < 1 ? false : true;
      }
    },
    hadResultError: {
      get() {
        if (!this.hadResult) return false;
        return !this.resultList.errorList || this.resultList.errorList.length < 1 ? false : true;
      }
    },
    hadResultMessage: {
      get() {
        if (!this.hadResult) return false;
        return (
          this.resultList.resultMessageForExpenses !== undefined &&
          this.resultList.resultMessageForExpenses !== ""
        );
      }
    },
    isValidError: function () {
      return function (orderRec) {
        return Object.keys(orderRec.detail.validationError).length;
      };
    }
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("finance", {
      fetchClearWorkList: "fetchClearWorkList",
      toggleExpensesImportErrorOnly: "toggleExpensesImportErrorOnly"
    }),
    toggleFilterError() {
      this.toggleExpensesImportErrorOnly(!this.isFilterError);
      this.pageCount = 0;
      this.loadWorkData();
    },
    loadWorkDataAll() {
      this.toggleExpensesImportErrorOnly(false);
      this.loadWorkData();
    },
    loadWorkData() {
      this.$refs.errorMsg.nonDisplayMessage();
      const getParams = {
        pageCount: this.pageCount
      };
      if (!this.hadResult) {
        this.$store.dispatch("finance/fetchAllExpensesImportWork", getParams).then(() => {
          if (this.$store.state.finance.noWorkImportRow) {
            if (!this.isFilterError) {
              this.$refs.errorMsg.displayMessage("金額が登録されている行がありません。");
            }
          }
        });
      }
    },
    fetchWorkListCount() {
      this.$store.dispatch("finance/fetchExpensesImportWorkCount");
    },
    clearWorkData() {
      this.pageCount = 0;
      this.fetchClearWorkList();
    },
    commit() {
      this.toggleExpensesImportErrorOnly(false);
      // 取込対象外のカードID
      const cardIdList = this.workList
        .filter(value => {
          if (!value.detail.isBillingDifferent && !value.detail.isPaymentDifferent) return false;
          return value.detail.isImportTarget === false;
        })
        .map(value => value.cardId);
      this.$store.dispatch("finance/commitImportRequest", cardIdList);
    },
    displayValidate(value) {
      let list = [];
      Object.entries(value).forEach(value => {
        list.push(value[0] + " : " + value[1]);
      });
      return list.join("\n");
    },
    getDisplayKey(data) {
      let key = "";
      if (data.planNo && data.routeNo && data.routeNoSeq) {
        key = data.planNo + "/" + data.routeNo + "-" + data.routeNoSeq;
        if (data.shipmentNo) {
          key = key + "(" + data.shipmentNo + ")";
        }
      }
      return key;
    },
    getDispDeliveryCompanyCard(detail) {
      if (detail.cardPrimaryDeliveryCompanyName && detail.cardPrimaryDeliveryCompanyCd) {
        return detail.cardPrimaryDeliveryCompanyName + "/" + detail.cardPrimaryDeliveryCompanyCd;
      } else if (detail.cardPrimaryDeliveryCompanyName) {
        return detail.cardPrimaryDeliveryCompanyName;
      } else {
        return detail.cardPrimaryDeliveryCompanyCd;
      }
    },
    getDispDeliveryCompany(detail) {
      if (detail.primaryDeliveryCompanyName && detail.primaryDeliveryCompanyCd) {
        return detail.primaryDeliveryCompanyName + "/" + detail.primaryDeliveryCompanyCd;
      } else if (detail.primaryDeliveryCompanyName) {
        return detail.primaryDeliveryCompanyName;
      } else {
        return detail.primaryDeliveryCompanyCd;
      }
    },
    validStatus(data) {
      let message = "";
      if (data.detail.billingExpenses && this.isTemp(data.billingStatus)) {
        message = "請求状態が仮確定です。 ";
      }
      if (data.detail.paymentExpenses && this.isTemp(data.paymentStatus)) {
        message = message + "支払状態が仮確定です。";
      }
      return message;
    }
  },
  mounted() {
    this.clearWorkData();
    this.loadWorkData();
  }
};
</script>

<style lang="stylus" scoped>
.header-summary {
  display: flex;
  justify-content: flex-end;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;

  td {
    height: 48px;
    border: solid #dcdcdc;
    border-width: 1px 0px;
    vertical-align: middle;
    padding-left: 8px;
    padding-right: 8px;
  }

  thead {
    tr {
      height: 54px;
    }

    th {
      border-right: 1px solid #E0E0E0;
      background-color: #fff;
      position: sticky;
      top: 45px;
      height: 54px;
      z-index: 1;
    }

    .second-row {
      border-right: 1px solid #E0E0E0;
      background-color: #fff;
      position: sticky;
      top: 99px;
      height: 54px;
      z-index: 1;
    }
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }

  th:last-child.right-border {
    border-right: 1px solid #E0E0E0;
  }

  tbody {
    tr {
      transition: 0.3s;
    }

    td {
      border-right: 1px solid #E0E0E0;
    }

    td.updateCell {
      background-color: #A5D6A7 // green lighten-3
    }
  }
}

.order-table {
  border-collapse: separate;
  border-spacing: 0;

  td {
    border-bottom: none;
  }
}

.commit-btn {
  margin: 0 8px 0 40px;
}

.warning-box-icon {
  color: #FF5722;
}

.targetSelectCell {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
