<template lang="pug">
  section
    v-layout(wrap)
      v-flex(md4)
        ClientSelect(:label.sync="conditions.clientName"
          :code.sync="conditions.clientId" clearable
          :errorHandlerMethod="getErrorMessages"
          :errorHandlerMethodArg="'clientId'")
      v-flex(md3)
        ClientBranchSelect(:clientId="conditions.clientId"
          :clientBranchId="conditions.clientBranchId"
          :code.sync="conditions.clientBranchId" clearable
          @updateLabel="value => conditions.clientBranchName = value"
          :errorHandlerMethod="getErrorMessages")
      v-flex(md2)
        VehicleTypeMultiSelect(labelDisp="荷物車格" :code.sync="conditions.vehicleTypeIdList" :clearable="true")
      v-flex(md2)
        ItemTypeSelect(:code.sync="conditions.itemTypeId" :label.sync="conditions.itemTypeName"
          :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'itemTypeId'" clearable
        )
      v-flex(md1)
        BillingTypeSelect(:code.sync="conditions.billingTypeId" :errorHandlerMethod="getErrorMessages")
    v-layout(row wrap)
      v-flex(xs4)
        v-layout(column)
          template(v-for="(loading, index) in conditions.loadingBaseList")
            //積地
            v-flex(xs12)
              LoadingBaseSelect(:excludeBaseIdList="loadingBaseIdList" clearable
                :clientId="conditions.clientId"
                :clientBranchId="conditions.clientBranchId"
                :enableDelete="(index == 0 ? false :true)"
                :label.sync="loading.loadingBase.nameAndCd"
                :code.sync="loading.loadingBase.id"
                :errorHandlerMethod="getErrorMessages"
                :errorHandlerMethodArg="'loadingBaseList[' + index + '].baseId'"
                @deleteList="deleteLoadingBaseList(index)"
                @setSelectData="data => selectLoadingBase(loading,data)")

      v-flex(xs4)
        v-layout(column)
          template(v-for="(unloading, index) in conditions.unloadingBaseList")
            //卸地
            v-flex(xs12)
              UnloadingBaseSelect(:excludeBaseIdList="unloadingBaseIdList" clearable
                :clientId="conditions.clientId"
                :clientBranchId="conditions.clientBranchId"
                :enableDelete="(index == 0 ? false :true)"
                :label.sync="unloading.unloadingBase.nameAndCd"
                :code.sync="unloading.unloadingBase.id"
                :errorHandlerMethod="getErrorMessages"
                :errorHandlerMethodArg="'unloadingBaseList[' + index + '].baseId'"
                @deleteList="deleteUnloadingBaseList(index)"
                @setSelectData="data => selectUnloadingBase(unloading, data)")
    v-layout(row wrap)
      v-btn(color="info" falt @click.stop="addLoadingBase" v-bind:disabled ="loadingBaseLimit" :loading="loading") 積地を追加する
      v-btn(color="info" falt @click.stop="addUnloadingBase" v-bind:disabled ="unloadingBaseLimit" :loading="loading") 卸地を追加する

    v-layout(wrap)
      v-spacer
      v-flex(md3).text-xs-right
        v-btn(@click="clear" :loading="loading") クリア
        v-btn(@click="submit" :loading="loading" color="primary") 検索
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import DistanceTypeSelect from "@/components/common/select/DistanceTypeSelect";
import DispatchStatusSelect from "@/components/common/select/DispatchStatusSelect";

import ClientSelect from "@/components/common/select/ClientSelect";
import ClientBranchSelect from "@/components/common/select/ClientBranchSelect";

import LoadingBaseSelect from "@/components/common/select/LoadingBaseSelect";
import UnloadingBaseSelect from "@/components/common/select/UnloadingBaseSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import _ from "lodash";
import VehicleTypeMultiSelect from "@/components/common/select/VehicleTypeMultiSelect.vue";
import BillingTypeSelect from "@/components/common/select/BillingTypeSelect.vue";
import ItemTypeSelect from "@/components/common/select/ItemTypeSelect.vue";

export default {
  components: {
    ItemTypeSelect,
    BillingTypeSelect,
    VehicleTypeMultiSelect,
    SearchConditions,
    DatePicker,
    DistanceTypeSelect,
    DispatchStatusSelect,
    ClientSelect,
    ClientBranchSelect,
    LoadingBaseSelect,
    UnloadingBaseSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      errors: {},
      conditions: {},
      loading: false,
      condWatch: false
    };
  },
  computed: {
    loadingBaseIdList: {
      get() {
        return this.conditions.loadingBaseList
          .filter(loading => loading.loadingBase.id != null)
          .map(loading => loading.loadingBase.id);
      }
    },
    unloadingBaseIdList: {
      get() {
        return this.conditions.unloadingBaseList
          .filter(unloading => unloading.unloadingBase.id != null)
          .map(unloading => unloading.unloadingBase.id);
      }
    },
    loadingBaseLimit: {
      get() {
        if (this.conditions.loadingBaseList === undefined) return false;
        return this.conditions.loadingBaseList.length >= 5;
      }
    },
    unloadingBaseLimit: {
      get() {
        if (this.conditions.unloadingBaseList === undefined) return false;
        return this.conditions.unloadingBaseList.length >= 5;
      }
    },
    conditions_str: function () {
      return JSON.stringify(this.conditions);
    }
  },
  watch: {
    "conditions.clientId": function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }

      if (this.isResetBaseTarget()) {
        this.clearLoadingBaseList();
        this.clearUnloadingBaseList();
      }
    },
    "conditions.clientBranchId": function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }

      if (this.isResetBaseTarget()) {
        this.clearLoadingBaseList();
        this.clearUnloadingBaseList();
      }
    },
    conditions_str: {
      /**
       * watchで[deep:true]とするとnewValueとOldValueが同じ引数となってしまうので、このようなHackで対応
       * @param newValue
       * @param oldValue
       */
      handler: function (newValue, oldValue) {
        if (this.condWatch === false) return;

        const nValue = JSON.parse(newValue);
        const oValue = JSON.parse(oldValue);

        if (oValue.loadingBaseList.length < nValue.loadingBaseList.length) {
          if (nValue.loadingBaseList.slice(-1)[0].loadingBase.id == null) {
            return;
          }
        }

        if (oValue.unloadingBaseList.length < nValue.unloadingBaseList.length) {
          if (nValue.unloadingBaseList.slice(-1)[0].unloadingBase.id == null) {
            return;
          }
        }

        if (oValue.loadingBaseList.length > nValue.loadingBaseList.length) {
          if (oValue.loadingBaseList.slice(-1)[0].loadingBase.id == null) {
            return;
          }
        }

        if (oValue.unloadingBaseList.length > nValue.unloadingBaseList.length) {
          if (oValue.unloadingBaseList.slice(-1)[0].unloadingBase.id == null) {
            return;
          }
        }

        this.debouncedTrigger(nValue);
      }
    }
  },
  methods: {
    /**
     * 積地フォームの追加
     */
    addLoadingBase() {
      this.conditions.loadingBaseList.push(this.loadingDefault());
    },
    /**
     * 積地フォームの追加
     */
    addUnloadingBase() {
      this.conditions.unloadingBaseList.push(this.unloadingDefault());
    },
    /**
     * 積地フォームの削除
     */
    deleteLoadingBaseList(index) {
      this.conditions.loadingBaseList.splice(index, 1);
    },
    /**
     * 卸地フォームの削除
     */
    deleteUnloadingBaseList(index) {
      this.conditions.unloadingBaseList.splice(index, 1);
    },
    selectLoadingBase(loading, data) {},
    selectUnloadingBase(unloading, data) {},
    getErrorMessages(fieldId) {
      if (this.errors[fieldId] == null) {
        return "";
      } else {
        return this.errors[fieldId].join("\n");
      }
    },
    setErrorMessages(error) {
      this.errors = error.response.data["fieldErrors"];
    },
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          clientId: null,
          clientBranchId: null,
          clientName: "",
          clientBranchName: "",
          billingTypeId: this.$store.state.site.global.billingTypeNormalId,
          itemTypeId: null,
          loadingBaseList: [],
          unloadingBaseList: [],
          vehicleTypeIdList: [],
          pageCount: 0
        }
      };
    },
    /**
     * 積地初期値
     */
    loadingDefault() {
      return {
        id: null,
        loadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        }
      };
    },
    /**
     * 卸地初期値
     */
    unloadingDefault() {
      return {
        id: null,
        unloadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        },
        specifiedTime: {
          from: null,
          to: null
        },
        unloadingOrder: 1
      };
    },
    /**
     * 積地・卸地が画面上登録されていたらクリア対象として識別する
     * @returns {boolean}
     */
    isResetBaseTarget() {
      const loadingExists =
        this.conditions.loadingBaseList.find(value => {
          return value.loadingBase.id !== null;
        }) !== undefined;

      const unloadingExists =
        this.conditions.unloadingBaseList.find(value => {
          return value.unloadingBase.id !== null;
        }) !== undefined;

      return loadingExists || unloadingExists;
    },
    clearLoadingBaseList() {
      this.conditions.loadingBaseList = [];
      this.addLoadingBase();
    },
    clearUnloadingBaseList() {
      this.conditions.unloadingBaseList = [];
      this.addUnloadingBase();
    },
    clearConditions() {
      this.conditions = this.initSearchCond().conditions;
      this.clearLoadingBaseList();
      this.clearUnloadingBaseList();
    },
    clear() {
      this.clearConditions();
    },
    submit() {
      this.trigger(this.conditions);
    },
    trigger(conditions) {
      this.setLoadingUp();
      let filterCond = JSON.parse(JSON.stringify(conditions));

      filterCond.loadingBaseIdList = filterCond.loadingBaseList
        .filter(value => value.loadingBase.id !== null)
        .map(value => value.loadingBase.id);

      filterCond.unloadingBaseIdList = filterCond.unloadingBaseList
        .filter(value => value.unloadingBase.id !== null)
        .map(value => value.unloadingBase.id);

      delete filterCond.clientName;
      delete filterCond.clientBranchName;

      this.$emit("onChangeSearchConditions", filterCond);
    },
    setLoadingDown() {
      this.loading = false;
    },
    setLoadingUp() {
      this.loading = true;
    }
  },
  mounted() {
    // this.clearConditions();
    window.addEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.setLoadingDown,
      false
    );
    //WATCHが稼働してしまって初回に検索が無意味に走ってしまうので、DOM更新後にWATCHが効くようにする
    this.$nextTick(() => {
      this.condWatch = true;
    });
  },
  destroyed() {
    window.removeEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.setLoadingDown
    );
  },
  created() {
    this.clearConditions();
    this.debouncedTrigger = _.debounce(this.trigger, 500);
  }
};
</script>
