<template lang="pug">
  v-dialog(v-model="display" max-width="600px")
    v-card
      v-card-title 備考編集
      v-card-text
        v-textarea(v-model="remark")
        v-card-actions
          v-btn(@click="display=false") キャンセル
          v-spacer
          v-btn(color="primary" @click="save") 変更
</template>

<script>
export default {
  data() {
    return {
      display: false,
      remark: "",
      item: {}
    };
  },
  methods: {
    open(item, remark, data) {
      this.item = item;
      this.remark = remark;
      this.display = true;
    },
    save(event) {
      event.preventDefault();
      this.$emit("saveRemarkEditDialog", {
        item: this.item,
        remark: this.remark,
        event: event
      });
      this.display = false;
    }
  }
};
</script>
