<template lang="pug">
  EditDialog(ref="EditDialog" title="タグ名称" width="30%" :recordId="tag.id" :loadingSave="loading.save" @save="onSave" :displayDeleteButton="false")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field(label="タグ名" v-model="tag.tagName")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import EventDispatcher from "@/mixin/EventDispatcher";
import EditDialog from "@/components/common/dialog/EditDialog";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EventDispatcher],
  data: () => ({
    tag: {}
  }),
  methods: {
    openDialog(data) {
      this.errors = {};
      this.tag = {};
      this.$store.dispatch("tariff/fetchTariffTagOne", data.id).then(value => {
        this.tag = value;
        this.$refs.EditDialog.openDialog();
      });
    },
    /**
     * 保存
     */
    async onSave() {
      this.loading.save = true;
      const refsDialog = this.$refs.EditDialog;
      await this.$store
        .dispatch("tariff/updateTariffTag", this.tag)
        .then(() => {
          this.errors = {};
          this.$emit("updatedTag");
          refsDialog.closeDialog();
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            this.setErrorMessages(error);
          } else {
            refsDialog.openErrorMsgBox(error.response.data.message);
          }
        })
        .finally(() => {
          this.loading.save = false;
        });
    }
  }
};
</script>
