<template lang="pug">
  v-icon(v-if="favorite" color="yellow darken-3" @click="toggle") star
  v-icon(v-else @click="toggle") star_border
</template>

<script>
export default {
  name: "Favorite",
  props: {
    favorite: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle(event) {
      event.preventDefault();
      this.$emit("toggle", this.favorite);
    }
  }
};
</script>
