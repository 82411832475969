<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      :label="labelDisp"
      :append-outer-icon="SearchIcon"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)"
      :prepend-icon="prependIcon"
      @click:prepend="prependClick")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond"
      :getListActionName="'deliveryCompany/fetchAllDeliveryCompanyForSelect'")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    excludeCompanyIdList: {
      type: Array,
      required: false,
      default: () => []
    },
    labelDisp: {
      type: String,
      default: "配送委託先会社"
    },
    prependIcon: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isDispCd: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * @return {string}
     */
    SearchIcon() {
      return this.readonly ? "" : "search";
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "deliveryCompanyId",
      config: {
        title: "配送委託先会社",
        headers: [
          {
            text: "会社CD",
            key: "companyCd",
            align: "",
            search: ""
          },
          {
            text: "会社名",
            key: "companyName",
            align: "",
            search: ""
          }
        ]
      },
      initSearchCond: () => {
        return {
          companyCd: "",
          companyName: "",
          excludeCompanyIdList: this.excludeCompanyIdList
        };
      }
    };
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      let isChanged = this.p_code !== data.id;
      this.p_code = data.id;
      if (this.isDispCd) {
        this.p_label = data.nameAndCd;
      } else {
        this.p_label = data.companyName;
      }
      if (isChanged) this.$emit("changeItem");
    },
    prependClick() {
      this.$emit("prependIconClick");
    }
  }
};
</script>
