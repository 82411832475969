<template lang="pug">
  v-content
    EmergencyErrorDialog
    v-container(fluid grid-list-md)
      v-layout
        v-flex(md12)
          v-card
            v-card-text
              TransportOrderReportFilterCondition(ref="TransportOrderReportFilterCondition" @onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              TransportOrderReportPrimaryDeliveryWarning
            v-card-text
              v-layout(fluid)
                v-flex(xs12)
                  v-btn(@click="exportConfirm(exportType.requestReport.dispatchUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll") {{ exportType.requestReport.labelName }}
                  v-btn(@click="exportConfirm(exportType.instructionReport.dispatchUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll") {{ exportType.instructionReport.labelName }}
                  v-btn(@click="exportConfirm(exportType.requestReport.sendUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll || !searchCond.faxTarget") {{ exportType.requestReport.sendLabel }}
                  v-btn(@click="exportConfirm(exportType.instructionReport.sendUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll || !searchCond.faxTarget") {{ exportType.instructionReport.sendLabel }}
                  ExportConfirmDialog(ref="ExportConfirmDialog" :message="confirmMessage" @yes="exportReport")
      v-layout
        v-flex(xs12)
          v-card
            TransportOrderReportListTable(ref="TransportOrderReportListTable")
</template>

<script>
import { mapGetters } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import ExportHandler from "@/mixin/ExportHandler";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import TransportOrderReportFilterCondition from "@/components/report/TransportOrderReportFilterCondition";
import TransportOrderReportListTable from "@/components/report/TransportOrderReportListTable";
import TransportOrderReportPrimaryDeliveryWarning from "@/components/report/TransportOrderReportPrimaryDeliveryWarning";

export default {
  components: {
    TransportOrderReportListTable,
    TransportOrderReportFilterCondition,
    TransportOrderReportPrimaryDeliveryWarning,
    EmergencyErrorDialog,
    ExportConfirmDialog
  },
  mixins: [EventDispatcher, ExportHandler],
  data: () => ({
    // 検索エラーフラグ
    isError: false,
    confirmMessage: "現在の選択内容を出力しますか？",
    deselectedSignatureMessage: "署名を選択してから出力してください。",
    // ExportHandler処理用Store URL
    dispatchUrl: "",
    exportType: {
      requestReport: {
        labelName: "輸送依頼書出力",
        dispatchUrl: "report/downloadTransportRequestReport",
        sendLabel: "輸送依頼書送信",
        sendUrl: "report/sendTransportRequestReport"
      },
      instructionReport: {
        labelName: "運行指示書出力",
        dispatchUrl: "report/downloadTransportInstructionReport",
        sendLabel: "運行指示書送信",
        sendUrl: "report/sendTransportInstructionReport"
      }
    }
  }),
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      isDeselectedAll: "report/getIsDeselectedAll",
      isDeselectedSignature: "report/getIsDeselectedSignature"
    })
  },
  methods: {
    /**
     * Event：検索条件 変更
     */
    onChangeSearchConditions(searchCondition) {
      // ExportHandler条件設定
      this.searchCond = searchCondition;
      // InfiniteScrollHandler処理
      this.$refs.TransportOrderReportListTable.setSearchConditions(searchCondition);
      this.$refs.TransportOrderReportListTable.resetPageCount();
      // データ取得
      this.$refs.TransportOrderReportListTable.loadData()
        .then(result => (this.isError = false))
        .catch(error => {
          this.isError = true;
          // alertBox表示
          this.$refs.TransportOrderReportFilterCondition.displayMessage(error.response.data.message);
        });
    },
    /**
     * Event：出力ダイアログの表示
     * ※PDFダウンロード
     */
    exportConfirm(url) {
      this.$refs.TransportOrderReportFilterCondition.nonDisplayMessage();
      if (this.isError) return;
      // 署名確認
      if (this.isDeselectedSignature) {
        this.$refs.TransportOrderReportFilterCondition.displayMessage(this.deselectedSignatureMessage);
        return;
      }
      this.dispatchUrl = url;
      this.$refs.ExportConfirmDialog.openDialog();
    },
    exportReport() {
      this.exportData(this.dispatchUrl).then(() => {
        this.$refs.TransportOrderReportFilterCondition.submit();
      });
    }
  },
  mounted() {
    // 初期ロード時に初期検索情報にて結果を表示
    this.$store.dispatch("report/clearFetchList");
    this.$refs.TransportOrderReportFilterCondition.submit();
  }
};
</script>
