/**
 * マージ：検索結果に更新情報を反映したデータの作成
 * @param {*} stateList 検索結果
 * @param {*} responseList 更新情報リスト
 * @param {*} param2 { searchFunction:マージ対象検索関数, mappingFunction:マージ対象データ構造関数 }
 */
const mergeList = (stateList, responseList, { searchFunction, mappingFunction }) => {
  // map()利用のため配列ではない場合、配列に変換
  let copyList = Array.isArray(stateList) ? [...stateList] : [stateList];
  const copyResponseList = Array.isArray(responseList) ? [...responseList] : [responseList];
  copyResponseList.map(responseItem => {
    const stateIndex = searchFunction(copyList, responseItem);
    if (stateIndex === undefined || stateIndex === null || stateIndex === -1) return;
    if (mappingFunction === undefined) {
      copyList[stateIndex] = { ...copyList[stateIndex], ...responseItem };
    } else {
      copyList[stateIndex] = { ...copyList[stateIndex], ...mappingFunction(responseItem) };
    }
  });
  return copyList;
};

/**
 * ArrayIndex取得：サロゲートキーによる検索
 * @param {*} stateList 検索結果
 * @param {*} responseItem 更新情報
 */
const searchSerial = (stateList, responseItem) => stateList.findIndex(item => item.id === responseItem.id);

/**
 * ArrayIndex取得：荷主ID x 荷主枝番 x 配車日による検索
 * @param {*} stateList 検索結果
 * @param {*} responseItem 更新情報
 */
const searchClient = (stateList, responseItem) =>
  stateList.findIndex(
    item =>
      item.clientId === responseItem.clientId &&
      item.clientBranchId === responseItem.clientBranchId &&
      item.scheduledLoadingDate === responseItem.scheduledLoadingDate
  );

/**
 * ArrayIndex取得：1次委託先会社ID x 配車日による検索
 * @param {*} stateList 検索結果
 * @param {*} responseItem 更新情報
 */
const searchDelivery = (stateList, responseItem) =>
  stateList.findIndex(
    item =>
      item.primaryDeliveryCompanyId === responseItem.primaryDeliveryCompanyId &&
      item.scheduledLoadingDate === responseItem.scheduledLoadingDate
  );

/**
 * ArrayIndex取得：1次委託先会社ID x 配車日による検索
 * @param {*} stateList 検索結果
 * @param {*} responseItem 更新情報
 */
const searchPaymentStatement = (stateList, responseItem) =>
  stateList.findIndex(
    item =>
      item.primaryDeliveryCompanyId === responseItem.primaryDeliveryCompanyId &&
      item.paymentTargetYearMonth === responseItem.paymentTargetYearMonth
  );

/**
 * オブジェクト設定：荷主 情報
 * ※出力用に必要情報を最小限にする。
 * @param {*} element 対象情報
 */
const formatClientRequired = element => ({
  clientId: element.clientId,
  clientBranchId: element.clientBranchId
});

/**
 * オブジェクト設定：1次委託先会社 情報
 * ※出力用に必要情報を最小限にする。
 * @param {*} element 対象情報
 */
const formatDeliveryRequired = element => ({
  deliveryCompanyId: element.primaryDeliveryCompanyId
});

/**
 * オブジェクト設定：カード 情報
 * ※出力用に必要情報を最小限にする。
 * @param {*} element 対象情報
 */
const formatBillingAndPaymentCardRequired = element => ({
  cardId: element.cardId
});

/**
 * オブジェクト設定：荷主 情報
 * ※出力用に必要情報を最小限にする。
 * @param {*} element 対象情報
 */
const formatBillingStatementRequired = element => ({
  clientId: element.clientId,
  clientBranchId: element.clientBranchId,
  billingTargetYearMonth: element.billingTargetYearMonth
});

/**
 * オブジェクト設定：荷主 情報
 * ※出力用に必要情報を最小限にする。
 * @param {*} element 対象情報
 */
const formatPaymentStatementRequired = element => ({
  id: element.statementId,
  primaryDeliveryCompanyId: element.primaryDeliveryCompanyId,
  paymentTargetYearMonth: element.paymentTargetYearMonth
});

/**
 * オブジェクト設定：情報
 * ※出力用に必要情報を最小限にする。
 * @param {*} element 対象情報
 */
const formatPaymentPromptRequired = element => ({
  primaryDeliveryCompanyId: element.primaryDeliveryCompanyId
});

/**
 * オブジェクト設定：荷主 情報
 * @param {*} element 対象情報
 */
const formatClient = element => ({
  ...formatClientRequired(element),
  ...formatMeta(element)
});

/**
 * オブジェクト設定：1次委託先会社 情報
 * @param {*} element 対象情報
 */
const formatDelivery = element => ({
  ...formatDeliveryRequired(element),
  ...formatMeta(element)
});

/**
 * オブジェクト設定：カード 情報
 * @param {*} element 対象情報
 */
const formatBillingAndPayment = element => ({
  ...formatBillingAndPaymentCardRequired(element)
});

/**
 * オブジェクト設定：請求書 情報
 * @param {*} element 対象情報
 */
const formatBillingStatement = element => ({
  ...formatBillingStatementRequired(element)
});

/**
 * オブジェクト設定：支払書 情報
 * @param {*} element 対象情報
 */
const formatPaymentStatement = element => ({
  ...formatPaymentStatementRequired(element)
});

/**
 * オブジェクト設定：支払書 メタ情報
 * @param {*} element 対象情報
 */
const formatPaymentStatementMeta = element => ({
  ...formatPaymentStatementRequired(element),
  ...formatPaymentMeta(element)
});

/**
 * オブジェクト設定：支払書速報 情報
 * @param {*} element 対象情報
 */
const formatPaymentPrompt = element => ({
  ...formatPaymentPromptRequired(element)
});
/**
 * オブジェクト設定：署名・定型文・コメントなど 更新情報
 * @param {*} response レスポンス
 */
const formatMeta = response => ({
  id: response.id,
  scheduledLoadingDate: response.scheduledLoadingDate,
  boilerplateId: response.boilerplateId,
  boilerplateTitle: response.boilerplateTitle,
  signatureId: response.signatureId,
  signatureTitle: response.signatureTitle,
  comment: response.comment,
  updatedAt: response.updatedAt
});

/**
 * オブジェクト設定：送信期限・必着期限 更新情報
 * @param {*} response レスポンス
 */
const formatPaymentMeta = response => ({
  id: response.statementId,
  faxSendPeriod: response.faxSendPeriod,
  arrivePeriod: response.arrivePeriod
});

/**
 * オブジェクト設定：署名・定型文・コメントなど 更新情報
 * @param {*} element リスト情報
 */
const formatMetaRequest = element => {
  let request = {};
  if ("clientId" in element) request.clientId = element.clientId;
  if ("clientBranchId" in element) request.clientBranchId = element.clientBranchId;
  if ("primaryDeliveryCompanyId" in element)
    request.primaryDeliveryCompanyId = element.primaryDeliveryCompanyId;
  request = {
    ...request,
    ...{
      id: element.id,
      scheduledLoadingDate: element.scheduledLoadingDate,
      boilerplateId: element.boilerplateId,
      boilerplateTitle: element.boilerplateTitle,
      signatureId: element.signatureId,
      signatureTitle: element.signatureTitle,
      comment: element.comment,
      updatedAt: element.updatedAt
    }
  };
  return request;
};

/**
 * オブジェクト設定：表示状態の維持
 * @param {*} response レスポンス
 */
const deleteSelectTarget = response => {
  if ("selectTarget" in response) delete response.selectTarget;
  if ("isDisplayCell" in response) delete response.isDisplayCell;
  return response;
};

/**
 * Export：ReportExtractor
 * ※一括でImportできるようにまとめる。
 */
const ReportExtractor = {
  mergeList,
  searchSerial,
  searchClient,
  searchDelivery,
  searchPaymentStatement,
  formatClientRequired,
  formatDeliveryRequired,
  formatClient,
  formatDelivery,
  formatBillingStatement,
  formatPaymentStatement,
  formatPaymentStatementMeta,
  formatPaymentPrompt,
  formatBillingAndPaymentCardRequired,
  formatBillingAndPayment,
  formatMeta,
  formatPaymentMeta,
  formatMetaRequest,
  deleteSelectTarget
};
export { ReportExtractor };
