<template lang="pug">
  table.operation-resource-table
    thead
      tr
        th.title(colspan="14") 車両数
      tr
        th.left-space
        th.day-title(v-for="item in orderQuantityList" :key="item.formatDate" :class="getWeekClass(item)")
          span {{ item.formatDate + "(" + item.dayOfWeekLabel + ")" }}

    tbody
      tr(v-for="vehicle in vehicleTypeList" :key="vehicle.id")
        th.left-header {{ vehicle.vehicleTypeName }}
        td(v-for="item in orderQuantityList" :key="item.targetDate" :class="getWeekClass(item)")
          div
            span {{ getVehicleCount({targetDate:item.targetDate,vehicleCd:vehicle.vehicleTypeCd}) }}
            template(v-if="getExceedCount({targetDate:item.targetDate,vehicleCd:vehicle.vehicleTypeCd}) > 0")
              span.day-exceed ({{ getExceedCount({targetDate:item.targetDate,vehicleCd:vehicle.vehicleTypeCd}) }})
</template>

<script>
import { mapGetters } from "vuex";
import OperationResource from "@/mixin/OperationResource";

export default {
  mixins: [OperationResource],
  components: {},
  data() {
    let headerCss = "font-weight-thin grey--text text--darken-1";
    let sundayCss = "sunDay";
    let otherDayCss = "otherDay";
    return {
      css: {
        headerClass: headerCss,
        sundayClass: sundayCss,
        otherDayClass: otherDayCss
      }
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      getVehicleCount: "operationResource/getVehicleCount",
      getExceedCount: "operationResource/getExceedCount"
    }),
    orderQuantityList: {
      get() {
        return this.$store.state.operationResource.orderQuantityList;
      }
    },
    vehicleTypeList: {
      get() {
        return this.$store.state.operationResource.displayVehicleTypeList;
      }
    }
  }
};
</script>
