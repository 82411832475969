<template lang="pug">
  section()#dispatch-schedule
    section.schedule-body-section
      div.classified-order-card-row
        div.classified-order-card-normal-area
          div.classified-order-card-normal-box
            template(v-for="(card,index) in cards" )

              div(v-if="index !== 0").drag-between-box.between
                div.content-box
                  DispatchFlowIcon
              div.driver-schedule-box.wrap
                ClassifiedCard.content-box(:item="card.cardView" read-only :displayRequiredVehicleTypeIcon="true"
                  @openCardDetailDialog="openCardDetailDialogInject"
                  @openMarkMenu="openMarkMenuInject"
                  @openRemarkEditDialog="openRemarkEditDialogInject"
                )
                VehicleTypeChipLabel(:label="card.matchCardCount" code="90").match-card-count


</template>

<script>
import ClassifiedCard from "@/components/dispatch/ClassifiedCard.vue";
import DispatchFlowIcon from "@/components/dispatch/DispatchFlowIcon.vue";
import VehicleTypeChipLabel from "@/components/common/VehicleTypeChipLabel.vue";

export default {
  components: {
    VehicleTypeChipLabel,
    DispatchFlowIcon,
    ClassifiedCard
  },
  props: {
    cards: {
      type: Array,
      required: true
    }
  },
  inject: ["openCardDetailDialogInject", "openMarkMenuInject", "openRemarkEditDialogInject"]
};
</script>

<style scoped lang="stylus">


.wrap{
  position: relative;
}

.match-card-count{
  position: absolute;
  left: 16px;
  top:-11px;
  background-color #FF9800
  border #FF9800
}
</style>
