import * as Enum from "@/assets/js/enum";
export default {
  methods: {
    isNone(status) {
      return status === Enum.ArrivalStatusType.None.code;
    },
    isBlue(status) {
      return status === Enum.ArrivalStatusType.Blue.code;
    },
    isGreen(status) {
      return status === Enum.ArrivalStatusType.Green.code;
    },
    isYellow(status) {
      return status === Enum.ArrivalStatusType.Yellow.code;
    },
    isOrange(status) {
      return status === Enum.ArrivalStatusType.Orange.code;
    },
    isRed(status) {
      return status === Enum.ArrivalStatusType.Red.code;
    }
  }
};
