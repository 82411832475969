import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  paymentTypeList: []
};

const getters = {};

const actions = {
  async fetchAllPaymentType({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllPaymentTypeList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllPaymentTypeData");
        }
        commit("appendAllPaymentTypeData", value.data.data);
      });
  },
  async fetchPaymentType({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllPaymentType + id).then(value => {
      return value.data.data;
    });
  }
};

const mutations = {
  clearAllPaymentTypeData(state) {
    state.paymentTypeList = [];
  },
  appendAllPaymentTypeData(state, data) {
    if (data != null) {
      state.paymentTypeList = state.paymentTypeList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
