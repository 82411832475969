<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      label="権限"
      append-outer-icon="search"
      :clearable="clearable"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    Dialog(ref="dialog" v-on:select:record="selectItem" :config="config")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import Dialog from "@/components/common/SimpleMasterSelectDialog";

export default {
  components: { Dialog },
  mixins: [ApiHandler, MasterSelectDialog],
  data() {
    return {
      defaultErrorHandlerMethodArg: "id",
      config: {
        title: "権限選択",
        headers: [{ text: "権限名称", value: "roleName", align: "", search: "" }],
        initDispatch: "role/fetchAllRole",
        getStore: function () {
          return this.$store.state.role.roleList;
        },
        width: "30%"
      }
    };
  },
  props: {
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      this.p_code = data.id;
      this.p_label = data.roleName;
    }
  }
};
</script>
