<template lang="pug">
  section
    ReportMetaApplyTargetDialog(ref="ReportMetaApplyTargetDialog" actionName="" @onSave="onClickYes")
    SelectAllButton(@checkAll="onSelectAll()" @unCheckAll="onDeselectAll()")
    v-btn(flat icon @click="onClickOpenApplyTargetDialog" :disabled="isDeselectedAll")
      v-icon(color="green") fact_check
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectAllButton from "@/components/report/SelectAllButton";
import ReportMetaApplyTargetDialog from "@/components/report/ReportMetaApplyTargetDialog";

export default {
  components: {
    SelectAllButton,
    ReportMetaApplyTargetDialog
  },
  data: () => ({}),
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      isDeselectedAll: "report/getIsDeselectedAll"
    })
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("report", {
      fetchToggleSelected: "fetchToggleSelected"
    }),
    /**
     * Event：一括更新 確認ダイアログ（Yes）
     */
    onClickYes(detail) {
      this.$emit("onClickEvent", detail);
    },
    /**
     * Event：全選択
     */
    onSelectAll() {
      this.fetchToggleSelected(true);
    },
    /**
     * Event：全解除
     */
    onDeselectAll() {
      this.fetchToggleSelected(false);
    },
    /**
     * 選択対象に対して一括設定するダイアログを開く
     */
    onClickOpenApplyTargetDialog() {
      this.$refs.ReportMetaApplyTargetDialog.openDialog();
    }
  }
};
</script>
