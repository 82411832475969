<template lang="pug">
  v-menu(v-model="menu"
    :open-on-hover="openOnHover"
    :open-on-click="openOnClick"
    :nudge-right="popNudgeRight"
    :nudge-left="popNudgeLeft"
    :close-on-click="true" transition="false"
    :close-on-content-click="true"
    content-class="classified-card-popup-wrapper"
    :position-x="position.x" :position-y="position.y"
  )
    v-container(ref="dialog")#classified-card-popup
      //積地と卸地
      v-layout(align-center justify-center row fill-height wrap style="margin-bottom:8px").base-area
        v-flex(xs12).base-wrapper
          table.base-table
            thead
              tr(v-if="record.receiptNo !== undefined")
                th
                  span 受付番号
              tr
                th
                  span.base-label 積地
                  span.base-date-label {{ getLoadingDateMD }}
            tbody
              tr(v-for="item in record.loadingBaseList" )
                td
                  span.base-name-label {{ item.baseName }}
                  span.shipment-box(v-if="findShipmentNo(item.baseId) != null")
                    span.shipment-box-title SPNo
                    span.shipment-box-no {{ findShipmentNo(item.baseId) }}

          table.base-table.un-loading-base-table
            thead
              tr(v-if="record.receiptNo !== undefined")
                th
                  span.receipt-no {{ record.receiptNo }}
              tr
                th
                  span.base-label 卸地
                  span.base-date-label {{ getUnloadingDateMD }}
            tbody
              tr(v-for="item in record.unloadingBaseList" )
                td
                  span.base-name-label {{ item.baseName }}
                  span(v-if="isSpecifiedTime(item)").specified-time-wrapper
                    span.specified-time-label {{ getSpecifiedTimeText(item) }}

      v-layout(row align-start wrap v-if="!isSlideRemarkEmpty || !isRemarkEmpty").remark-area
        v-flex(xs12)
          table.remark-table
            tr(v-if="!isRemarkEmpty")
              th.remark-title カード備考
              td
                span.remark-wrapper
                  span.remark-normal() {{ record.remark }}
            tr(v-if="!isSlideRemarkEmpty")
              th.slide-remark-title スライド備考
              td
                span.remark-wrapper
                  span.remark-slide() {{ slideRemark }}
</template>

<script>
import * as Enum from "@/assets/js/enum";

export default {
  props: {
    isDynamic: {
      type: Boolean,
      required: false,
      default: () => false
    },
    openOnHover: {
      type: Boolean,
      default: () => false
    },
    openOnClick: {
      type: Boolean,
      default: () => false
    },
    popMaxWidth: {
      type: Number,
      default: () => 900
    }
  },
  data() {
    return {
      menu: false,
      orderId: null,
      orderType: null,
      record: {},
      slideRemark: "",
      event: {
        mouseX: 0,
        mouseY: 0
      },
      isLeftNudge: true,
      popOffset: 16,
      position: {
        x: 0,
        y: 0
      },
      progress: {
        card: false
      },
      contents: {
        width: 0,
        height: 0
      }
    };
  },
  computed: {
    //ローディング表示可否
    isDisplayLoadingProgress() {
      return this.progress.card;
    },
    cardDetailAction: {
      get() {
        if (this.isDynamic) {
          return "dynamic/fetchDynamicCardDetail";
        } else {
          return "dispatch/fetchDispatchCardDetail";
        }
      }
    },
    cardSlideRemarkAction: {
      get() {
        if (this.isDynamic) {
          return "dynamic/fetchSlideCardRemark";
        } else {
          return "dispatch/fetchSlideCardRemark";
        }
      }
    },
    rowCount: {
      get() {
        if (this.record.loadingBaseList === undefined) {
          return 0;
        }
        return Math.max(this.record.loadingBaseList.length, this.record.unloadingBaseList.length);
      }
    },
    popNudgeLeft: {
      get() {
        if (this.isLeftNudge) {
          return this.contents.width + this.cardWidthOffset * 2;
        } else {
          return 0;
        }
      }
    },
    popNudgeRight: {
      get() {
        if (this.isLeftNudge) {
          return 0;
        } else {
          return this.popOffset;
        }
      }
    },
    cardWidthOffset() {
      return 30;
    },
    getLoadingDateMD() {
      if (this.record.loadingDate === undefined) return "";
      const [, month, day] = this.record.loadingDate.split("-");
      return `${month}/${day}`;
    },
    getUnloadingDateMD() {
      if (this.record.unloadingDate === undefined) return "";
      const [, month, day] = this.record.unloadingDate.split("-");
      return `${month}/${day}`;
    },
    isRemarkEmpty() {
      return !this.record.remark || this.record.remark.length < 1;
    },
    isSlideRemarkEmpty() {
      return !this.slideRemark || this.slideRemark.length < 1;
    },
    isRelayCard() {
      return this.record.orderType === Enum.OrderType.Relay.code;
    }
  },
  methods: {
    openTrigger(args) {
      this.progress.card = true;
      this.fetchData(args.item.orderId, args.item.orderType).then(() => {
        this.$nextTick(() => {
          if (!this.progress.card) {
            return;
          }
          const cardDom = document.getElementById("classified-card-popup").parentNode;
          cardDom.style.display = "block";
          cardDom.style.opacity = 0;
          cardDom.style.pointerEvents = "auto";
          this.contents.width = cardDom.clientWidth;
          this.contents.height = cardDom.clientHeight;

          //マウスポインタの位置から、右方向にコンテンツを描画するスペースがあるかチェック
          const drawSpaceWidth = window.screen.availWidth - args.event.clientX - this.cardWidthOffset;
          this.isLeftNudge = drawSpaceWidth < this.contents.width;
          this.position.x = args.event.clientX + this.cardWidthOffset;
          this.position.y = args.event.clientY - this.contents.height;

          this.progress.card = false;
          cardDom.style.opacity = 1;
          this.menu = true;
        });
      });
    },
    closeTrigger(event) {
      this.$nextTick(() => {
        const cardDom = document.getElementById("classified-card-popup").parentNode;
        cardDom.style.opacity = 0;
        cardDom.style.zIndex = -1;
        cardDom.style.pointerEvents = "none";
        this.progress.card = false;
        this.menu = false;
      });
    },
    findShipmentNo(baseId) {
      if (this.record.slipList !== undefined && this.record.slipList.length > 0) {
        const findBase = this.record.slipList.filter(value => value.loadingBaseId === baseId).pop();

        return findBase !== undefined ? findBase.shipmentNo : null;
      } else {
        return null;
      }
    },
    clear() {},
    isAnySpecifiedTime() {
      let ret = false;
      if (this.rowCount > 0) {
        this.record.unloadingBaseList.forEach(value => {
          if (this.isSpecifiedTime(value)) {
            ret = true;
          }
        });
      }
      return ret;
    },
    isSpecifiedTime(unloadingBase) {
      return unloadingBase.specifiedTimeTo || unloadingBase.specifiedTimeFrom;
    },
    getSpecifiedTimeText(unloadingBase) {
      if (this.isSpecifiedTime(unloadingBase)) {
        return (
          this.getTimeFormat(unloadingBase.specifiedTimeFrom) +
          " - " +
          this.getTimeFormat(unloadingBase.specifiedTimeTo)
        );
      } else {
        return "";
      }
    },
    getTimeFormat(timeString) {
      if (timeString) {
        const [h, m] = timeString.split(":");
        return `${h}:${m}`;
      } else {
        return "";
      }
    },
    async fetchData(orderId, orderType) {
      this.orderId = orderId;
      this.orderType = orderType;

      await this.fetchCardData();
      await this.$store
        .dispatch(this.cardSlideRemarkAction, {
          orderId: this.orderId,
          orderType: this.orderType
        })
        .then(value => {
          this.slideRemark = value != null ? value.remark : "";
          return Promise.resolve();
        });
    },
    async fetchCardData() {
      await this.$store
        .dispatch(this.cardDetailAction, {
          orderId: this.orderId,
          orderType: this.orderType
        })
        .then(value => {
          if (value) {
            if (this.isDynamic) {
              this.dynamicData = value;
              this.record = this.dynamicData.detail;
            } else {
              this.record = value;
            }
          }
          return Promise.resolve(value);
        })
        .catch(error => {
          const event = document.createEvent("Event");
          event.initEvent(window.Common.Config.observer.onErrorResponse404, false, true);
          window.dispatchEvent(event);
          return Promise.resolve(error);
        });
    }
  }
};
</script>

<style lang="stylus" scoped>
#classified-card-popup{
  background-color #FFF;
  //max-height: 580px;

  $borderColor1 = #dcdcdc
  $titleColor = #9e9e9e

  .card-title{
    font-weight bold
    margin-right 16px
    color $titleColor
  }

  .base-date-label{
    margin-left 16px
    font-size 1.2em
    color rgba(0,0,0,0.65)
  }

  .receipt-no{
    font-size 1.2em
    color rgba(0,0,0,0.65)
  }

  .un-loading-date-title{
    margin-left 24px
  }

  .loading-date{
    font-size 1.5em
  }

  .base-area{
    margin-top 16px
    padding 0 8px 8px 8px
    //border 1px solid $borderColor1
    border-left 4px solid $borderColor1
    border-right 4px solid $borderColor1

    .base-wrapper{
      white-space nowrap
    }
  }

  .specified-time-wrapper{
      margin-left 16px
      .specified-time-label{
        font-size 1.2em
        font-weight bold
        color rgba(0,0,0,0.65)
      }
    }

  .remark-area{
    margin-top 24px
  }

  .base-table{
    display inline-table
    th {
      color $titleColor
    }
    th,td{
      padding 8px 0
      text-align left
      border-bottom 1px solid $borderColor1
    }

    td{
      padding-left 16px
    }
  }

  .base-name-label{
    background-color #f5f5f5
    padding 4px 8px
  }

  .un-loading-base-table{
    margin-left 16px
  }

  .remark-table{
    width 100%
    th {
      color $titleColor
      vertical-align top
      text-align left

      &.remark-title{
        width 110px
      }

      &.slide-remark-title{
        width 110px
      }
    }
    td{
      text-align left
    }
  }

  .remark-wrapper{
    display inline-block

    .remark-normal{
      white-space pre-wrap
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .remark-slide{
      white-space pre-wrap
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.shipment-box {
  display block
  padding: 8px 0 8px 8px;
  white-space nowrap

  .shipment-box-title {
    margin-right: 8px;
    font-size 0.8em
  }

  .shipment-box-no{
    font-size 1.2em
    font-weight bold
    color rgba(0,0,0,0.65)
  }
}
</style>
