<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="vehicleTypeName"
    item-value="id"
    :label="labelDisp"
    multiple
    small-chips
    :clearable="clearable"
    :box="readonly"
    :readonly="readonly"
    :error-messages="errorHandlerMethod('drivableVehicleTypeIdList')"
    v-on:blur="changeItem")
</template>

<script>
export default {
  props: {
    // TODO:type:Arrayと思われるがnullの可能性があるため回避
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default() {
        return null;
      }
    },
    labelDisp: {
      type: String,
      default: "運転可能車格"
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return this.$store.state.vehicle.vehicleTypeList;
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    changeItem(data) {
      this.$emit("changeItem", this.p_code);
    },
    /**
     * データの読み込み
     */
    loadListData() {
      this.$store.dispatch("vehicle/fetchAllVehicleType", {});
    }
  }
};
</script>

<style scoped></style>
