import { render, staticRenderFns } from "./DispatchDriverSelect.vue?vue&type=template&id=a84aeb64&scoped=true&lang=pug"
import script from "./DispatchDriverSelect.vue?vue&type=script&lang=js"
export * from "./DispatchDriverSelect.vue?vue&type=script&lang=js"
import style0 from "./DispatchDriverSelect.vue?vue&type=style&index=0&id=a84aeb64&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a84aeb64",
  null
  
)

export default component.exports