<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      ImportOrderForm(@importedFile="importedFile" @upload="clearWorkData")
      BjiOrderWorkList(class="mt-4" ref="WorkList" @commitedFile="commitedFile")
      BjiOrderCommitResult(ref="CommitList")
</template>

<script>
import ImportOrderForm from "@/components/import/ImportOrderForm";
import BjiOrderWorkList from "@/components/import/BjiOrderWorkList";
import BjiOrderCommitResult from "@/components/import/BjiOrderCommitResult";

export default {
  components: {
    ImportOrderForm,
    BjiOrderWorkList,
    BjiOrderCommitResult
  },
  data: function () {
    return {};
  },
  methods: {
    importedFile() {
      this.$refs.CommitList.clearData();
      this.$refs.WorkList.loadData();
    },
    commitedFile(data) {
      this.$refs.CommitList.loadData(data);
    },
    clearWorkData() {
      this.$refs.WorkList.clearWorkData();
    }
  }
};
</script>
