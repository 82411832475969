/**
 * 数値とおぼしき文字列に3桁カンマを付与する
 */
window.Vue.filter("addComma", function (value) {
  const commaFunc = function separate(num) {
    // 文字列にする
    if (num === null) {
      return "";
    } else {
      num = String(num);
    }

    // 整数部取り出し
    let num1 = "";
    if (num > 0) num1 = String(Math.floor(num));
    else num1 = String(Math.ceil(num));

    // 小数部取り出し
    let nums = String(num).split(".");
    let num2 = "";
    if (nums.length > 1) {
      num2 = String(num).split(".")[1];
    }

    const len1 = num1.length;
    const len2 = num2.length;

    if (len1 > 0 && !isNaN(Number(num))) {
      if (len2 > 0) {
        return Number(num).toLocaleString("ja-JP", { maximumFractionDigits: len2 });
      }
      return Number(num).toLocaleString();
    } else {
      return num;
    }
  };

  return commaFunc(value);
});

/**
 * カンマを改行コード(\n\r)に変換する
 * css whitespace:pre  で改行表示させるため
 */
window.Vue.filter("commaToNr", function (value) {
  return String(value).replace(/,/g, "\n\r");
});

window.Vue.filter("arrayToStringAndNr", function (arrayValue) {
  if (arrayValue instanceof Array) {
    return arrayValue.join(",").replace(/,/g, "\n\r");
  }

  return arrayValue;
});
