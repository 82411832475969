<template lang="pug">
  v-layout(v-if="userData !== null && userData.lastUpdated")
    v-flex(md12)
      v-card
        v-card-text
          v-layout
            v-flex(md6)
              table.update-user-table
                tr
                  th 最終更新日
                  td {{formatFullDateTime(userData.lastUpdated)}}
                tr
                  th 最終更新者
                  td {{userData.lastUpdateUserName}}
            v-spacer
            v-flex(md2)
              v-btn(color="success" v-show="displayHistoryButton" @click="clickHistoryButton") 履歴を確認する
</template>

<script>
import DateUtils from "@/mixin/DateUtils";
export default {
  mixins: [DateUtils],
  props: {
    userData: {
      type: Object,
      require: false,
      default: () => null
    },
    displayHistoryButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    clickHistoryButton() {
      this.$emit("openHistoryDialog");
    }
  }
};
</script>

<style scoped lang="stylus">
table.update-user-table {
  th, td {
    padding 8px
  }

  th,td{
    white-space nowrap
  }
}
</style>
