<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          VehicleTypeSelect(:code.sync="conditions.vehicleTypeId" :clearable="true")
        v-flex(md2)
          v-text-field.text-align-right(label="積載重量(最小)" v-model="conditions.carryWeightMin" step="0.01" type="number" @keydown.69.187.189.107.109.prevent) // e,+,- は除去
        v-flex(md2)
          v-text-field.text-align-right(label="積載重量(最大)" v-model="conditions.carryWeightMax" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
        v-flex(md2)
          v-text-field.text-align-right(label="最大積載重量(最小)" v-model="conditions.maxCarryWeightMin" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
        v-flex(md2)
          v-text-field.text-align-right(label="最大積載重量(最大)" v-model="conditions.maxCarryWeightMax" step="0.01" type="number" @keydown.69.187.189.107.109.prevent)
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";

export default {
  components: {
    SearchConditions,
    VehicleTypeSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          vehicleTypeName: "",
          vehicleTypeId: "",
          carryWeightMin: "",
          carryWeightMax: "",
          maxCarryWeightMin: "",
          maxCarryWeightMax: ""
        }
      };
    }
  }
};
</script>
