<template lang="pug">
  EditDialog(ref="EditDialog" title="車庫" :recordId="record.id" @delete="erase" @save="save"
      :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field(v-model="record.name" label="車庫名称" :error-messages="getErrorMessages('name')")

          v-flex(xs12 md6)
            AreaSelect(:label.sync="record.areaName" :code.sync="record.areaId" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md6)
            PrefSelect(:label.sync="record.prefectureName" :code.sync="record.prefId" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md12)
            v-text-field(v-model="record.address" label="住所" :error-messages="getErrorMessages('address')")
</template>

<script>
import AreaSelect from "@/components/common/select/AreaSelect";
import PrefSelect from "@/components/common/select/PrefSelect";
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    AreaSelect,
    PrefSelect,
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {
        id: null,
        name: "",
        areaId: "",
        prefId: "",
        address: ""
      }
    };
  },
  methods: {
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
