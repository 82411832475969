export default {
  methods: {
    isSunday(num) {
      return num === 7;
    },
    isSaturday(num) {
      return num === 6;
    },
    getWeekClass(item) {
      return { saturday: this.isSaturday(item.dayOfWeekNum), sunday: this.isSunday(item.dayOfWeekNum) };
    }
  }
};
