<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FilterConditions(@onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              ClientBranchTable(ref="ClientBranchTable" :prop-header="clientBranchTableHeader" :prop-data="clientBranchList" :getListActionName="getListActionName" detail-url="clientBranch/fetchClientBranch" @open-detail="openDetail" :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-center(width="30px") {{ body.id }}
                  td.text-xs-left {{ body.client.clientDisplayName }}
                  td.text-xs-left {{ body.clientLoadingBaseClass.baseClassName }}
                  td.text-xs-left {{ body.clientUnloadingBaseClass.baseClassName }}
                  td.text-xs-left {{ body.branchName }}
                  td.text-xs-left {{ getFaxTarget(body.serviceContactFaxTarget) }}
                  td.text-xs-left {{ getFaxTarget(body.equalClient ? body.client.billingStatementFaxTarget : body.billingStatementFaxTarget) }}

    ClientBranchEditDialog(ref="ClientBranchEditDialog" :postUrl="$config.urls.postEditClientBranch" :deleteUrl="$config.urls.deleteClietnBranch"
      @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('clientBranch/export')")
</template>

<script>
import { mapGetters } from "vuex";
import ClientBranchEditDialog from "@/components/management/client-branch/ClientBranchEdit";
import ClientBranchTable from "@/components/common/PagingListTable";
import FilterConditions from "@/components/management/client-branch/ClientBranchFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    ClientBranchEditDialog,
    ClientBranchTable,
    FilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getFaxTarget: "site/faxTargetLabel"
    }),
    clientBranchList: {
      get() {
        return this.$store.state.clientBranch.clientBranchList;
      }
    },
    getListActionName: {
      get() {
        return "clientBranch/fetchAll";
      }
    },
    clientBranchTableHeader: {
      get() {
        return [
          { text: "ID" },
          { text: "荷主" },
          { text: "積地分類" },
          { text: "卸地分類" },
          { text: "荷主枝番" },
          { text: "車番連絡表FAX送信対象" },
          { text: "請求書FAX送信対象" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    // 新規作成ダイアログの表示
    openAddDialog() {
      this.$refs.ClientBranchEditDialog.openDialog();
    },
    // 詳細ダイアログの表示
    openDetail(item) {
      this.$refs.ClientBranchEditDialog.openDialog(item);
    },
    // データの読み込み
    loadListData() {
      this.$refs.ClientBranchTable.loadData();
    },
    // レコード追加・更新・削除時に検索同様ページ番号0にして再取得
    itemUpdated(response, data) {
      this.$refs.ClientBranchTable.resetPageCount();
      this.loadListData();
    },
    // 検索条件欄に入力されたら発火
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.ClientBranchTable.setSearchConditions(searchCond);
      this.$refs.ClientBranchTable.resetPageCount();
      this.loadListData();
    },
    //出力確認ダイアログの表示
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  }
};
</script>
