<template lang="pug">
  v-content
    v-container.login-container
      v-layout(align-center justify-center row fill-height)
        v-flex(d-inline-block)
          v-card.px-4.py-3
            v-card-text
              p.login-logo
                img(alt="logo" src="../assets/logo/main.png")
              alert-box(ref="errorMsg")
              v-form(@submit.prevent="authMail" ref="form" v-if="!isPassForm && !isResetForm")
                v-card-title ※登録時のメールアドレスを入力してください。
                v-text-field(
                  ref="txt_mail"
                  label="メールアドレス"
                  prepend-icon="mail"
                  v-model="inputs.mail"
                  :error-messages="getErrorMessages('mail')"
                )
                v-btn(block color="primary" dark class="mt-4" @click="authMail" :loading="loading.authMail") 送信
              v-form(@submit.prevent="oneTimePass" ref="onetime_form" v-if="isPassForm && !isResetForm")
                v-card-title ※メール記載の6桁の認証番号を入力してください。
                v-text-field(
                  ref="txt_onetime"
                  label="ワンタイムパスワード"
                  prepend-icon="lock"
                  v-model="inputs.pin"
                  autofocus
                  :error-messages="getErrorMessages('pin')"
                )
                v-btn(block color="primary" dark class="mt-4" @click="oneTimePass" :loading="loading.onetime") 送信
              v-form(@submit.prevent="resetPass" ref="reset_form" v-if="isPassForm && isResetForm")
                v-card-title ※8桁以上の半角英数字を入力してください。
                v-text-field(
                  ref="txt_reset"
                  label="新しいパスワード"
                  prepend-icon="lock"
                  type="password"
                  v-model="inputs.password"
                  :append-icon="isShowPassword ? 'visibility_off' : 'visibility'"
                  :type="isShowPassword ? 'text' : 'password'"
                  @click:append="isShowPassword = !isShowPassword"
                  autofocus
                  :error-messages="getErrorMessages('password')"
                )
                v-text-field(
                  ref="txt_reset_confirm"
                  label="新しいパスワードの再入力（確認）"
                  prepend-icon="lock"
                  type="password"
                  v-model="inputs.passwordConfirm"
                  :append-icon="isShowPasswordConfirm ? 'visibility_off' : 'visibility'"
                  :type="isShowPasswordConfirm ? 'text' : 'password'"
                  @click:append="isShowPasswordConfirm = !isShowPasswordConfirm"
                  :error-messages="getErrorMessages('passwordConfirm')"
                )
                v-btn(block color="primary" dark class="mt-4" @click="resetPass" :loading="loading.resetPass") 送信
              p.password-remind-box
                a(@click="goLogin") 戻る
    SuccessDialog(ref="SuccessDialog" @ok="goLogin")
</template>

<script>
import AlertBox from "@/components/common/AlertBox";
import Axios from "@/assets/js/axios";
import SuccessDialog from "@/components/common/dialog/ChangePasswordSuccessDialog";

let $http = Axios.http;

export default {
  name: "ForgetPassword",
  components: {
    "alert-box": AlertBox,
    SuccessDialog
  },
  data: () => ({
    isPassForm: false,
    isResetForm: false,
    isShowPassword: false,
    isShowPasswordConfirm: false,
    inputs: {
      mail: "",
      pin: "",
      password: "",
      passwordConfirm: ""
    },
    errors: {},
    loading: {
      authMail: false,
      onetime: false,
      resetPass: false
    }
  }),
  created: function () {
    const self = this;

    const raiseError = message => {
      self.$refs.errorMsg.displayMessage(message);
    };

    window.addEventListener(
      window.Common.Config.observer.onErrorResponseNetworkError,
      () => raiseError(window.Common.Messages.Common.Error.NetworkError),
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onErrorResponse500,
      () => raiseError(window.Common.Messages.Common.Error.InternalServerError),
      false
    );
    this.$nextTick(() => this.$refs.txt_mail.focus());
  },
  destroyed() {
    window.removeEventListener(window.Common.Config.observer.onErrorResponseNetworkError, () =>
      this.displayErrorMessage(window.Common.Messages.Common.Error.NetworkError)
    );
    window.removeEventListener(window.Common.Config.observer.onErrorResponse500, () =>
      this.displayErrorMessage(window.Common.Messages.Common.Error.NetworkError)
    );
  },
  methods: {
    displayErrorMessage(message) {
      this.$refs.errorMsg.displayMessage(message);
    },
    async authMail(data) {
      this.loading.authMail = true;
      $http
        .post(this.$config.urls.postAuthMail, this.inputs)
        .then(res => {
          this.isPassForm = true;
          this.$refs.errorMsg.alert = false;
          this.$nextTick(() => {
            this.$refs.txt_onetime.focus();
            this.$refs.onetime_form.reset();
          });
        })
        .catch(error => {
          this.setErrorMessage(error);
          this.$refs.errorMsg.displayMessage(error.response.data.message);
        })
        .finally(() => {
          this.loading.authMail = false;
        });
    },
    async oneTimePass() {
      this.loading.onetime = true;
      $http
        .post(this.$config.urls.postAuthPin, this.inputs)
        .then(res => {
          this.isResetForm = true;
          this.$refs.errorMsg.alert = false;
          this.$nextTick(() => {
            this.$refs.txt_reset.focus();
            this.$refs.reset_form.reset();
          });
        })
        .catch(error => {
          this.setErrorMessage(error);
          this.$refs.errorMsg.displayMessage(error.response.data.message);
        })
        .finally(() => {
          this.loading.onetime = false;
        });
    },
    async resetPass() {
      this.loading.resetPass = true;
      $http
        .post(this.$config.urls.postSavePassword, this.inputs)
        .then(res => {
          this.$refs.SuccessDialog.openDialog();
        })
        .catch(error => {
          this.setErrorMessage(error);
          this.$refs.errorMsg.displayMessage(error.response.data.message);
        })
        .finally(() => {
          this.loading.resetPass = false;
        });
    },
    goLogin() {
      this.$router.push({ name: "login" });
    },
    getErrorMessages(fieldId) {
      if (this.errors == null || this.errors[fieldId] == null) {
        return "";
      } else {
        return this.errors[fieldId].join("\n");
      }
    },
    setErrorMessage(error) {
      this.errors = error.response.data["fieldErrors"];
    }
  }
};
</script>

<style scoped lang="stylus">
.password-remind-box {
  margin-top: 32px;
  text-align: right;
}

.login-logo {
  text-align: center;

  img {
    width: 50%;
  }
}

$boxSize = 550px;

.login-container {
  @media (max-width: $boxSize) {
    width: 90%;
  }

  @media (min-width: $boxSize) {
    width: $boxSize;
  }

  height: 100%;
}
</style>
