<template lang="pug">
  HistoryDialog(ref="HistoryDialog" title="タリフ"
    :leftData="tariffHistoryList" :oldUpdateUser="oldUpdateUser" :newUpdateUser="newUpdateUser" @incrementPageCount="incrementHistoryTablePageCount"
    @selectLine="selectLine" @terminate="terminate" load-url="tariff/fetchTariffHistoryList" :targetId="targetId")
    template(v-slot:rightTop)
      TariffHistoryTable(:diffArrayData="newRows")

    template(v-slot:rightBottom)
      TariffHistoryTable(:diffArrayData="oldRows")

</template>

<script>
import HistoryDialog from "@/components/common/dialog/HistoryDialog";
import TariffHistoryTable from "@/components/management/tariff/TariffHistoryTable";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    HistoryDialog,
    TariffHistoryTable
  },
  data() {
    return {
      targetId: null
    };
  },
  computed: {
    ...mapGetters({
      tariffHistoryList: "tariff/tariffHistoryList",
      tariffHistoryDiff: "tariff/tariffHistoryDiff"
    }),
    newRows: {
      get() {
        return this.tariffHistoryDiff != null ? this.tariffHistoryDiff.newRows : null;
      }
    },
    oldRows: {
      get() {
        return this.tariffHistoryDiff != null ? this.tariffHistoryDiff.oldRows : null;
      }
    },
    oldUpdateUser: {
      get() {
        return this.tariffHistoryDiff != null ? this.tariffHistoryDiff.oldUpdateUser : null;
      }
    },
    newUpdateUser: {
      get() {
        return this.tariffHistoryDiff != null ? this.tariffHistoryDiff.newUpdateUser : null;
      }
    }
  },
  methods: {
    ...mapMutations({
      incrementHistoryTablePageCount: "tariff/incrementHistoryTablePageCount",
      clearHistoryTablePageCount: "tariff/clearHistoryTablePageCount"
    }),
    ...mapActions("tariff", {
      fetchTariffHistoryList: "fetchTariffHistoryList",
      fetchTariffDiff: "fetchTariffDiff",
      clearHistoryData: "clearHistoryData"
    }),
    openDialog(data) {
      this.clearHistoryTablePageCount();
      this.targetId = data.id;
      this.fetchTariffHistoryList(data.id).then(value => {
        this.$refs.HistoryDialog.initialize();
      });
      this.$refs.HistoryDialog.openDialog();
    },
    closeDialog() {
      this.$refs.HistoryDialog.closeDialog();
    },
    selectLine(data) {
      this.fetchTariffDiff(data.id);
    },
    terminate() {
      this.clearHistoryData();
    }
  }
};
</script>

<style scoped lang="stylus"></style>
