<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力
                  v-btn(color="info" to="/tms/management/fixed-billing-rate/import") インポート

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FixedBillingRateFilterConditions(@onChangeSearchConditions="onChangeSearchConditions" ref="FixedBillingRateFilterConditions")

            v-card-text
              section.table-scroll-x.sticky-container(ref="container")
                FixedBillingRateTable.content-table(ref="FixedBillingRateTable" :prop-header="tableHeaderAddVehicleTypeList" :prop-data="dataList" @open-detail="openDetail" detail-url="fixedBillingRate/fetchFixedBillingRate" :getListActionName="getListActionName" displaySlotBody)
                  template(v-slot:body="{body}")
                    td {{ body.client.clientDisplayName }}
                    td {{ body.clientBranch.branchName }}
                    td {{ body.loadingBase.nameAndCd }}
                    td {{ body.unloadingBase.nameAndCd }}
                    td {{ body.applicableStartDate }}
                    td
                      v-tooltip(top)
                        template(v-slot:activator="{ on }")
                          p(v-on="on").remark.whitespace-pre {{ body.remark }}
                        span.whitespace-pre {{ body.remark }}
                    template(v-for="detail in body.fixedBillingRateDetails")
                      td.text-xs-right
                        DigitSeparatorsLabel(:value="detail.price")

    FixedBillingRateEditDialog(ref="FixedBillingRateEditDialog" :postUrl="$config.urls.postEditFixedBillingRate" :deleteUrl="$config.urls.deleteFixedBillingRate" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('fixedBillingRate/export')")
</template>

<script>
import FixedBillingRateEditDialog from "@/components/management/fixed-billing-rate/FixedBillingRateEdit";
import FixedBillingRateTable from "@/components/common/PagingListTable";
import FixedBillingRateFilterConditions from "@/components/management/fixed-billing-rate/FixedBillingRateFilterConditions";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import { LimitNumber } from "@/assets/js/constants";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    FixedBillingRateEditDialog,
    FixedBillingRateTable,
    FixedBillingRateFilterConditions,
    DigitSeparatorsLabel,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {
      vehicleTypeList: [],
      oldScrollTop: null
    };
  },
  computed: {
    dataList: {
      get() {
        return this.$store.state.fixedBillingRate.fixedBillingRateList;
      }
    },
    getListActionName: {
      get() {
        return "fixedBillingRate/fetchAll";
      }
    },
    tableHeader: {
      get() {
        return [
          { text: "荷主", value: "client.clientDisplayName", centerAlign: "" },
          { text: "荷主枝番", value: "clientBranch.branchName", centerAlign: "" },
          { text: "積地", value: "loadingBase.displayBaseName", centerAlign: "" },
          { text: "卸地", value: "unloadingBase.displayBaseName", centerAlign: "" },
          { text: "適用開始日", value: "applicableStartDate", centerAlign: true },
          { text: "備考", value: "remark", centerAlign: "" }
        ];
      }
    },
    tableHeaderAddVehicleTypeList: {
      get() {
        let tableHeaderAddVehicleTypeList = this.tableHeader.slice();
        this.vehicleTypeList.forEach(vehicleType => {
          tableHeaderAddVehicleTypeList.push({ text: vehicleType.vehicleTypeName });
        });
        return tableHeaderAddVehicleTypeList;
      }
    }
  },
  mounted() {
    this.loadListData();
    this.$refs.container.removeEventListener("scroll", this.handleScroll);
    this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.FixedBillingRateEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.FixedBillingRateEditDialog.openDialog(item);
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.FixedBillingRateTable.loadData();
      // テーブルヘッダー用に車格取得
      this.$store.dispatch("tariff/fetchTariffUseVehicleTypeList").then(data => {
        this.vehicleTypeList = data;
      });
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.FixedBillingRateTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.container.scrollTop = 0;
      this.$refs.FixedBillingRateTable.setSearchConditions(searchCond);
      this.$refs.FixedBillingRateTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    },
    handleScroll() {
      if (this.$refs.container !== undefined) {
        const clientHeight = this.$refs.container.clientHeight;
        const scrollTop = this.$refs.container.scrollTop;
        const scrollHeight = this.$refs.container.scrollHeight;
        if (this.oldScrollTop === scrollTop) return;
        // 横スクロール用にスクロールトップ退避
        this.oldScrollTop = scrollTop;

        if (
          scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber &&
          !this.$refs.FixedBillingRateTable.getProgress()
        ) {
          this.$refs.FixedBillingRateTable.addPageCount();
          this.$refs.FixedBillingRateTable.loadData();
        }
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
>>> .sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
}

>>> .sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
	top: 0;
  z-index: 1;
}
</style>
