<template lang="pug">
  EditDialog(ref="EditDialog" title="動態追跡" :enableSave="false" :displayDeleteButton="false" :hasTitleSuffix="false" width="50%"
    :loadingSave="loading.save")
    template(v-slot:body)
      v-container(grid-list-md)
        v-container(grid-list-md)
          v-layout(row wrap)
            v-flex(xs12)
              TrackingCardIcon.tracking-icon(:arrivalStatus="record.arrivalStatus")
              span {{getArrivalStatusLabel(record.arrivalStatus)}}
              v-text-field(readonly box label="目標拠点名" v-model="record.baseName")
            v-flex(xs12)
              table.sticky_table
                thead
                  tr
                    th 位置情報送信日時
                    th 残り時間
                    th 残り距離（m）
                    th 判定種別
                    th マップリンク
                tbody
                  tr(v-for="item in record.trackingList")
                    td.text-xs-center {{item.positioningAt}}
                    td.text-xs-right {{item.time}}
                    td.text-xs-right {{item.distance}}
                    td.text-xs-left {{item.decisionType}}
                    td.text-xs-center
                      v-btn(icon flat @click="openMap(item)")
                        v-icon() my_location


</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";
import TrackingCardIcon from "@/components/dynamic/TrackingCardIcon";

export default {
  components: {
    EditDialog,
    TrackingCardIcon
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {
        baseName: "",
        arrivalStatus: "00"
      }
    };
  },
  computed: {},
  methods: {
    openDialog(item) {
      this.record = item;
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    getArrivalStatusLabel(cd) {
      return Enum.Utils.findLabel(cd, Enum.ArrivalStatusType);
    },
    openMap(item) {
      const url = "https://www.google.co.jp/maps/?q=" + item.latitude + "," + item.longitude;
      window.open(url, "mapWindow");
    }
  }
};
</script>

<style lang="stylus" scoped>
.sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: -16px;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-card__text {
  padding-top: 0;
}

.footer {
  position: sticky;
  bottom: 0;
}

table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  font-size: 15px;
  padding-left: 4px;
  padding-right: 4px;
}

.th-text {
  font-size: 13px;
}

.targetSelectCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracking-icon {
  display: inline-block;
}
</style>
