<template lang="pug">
  EditDialog(ref="EditDialog" title="距離" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete" :displayDeleteButton="!record.financialCd" @closeEditDialog="closeEditDialog")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md6)
            ClientSelect(:label.sync="record.client.clientDisplayName" :code.sync="record.client.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'client.id'")
          v-flex(xs12 md6)
            ClientBranchSelect(:clientId="record.client.id" :clientBranchId="record.clientBranch.id" :isSelectBjiAll="true" :code.sync="record.clientBranch.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'clientBranch.id'")

        v-alert(type="warning" :value="true" v-show="!canSelectBase") 積地・卸地の設定をするには荷主と荷主枝番を選択してください。
        v-layout(row wrap v-show="canSelectBase")
          v-flex(xs12 md6)
            LoadingBaseSelect(:enableDelete="false" :clientId="record.client.id" :clientBranchId="record.clientBranch.id" :label.sync="record.loadingBase.nameAndCd" :code.sync="record.loadingBase.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'loadingBase.id'" @deleteList="")
          v-flex(xs12 md6)
            UnloadingBaseSelect(:enableDelete="false" :clientId="record.client.id" :clientBranchId="record.clientBranch.id" :label.sync="record.unloadingBase.nameAndCd" :code.sync="record.unloadingBase.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'unloadingBase.id'" @deleteList="")

        v-layout(row wrap)
          v-flex(xs12 md6)
            DatePicker(pickerLabel="適用開始日" :initDate="record.applicableStartDate" :inputDate.sync="record.applicableStartDate" :isClearable="false" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'applicableStartDate'")
          v-flex(xs12 md6)
            v-text-field(v-model="record.distance" label="距離" maxlength=5 :error-messages="getErrorMessages('distance')").text-align-right
          v-flex(xs12 md6)
            PaymentTariffTypeSelect(:label.sync="record.paymentTariffType.name" :code.sync="record.paymentTariffType.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'paymentTariffType.id'")
          v-flex(xs12 md6)
            TariffSelect(:label.sync="record.billingTariff.tariffName" :code.sync="record.billingTariff.tariffCd" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'billingTariff.tariffCd'")
          v-flex(xs12 md4)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")
          v-flex(xs12 md4)
            v-checkbox(label="高速代フラグ" v-model="record.tollActualCostFlg" hide-details)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-textarea(v-model="record.remark" label="備考" :error-messages="getErrorMessages('remark')" maxlength=1000)
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ClientSelect from "@/components/common/select/ClientSelect";
import ClientBranchSelect from "@/components/common/select/ClientBranchSelect";
import LoadingBaseSelect from "@/components/common/select/LoadingBaseSelect";
import UnloadingBaseSelect from "@/components/common/select/UnloadingBaseSelect";
import PaymentTariffTypeSelect from "@/components/common/select/PaymentTariffTypeSelect";
import TariffSelect from "@/components/common/select/TariffSelect";
import DatePicker from "@/components/common/DatePicker";
import StatusSelect from "@/components/common/select/StatusSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    ClientSelect,
    ClientBranchSelect,
    LoadingBaseSelect,
    UnloadingBaseSelect,
    PaymentTariffTypeSelect,
    TariffSelect,
    DatePicker,
    StatusSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: this.initData()
    };
  },
  computed: {
    /**
     * 積地卸地が選択可能かどうか
     **/
    canSelectBase: {
      get() {
        return this.record.client.id && this.record.clientBranch.id;
      }
    }
  },
  watch: {
    "record.client.id": function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }
      this.record.clientBranch = this.initData().clientBranch;
      this.record.loadingBase = this.initData().loadingBase;
      this.record.unloadingBase = this.initData().unloadingBase;
    },
    "record.clientBranch.id": function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }
      this.record.loadingBase = this.initData().loadingBase;
      this.record.unloadingBase = this.initData().unloadingBase;
    }
  },
  methods: {
    initData() {
      return {
        id: null,
        client: {
          id: null
        },
        clientBranch: {
          id: null
        },
        loadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        },
        unloadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        },
        paymentTariffType: {
          id: null
        },
        billingTariff: {
          tariffCd: null,
          tariffName: null
        },
        distance: null,
        applicableStartDate: null,
        status: Enum.ActiveFlag.Active.code,
        tollActualCostFlg: false,
        remark: null
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    closeEditDialog() {
      this.record = this.initData();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
