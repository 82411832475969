import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  list: [],
  selectList: [],
  signatureList: []
};

const getters = {
  getList: state => {
    return state.list;
  },
  getSelectList: state => {
    return state.selectList;
  },
  /**
   * GET：IDによる情報取得
   * @param {*} state Store
   * @param {Number} id ID
   */
  getSelectInfo: state => id => {
    const info = state.selectList.find(element => element.id === id) || {
      body: "",
      id: 0,
      status: "",
      title: "",
      updatedAt: ""
    };
    return info;
  }
};

const actions = {
  /**
   * マスタメンテ一覧画面用の全件検索取得
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<*[] | *>}
   */
  async fetchAllSignature({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllSignatureList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllSignatureData");
        }
        commit("appendAllSignatureData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * 単レコード取得用
   *
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<*[] | *>}
   */
  async fetchSignature({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getAllSignature + id).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  /**
   * セレクトボックス用の全件取得。基本的にページング不要（件数が少ない）だがクリア処理が必要なので入れておく
   *
   * @param commit
   * @param state
   * @param getters
   * @param getParams
   * @returns {Promise<*[] | *>}
   */
  async fetchSelectList({ commit, state, getters }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getSignatureSelectList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearSelectList");
        }
        commit("writeSelectList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * FETCH：選択項目リスト初期化
   * @param { commit } param0 Store
   */
  async fetchClearSelectList({ commit }) {
    await commit("clearSelectList");
  }
};

const mutations = {
  clearAllSignatureData(state) {
    state.signatureList = [];
  },
  appendAllSignatureData(state, data) {
    if (data != null) {
      state.signatureList = state.signatureList.concat(data);
    }
  },
  clearList(state) {
    state.list = [];
  },
  writeList(state, data) {
    if (data != null) {
      state.list = state.list.concat(data.list);
    }
  },
  clearSelectList(state) {
    state.selectList = [];
  },
  writeSelectList(state, list) {
    if (list != null) {
      state.selectList = state.selectList.concat(list);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
