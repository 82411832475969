<template>
  <v-toolbar class="footer">
    <v-btn v-if="recordId" color="error" @click="deleteRecord">削除</v-btn>
    <v-spacer></v-spacer>
    <v-btn v-if="displaySave" color="primary" @click="save">保存</v-btn>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    recordId: {
      type: Number,
      default: () => null
    },
    displaySave: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    save() {
      this.$emit("save");
    },
    deleteRecord() {
      this.$emit("deleteRecord");
    }
  }
};
</script>

<style>
.footer {
  position: sticky;
  bottom: 0;
}
</style>
