<template lang="pug">
  EditDialog(ref="EditDialog" title="積地" :recordId="record.id"
    @delete="erase" @save="save" @closeEditDialog="closeDialogCallback"
    :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md2)
            v-text-field(v-model="record.baseCd" label="積地CD" :error-messages="getErrorMessages('baseCd')")

          v-flex(xs12 md8)
            ClientSelect(:label.sync="record.client.clientDisplayName" :code.sync="record.client.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'client.id'")

          v-flex(xs12 md2)
            ClientLoadingBaseClassSelect(:label.sync="record.clientLoadingBaseClass.baseClassName"
              :clientId="record.client.id"
              :code.sync="record.clientLoadingBaseClass.id"
              :errorHandlerMethod="getErrorMessages"
              :errorHandlerMethodArg="'clientLoadingBaseClass.id'")

          v-flex(xs12 md9)
            v-text-field(v-model="record.baseName" label="積地名称" :error-messages="getErrorMessages('baseName')")

          v-flex(xs12 md3)
            v-text-field(v-model="record.baseShortName" label="略称" :error-messages="getErrorMessages('baseShortName')")

          v-flex(xs12 md12)
            PrefSelect(:label.sync="record.prefecture.name" :code.sync="record.prefecture.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'prefecture.id'")

          v-flex(xs12 md6)
            v-text-field(v-model="record.city" label="市町村" :error-messages="getErrorMessages('city')")

          v-flex(xs12 md6)
            AreaSelect(:label.sync="record.area.name" :code.sync="record.area.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'area.id'")

          v-flex(xs12 md6)
            v-text-field.text-align-right(v-model="record.location.latitude" label="緯度" :error-messages="getErrorMessages('location.latitude')")

          v-flex(xs12 md6)
            v-text-field.text-align-right(v-model="record.location.longitude" label="経度" :error-messages="getErrorMessages('location.longitude')")

          v-flex(xs12 md6)
            v-text-field(v-model="record.tel" label="電話番号" :error-messages="getErrorMessages('tel')")

          v-flex(xs12 md6)
            v-text-field(v-model="record.fax" label="FAX番号" :error-messages="getErrorMessages('fax')")

          v-flex(xs12 md6)
            VehicleOptionSeparateSelect(:code.sync="record.optionIdList" :errorHandlerMethod="getErrorMessages" :clearable="true")

          v-flex(xs12 md6)
            LiftTypeSelect(:code.sync="record.requireLiftType" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md12)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md12)
            v-textarea(v-model="record.remark" label="備考" :error-messages="getErrorMessages('remark')")

</template>

<script>
import AreaSelect from "@/components/common/select/AreaSelect";
import PrefSelect from "@/components/common/select/PrefSelect";
import ClientSelect from "@/components/common/select/ClientSelect";
import ClientLoadingBaseClassSelect from "@/components/common/select/ClientLoadingBaseClassSelect";
import LiftTypeSelect from "@/components/common/select/LiftTypeSelect";
import StatusSelect from "@/components/common/select/StatusSelect";
import EditDialog from "@/components/common/dialog/EditDialog";
import VehicleOptionSeparateSelect from "@/components/common/select/VehicleOptionSeparateSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    AreaSelect,
    PrefSelect,
    LiftTypeSelect,
    ClientSelect,
    ClientLoadingBaseClassSelect,
    StatusSelect,
    EditDialog,
    VehicleOptionSeparateSelect
  },
  mixins: [ApiHandler, EditForm],
  watch: {
    "record.client.id": function (newVal, oldVal) {
      if (oldVal) {
        this.record.clientLoadingBaseClass = this.initData().clientLoadingBaseClass;
      }
    }
  },
  data() {
    return {
      record: {}
    };
  },
  created() {
    this.record = this.initData();
  },
  methods: {
    initData: () => {
      return {
        id: null,
        baseCd: null,
        baseName: null,
        baseShortName: null,
        client: {
          id: null,
          clientName: null
        },
        clientLoadingBaseClass: {
          baseClassName: null,
          clientId: null,
          id: null
        },
        prefecture: {
          id: null,
          name: null
        },
        city: null,
        area: {
          id: null,
          name: null
        },
        location: {
          longitude: null,
          latitude: null
        },
        requireLiftType: null,
        tel: null,
        fax: null,
        specifiedTime: {
          from: null,
          to: null
        },
        remark: null,
        status: null,
        optionIdList: []
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.record.optionIdList = this.record.optionIdList.map(optionId => optionId.toString());
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    closeDialogCallback() {
      this.record = this.initData();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
