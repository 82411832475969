<template lang="pug">
  v-select(
    v-model="p_code"
    :readonly="readonly"
    :box="readonly"
    :items="masterList"
    item-text="label"
    :item-value="itemValue"
    :label="labelDisp"
    :small-chips="useFilter"
    :multiple="useFilter"
    :clearable="clearable"
    :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")
</template>

<script>
import * as Enum from "@/assets/js/enum";
export default {
  props: {
    // TODO:type:Boolean 必須でありながらNullがはいる可能性があるため回避。（※clearable影響）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default() {
        return null;
      }
    },
    errorHandlerMethodArg: {
      type: String,
      default() {
        return null;
      }
    },
    useFilter: {
      type: Boolean,
      default() {
        return false;
      }
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    labelDisp: {
      type: String,
      default: "会計種別"
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "financeType"
    };
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return Enum.Utils.enumToList(Enum.FinanceType);
      }
    },
    errorHandlerMethodArgComputed: {
      get() {
        if (this.errorHandlerMethodArg == null) {
          return this.defaultErrorHandlerMethodArg;
        }
        return this.errorHandlerMethodArg;
      }
    },
    itemValue: {
      get() {
        if (this.useFilter) {
          return "label";
        } else {
          return "code";
        }
      }
    }
  },
  methods: {}
};
</script>
