<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="ログインID" v-model="conditions.loginCd")
        v-flex(md2)
          v-text-field(label="氏名" v-model="conditions.name")
        v-flex(md4)
          v-text-field(label="メールアドレス" v-model="conditions.mail")
        v-flex(md2)
          v-select(v-model="conditions.locked" :items="AccountLockList" item-text="label" item-value="code" label="アカウントロック状態" clearable)
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import StatusSelect from "@/components/common/select/StatusSelect";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    SearchConditions,
    StatusSelect
  },
  computed: {
    AccountLockList() {
      return Enum.Utils.enumToList(Enum.AccountLockType);
    }
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          loginCd: "",
          name: "",
          mail: "",
          locked: null,
          status: null
        }
      };
    }
  }
};
</script>
