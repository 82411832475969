<template lang="pug">
  v-dialog(v-model="dialog" persistent width="500" @keydown.esc="ok")
    v-card
      v-toolbar(dark :color="$store.getters['site/siteColor']")
        span  {{ title }}
        v-spacer

      v-card-text
        v-layout
          v-flex
            div(class="ma-3") {{ message }}

      v-toolbar.footer
        v-spacer
        v-btn(color="primary" @click="ok") OK
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "パスワード変更"
    },
    message: {
      type: String,
      required: false,
      default: "パスワードの変更が完了しました。"
    }
  },
  data() {
    return {
      dialog: false,
      text: null
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    ok() {
      this.$emit("ok");
      this.closeDialog();
    }
  }
};
</script>
