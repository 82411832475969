<template lang="pug">
  v-btn(flat icon color="grey")
    v-icon(v-on:click="callReload") loop
</template>

<script>
export default {
  methods: {
    callReload() {
      this.$emit("callReload");
    }
  }
};
</script>
