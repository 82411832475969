<template lang="pug">
  section.pattern-card-box.v-card(:class="[{ 'editable': editable },{ 'pointer-dblclick': editable },{'dragging-hover':css.over},{'dragging-select':css.start}]"
    :draggable="editable"
    @mousedown="mouseDown"
    @dblclick="onDblClick"
    @dragstart="dragStart(argument,$event)",
    @dragend="dragEnd(argument,$event)",
    @dragenter.self="dragEnter(argument,$event)",
    @dragleave.self="dragLeave(argument,$event)",
    @drop="drop(argument,$event)",
    @dragover.prevent="dragOver(argument,$event)"
  )
    section.card-section
      section.client-box
        div
          v-icon(small).label-icon fas fa-building
        div.client-box-name
          span {{card.clientName}}
          br
          span {{card.clientBranchName}}

        div
          VehicleTypeChipLabel(:label="card.vehicleTypeName" :code="card.vehicleTypeCd")

      section.bases
        div.base-name-box
          template(v-for="base in card.loadingBaseList")
            v-layout(row wrap :class="getDecisionTypeClass(base)")
              v-flex(xs9)
                span.base-cd ({{base.baseCd}})
                span.base-name {{base.baseName}}
              v-flex(xs3)
                span.base-area-name {{base.areaName}}

            //div ({{base.baseCd}}){{base.baseName}}*{{base.areaName}}*
        div.allow-box
          v-icon(small) arrow_drop_down
        div.base-name-box
          template(v-for="base in card.unloadingBaseList")
            v-layout(row wrap :class="getDecisionTypeClass(base)")
              v-flex(xs9)
                span.base-cd ({{base.baseCd}})
                span.base-name {{base.baseName}}
              v-flex(xs3)
                span.base-area-name {{base.areaName}}
</template>

<script>
import Draggable from "@/mixin/draggable";
import VehicleTypeChipLabel from "@/components/common/VehicleTypeChipLabel.vue";
import * as Enum from "@/assets/js/enum";

export default {
  components: { VehicleTypeChipLabel },
  props: {
    card: {
      required: true,
      type: Object
    },
    editable: {
      required: false,
      type: Boolean,
      default: false
    },
    index: {
      required: false,
      type: Number,
      default: -999
    }
  },
  mixins: [Draggable],
  methods: {
    onDblClick() {
      if (!this.editable) {
        return;
      }

      this.$emit("activate", this.card);
    },
    getDecisionTypeClass(base) {
      if (base.decisionType === Enum.DispatchPatternDecisionType.Area.code) {
        return "decision-area";
      }

      if (base.decisionType === Enum.DispatchPatternDecisionType.Base.code) {
        return "decision-base";
      }

      return "";
    }
  },
  computed: {
    argument: {
      get() {
        return { index: this.index, card: this.card };
      }
    }
  }
};
</script>

<style scoped lang="stylus">
/* 親 */
.pattern-card-box {
  width: 370px;
  padding: 8px 12px;
  transition: 0.2s;

  display: flex;
  flex-direction: column;

  background-color: #FFFFFF;
}

.label-icon {
  margin-right: 4px;
}

.card-section {
  pointer-events: none;


  .client-box {
    margin-bottom: 8px;
    display: flex;

    .client-box-name {
      flex-grow: 3;
    }
  }

  .base-name-box {
    background-color: #f5f5f5;
    padding: 8px;

    .base-cd {
      font-size: 0.8em;
      color: #757575;
    }
  }
}

.dragging-select {
  opacity: 0.4;
}

.dragging-hover {
  background-color: #E5E5E5
}

.editable:hover {
  background-color: #fdfdfd;
}

.allow-box {
  text-align: center;
  padding: 4px 0;
}


.decision-area {
  .base-area-name {
    font-weight: bolder;
  }
}

.decision-base {
  .base-name {
    font-weight: bolder;
  }
}
</style>
