<template lang="pug">
  section
    FinanceMasterImport(
      backUrlPath="/tms/management/fixed-billing-rate"
      :uploadFileUrl="getUploadFileUrl"
      :uploadValidationUrl="getUploadValidationUrl"
      :commit-import-url="getCommitImportUrl"
      :fetchWorkListUrl="fetchWorkListUrl"
      :target-count-url="fetchWorkListCountUrl"
      :uploaded-at-action="uploadedAtAction"
      :isFilterError="isFilterError"
      :toggleFilterAction="toggleFilter"
    )
      template(v-slot:table)
        FixedBillingImportWorkListTable(ref="WorkTable" :getListActionName="getListActionName" :isFilterError="isFilterError")
</template>

<script>
import FixedBillingImportWorkListTable from "@/components/management/fixed-billing-rate/FixedBillingImportWorkListTable";
import FinanceMasterImport from "@/components/import/FinanceMasterImport";
import AxiosBase from "@/assets/js/axios-base";
import { mapActions, mapGetters } from "vuex";

let axiosBase = new AxiosBase();

export default {
  components: {
    FixedBillingImportWorkListTable,
    FinanceMasterImport
  },
  methods: {
    ...mapActions("financeImport", {
      uploadFile: "uploadImportFile",
      commitImport: "commitImport",
      clearWorkData: "clearWorkData",
      fetchWorkList: "fetchWorkList",
      toggleErrorOnly: "toggleErrorOnly"
    }),
    uploadedAtAction() {
      this.toggleErrorOnly(false);
      this.$refs.WorkTable.loadUploadData();
    },
    toggleFilter() {
      this.toggleErrorOnly(!this.isFilterError);
      this.$refs.WorkTable.loadUploadData();
    }
  },
  computed: {
    ...mapGetters("financeImport", {
      isFilterError: "isFilterError"
    }),
    getListActionName: () => "financeImport/fetchWorkList",
    getUploadFileUrl: () => axiosBase.getUrls().uploadFixedBillingRateImportWork,
    getUploadValidationUrl: () => axiosBase.getUrls().uploadFixedBillingRateImportWorkValidation,
    getCommitImportUrl: () => axiosBase.getUrls().uploadFixedBillingRateImportWorkCommit,
    fetchWorkListUrl: () => axiosBase.getUrls().getFixedBillingWorkList,
    fetchWorkListCountUrl: () => axiosBase.getUrls().getFixedBillingWorkListCount
  }
};
</script>
