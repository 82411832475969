<template lang="pug">
  v-card(elevation=0 :class="{'driver-rest':isDriverRest, 'driver-result':hasDispatchResult,'remark-driver':driver.hasRemark}").driver-card
    v-layout(justify-start column fill-height)
      //乗務員名
      v-flex(v-show="isExists" xs12)
        v-layout(align-center justify-start row fill-height)
          v-flex.text-xs-center(xs2)
            v-icon(small) fas fa-user
          v-flex.text-truncate(xs8)
            span
              span(v-if="!readOnly").driver-seq-no {{ driverIndex + 1 }}
              span {{ driver.driverName }}
          v-flex.text-xs-right(xs1)
            LiftTypeIcon(:liftType="driver.handlableLiftType" :displayTypeNone="false")
      //所属企業名
      v-flex(v-show="isExists" xs12)
        v-layout(align-center justify-start row fill-height)
          v-flex.text-xs-center(xs2)
            v-icon(small) fas fa-building
          v-flex.text-truncate(xs10)
            v-tooltip(top)
              template(v-slot:activator="{ on }")
                p(v-on="on").company-name.whitespace-pre.text-truncate {{ driver.companyName }} {{ driver.department }}
              span.whitespace-pre {{ driver.companyName }} {{ driver.department }}

      //車両情報
      v-flex(v-show="isExists" xs12)
        v-layout(align-center justify-start row fill-height)
          v-flex.text-xs-center(xs2)
            VehicleTypeChipLabel(:label="driver.currentVehicleTypeName" :code="driver.currentVehicleTypeCd")
          v-flex(xs8)
            span {{ driver.currentCarryWeightWithUnit }} ({{ driver.currentVehicleNumber }})
          v-flex(xs2)
            YenTipLabel(ref="YenTipLabel" @triggerOpenYenTip="triggerOpenYenTip")

      v-flex(v-show="isExists" xs12)
        v-layout(align-center justify-start row fill-height)
          v-flex.text-xs-right.icons
            v-btn(icon flat color="grey" small disabled v-if="driver.hasMailAddress")
              DriverMailIcon(:mailStatus="driver.firstDispatchMailStatusType")
            v-btn(icon flat color="grey" small disabled v-if="driver.hasMailAddress")
              DriverMailIcon(:mailStatus="driver.allDispatchMailStatusType")
            v-btn(icon flat color="grey" small @click="toggleRemarkEditDialog" v-show="!driver.hasRemark" )
              RemarkStatusIcon(:hasRemark="driver.hasRemark")
            v-btn(icon flat color="grey" small)
              FavoriteIcon(:favorite="driver.isFavorite" @toggle="toggleFavorite")
            v-btn(icon flat color="grey" small @click="toggleDriverContextMenu" v-show="!readOnly")
              v-icon more_vert
            v-btn(icon flat color="grey" small @click="openDriverDetail" v-show="readOnly" )
              v-icon info

      v-flex(v-show="driver.hasRemark" xs12 )
        v-layout(align-center justify-start row fill-height)
          v-flex.text-xs-center(xs2)
            v-btn(icon flat color="grey" small @click="toggleRemarkEditDialog")
              RemarkStatusIcon(:hasRemark="driver.hasRemark")
          v-flex.text-xs-left(xs10)
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  span(v-on="on" style="margin-left:5px").remark-box.whitespace-nowrap {{ driver.remark }}
                span {{ driver.remark }}
</template>

<script>
import OneChip from "../common/OneCharChipLabel";
import DriverMailIcon from "@/components/dispatch/DriverMailStatusIcon";
import FavoriteIcon from "@/components/dispatch/FavoriteIcon";
import LiftTypeIcon from "@/components/dispatch/LiftTypeIcon";
import RemarkStatusIcon from "@/components/dispatch/RemarkStatusIcon";
import YenTipLabel from "@/components/common/YenTipLabel";
import VehicleTypeChipLabel from "@/components/common/VehicleTypeChipLabel.vue";

export default {
  components: {
    VehicleTypeChipLabel,
    OneChip,
    DriverMailIcon,
    FavoriteIcon,
    LiftTypeIcon,
    RemarkStatusIcon,
    YenTipLabel
  },
  data() {
    return {
      show: false
    };
  },
  props: {
    driver: {
      type: Object,
      required: true
    },
    driverIndex: {
      type: Number,
      required: false,
      default: () => null
    },
    readOnly: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    vehicleOneChar() {
      return this.driver.currentVehicleTypeName == null ? "未" : this.driver.currentVehicleTypeName.charAt();
    },
    vehicleTypeCode() {
      return this.driver.currentVehicleTypeCd;
    },
    areaOneChar() {
      return "";
    },
    isExists() {
      return this.driver.driverId != null;
    },
    isDriverRest() {
      return this.driver.shiftType === "00";
    },
    hasDispatchResult() {
      return this.driver.hasDispatchResult;
    }
  },
  methods: {
    getAreaColorClass(id) {
      return "area-color-" + id;
    },
    getVehicleColorClass(code) {
      return "vehicle-color-" + code;
    },
    toggleDriverContextMenu(event) {
      event.preventDefault();
      this.$emit("openDriverContextMenu", {
        item: this.driver,
        event: event
      });
    },
    openDriverDetail() {
      this.$emit("openDriverDetail", this.driver);
    },
    toggleFavorite(favorite) {
      this.$emit("toggleFavorite", {
        driver: this.driver,
        favorite: favorite
      });
    },
    delClick() {
      this.$emit("delDriver", {
        index: this.driverIndex,
        driver: this.driver
      });
    },
    toggleRemarkEditDialog(event) {
      event.preventDefault();
      this.$emit("openRemarkEditDialog", this.driver);
    },
    triggerOpenYenTip(event) {
      this.$refs.YenTipLabel.openTrigger({
        item: this.driver,
        event: event
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.driver-rest {
  background-color: #B0BEC5 !important;
}

.driver-result {
  border: 1px solid #4CAF50;
  background-color: #C8E6C9 !important;
}

.remark-box {
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.dispatch {
  #dispatch-schedule {
    .driver-schedule-row {
      .remark-driver {
        height: 180px;
      }
    }
  }
}


.company-name {
  margin 0
}
</style>
