import * as Enum from "@/assets/js/enum";

export default {
  data() {
    return {
      target: {
        record: {}
      }
    };
  },

  methods: {
    getShiftType(code, defaultColor) {
      const matchShiftType = this.shiftItems.find(item => {
        return item.code === code;
      });

      if (!matchShiftType) {
        return {
          label: "未入力",
          code: "99",
          icon: "remove",
          color: defaultColor === undefined ? "" : defaultColor,
          lightColor: defaultColor === undefined ? "" : defaultColor
        };
      } else {
        return matchShiftType;
      }
    }
  },
  computed: {
    shiftItems: {
      get() {
        return Enum.Utils.enumToList(Enum.ShiftType);
      }
    }
  }
};
