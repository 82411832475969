<template lang="pug">
  EditDialog(ref="EditDialog" title="エリア" :recordId="record.id" @delete="erase" @save="save"
      :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field( v-model="record.name" label="エリア名" :error-messages="getErrorMessages('name')")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "../../../mixin/ApiHandler";
import EditForm from "../../../mixin/EditForm";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      title: "エリア編集",
      record: {
        id: null,
        areaCd: null,
        name: null
      },
      initValues: {
        id: null,
        areaCd: null,
        name: null
      }
    };
  },
  methods: {
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
