<template lang="pug">
  v-dialog(v-model="dialog" persistent width="500" @keydown.esc="closeDialog")
    v-card
      v-toolbar(dark :color="$store.getters['site/siteColor']")
        v-icon(left color="white darken-2" large) help
        span  {{ title }}
        v-spacer
        v-btn(flat icon @click="closeDialog")
          v-icon close

      v-card-text
        v-layout
          v-flex
            div(class="ma-3") {{ message }}

      v-toolbar.footer
        v-spacer
        v-btn(color="primary" @click="yes") 解除する
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "スライド設定解除確認"
    },
    message: {
      type: String,
      required: false,
      default: "スライド設定解除をしますか？"
    }
  },
  data() {
    return {
      dialog: false,
      text: null,
      params: {}
    };
  },
  methods: {
    openDialog(item) {
      this.params = item;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    yes() {
      this.$emit("yes");
      this.$store
        .dispatch("dispatch/releaseDispatchSlide", this.params)
        .then(() => {
          this.$emit("yes");
        })
        .catch(reason => {
          this.$emit("error", reason.message);
          this.closeDialog();
        })
        .finally(() => this.closeDialog());
    }
  }
};
</script>
