<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      label="基本車両"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")


    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="'vehicle/fetchAllUsableVehicle'")

</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    companyId: {
      type: Number,
      required: false,
      default: () => null
    },
    drivableVehicleTypeIdList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "regularUseVehicleId",
      config: {
        title: "基本車両選択",
        headers: [
          {
            text: "車番",
            key: "fullNumberDisp",
            align: ""
          },
          {
            text: "車格",
            key: "vehicleTypeName",
            align: ""
          },
          {
            text: "積載重量(値以上)",
            key: "carryWeight",
            rightAlign: "true",
            align: "text-align-right"
          },
          {
            text: "車庫",
            key: "vehicleGarageName",
            align: ""
          }
        ]
      },
      initSearchCond: () => {
        return {
          deliveryCompanyId: this.companyId,
          vehicleTypeIdList: this.drivableVehicleTypeIdList,
          fullNumberDisp: "",
          vehicleTypeName: "",
          carryWeightDisp: "",
          vehicleGarageName: ""
        };
      }
    };
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      let isChanged = this.p_code !== data.id;
      this.p_code = data.id;
      this.p_label = data.fullNumberDisp;
      if (isChanged) this.$emit("changeItem", data);
    }
  }
};
</script>
