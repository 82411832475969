<template lang="pug">
  table.sticky_table.dispatch-select-cond-table
    thead
      tr
        th(v-for="(header, index) in propHeader")
          div(class="py-3 th-text font-weight-thin grey--text text--darken-1") {{ header.text }}

    tbody
      tr(v-if="getTableDataList.length" v-for="item in getTableDataList" v-bind:class="{ active: isSelected(item.id) }" @click="clickRow(item)")
        td(width="150px") {{ item.cd }}
        td(width="300px") {{ item.name }}
        td(width="490px")
          template(v-for="rec in item.clientBranches")
            BaseFilterChip(:chipName="rec.name" :active="isSelectedClientBranch(rec.id)" @changeFilter="clickBranch(rec, item)")
        //slot(v-if="displaySlotBody" name="body" v-bind:body="item")

      tr(v-if="!getTableDataList.length")
        td(:colspan="propHeader.length") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import BaseFilterChip from "@/components/common/BaseFilterChip";

export default {
  components: {
    BaseFilterChip
  },
  props: {
    propHeader: {
      type: Array,
      required: false,
      default() {
        return [
          { text: "荷主CD", value: "clientCd", centerAlign: false },
          { text: "名称", value: "clientDisplayName", centerAlign: "" },
          { text: "枝番", value: "clientBranchName", centerAlign: "" }
        ];
      }
    },
    propData: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    displaySlotBody: {
      type: Boolean,
      default() {
        return false;
      }
    },
    usePaging: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      dataHeader: [],
      selected: [],
      selectedClientBranch: [],
      dialog: false
    };
  },
  computed: {
    getTableDataList: {
      get() {
        return this.propData;
      }
    }
  },
  mounted() {
    this.dataHeader = this.propHeader;
  },
  methods: {
    /**
     * 詳細ダイアログを開く
     **/
    clickRow(item) {
      if (this.selected.indexOf(item.id) >= 0) {
        this.selected.splice(this.selected.indexOf(item.id), 1);
        // 荷主枝番全選択解除
        item.clientBranches.map(clientBranch => {
          clientBranch.toggle = false;
          if (this.selectedClientBranch.indexOf(clientBranch.id) >= 0) {
            this.selectedClientBranch.splice(this.selectedClientBranch.indexOf(clientBranch.id), 1);
          }
        });
        this.$emit("clickBranch", this.selectedClientBranch);
      } else {
        this.selected.push(item.id);
        // 荷主枝番全選択
        item.clientBranches.map(clientBranch => {
          clientBranch.toggle = true;
          if (this.selectedClientBranch.indexOf(clientBranch.id) < 0) {
            this.selectedClientBranch.push(clientBranch.id);
          }
        });
        this.$emit("clickBranch", this.selectedClientBranch);
      }
      this.$emit("click", this.selected);
    },
    clickBranch(rec, client) {
      if (this.selectedClientBranch.indexOf(rec.id) >= 0) {
        rec.toggle = false;
        this.selectedClientBranch.splice(this.selectedClientBranch.indexOf(rec.id), 1);
        // 荷主枝番全選択解除
        var selectedData = client.clientBranches.filter(branches => {
          return branches.toggle === true;
        });
        if (selectedData.length < 1 && this.selected.indexOf(client.id) >= 0) {
          this.selected.splice(this.selected.indexOf(client.id), 1);
        }
      } else {
        rec.toggle = true;
        this.selectedClientBranch.push(rec.id);
        // 荷主枝番全選択
        var filterData = client.clientBranches.filter(branches => {
          return branches.toggle === false;
        });
        if (filterData.length < 1 && this.selected.indexOf(client.id) < 0) {
          this.selected.push(client.id);
        }
      }
      this.$emit("clickBranch", this.selectedClientBranch);
    },
    getDataFromKey(item, key) {
      let rslt = item;
      const keyArray = key.split(".");
      for (let i = 0; i < keyArray.length; i++) {
        if (rslt == null) break;
        rslt = rslt[keyArray[i]];
      }
      return rslt == null ? "" : rslt;
    },
    getItemValue(item, header) {
      if (header.displayFunc != null && header.displayFunc instanceof Function) {
        return header.displayFunc(item, header);
      } else {
        return this.getDataFromKey(item, header.value);
      }
    },
    isSelected(id) {
      return this.selected.indexOf(id) >= 0;
    },
    isSelectedClientBranch(id) {
      return this.selectedClientBranch.indexOf(id) >= 0;
    },
    clearSelected() {
      this.selected = [];
      this.selectedClientBranch = [];
    }
  }
};
</script>

<style lang="stylus" scoped>
.sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
