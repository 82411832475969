<template lang="pug">
  EditDialog(ref="EditDialog" title="タリフ" :recordId="record.id" @delete="erase" @save="save" width="80%"
    :loadingSave="loading.save" :loadingDelete="loading.delete"
    :updateUser="record.updateUser" :displayHistoryButton="true" @openHistoryDialog="openHistoryDialog")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md6)
            v-text-field(v-model="record.tariffCd" :box="record.id" :readonly="record.id" label="タリフCD" :error-messages="getErrorMessages('tariffCd')")
          v-flex(xs12 md6)
            DatePicker(:inputDate.sync="record.applicableStartDate" picker-label="適用開始日"  :errorHandlerMethod="getErrorMessages" errorHandlerMethodParam="applicableStartDate")
          v-flex(xs12 md6)
            v-text-field( v-model="record.tariffName" label="タリフ名" :error-messages="getErrorMessages('tariffName')")
          v-flex(xs12 md6)
            v-text-field( v-model="record.tariffNameShort" label="タリフ略称" :error-messages="getErrorMessages('tariffNameShort')")

          v-flex(xs12 md12)
            v-autocomplete(label="タグ" v-model="record.selectTagList" :items="tagList" :search-input.sync="record.inputTagValue"
              @input="clearSearchTagString"
              hide-selected
              item-text="tagName" item-value="id"
              multiple chips deletable-chips clearable
              dont-fill-mask-blanks auto-select-first)
              template(v-slot:no-data)
                div
                  v-btn(color="success" @click="registerTag") 新しく登録する

          v-flex(xs12 md12)
            div.table-scroll
              table.rate-table
                tr
                  th.first-child(width="80px")
                  th.second-child(width="150px")
                    span 距離(Km)

                  template(v-for="header in record.vehicleTypeList")
                    th(width="150px") {{ header.vehicleTypeName }}

                template(v-for="(row,indexRow) in record.tariffDetailRows")
                  tr
                    td.first-child
                      v-btn(v-if="indexRow > 0" fab small @click="removeRow(indexRow)" color="warning")
                        v-icon remove
                    td.second-child
                      v-text-field.input-align-right(v-model="row.distance" background-color="#fff" maxlength=5
                        :error-messages="getErrorMessages('tariffDetailRows[' + indexRow + '].distance')")
                    td(v-for="(col, indexCol) in row.tariffColumns")
                      DigitSeparatorsField(v-model="col.price" :errorMessages="getErrorMessages('tariffDetailRows[' + indexRow + '].tariffColumns[' + indexCol + '].price')")
                  tr
                    v-alert.table-alert-row.alert-ml(type="error" :value="getErrorMessages('tariffDetailRows[' + indexRow + ']')" outline) {{ getErrorMessages('tariffDetailRows[' + indexRow + ']') }}

          v-flex(xs12)
            div.add-btn-row
              v-btn(fab small @click="addRow" color="success")
                v-icon add


        v-layout(row wrap)
          v-flex(xs12 md12)
            v-textarea(v-model="record.remark" label="備考" :error-messages="getErrorMessages('remark')" maxlength=1000)

        v-layout(row wrap)
          v-flex(xs12 md3)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

        TariffHistoryDialog(ref="TariffHistoryDialog")

</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import DatePicker from "@/components/common/DatePicker";
import StatusSelect from "@/components/common/select/StatusSelect";
import TariffHistoryDialog from "@/components/management/tariff/TariffHistoryDialog";
import DigitSeparatorsField from "@/components/common/DigitSeparatorsField";

import * as Enum from "@/assets/js/enum";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    EditDialog,
    DatePicker,
    StatusSelect,
    TariffHistoryDialog,
    DigitSeparatorsField
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      title: "タリフ編集",
      record: this.initData(),
      tagList: []
    };
  },
  computed: {
    menuProp: function () {
      return {
        auto: true,
        overflowY: true,
        closeOnClick: false,
        closeOnContentClick: true,
        openOnClick: true,
        maxHeight: 300
      };
    }
  },
  methods: {
    initData() {
      return {
        id: null,
        tariffCd: null,
        tariffName: null,
        tariffNameShort: null,
        applicableStartDate: null,
        remark: null,
        selectTagList: [],
        inputTagValue: null,
        tariffDetailRows: [],
        vehicleTypeList: [],
        status: Enum.ActiveFlag.Active.code
      };
    },
    initRowData() {
      let columns = [];
      this.record.vehicleTypeList.forEach(value => {
        columns.push({
          id: null,
          vehicleTypeId: value.id,
          vehicleTypeName: value.vehicleTypeName,
          price: null,
          updatedAt: null
        });
      });

      return {
        id: null,
        distance: null,
        tariffColumns: columns
      };
    },
    openDialog(item) {
      this.record = this.initData();
      this.initForm(item);
      this.fetchTariffTagList().then(value => {
        //新規作成時、基準となる車格リストを取得してからダイアログを開く
        if (item === undefined) {
          this.$store.dispatch("tariff/fetchTariffUseVehicleTypeList").then(data => {
            this.record.vehicleTypeList = data;
            this.addRow();
            this.$refs.EditDialog.openDialog();
          });
        } else {
          this.record.selectTagList = this.record.tariffTagList.map(d => {
            return d.id;
          });
          this.$refs.EditDialog.openDialog();
        }
      });
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    },
    async fetchTariffTagList() {
      this.$store.dispatch("tariff/fetchTariffTagList").then(data => {
        this.tagList = data;
      });
    },
    addRow() {
      this.record.tariffDetailRows.push(this.initRowData());
    },
    removeRow(ind) {
      this.record.tariffDetailRows.splice(ind, 1);
      //複数行明細でエラーが存在する時、行削除から追加を行うと以前のエラーが残るため、臨時対応
      let errors = this.errors;
      Object.keys(this.errors).forEach(function (key) {
        if (key.includes("tariffDetailRows")) {
          delete errors[key];
        }
      });
    },
    registerTag() {
      this.$store.dispatch("tariff/saveTariffTag", this.record.inputTagValue).then(tagId => {
        this.fetchTariffTagList().then(value => {
          this.record.selectTagList.push(tagId);
        });
        this.clearSearchTagString();
      });
    },
    clearSearchTagString() {
      this.record.inputTagValue = null;
    },
    openHistoryDialog() {
      this.$refs.TariffHistoryDialog.openDialog(this.record);
    }
  }
};
</script>

<style scoped lang="stylus">
.rate-table .alert-ml {
  margin-left 80px
}

.rate-table .first-child {
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  left: 0;
  z-index: 1;
}

.rate-table .second-child {
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  left: 80px;
  z-index: 1;
}

.add-btn-row {
  padding 9px
}
</style>
