<template lang="pug">
  EditDialog(ref="EditDialog" title="利用者" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md6)
            v-text-field(v-model="record.loginCd" label="ログインID" :error-messages="getErrorMessages('loginCd')")

          v-flex(xs12 md6)
            v-text-field(v-model="record.name" label="氏名" :error-messages="getErrorMessages('name')")

          v-flex(xs12 md12)
            v-text-field(v-model="record.mail" label="メールアドレス" :error-messages="getErrorMessages('mail')")

          v-flex(xs12 md6)
            v-text-field(v-model="record.password" label="パスワード" :append-icon="isShowPassword ? 'visibility_off' : 'visibility'"
                :type="isShowPassword ? 'text' : 'password'" @click:append="isShowPassword = !isShowPassword" :error-messages="getErrorMessages('password')")

          v-flex(xs12 md6)
            v-text-field(v-model="record.passwordConfirm" label="パスワード確認" :append-icon="isShowPasswordConfirm ? 'visibility_off' : 'visibility'"
                :type="isShowPasswordConfirm ? 'text' : 'password'" @click:append="isShowPasswordConfirm = !isShowPasswordConfirm" :error-messages="getErrorMessages('passwordConfirm')")

          v-flex(xs12 md12)
            v-text-field(v-model="record.department" label="所属" :error-messages="getErrorMessages('department')")

          v-flex(xs12 md3)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md3)
            RoleSelect(:label.sync="record.role.roleName" :code.sync="record.roleId" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'roleId'")

          v-flex(xs12 md9)
          v-flex(xs12 md3)
            v-btn(v-if="record.locked" color="success" @click="cancelAccountLock") アカウントロック解除

</template>

<script>
import StatusSelect from "@/components/common/select/StatusSelect";
import DatePicker from "@/components/common/DatePicker";
import EditDialog from "@/components/common/dialog/EditDialog";
import RoleSelect from "@/components/common/select/RoleSelect";

import ApiHandler from "../../../mixin/ApiHandler";
import EditForm from "../../../mixin/EditForm";
import Axios from "@/assets/js/axios";

let $http = Axios.http;

export default {
  components: {
    StatusSelect,
    DatePicker,
    EditDialog,
    RoleSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {
        id: null,
        loginCd: null,
        name: null,
        password: null,
        passwordConfirm: null,
        mail: null,
        department: null,
        roleId: null,
        role: {
          id: null,
          roleName: null
        },
        status: null
      },
      isShowPassword: false,
      isShowPasswordConfirm: false
    };
  },
  methods: {
    cancelAccountLock(data) {
      const self = this;
      $http
        .post(this.$config.urls.postEditAccountLock, this.record)
        .then(value => {
          self.$emit("unlocked");
          self.closeDialog();
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            self.setErrorMessages(error);
          } else {
            this.openErrorMsgBox(error.response.data.message);
          }
        });
    },
    initData() {
      return {
        id: null,
        loginCd: null,
        name: null,
        password: null,
        passwordConfirm: null,
        mail: null,
        department: null,
        roleId: null,
        role: {
          id: null,
          roleName: null
        },
        status: null
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.isShowPassword = false;
      this.isShowPasswordConfirm = false;
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
