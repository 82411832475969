<template lang="pug">
  section
    FinanceMetaApplyTargetDialog(ref="FinanceMetaApplyTargetDialog" :searchCond="searchCond" actionName="")
    SelectAllButton(ref="SelectAllButton" @checkAll="onSelectAll()" @unCheckAll="onDeselectAll()")
    v-btn(flat icon @click="onClickOpenApplyTargetDialog" :disabled="isDeselectedAll")
      v-icon(color="green") fact_check
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectAllButton from "@/components/report/SelectAllButton";
import FinanceMetaApplyTargetDialog from "@/components/finance/FinanceMetaApplyTargetDialog";

export default {
  components: {
    SelectAllButton,
    FinanceMetaApplyTargetDialog
  },
  props: {
    searchCond: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({}),
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      isDeselectedAll: "finance/getIsDeselectedAll",
      isSelectedAll: "finance/getIsSelectedAll",
      isStateSelectAll: "finance/getStateSelectAll"
    })
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("finance", {
      fetchToggleSelected: "fetchToggleSelected"
    }),
    /**
     * Event：全選択
     */
    onSelectAll() {
      this.fetchToggleSelected(true);
    },
    /**
     * Event：全解除
     */
    onDeselectAll() {
      this.fetchToggleSelected(false);
    },
    /**
     * 選択対象に対して一括設定するダイアログを開く
     */
    onClickOpenApplyTargetDialog() {
      this.$refs.FinanceMetaApplyTargetDialog.openDialog();
    },
    onIndeterminate() {
      if (this.isStateSelectAll) {
        this.$refs.SelectAllButton.onIndeterminate(!this.isSelectedAll);
      }
    },
    resetSelectAll() {
      this.$refs.SelectAllButton.unCheckAll();
    }
  }
};
</script>
