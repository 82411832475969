<template lang="pug">
  table.sticky_table.dispatch-select-cond-table
    thead
      tr
        th(v-for="(header, index) in propHeader")
          div(class="py-3 th-text font-weight-thin grey--text text--darken-1") {{ header.text }}

    tbody
      tr(v-if="getTableDataList.length" v-for="item in getTableDataList" v-bind:class="{ active: isSelected(item.id) }" @click="clickRow(item.id)")
        td(v-if="!displaySlotBody" v-for="header in propHeader" v-bind:class="{'text-xs-center': header.centerAlign}") {{ getItemValue(item, header) }}
        slot(v-if="displaySlotBody" name="body" v-bind:body="item")

      tr(v-if="!getTableDataList.length")
        td(:colspan="propHeader.length") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
export default {
  props: {
    propHeader: {
      type: Array,
      required: false,
      default() {
        return [{ text: "名称", value: "name", centerAlign: "" }];
      }
    },
    propData: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    displaySlotBody: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      dataHeader: [],
      selected: [],
      dialog: false
    };
  },
  computed: {
    getTableDataList: {
      get() {
        return this.propData;
      }
    }
  },
  mounted() {
    this.dataHeader = this.propHeader;
  },
  methods: {
    clickRow(id) {
      if (this.selected.indexOf(id) >= 0) {
        this.selected.splice(this.selected.indexOf(id), 1);
      } else {
        this.selected.push(id);
      }
      this.$emit("click", this.selected);
    },
    getDataFromKey(item, key) {
      let rslt = item;
      const keyArray = key.split(".");
      for (let i = 0; i < keyArray.length; i++) {
        if (rslt == null) break;
        rslt = rslt[keyArray[i]];
      }
      return rslt == null ? "" : rslt;
    },
    getItemValue(item, header) {
      if (header.displayFunc != null && header.displayFunc instanceof Function) {
        return header.displayFunc(item, header);
      } else {
        return this.getDataFromKey(item, header.value);
      }
    },
    isSelected(id) {
      return this.selected.indexOf(id) >= 0;
    },
    clearSelected() {
      this.selected = [];
    }
  }
};
</script>

<style lang="stylus" scoped>
.sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
