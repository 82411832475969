<template lang="pug">
  section
    EditDialog(ref="EditDialog" title="一括反映" width="500px"
      :recordId="detail.id"
      :loadingSave="loading.save"
      labelSaveButton="一括反映"
      @save="openConfirmDialog"
      :disabledSave="!detail.statusConfirmed"
      :displayDeleteButton="false"
      :hasTitleSuffix="false")
      template(v-slot:body)
        v-container(grid-list-md)
          v-layout(row wrap)
            v-flex(xs12 md6)
              v-checkbox(color="green" label="確定済に変更" v-model="detail.statusConfirmed")
    ConfirmDialog(ref="ConfirmDialog" @yes="onSave" title="一括反映確認" message="一括で対象に設定を反映します、よろしいですか？" yesButtonTitle="反映する")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import EditDialog from "@/components/common/dialog/EditDialog";
import SelectBoilerplate from "@/components/report/SelectBoilerplate";
import SelectSignature from "@/components/report/SelectSignature";
import ConfirmDialog from "@/components/common/dialog/ConfirmDialog";

export default {
  components: {
    EditDialog,
    SelectBoilerplate,
    SelectSignature,
    ConfirmDialog
  },
  mixins: [ApiHandler],
  data: () => ({
    // 更新情報
    detail: {}
  }),
  computed: {
    // ラベル名
    labelName: () => ({
      boilerplate: "定型文",
      signature: "署名",
      comment: "手書きメモ"
    })
  },
  methods: {
    /**
     * 初期データ
     */
    initData() {
      return {
        detail: {
          statusConfirmed: null
        }
      };
    },
    /**
     * Event：ダイアログを開く
     * ※親→子（このコンポーネント）→孫（slot利用）すべて参照利用。
     */
    openDialog() {
      this.detail = this.initData().detail;
      // mixin:ApiHandlerに情報を設定
      this.errors = {};
      this.$refs.EditDialog.openDialog();
    },
    openConfirmDialog() {
      this.$refs.ConfirmDialog.openDialog();
    },
    /**
     * 保存：一括設定用署名と定型文
     */
    async onSave() {
      this.$emit("onSave", this.detail);
      this.$refs.EditDialog.closeDialog();
    }
  },
  beforeMount() {
    this.detail = this.initData().detail;
  }
};
</script>
