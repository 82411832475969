<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      PaymentTariffTypeFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              PaymentTariffTypeTable(ref="PaymentTariffTypeTable" :prop-header="paymentTariffTypeTableHeader" :prop-data="paymentTariffTypeList" @open-detail="openDetail" detail-url="paymentTariffType/fetchPaymentTariffType" :getListActionName="getListActionName" displaySlotBody)
                template(v-slot:body="{body}")
                  td.text-xs-center(width="30px") {{ body.id }}
                  td {{ body.name }}
                  td(width="150px")
                    v-tooltip(top)
                      template(v-slot:activator="{ on }")
                        p(v-on="on").remark.whitespace-pre {{ body.remark }}
                      span.whitespace-pre {{ body.remark }}

    PaymentTariffTypeEditDialog(ref="PaymentTariffTypeEditDialog" :postUrl="$config.urls.postEditPaymentTariffType" :deleteUrl="$config.urls.deletePaymentTariffType" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import PaymentTariffTypeEditDialog from "@/components/management/payment-tariff-type/PaymentTariffTypeEdit";
import PaymentTariffTypeTable from "@/components/common/PagingListTable";
import PaymentTariffTypeFilterConditions from "@/components/management/payment-tariff-type/PaymentTariffTypeFilterConditions";

export default {
  components: {
    PaymentTariffTypeEditDialog,
    PaymentTariffTypeTable,
    PaymentTariffTypeFilterConditions
  },
  data() {
    return {};
  },
  computed: {
    getListActionName: {
      get() {
        return "paymentTariffType/fetchAllPaymentTariffType";
      }
    },
    paymentTariffTypeList: {
      get() {
        return this.$store.state.paymentTariffType.paymentTariffTypeList;
      }
    },
    paymentTariffTypeTableHeader: {
      get() {
        return [
          { text: "ID", value: "id", centerAlign: "true" },
          { text: "支払タリフ区分名", value: "name", centerAlign: "" },
          { text: "備考", value: "remark", centerAlign: "" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    openAddDialog() {
      this.$refs.PaymentTariffTypeEditDialog.openDialog();
    },
    openDetail(item) {
      this.$refs.PaymentTariffTypeEditDialog.openDialog(item);
    },
    loadListData() {
      this.$refs.PaymentTariffTypeTable.loadData();
    },
    itemUpdated(response, data) {
      this.$refs.PaymentTariffTypeTable.resetPageCount();
      this.loadListData();
    },
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.PaymentTariffTypeTable.setSearchConditions(searchCond);
      this.$refs.PaymentTariffTypeTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>
