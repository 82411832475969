<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      label="積荷種別"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :clearable="clearable"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="'itemType/fetchSelectItemType'")
</template>

<script>
import ApiHandler from "../../../mixin/ApiHandler";
import MasterSelectDialog from "../../../mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      config: {
        title: "積荷種別選択",
        headers: [
          {
            text: "積荷種別",
            key: "name",
            align: "text-xs-left"
          }
        ],
        initDispatch: "itemType/fetchSelectItemType",
        getStore: function () {
          return this.$store.state.itemType.itemList;
        }
      },
      initSearchCond: () => {
        return {
          name: ""
        };
      }
    };
  },
  methods: {
    selectItem(data) {
      this.p_code = data.id;
      this.p_label = data.name;
    }
  }
};
</script>
