import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  driverNameList: []
};

const getters = {};

const actions = {
  async fetchAllDriverName({ commit, state }, totalGrossWeight) {
    return $axios.get(axiosBase.getUrls().getAllDriverNameList + totalGrossWeight).then(value => {
      commit("writeAllDriverName", value.data.data);
    });
  }
};

const mutations = {
  writeAllDriverName(state, data) {
    state.driverNameList = data == null ? [] : data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
