export default {
  methods: {
    isNothing(specifiedTimeType) {
      return specifiedTimeType === "00";
    },
    isBelong_Master(specifiedTimeType) {
      return specifiedTimeType === "10";
    },
    isSpecified(specifiedTimeType) {
      return specifiedTimeType === "20";
    },
    isMorning_Designation(specifiedTimeType) {
      return specifiedTimeType === "30";
    },
    isNoon_Designation(specifiedTimeType) {
      return specifiedTimeType === "40";
    },
    isAfternoon_Designation(specifiedTimeType) {
      return specifiedTimeType === "50";
    }
  }
};
