<template lang="pug">
  section
    v-text-field(
      readonly clearable
      @click:clear="clear"
      v-model="p_label"
      :label="titleLabel"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond"
      :getListActionName="DispatchName")
      template(v-slot:headerRight)
        v-flex(xs2).dialog-header-right-box
          v-btn(color="primary" @click="openAddDialog") 新規追加
      template(v-slot:body="{body}")
        td.text-xs-left {{ body.name }}
        td {{ getContractTypeLabel(body.contractType) }}
        td {{ body.mobile }}
        td {{ getLiftLabel(body.handlableLiftType) }}
        td {{ body.drivableVehicleTypes }}

</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";
import * as Enum from "@/assets/js/enum";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    companyId: {
      type: Number,
      required: false,
      default: () => null
    },
    primaryDeliveryCompanyId: {
      type: Number,
      required: false,
      default: () => null
    },
    vehicleTypeId: {
      type: Number,
      required: false,
      default: () => null
    },
    titleLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "regularUseVehicleId",
      initSearchCond: () => {
        return {
          name: null,
          contractType: null,
          mobile: null,
          liftType: null,
          drivableVehicleTypeCd: null,
          deliveryCompanyId: this.companyId,
          primaryDeliveryCompanyId: this.primaryDeliveryCompanyId,
          vehicleTypeId: this.vehicleTypeId
        };
      }
    };
  },
  computed: {
    config() {
      return {
        title: this.titleLabel,
        width: "60%",
        headers: [
          {
            text: "氏名",
            key: "name",
            align: ""
          },
          {
            text: "契約",
            key: "contractType",
            align: "",
            select: true,
            items: Enum.Utils.enumToList(Enum.ContractType)
          },
          {
            text: "電話番号",
            key: "mobile",
            align: ""
          },
          {
            text: "リフト",
            key: "liftType",
            rightAlign: "true",
            align: "text-align-right",
            select: true,
            items: Enum.Utils.enumToList(Enum.LiftType)
          },
          {
            text: "運転可能車格",
            key: "drivableVehicleTypeCd",
            align: "",
            select: true,
            items: this.$store.state.site.primaryVehicleTypes
          }
        ]
      };
    },
    /**
     * @return {string}
     */
    DispatchName() {
      return "driver/fetchAllDriverForDispatch";
    }
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      this.p_code = data.id;
      this.p_label = data.name;
      this.$emit("selectItem", data);
    },
    clear() {
      this.$emit("clear");
    },
    getContractTypeLabel(code) {
      return Enum.Utils.findLabel(code, Enum.ContractType);
    },
    getLiftLabel(code) {
      return Enum.Utils.findLabel(code, Enum.LiftType);
    },
    findResult() {
      return this.$store.dispatch(
        this.DispatchName,
        Object.assign({}, this.initSearchCond(), {
          unlimited: true
        })
      );
    },
    openAddDialog() {
      this.$emit("openAddDialog");
    },
    closeDialog() {
      this.$refs.dialog.closeDialog();
    }
  }
};
</script>
<style lang="stylus" scoped>
.dialog-header-right-box{
  margin-left auto
}
</style>
