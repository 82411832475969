import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  userList: []
};

const getters = {};

const actions = {
  async fetchAll({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllUser, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllData");
        }
        commit("appendAllData", value.data.data);
      });
  },
  async fetchUser({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllUser + "/" + id).then(value => {
      return value.data.data;
    });
  },
  postEdit({ commit, state }, payload) {
    return $axios.post(axiosBase.getUrls().postEditUser, payload.params);
  },
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportUser, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllData(state) {
    state.userList = [];
  },
  appendAllData(state, data) {
    if (data != null) {
      state.userList = state.userList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
