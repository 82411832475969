import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  bjiOrderList: {
    orderList: [],
    size: 0
  },
  bjiOrderDeleteList: {
    orderList: [],
    size: 0
  },
  generalOrderList: [],
  generalOrderDetailListCount: 0,
  generalOrderHistoryList: [],
  generalOrderHistoryDetailListCount: 0,
  importOrderWorkList: {
    driverNameSummary: [],
    routeNoSummary: [],
    totalRecordCount: 0,
    uploadAt: "",
    distanceTypeLabel: "",
    loadingDate: "",
    clientBranchName: ""
  },
  orderListConditions: {
    clientName: "",
    transportType: "",
    loadingDate: "",
    loadingPlace: "",
    unloadingDate: "",
    unloadingPlace: "",
    orderDate: "",
    billingTypeName: "",
    distanceTypeName: ""
  },
  importOrderToken: "",
  bjiImportQuantityList: [],
  orderQuantityList: []
};

const getters = {
  bjiOrders: state => {
    return state.bjiOrderList;
  },
  workShipCount: state => {
    return state.importOrderWorkList.totalRecordCount;
  },
  routeNoCount: state => {
    return state.importOrderWorkList.routeNoSummary.length;
  },
  driverNameCount: state => {
    return state.importOrderWorkList.driverNameSummary.length;
  },
  orderListConditions: state => state.orderListConditions,
  getToken: state => state.importOrderToken,
  /**
   * BJIインポートデータの車両カウントを取得
   */
  getImportVehicleCount: state => vehicleCd => {
    const target = state.bjiImportQuantityList
      .filter(rec => {
        return rec.vehicleTypeCd === vehicleCd;
      })
      .pop();

    return target === undefined ? 0 : target.vehicleQuantity;
  },
  /**
   * BJIインポートデータのSlipカウントを取得
   */
  getImportSlipCount: state => vehicleCd => {
    const target = state.bjiImportQuantityList
      .filter(rec => {
        return rec.vehicleTypeCd === vehicleCd;
      })
      .pop();

    return target === undefined ? 0 : target.slipQuantity;
  },
  /**
   * 手入力データの車両カウントを取得
   */
  getOrderVehicleCount: state => vehicleCd => {
    const target = state.orderQuantityList
      .filter(rec => {
        return rec.vehicleTypeCd === vehicleCd;
      })
      .pop();

    return target === undefined ? 0 : target.vehicleQuantity;
  },
  /**
   * 手入力データの受注カウントを取得
   */
  getOrderCount: state => vehicleCd => {
    const target = state.orderQuantityList
      .filter(rec => {
        return rec.vehicleTypeCd === vehicleCd;
      })
      .pop();

    return target === undefined ? 0 : target.orderQuantity;
  },
  /**
   * BJIインポートと手入力データの合算車両カウントを取得
   */
  getTotalOrderVehicleCount: (state, getters) => vehicleCd => {
    return getters.getImportVehicleCount(vehicleCd) + getters.getOrderVehicleCount(vehicleCd);
  },
  /**
   * BJIインポートデータの車両数が検索結果に存在するか
   */
  hasBjiCount: state => {
    return (
      state.bjiImportQuantityList.filter(rec => {
        return rec.vehicleQuantity > 0;
      }).length > 0
    );
  },
  /**
   * 手入力データの車両数が検索結果に存在するか
   */
  hasOrderCount: state => {
    return (
      state.orderQuantityList.filter(rec => {
        return rec.vehicleQuantity > 0;
      }).length > 0
    );
  },
  getOrderHistoryList: state => {
    return state.generalOrderHistoryList;
  }
};

const actions = {
  /**
   * BJIインポート受注を検索して車格ごと件数を取得
   */
  async fetchAllBjiOrderCount({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getBjiAllOrderCount, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("writeBjiImportCount", value);
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject();
      });
  },
  /**
   * BJIインポート受注を検索して取得
   */
  async fetchAllBjiOrder({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getBjiAllOrders, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllBjiOrderData");
        }
        commit("writeBjiAllData", value.data.data);
      });
  },
  /**
   * BJIインポート受注削除を検索して取得
   */
  async fetchAllBjiOrderDelete({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;

    return $axios
      .get(axiosBase.getUrls().getBjiAllDeleteOrders, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllBjiDeleteOrderData");
        }
        commit("writeBjiAllDeleteData", value.data.data);
      });
  },
  async fetchOrder({ commit, state }, orderId) {
    return $axios.get(axiosBase.getUrls().getBjiImportOrder + "/" + orderId).then(value => {
      //TODO テストデータで作成してるので本来はこのIF文は通ることはないはず
      if (value.data.data.orderUnloadingBases == null) {
        value.data.data["orderUnloadingBases"] = {
          list: []
        };
      }
      return value.data.data;
    });
  },
  async fetchDetailOrder({ commit, state }, detailId) {
    return $axios.get(axiosBase.getUrls().getBjiImportDetailOrder + "/" + detailId).then(value => {
      return value.data.data;
    });
  },
  /**
   * 手入力受注を検索して車格ごとの件数を取得
   */
  async fetchAllGeneralOrderCount({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getGeneralOrderSearchCount, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("writeOrderCount", value);
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * 手入力受注を検索して取得
   */
  async fetchAllGeneralOrder({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getGeneralOrderSearch, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllGeneralOrderData");
        }
        commit("writeGeneralAllData", value.data.data);
      });
  },
  /**
   * 手入力受注履歴を検索して取得
   */
  async fetchAllGeneralOrderHistory({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getGeneralOrderHistoryList, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllGeneralOrderHistoryData");
        }
        commit("writeGeneralAllHistoryData", value.data.data);
      });
  },
  //
  async fetchGeneralOrderForEdit({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getGeneralOrder + "/" + id).then(value => {
      return value.data.data;
    });
  },
  async fetchAllImportWorkOrder({ commit, state }) {
    if (state.importOrderToken === "") {
      return null;
    }

    return $axios.get(axiosBase.getUrls().importOrderWorkList + state.importOrderToken).then(value => {
      // commit("writeImportOrderWorkAllData", value.data.data);
      return Promise.resolve(value.data.data);
    });
  },
  async commitImportRequest({ commit, state }, driverNameList) {
    return $axios
      .post(axiosBase.getUrls().importOrderFileCommit, {
        targetDriverNameList: driverNameList,
        token: state.importOrderToken
      })
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async exportOrder({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportOrder, {
      params: getParams,
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
  },
  async exportCardOrder({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportCardOrder, {
      params: getParams,
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
  },
  async uploadImportOrderFile({ commit, state }, data) {
    let config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    return $axios
      .post(axiosBase.getUrls().importOrderFile, data, config)
      .then(value => {
        commit("writeImportWorkToken", value.data.data);
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async postBillingType({ commit, state }, data) {
    return await $axios.post(axiosBase.getUrls().postBillingType, {
      orderId: data.orderId,
      billingTypeId: data.billingTypeId,
      cardUpdatedAt: data.cardUpdatedAt
    });
  }
};

const mutations = {
  clearAllBjiOrderData(state) {
    state.bjiOrderList.orderList = [];
  },
  clearAllBjiDeleteOrderData(state) {
    state.bjiOrderDeleteList.orderList = [];
  },
  writeBjiAllData(state, data) {
    if (data != null) {
      state.bjiOrderList.orderList = state.bjiOrderList.orderList.concat(data.list);
    }
    state.bjiOrderList.size = data == null ? 0 : data.size;
  },
  writeBjiAllDeleteData(state, data) {
    if (data != null) {
      state.bjiOrderDeleteList.orderList = state.bjiOrderDeleteList.orderList.concat(data.list);
    }
    state.bjiOrderDeleteList.size = data == null ? 0 : data.size;
  },
  clearAllGeneralOrderData(state) {
    state.generalOrderList = [];
    state.generalOrderDetailListCount = 0;
  },
  writeGeneralAllData(state, data) {
    if (data != null) {
      // 一覧の明細No設定
      if (state.generalOrderDetailListCount > 0) {
        data.forEach(order =>
          order.details.list.forEach(
            detail => (detail.displayIndex = detail.displayIndex + state.generalOrderDetailListCount)
          )
        );
      }
      state.generalOrderList = state.generalOrderList.concat(data);
      // ページング後の明細No退避
      if (state.generalOrderList.length > 0) {
        state.generalOrderList.forEach(
          order => (state.generalOrderDetailListCount += order.details.list.length)
        );
      }
    }
  },
  clearAllGeneralOrderHistoryData(state) {
    state.generalOrderHistoryList = [];
    state.generalOrderHistoryDetailListCount = 0;
  },
  writeGeneralAllHistoryData(state, data) {
    if (data != null) {
      state.generalOrderHistoryList = state.generalOrderHistoryList.concat(data);
    }
  },
  writeImportWorkToken(state, token) {
    state.importOrderToken = token;
  },
  writeBjiImportCount(state, value) {
    state.bjiImportQuantityList = value.data.data === null ? [] : value.data.data;
  },
  writeOrderCount(state, value) {
    state.orderQuantityList = value.data.data === null ? [] : value.data.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
