<template lang="pug">
  v-dialog(v-model="dialog" persistent width="500")
    v-card
      v-toolbar(dark :color="$store.getters['site/siteColor']")
        v-icon(left color="white darken-2" large) help
        span  {{ title }}
        v-spacer
        v-btn(flat icon @click="closeDialog")
          v-icon close

      v-card-text
        v-layout
          v-flex
            div(class="ma-3") {{ message }}

      v-toolbar.footer
        v-spacer
        v-btn(color="" @click="no") キャンセル
        v-btn(color="primary" @click="yes") 変更する
</template>

<script>
export default {
  props: {
    // nullの可能性があるため回避
    // eslint-disable-next-line vue/require-prop-types
    driverName: {
      required: true
    },
    // nullの可能性があるため回避
    // eslint-disable-next-line vue/require-prop-types
    shiftType: {
      required: true
    },
    // nullの可能性があるため回避
    // eslint-disable-next-line vue/require-prop-types
    yearMonth: {
      required: true
    },
    title: {
      type: String,
      required: false,
      default: "一括変更確認"
    }
  },
  data() {
    return {
      dialog: false,
      text: null
    };
  },
  computed: {
    message: {
      get() {
        return (
          this.yearMonth +
          "分　" +
          this.driverName +
          "　の勤怠を　" +
          this.shiftType +
          "　に一括変更します、よろしいですか？"
        );
      }
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    no() {
      this.$emit("no");
      this.closeDialog();
    },
    yes() {
      this.$emit("yes");
      this.closeDialog();
    }
  }
};
</script>
