<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      ImportForm(@importedFile="importedFile" @upload="clearWorkData")
      ProgressBar
      ExpensesImportWorkList(class="mt-4" ref="workList" getListActionName="finance/fetchAllExpensesImportWork")
</template>

<script>
import ImportForm from "@/components/finance/expenses-import/ImportForm";
import ExpensesImportWorkList from "@/components/finance/expenses-import/ExpensesImportWork";
import ProgressBar from "@/components/common/ProgressBar";

export default {
  components: {
    ImportForm,
    ExpensesImportWorkList,
    ProgressBar
  },
  methods: {
    importedFile() {
      this.$refs.workList.loadWorkDataAll();
      this.$refs.workList.fetchWorkListCount();
    },
    clearWorkData() {
      this.$refs.workList.clearWorkData();
    }
  }
};
</script>
