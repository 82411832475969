<template lang="pug">
  EditDialog(ref="EditDialog" title="固定請求料金" :recordId="record.id" @delete="erase" @save="save"  width="60%"
    :loadingSave="loading.save" :loadingDelete="loading.delete" :displayDeleteButton="!record.financialCd" @closeEditDialog="closeEditDialog"
    :updateUser="record.updateUser" :displayHistoryButton="true" @openHistoryDialog="openHistoryDialog")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md6)
            ClientSelect(:label.sync="record.client.clientDisplayName" :code.sync="record.client.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'client.id'")
          v-flex(xs12 md6)
            ClientBranchSelect(:clientId="record.client.id" :clientBranchId="record.clientBranch.id" :isSelectBjiAll="true" :code.sync="record.clientBranch.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'clientBranch.id'")

        v-alert(type="warning" :value="true" v-show="!canSelectBase") 積地・卸地の設定をするには荷主と荷主枝番を選択してください。
        v-layout(row wrap v-show="canSelectBase")
          v-flex(xs12 md6)
            LoadingBaseSelect(:enableDelete="false" :clientId="record.client.id" :clientBranchId="record.clientBranch.id" :label.sync="record.loadingBase.nameAndCd" :code.sync="record.loadingBase.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'loadingBase.id'" @deleteList="")
          v-flex(xs12 md6)
            UnloadingBaseSelect(:enableDelete="false" :clientId="record.client.id" :clientBranchId="record.clientBranch.id" :label.sync="record.unloadingBase.nameAndCd" :code.sync="record.unloadingBase.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'unloadingBase.id'" @deleteList="")
        v-layout(row wrap)
          v-flex(xs12 md4)
            DatePicker(pickerLabel="適用開始日" :initDate="record.applicableStartDate" :inputDate.sync="record.applicableStartDate" :isClearable="false" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'applicableStartDate'")
          v-flex(xs12 md4)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")
          v-flex(xs12 md4)
            v-checkbox(label="高速代フラグ" v-model="record.tollActualCostFlg" hide-details)
        v-layout(row wrap)
          v-flex(xs12 md12)
            section.table-scroll
              table.rate-table
                tr
                  template(v-for="header in record.vehicleTypeList")
                    th.vehicle-column {{ header.vehicleTypeName }}

                template()
                  tr
                    td(v-for="(col, indexCol) in record.fixedBillingRateDetails")
                      DigitSeparatorsField(v-model="col.price" :errorMessages="getErrorMessages('fixedBillingRateDetails[' + indexCol + '].price')")
                  tr
                    v-alert.table-alert-row(type="error" outline :value="getErrorMessages('fixedBillingRateDetails')") {{ getErrorMessages('fixedBillingRateDetails') }}

        v-layout(row wrap)
          v-flex(xs12 md12)
            v-textarea(v-model="record.remark" label="備考" :error-messages="getErrorMessages('remark')" maxlength=1000)

        FixedBillingRateHistoryDialog(ref="FixedBillingRateHistoryDialog")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ClientSelect from "@/components/common/select/ClientSelect";
import ClientBranchSelect from "@/components/common/select/ClientBranchSelect";
import LoadingBaseSelect from "@/components/common/select/LoadingBaseSelect";
import UnloadingBaseSelect from "@/components/common/select/UnloadingBaseSelect";
import DatePicker from "@/components/common/DatePicker";
import StatusSelect from "@/components/common/select/StatusSelect";
import FixedBillingRateHistoryDialog from "@/components/management/fixed-billing-rate/FixedBillingRateHistoryDialog";
import DigitSeparatorsField from "@/components/common/DigitSeparatorsField";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    ClientSelect,
    ClientBranchSelect,
    LoadingBaseSelect,
    UnloadingBaseSelect,
    DatePicker,
    StatusSelect,
    FixedBillingRateHistoryDialog,
    DigitSeparatorsField
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: this.initData()
    };
  },
  computed: {
    /**
     * 積地卸地が選択可能かどうか
     **/
    canSelectBase: {
      get() {
        return this.record.client.id && this.record.clientBranch.id;
      }
    }
  },
  watch: {
    "record.client.id": function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }
      this.record.clientBranch = this.initData().clientBranch;
      this.record.loadingBase = this.initData().loadingBase;
      this.record.unloadingBase = this.initData().unloadingBase;
    },
    "record.clientBranch.id": function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }
      this.record.loadingBase = this.initData().loadingBase;
      this.record.unloadingBase = this.initData().unloadingBase;
    }
  },
  methods: {
    initData() {
      return {
        id: null,
        client: {
          id: null
        },
        clientBranch: {
          id: null
        },
        loadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        },
        unloadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        },
        applicableStartDate: null,
        vehicleTypeList: [],
        fixedBillingRateDetails: [],
        status: Enum.ActiveFlag.Active.code,
        remark: null,
        tollActualCostFlg: false
      };
    },
    setDetailData() {
      this.record.vehicleTypeList.forEach(value => {
        this.record.fixedBillingRateDetails.push({
          id: null,
          vehicleTypeId: value.id,
          vehicleTypeName: value.vehicleTypeName,
          price: null,
          updatedAt: null
        });
      });
    },
    openDialog(item) {
      this.record = this.initData();
      this.initForm(item);
      if (!item) {
        this.$store.dispatch("tariff/fetchTariffUseVehicleTypeList").then(data => {
          this.record.vehicleTypeList = data;
          this.setDetailData();
        });
      }
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    closeEditDialog() {
      this.record = this.initData();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    },
    async fetchVehicleTypeList() {
      this.$store.dispatch("vehicleType/fecthAll").then(data => {
        this.vehicleTypeList = data;
      });
    },
    openHistoryDialog() {
      this.$refs.FixedBillingRateHistoryDialog.openDialog(this.record);
    }
  }
};
</script>
