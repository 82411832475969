<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="荷主" v-model="conditions.clientName")
        v-flex(md2)
          v-text-field(label="卸地CD" v-model="conditions.baseCd")
        v-flex(md2)
          v-text-field(label="卸地名" v-model="conditions.baseName")
        v-flex(md2)
          v-text-field(label="エリア名" v-model="conditions.areaName")
        v-flex(md2)
          v-text-field(label="都道府県名" v-model="conditions.prefName")
        v-flex(md2)
          v-text-field(label="市区町村" v-model="conditions.city")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="荷主卸地分類" v-model="conditions.clientUnloadingBaseClass")
        v-flex(md2)
          DatePicker(pickerLabel="登録日" :initDate="conditions.created" :inputDate.sync="conditions.created")
        v-flex(md2)
          DatePicker(pickerLabel="更新日" :initDate="conditions.updated" :inputDate.sync="conditions.updated")
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import StatusSelect from "@/components/common/select/StatusSelect";
import DatePicker from "@/components/common/DatePicker";

export default {
  components: {
    SearchConditions,
    StatusSelect,
    DatePicker
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          baseCd: "",
          baseName: "",
          areaName: "",
          prefName: "",
          city: "",
          clientUnloadingBaseClass: "",
          created: "",
          updated: "",
          status: null
        }
      };
    }
  }
};
</script>
