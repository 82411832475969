<template lang="pug">
  EditDialog(ref="Self" title="配車パターン選択" width="90%"
    :hasTitleSuffix="false" @closeEditDialog="clearData" :enableSave="false"
    :loadingSave="loading.save" :loadingDelete="loading.delete" :scroll-fire="onScrollButton"
    :enable-scroll-fire="scrollFireFlg")
    template(v-slot:body)
      v-container(grid-list-md fluid)
        LoadingWrapper(:isDisplay="patternLoading" :positionFixed="false")
        v-layout(row wrap)
          v-flex(xs12)
            v-card
              v-card-text
                DispatchPatternMatchFilterConditions(@onChangeSearchConditions="onChangeSearchConditions" ref="cond")
            DispatchPatternDriverSelectDialog(ref="DispatchPatternDriverSelectDialog")
        v-layout(row wrap)
          v-flex(xs12)
            v-card
              v-card-text
                DispatchPatternTable(ref="ListTable" :prop-header="tableHeader" :prop-data="matchedList" displaySlotBody
                :getListActionName="getListActionName")
                  template(v-slot:body="{body}")
                    td(@dblclick="openDriverSelectDialog(body)")
                      PatternCardLine(:cards="body.cards")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog.vue";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import DispatchPatternTable from "@/components/common/PagingListTable";

import DispatchPatternCardEdit from "@/components/management/dispatch-pattern/DispatchPatternCardEdit";
import DispatchPatternMatchFilterConditions from "@/components/dispatch-pattern/DispatchPatternMatchFilterConditions";
import { mapActions, mapGetters, mapMutations } from "vuex";
import PatternCardLine from "@/components/dispatch-pattern/PatternCardLine";
import DispatchPatternDriverSelectDialog from "@/components/dispatch-pattern/DispatchPatternDriverSelectDialog";
import LoadingWrapper from "@/components/common/LoadingWrapper.vue";

export default {
  components: {
    LoadingWrapper,
    DispatchPatternDriverSelectDialog,
    PatternCardLine,
    EditDialog,
    DispatchPatternCardEdit,
    DispatchPatternTable,
    DispatchPatternMatchFilterConditions
  },
  mixins: [ApiHandler, EditForm],
  computed: {
    ...mapGetters({
      matchedList: "dispatchPattern/getMatchedList",
      loadingDate: "dispatchPattern/getLoadingDate",
      dispatchPatternCurrentPage: "dispatchPattern/getDispatchPatternCurrentPage",
      getProgressDispatchPatternSelect: "dispatchPattern/getProgressDispatchPatternSelect",
      getProgressDispatchPatternComplete: "dispatchPattern/getProgressDispatchPatternComplete",
      patternLoading: "dispatchPattern/getProgressDispatchPatternSelect"
    }),
    getListActionName: {
      get() {
        return "dispatchPattern/fetchAllMatchedListSse";
      }
    },
    tableHeader: {
      get() {
        return [{ text: "", value: "", centerAlign: false }];
      }
    },
    /**
     * スクロールダウンで底をついたとき、次のデータを読み込みに行くが
     * 裏でSSEでの通信中であったり、パターンデータすべての精査が済んだ場合は発火しない
     */
    scrollFireFlg: {
      get() {
        return !this.getProgressDispatchPatternSelect && !this.getProgressDispatchPatternComplete;
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("dispatchPattern", {
      setSelectedCardInfo: "setSelectedCardInfo",
      removeCacheAllData: "removeCacheAllData",
      removeDispatchPatternProgressData: "removeDispatchPatternProgressData",
      removeEmptyPageNo: "removeEmptyPageNo",
      removeSse: "removeSse"
    }),
    ...mapMutations("dispatchPattern", {
      setDispatchPatternCurrentPage: "setDispatchPatternCurrentPage"
    }),
    /**
     * 表時しているパターン行をDクリックされた時に発火
     * 選択されたカード情報（表時してる行のデータ）を退避しておく
     * その後、乗務員選択ダイアログを開く
     * @param item
     */
    openDriverSelectDialog(item) {
      this.setSelectedCardInfo(item).then(() => {
        this.$refs.DispatchPatternDriverSelectDialog.openDialog();
      });
    },
    /**
     * このダイアログを開いた時、データをロードする
     */
    openDialog() {
      this.$refs.ListTable.resetPageCount();
      this.$refs.cond.submit();
      this.$refs.Self.openDialog();
    },
    /**
     * ダイアログ閉じるとき、キャッシュしているデータを削除
     */
    closeDialog() {
      //データ削除
      this.clearData();
      this.$refs.Self.closeDialog();
    },
    clearData() {
      this.removeCacheAllData();
      this.removeDispatchPatternProgressData();
      this.removeEmptyPageNo();
      this.removeSse();
    },
    loadListData() {
      this.$refs.ListTable.loadData();
    },
    reload() {
      this.clearData();
      this.openDialog();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.$refs.ListTable.setSearchConditions(searchCond);
      //この機能はページングをサーバー側で独自にセットするのでStoreを使う
      //(レスポンスに現在のページを返す為)
      this.setDispatchPatternCurrentPage(0);
      this.loadListData();
    },
    onScrollButton() {
      this.setDispatchPatternCurrentPage(this.dispatchPatternCurrentPage * 1 + 1);
      this.loadListData();
      return Promise.resolve();
    }
  },
  mounted() {
    //配車画面をリロードする必要がある際にコールする
    window.removeEventListener(
      window.Common.Config.observer.onRequireDispatchPatternSelectReload,
      this.reload
    );
    window.addEventListener(
      window.Common.Config.observer.onRequireDispatchPatternSelectReload,
      this.reload,
      false
    );
  }
};
</script>

<style scoped lang="stylus">
.card-holder
  position relative
  display flex

>>> .sticky_table thead th {
  background-color: transparent;
}
</style>
