<template lang="pug">
  EditDialog(ref="EditDialog" title="請求項目" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete" :displayDeleteButton="!record.financialCd")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field(v-model="record.billItemName" label="請求項目名" :error-messages="getErrorMessages('billItemName')")

          v-flex(xs12 md3)
            SaleTaxTargetSelect(:code.sync="record.saleTaxTarget" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md3)
            v-text-field(v-model="record.sortOrder" label="表示順" :error-messages="getErrorMessages('sortOrder')")

          v-flex(xs12 md3)
            v-checkbox(label="表示対象" v-model="record.displayTarget" hide-details)
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import SaleTaxTargetSelect from "@/components/common/select/SaleTaxTargetSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    SaleTaxTargetSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {}
    };
  },
  methods: {
    initData() {
      return {
        id: null,
        billingCd: null,
        billItemName: null,
        saleTaxTarget: Enum.SaleTaxTargetType.Addition.code,
        sortOrder: null
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  },
  created() {
    this.record = this.initData();
  }
};
</script>
