<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions" ref="cond")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="1次委託先会社" v-model="conditions.primaryContractorName")
        v-flex(md2)
          v-text-field(label="所属会社" v-model="conditions.deliveryCompanyName")
        v-flex(md2)
          v-text-field(label="乗務員名" v-model="conditions.driverName")
        v-flex(md1)
          v-text-field(label="車番" v-model="conditions.vehicleNumber")
        v-flex(md2)
          ShiftSelect(:code.sync="conditions.driverShiftList")
        v-flex(md2)
          v-text-field(label="車庫エリア" v-model="conditions.vehicleGarageAreaName")
        v-flex(md2)
          VehicleTypeMultiSelect(:code.sync="conditions.vehicleType" clearable labelDisp="乗務員車両車格")
        v-flex(md2)
          v-text-field(label="乗務員備考" v-model="conditions.driverRemark")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import ShiftSelect from "@/components/common/select/ShiftSelect.vue";
import VehicleTypeMultiSelect from "@/components/common/select/VehicleTypeMultiSelect.vue";

export default {
  components: {
    VehicleTypeMultiSelect,
    ShiftSelect,
    SearchConditions
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          primaryContractorName: "",
          deliveryCompanyName: "",
          driverName: "",
          vehicleNumber: "",
          driverShiftList: [],
          vehicleType: [],
          vehicleGarageAreaName: "",
          driverRemark: ""
        }
      };
    },
    submit() {
      this.$refs.cond.submit();
    }
  }
};
</script>
