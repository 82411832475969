<template lang="pug">
  section(v-show="enableCondition")
    v-text-field(
      readonly
      v-model="p_label"
      label="車両"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem" :config="config"
      :initSearchCond="initSearchCond" :getListActionName="actionName")
      template(v-slot:body="{ body }")
        td {{ body.vehicleTypeName }}
        td {{ body.fullNumberDisp }}
        td.text-xs-right(v-if="body.carryWeight != 0") {{ body.carryWeight | addComma }}kg
        td(v-else)
        td
          v-chip(v-for="(optionName, index) in body.optionNameList" :key="index") {{ optionName }}
        td {{ body.vehicleGarageName }}
        td {{ body.deliveryCompanyName }}
</template>

<script>
import { mapGetters } from "vuex";
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  mixins: [ApiHandler, MasterSelectDialog],
  components: { PagingMasterSelectDialog },
  props: {
    enableCondition: {
      type: Boolean,
      required: false,
      default: true
    },
    excludeVehicleId: {
      type: Number,
      required: false,
      default: null
    },
    actionName: {
      type: String,
      default: () => "dispatch/fetchDispatchChangableVehicleList"
    }
  },
  methods: {
    openDialog(data, scheduleLoadingDate) {
      this.$refs.dialog.resetSearchCondition();
      this.$refs.dialog.searchConditions.scheduleLoadingDate = scheduleLoadingDate;
      this.$refs.dialog.searchConditions.driverId = data.driverId;
      this.$refs.dialog.searchConditions.excludeVehicleId =
        data.item.currentVehicleId == null ? data.item.vehicleId : data.item.currentVehicleId;
      this.p_code = this.$refs.dialog.searchConditions.excludeVehicleId;
      this.$refs.dialog.resetPageCount();
      this.$refs.dialog.records = [];
      this.$refs.dialog.dialog = true;
    },
    selectItem(data) {
      let isChanged = this.p_code !== data.id;
      this.p_code = data.id;
      if (isChanged) this.$emit("changeItem", data);
      this.$refs.dialog.records = [];
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "loadingBaseId",
      config: {
        title: "車両選択",
        width: "60%",
        headers: [
          {
            text: "車格",
            key: "vehicleTypeName",
            align: "text-xs-left"
          },
          {
            text: "車番",
            key: "fullNumberDisp",
            align: "text-xs-left"
          },
          {
            text: "積載重量（値以上）",
            key: "carryWeight",
            align: "text-align-right"
          },
          {
            text: "オプション",
            key: "optionId",
            align: "",
            select: true,
            multipleSelect: true,
            items: this.$store.state.site.vehicleOptionTypes
          },
          {
            text: "車庫名",
            key: "vehicleGarageName",
            align: "text-xs-left"
          },
          {
            text: "所属企業",
            key: "deliveryCompanyName",
            align: "text-xs-left"
          }
        ]
      },
      initSearchCond: () => {
        return {
          vehicleTypeName: "",
          fullNumberDisp: "",
          floorSpecCd: "",
          carryWeight: "",
          optionName: "",
          vehicleGarageName: "",
          deliveryCompanyName: "",
          scheduleLoadingDate: "",
          driverId: "",
          excludeVehicleId: ""
        };
      }
    };
  },
  computed: {
    ...mapGetters({
      getFloorSpec: "site/floorSpecFromCd"
    })
  }
};
</script>

<style lang="stylus" scoped>
td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
}
</style>
