<template lang="pug">
  section.table-scroll
    table.rate-table
      tr(v-if="diffArrayData && diffArrayData.length > 0")
        th(colspan=2 width="200px")
          span 基本料金
        template(v-for="(rec, index) in diffArrayData")
          th.column(v-if="index!==0" rowspan=2) {{ rec.billItemName }}
      tr(v-if="diffArrayData && diffArrayData.length > 0")
        th(colspan=1 width="100px")
          span 自動計算
        th.right-border.column(rowspan=1 width="100px") 入力

      td(v-if="billingBasicPrice" :class="getHistoryDiffCssClass(billingBasicPrice.basicPriceChangeStatus)").price
        DigitSeparatorsLabel(v-if="billingBasicPrice" :value="billingBasicPrice.basicPrice")
      td(v-else)
      template(v-for="(row,indexRow) in diffArrayData")
        td(:class="getHistoryDiffCssClass(row.changeStatus)").price
          DigitSeparatorsLabel(:value="row.billingItemPrice")
</template>

<script>
import HistoryDiffCss from "@/mixin/HistoryDiffCss";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";

export default {
  mixins: [HistoryDiffCss],
  components: {
    DigitSeparatorsLabel
  },
  props: {
    diffArrayData: {
      type: Array,
      required: false,
      default: () => null
    },
    billingBasicPrice: {
      type: Object,
      required: false,
      default: () => null
    }
  }
};
</script>

<style scoped lang="stylus">
.table-scroll {
  white-space: nowrap
  overflow-x auto
  -webkit-overflow-scrolling: touch;
}

.rate-table {
  table-layout fixed
  width 100%

  .column{
    width 100px
    white-space normal
  }

  td {
    height 37px;
  }
}

.right-border {
  border-right: 1px solid #E0E0E0 !important
}
</style>
