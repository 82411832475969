<template lang="pug">
  section.classified-condition-box
    v-tabs(color="primary darken-2" dark slider-color="yellow darken-2" v-model="model")
      v-tab(href="#tab1") 状態
      v-tab(href="#tab2") 荷主
      v-tab(href="#tab3") 積地エリア
      v-tab(href="#tab4") 卸地エリア
      v-tab-item(value="tab1")
        v-card(flat)
          v-card-text
            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span 積載条件
              v-flex(xs9)
                BaseFilterChip(chipName="時間指定" :active="searchCondItem.specifiedTime" @changeFilter="(value) => searchCondItem.specifiedTime = value")

            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span 荷主区分
              v-flex(xs9)
                BaseFilterChip(chipName="BJI" :active="searchCondItem.clientType.bji.active" @changeFilter="(value) => searchCondItem.clientType.bji.active = value")
                BaseFilterChip(chipName="一般" :active="searchCondItem.clientType.general.active" @changeFilter="(value) => searchCondItem.clientType.general.active = value")

            //TODO 荷主区分をBJI選択したら表示する。一般なら非表示＆非選択
            v-layout(row wrap align-center justify-start fill-height v-if="isDisplayBjiOrderType")
              v-flex(xs3)
                span BJI区分
              v-flex(xs9)
                template(v-for="bjiOrderType in searchCondItem.bjiOrderType")
                  BaseFilterChip(:chipName="bjiOrderType.label" :active="bjiOrderType.active" @changeFilter="(value) => bjiOrderType.active = value")

            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span 距離区分
              v-flex(xs9)
                BaseFilterChip(chipName="中長距離" :active="searchCondItem.distanceType.long.active" @changeFilter="(value) => searchCondItem.distanceType.long.active = value")
                BaseFilterChip(chipName="地場" :active="searchCondItem.distanceType.short.active" @changeFilter="(value) => searchCondItem.distanceType.short.active = value")

            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span 必要車格
              v-flex(xs9)
                template(v-for="vehicleType in searchCondItem.vehicleType")
                  BaseFilterChip(:chipName="vehicleType.label" :active="vehicleType.active" @changeFilter="(value) => vehicleType.active = value")

            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span 拠点要求オプション
              v-flex(xs9)
                template(v-for="option in searchCondItem.vehicleOption")
                  BaseFilterChip(:chipName="option.label" :active="option.active" @changeFilter="(value) => option.active = value")

            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span マーク
              v-flex(xs9)
                BaseFilterChip(chipName="マーク1" :active="searchCondItem.mark.mark1.active" @changeFilter="(value) => searchCondItem.mark.mark1.active = value")
                BaseFilterChip(chipName="マーク2" :active="searchCondItem.mark.mark2.active" @changeFilter="(value) => searchCondItem.mark.mark2.active = value")
                BaseFilterChip(chipName="マーク3" :active="searchCondItem.mark.mark3.active" @changeFilter="(value) => searchCondItem.mark.mark3.active = value")
                BaseFilterChip(chipName="マーク4" :active="searchCondItem.mark.mark4.active" @changeFilter="(value) => searchCondItem.mark.mark4.active = value")
            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span カード種別
              v-flex(xs9)
                BaseFilterChip(chipName="中継のみ表示" :active="searchCondItem.relay" @changeFilter="(value) => searchCondItem.relay = value")
            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span 積地
              v-flex(xs8)
                v-text-field(v-model="searchCondItem.loadingBaseName" maxlength="100")
              v-flex(xs1)
            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span 卸地
              v-flex(xs8)
                v-text-field(v-model="searchCondItem.unloadingBaseName" maxlength="100")
              v-flex(xs1)

            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs3)
                span シップ/受付/中継番号
              v-flex(xs8)
                v-textarea(v-model="searchCondItem.shipmentNo" maxlength=700 rows="1")
              v-flex(xs1)

      v-tab-item(value="tab2")
        v-card(flat)
          v-card-text.dispatch_filter_card
            DispatchClientFilter(ref="DispatchClientFilter" v-bind:prop-data="clientList" @click="(value) => searchCondItem.clientId = value" @clickBranch="(value) => searchCondItem.clientBranchId = value")

      v-tab-item(value="tab3")
        v-card(flat)
          v-card-text
            v-layout(row wrap)
              v-flex(xs12)
                template(v-for="item in searchCondItem.loadingBaseArea")
                  BaseFilterChip(:chipName="item.name" :active="item.active" @changeFilter="(value) => item.active = value")

      v-tab-item(value="tab4")
        v-card(flat)
          v-card-text
            v-layout(row wrap)
              template(v-for="item in searchCondItem.unloadingBaseArea")
                BaseFilterChip(:chipName="item.name" :active="item.active" @changeFilter="(value) => item.active = value")
</template>

<script>
import BaseFilterChip from "@/components/common/BaseFilterChip";
import _ from "lodash";
import * as Enum from "@/assets/js/enum";
import DispatchClientFilter from "@/components/dispatch/DispatchClientFilter";

export default {
  components: {
    BaseFilterChip,
    DispatchClientFilter
  },
  data() {
    return {
      model: "tab1",
      searchCondItem: {},
      searchCondItemDef: {
        specifiedTime: false,
        clientType: {
          bji: { value: Enum.ClientType.Bji.code, active: false },
          general: { value: Enum.ClientType.General.code, active: false }
        },
        bjiOrderType: [],
        distanceType: {
          short: { value: Enum.DistanceType.Short.code, active: false },
          long: { value: Enum.DistanceType.Long.code, active: false }
        },
        vehicleType: [],
        vehicleOption: [],
        mark: {
          mark1: { value: Enum.CardMarkType.MarkOne.code, active: false },
          mark2: { value: Enum.CardMarkType.MarkTwo.code, active: false },
          mark3: { value: Enum.CardMarkType.MarkThree.code, active: false },
          mark4: { value: Enum.CardMarkType.MarkFour.code, active: false }
        },
        relay: false,
        loadingBaseArea: [],
        unloadingBaseArea: [],
        loadingBaseName: "",
        unloadingBaseName: "",
        clientId: [],
        clientBranchId: [],
        shipmentNo: ""
      },
      conditions: {},
      clientList: []
    };
  },
  computed: {
    vehicleOptionList: {
      get() {
        let vehicleOptionList = this.$store.state.site.vehicleOptionTypes;
        vehicleOptionList.map(value => {
          value.active = false;
        });
        return vehicleOptionList;
      }
    },
    vehicleTypeList: {
      get() {
        let vehicleTypeList = this.$store.state.site.vehicleTypeIds;
        vehicleTypeList.map(value => {
          value.active = false;
        });
        return vehicleTypeList;
      }
    },
    bjiOrderTypeList: {
      get() {
        let bjiOrderTypeList = this.$store.state.site.bjiTransportTypeIds;
        bjiOrderTypeList.map(value => {
          value.active = false;
        });
        return bjiOrderTypeList;
      }
    },
    loadingAreaList: {
      get() {
        const filteredList = [];
        this.searchCondItem.loadingBaseArea.map(value => {
          if (value.active === true) filteredList.push(value.id);
        });
        return filteredList;
      }
    },
    unloadingAreaList: {
      get() {
        const filteredList = [];
        this.searchCondItem.unloadingBaseArea.map(value => {
          if (value.active === true) filteredList.push(value.id);
        });
        return filteredList;
      }
    },
    clientType: {
      get() {
        let clientTypeList = [];
        if (_.get(this.searchCondItem.clientType, "bji.active") === true)
          clientTypeList.push(this.searchCondItem.clientType.bji.value);
        if (_.get(this.searchCondItem.clientType, "general.active") === true)
          clientTypeList.push(this.searchCondItem.clientType.general.value);
        return clientTypeList;
      }
    },
    bjiOrderType: {
      get() {
        let bjiOrderTypeList = [];
        this.searchCondItem.bjiOrderType.map(value => {
          if (value.active) bjiOrderTypeList.push(value.code);
        });
        return bjiOrderTypeList;
      }
    },
    distanceType: {
      get() {
        let distanceTypeList = [];
        if (_.get(this.searchCondItem.distanceType, "long.active") === true)
          distanceTypeList.push(this.searchCondItem.distanceType.long.value);
        if (_.get(this.searchCondItem.distanceType, "short.active") === true)
          distanceTypeList.push(this.searchCondItem.distanceType.short.value);
        return distanceTypeList;
      }
    },
    vehicleOption: {
      get() {
        let vehicleOptionList = [];
        this.searchCondItem.vehicleOption.map(value => {
          if (value.active) vehicleOptionList.push(value.code);
        });
        return vehicleOptionList;
      }
    },
    vehicleType: {
      get() {
        let vehicleTypeList = [];
        this.searchCondItem.vehicleType.map(value => {
          if (value.active) vehicleTypeList.push(value.id);
        });
        return vehicleTypeList;
      }
    },
    mark: {
      get() {
        let markList = [];
        if (_.get(this.searchCondItem.mark, "mark1.active") === true)
          markList.push(this.searchCondItem.mark.mark1.value);
        if (_.get(this.searchCondItem.mark, "mark2.active") === true)
          markList.push(this.searchCondItem.mark.mark2.value);
        if (_.get(this.searchCondItem.mark, "mark3.active") === true)
          markList.push(this.searchCondItem.mark.mark3.value);
        if (_.get(this.searchCondItem.mark, "mark4.active") === true)
          markList.push(this.searchCondItem.mark.mark4.value);
        return markList;
      }
    },
    isDisplayBjiOrderType: {
      get() {
        return this.searchCondItem.clientType.bji["active"] === true ? true : false;
      }
    }
  },
  methods: {
    loadData(loadingDate) {
      this.$store.dispatch("dispatch/fetchExistsLoadingArea", loadingDate).then(value => {
        if (value) this.addActiveloadingAreaList(value);
      });
      this.$store.dispatch("dispatch/fetchExistsUnloadingArea", loadingDate).then(value => {
        if (value) this.addActiveUnloadingAreaList(value);
      });
      this.$store.dispatch("dispatch/fetchClientClassifiedFilter", loadingDate).then(value => {
        if (value) this.clientList = value;
      });
    },
    reloadData(loadingDate) {
      this.$store.dispatch("dispatch/fetchExistsLoadingArea", loadingDate).then(value => {
        this.resetLoadingAreaList(value);
      });
      this.$store.dispatch("dispatch/fetchExistsUnloadingArea", loadingDate).then(value => {
        this.resetUnloadingAreaList(value);
      });
      this.$store.dispatch("dispatch/fetchClientClassifiedFilter", loadingDate).then(value => {
        if (value) this.clientList = value;
      });
    },
    addActiveloadingAreaList(data) {
      let list = [];
      data.map(area => {
        area.active = false;
        list.push(area);
      });
      this.searchCondItem.loadingBaseArea = list;
    },
    addActiveUnloadingAreaList(data) {
      let list = [];
      data.map(area => {
        area.active = false;
        list.push(area);
      });
      this.searchCondItem.unloadingBaseArea = _.cloneDeep(list);
    },
    resetLoadingAreaList(data) {
      this.searchCondItemDef.loadingBaseArea = [];
      let list = [];
      if (data !== null) {
        data.map(area => {
          let _area = this.searchCondItem.loadingBaseArea.filter(function (item, index) {
            if (item.id == area.id) return true;
          });
          if (_area.length == 0) {
            area.active = false;
          } else {
            area.active = _area[0].active;
          }
          list.push(area);
        });
      }
      this.searchCondItemDef.loadingBaseArea = list;
      this.searchCondItem.loadingBaseArea = _.cloneDeep(this.searchCondItemDef.loadingBaseArea);
    },
    resetUnloadingAreaList(data) {
      this.searchCondItemDef.unloadingBaseArea = [];
      let list = [];
      if (data !== null) {
        data.map(area => {
          let _area = this.searchCondItem.unloadingBaseArea.filter(function (item, index) {
            if (item.id == area.id) return true;
          });
          if (_area.length == 0) {
            area.active = false;
          } else {
            area.active = _area[0].active;
          }
          list.push(area);
        });
      }
      this.searchCondItemDef.unloadingBaseArea = list;
      this.searchCondItem.unloadingBaseArea = _.cloneDeep(this.searchCondItemDef.unloadingBaseArea);
    },
    isChangedSearchItemArea() {
      if (
        _.isEqual(this.searchCondItem.loadingBaseArea, this.searchCondItemDef.loadingBaseArea) &&
        _.isEqual(this.searchCondItem.unloadingBaseArea, this.searchCondItemDef.unloadingBaseArea)
      ) {
        return false;
      } else {
        return true;
      }
    },
    isChangedSearchItemClient() {
      if (
        _.isEqual(this.searchCondItem.clientId, this.searchCondItemDef.clientId) &&
        _.isEqual(this.searchCondItem.clientBranchId, this.searchCondItemDef.clientBranchId)
      ) {
        if (this.searchCondItem.clientId.length < 1 || this.searchCondItem.clientBranchId.length < 1) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    addSearchCond(data) {
      let cloneData = _.cloneDeep(data);
      cloneData.specifiedTime = this.searchCondItem.specifiedTime;
      cloneData.clientType = this.clientType;
      cloneData.bjiOrderType = this.bjiOrderType;
      cloneData.distanceType = this.distanceType;
      cloneData.vehicleOption = this.vehicleOption;
      cloneData.vehicleType = this.vehicleType;
      cloneData.mark = this.mark;
      cloneData.relay = this.searchCondItem.relay;
      cloneData.loadingBaseArea = this.loadingAreaList;
      cloneData.unloadingBaseArea = this.unloadingAreaList;
      cloneData.loadingBaseName = this.searchCondItem.loadingBaseName;
      cloneData.unloadingBaseName = this.searchCondItem.unloadingBaseName;
      cloneData.clientId = this.searchCondItem.clientId;
      cloneData.clientBranchId = this.searchCondItem.clientBranchId;
      cloneData.shipmentNo = this.searchCondItem.shipmentNo;
      return cloneData;
    },
    setApiData() {
      this.searchCondItemDef.vehicleType = this.vehicleTypeList;
      this.searchCondItemDef.bjiOrderType = this.bjiOrderTypeList;
      this.searchCondItemDef.vehicleOption = this.vehicleOptionList;
    },
    clearSearchCond(data) {
      this.searchCondItemDef.unloadingBaseArea = [];
      this.searchCondItemDef.loadingBaseArea = [];
      this.searchCondItem = _.cloneDeep(this.searchCondItemDef);
      this.$refs.DispatchClientFilter.clearSelected();
      return this.addSearchCond(data);
    },
    trigger() {
      this.$emit("onChangeClassifiedConditions");
    },
    loadingDown() {
      this.loading = false;
    }
  },
  watch: {
    searchCondItem: {
      handler: function (newValue) {
        this.debouncedTrigger(newValue);
      },
      deep: true
    }
  },
  created() {
    this.debouncedTrigger = _.debounce(this.trigger, 500);
    this.setApiData();
    this.loadData(window.Common.Util.getYearMonthDayIsoFormat());
    this.searchCondItem = _.cloneDeep(this.searchCondItemDef);
  },
  mounted() {
    window.addEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.loadingDown,
      false
    );
  },
  destroyed() {
    window.removeEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.loadingDown
    );
  }
};
</script>

<style lang="stylus" scoped>
.dispatch_filter_card {
  max-height: 650px;
  overflow-y: scroll;
  padding-top: 0;
}
</style>
