<template lang="pug">
  div
    DriverShiftPicker(ref="DriverShiftPicker" @selectShiftType="shiftSelect")
    v-icon(:color="getShiftType(shiftType).color",
      @click="openShiftMenu($event,shiftType)") {{ getShiftType(shiftType).icon }}
    v-btn.apply-btn(color="grey lighten-4" @click="clickApply") 一括反映
</template>

<script>
import DriverShiftPicker from "@/components/common/DriverShiftPicker";
import ShiftTypeMenu from "@/mixin/ShiftTypeMenu";

import * as Enum from "@/assets/js/enum";

export default {
  mixins: [ShiftTypeMenu],
  components: {
    DriverShiftPicker
  },
  data() {
    return {
      shiftType: null
    };
  },
  mounted() {
    this.target.record = this.shiftType = Enum.ShiftType.Day.code;
  },
  methods: {
    clickApply() {
      this.$emit("click", this.target.record);
    },
    openShiftMenu(event, shift) {
      this.target.record = shift;
      this.$refs.DriverShiftPicker.open(event);
    },
    shiftSelect(selectItem) {
      this.target.record = selectItem.code;
      this.shiftType = selectItem.code;
    }
  }
};
</script>

<style scoped lang="stylus">
.select-apply-shift {
  display: inline-block;
}

.apply-btn {
  display: inline-block;
}

.driver-schedule-switch {
  display: inline-block;
}
</style>
