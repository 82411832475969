import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  billingTypeList: []
};

const getters = {};

const actions = {
  async fetchAllBillingType({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllBillingTypeList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllBillingTypeData");
        }
        commit("appendAllBillingTypeData", value.data.data);
      });
  },
  async fetchBillingType({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllBillingType + id).then(value => {
      return value.data.data;
    });
  }
};

const mutations = {
  clearAllBillingTypeData(state) {
    state.billingTypeList = [];
  },
  appendAllBillingTypeData(state, data) {
    if (data != null) {
      state.billingTypeList = state.billingTypeList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
