<template lang="pug">
  div
    template(v-if="!readonly")
      v-menu(
        ref="menu"
        :close-on-content-click="false"
        v-model="menu"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-overflow
        full-width
        min-width="290px"
        max-width="290px"
      )
        v-text-field(
          slot="activator"
          v-model="date"
          prepend-icon="event"
          readonly
          :label="pickerLabel"
          :clearable="clearable"
          :error-messages="errorHandlerMethod(errorHandlerMethodParam)"
        )
        v-date-picker(
          locale="jp-ja"
          v-model="date"
          @input="changeItem"
          type="month")
    template(v-else)
      v-text-field.date-input(
        slot="activator"
        v-model="date"
        readonly
        box
        :label="pickerLabel"
        :error-messages="errorHandlerMethod(errorHandlerMethodParam)"
      )
</template>

<script>
export default {
  data: () => ({
    menu: false,
    modal: false,
    menu2: false
  }),
  props: {
    pickerLabel: {
      type: String,
      default: "日時"
    },
    // TODO:type: String,default:()=>""と思われるが、日時要素のため回避
    // eslint-disable-next-line
    initDate: {
      default: window.Common.Util.getYearMonthIsoFormat()
    },
    // TODO:type: String,default:()=>""と思われるが、日時要素のため回避
    // eslint-disable-next-line
    inputDate: {},
    errorHandlerMethod: {
      type: Function,
      required: false,
      default() {
        return this.getErrorMessages();
      }
    },
    errorHandlerMethodParam: {
      type: String,
      required: false,
      default() {
        return "date";
      }
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    date: {
      get() {
        return this.inputDate;
      },
      set(newVal) {
        if (this.inputDate !== newVal) this.$emit("update:inputDate", newVal);
      },
      required: true
    }
  },
  mounted() {
    this.date = this.initDate;
  },
  methods: {
    getErrorMessages() {
      return "";
    },
    changeItem() {
      this.menu = false;
      this.$emit("changeItem");
    }
  }
};
</script>
