<template lang="pug">
  v-container(grid-list-md)
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogBilling" @yes="saveBillingPreRelay" @close="loading = false")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogPayment" @yes="savePaymentPreRelay" @close="loading = false" :billingMode="false")
    BillingCardHistoryDialog(ref="BillingCardHistoryDialog")
    PaymentCardHistoryDialog(ref="PaymentCardHistoryDialog")
    ConfirmDialog(ref="ConfirmDialogBillingRelaySrc" @yes="saveBillingCard" title="中継元更新確認" message="請求区分の変更があれば、複数中継元が存在する場合は併せて更新されますがよろしいですか？" yesButtonTitle="更新する" @close="loading = false")
    ConfirmDialog(ref="ConfirmDialogBillingRelayDest" @yes="saveBillingCard" title="中継元更新確認" message="請求区分の変更があれば、複数中継先が存在する場合は併せて更新されますがよろしいですか？" yesButtonTitle="更新する" @close="loading = false")
    ConfirmDialog(ref="ConfirmDialogPaymentRelayDest" @yes="savePaymentCard" title="中継先更新確認" message="支払区分の変更があれば、複数中継先が存在する場合は併せて更新されますがよろしいですか？" yesButtonTitle="更新する" @close="loading = false")
    ConfirmDialog(ref="ConfirmDialogBillingConfirmed" @yes="saveBillingConfirmed" title="請求確定確認" message="請求状態を確定済にしますがよろしいですか？" yesButtonTitle="確定する" @close="loading = false")
    ConfirmDialog(ref="ConfirmDialogPaymentConfirmed" @yes="savePaymentConfirmed" title="支払確定確認" message="支払状態を確定済にしますがよろしいですか？" yesButtonTitle="確定する" @close="loading = false")
    v-layout
      v-flex(xs6)
        v-card
          v-card-title.billing-bg-color
            v-flex(xs2)
              span.title-text 請求情報
          v-card-text
            alert-box(ref="billingErrorMsg")
            v-layout(justify-end)
              v-flex(xs4)
                BillingTypeSelect(:code.sync="billingCard.billingTypeId" :errorHandlerMethod="getErrorMessages" :readonly="!canBillingEdit")
              v-flex(xs4)
                VehicleTypeSelect(:code.sync="billingCard.billingVehicleTypeId" label="請求車格(変更する場合)"
                  :clearable="canBillingEdit" :readonly="!canBillingEdit")
              v-flex(xs4)
                MonthPicker(pickerLabel="請求対象年月" :initDate="billingCard.billingTargetYearMonth"
                  :inputDate.sync="billingCard.billingTargetYearMonth" :clearable="true" :readonly="!canBillingEdit")
            v-layout(v-if="isRelaySource")
              v-flex(xs4)
                DatePicker(pickerLabel="請求基準日" :initDate="billingCard.billingBaseDate"
                  :inputDate.sync="billingCard.billingBaseDate" :isClearable="true" :isReadonly="!canBillingEdit")
              v-flex(xs3)
                v-text-field(label="請求書用車番" v-model="billingCard.manualEntryNo" :errorMessages="getErrorMessages('billingCard.manualEntryNo')" maxlength="4")
              v-flex(xs3)
                v-text-field(v-if="requiredVehicleUseChassis" label="請求書用シャーシ番号" v-model="billingCard.manualChassisNumber" :errorMessages="getErrorMessages('billingCard.manualChassisNumber')" maxlength="4")
            v-layout(justify-end)
              v-flex(xs12)
                v-layout
                  v-flex(xs3)
                    DigitSeparatorsField(v-model="getBasicPrice" label="基本料金(自動計算)" :read-only="true")
                  v-flex(xs4)
                    v-text-field(readonly box label="適用料金ルール" v-model="getBasicPriceRule")
                  v-spacer
                  v-flex(xs2)
                    v-btn(:disabled="!canBillingEdit" color="success" round @click="saveBillingPreFlight" :loading="loading")
                      span 更新
                v-layout
                  v-flex(xs12)
                    v-card
                      v-card-text
                        v-layout(wrap)
                          template(v-for="(billing,index) in billingCardDetail")
                            v-flex(xs3)
                              DigitSeparatorsField(v-model="billing.billingItemPrice" :label="billing.billItemName"
                                :errorMessages="getErrorMessages('billingCard.billingCardDetails[' + index + '].billingItemPrice')" :read-only="!canBillingEdit")

            v-layout(justify-end)
              v-flex(xs12)
                table.finance-summary-table
                  tr
                    th 通行料
                    td
                      DigitSeparatorsLabel(:value="billingCardToll").price
                    th 運賃料金計
                    td
                      DigitSeparatorsLabel(:value="billingCardSummaryWithoutToll").price
            v-layout
              v-flex(xs3)
                v-btn(color="success" @click="openBillingCardHistoryDialog") 変更履歴表示
              v-flex(xs2)
                v-text-field(readonly box label="請求状態" :value="billingStatusLabel(billingCard.billingStatus)")
              v-flex(xs3)
                BillingCardStatusBox(:temporary-confirmed-mode="true" :disabled="isBillingCardDispatchStatusNone" :cardId="getCardId" @openErrorMessage="openBillingErrorMessage" @financeUpdated="financeUpdated")
              v-spacer
              v-btn.mt-2(v-if="canBillingConfirmed" color="warning" round @click="saveBillingConfirmedPre" :loading="loading")
                span 確定済にする


      v-flex(xs6)
        v-card
          v-card-title.payment-bg-color
            v-flex(xs2)
              span.title-text 支払情報
          v-card-text
            alert-box(ref="paymentErrorMsg")
            v-layout(justify-end)
              v-flex(xs4)
                PaymentTypeSelect(:code.sync="paymentCard.paymentTypeId"
                  :errorHandlerMethod="getErrorMessages"  :readonly="!canPaymentEdit")
              v-flex(xs4)
                VehicleTypeSelect(:code.sync="paymentCard.paymentVehicleTypeId" label="支払車格(変更する場合)"
                  :clearable="canPaymentEdit"  :readonly="!canPaymentEdit")
              v-flex(xs4)
                MonthPicker(pickerLabel="支払対象年月" :initDate="paymentCard.paymentTargetYearMonth"
                  :inputDate.sync="paymentCard.paymentTargetYearMonth" :clearable="true" :readonly="!canPaymentEdit")
            v-layout(justify-end)
              v-flex(xs12)
                v-layout
                  v-flex(xs3)
                    DigitSeparatorsField(v-model="getPaymentBasicPrice" label="基本料金(自動計算)" :read-only="true")
                  v-flex(xs4)
                    v-text-field(readonly box label="適用料金ルール" v-model="getPaymentBasicPriceRule")
                  v-spacer
                  v-flex(xs2)
                    v-btn(:disabled="!canPaymentEdit" color="warning" round @click="savePaymentPreFlight" :loading="loading")
                      span 更新
                v-layout
                  v-flex(xs12)
                    v-card
                      v-card-text
                        v-layout(wrap)
                          template(v-for="(rec,index) in paymentCardDetail")
                            v-flex(xs3)
                              DigitSeparatorsField(v-model="rec.paymentItemPrice" :label="rec.paymentItemName"
                                :errorMessages="getErrorMessages('paymentCard.paymentCardDetails[' + index + '].paymentItemPrice')"
                                :read-only="!canPaymentEdit")

            v-layout(justify-end)
              v-flex(xs12)
                table.finance-summary-table
                  tr
                    th 通行料
                    td
                      DigitSeparatorsLabel(:value="paymentCardToll").price
                    th 運賃料金計
                    td
                      DigitSeparatorsLabel(:value="paymentCardSummaryWithoutToll").price
            v-layout
              v-flex(xs3)
                v-btn(color="success" @click="openPaymentCardHistoryDialog") 変更履歴表示
              v-flex(xs2)
                v-text-field(readonly box label="支払状態" :value="paymentStatusLabel(paymentCard.paymentStatus)")
              v-flex(xs3)
                PaymentCardStatusBox(:temporary-confirmed-mode="true" :disabled="isPaymentCardDispatchStatusNone" :cardId="getCardId" @openErrorMessage="openPaymentErrorMessage" @financeUpdated="financeUpdated")
              v-spacer
              v-btn.mt-2(v-if="canPaymentConfirmed" color="warning" round @click="savePaymentConfirmedPre" :loading="loading")
                span 確定済にする

</template>

<script>
import DigitSeparatorsField from "@/components/common/DigitSeparatorsField";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import MonthPicker from "@/components/common/MonthPicker";
import DatePicker from "@/components/common/DatePicker";
import BillingTypeSelect from "@/components/common/select/BillingTypeSelect";
import PaymentTypeSelect from "@/components/common/select/PaymentTypeSelect";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";
import BillingCardStatusBox from "@/components/finance/status/BillingCardStatusBox";
import PaymentCardStatusBox from "@/components/finance/status/PaymentCardStatusBox";
import FinanceOverrideWarningDialog from "@/components/common/dialog/FinanceOverrideWarningDialog";
import BillingCardHistoryDialog from "@/components/finance/BillingCardHistoryDialog";
import PaymentCardHistoryDialog from "@/components/finance/PaymentCardHistoryDialog";
import ConfirmDialog from "@/components/common/dialog/ConfirmDialog";
import AlertBox from "@/components/common/AlertBox";
import ApiHandler from "@/mixin/ApiHandler";
import { mapActions, mapGetters } from "vuex";
import * as Enum from "@/assets/js/enum";

export default {
  mixins: [ApiHandler],
  components: {
    DigitSeparatorsField,
    DigitSeparatorsLabel,
    BillingTypeSelect,
    PaymentTypeSelect,
    MonthPicker,
    VehicleTypeSelect,
    BillingCardStatusBox,
    PaymentCardStatusBox,
    AlertBox,
    FinanceOverrideWarningDialog,
    BillingCardHistoryDialog,
    PaymentCardHistoryDialog,
    DatePicker,
    ConfirmDialog
  },
  props: {
    isRelaySource: {
      type: Boolean,
      default: () => false
    },
    isFinance: {
      type: Boolean,
      default: () => false
    },
    requiredVehicleUseChassis: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      vehicleTypeId: null,
      loading: false
    };
  },
  methods: {
    ...mapActions("finance", [
      "fetchBillingAndPaymentCard",
      "postBillingCard",
      "postPaymentCard",
      "postBillingConfirmed",
      "postPaymentConfirmed",
      "fetchBillingAndPaymentCardAndMerge",
      "fetchBillingAndPaymentListSum",
      "mergeBillingCardVehicleTypeTrailer"
    ]),
    initData(cardId) {
      this.errors = {};
      this.fetchBillingAndPaymentCard(cardId);
      this.$refs.billingErrorMsg.nonDisplayMessage();
      this.$refs.paymentErrorMsg.nonDisplayMessage();
    },
    async saveBillingCard() {
      if (this.isRelaySource) {
        this.mergeBillingCardVehicleTypeTrailer(this.requiredVehicleUseChassis);
      }
      return await this.postBillingCard()
        .then(value => {
          this.errors = {};
          this.$refs.billingErrorMsg.displaySuccessMessage(value.message);
          if (this.isFinance) {
            this.financeUpdated();
          }
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            this.setErrorMessages(error);
          } else {
            this.$refs.billingErrorMsg.displayMessage(error.response.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async savePaymentCard() {
      return await this.postPaymentCard()
        .then(value => {
          this.errors = {};
          this.$refs.paymentErrorMsg.displaySuccessMessage(value.message);
          if (this.isFinance) {
            this.financeUpdated();
          }
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            this.setErrorMessages(error);
          } else {
            this.$refs.paymentErrorMsg.displayMessage(error.response.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveBillingPreFlight() {
      this.loading = true;
      this.$refs.FinanceOverrideWarningDialogBilling.checkEditableFinanceInfo(this.originCard.id);
    },
    savePaymentPreFlight() {
      this.loading = true;
      this.$refs.FinanceOverrideWarningDialogPayment.checkEditableFinanceInfo(this.originCard.id);
    },
    saveBillingPreRelay() {
      if (this.isRelaySource) {
        this.$refs.ConfirmDialogBillingRelaySrc.openDialog();
      } else if (this.isRelayDest) {
        this.$refs.ConfirmDialogBillingRelayDest.openDialog();
      } else {
        this.saveBillingCard();
      }
    },
    savePaymentPreRelay() {
      if (this.isRelayDest) {
        this.$refs.ConfirmDialogPaymentRelayDest.openDialog();
      } else {
        this.savePaymentCard();
      }
    },
    /** 請求なしデータ確定処理事前確認 */
    saveBillingConfirmedPre() {
      this.loading = true;
      this.$refs.ConfirmDialogBillingConfirmed.openDialog();
    },
    /** 支払なしデータ確定処理事前確認 */
    savePaymentConfirmedPre() {
      this.loading = true;
      this.$refs.ConfirmDialogPaymentConfirmed.openDialog();
    },
    /** 請求なしデータ確定処理 */
    async saveBillingConfirmed() {
      this.$refs.billingErrorMsg.nonDisplayMessage();
      return await this.postBillingConfirmed()
        .then(value => {
          this.errors = {};
          this.$refs.billingErrorMsg.displaySuccessMessage(value.message);
          this.financeUpdated();
        })
        .catch(error => {
          this.openBillingErrorMessage(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /** 支払なしデータ確定処理 */
    async savePaymentConfirmed() {
      this.$refs.paymentErrorMsg.nonDisplayMessage();
      return await this.postPaymentConfirmed()
        .then(value => {
          this.errors = {};
          this.$refs.paymentErrorMsg.displaySuccessMessage(value.message);
          this.financeUpdated();
        })
        .catch(error => {
          this.openPaymentErrorMessage(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openBillingCardHistoryDialog() {
      this.$refs.BillingCardHistoryDialog.openDialog(this.billingCard.cardId);
    },
    openPaymentCardHistoryDialog() {
      this.$refs.PaymentCardHistoryDialog.openDialog(this.paymentCard.cardId);
    },
    openBillingErrorMessage(message) {
      this.$refs.billingErrorMsg.displayMessage(message);
    },
    openPaymentErrorMessage(message) {
      this.$refs.paymentErrorMsg.displayMessage(message);
    },
    financeUpdated() {
      this.fetchBillingAndPaymentCardAndMerge(this.getCardId);
    }
  },
  computed: {
    ...mapGetters("finance", {
      billingCard: "billingCard",
      paymentCard: "paymentCard",
      originCard: "originCard",
      billingCardDetail: "billingCardDetail",
      paymentCardDetail: "paymentCardDetail",
      billingCardBasicPrice: "billingCardBasicPrice",
      paymentCardBasicPrice: "paymentCardBasicPrice",
      billingCardToll: "billingCardToll",
      billingCardSummaryWithoutToll: "billingCardSummaryWithoutToll",
      paymentCardToll: "paymentCardToll",
      billingStatusLabel: "billingStatusLabel",
      paymentStatusLabel: "paymentStatusLabel",
      paymentCardSummaryWithoutToll: "paymentCardSummaryWithoutToll",
      canBillingEdit: "canBillingEdit",
      canPaymentEdit: "canPaymentEdit",
      canBillingConfirmed: "canBillingConfirmed",
      canPaymentConfirmed: "canPaymentConfirmed"
    }),
    getBasicPriceRule() {
      return this.billingCardBasicPrice ? this.billingCardBasicPrice.basicPriceCalculationTypeLabel : null;
    },
    getBasicPrice() {
      return this.billingCardBasicPrice && Object.keys(this.billingCardBasicPrice).length !== 0
        ? this.billingCardBasicPrice.basicPrice
        : null;
    },
    getPaymentBasicPriceRule() {
      return this.paymentCardBasicPrice ? this.paymentCardBasicPrice.basicPriceCalculationTypeLabel : null;
    },
    getPaymentBasicPrice() {
      return this.paymentCardBasicPrice && Object.keys(this.paymentCardBasicPrice).length !== 0
        ? this.paymentCardBasicPrice.basicPrice
        : null;
    },
    isPaymentCardDispatchStatusNone() {
      if (this.originCard === null) return true;
      if (this.originCard.paymentFinanceType === Enum.FinanceType.None.code) return false;
      return this.originCard.primaryDeliveryCompanyId === null;
    },
    isBillingCardDispatchStatusNone() {
      if (this.originCard === null) return true;
      if (this.isRelaySource) return false;
      if (this.originCard.billingFinanceType === Enum.FinanceType.None.code) return false;
      return this.originCard.primaryDeliveryCompanyId === null;
    },
    getCardId() {
      return this.originCard === null ? null : this.originCard.id;
    },
    isRelayDest() {
      return this.originCard.orderType === Enum.OrderType.Relay.code;
    }
  }
};
</script>

<style scoped lang="stylus">
.btn-box {
  padding 16px 0 0 0
}

.title-text {
  font-size 16px
  font-weight bold
}
</style>
