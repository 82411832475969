<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      DriverFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              DriverTable(
                ref="DriverTable"
                :prop-header="driverTableTableHeader"
                :prop-data="driverList"
                :getListActionName="getListActionName"
                detail-url="driver/fetchDriver"
                @open-detail="openDetail"
                :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-left {{ body.name }}
                  td.text-xs-left {{ getContractType(body.contractType) }}
                  td.text-xs-left {{ body.deliveryCompanyName }}
                  td.text-xs-left {{ body.regularUseVehicleFullNumberDisp }}

    DriverEditDialog(ref="DriverEditDialog" :postUrl="$config.urls.postEditDriver" :deleteUrl="$config.urls.deleteDriver" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('driver/export')")
</template>

<script>
import { mapGetters } from "vuex";
import DriverEditDialog from "@/components/management/driver/DriverEdit";
import DriverTable from "@/components/common/PagingListTable";
import DriverFilterConditions from "@/components/management/driver/DriverFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

import _ from "lodash";

export default {
  components: {
    DriverEditDialog,
    DriverTable,
    DriverFilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {
      searchCond: {},
      driverTableTableHeader: [{ text: "氏名" }, { text: "契約" }, { text: "所属企業" }, { text: "基本車両" }]
    };
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.DriverEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.DriverEditDialog.openDialog(item);
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     **/
    itemUpdated(response, data) {
      this.$refs.DriverTable.resetPageCount();
      this.loadListData();
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.DriverTable.loadData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.DriverTable.setSearchConditions(searchCond);
      this.$refs.DriverTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力確認ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  },
  computed: {
    ...mapGetters({
      getContractType: "site/contractTypeFromCd",
      getStatus: "site/statusFromCd"
    }),
    getListActionName: {
      get() {
        return "driver/fetchAllDriver";
      }
    },
    driverList: {
      get() {
        if (_.isNil(this.$store.state.driver.driverList)) return [];
        return this.$store.state.driver.driverList;
      }
    }
  },
  mounted() {
    this.loadListData();
  }
};
</script>
