<template lang="pug">
  EditDialog(ref="EditDialog" title="支払タリフ区分" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field(v-model="record.name" label="支払タリフ区分名" :error-messages="getErrorMessages('name')")

          v-flex(xs12 md4)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

        v-layout(row wrap)
          v-flex(xs12 md12)
            v-textarea(v-model="record.remark" label="備考" :error-messages="getErrorMessages('remark')" maxlength=1000)
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import StatusSelect from "@/components/common/select/StatusSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    StatusSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {
        id: null,
        name: null,
        status: Enum.ActiveFlag.Active.code,
        remark: null
      }
    };
  },
  methods: {
    initData() {
      return {
        id: null,
        name: null,
        status: Enum.ActiveFlag.Active.code
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
