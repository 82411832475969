<template lang="pug">
  SearchConditions(ref="SearchConditions" :initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      alert-box(ref="errorMsg")
      v-layout(wrap)
        v-flex(md2)
          DatePicker(pickerLabel="基準日(開始)" :initDate="conditions.paymentBaseDateStart" :inputDate.sync="conditions.paymentBaseDateStart" :isClearable="false")
        v-flex(md2)
          DatePicker(pickerLabel="基準日(終了)" :initDate="conditions.paymentBaseDateEnd" :inputDate.sync="conditions.paymentBaseDateEnd" :isClearable="false")

        v-flex(md4)
          v-text-field(label="1次配送委託先" v-model="conditions.primaryDeliveryCompanyName")
        v-flex(md2)
          FaxSendStatusSelect(:code.sync="conditions.faxSendStatus" :clearable="true")
        v-flex(md2)
          v-checkbox(label="FAX送信対象のみ表示" v-model="conditions.onlyFaxTarget" hide-details )
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import MonthPicker from "@/components/common/MonthPicker";
import DatePicker from "@/components/common/DatePicker";
import FaxSendStatusSelect from "@/components/common/select/FaxSendStatusSelect";
import AlertBox from "@/components/common/AlertBox";

export default {
  components: {
    SearchConditions,
    MonthPicker,
    DatePicker,
    FaxSendStatusSelect,
    AlertBox
  },
  methods: {
    /**
     * Emit：検索条件 変更
     */
    onChangeSearchConditions(item) {
      this.nonDisplayMessage();
      this.$emit("onChangeSearchConditions", item);
    },
    /**
     * アラートボックス表示
     */
    displayMessage(errorMsg) {
      this.$refs.errorMsg.displayMessage(errorMsg);
    },
    /**
     * アラートボックス非表示
     */
    nonDisplayMessage() {
      this.$refs.errorMsg.nonDisplayMessage();
    },
    /**
     * 初期設定：子コンポーネント情報
     */
    initSearchCond() {
      return {
        conditions: {
          paymentBaseDateStart: window.Common.Util.getTomorrowYearMonthDayIsoFormat(),
          paymentBaseDateEnd: window.Common.Util.getTomorrowYearMonthDayIsoFormat(),
          primaryDeliveryCompanyName: "",
          faxSendStatus: "",
          onlyFaxTarget: false
        }
      };
    },
    /**
     * Event：子コンポーネントsubmit処理
     * ※最終的に子からemitでonChangeSearchConditions(item)に伝播される。
     */
    submit() {
      this.$refs.SearchConditions.submit();
    }
  }
};
</script>
