import Vue from "vue";
import Vuex from "vuex";

import classify from "./stores/classify";
import dispatch from "./stores/dispatch";
import dynamic from "./stores/dynamic";
import vehicle from "./stores/vehicle";
import area from "./stores/area";
import driver from "./stores/driver";
import user from "./stores/user";
import site from "./stores/site";
import item from "./stores/item";
import auth from "./stores/auth";
import order from "./stores/order";
import client from "./stores/client";
import base from "./stores/base";
import itemType from "./stores/item-type";
import bjiCustomer from "./stores/bji-customer";
import deliveryCompany from "./stores/delivery-company";
import prefecture from "./stores/prefecture";
import transportType from "./stores/transport-type";
import billingType from "./stores/billing-type";
import clientBranch from "./stores/client-branch";
import role from "./stores/role";
import driverName from "./stores/driver-name";
import operationResource from "./stores/operation-resource";
import baseClass from "./stores/base-class";
import report from "./stores/report";
import boilerplate from "./stores/boilerplate";
import signature from "./stores/signature";
import tariff from "./stores/tariff";
import billingItem from "./stores/billing-item";
import paymentItem from "./stores/payment-item";
import baseDistance from "./stores/base-distance";
import paymentTariffType from "./stores/payment-tariff-type";
import paymentTariffRule from "./stores/payment-tariff-rule";
import paymentType from "./stores/payment-type";
import fixedBillingRate from "./stores/fixed-billing-rate";
import fixedPaymentRate from "./stores/fixed-payment-rate";
import exportTemplate from "./stores/export-template";
import exportCsv from "./stores/export-csv";
import finance from "./stores/finance";
import financeImport from "./stores/finance-import";
import statement from "./stores/statement";
import indicator from "./stores/indicator";
import dispatchPattern from "./stores/dispatch-pattern";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    classify,
    dispatch,
    dynamic,
    vehicle,
    area,
    driver,
    user,
    site,
    item,
    auth,
    order,
    client,
    base,
    itemType,
    bjiCustomer,
    deliveryCompany,
    prefecture,
    transportType,
    billingType,
    clientBranch,
    role,
    driverName,
    operationResource,
    baseClass,
    report,
    boilerplate,
    signature,
    tariff,
    billingItem,
    paymentItem,
    baseDistance,
    paymentTariffType,
    paymentTariffRule,
    paymentType,
    fixedBillingRate,
    fixedPaymentRate,
    exportTemplate,
    exportCsv,
    finance,
    financeImport,
    statement,
    indicator,
    dispatchPattern
  },
  // strict: process.env.NODE_ENV !== "production"
  strict: false
});
