<template lang="pug">
  HistoryDialog(ref="HistoryDialog" title="固定請求料金"
    :leftData="fixedBillingRateHistoryList" :oldUpdateUser="oldUpdateUser" :newUpdateUser="newUpdateUser" @incrementPageCount="incrementHistoryTablePageCount"
    @selectLine="selectLine" @terminate="terminate" load-url="fixedBillingRate/fetchFixedBillingRateHistoryList" :targetId="targetId")
    template(v-slot:rightTop)
      HistoryTable(:diffArrayData="newRows")

    template(v-slot:rightBottom)
      HistoryTable(:diffArrayData="oldRows")

</template>

<script>
import HistoryDialog from "@/components/common/dialog/HistoryDialog";
import HistoryTable from "@/components/common/HistoryTable";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    HistoryDialog,
    HistoryTable
  },
  data() {
    return {
      targetId: null
    };
  },
  computed: {
    ...mapGetters({
      fixedBillingRateHistoryList: "fixedBillingRate/fixedBillingRateHistoryList",
      fixedBillingRateHistoryDiff: "fixedBillingRate/fixedBillingRateHistoryDiff"
    }),
    newRows: {
      get() {
        return this.fixedBillingRateHistoryDiff != null ? this.fixedBillingRateHistoryDiff.newRows : null;
      }
    },
    oldRows: {
      get() {
        return this.fixedBillingRateHistoryDiff != null ? this.fixedBillingRateHistoryDiff.oldRows : null;
      }
    },
    oldUpdateUser: {
      get() {
        return this.fixedBillingRateHistoryDiff != null
          ? this.fixedBillingRateHistoryDiff.oldUpdateUser
          : null;
      }
    },
    newUpdateUser: {
      get() {
        return this.fixedBillingRateHistoryDiff != null
          ? this.fixedBillingRateHistoryDiff.newUpdateUser
          : null;
      }
    }
  },
  methods: {
    ...mapMutations({
      incrementHistoryTablePageCount: "fixedBillingRate/incrementHistoryTablePageCount",
      clearHistoryTablePageCount: "fixedBillingRate/clearHistoryTablePageCount"
    }),
    ...mapActions("fixedBillingRate", {
      fetchFixedBillingRateHistoryList: "fetchFixedBillingRateHistoryList",
      fetchFixedBillingRateDiff: "fetchFixedBillingRateDiff",
      clearHistoryData: "clearHistoryData"
    }),
    openDialog(data) {
      this.clearHistoryTablePageCount();
      this.targetId = data.id;
      this.fetchFixedBillingRateHistoryList(data.id).then(value => {
        this.$refs.HistoryDialog.initialize();
      });
      this.$refs.HistoryDialog.openDialog();
    },
    closeDialog() {
      this.$refs.HistoryDialog.closeDialog();
    },
    selectLine(data) {
      if (data) {
        this.fetchFixedBillingRateDiff(data.id);
      }
    },
    terminate() {
      this.clearHistoryData();
    }
  }
};
</script>

<style scoped lang="stylus"></style>
