<template lang="pug">
  v-flex(xs12)
    table.accordion-table(class="sticky-table order-table")
      thead
        tr
          th No
          th 積込日/納品日
          th 荷主
          th 荷主枝番
          th 配送委託先会社
          th 積地
          th.no-right-border 卸地
          th(class="time-icon-cell")
          th 車両車格
          th 車番
          th 積荷種別
          th 荷役区分
          th 距離区分
          th(width="150px").no-right-border 備考
      tbody
        template(v-for="(rec, index) in getList" v-if="getList.length" )
          tr
            td.text-sm-center {{ index + 1 }}
            td.text-sm-center.text-whitespace-pre {{ getCombineDate(rec.shipmentDate, rec.deliveryDate) | commaToNr }}
            td {{ rec.clientName }}
            td {{ rec.clientBranchName }}
            td {{ rec.deliveryCompanyName }}
            td.text-whitespace-pre {{ rec.loadingBaseNames | commaToNr }}
            td.no-right-border.text-whitespace-pre {{ rec.unloadingBaseNames | commaToNr }}
            td(class="time-icon-cell")
              SpecifiedTimeTypeIcon(v-for="specifiedTimeType in rec.specifiedTimeTypeList" :class="isNothing(specifiedTimeType) ? 'visibility-hidden' : ''" :specifiedTimeType="specifiedTimeType")
            td {{ rec.vehicleTypeName }}
            td {{ rec.vehicleNuber }}
            td {{ rec.itemTypeName }}
            td {{ rec.handlingUnitTypeName }}
            td {{ rec.distanceTypeName }}
            td {{ rec.remark }}
          tr(v-if="!getList.length")
            td(:colspan="9")  {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import { mapGetters } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import InfiniteScrollHandler from "@/mixin/InfiniteScrollHandler";
import SpecifiedTimeTypeHandler from "@/mixin/SpecifiedTimeTypeHandler";
import SpecifiedTimeTypeIcon from "@/components/common/SpecifiedTimeTypeIcon";
import ConbineUtils from "@/mixin/ConbineUtils";

export default {
  components: {
    SpecifiedTimeTypeIcon
  },
  mixins: [EventDispatcher, InfiniteScrollHandler, SpecifiedTimeTypeHandler, ConbineUtils],
  computed: {
    ...mapGetters({
      getList: "exportCsv/getList"
    })
  }
};
</script>

<style lang="stylus" scoped>
.accordion-table {
  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }
}

.visibility-hidden {
  visibility: hidden;
}
</style>
