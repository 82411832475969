<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      //
    };
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      capitalBlankList: "report/getPrimaryDeliveryCompanyThatCapitalBlank"
    }),
    hasData() {
      return this.capitalBlankList.length > 0;
    }
  }
};
</script>

<template lang="pug">
  section
    v-alert(
      :value="true" type="warning" dismissible v-if="hasData"
    )
      p {{this.$config.messages.Common.Warn.CapitalBlankDeliveryCompany}}
      div(v-for="rec in capitalBlankList") {{ rec.companyName }}
</template>

<style scoped lang="stylus"></style>
