<template lang="pug">
  EditDialog(ref="EditDialog" title="宵積み対象選択" @save="save" :disabledSave="this.selectKey === null" :displayDeleteButton="false" :hasTitleSuffix="false" labelSaveButton="確定")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout.test1(row wrap)
          v-flex(xs12)
            v-alert(:value="true" color="info" icon="info" outline) {{ $config.messages.Common.Info.NightLoadingMultiSelectable }}
          v-flex.test2(xs12 md12)
            table
              thead
                tr
                  th(v-for="(header, index) in config.headers")
                    slot(name="head" v-bind:head="header")
                      div(class="th-text font-weight-thin grey--text text--darken-1") {{ header.text }}

              tbody
                tr(v-for="(item, key) in records" @click="selectLine(key)" v-bind:class="{ active: key === selectKey }")
                  slot(name="body" v-bind:body="item")
                    td(v-for="header in config.headers" v-bind:class="header.align") {{ item[header.value] }}

</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import { mapGetters } from "vuex";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  computed: {
    ...mapGetters({
      getScheduledLoadingDate: "dispatch/getScheduledLoadingDate"
    })
  },
  data() {
    return {
      controls: {
        index: null
      },
      config: {
        headers: [
          { text: "No", value: "no", align: "text-xs-right" },
          {
            text: "乗務員名",
            value: "name",
            align: "text-xs-left"
          },
          {
            text: "所属会社",
            value: "deliveryCompanyName",
            align: "text-xs-left"
          },
          {
            text: "配車日",
            value: "scheduledLoadingDate",
            align: "text-xs-center"
          },
          {
            text: "最終運行番号",
            value: "cardOrder",
            align: "text-xs-right"
          }
        ]
      },
      records: {},
      fromDriverId: null,
      selectKey: null,
      tranData: {}
    };
  },
  methods: {
    /**
     * 初期データ
     */
    initData(data) {
      let datas = [];
      for (let i = 0, len = data.length; i < len; i++) {
        datas.push({
          no: i + 1,
          driverId: data[i].driver.id,
          name: data[i].driver.name,
          deliveryCompanyName: data[i].driver.deliveryCompanyName,
          scheduledLoadingDate: data[i].scheduledLoadingDate,
          cardOrder: data[i].cardOrder
        });
      }

      return datas;
    },
    /**
     * 初期化処理
     */
    setTranData() {
      const rec = this.records[this.selectKey];
      this.tranData = {
        destScheduledLoadingDate: this.records[this.selectKey].scheduledLoadingDate,
        srcScheduledLoadingDate: this.getScheduledLoadingDate,
        srcDriverId: this.fromDriverId,
        destDriverId: rec.driverId
      };
    },
    /**
     * フォームの初期化
     * @param data
     */
    initForm(data) {
      this.selectKey = null;
      this.records = Array.from(this.initData(data.item));
      this.fromDriverId = data.driverId;
      this.errors = {};
    },
    openDialog(data) {
      this.initForm(data);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      if (this.selectKey !== null) {
        this.setTranData();
        this.saveRecord(this.tranData).then(() => {
          this.$refs.EditDialog.closeDialog();
          this.$emit("saved", {
            driverId: this.fromDriverId
          });
        });
      }
    },
    selectLine(key) {
      this.selectKey = key;
    }
  }
};
</script>

<style lang="stylus" scoped>
.container {
  padding: 1em;
}

thead, thead tr {
  margin: 0;
  padding: 0;
}

tbody tr.active {
  background-color: #f5f5f5;
  transition: 0.2s;
}

thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: -1.2em;
  z-index: 1;
  padding: 1em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

tbody tr:hover {
  background-color: #f5f5f5;
  transition: 0.2s;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
}

.result-content {
  height: 550px;
}
</style>
