<template lang="pug">
  v-content
    EmergencyErrorDialog
    v-container(fluid grid-list-md)
      v-layout
        v-flex(md12)
          v-card
            v-card-text
              PaymentStatementFilterConditions(ref="PaymentStatementFilterConditions" @onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              v-layout(fluid)
                v-flex(xs12)
                  v-btn(@click="exportConfirm(exportType.sendUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll || !searchCond.onlyFaxTarget || !isTemporaryConfirmed") {{ exportType.sendLabel }}
                  v-btn(@click="exportConfirm(exportType.downloadUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll") {{ exportType.downloadLabel }}
                  ProgressBar
                  ExportConfirmDialog(ref="ExportConfirmDialog" :message="confirmMessage" @yes="exportReport")
      v-layout
        v-flex(xs12)
          v-card
            PaymentStatementListTable(ref="PaymentStatementListTable" @onChangeSearchConditions="onChangeSearchConditions")
</template>

<script>
import { mapGetters } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import ExportHandler from "@/mixin/ExportHandler";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import PaymentStatementFilterConditions from "@/components/report/PaymentStatementFilterConditions";
import PaymentStatementListTable from "@/components/report/PaymentStatementListTable";
import ProgressBar from "@/components/common/ProgressBar";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    PaymentStatementListTable,
    PaymentStatementFilterConditions,
    EmergencyErrorDialog,
    ExportConfirmDialog,
    ProgressBar
  },
  mixins: [EventDispatcher, ExportHandler],
  data: () => ({
    // 検索エラーフラグ
    isError: false,
    confirmMessage: "現在の選択内容を出力しますか？",
    // ExportHandler処理用Store URL
    dispatchUrl: "",
    exportType: {
      downloadLabel: "支払書ダウンロード",
      sendLabel: "支払書送信(FAX)",
      downloadUrl: "statement/downloadPaymentStatement",
      sendUrl: "statement/sendPaymentStatement"
    },
    deselectedPeriodMessage: "送付期限・必着期限を設定してから出力してください。"
  }),
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      isDeselectedAll: "statement/getIsDeselectedAll",
      isDeselectedPeriod: "statement/getIsDeselectedPeriod"
    }),
    isTemporaryConfirmed: {
      get() {
        return Enum.PaymentStatusType.TEMPORARY_CONFIRMED.code === this.searchCond.paymentStatus;
      }
    }
  },
  methods: {
    /**
     * Event：検索条件 変更
     */
    onChangeSearchConditions(searchCondition) {
      // ExportHandler条件設定
      this.searchCond = searchCondition;
      // InfiniteScrollHandler処理
      this.$refs.PaymentStatementListTable.setSearchConditions(searchCondition);
      this.$refs.PaymentStatementListTable.resetPageCount();
      this.$refs.PaymentStatementListTable.resetAllSelect();
      // データ取得
      this.$refs.PaymentStatementListTable.loadData()
        .then(result => (this.isError = false))
        .catch(error => {
          this.isError = true;
          // alertBox表示
          this.$refs.PaymentStatementFilterConditions.displayMessage(error.response.data.message);
        });
    },
    /**
     * Event：出力ダイアログの表示
     * ※PDFダウンロード
     */
    exportConfirm(url) {
      this.$refs.PaymentStatementFilterConditions.nonDisplayMessage();
      if (this.isError) return;
      // 署名確認
      if (this.isDeselectedPeriod) {
        this.$refs.PaymentStatementFilterConditions.displayMessage(this.deselectedPeriodMessage);
        return;
      }
      this.dispatchUrl = url;
      this.$refs.ExportConfirmDialog.openDialog();
    },
    exportReport() {
      this.exportData(this.dispatchUrl).then(() => {
        this.$refs.PaymentStatementFilterConditions.submit();
      });
    }
  },
  mounted() {
    // 初期ロード時に初期検索情報にて結果を表示
    this.$store.dispatch("statement/clearFetchList");
    this.$refs.PaymentStatementFilterConditions.submit();
  }
};
</script>
