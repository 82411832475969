<template lang="pug">
  section.main-content
    LoadingWrapper(:isDisplay="displayProgress")
    EmergencyErrorDialog(ref="EmergencyErrorDialog" @clickClose="goLogin")
    EmergencyErrorDialog(ref="ServerErrorDialog")

    // サイドバー
    v-navigation-drawer(app fixed :mini-variant="mini" permanent mini-variant-width="60"
      @mouseleave.native="mouseLeaveCloseMenu()" @mouseenter.native="mouseEnterOpenMenu()")
      v-list
        template(v-for="item in sidebar")
          // ホーム
          v-list-tile(v-if="item.childMenuKey == null"
            @click="goHome")
            v-list-tile-action
              v-icon home
            v-list-tile-content
              v-list-tile-title ホーム
          // メインメニュー
          v-list-group(v-if="canGroupAccess(item)" v-model="item.active" :prepend-icon="item.action" no-action v-bind:class="[currentRoute != null && currentRoute.meta.parent == item.childMenuKey ? 'menu--active' : '']")
            template(v-slot:activator)
              v-list-tile
                v-list-tile-content
                  v-list-tile-title {{ item.title }}
            // サブメニュー
            v-list-tile(v-if="isCanAccess(subItem)" v-for="subItem in childMenu[item.childMenuKey]"
              :key="subItem.title" :to="{ name: subItem.routeName }")
              v-list-tile-action
                v-icon {{ subItem.action }}
              v-list-tile-content
                v-list-tile-title {{ subItem.title }}

    v-toolbar( :color="getSiteColor" dark fixed app dense)
      v-toolbar-title(v-if="$route.name != 'dashBoard'")
        span {{ getPageTitle }}
        span.staging-title {{ getSiteTitle }}
      v-spacer
      v-toolbar-items
        v-menu(offset-y)
          v-btn(slot="activator" dark flat)
            v-icon(left) fas fa-user-circle
            span {{ loginUserName }}
            v-icon(right) fas fa-angle-down
          v-list(dense)
            v-list-tile(avatar @click="")
              v-list-tile-avatar
                v-icon fas fa-sign-out-alt
              v-list-tile-content(@click="logout")
                span.caption ログアウト

    transition(name="fade" mode="out-in")
      template(v-if="$route.name == 'dashBoard'")
        v-content.main-content
          v-container(fluid fill-height)
            v-layout(align-center justify-center row fill-height)
              span.logo-text トラスコ DisPatch one
      template(v-else)
        router-view
    AlertSnackBar(ref="AlertSnackBar")

    // ページトップへボタン
    v-btn(fab dark fixed bottom right :color="$store.getters['site/siteColor']" :class="{ 'btn-hidden': pageTopBtnStatus.isHidden }" @click="$vuetify.goTo('#app')")
      v-icon(dark) keyboard_arrow_up
</template>

<script>
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import AlertSnackBar from "@/components/common/AlertSnackBar";
import LoadingWrapper from "@/components/common/LoadingWrapper";
import { mapGetters } from "vuex";
import * as Enum from "@/assets/js/enum";

export default {
  components: { EmergencyErrorDialog, AlertSnackBar, LoadingWrapper },
  data() {
    return {
      mini: true,
      hoverHome: false,
      hoverMain: false,
      hoverSub: false,
      childMenu: {
        order: [
          {
            title: "受注インポート",
            routeName: Enum.FunctionList.ImportOrder.code,
            action: "fas fa-file-upload"
          },
          {
            title: "受注一覧",
            routeName: Enum.FunctionList.OrderList.code,
            action: "fas fa-clipboard-list"
          },
          {
            title: "受注一覧(取込時削除分)",
            routeName: Enum.FunctionList.OrderDeleteList.code,
            action: "fas fa-trash"
          },
          {
            title: "シップメントNo取込み",
            routeName: Enum.FunctionList.ImportShipmentNo.code,
            action: "fas fa-file-upload"
          }
        ],
        dispatch: [
          {
            title: "配車表",
            routeName: Enum.FunctionList.DispatchSchedule.code,
            action: "fas fa-calendar-alt"
          },
          {
            title: "配車メール",
            routeName: Enum.FunctionList.DispatchMailList.code,
            action: "fas fa-calendar-alt"
          }
        ],
        dynamic: [
          {
            title: "動態管理",
            routeName: Enum.FunctionList.DynamicTracking.code,
            action: "fas fa-calendar-alt"
          }
        ],
        report: [
          {
            title: "車番連絡表出力",
            routeName: Enum.FunctionList.ReportServiceContact.code,
            action: "fas fa-file-pdf"
          },
          {
            title: "輸送依頼(指示)書出力",
            routeName: Enum.FunctionList.TransportOrderReport.code,
            action: "fas fa-file-pdf"
          },
          {
            title: Enum.FunctionList.ExportBillingStatement.label,
            routeName: Enum.FunctionList.ExportBillingStatement.code,
            action: "fas fa-file-pdf"
          },
          {
            title: Enum.FunctionList.ExportPaymentStatement.label,
            routeName: Enum.FunctionList.ExportPaymentStatement.code,
            action: "fas fa-file-pdf"
          },
          {
            title: Enum.FunctionList.ExportPaymentPrompt.label,
            routeName: Enum.FunctionList.ExportPaymentPrompt.code,
            action: "fas fa-file-pdf"
          }
        ],
        finance: [
          {
            title: Enum.FunctionList.BillingAndPayment.label,
            routeName: Enum.FunctionList.BillingAndPayment.code,
            action: "fas fa-yen-sign"
          },
          {
            title: Enum.FunctionList.ExpensesImport.label,
            routeName: Enum.FunctionList.ExpensesImport.code,
            action: "fas fa-yen-sign"
          }
        ],
        export: [
          {
            title: Enum.FunctionList.ExportOrderAndCard.label,
            routeName: Enum.FunctionList.ExportOrderAndCard.code,
            action: "fas fa-file-csv"
          }
        ],
        maintenance: [
          { title: "利用者", routeName: Enum.FunctionList.UserList.code, action: "fas fa-users" },
          {
            title: "車両",
            routeName: Enum.FunctionList.VehicleList.code,
            action: "fas fa-truck-moving"
          },
          {
            title: "車格",
            routeName: Enum.FunctionList.VehicleTypeList.code,
            action: "fas fa-truck-moving"
          },
          {
            title: "車庫",
            routeName: Enum.FunctionList.VehicleGarageList.code,
            action: "fas fa-truck-moving"
          },
          {
            title: "車格積載重量",
            routeName: Enum.FunctionList.VehicleCarryWeightList.code,
            action: "fas fa-truck-moving"
          },
          {
            title: "乗務員",
            routeName: Enum.FunctionList.DriverList.code,
            action: "fas fa-user-friends"
          },
          {
            title: "乗務員スケジュール",
            routeName: Enum.FunctionList.DriverSchedule.code,
            action: "fas fa-calendar-alt"
          },
          {
            title: "運行可能リソース",
            routeName: Enum.FunctionList.OperationResource.code,
            action: "fas fa-calendar-alt"
          },
          { title: "積地", routeName: Enum.FunctionList.LoadingBaseList.code, action: "fas fa-city" },
          { title: "卸地", routeName: Enum.FunctionList.UnloadingBaseList.code, action: "fas fa-city" },
          {
            title: "荷主",
            routeName: Enum.FunctionList.GeneralClientList.code,
            action: "fas fa-city"
          },
          {
            title: "荷主積地分類",
            routeName: Enum.FunctionList.ClientLoadingBaseClass.code,
            action: "fas fa-city"
          },
          {
            title: "荷主卸地分類",
            routeName: Enum.FunctionList.ClientUnloadingBaseClass.code,
            action: "fas fa-city"
          },
          {
            title: "荷主枝番",
            routeName: Enum.FunctionList.ClientBranch.code,
            action: "fas fa-city"
          },
          {
            title: "配送委託先会社",
            routeName: Enum.FunctionList.DeliveryCompany.code,
            action: "fas fa-city"
          },
          {
            title: "エリア",
            routeName: Enum.FunctionList.AreaList.code,
            action: "fas fa-map-marked-alt"
          },
          {
            title: "タリフ",
            routeName: Enum.FunctionList.TariffList.code,
            action: "fas fa-yen-sign"
          },
          {
            title: "請求項目",
            routeName: Enum.FunctionList.BillingItemList.code,
            action: "fas fa-yen-sign"
          },
          {
            title: "支払項目",
            routeName: Enum.FunctionList.PaymentItemList.code,
            action: "fas fa-yen-sign"
          },
          {
            title: "距離",
            routeName: Enum.FunctionList.BaseDistanceList.code,
            action: "fas fa-yen-sign"
          },
          {
            title: "支払タリフ区分",
            routeName: Enum.FunctionList.PaymentTariffTypeList.code,
            action: "fas fa-yen-sign"
          },
          {
            title: "支払タリフルール",
            routeName: Enum.FunctionList.PaymentTariffRuleList.code,
            action: "fas fa-yen-sign"
          },
          {
            title: "固定請求料金",
            routeName: Enum.FunctionList.FixedBillingRateList.code,
            action: "fas fa-yen-sign"
          },
          {
            title: "固定支払料金",
            routeName: Enum.FunctionList.FixedPaymentRateList.code,
            action: "fas fa-yen-sign"
          },
          {
            title: "定型文",
            routeName: Enum.FunctionList.BoilerplateList.code,
            action: "fas fa-cubes"
          },
          {
            title: "署名",
            routeName: Enum.FunctionList.SignatureList.code,
            action: "fas fa-cubes"
          },
          {
            title: "テンプレート",
            routeName: Enum.FunctionList.ExportTemplateList.code,
            action: "fas fa-cubes"
          },
          {
            title: "積荷種別",
            routeName: Enum.FunctionList.ItemTypeList.code,
            action: "fas fa-cubes"
          },
          {
            title: "請求区分",
            routeName: Enum.FunctionList.BillingTypeList.code,
            action: "fas fa-cubes"
          },
          {
            title: "支払区分",
            routeName: Enum.FunctionList.PaymentTypeList.code,
            action: "fas fa-cubes"
          },
          { title: "権限管理", routeName: Enum.FunctionList.RoleList.code, action: "fas fa-cubes" },
          {
            title: "配車パターン",
            routeName: Enum.FunctionList.DispatchPatternList.code,
            action: "fas fa-cubes"
          }
        ]
      },
      sidebar: [
        {
          action: "fas home",
          title: "ホーム",
          childMenuKey: null,
          active: false,
          menuKey: "dashBoard"
        },
        {
          action: "fas fa-briefcase fa-sm",
          title: "受注管理",
          childMenuKey: "order",
          active: false
        },
        {
          action: "fas fa-clock",
          title: "配車管理",
          childMenuKey: "dispatch",
          active: false
        },
        {
          action: "fas fa-truck-moving",
          title: "動態管理",
          childMenuKey: "dynamic",
          active: false
        },
        {
          action: "fas fa-folder",
          title: "帳票出力",
          childMenuKey: "report",
          active: false
        },
        {
          action: "fas fa-yen-sign",
          title: "会計",
          childMenuKey: "finance",
          active: false
        },
        {
          action: "fas fa-file-export",
          title: "データ出力",
          childMenuKey: "export",
          active: false
        },
        {
          action: "fas fa-database",
          title: "マスタ管理",
          childMenuKey: "maintenance",
          active: false
        }
      ],
      pageTopBtnStatus: {
        isHidden: true
      },
      currentRoute: null
    };
  },
  beforeRouteEnter(to, from, next) {
    // サブメニューの画面ではメニューをオープンしたままにする
    if (to.meta.parent) {
      next(vm => {
        vm.currentRoute = to;
        vm.setData(to.meta.parent);
      });
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.currentRoute = to;
    next();
  },
  computed: {
    ...mapGetters({
      loginUserName: "site/loginUserName"
    }),
    getPageTitle() {
      if (this.$store.getters["site/pageTitle"] !== "") {
        return "DP1 " + this.$store.getters["site/pageTitle"];
      }
      return "";
    },
    displayProgress() {
      if (this.currentRoute != null) {
        //配車画面と動態管理画面は、共通ローディングではなく独自のローディングを利用する
        //※画面内での通信処理が多岐にわたるため
        if (this.currentRoute.name === Enum.FunctionList.DispatchSchedule.code) {
          return false;
        }
        if (this.currentRoute.name === Enum.FunctionList.DynamicTracking.code) {
          return false;
        }
      }
      return this.$store.state.site.signalCount > 0;
    },
    getAuth() {
      return this.$store.getters["site/authorities"];
    },
    getSiteTitle() {
      return this.$store.getters["site/siteTitle"];
    },
    getSiteColor() {
      return this.$store.getters["site/siteColor"];
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    const self = this;
    // イベントを待受します。
    window.addEventListener(
      window.Common.Config.observer.onErrorResponseNetworkError,
      function (e) {
        self.$refs.ServerErrorDialog.open();
      },
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onErrorResponse401,
      function (e) {
        self.$refs.EmergencyErrorDialog.open();
      },
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onErrorResponse400,
      function (e) {
        let message = "";
        if (e.detail.message !== undefined) {
          message = e.detail.message;
        }
        self.$refs.ServerErrorDialog.open(message);
      },
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onErrorResponse403,
      function (e) {
        let message = "";
        if (e.detail.message !== undefined) {
          message = e.detail.message;
        }
        self.$refs.ServerErrorDialog.open(message);
      },
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onErrorResponse500,
      function (e) {
        self.$refs.ServerErrorDialog.open();
      },
      false
    );

    //404
    window.addEventListener(
      window.Common.Config.observer.onErrorResponse404,
      function (e) {
        self.$refs.ServerErrorDialog.open(window.Common.Messages.Common.Warn.DataNotFound);
      },
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onCommonErrorResponse404,
      function (e) {
        self.$refs.ServerErrorDialog.open(window.Common.Messages.Common.Warn.CommonDataNotFound);
      },
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onCommonErrorResponse400,
      function (e) {
        self.$refs.ServerErrorDialog.open(window.Common.Messages.Common.Warn.ExportLimit);
      },
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onAxiosStart,
      function (e) {
        self.$store.commit("site/countUpSignal");
      },
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onAxiosEnd,
      function (e) {
        self.$store.commit("site/countDownSignal");
      },
      false
    );

    this.$store.watch(
      state => state.site.commonAlertMessage,
      commonAlertMessage => {
        self.$refs.AlertSnackBar.openSnack(commonAlertMessage);
      }
    );
  },
  methods: {
    goHome() {
      this.$router.push(
        {
          name: "dashBoard"
        },
        () => {}
      );
    },
    goLogin() {
      this.$router.push({
        name: "login"
      });
    },
    logout: function () {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login").then(() => {
          location.reload();
        });
      });
    },
    closeMenu: function (e) {},
    openMenu: function (e) {},
    canGroupAccess(item) {
      if (item.childMenuKey == null) {
        return false;
      }

      const ret = this.childMenu[item.childMenuKey]
        .filter(item => {
          return this.isCanAccess(item);
        })
        .pop();

      return ret !== undefined;
    },
    isCanAccess(item) {
      if (this.getAuth != null) {
        const auth = this.getAuth.find(value => value.functionCode === item.routeName);
        if (auth === undefined) {
          return false;
        }

        return auth.roleBit !== 0;
      }
    },
    handleScroll() {
      this.pageTopBtnStatus.isHidden = this.getScrollTop() <= 200;
    },
    /**
     * ブラウザのスクロールバーの位置を取得
     * @returns {number}
     */
    getScrollTop() {
      const body = window.document.body;
      const html = window.document.documentElement;
      return body.scrollTop || html.scrollTop;
    },
    setData(parent) {
      for (const item of this.sidebar) {
        if (item.childMenuKey === parent) {
          // 表示時にメニューを開かない
          // item.active = true;
        }
      }
    },
    // マウスエンターでサイドバーがminiでなくなる
    mouseEnterOpenMenu() {
      this.mini = false;
    },
    // マウスリーブでサイドバーがminiになる
    mouseLeaveCloseMenu() {
      // FIXME 子メニュー開いた状態でサイドバーminiにしたとき、アイコンが表示されないから、視認性悪くなる。閉じたら子メニューも閉じる暫定対応
      this.sidebar.forEach(value => {
        value.active = false;
      });
      this.mini = true;
    }
  }
};
</script>

<style scoped lang="stylus">
.logo-text {
  font-size: 32px;
  text-align: center;
  color: #3f51b5;
  word-break: break-all;
  margin: 0.5em;
}

.main-content {
  height: 100%;
}

.progress-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-left: -24px;

  .v-progress-linear {
    margin: 0;
  }
}

.navi-sheet {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.btn-hidden {
  opacity: 0;
  pointer-events: none;
}

>>> .v-list__group--active {
  .v-list__group__header--active {
    background-color: whitesmoke;
  }

  .v-list__group__items {
    background-color: whitesmoke;
  }
}

>>> .menu--active .v-list__group__header__prepend-icon .v-icon {
  color: #1976d2 !important;
}

>>> .v-list__group__header .v-list__group__header__prepend-icon {
  width: 56px;
}

>>> .v-navigation-drawer--mini-variant {
  .v-list__group__header__prepend-icon {
    width: 100%;
    justify-content: start;
  }

  .v-list__tile__action {
    justify-content: start;
  }
}

.staging-title {
  display: inline-block;
  margin-left: 16px;
  color: #fde2e5;
}
</style>
