<template lang="pug">
  EditDialog(ref="EditDialog" title="車格積載重量" :recordId="record.id" @delete="erase" @save="save"
      :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md4)
            VehicleTypeSelect(:readonly="record.id != null" :code.sync="record.vehicleTypeId" :errorHandlerMethod="getErrorMessages")

        v-layout(row wrap)
          v-flex(xs12 md4)
            v-text-field.text-align-right(v-model="record.carryWeight" label="積載重量" suffix="kg" :error-messages="getErrorMessages('carryWeight')")

          v-flex(xs12 md4)
            v-text-field.text-align-right(v-model="record.maxCarryWeight" label="最大積載重量" suffix="kg" :error-messages="getErrorMessages('maxCarryWeight')")

          v-flex(xs12 md4)
            v-text-field.text-align-right( v-model="record.displayOrder" label="表示順" :error-messages="getErrorMessages('displayOrder')")

</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    EditDialog,
    VehicleTypeSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      title: "車格積載重量編集",
      record: {
        id: null,
        vehicleTypeCd: null,
        carryWeight: null,
        displayOrder: null
      }
    };
  },
  methods: {
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
