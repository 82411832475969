<template lang="pug">
  section
    EditDialog(ref="EditDialog" title="手動登録" @delete="erase" @save="savePreFlight" :recordId="record.id"
    :displayDeleteButton="!isDispatchedDetail && canEditData" :enableSave="!isDispatchedDetail && canEditData"
    :loadingSave="loading.save" :loadingDelete="loading.delete"
    @closeEditDialog="closeEditDialog")
      template(v-slot:body)
        v-container(grid-list-md)
          v-layout(row wrap)
            // 荷主
            v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
              ClientSelect(:label.sync="record.client.clientDisplayName" :code.sync="record.client.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'clientId'")
            v-flex(xs12 md3 v-else)
              v-text-field(readonly box label="荷主" v-model="record.client.clientDisplayName")

            // 請求区分
            v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
              BillingTypeSelect(:code.sync="record.detail.billingType.id" :errorHandlerMethod="getErrorMessages")
            v-flex(xs12 md3 v-else)
              v-text-field(readonly box label="請求区分" v-model="record.detail.billingType.billingTypeName")

            // 距離区分
            v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
              DistanceTypeSelect(:code.sync="record.distanceType" :errorHandlerMethod="getErrorMessages")
            v-flex(xs12 md3 v-else)
              v-select(readonly box v-model="record.distanceType" :items="distanceTypes" item-text="label" item-value="code" label="距離区分")

            // 荷主枝番
            v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
              ClientBranchSelect(:clientId="record.client.id" :clientBranchId="record.clientBranch.id" :code.sync="record.clientBranch.id" @updateLabel="value => record.clientBranch.branchName = value" :errorHandlerMethod="getErrorMessages")
            v-flex(xs12 md3 v-else)
              v-text-field(readonly box label="荷主枝番" v-model="record.clientBranch.branchName")

            // 受注日
            v-flex(xs12 md3)
              DatePicker(:isReadonly="isDispatchedOrder || !canEditData" pickerLabel="受注日" :initDate.sync="getInitOrderDate" :inputDate.sync="record.orderDate" :errorHandlerMethod="getErrorMessages" errorHandlerMethodParam="orderDate")

            // 積日
            v-flex(xs12 md3)
              DatePicker(:isReadonly="isDispatchedOrder || !canEditData" :pickerLabel="labelName.LoadingSchedule" :initDate.sync="getInitLoadingDate" :inputDate.sync="record.loadingDate" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'loadingDate'")

            // 納品日
            v-flex(xs12 md3)
              DatePicker(:isReadonly="isDispatchedOrder || !canEditData" :pickerLabel="labelName.UnloadingSchedule" :initDate.sync="getInitUnloadingDate" :inputDate.sync="record.unloadingDate" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'unloadingDate'")

            // 受付番号
            v-flex(xs12 md3 v-if="record.detail.receiptNo != null")
              v-textarea(label="受付番号" :value="record.detail.receiptNo" auto-grow box readonly hide-details rows="1")

            // 車格積載重量
            v-flex(xs12 md5)
              VehicleCarryWeightSelect(:isReadonly="isDispatchedOrder || !canEditData" :label.sync="record.details.firstItem.vehicleCarryWeight.carryWeight"
                @setSelectData="selectVehicleType"
                :code.sync="record.details.firstItem.vehicleCarryWeight.id"
                :selectVehicleTypeId.sync="record.details.firstItem.vehicleCarryWeight.vehicleTypeId"
                :vehicleTypeName="record.details.firstItem.vehicleCarryWeight.vehicleTypeName" :errorHandlerMethod="getErrorMessages")

            //台数
            v-flex(xs12 md1 v-if="!record.id")
              v-text-field.text-align-right(v-model="record.vehicleQuantity" label="台数" :error-messages="getErrorMessages('vehicleQuantity')")

            //積荷種別
            v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
              ItemTypeSelect(:label.sync="record.itemType.name" :code.sync="record.itemType.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'itemTypeId'")
            v-flex(xs12 md3 v-else)
              v-text-field(readonly box label="積荷種別" v-model="record.itemType.name")

            //積荷重量
            v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
              v-text-field.text-align-right(v-model="record.weight" @blur="addComma" label="積荷重量（１台あたり）" :error-messages="getErrorMessages('weight')" suffix="kg")
            v-flex(xs12 md3 v-else)
              v-text-field.text-align-right(readonly box v-model="record.weight" @blur="addComma" label="積荷重量（１台あたり）" :error-messages="getErrorMessages('weight')" suffix="kg")

            //荷役区分
            v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
              HandlingUnitTypeSelect(:code.sync="record.handlingUnitType" :errorHandlerMethod="getErrorMessages")
            v-flex(xs12 md3 v-else)
              v-select(readonly box v-model="record.handlingUnitType" :items="handlingUnitTypes" item-text="label" item-value="code" label="荷役区分")


          v-layout(row wrap)
            v-flex(xs12)
              v-alert(type="warning" :value="true" v-show="!canSelectBase") 積地・卸地の設定をするには荷主と荷主枝番を選択してください。
              v-card(v-show="canSelectBase")
                v-card-text
                  v-container(grid-list-md)
                    div(v-for="(loading, index) in record.orderLoadings.sortedList")
                      v-layout(row wrap)
                        //積地
                        v-flex(xs12 md12 v-if="!isDispatchedOrder && canEditData")
                          LoadingBaseSelect(:excludeBaseIdList="loadingBaseIdList" :clientId="record.client.id" :clientBranchId="record.clientBranch.id" :enableDelete="(index == 0 ? false :true)" :label.sync="loading.loadingBase.nameAndCd" :code.sync="loading.loadingBase.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'loadingBaseList[' + index + '].baseId'" @deleteList="deleteLoadingBaseList(index)" @setSelectData="data => selectLoadingBase(loading,data)")
                        v-flex(xs12 md12 v-else)
                          v-text-field(readonly box label="積地" v-model="loading.loadingBase.nameAndCd")

                    v-layout(row wrap)
                      v-flex(xs12 md6)
                      v-icon keyboard_arrow_down

                    div(v-for="(unloading, index) in record.orderUnloadings.sortedList")
                      v-layout(row wrap)
                        //卸地
                        v-flex(xs12 md4 v-if="!isDispatchedOrder && canEditData")
                          UnloadingBaseSelect(:excludeBaseIdList="unloadingBaseIdList" :clientId="record.client.id" :clientBranchId="record.clientBranch.id" :enableDelete="(index == 0 ? false :true)" :label.sync="unloading.unloadingBase.nameAndCd" :code.sync="unloading.unloadingBase.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'unloadingBaseList[' + index + '].baseId'" @deleteList="deleteUnloadingBaseList(index)" @setSelectData="data => selectUnloadingBase(unloading, data)")
                        v-flex(xs12 md4 v-else)
                          v-text-field(readonly box label="卸地" v-model="unloading.unloadingBase.nameAndCd")

                        //時間指定(開始)
                        v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
                          TimePicker(pickerLabel="時間指定(開始)" :initTime="null" :inputTime.sync="unloading.specifiedTime.from" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'unloadingBaseList[' + index + '].specifiedTimeFrom'" )
                        v-flex(xs12 md3 v-else)
                          v-text-field(readonly box label="時間指定(開始)" v-model="unloading.specifiedTime.from")
                        //時間指定(終了)
                        v-flex(xs12 md3 v-if="!isDispatchedOrder && canEditData")
                          TimePicker(pickerLabel="時間指定(終了)" :initTime="null" :inputTime.sync="unloading.specifiedTime.to" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'unloadingBaseList[' + index + '].specifiedTimeTo'" )
                        v-flex(xs12 md3 v-else)
                          v-text-field(readonly box label="時間指定(終了)" v-model="unloading.specifiedTime.to")

                        v-flex(xs12 md2 v-if="!isDispatchedOrder && canEditData")
                          v-text-field.text-align-right(label="卸順序" v-model="unloading.unloadingOrder",
                            :error-messages="getErrorMessages('unloadingBaseList[' + index + '].unloadingOrder')")
                        v-flex(xs12 md2 v-else)
                          v-text-field.text-align-right(readonly box label="卸順序" v-model="unloading.unloadingOrder")

                    v-btn(v-if="!isDispatchedOrder" color="info" falt @click.stop="addLoadingBase" v-bind:disabled ="loadingBaseLimit") 積地を追加する
                    v-btn(v-if="!isDispatchedOrder" color="info" falt @click.stop="addUnloadingBase" v-bind:disabled ="unloadingBaseLimit") 卸地を追加する

            v-flex(xs12)
              v-card
                v-card-text
                  v-container(grid-list-md)
                    v-layout(row wrap)
                      v-flex(xs12)
                        v-card
                          v-card-text
                            v-layout
                              v-flex(md9)
                                BillingCardBasicPriceBox

                              v-flex(md3 v-if="!isDispatchedDetail && canEditData")
                                template(v-for="(billing,index) in billingCardDetail" v-if="billing.billingItemId === 1")
                                  DigitSeparatorsField(:readOnly="!canEditData" v-model="billing.billingItemPrice" :label="billing.billItemName"
                                    :errorMessages="getErrorMessages('billingCard.billingCardDetails[' + index + '].billingItemPrice')")
                              v-flex(md3 v-else)
                                template(v-for="(billing,index) in billingCardDetail" v-if="billing.billingItemId === 1")
                                  v-text-field.text-align-right(readonly box :label="billing.billItemName"  :value="billing.billingItemPrice|addComma" @input="value=>billing.billingItemPrice=value")

                    v-layout(row wrap)
                      template(v-for="(billing,index) in billingCardDetail"  v-if="billing.billingItemId !== 1")
                        v-flex(xs3 v-if="!isDispatchedDetail && canEditData")
                          DigitSeparatorsField(:readOnly="!canEditData" v-model="billing.billingItemPrice" :label="billing.billItemName"
                            :errorMessages="getErrorMessages('billingCard.billingCardDetails[' + index + '].billingItemPrice')")
                        v-flex(md3 v-else)
                          v-text-field.text-align-right(readonly box :label="billing.billItemName" :value="billing.billingItemPrice|addComma" @input="value=>billing.billingItemPrice=value")

                    v-layout(row wrap)
                      v-flex(xs12)
                        table.finance-summary-table
                          tr
                            th 通行料
                            td
                              DigitSeparatorsLabel(:value="billingCardToll").price
                            th 運賃料金計
                            td
                              DigitSeparatorsLabel(:value="billingCardSummaryWithoutToll").price
                      v-flex(xs12)
                        v-spacer
                        v-btn(color="info" falt @click="openBillingHistory" :disabled="!isSettingKey || isDispatchedDetail || !canEditData") 確定済履歴の表示


            // 備考
            v-flex(xs12)
              v-textarea(:readOnly="isDispatchedDetail || !canEditData" :box="isDispatchedDetail || !canEditData" label="備考",auto-grow,v-model="record.detail.remark" :error-messages="getErrorMessages('remark')")
              v-textarea(:readOnly="isDispatchedDetail || !canEditData" :box="isDispatchedDetail || !canEditData" label="会計備考",auto-grow,v-model="record.detail.financeRemark" :error-messages="getErrorMessages('financeRemark')")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialog" @yes="save")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import TimePicker from "@/components/common/TimePicker";
import DatePicker from "@/components/common/DatePicker";
import ClientSelect from "@/components/common/select/ClientSelect";
import ClientBranchSelect from "@/components/common/select/ClientBranchSelect";
import BillingTypeSelect from "@/components/common/select/BillingTypeSelect";
import DistanceTypeSelect from "@/components/common/select/DistanceTypeSelect";
import VehicleCarryWeightSelect from "@/components/common/select/VehicleCarryWeightSelect";
import ItemTypeSelect from "@/components/common/select/ItemTypeSelect";
import HandlingUnitTypeSelect from "@/components/common/select/HandlingUnitTypeSelect";
import LoadingBaseSelect from "@/components/common/select/LoadingBaseSelect";
import UnloadingBaseSelect from "@/components/common/select/UnloadingBaseSelect";
import SpecifiedTimeTypeIcon from "@/components/common/SpecifiedTimeTypeIcon";
import DigitSeparatorsField from "@/components/common/DigitSeparatorsField";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import BillingCardBasicPriceBox from "@/components/finance/BillingCardBasicPriceBox";
import FinanceOverrideWarningDialog from "@/components/common/dialog/FinanceOverrideWarningDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

import { LabelName } from "@/assets/js/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    SpecifiedTimeTypeIcon,
    EditDialog,
    TimePicker,
    DatePicker,
    ClientSelect,
    ClientBranchSelect,
    BillingTypeSelect,
    DistanceTypeSelect,
    VehicleCarryWeightSelect,
    ItemTypeSelect,
    HandlingUnitTypeSelect,
    LoadingBaseSelect,
    UnloadingBaseSelect,
    DigitSeparatorsField,
    DigitSeparatorsLabel,
    BillingCardBasicPriceBox,
    FinanceOverrideWarningDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      labelName: LabelName,
      controls: {},
      record: this.initData(),
      tranData: {}
    };
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      billingCard: "finance/billingCard",
      canOriginCardEdit: "finance/canOriginCardEdit",
      billingCardBasicPrice: "finance/billingCardBasicPrice",
      billingCardDetail: "finance/billingCardDetail",
      billingCardToll: "finance/billingCardToll",
      billingCardTotal: "finance/billingCardTotal",
      billingSaleTaxSubtotalPrice: "finance/billingSaleTaxSubtotalPrice",
      billingCardSummaryWithoutToll: "finance/billingCardSummaryWithoutToll"
    }),
    /**
     * 積地卸地が選択可能かどうか
     **/
    canSelectBase: {
      get() {
        return this.record.client.id && this.record.clientBranch.id;
      }
    },
    loadingBaseIdList: {
      get() {
        return this.record.orderLoadings.sortedList
          .filter(loading => loading.loadingBase.id != null)
          .map(loading => loading.loadingBase.id);
      }
    },
    unloadingBaseIdList: {
      get() {
        return this.record.orderUnloadings.sortedList
          .filter(unloading => unloading.unloadingBase.id != null)
          .map(unloading => unloading.unloadingBase.id);
      }
    },
    loadingBaseLimit: {
      get() {
        return this.record.orderLoadings.sortedList.length >= 5;
      }
    },
    unloadingBaseLimit: {
      get() {
        return this.record.orderUnloadings.sortedList.length >= 5;
      }
    },
    isDispatchedOrder: {
      get() {
        return (
          this.record.id && (this.record.hasDispatched || this.record.hasRelayed || this.record.hasSlide)
        );
      }
    },
    isDispatchedDetail: {
      get() {
        return (
          this.record.id &&
          (this.record.detail.dispatched || this.record.detail.relayed || this.record.detail.slide)
        );
      }
    },
    canDisplayBillingData: {
      get() {
        if (this.record.id) {
          return true;
        }

        if (this.canSelectBase && this.loadingBaseIdList.length > 0 && this.unloadingBaseIdList.length > 0) {
          return true;
        }

        return false;
      }
    },
    canEditData: {
      get() {
        if (!this.record.id) {
          return true;
        }
        return this.canOriginCardEdit;
      }
    },
    isSettingKey: {
      get() {
        return (
          this.record.client.id &&
          this.record.clientBranch.id &&
          this.loadingBaseIdList.length > 0 &&
          this.unloadingBaseIdList.length > 0
        );
      }
    },
    distanceTypes: function () {
      return Enum.Utils.enumToList(Enum.DistanceType);
    },
    handlingUnitTypes: function () {
      return Enum.Utils.enumToList(Enum.HandlingUnitType);
    }
  },
  watch: {
    "record.client.id": function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }

      this.record.billingType = this.initData().billingType;
      this.record.clientBranch = this.initData().clientBranch;

      if (this.isResetBaseTarget()) {
        this.clearLoadingBaseList();
        this.clearUnloadingBaseList();
      }
    },
    "record.clientBranch.id": function (newValue, oldValue) {
      if (!oldValue) {
        return;
      }

      if (this.isResetBaseTarget()) {
        this.clearLoadingBaseList();
        this.clearUnloadingBaseList();
      }
    }
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("finance", {
      fetchBillingCard: "fetchBillingCard",
      fetchBillingCardBasicPrice: "fetchBillingCardBasicPrice",
      clearBillingCard: "clearBillingCard",
      fetchOriginCardEditable: "fetchOriginCardEditable"
    }),
    ...mapMutations("finance", {
      writeManualOrderHistoryRequestParam: "writeManualOrderHistoryRequestParam",
      writeManualOrderSelectHistoryBase: "writeManualOrderSelectHistoryBase"
    }),
    /**
     * 初期データ
     */
    initData() {
      return {
        id: null,
        client: {
          id: null,
          clientName: null
        },
        clientBranch: {
          id: null,
          branchName: null
        },
        orderLoadings: {
          sortedList: []
        },
        orderUnloadings: {
          sortedList: []
        },
        itemType: {
          id: null,
          name: null
        },
        distanceType: null,
        vehicleCarryWeightId: null,
        handlingUnitType: null,
        vehicleQuantity: null,
        loadingBaseList: [],
        unloadingBaseList: [],
        itemTypeId: null,
        weight: null,
        orderDate: this.getInitOrderDate(),
        loadingDate: this.getInitLoadingDate(),
        unloadingDate: this.getInitUnloadingDate(),
        detail: {
          id: null,
          billingType: {
            id: null
          },
          remark: null
        },
        details: {
          firstItem: {
            id: null,
            vehicleCarryWeight: {
              id: "",
              vehicleTypeName: ""
            },
            notDispatched: false
          }
        }
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = {
        id: this.record.id,
        cardId: this.record.detail.cardId,
        detailId: this.record.detail.id,
        clientId: this.record.client.id,
        clientBranchId: this.record.clientBranch.id,
        distanceType: this.record.distanceType,
        billingTypeId: this.record.detail.billingType.id,
        orderDate: this.record.orderDate,
        loadingDate: this.record.loadingDate,
        unloadingDate: this.record.unloadingDate,
        itemTypeId: this.record.itemType.id,
        updatedAt: this.record.updatedAt,
        weight: this.record.weight ? String(this.record.weight).replace(/,/g, "") : null,
        vehicleQuantity: this.record.vehicleQuantity,
        remark: this.record.detail.remark,
        financeRemark: this.record.detail.financeRemark,
        cardUpdatedAt: this.record.detail.cardUpdatedAt,
        vehicleCarryWeightId: this.record.details.firstItem.vehicleCarryWeight.id,
        handlingUnitType: this.record.handlingUnitType,
        loadingBaseList: this.record.orderLoadings.sortedList.map(value => {
          return {
            baseId: value.loadingBase.id
          };
        }),
        unloadingBaseList: this.record.orderUnloadings.sortedList.map(value => {
          return {
            baseId: value.unloadingBase.id,
            specifiedTimeFrom: value.specifiedTime.from,
            specifiedTimeTo: value.specifiedTime.to,
            unloadingOrder: value.unloadingOrder
          };
        }),
        billingCard: { ...this.billingCard, ...{ billingCardBasicPrice: this.billingCardBasicPrice } }
      };
    },
    /**
     * 積地初期値
     */
    loadingDefault() {
      return {
        id: null,
        loadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        }
      };
    },
    /**
     * 卸地初期値
     */
    unloadingDefault() {
      return {
        id: null,
        unloadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        },
        specifiedTime: {
          from: null,
          to: null
        },
        unloadingOrder: 1
      };
    },
    /**
     * 積地フォームの追加
     */
    addLoadingBase() {
      this.record.orderLoadings.sortedList.push(this.loadingDefault());
    },
    /**
     * 積地フォームの追加
     */
    addUnloadingBase() {
      this.record.orderUnloadings.sortedList.push(this.unloadingDefault());
    },
    /**
     * 積地フォームの削除
     */
    deleteLoadingBaseList(index) {
      this.record.orderLoadings.sortedList.splice(index, 1);
      this.triggerCalcBasicPrice();
    },
    /**
     * 卸地フォームの削除
     */
    deleteUnloadingBaseList(index) {
      this.record.orderUnloadings.sortedList.splice(index, 1);
      this.triggerCalcBasicPrice();
    },
    /**
     * カンマフィルタ呼び出し
     */
    addComma(value) {
      this.record.weight = this.$options.filters.addComma(value.target.value.replace(/,/g, ""));
    },
    /**
     * フォームの初期化
     * @param values
     */
    initForm(values) {
      const self = this;
      this.record = Object.assign(this.initData(), values);
      this.setInitDate();
      if (!values || this.record.orderLoadings.sortedList == null) {
        this.clearLoadingBaseList();
      }
      if (!values || this.record.orderUnloadings.sortedList == null) {
        this.clearUnloadingBaseList();
      }
      if (values) {
        this.sortListById(this.record.loadingBaseList);
        this.sortListById(this.record.unloadingBaseList);
      }
      //請求項目の取得。新規作成なら存在しないＩＤを指定して取得するとテンプレートデータ（請求項目マスタベース）を取得
      this.fetchBillingCard(this.record.detail.cardId === undefined ? 0 : this.record.detail.cardId).then(
        () => {
          if (values.copy !== undefined) {
            self.triggerCalcBasicPrice();
          }
        }
      );

      this.errors = {};
    },
    sortListById(list) {
      return list.sort((a, b) => {
        return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
      });
    },
    /**
     * 日付の初期値設定
     */
    setInitDate() {
      if (this.record.orderDate === "") this.record.orderDate = this.getInitOrderDate();
      if (this.record.loadingDate === "") this.record.loadingDate = this.getInitLoadingDate();
      if (this.record.unloadingDate === "") this.record.unloadingDate = this.getInitUnloadingDate();
    },
    getInitOrderDate() {
      return window.Common.Util.getYearMonthDayIsoFormat();
    },
    getInitLoadingDate() {
      return window.Common.Util.getYearMonthDayIsoFormat(this.getNextBusinessDay());
    },
    getInitUnloadingDate() {
      return window.Common.Util.getYearMonthDayIsoFormat(this.getNextBusinessDay());
    },
    getNextBusinessDay() {
      const date = new Date();
      if (date.getDay() === 6) {
        date.setDate(date.getDate() + 2);
      } else {
        date.setDate(date.getDate() + 1);
      }
      return date;
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    savePreFlight() {
      this.setTranData();
      if (this.record.copy) {
        this.$refs.FinanceOverrideWarningDialog.checkEditableFinanceInfo(null);
      } else {
        this.$refs.FinanceOverrideWarningDialog.checkEditableFinanceInfo(this.tranData.cardId);
      }
    },
    save() {
      const self = this;
      this.setTranData();
      this.saveRecord(this.tranData).then(() => {
        self.$refs.EditDialog.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord(this.record.detail.id).then(() => {
        self.closeDialog();
      });
    },
    openErrorMsgBox(message) {
      this.$refs.EditDialog.openErrorMsgBox(message);
    },
    /**
     * 積地・卸地が画面上登録されていたらクリア対象として識別する
     * @returns {boolean}
     */
    isResetBaseTarget() {
      const loadingExists =
        this.record.orderLoadings.sortedList.find(value => {
          return value.loadingBase.id !== null;
        }) !== undefined;

      const unloadingExists =
        this.record.orderUnloadings.sortedList.find(value => {
          return value.unloadingBase.id !== null;
        }) !== undefined;

      return loadingExists || unloadingExists;
    },
    clearLoadingBaseList() {
      this.record.orderLoadings.sortedList = [];
      this.addLoadingBase();
    },
    clearUnloadingBaseList() {
      this.record.orderUnloadings.sortedList = [];
      this.addUnloadingBase();
    },
    closeEditDialog() {
      this.record = this.initData();
      this.clearBillingCard();
    },
    selectLoadingBase(loading, data) {
      this.triggerCalcBasicPrice();
    },
    selectUnloadingBase(unloading, data) {
      unloading.specifiedTime = data.specifiedTime;

      this.triggerCalcBasicPrice();
    },
    selectVehicleType(data) {
      this.triggerCalcBasicPrice();
    },
    triggerCalcBasicPrice() {
      const requestData = {
        clientId: this.record.client.id,
        clientBranchId: this.record.clientBranch.id,
        loadingBaseIds: this.record.orderLoadings.sortedList
          .filter(v => v.loadingBase.id)
          .map(value => {
            return value.loadingBase.id;
          }),
        unloadingBaseIds: this.record.orderUnloadings.sortedList
          .filter(v => v.unloadingBase.id)
          .map(value => {
            return value.unloadingBase.id;
          }),
        vehicleTypeId: this.record.details.firstItem.vehicleCarryWeight.vehicleTypeId
      };

      for (const key in requestData) {
        if (requestData[key] === undefined || !requestData[key]) {
          return;
        }
      }

      if (requestData.loadingBaseIds.length < 1 || requestData.unloadingBaseIds.length < 1) {
        return;
      }

      this.fetchBillingCardBasicPrice(requestData);
    },
    openBillingHistory() {
      const payload = {
        cardId: this.record.detail.cardId,
        clientId: this.record.client.id,
        clientBranchId: this.record.clientBranch.id,
        loadingBaseIdList: this.loadingBaseIdList,
        unloadingBaseIdList: this.unloadingBaseIdList
      };

      const refData = {
        clientName: this.record.client.clientDisplayName,
        clientBranchName: this.record.clientBranch.branchName,
        loadingBaseNames: this.record.orderLoadings.sortedList.map(
          value => value.loadingBase.displayBaseName
        ),
        unloadingBaseNames: this.record.orderUnloadings.sortedList.map(
          value => value.unloadingBase.displayBaseName
        )
      };

      this.writeManualOrderHistoryRequestParam(payload);
      this.writeManualOrderSelectHistoryBase(refData);

      this.$emit("openBillingHistory");
    }
  }
};
</script>
