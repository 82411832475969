<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      ItemTypeFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              ItemTypeTable(ref="ItemTypeTable" :prop-header="itemTypeTableHeader" :prop-data="iItemTypeList" detail-url="itemType/fetchItemType" @open-detail="openDetail" :getListActionName="getListActionName")

    ItemTypeEditDialog(ref="ItemTypeEditDialog" :postUrl="$config.urls.postEditItemType" :deleteUrl="$config.urls.deleteItemType" @updated="itemUpdated" @deleted="itemUpdated")

</template>

<script>
import ItemTypeEditDialog from "@/components/management/item/ItemTypeEdit";
import ItemTypeTable from "@/components/common/PagingListTable";
import ItemTypeFilterConditions from "@/components/management/item/ItemTypeFilterConditions";

export default {
  components: {
    ItemTypeEditDialog,
    ItemTypeTable,
    ItemTypeFilterConditions
  },
  data() {
    return {};
  },
  computed: {
    getListActionName: {
      get() {
        return "itemType/fetchAllItemType";
      }
    },
    iItemTypeList: {
      get() {
        return this.$store.state.itemType.itemList;
      }
    },
    itemTypeTableHeader: {
      get() {
        return [{ text: "積荷種別", value: "name", centerAlign: false }];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openAddDialog() {
      this.$refs.ItemTypeEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.ItemTypeEditDialog.openDialog(item);
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.ItemTypeTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     * @param response
     * @param data
     */
    itemUpdated(response, data) {
      this.$refs.ItemTypeTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.ItemTypeTable.setSearchConditions(searchCond);
      this.$refs.ItemTypeTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>
