<template lang="pug">
  span
    span {{ displayPrefix }}
    span {{ value | addComma}}
    span {{postfix}}
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    prefix: {
      type: String,
      default: "￥"
    },
    postfix: {
      type: String,
      default: ""
    }
  },
  computed: {
    displayPrefix: {
      get() {
        return this.value || this.value === 0 ? this.prefix : "";
      }
    }
  }
};
</script>
