<template lang="pug">
  HistoryDialog(ref="HistoryDialog" title="変更履歴"
    :leftData="billingHistoryList" :oldUpdateUser="oldUpdateUser" :newUpdateUser="newUpdateUser" @incrementPageCount="incrementHistoryTablePageCount"
    @selectLine="selectLine" @terminate="terminate" load-url="finance/fetchBillingHistoryList" :targetId="targetId")
    template(v-slot:rightTop)
      BillingCardHistoryTable(:diffArrayData="newRows" :billingBasicPrice="newBillingBasicPrice")

    template(v-slot:rightBottom)
      BillingCardHistoryTable(:diffArrayData="oldRows" :billingBasicPrice="oldBillingBasicPrice")

</template>

<script>
import HistoryDialog from "@/components/common/dialog/HistoryDialog";
import BillingCardHistoryTable from "@/components/finance/BillingCardHistoryTable";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    HistoryDialog,
    BillingCardHistoryTable
  },
  data() {
    return {
      targetId: null
    };
  },
  computed: {
    ...mapGetters({
      billingHistoryList: "finance/billingCardHistoryList",
      billingHistoryDiff: "finance/billingCardHistoryDiff"
    }),
    newRows: {
      get() {
        return this.billingHistoryDiff != null ? this.billingHistoryDiff.newRows : null;
      }
    },
    oldRows: {
      get() {
        return this.billingHistoryDiff != null ? this.billingHistoryDiff.oldRows : null;
      }
    },
    newBillingBasicPrice: {
      get() {
        return this.billingHistoryDiff != null ? this.billingHistoryDiff.newBillingBasicPrice : null;
      }
    },
    oldBillingBasicPrice: {
      get() {
        return this.billingHistoryDiff != null ? this.billingHistoryDiff.oldBillingBasicPrice : null;
      }
    },
    oldUpdateUser: {
      get() {
        return this.billingHistoryDiff != null ? this.billingHistoryDiff.oldUpdateUser : null;
      }
    },
    newUpdateUser: {
      get() {
        return this.billingHistoryDiff != null ? this.billingHistoryDiff.newUpdateUser : null;
      }
    }
  },
  methods: {
    ...mapMutations({
      incrementHistoryTablePageCount: "finance/incrementBillingHistoryTablePageCount",
      clearHistoryTablePageCount: "finance/clearBillingHistoryTablePageCount"
    }),
    ...mapActions("finance", {
      fetchBillingHistoryList: "fetchBillingHistoryList",
      fetchBillingDiff: "fetchBillingDiff",
      clearHistoryData: "clearBillingHistoryData"
    }),
    openDialog(cardId) {
      this.clearHistoryTablePageCount();
      this.fetchBillingHistoryList(cardId).then(value => {
        this.$refs.HistoryDialog.initialize();
      });
      this.$refs.HistoryDialog.openDialog();
    },
    closeDialog() {
      this.$refs.HistoryDialog.closeDialog();
    },
    selectLine(data) {
      this.fetchBillingDiff(data.id);
    },
    terminate() {
      this.clearHistoryData();
    }
  }
};
</script>
