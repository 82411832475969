<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md4)
          v-text-field(label="1次配送委託先" v-model="conditions.primaryDeliveryCompanyName")
        v-flex(md4)
          v-text-field(label="支払タリフ区分" v-model="conditions.paymentTariffTypeName")
        v-flex(md4)
          v-text-field(label="支払タリフCD" v-model="conditions.paymentTariffCd")
        v-flex(md2)
          DatePicker(pickerLabel="適用開始日(開始)" :initDate="conditions.applicableStartDateMin" :inputDate.sync="conditions.applicableStartDateMin" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="適用開始日(終了)" :initDate="conditions.applicableStartDateMax" :inputDate.sync="conditions.applicableStartDateMax" :isClearable="true")
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import StatusSelect from "@/components/common/select/StatusSelect";

export default {
  components: {
    SearchConditions,
    DatePicker,
    StatusSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          primaryDeliveryCompanyName: "",
          paymentTariffTypeName: "",
          paymentTariffCd: "",
          applicableStartDateMin: "",
          applicableStartDateMax: "",
          status: null
        }
      };
    }
  }
};
</script>
