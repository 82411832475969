<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="配送委託先会社コード" v-model="conditions.companyCd")
        v-flex(md2)
          v-text-field(label="配送委託先会社名" v-model="conditions.companyName")
        v-flex(md1)
          v-text-field(label="郵便番号" v-model="conditions.zipCode")
        v-flex(md2)
          v-text-field(label="住所" v-model="conditions.headOfficeAddress")
        v-flex(md2)
          v-text-field(label="担当者(輸送依頼書)" v-model="conditions.transportStaff")
        v-flex(md2)
          v-text-field(label="電話番号(輸送依頼書)" v-model="conditions.transportTel")
        v-flex(md2)
          v-text-field(label="FAX(輸送依頼書)" v-model="conditions.transportFax")
        v-flex(md2)
          v-text-field(label="担当者(支払)" v-model="conditions.paymentStaff")

        v-flex(md1)
          ContractExistsSelect(:code.sync="conditions.contractSigned" :clearable="true")
        v-flex(md2)
          DatePicker(:inputDate.sync="conditions.contractDateMin" pickerLabel="契約年月日(開始)" initDate="" :isClearable="true")
        v-flex(md2)
          DatePicker(:inputDate.sync="conditions.contractDateMax" pickerLabel="契約年月日(終了)" initDate="" :isClearable="true")
        v-flex(md2)
          v-text-field(label="備考" v-model="conditions.remark")
        v-flex(md1)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
        v-flex(md2)
          FaxTargetSelect(labelDisp="輸送依頼書FAX送信対象" :code.sync="conditions.transportFaxTarget" :clearable="true")
        v-flex(md2)
          FaxTargetSelect(labelDisp="支払書FAX送信対象" :code.sync="conditions.paymentStatementFaxTarget" :clearable="true")
        v-flex(md2)
          v-text-field(label="資本金(下限)" v-model="conditions.capitalFrom" suffix="万円")
        v-flex(md2)
          v-text-field(label="資本金(上限)" v-model="conditions.capitalTo" suffix="万円")
        v-flex(md1)
          CheckOnOffSelect(label-disp="資本金登録" :code.sync="conditions.capitalBlank" :label-dic="capitalRegisterStatus" clearable )
        v-flex(md2)
          CheckOnOffSelect(label-disp="下請法該当フラグ" :code.sync="conditions.subcontractingLawFlg" clearable)
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import ContractExistsSelect from "@/components/common/select/ContractExistsSelect";
import DatePicker from "@/components/common/DatePicker";
import StatusSelect from "@/components/common/select/StatusSelect";
import FaxTargetSelect from "@/components/common/select/FaxTargetSelect";
import CheckOnOffSelect from "@/components/common/select/CheckOnOffSelect.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CheckOnOffSelect,
    SearchConditions,
    ContractExistsSelect,
    DatePicker,
    StatusSelect,
    FaxTargetSelect
  },
  computed: {
    ...mapGetters("site", ["capitalRegisterStatus"])
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          companyCd: "",
          companyName: "",
          headOfficeAddress: "",
          transportTel: "",
          transportFax: "",
          transportStaff: "",
          pasymentStaff: "",
          contractSigned: "",
          contractDateMin: "",
          contractDateMax: "",
          remark: "",
          transportFaxTarget: "",
          paymentStatementFaxTarget: "",
          status: ""
        }
      };
    }
  }
};
</script>
