<template lang="pug">
  EditDialog(ref="EditDialog" title="荷主枝番" :recordId="record.id"
    :loadingSave="loading.save" :loadingDelete="loading.delete"
    @delete="erase" @save="save" @closeEditDialog="clear")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md6)
            ClientSelect(:label.sync="record.client.clientDisplayName" :setClient="true" @changeItem="changeClient" :code.sync="record.client.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'client.id'")

          v-flex(xs12 md6)
            v-text-field(v-model="record.branchName" label="荷主枝番名称" :error-messages="getErrorMessages('branchName')")

          v-flex(xs12 md6)
            ClientLoadingBaseClassSelect(:label.sync="record.clientLoadingBaseClass.baseClassName"
              :code.sync="record.clientLoadingBaseClass.id"
              :clientId="record.client.id"
              :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'clientLoadingBaseClass.id'")

          v-flex(xs12 md6)
            ClientUnloadingBaseClassSelect(:label.sync="record.clientUnloadingBaseClass.baseClassName"
              :code.sync="record.clientUnloadingBaseClass.id"
              :clientId="record.client.id"
              :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'clientUnloadingBaseClass.id'")

          v-flex(xs12)
            v-card
              v-card-text
                v-layout(row wrap)
                  v-flex(xs12 md12)
                    v-checkbox(v-model="record.equalClient" label="荷主と同一" hide-details)
                  v-flex(xs12 md12)
                    span {{ record.client.zipCode ? "郵便番号: " + record.client.zipCode : "郵便番号" }}
                  v-flex(xs12 md12)
                    span.mr-3 {{ record.client.prefecture && record.client.prefecture.name ? "都道府県: " + record.client.prefecture.name : "都道府県: "}}
                    span {{ record.client.address ? "住所: " + record.client.address : "住所: " }}
                v-layout(v-if="!record.equalClient" row wrap)
                  v-flex(xs12 md2)
                    v-text-field(
                      :readonly="record.equalClient"
                      :box="record.equalClient"
                      v-model="record.zipCode"
                      label="郵便番号"
                      :error-messages="getErrorMessages('zipCode')")

                  v-flex(xs12 md2)
                    PrefSelect(:readonly="record.equalClient" :label.sync="record.prefecture.name" :code.sync="record.prefecture.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'prefecture.id'")
                  v-flex(xs12 md8)
                    v-text-field(
                      :readonly="record.equalClient"
                      :box="record.equalClient"
                      v-model="record.address"
                      label="住所"
                      :error-messages="getErrorMessages('address')")

                v-layout(row wrap)
                  v-flex(xs12 md6)
                    span {{ record.client.tel ? '電話番号: ' + record.client.tel : "電話番号: " }}
                  v-flex(xs12 md6)
                    span {{ record.client.fax ? 'FAX: ' + record.client.fax : "FAX: " }}
                  v-flex(xs12 md6)
                    v-text-field(
                      v-if="!record.equalClient"
                      :readonly="record.equalClient"
                      :box="record.equalClient"
                      v-model="record.tel"
                      label="電話番号"
                      :error-messages="getErrorMessages('tel')")

                  v-flex(xs12 md6)
                    v-text-field(
                      v-if="!record.equalClient"
                      :readonly="record.equalClient"
                      :box="record.equalClient"
                      v-model="record.fax"
                      label="FAX"
                      :error-messages="getErrorMessages('fax')")

                v-layout(row wrap)
                  v-flex(xs12 md6)
                    span {{ record.client.mail ? 'メール: ' + record.client.mail : "メール: " }}
                  v-flex(xs12 md6)
                    span {{ record.client.staff ? '担当者: ' + record.client.staff : "担当者: " }}
                  v-flex(xs12 md6)
                    v-text-field(
                      v-if="!record.equalClient"
                      :readonly="record.equalClient"
                      :box="record.equalClient"
                      v-model="record.mail"
                      label="メール"
                      :error-messages="getErrorMessages('mail')")

                  v-flex(xs12 md6)
                    v-text-field(
                      v-if="!record.equalClient"
                      :readonly="record.equalClient"
                      :box="record.equalClient"
                      v-model="record.staff"
                      label="担当者"
                      :error-messages="getErrorMessages('staff')")

                v-layout(row wrap)
                  v-flex(xs12 md12)
                    span {{ record.client.billingStatementFaxTarget ? '請求書FAX送信対象: ' + getFaxTarget(record.client.billingStatementFaxTarget) : "" }}
                  v-flex(v-if="!record.equalClient" xs12 md12)
                    FaxTargetSelect(labelDisp="請求書FAX送信対象" :readonly="record.equalClient" :code.sync="record.billingStatementFaxTarget" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.dispatchTel"
              label="電話番号（配車）"
              :error-messages="getErrorMessages('dispatchTel')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.dispatchFax"
              label="FAX（配車）"
              :error-messages="getErrorMessages('dispatchFax')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.dispatchMail"
              label="メール（配車）"
              :error-messages="getErrorMessages('dispatchMail')")

          v-flex(xs12 md6)
            v-text-field(
              v-model="record.dispatchStaff"
              label="担当者（配車）"
              :error-messages="getErrorMessages('dipsatchStaff')")

          v-flex(xs12 md6)
            FaxTargetSelect(
              labelDisp="車番連絡表FAX送信対象" :code.sync="record.serviceContactFaxTarget" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md6)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12)
            v-textarea(label="備考" auto-grow v-model="record.remark" :error-messages="getErrorMessages('remark')")

</template>

<script>
import { mapGetters } from "vuex";
import ClientSelect from "../../common/select/ClientSelect";
import ClientLoadingBaseClassSelect from "../../common/select/ClientLoadingBaseClassSelect";
import ClientUnloadingBaseClassSelect from "../../common/select/ClientUnloadingBaseClassSelect";
import StatusSelect from "../../common/select/StatusSelect";
import EditDialog from "@/components/common/dialog/EditDialog";
import PrefSelect from "../../common/select/PrefSelect";
import FaxTargetSelect from "@/components/common/select/FaxTargetSelect";

import ApiHandler from "../../../mixin/ApiHandler";
import EditForm from "../../../mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    ClientSelect,
    ClientLoadingBaseClassSelect,
    ClientUnloadingBaseClassSelect,
    StatusSelect,
    EditDialog,
    PrefSelect,
    FaxTargetSelect
  },
  mixins: [ApiHandler, EditForm],
  watch: {
    "record.client.id": function (newVal, oldVal) {
      if (oldVal) {
        this.record.clientLoadingBaseClass = this.initData().clientLoadingBaseClass;
        this.record.clientUnloadingBaseClass = this.initData().clientUnloadingBaseClass;
      }
    }
  },
  data() {
    return {
      record: {}
    };
  },
  computed: {
    ...mapGetters({
      getFaxTarget: "site/faxTargetLabel"
    })
  },
  created() {
    this.clear();
  },
  methods: {
    initData() {
      return {
        id: null,
        client: {
          id: null,
          clientName: null,
          zipcode: null,
          prefecture: {
            id: null,
            name: null
          },
          address: null,
          tel: null,
          fax: null,
          mail: null,
          staff: null
        },
        clientLoadingBaseClass: {
          id: null,
          baseClassName: null
        },
        clientUnloadingBaseClass: {
          id: null,
          baseClassName: null
        },
        branchName: null,
        branchType: null,
        zipcode: null,
        prefecture: {
          id: null,
          name: null
        },
        address: null,
        staff: null,
        tel: null,
        fax: null,
        mail: null,
        serviceContactFaxTarget: Enum.FaxTargetFlag.Exclusion.code,
        billingStatementFaxTarget: Enum.FaxTargetFlag.Unset.code,
        dispatchStaff: null,
        dispatchTel: null,
        dispatchFax: null,
        dispatchMail: null,
        status: Enum.ActiveFlag.Active.code,
        remark: null,
        equalClient: null
      };
    },
    changeClient(data) {
      this.record.client = data;
    },
    openDialog(item) {
      this.clear();
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    clear() {
      this.record = this.initData();
    },
    save() {
      const self = this;
      this.record.branchType = "20"; //手入力で固定
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
