<template lang="pug">
  v-content
    v-container(fluid grid-list-xs v-show="hasDisaptchList")
      v-layout(row wrap)
        v-flex(xs6 md6)
          v-text-field.body-2(readonly v-if="conditions.dispatchList" v-model="conditions.dispatchList.loadingDate" label="運行日")
        v-flex(xs6 md6)
          v-text-field.body-2(readonly v-if="conditions.dispatchList" v-model="conditions.dispatchList.driverName" label="乗務員名")
      v-layout(row wrap)
        v-flex(xs12 md12)
          v-textarea.body-2(readonly v-if="conditions.dispatchList" v-model="conditions.dispatchList.driverRemark" label="乗務員備考")
      v-layout(row wrap v-if="query.mailType === '20'")
        v-flex(xs12 md12)
          v-card()
            v-card-text
              v-layout(row wrap align-center)
                v-flex(xs12)
                  div(font-weight-thin grey--text text--darken-1) 車両情報
              v-layout(row wrap align-center)
                v-flex(xs2)
                v-flex(xs10)
                  div.pl-3.text-whitespace-pre {{ getFirstVehicle()}}

          template(v-for="item, index in dispatchList")
            v-layout(row wrap v-if="isChangeVehicle(index)").py-2
              v-flex(xs12)
                v-card()
                  v-card-text
                    v-layout(row wrap align-center)
                      v-flex(xs12)
                        div(font-weight-thin grey--text text--darken-1).vehicle-change-alert
                          v-alert(value="true" type="warning" outline) 車両変更
                    v-layout(row wrap align-center)
                      v-flex(xs2)
                      v-flex(xs10)
                        div.pl-3.text-whitespace-pre {{ getVehicleValue(item.vehicle)}}
            v-layout(row wrap).py-2
              v-flex(xs12)
                v-card()
                  v-layout(row wrap)
                    v-flex(xs6).py-2
                      div.th-text.text-xs-right {{ getCardOrderValue(item) }}
                    v-flex(xs6).py-2.pl-1
                      div.icon-night-loading-specified(v-if="item.isNightLoading")
                        NightLoadingIcon

                v-card()
                  v-card-text
                    v-layout(row wrap align-center yellow lighten-3)
                      v-flex(xs2).text-size-11
                        div.text-xs-center() 積地
                      v-flex(xs10)
                        template(v-for="base in item.loadingBaseList")
                          div.overflow-auto {{ getLoadingBase(base) }}
                          div(v-if="base.shipmentNoList" v-for="shipmentNo in base.shipmentNoList")
                            div.text-xs-left {{prefixMessage + shipmentNo}}
                          BaseLiftAndOptionInfo.ml-4(:item="base")
                v-card()
                  v-card-text
                    v-layout(row wrap align-center green lighten-3)
                      v-flex(xs2).text-size-11
                        div.text-xs-center 卸地
                      v-flex(xs10)
                        template(v-for="base in item.unloadingBaseList")
                          div.overflow-auto {{ getUnloadingBase(base)}}
                          BaseLiftAndOptionInfo.ml-4(:item="base")

                v-card(v-if="item.remark")
                  v-card-text
                    v-layout(row wrap align-center)
                      v-flex(xs2).text-size-11
                        div.text-xs-center 備考
                      v-flex(xs10)
                        div.whitespace-pre-line {{ item.remark}}
            div.arrow-icon(v-if="index !== (dispatchList.length - 1)")
              v-icon(flat) arrow_downward

      v-layout(row wrap v-if="query.mailType === '10'")
        v-flex(xs12 md12)
          v-card()
            v-card-text
              v-layout(row wrap align-center)
                v-flex(xs12)
                  div(font-weight-thin grey--text text--darken-1) 車両情報
              v-layout(row wrap align-center)
                v-flex(xs2)
                v-flex(xs10)
                  div.pl-3.text-whitespace-pre {{ getVehicleValue(dispatchList.vehicle)}}

          v-layout(row wrap).py-2
            v-flex(xs12)
              v-card()
                v-layout(row wrap)
                  v-flex(xs6).py-2
                    div.th-text.text-xs-right {{ getCardOrderValue(dispatchList) }}
                  v-flex(xs6).py-2.pl-1
                    div.icon-night-loading-specified(v-if="dispatchList.isNightLoading")
                      NightLoadingIcon


              v-card()
                v-card-text
                  v-layout(row wrap align-center yellow lighten-3)
                    v-flex(xs2).text-size-11
                      div.text-xs-center() 積地
                    v-flex(xs10)
                      template(v-for="base in dispatchList.loadingBaseList")
                        div.overflow-auto {{ getLoadingBase(base) }}
                        div(v-if="base.shipmentNoList" v-for="shipmentNo in base.shipmentNoList")
                          div.text-xs-left {{prefixMessage + shipmentNo}}
                        BaseLiftAndOptionInfo.ml-4(:item="base")
              v-card()
                v-card-text
                  v-layout(row wrap align-center green lighten-3)
                    v-flex(xs2).text-size-11
                      div.text-xs-center 卸地
                    v-flex(xs10)
                      template(v-for="base in dispatchList.unloadingBaseList")
                        div.overflow-auto {{ getUnloadingBase(base)}}
                        BaseLiftAndOptionInfo.ml-4(:item="base")

              v-card(v-if="dispatchList.remark")
                v-card-text
                  v-layout(row wrap align-center)
                    v-flex(xs2).text-size-11
                      div.text-xs-center 備考
                    v-flex(xs10)
                      div.whitespace-pre-line {{ dispatchList.remark}}

      v-layout(row wrap py-4)
        v-flex(xs12 md12 v-if="isFirstDispatch" )
            div(class="th-text") ※第１運行のみのお知らせです。全運行は追ってメール差し上げます。

        v-flex(xs12 md12 v-if="!isConfirmed" )
            div(class="th-text") ※運行確認後、以下の「確認完了」ボタンを押してください。
        v-flex(xs2 offset-xs4 py-4 v-if="!isConfirmed" )
          v-btn(@click="statusConfirmed" color="primary") 確認完了

    ConfirmDialog(v-if="isFirstDispatch" ref="ConfirmDialog" :isFirstDispatch="true")
    ConfirmDialog(v-if="!isFirstDispatch" ref="ConfirmDialog" :isFirstDispatch="false")
    AlertBox(ref="errorMsg")

</template>

<script>
import ConfirmDialog from "@/components/dispatch/ConfirmResultDialog";
import Axios from "@/assets/js/axios";
import AlertBox from "@/components/common/AlertBox";
import BaseLiftAndOptionInfo from "@/components/dispatch/BaseLiftAndOptionInfo";
import NightLoadingIcon from "@/components/dispatch/NightLoadingIcon";

let $http = Axios.http;
export default {
  components: {
    ConfirmDialog,
    AlertBox,
    BaseLiftAndOptionInfo,
    NightLoadingIcon
  },
  data() {
    return {
      conditions: {
        dispatchList: ""
      },
      query: {
        driverId: "",
        token: "",
        mailType: ""
      },
      prefixMessage: "　シップメントNo : "
    };
  },
  computed: {
    hasDisaptchList: {
      get() {
        return this.conditions.dispatchList === "" || this.conditions.dispatchList === undefined
          ? false
          : true;
      }
    },
    isFirstDispatch: {
      get() {
        if (this.$route.query.type === "10") return true;
        else return false;
      }
    },
    isConfirmed: {
      get() {
        if (this.conditions.dispatchList) return this.conditions.dispatchList.isConfirmed;
        return false;
      }
    },
    dispatchList: {
      get() {
        if (this.$route.query.type === "20") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.conditions.dispatchList = this.$store.state.dispatch.driverDispatchList[0];
        } else if (this.$store.state.dispatch.driverDispatchList.length !== 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.conditions.dispatchList = this.$store.state.dispatch.driverDispatchList;
        }
        return this.$store.state.dispatch.driverDispatchList;
      }
    }
  },
  methods: {
    /**
     * type: 10 第一運行
     *       20 全運行
     */
    loadListData() {
      this.getQuery();
      if (this.$route.query.type === "10") {
        this.$store.dispatch("dispatch/fetchFirstDispatch", this.query).catch(error => {
          this.openErrorMsgBox(error.response.data.message);
        });
      } else if (this.$route.query.type === "20") {
        this.$store.dispatch("dispatch/fetchAllDispatch", this.query).catch(error => {
          this.openErrorMsgBox(error.response.data.message);
        });
      }
    },
    openErrorMsgBox(message) {
      this.$refs.errorMsg.displayMessage(message);
    },
    statusConfirmed() {
      $http.post(this.$config.urls.postSaveDispatchMailConfirm, this.query).then(response => {
        this.conditions.dispatchList.isConfirmed = true;
        this.openConfirmDialog();
      });
    },
    openConfirmDialog() {
      this.$refs.ConfirmDialog.openDialog();
    },
    getQuery() {
      this.query.driverId = this.$route.query.driver;
      this.query.token = this.$route.query.token;
      this.query.mailType = this.$route.query.type;
    },
    getUnloadingBase(base) {
      let specifiedTime = "";
      if (base.specifiedTime) {
        let from = base.specifiedTime.from ? base.specifiedTime.from : "00:00";
        let to = base.specifiedTime.to ? base.specifiedTime.to : "24:00";
        specifiedTime = " (" + from + "-" + to + ")";
      }
      return base.baseName + specifiedTime;
    },
    getLoadingBase(base) {
      let specifiedTime = "";
      if (base.specifiedTimeFrom || base.specifiedTimeTo) {
        let from = base.specifiedTimeFrom ? base.specifiedTimeFrom : "00:00";
        let to = base.specifiedTimeTo ? base.specifiedTimeTo : "24:00";
        specifiedTime = " (" + from + "-" + to + ")";
      }

      return base.baseName + specifiedTime;
    },
    getCardOrderValue(value) {
      return value.isDayExceed ? "日跨ぎ" : value.cardOrder;
    },
    getVehicleValue(vehicle) {
      if (vehicle === undefined) return "";
      return vehicle.vehicleTypeName + "(" + vehicle.entryNumber + ")\r\n";
    },
    isChangeVehicle(index) {
      if (index === 0) return false;
      let data = this.dispatchList[index];
      let beforeData = this.dispatchList[index - 1];
      return data.vehicle.vehicleId !== beforeData.vehicle.vehicleId;
    },
    getFirstVehicle() {
      const data = this.dispatchList[0];
      if (data === undefined) return "";
      return data.vehicle.vehicleTypeName + "(" + data.vehicle.entryNumber + ")\r\n";
    }
  },
  mounted() {
    this.loadListData();
  }
};
</script>

<style lang="stylus" scoped>
table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

row-border {
  border: solid #dcdcdc;
  border-width: 1px 0px;
}

.text-size-9 {
  font-size: 9px;
}

.text-size-11 {
  font-size: 11px;
}

.overflow-auto {
  overflow: auto;
}

.icon-night-loading-specified {
  $iconSize = 18px;
  display: inline-block;
  width: $iconSize;
  height: $iconSize;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  background-color: purple;

  .v-icon {
    font-size: 15px;
    color: white;
  }
}

.arrow-icon {
  width: 32px;
  height: 32px;
  margin: 8px auto 0;
}
</style>
