<template lang="pug">
  v-layout(row wrap)
    v-flex(xs12)
      v-card
        v-card-title
          v-layout(row wrap)
            v-spacer
            div
              ReloadButton(@callReload="clickReloadButtonForSchedule")
            v-flex(xs6 md3)
              MonthPicker(
                :inputDate="selectYearMonth"
                v-on:update:inputDate="selectMonth"
                pickerLabel="表示起点年月"
              )
            v-flex(xs12 md3)
              DriverSelect(:code.sync="searchConditions.driverId"
                :label.sync="selectedDriver.driverName"
                :actionName="'driver/fetchAllDriverForSchedule'"
                @changeItem="selectDriver")

        v-card-text
          v-container(v-show="this.canDrawCalendar")
            v-layout(row wrap)
              v-flex(xs12 md10 offset-md1)
                v-card
                  v-card-text
                    v-layout(row no-wrap align-start fill-height)
                      v-flex(xs6)
                        v-layout(
                          align-center
                          justify-left
                          row
                          fill-height
                          wrap
                        )
                          v-flex(xs2) {{ getCurrentSelectYearMonth }}
                          v-flex(xs3)
                            SelectApplyShift(@click="shiftBulkChangeConfirm($event, driverMonthlySchedule, 0)")

                          v-flex(xs12)
                            CurrentMonthlyCalendar(
                              :driverMonthlySchedule="driverMonthlySchedule"
                              @save="save($event, searchConditions.driverId)"
                            )
                      v-flex(xs6)
                        v-layout(
                          align-center
                          justify-left
                          row
                          fill-height
                          wrap
                        )
                          v-flex(xs2) {{ getNextSelectYearMonth }}
                          v-flex(xs3)
                            SelectApplyShift(@click="shiftBulkChangeConfirm($event, driverNextMonthlySchedule, 1)")
                          v-flex(xs12)
                            CurrentMonthlyCalendar(
                              :driverMonthlySchedule="driverNextMonthlySchedule"
                              @save="save($event, searchConditions.driverId)"
                            )
    EmergencyErrorDialog(ref="errorMsg")
    ShiftBulkChangeConfirmDialog(
      ref="ShiftBulkChangeConfirmDialog"
      :driverName="shiftBulkChangeConditions.driverName"
      :shiftType="this.getShiftType(shiftBulkChangeConditions.shiftType)"
      :yearMonth="shiftBulkChangeConditions.yearMonth"
      @yes="bulkSave()"
      )

</template>

<script>
import { mapGetters } from "vuex";

import MonthPicker from "@/components/common/MonthPicker";
import DriverSelect from "@/components/common/select/DriverSelect";

import CurrentMonthlyCalendar from "@/components/management/driver/schedule/monthly-calendar/MonthlyShiftCalendar";
import SelectApplyShift from "@/components/management/driver/schedule/monthly-calendar/SelectApplyShift";
import ShiftBulkChangeConfirmDialog from "@/components/management/driver/schedule/monthly-calendar/ShiftBulkChangeConfirmDialog";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import ReloadButton from "@/components/common/buttons/ReloadButton";

import ApiHandler from "@/mixin/ApiHandler";

import _ from "lodash";

export default {
  components: {
    MonthPicker,
    DriverSelect,
    CurrentMonthlyCalendar,
    SelectApplyShift,
    ShiftBulkChangeConfirmDialog,
    EmergencyErrorDialog,
    ReloadButton
  },
  mixins: [ApiHandler],
  data() {
    return {
      selectedDriver: {
        driverName: null
      },
      selectYearMonth: "",
      searchConditions: {
        driverId: null,
        year: null,
        month: null
      },
      shiftBulkChangeConditions: {
        driverId: null,
        driverName: null,
        shiftType: null,
        yearMonth: null,
        driverMonthlySchedule: null
      },
      driverMonthlySchedule: [],
      driverNextMonthlySchedule: []
    };
  },
  methods: {
    selectDriver(data) {
      this.selectedDriver.driverName = data.name;
      this.searchConditions.driverId = data.id;
      this.loadListData();
    },
    selectMonth(date) {
      this.selectYearMonth = date;
      let [year, month] = date.split("-");
      this.searchConditions.year = year;
      this.searchConditions.month = month;
      this.loadListData();
    },
    /**
     * APIからカレンダーデータを取得
     * */
    async loadListData() {
      if (this.canDrawCalendar) {
        await this.$store.dispatch("driver/fetchSchedule", {
          driverId: this.searchConditions.driverId,
          year: this.searchConditions.year,
          month: this.searchConditions.month
        });
        this.driverMonthlySchedule = _.cloneDeep(this.$store.state.driver.driverScheduleList.currentMonth);
        this.driverNextMonthlySchedule = _.cloneDeep(this.$store.state.driver.driverScheduleList.nextMonth);
      }
    },
    /**
     * 保存処理
     */
    save(record, driverId) {
      var recordList = [];
      record.driverId = driverId;
      recordList.push(record);

      var self = this;
      this.saveRecord(recordList).then(response => {
        self.loadListData();
      });
    },
    /**
     * 一括変更確認ダイアログの表示
     */
    shiftBulkChangeConfirm(shiftType, driverMonthlySchedule, addMonth) {
      const targetMonth = new Date(this.selectYearMonth + "-01");
      targetMonth.setMonth(targetMonth.getMonth() + addMonth);
      const [year, month] = window.Common.Util.getYearMonthIsoFormat(targetMonth).split("-");

      this.shiftBulkChangeConditions.driverId = this.searchConditions.driverId;
      this.shiftBulkChangeConditions.driverName = this.selectedDriver.driverName;
      this.shiftBulkChangeConditions.shiftType = shiftType;
      this.shiftBulkChangeConditions.yearMonth = year + "年" + month + "月";
      this.shiftBulkChangeConditions.driverMonthlySchedule = driverMonthlySchedule;
      this.$refs.ShiftBulkChangeConfirmDialog.openDialog();
    },
    /**
     * 一括保存処理
     */
    bulkSave() {
      var recordList = [];

      var data = this.shiftBulkChangeConditions.driverMonthlySchedule;
      for (var week in data) {
        for (var day in data[week]) {
          if (data[week][day].targetMonth && !data[week][day].holiday) {
            this.$set(data[week][day], "shiftType", this.shiftBulkChangeConditions.shiftType);
          }
          this.$set(data[week][day], "driverId", this.shiftBulkChangeConditions.driverId);
          recordList.push(data[week][day]);
        }
      }

      var self = this;
      this.saveRecord(recordList).then(response => {
        self.loadListData();
      });
    },
    openErrorMsgBox(message) {
      this.$refs.errorMsg.open(message);
      this.loadListData();
    },
    /**
     * リロードボタン押下
     */
    clickReloadButtonForSchedule() {
      this.loadListData();
    }
  },
  computed: {
    ...mapGetters({
      getShiftType: "site/shiftTypeFromCd"
    }),
    getCurrentSelectYearMonth() {
      var [year, month] = this.selectYearMonth.split("-");
      return `${year}年${month}月`;
    },
    getNextSelectYearMonth() {
      var date = new Date(this.selectYearMonth + "-01");
      date.setMonth(date.getMonth() + 1);
      const [year, month] = window.Common.Util.getYearMonthIsoFormat(date).split("-");

      return `${year}年${month}月`;
    },
    canDrawCalendar() {
      return this.searchConditions.driverId && this.searchConditions.year && this.searchConditions.month;
    }
  },
  mounted() {
    this.loadListData();
  }
};
</script>
