<template lang="pug">
  EditDialog(ref="EditDialog" title="DriverName" @save="save")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            DriverNameSelect(:label.sync="record.driverName" :code.sync="record.driverName" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'driverName'" :totalGrossWeight="record.totalGrossWeight")
</template>

<script>
import DriverNameSelect from "@/components/common/select/DriverNameSelect";
import EditDialog from "@/components/common/dialog/EditDialog";
import ApiHandler from "../../mixin/ApiHandler";
import EditForm from "../../mixin/EditForm";

export default {
  components: {
    DriverNameSelect,
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {
        driverName: "",
        routeNo: "",
        routeSeq: "",
        planBoardNo: "",
        totalGrossWeight: 0
      }
    };
  },
  methods: {
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.record.token = this.$store.getters["order/getToken"];
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
