/**
 * ApiHandler.js に依存
 */
import _ from "lodash";

export default {
  data() {
    return {
      controls: {
        dialog: false
      }
    };
  },
  methods: {
    openDialog(item) {
      this.initForm(item);
      this.controls.dialog = true;
    },
    closeDialog() {
      this.controls.dialog = false;
    },
    mergeEditFormData(dataObject) {
      const self = this;
      if (dataObject == null) return;

      Object.keys(dataObject).forEach(key => {
        if (dataObject[key] instanceof Object) {
          if (dataObject[key] instanceof Array) {
            if (this.initData !== undefined && this.initData instanceof Function) {
              this.$set(dataObject, key, this.initData()[key] !== undefined ? this.initData()[key] : null);
            } else {
              dataObject[key].splice(0, dataObject[key].length);
              self.mergeEditFormData(dataObject[key]);
            }
          } else {
            if (this.initData !== undefined && this.initData instanceof Function) {
              this.$set(dataObject, key, this.initData()[key] !== undefined ? this.initData()[key] : null);
            }
          }
        } else {
          if (this.initData !== undefined && this.initData instanceof Function) {
            this.$set(dataObject, key, this.initData()[key] !== undefined ? this.initData()[key] : null);
          } else {
            this.$set(dataObject, key, null);
          }
        }
      });
    },
    initForm(values) {
      this.mergeEditFormData(this.record);
      if (values !== undefined) {
        this.record = _.cloneDeep(values);
      }

      this.errors = {};
    },
    postSave() {
      this.closeDialog();
    },
    postDelete() {
      this.closeDialog();
    },
    openDeleteConfirmDialog() {
      this.$refs.DeleteConfirmDialog.openDialog();
    },
    openErrorMsgBox(message) {
      this.$refs.EditDialog.openErrorMsgBox(message);
    }
  }
};
