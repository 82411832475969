<template lang="pug">
  v-dialog(v-model="dialog" persistent width="95%" @keydown.esc="closeDialog" )
    v-card
      v-toolbar(dark :color="$store.getters['site/siteColor']")
        v-icon(left color="white darken-2" large) help
        span  {{ title }}
        v-spacer
        v-btn(flat icon @click="closeDialog")
          v-icon close

      v-card-text
        section()#dispatch-schedule
          v-layout(row wrap)
            v-flex(xs12)
              section.schedule-header-section
                //配車表テーブルヘッダー
                v-layout.schedule-header(row)
                  div.driver-box-header
                    span 乗務員情報

                  div.classified-order-card-row
                    div.classified-order-card-normal-area
                      div.driver-schedule-header(v-for="n in 30")
                        p {{ n }}
                    div.classified-order-card-day-exceed-area
                      div.driver-schedule-header
                        p (日跨ぎ用)
              section.schedule-body-section
                v-layout(row align-start justify-start).driver-schedule-row
                  DriverBox.driver-box(:driver="dispatchData" read-only
                    @openDriverDetail="openDriverDetailDialogInject"
                    @openRemarkEditDialog="openDriverRemarkEditDialogInject"
                    @toggleFavorite="toggleFavoriteInject"
                  )

                  div.classified-order-card-row
                    div.classified-order-card-normal-area
                      div.classified-order-card-normal-box
                        div.drag-between-box
                          div.content-box
                            DispatchFlowIcon(:item="firstCard")

                        div.driver-schedule-box
                          ClassifiedCard.content-box(:driverId="dispatchData.driverId" :item="firstCard" read-only
                            @openCardDetailDialog="openCardDetailDialogInject"
                            @openWildCardEditDialog="openWildCardEditDialogInject"
                            @openMarkMenu="openMarkMenuInject"
                            @openRemarkEditDialog="openRemarkEditDialogInject"
                          )

                      div.classified-order-card-normal-box(v-for="(card , index) in dispatchData.cardList")
                        div.drag-between-box.between
                          div.content-box
                            DispatchFlowIcon(:item="card")
                        div.driver-schedule-box
                          ClassifiedCard.content-box(:driverId="dispatchData.driverId" :item="card" read-only
                            @openCardDetailDialog="openCardDetailDialogInject"
                            @openWildCardEditDialog="openWildCardEditDialogInject"
                            @openMarkMenu="openMarkMenuInject"
                            @openRemarkEditDialog="openRemarkEditDialogInject"
                          )

                    div.classified-order-card-day-exceed-area
                      section.drag-between-box
                        div.content-box
                          DispatchFlowIcon(:item="dayExceedCard")

                      div.driver-schedule-box
                        ClassifiedCard.content-box(:driverId="dispatchData.driverId" :item="dayExceedCard" read-only
                          @openCardDetailDialog="openCardDetailDialogInject"
                          @openWildCardEditDialog="openWildCardEditDialogInject"
                          @openMarkMenu="openMarkMenuInject"
                          @openRemarkEditDialog="openRemarkEditDialogInject"
                        )

        v-layout
          v-flex
            div(class="ma-3") {{ message }}

      v-toolbar.footer
        v-spacer
        v-btn(color="" @click="no") {{ noButtonTitle }}
        v-btn(color="primary" @click="yes") {{ yesButtonTitle }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DriverBox from "@/components/dispatch/DriverBox";
import DispatchFlowIcon from "@/components/dispatch/DispatchFlowIcon";
import ClassifiedCard from "@/components/dispatch/ClassifiedCard";

export default {
  components: { ClassifiedCard, DispatchFlowIcon, DriverBox },
  props: {
    title: {
      type: String,
      required: false,
      default: "最終カード割当保存確認"
    },
    message: {
      type: String,
      required: false,
      default: "指定の乗務員に配車割付を行いますか？"
    },
    yesButtonTitle: {
      type: String,
      required: false,
      default: "保存"
    },
    noButtonTitle: {
      type: String,
      required: false,
      default: "キャンセル"
    }
  },
  inject: [
    "openDriverDetailDialogInject",
    "openDriverRemarkEditDialogInject",
    "toggleFavoriteInject",
    "openCardDetailDialogInject",
    "openWildCardEditDialogInject",
    "openMarkMenuInject",
    "openRemarkEditDialogInject",
    "removeSubscribeInject"
  ],
  data() {
    return {
      dialog: false,
      text: null
    };
  },
  computed: {
    ...mapGetters({
      getActiveDriversSchedule: "dispatchPattern/getSelectedDispatchDriverInfo"
    }),
    dispatchData: {
      get() {
        if (this.getActiveDriversSchedule.length < 1) {
          return {};
        }
        return this.getActiveDriversSchedule[0];
      }
    },
    firstCard: {
      get() {
        return this.dispatchData.firstCard !== undefined ? this.dispatchData.firstCard : {};
      }
    },
    dayExceedCard: {
      get() {
        return this.dispatchData.dayExceedCard !== undefined ? this.dispatchData.dayExceedCard : {};
      }
    }
  },
  methods: {
    ...mapActions("dispatchPattern", {
      removeSelectedDispatchDriverInfo: "removeSelectedDispatchDriverInfo"
    }),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.removeSubscribeInject();
      this.removeSelectedDispatchDriverInfo();
      this.$emit("close");
      this.dialog = false;
    },
    no() {
      this.$emit("no");
      this.closeDialog();
    },
    yes() {
      this.$emit("yes");
      this.closeDialog();
    }
  }
};
</script>

<style scoped lang="stylus">
#dispatch-schedule {
  .schedule-header {
    margin-left 0
  }
}
</style>
