export default {
  data() {
    return {
      bases: {
        loadingBaseList: [],
        unloadingBaseList: []
      }
    };
  },
  computed: {
    loadingBaseIdList: {
      get() {
        return this.bases.loadingBaseList
          .filter(loading => loading.baseId != null)
          .map(loading => loading.baseId);
      }
    },
    unloadingBaseIdList: {
      get() {
        return this.bases.unloadingBaseList
          .filter(unloading => unloading.baseId != null)
          .map(unloading => unloading.baseId);
      }
    },
    loadingBaseLimit: {
      get() {
        if (this.bases.loadingBaseList === undefined) return false;
        return this.bases.loadingBaseList.length >= 5;
      }
    },
    unloadingBaseLimit: {
      get() {
        if (this.bases.unloadingBaseList === undefined) return false;
        return this.bases.unloadingBaseList.length >= 5;
      }
    }
  },
  methods: {
    /**
     * 積地フォームの追加
     */
    addLoadingBase() {
      this.bases.loadingBaseList.push(this.loadingDefault());
    },
    /**
     * 積地フォームの追加
     */
    addUnloadingBase() {
      this.bases.unloadingBaseList.push(this.unloadingDefault());
    },
    /**
     * 積地フォームの削除
     */
    deleteLoadingBaseList(index) {
      this.bases.loadingBaseList.splice(index, 1);
    },
    /**
     * 卸地フォームの削除
     */
    deleteUnloadingBaseList(index) {
      this.bases.unloadingBaseList.splice(index, 1);
    },
    /**
     * 積地初期値
     */
    loadingDefault() {
      return {
        id: null,
        baseId: null,
        baseCd: null,
        baseName: null
      };
    },
    /**
     * 卸地初期値
     */
    unloadingDefault() {
      return {
        id: null,
        baseId: null,
        baseCd: null,
        baseName: null,
        specifiedTime: {
          from: null,
          to: null
        },
        unloadingOrder: 1
      };
    },
    selectLoadingBase(loading, data) {
      console.log(data);
      loading.baseCd = data.baseCd;
      loading.baseName = data.baseName;
    },
    selectUnloadingBase(unloading, data) {
      unloading.baseCd = data.baseCd;
      unloading.baseName = data.baseName;
    },
    clearLoadingBaseList() {
      this.bases.loadingBaseList = [];
      this.addLoadingBase();
    },
    clearUnloadingBaseList() {
      this.bases.unloadingBaseList = [];
      this.addUnloadingBase();
    },
    clearBases() {
      this.clearLoadingBaseList();
      this.clearUnloadingBaseList();
    }
  }
};
