<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      label="積地分類"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond"
      :getListActionName="'baseClass/fetchAllLoadingBaseClassList'")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    clientId: {
      type: Number,
      required: false,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "clientLoadingBaseClassId",
      config: {
        title: "積地分類選択",
        headers: [
          {
            text: "積地分類名",
            key: "baseClassName",
            align: false
          }
        ],
        width: "30%"
      },
      initSearchCond: () => {
        return {
          clientId: this.clientId == null ? -1 : this.clientId,
          clientName: "",
          baseClassName: ""
        };
      }
    };
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      this.p_code = data.id;
      this.p_label = data.baseClassName;
    }
  }
};
</script>
