import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import qs from "qs";
import * as Enum from "@/assets/js/enum";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  /**
   * 配車画面の左側、乗務員と乗務員に紐付くデータ
   */
  scheduleList: [],
  /**
   * 配車画面の左側、中継用のデータ
   */
  relayList: [],
  relayScheduleList: {},
  /**
   * 配車画面の右側、未配車のデータ
   */
  reserveList: [],
  reserveListCount: 0,
  /**
   * 配車画面の右側、スライド用のデータ
   */
  slideList: [],
  slideListCount: 0,
  /**
   * 配車画面の右側、最近戻したカード用のデータ
   */
  recentlyReturnedList: [],
  recentlyReturnedListCount: 0,
  /**
   * 確認メール用運行リスト
   */
  driverDispatchList: [],
  /**
   * メール送信履歴リスト
   */
  dispatchMailList: {
    listOfDriversForAll: [],
    listOfDriversForFirst: []
  },
  /**
   * 積地エリアリスト
   */
  loadingAreaList: [],
  /**
   * 卸地エリア履歴リスト
   */
  unloadingAreaList: [],
  //配車表の日付
  scheduledLoadingDate: null,
  //ページング用
  paging: {
    scheduleTablePageCount: 0,
    reserveTablePageCount: 0,
    slideTablePageCount: 0,
    recentlyReturnedTablePageCount: 0
  },
  driverCount: 0,
  activeEditDriverIds: []
};

const getters = {
  allData: (state, getters, rootState) => {
    return state.driverScheduleList;
  },
  getScheduledLoadingDate: state => state.scheduledLoadingDate,
  getDriverCount: state => state.driverCount,
  getActiveDriversSchedule: state => {
    return state.scheduleList.filter(card => state.activeEditDriverIds.includes(card.driverId));
  }
};

const actions = {
  // 乗務員を取得
  async fetchDriver({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getAllDriver + id).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 運行情報を取得
  async fetchDispatchCardDetail({ commit, state }, { orderType, orderId }) {
    return $axios
      .get(
        axiosBase.getUrls().getDispatchCardDetail +
          orderType +
          "/" +
          orderId +
          "/" +
          state.scheduledLoadingDate
      )
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  // 運行情報を取得
  async fetchDispatchCardDetailByCardId({ commit, state }, { cardId }) {
    return $axios.get(axiosBase.getUrls().getDispatchCardDetail + cardId).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 中継設定 中継元を取得
  async fetchDispatchRelayCardDetail({ commit, state }, { orderType, orderId }) {
    return $axios
      .get(axiosBase.getUrls().getDispatchRelayCardDetail + orderType + "/" + orderId)
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  // 車両変更情報を取得
  async fetchDispatchChangableVehicleList({ commit, state }, getParams) {
    if (Object.keys(getParams).length === 0 || !getParams.driverId || !getParams.excludeVehicleId) {
      return [];
    } else {
      return $axios
        .get(axiosBase.getUrls().getDispatchChangableVehicle, {
          params: getParams,
          paramsSerializer: params => {
            return qs.stringify(params);
          }
        })
        .then(value => {
          return Promise.resolve(value.data.data == null ? [] : value.data.data);
        });
    }
  },
  // 配車画面の左側。乗務員と乗務員に紐付くカードを取得
  async fetchScheduleList({ commit, state }, getParams) {
    const mergedParams = Object.assign(getParams, {
      pageCount: state.paging.scheduleTablePageCount
    });

    return $axios
      .get(axiosBase.getUrls().getDispatchDriverList, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.scheduleTablePageCount === 0) {
          commit("clearDispatchSchedule");
        }
        commit("writeDispatchSchedule", value.data.data);
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車画面の左側。乗務員と乗務員に紐付くカードを乗務員を特定して取得
  // 取得したデータをVuexの取得済み一覧にマージする
  async fetchScheduleListSpecifiedDriver({ commit, state }, driverIds) {
    const params = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      driverId: driverIds.filter(v => v !== null)
    };

    return $axios
      .get(axiosBase.getUrls().getDispatchDriverList, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("mergeDispatchSchedule", value.data.data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  // 配車画面の左側。乗務員と乗務員に紐付くカードを乗務員を特定して取得
  // 上記のマージなしバージョン
  async getScheduleListSpecifiedDriver({ commit, state }, driverIds) {
    const params = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      driverId: driverIds.filter(v => v !== null)
    };

    return $axios
      .get(axiosBase.getUrls().getDispatchDriverList, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  // 配車乗務員備考の取得
  async fetchScheduleRemark({ dispatch, commit, state }, driverId) {
    const params = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      driverId: driverId
    };

    return $axios
      .get(axiosBase.getUrls().getDispatchRemark, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車カード備考の取得
  async fetchCardRemark({ dispatch, commit, state }, params) {
    const getUrl = axiosBase.getUrls().getDispatchCardRemark + params.orderType + "/" + params.orderId;

    return $axios
      .get(getUrl)
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },

  // 配車スライドカード備考の取得
  async fetchSlideCardRemark({ dispatch, commit, state }, params) {
    const mergedParams = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      orderType: params.orderType,
      orderId: params.orderId
    };

    return $axios
      .get(axiosBase.getUrls().getDispatchSlideCardRemark, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },

  // 配車画面の右側。未配車のカードを取得
  async fetchReserveList({ commit, state }, getParams) {
    const mergedParams = Object.assign(getParams, {
      pageCount: state.paging.reserveTablePageCount
    });

    return $axios
      .get(axiosBase.getUrls().getClassifiedOrder, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.reserveTablePageCount === 0) {
          commit("clearDispatchReserve");
        }
        commit("writeDispatchReserve", value.data.data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車画面の右側。orderIdとorderTypeを特定して取得
  // 取得したデータをVuexの取得済み一覧にマージする
  async fetchReserveOrder({ commit, state }, orderData) {
    const params = orderData;

    return $axios
      .get(axiosBase.getUrls().getClassifiedOrder, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("mergeDispatchReserve", value.data.data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車画面の右側。未配車のスライドカードを取得
  async fetchReserveSlideList({ commit, state }) {
    return $axios
      .get(axiosBase.getUrls().getClassifiedOrderSlide, {
        params: {
          scheduledLoadingDate: state.scheduledLoadingDate,
          pageCount: state.paging.slideTablePageCount
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.slideTablePageCount === 0) {
          commit("clearDispatchReserveSlide");
        }
        commit("writeDispatchReserveSlide", value.data.data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // スライドデータ。orderIdとorderTypeを特定して取得
  // 取得したデータをVuexの取得済み一覧にマージする
  async fetchReserveSlideOrder({ commit, state }, getParams) {
    const params = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      orderId: getParams.orderId,
      orderType: getParams.orderType
    };

    return $axios
      .get(axiosBase.getUrls().getClassifiedOrderSlide, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("mergeReserveSlide", value.data.data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車画面の右側。最近戻したカードを取得
  async fetchRecentlyReturnedList({ commit, state }) {
    return $axios
      .get(axiosBase.getUrls().getRecentlyReturned, {
        params: {
          scheduledLoadingDate: state.scheduledLoadingDate,
          pageCount: state.paging.recentlyReturnedTablePageCount
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.recentlyReturnedTablePageCount === 0) {
          commit("clearDispatchRecentlyReturned");
        }
        commit("writeDispatchRecentlyReturned", value.data.data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車画面の右側。orderIdとorderTypeを特定して取得
  // 取得したデータをVuexの取得済み一覧にマージする
  async fetchRecentlyReturnedOrder({ commit, state }, getParams) {
    const params = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      orderId: getParams.orderId,
      orderType: getParams.orderType
    };
    return $axios
      .get(axiosBase.getUrls().getRecentlyReturned, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("mergeRecentlyReturnedReserve", value.data.data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // GeneralClient取得
  async fetchGeneralClientList({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getAllGeneralClient).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 配車画面左側。フィルタ荷主一覧取得
  async fetchClientDispatchFilter({ commit, state }, scheduleDate) {
    return $axios.get(axiosBase.getUrls().getClientDispatchFilter + scheduleDate).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 配車画面右側。フィルタ荷主一覧取得
  async fetchClientClassifiedFilter({ commit, state }, scheduleDate) {
    return $axios.get(axiosBase.getUrls().getClientClassifiedFilter + scheduleDate).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 第一運行メール送信履歴取得
  async fetchAllMailFirst({ commit, state }, date) {
    return $axios.get(axiosBase.getUrls().getDispatchMailList + "/" + date + "/first").then(value => {
      commit("writeDispatchMailFirst", value.data.data);
    });
  },
  // 全運行メール送信履歴取得
  async fetchAllMailAll({ commit, state }, date) {
    return $axios.get(axiosBase.getUrls().getDispatchMailList + "/" + date + "/all").then(value => {
      commit("writeDispatchMailAll", value.data.data);
    });
  },
  // 指定乗務員のメール送信履歴を取得
  async fetchMailByDriver({ commit, state }, { loadingDate, driverId }) {
    return await $axios
      .get(axiosBase.getUrls().getDispatchMailList + "/" + loadingDate + "/" + driverId)
      .then(value => {
        return value.data.data;
      });
  },
  // 連絡メール用第一運行取得
  async fetchFirstDispatch({ commit, state }, { driverId, token }) {
    return await $axios
      .get(axiosBase.getUrls().getFirstDispatch + "/" + driverId + "/" + token)
      .then(value => {
        commit("writeDriverDispatch", value.data.data);
      });
  },
  // 連絡メール用全運行取得
  async fetchAllDispatch({ commit, state }, { driverId, token }) {
    return await $axios.get(axiosBase.getUrls().getAllDispatch + "/" + driverId + "/" + token).then(value => {
      commit("writeDriverDispatch", value.data.data);
    });
  },
  // 積日から積地エリアを取得
  async fetchExistsLoadingArea({ commit, state }, loadingDate) {
    return $axios.get(axiosBase.getUrls().getExistsLoadingAreaList + loadingDate).then(value => {
      return value.data.data;
      // commit("writeLoadingArea", value.data.data);
    });
  },
  // 積日から卸地エリアを取得
  async fetchExistsUnloadingArea({ commit, state }, loadingDate) {
    return await $axios.get(axiosBase.getUrls().getExistsUnloadingAreaList + loadingDate).then(value => {
      return value.data.data;
      // commit("writeUnloadingArea", value.data.data);
    });
  },
  // 水揚げを取得
  async fetchDispatchLanding({ commit, state }, driverId) {
    return $axios
      .get(axiosBase.getUrls().getDispatchLanding + state.scheduledLoadingDate + "/" + driverId)
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  //カードの備考を編集
  async postEditCardRemark({ dispatch, commit, state }, params) {
    return $axios
      .post(axiosBase.getUrls().postEditCardRemark, params)
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  //仮カード作成
  async postEditDispatchTempCard({ commit, state }, data) {
    return $axios
      .post(axiosBase.getUrls().postEditDispatchTempCard, data)
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * GET：D&D 事前確認
   */
  async checkDragDrop({ state, getters, commit }, data) {
    const makeRequest = data => {
      const fromCard = data.from;
      const toCard = data.to;
      const opt = data.opt;

      let deployType = opt.between ? "20" : "10";
      if (deployType === "10") {
        deployType = toCard.data.isBlank ? "20" : "10";
      }

      //deployType交換:10 挿入:20
      return {
        orderId: fromCard.data.orderId,
        orderType: fromCard.data.orderType,
        cardId: fromCard.data.cardId,
        scheduledLoadingDate: state.scheduledLoadingDate,
        driverId: toCard.driverId,
        cardOrder: toCard.data.cardOrder,
        deployType: deployType
      };
    };
    return await $axios
      .get(axiosBase.getUrls().getDeployEditable, {
        params: makeRequest(data),
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data);
      });
  },
  /**
   * 通常のD&D
   */
  async dragAndDrop({ commit, state }, data) {
    const makeRequest = data => {
      const fromCard = data.from;
      const toCard = data.to;
      const opt = data.opt;
      const reset = data.resetPaymentDetail ? data.resetPaymentDetail : false;

      let deployType = opt.between ? "20" : "10";
      if (deployType === "10") {
        deployType = toCard.data.isBlank ? "20" : "10";
      }

      //deployType交換:10 挿入:20
      return {
        orderId: fromCard.data.orderId,
        orderType: fromCard.data.orderType,
        // スライド蘭からスライドした配車日=スライド日のカード対応
        cardId: fromCard.data.slideCardId ? fromCard.data.slideCardId : fromCard.data.cardId,
        scheduledLoadingDate: state.scheduledLoadingDate,
        driverId: toCard.driverId,
        cardOrder: toCard.data.cardOrder,
        deployType: deployType,
        resetPaymentDetail: reset
      };
    };

    return $axios
      .post(axiosBase.getUrls().postDragAndDrop, makeRequest(data))
      .then(value => {
        // self.commit("dispatch/swap", data); // 後の処理で「fetchScheduleListSpecifiedDriver」をコールしているので不要
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },

  /**
   * 配車表から外すD&D
   */
  async dragAndDropRemove({ commit, state }, data) {
    const makeRequest = data => {
      const fromCard = data.from;
      return {
        orderId: fromCard.data.orderId,
        orderType: fromCard.data.orderType,
        cardId: fromCard.data.cardId,
        scheduledLoadingDate: state.scheduledLoadingDate,
        driverId: fromCard.driverId
      };
    };

    const self = this;
    const requestData = makeRequest(data);

    return $axios
      .post(axiosBase.getUrls().postDragAndDropRemove, requestData)
      .then(value => {
        //仮カードなら区分け表内には戻さない
        if (requestData.orderType !== Enum.OrderType.TempCard.code) {
          self.commit("dispatch/swap", data);
        }

        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  /**
   * 日跨ぎへのドロップ
   */
  async dragAndDropDayExceed({ commit, state }, data) {
    const makeRequest = data => {
      const fromCard = data.from;
      const toCard = data.to;
      return {
        orderId: fromCard.data.orderId,
        orderType: fromCard.data.orderType,
        cardId: fromCard.data.cardId,
        scheduledLoadingDate: state.scheduledLoadingDate,
        driverId: toCard.driverId
      };
    };

    const self = this;

    return $axios
      .post(axiosBase.getUrls().postCardDayExceed, makeRequest(data))
      .then(value => {
        self.commit("dispatch/swap", data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },

  /**
   * 日跨ぎ対象カードに付帯する仮カードの作成
   */
  async createDayExceedTemp({ commit, state }, params) {
    return $axios
      .post(axiosBase.getUrls().postCardDayExceedCreateTemp, params)
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },

  /**
   * 配車表(左)以外のスライド解除処理
   */
  async releaseDispatchSlide({ commit, state }, params) {
    return $axios
      .post(axiosBase.getUrls().postDispatchSlideRelease, params)
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },

  /**
   * カードのマークを行う
   */
  async saveCardMark({ commit, state }, data) {
    return $axios
      .post(
        axiosBase.getUrls().postSaveCardMark,
        Object.assign(data, {
          scheduledLoadingDate: state.scheduledLoadingDate
        })
      )
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  //宵積み設定
  async postSetNightLoading({ commit, state }, data) {
    return $axios
      .post(axiosBase.getUrls().postSetNightLoading, {
        driverId: data.driverId,
        scheduledLoadingDate: state.scheduledLoadingDate
      })
      .then(value => {
        return Promise.resolve(value.data);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  //宵積み解除設定
  async postUnSetNightLoading({ commit, state }, data) {
    return $axios
      .post(axiosBase.getUrls().postUnSetNightLoading, {
        driverId: data.driverId,
        scheduledLoadingDate: state.scheduledLoadingDate
      })
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  //中継解除設定
  async postDispatchRelayRelease({ commit, state }, data) {
    return $axios
      .post(axiosBase.getUrls().postDispatchRelayRelease + data.orderId)
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  //中継解除前の実績存在チェック
  async postCheckDispatchRelayRelease({ commit, state }, data) {
    return $axios
      .post(axiosBase.getUrls().postDispatchRelayReleaseCheck + data.orderId)
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  async mergeDriverRemark({ commit, state }, data) {
    commit("mergeDriverRemark", data);
  }
};

const mutations = {
  mergeDriverRemark(state, data) {
    if (state.scheduleList != null && state.scheduleList.length > 0) {
      const schedule = state.scheduleList.filter(line => line.driverId === data.driverId).pop();
      if (schedule) {
        schedule.remark = data.remark;
      }
    }
  },
  clearDispatchData(state) {
    state.scheduleList = [];
    state.relayList = [];
    state.reserveList = [];
    state.reserveListCount = 0;
    state.slideList = [];
    state.slideListCount = 0;
    state.recentlyReturnedList = [];
    state.recentlyReturnedListCount = 0;
  },
  clearDispatchSchedule(state) {
    state.scheduleList = [];
  },
  writeDispatchSchedule(state, data) {
    if (data != null) {
      state.scheduleList = state.scheduleList.concat(data);
    }
  },
  clearDispatchReserve(state) {
    state.reserveList = [];
    state.reserveListCount = 0;
  },
  writeDispatchReserve(state, data) {
    if (data != null) {
      state.reserveList = state.reserveList.concat(data.list);
      state.reserveListCount = data.count;
    } else {
      state.reserveListCount = 0;
    }
  },
  clearDispatchReserveSlide(state) {
    state.slideList = [];
    state.slideListCount = 0;
  },
  writeDispatchReserveSlide(state, data) {
    if (data != null) {
      state.slideList = state.slideList.concat(data.list);
      state.slideListCount = data.count;
    } else {
      state.slideListCount = 0;
    }
  },
  clearDispatchRecentlyReturned(state) {
    state.recentlyReturnedList = [];
    state.recentlyReturnedListCount = 0;
  },
  writeDispatchRecentlyReturned(state, data) {
    if (data != null) {
      state.recentlyReturnedList = state.recentlyReturnedList.concat(data.list);
      state.recentlyReturnedListCount = data.count;
    } else {
      state.recentlyReturnedListCount = 0;
    }
  },
  writeDriverDispatch(state, data) {
    state.driverDispatchList = data == null ? [] : data;
  },
  writeDispatchMailAll(state, data) {
    state.dispatchMailList.listOfDriversForAll = data == null ? [] : data;
  },
  writeDispatchMailFirst(state, data) {
    state.dispatchMailList.listOfDriversForFirst = data == null ? [] : data;
  },
  writeLoadingArea(state, data) {
    state.loadingAreaList = data == null ? [] : data;
  },
  writeUnloadingArea(state, data) {
    state.unloadingAreaList = data == null ? [] : data;
  },
  /**
   * 配車表のデータリストへ新しく取得してきた乗務員のカードリストをマージする
   */
  mergeDispatchSchedule(state, cards) {
    state.activeEditDriverIds = [];
    cards.forEach(card => {
      let targetIndex = null;
      state.activeEditDriverIds.push(card.driverId);
      state.scheduleList
        .filter((value, index) => {
          if (value.driverId === card.driverId) {
            targetIndex = index;
          }
          return value.driverId === card.driverId;
        })
        .pop();
      if (targetIndex !== null) {
        state.scheduleList.splice(targetIndex, 1, card);
      }
    });
  },
  /**
   * 配車前カードのデータリストへ新しく取得してきた配車前カードをマージする
   */
  mergeDispatchReserve(state, cards) {
    if (cards !== null) {
      cards.list.forEach(card => {
        let targetIndex = null;
        state.reserveList
          .filter((value, index) => {
            if (value.orderId === card.orderId && value.orderType === card.orderType) {
              targetIndex = index;
            }
            return value.orderId === card.orderId && value.orderType === card.orderType;
          })
          .pop();
        if (targetIndex !== null) {
          state.reserveList.splice(targetIndex, 1, card);
        }
      });
    }
  },
  mergeRecentlyReturnedReserve(state, cards) {
    if (cards !== null) {
      cards.list.forEach(card => {
        let targetIndex = null;
        state.recentlyReturnedList
          .filter((value, index) => {
            if (value.orderId === card.orderId && value.orderType === card.orderType) {
              targetIndex = index;
            }
            return value.orderId === card.orderId && value.orderType === card.orderType;
          })
          .pop();
        if (targetIndex !== null) {
          state.recentlyReturnedList.splice(targetIndex, 1, card);
        }
      });
    }
  },
  mergeReserveSlide(state, cards) {
    if (cards !== null) {
      cards.list.forEach(card => {
        let targetIndex = null;
        state.slideList
          .filter((value, index) => {
            if (value.orderId === card.orderId && value.orderType === card.orderType) {
              targetIndex = index;
            }
            return value.orderId === card.orderId && value.orderType === card.orderType;
          })
          .pop();
        if (targetIndex !== null) {
          state.slideList.splice(targetIndex, 1, card);
        }
      });
    }
  },
  /**
   * 仮車両追加処理
   * @param state
   * @param emptyCard
   */
  addDriver(state, emptyCard) {
    let firstCard = Object.assign({}, emptyCard);
    firstCard.isFirst = true;
    let dayExceedCard = Object.assign({}, emptyCard);
    dayExceedCard.isDayExceed = true;

    state.scheduleList.push({
      firstCard: firstCard,
      cardList: [Object.assign({}, emptyCard)],
      dayExceedCard: dayExceedCard
    });
  },
  /**
   * 仮車両削除処理
   * @param state
   * @param index
   */
  delDriver(state, index) {
    // 元に戻すカード
    for (var i = state.scheduleList[index].cardList.length - 1; i >= 0; i--) {
      let _card = state.scheduleList[index].cardList[i];
      if (_card.isBlank === false) {
        _card.isReserve = true;
        state.reserveList.splice(0, 0, _card);
      }
    }
    state.scheduleList.splice(index, 1);
  },

  /**
   * 配車表でカードドロップ時カードを入れ替える
   * @param state
   * @param data
   */
  swap(state, data) {
    // ドラッグ先カード定義
    let toCardType = null;
    let toCards = null;
    let toCard = null;
    let toCardIndex = null;

    //区分け表でDROP
    if (data.opt.classifyWrap === true) {
      // 未配車データ
      toCardType = 1;
      toCards = state.reserveList;
      toCard = {
        isFirst: false,
        isDayExceed: false,
        isSlide: false,
        isRelay: false,
        isNightLoading: false,
        isReserve: true
      };
      toCardIndex = data.to.data.seq;
    } else if (data.opt.slideWrap !== false) {
      // スライドデータ
      toCardType = 2;
      toCards = state.slideList;
      toCard = {
        isFirst: false,
        isDayExceed: false,
        isSlide: true,
        isRelay: false,
        isNightLoading: false,
        isReserve: false
      };
      toCardIndex = 0;
    } else if (data.to.data._relayCard === true) {
      // 中継データ
      toCardType = 3;
      toCards = state.relayList;
      toCard = data.to.data;
      toCard._relayCard = true;
      toCardIndex = data.to.data.seq;
    } else if (data.to.data.isFirst === true) {
      // 第一運行データ
      toCardType = 4;
      toCards = state.scheduleList[data.to.itemKey].firstCard;
      toCard = data.to.data;
      toCardIndex = data.to.data.seq;
    } else if (data.to.data.isDayExceed === true) {
      // 日跨ぎ運行データ
      toCardType = 5;
      toCards = state.scheduleList[data.to.itemKey].dayExceedCard;
      toCard = data.to.data;
      toCardIndex = data.to.data.seq;
    } else {
      // 通常運行データ
      toCardType = 6;
      toCards = state.scheduleList[data.to.itemKey].cardList;
      toCard = data.to.data;
      toCardIndex = data.to.data.seq;
    }
    const toCardWork = Object.assign({}, toCard);

    // ドラッグ元カード定義
    let fromCards = null;
    let fromCard = data.from.data;
    let fromCardIndex = null;
    let fromCardType = null;

    if (data.from.data.isReserve === true) {
      // 未配車データ
      fromCardType = 1;
      fromCards = state.reserveList;
      fromCardIndex = data.from.data.seq;
    } else if (data.from.data.isSlide !== false) {
      // スライドデータ
      fromCardType = 2;
      fromCards = state.slideList;
      fromCardIndex = data.from.data.seq;
    } else if (data.from.data._relayCard === true) {
      // 中継データ
      fromCardType = 3;
      fromCards = state.relayList;
      fromCard._relayCard = true;
      fromCardIndex = data.from.data.seq;
    } else if (data.from.data.isFirst === true) {
      // 第一運行データ
      fromCardType = 4;
      fromCards = state.scheduleList[data.from.itemKey].firstCard;
      fromCardIndex = data.from.data.seq;
    } else if (data.from.data.isDayExceed === true) {
      // 日跨ぎ運行データ
      fromCardType = 5;
      fromCards = state.scheduleList[data.from.itemKey].dayExceedCard;
      fromCardIndex = data.from.data.seq;
    } else {
      // 通常運行データ
      fromCardType = 6;
      fromCards = state.scheduleList[data.from.itemKey].cardList;
      fromCardIndex = data.from.data.seq;
    }
    const fromCardWork = Object.assign({}, fromCard);

    // 関数
    const isChange = function (card, opt) {
      return (
        card.isBlank === false &&
        opt.between === false &&
        opt.classifyWrap === false &&
        opt.slideWrap === false
      );
    };
    const overwritePos = function (card, work) {
      if (card.isBlank === true) {
        // return Object.assign(card);
        return Object.assign({}, card);
      } else {
        return Object.assign(card, {
          isSlide: work.isSlide,
          isDayExceed: work.isDayExceed,
          isNightLoading: work.isNightLoading,
          isReserve: work.isReserve,
          isFirst: work.isFirst
        });
      }
    };
    const cardSplice = function (cardList, index, card, data, type) {
      if (Array.isArray(cardList)) {
        if (card === null) {
          if (
            toCardType === fromCardType &&
            data.from.itemKey === data.to.itemKey &&
            data.from.data.seq > data.to.data.seq
          ) {
            cardList.splice(index + 1, 1);
          } else {
            cardList.splice(index, 1);
          }
        } else {
          cardList.splice(index, 0, card);
        }
      } else {
        if (card === null) {
          let keys = Object.keys(data.empty);
          for (let i = 0, len = keys.length; i < len; i++) {
            cardList[keys[i]] = data.empty[keys[i]];
          }
          if (type === 4) {
            cardList.isFirst = true;
          }
          if (type === 5) {
            cardList.isDayExceed = true;
          }
        } else {
          let keys = Object.keys(card);
          for (let i = 0, len = keys.length; i < len; i++) {
            cardList[keys[i]] = card[keys[i]];
          }
        }
      }
    };
    const setSeq = function (cardList) {
      if (Array.isArray(cardList)) {
        for (var i = 0; i < cardList.length; i++) {
          cardList[i].seq = i;
        }
        cardList.splice();
      } else {
        cardList.seq = 0;
      }
    };

    // ドラッグカード処理
    fromCard = overwritePos(fromCard, toCardWork);
    toCard = overwritePos(toCard, fromCardWork);

    delete toCard._relayCard;
    delete fromCard._relayCard;
    delete toCard._isHistory;
    delete fromCard._isHistory;
    delete toCard._befDriverId;
    delete fromCard._befDriverId;
    if (toCardType === 1) {
      fromCard._isHistory = true;
    } else if (toCardType === 3 && fromCardType !== 3) {
      fromCard._befDriverId = data.from.driverId;
    }
    if (isChange(toCardWork, data.opt)) {
      toCard = overwritePos(Object.assign(toCard, fromCardWork), toCardWork);
    } else {
      if (
        toCardType === 4 &&
        data.opt.between === true &&
        state.scheduleList[data.to.itemKey].firstCard.isBlank === false
      ) {
        // 第一運行の矢印の場合、既存の第一運行を通常運行へスライドする
        let targetCard = Object.assign({}, state.scheduleList[data.to.itemKey].firstCard);
        targetCard.isFirst = false;
        targetCard.isSlide = false;
        targetCard.isRelay = false;
        targetCard.isReserve = false;
        state.scheduleList[data.to.itemKey].cardList.splice(0, 0, targetCard);
        fromCardIndex = fromCardIndex + 1;
      }
      cardSplice(toCards, toCardIndex, Object.assign({}, fromCard), data, toCardType);
    }
    if (isChange(toCardWork, data.opt)) {
      fromCard = overwritePos(Object.assign(fromCard, toCardWork), fromCardWork);
    } else {
      cardSplice(fromCards, fromCardIndex, null, data, fromCardType);
    }
    setSeq(fromCards);
    setSeq(toCards);
  },
  /**
   * 宵積設定
   * @param state
   * @param data
   */
  setNightLoadingClassifiedOrders(state, data) {
    data.isNightLoading = true;
  },
  /**
   * 宵積解除
   * @param state
   * @param data
   */
  unSetNightLoadingClassifiedOrders(state, data) {
    data.isNightLoading = false;
  },
  setScheduledLoadingDate: (state, data) => {
    state.scheduledLoadingDate = data;
  },
  incrementDispatchTablePageCount(state) {
    state.paging.scheduleTablePageCount++;
  },
  clearDispatchTablePageCount(state) {
    state.paging.scheduleTablePageCount = 0;
  },
  incrementReserveTablePageCount(state) {
    state.paging.reserveTablePageCount++;
  },
  clearReserveTablePageCount(state) {
    state.paging.reserveTablePageCount = 0;
  },
  incrementSlideTablePageCount(state) {
    state.paging.slideTablePageCount++;
  },
  clearSlideTablePageCount(state) {
    state.paging.slideTablePageCount = 0;
  },
  incrementRecentlyReturnedTablePageCount(state) {
    state.paging.recentlyReturnedTablePageCount++;
  },
  clearRecentlyReturnedTablePageCount(state) {
    state.paging.recentlyReturnedTablePageCount = 0;
  },
  setDriverCount(state, count) {
    state.driverCount = count;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
