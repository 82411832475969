export default class AxiosBase {
  getUrls() {
    return {
      loginAuth: "login",
      logout: "logout",
      changePassword: "/api/public/password-reminder/update-password",
      getMeta: "api/meta/init",
      getBjiOrders: "api/order/bji/list",
      getBjiAllOrders: "api/ccbji/import/all-order",
      getBjiAllOrderCount: "api/ccbji/import/all-order-count",
      getBjiAllDeleteOrders: "api/ccbji/import/all-order-delete",
      getBjiImportOrder: "/api/ccbji/import",
      getBjiImportDetailOrder: "/api/ccbji/import/detail",
      getGeneralOrder: "api/order",
      getGeneralOrderSearch: "api/order/list/",
      getGeneralOrderSearchCount: "api/order/list-count/",
      getGeneralOrderHistoryList: "api/order/history/list/",
      getEditedOrderList: "api/order/integrated/list/",
      getPreClassifyOrders: "api/classify/all",
      getClassifiedOrder: "api/dispatch/reserve/list/",
      getClassifiedOrderSlide: "api/dispatch/reserve/list/slide/",
      getRecentlyReturned: "api/dispatch/reserve/list/recently-returned/",
      getDispatchDriverList: "api/dispatch/schedule/list",
      getDispatchCardDetail: "api/dispatch/card/detail/",
      getDispatchRelayCardDetail: "api/dispatch/relay/card/detail/",
      getMailSendDriverList: "api/dispatch/send/mail/list/",
      getDynamicTrackingList: "api/dynamic/tracking/list",
      getDynamicCardDetail: "api/dynamic/tracking/detail/",
      getDispatchGarageArea: "api/management/area/schedule/garage-area/",
      getDispatchGarageAreaForDynamic: "api/management/area/dynamic/garage-area/",
      getAllBaseArea: "api/management/area/list",
      getAllBases: "api/management/bases",
      getAllPrefectures: "api/management/prefectures",
      getClient: "api/management/general-client",
      getAllDriver: "api/management/driver/",
      getAllDriverList: "api/management/driver/list",
      getAllDriverListForDispatch: "api/management/driver-schedule/list/dispatch",
      getAllDriverListForDirectDispatch: "api/management/driver-schedule/list/direct/dispatch",
      getOneDriverScheduleForDispatch: "api/management/driver-schedule/schedule/dispatch",
      getAllDriverListForSchedule: "api/management/driver-schedule/list/schedule",
      getAllDriverScheduleList: "api/management/driver-schedule/list",
      getAllDriverSchedule: "api/management/driver-schedule/schedule",
      getAllGeneralClient: "api/management/general-client/list",
      getAllGeneralClientForSelect: "api/management/general-client/list/select",
      getAllItemType: "api/management/item-type/",
      getAllItemTypeList: "api/management/item-type/list",
      getAllItemTypeForSelect: "api/management/item-type/list/select",
      getAllUser: "api/management/user",
      getAllBjiCustomer: "api/management/bjiCustomer/list",
      getAllDeliveryCompany: "api/management/delivery-company/",
      getAllDeliveryCompanyList: "api/management/delivery-company/list",
      getAllDeliveryCompanyListForSelect: "api/management/delivery-company/list/select",
      getAllArea: "api/management/area/",
      getAllAreaList: "api/management/area/list",
      getAllAreaListNoConditions: "api/management/area/list/all",
      getAllBoilerplate: "api/management/boilerplate/",
      getAllBoilerplateList: "api/management/boilerplate/list",
      getAllSignature: "api/management/signature/",
      getAllSignatureList: "api/management/signature/list",
      getAllTransportTypeList: "api/meta/transport/all/list",
      getAllVehicle: "api/management/vehicle/",
      getAllVehicleList: "api/management/vehicle/list",
      getAllUsableVehicleList: "api/management/vehicle/usable/list",
      getAllUsableVehicleListForDispatch: "api/management/vehicle/usable/list/dispatch",
      getAllVehicleType: "api/management/vehicle-type/",
      getAllVehicleTypeList: "api/management/vehicle-type/list",
      getAllVehicleCarryWeight: "api/management/vehicle-carry-weight/",
      getAllVehicleCarryWeightList: "api/management/vehicle-carry-weight/list",
      getAllOperationResourceList: "api/management/operation-resource/list",
      getAllOrderQuantityList: "api/management/operation-possible-resource/order/list",
      getAllLoadingBase: "api/management/loading-base/",
      getAllLoadingBaseList: "api/management/loading-base/list",
      getLoadingBaseForSelect: "api/management/loading-base/select/list/",
      getAllLoadingBaseClass: "api/management/client-branch/loading-base-class/list",
      getAllUnloadingBase: "api/management/unloading-base/",
      getAllUnloadingBaseList: "api/management/unloading-base/list",
      getUnloadingBaseForSelect: "api/management/unloading-base/select/list",
      getAllUnloadingBaseClass: "api/management/client-branch/unloading-base-class/list",
      getAllVehicleGarage: "api/management/vehicle-garage/list",
      getVehicleGarage: "api/management/vehicle-garage/",
      getAllBjiImportBranchType: "api/management/client-branch/bji-import/list",
      getAllBranchTypeByClientId: "api/management/client-branch/list/client-id",
      getAllBranchTypeByClientIdAll: "api/management/client-branch/list/client-id/all",
      getAllClientBranchList: "api/management/client-branch/list",
      getAllClientBranch: "api/management/client-branch/",
      getAllLoadingBaseClassList: "api/management/client-loading-base-class/list",
      getAllUnloadingBaseClassList: "api/management/client-unloading-base-class/list",
      getLoadingBaseClass: "api/management/client-loading-base-class/",
      getUnloadingBaseClass: "api/management/client-unloading-base-class/",
      getAllBillingType: "api/management/billing-type/",
      getAllBillingTypeList: "api/management/billing-type/list",
      getAllRoleList: "api/management/user-role/list",
      getAllDriverNameList: "api/meta/driver-name/list/",
      getRole: "api/management/user-role/",
      getAllFunctionList: "api/meta/function/list",
      getDispatchMailList: "api/dispatch/mail/list",
      getFirstDispatch: "api/public/dispatch/mail/first-dispatch",
      getAllDispatch: "api/public/dispatch/mail/all-dispatch",
      getExportDriver: "api/management/driver/export",
      getExportUser: "api/management/user/export",
      getExportVehicle: "api/management/vehicle/export",
      getExportLoadingBase: "api/management/loading-base/export",
      getExportUnloadingBase: "api/management/unloading-base/export",
      getExportDeliveryCompany: "api/management/delivery-company/export",
      getExportClient: "api/management/general-client/export",
      getExportClientBranch: "api/management/client-branch/export",
      getExportArea: "api/management/area/export",
      getExportUserRole: "api/management/user-role/export",
      getBoilerplateSelectList: "api/management/boilerplate/list/all",
      getSignatureSelectList: "api/management/signature/list/all",
      getAllBillingItem: "api/management/billing-item/",
      getAllBillingItemList: "api/management/billing-item/list",
      getAllDisplayBillingItemList: "api/management/billing-item/list/display",
      getAllPaymentItem: "api/management/payment-item/",
      getAllPaymentItemList: "api/management/payment-item/list",
      getAllBaseDistance: "api/management/base-distance/",
      getAllBaseDistanceList: "api/management/base-distance/list",
      getBaseDistanceWorkList: "api/management/base-distance/import/summary",
      getBaseDistanceWorkListCount: "api/management/base-distance/import/summary/count",
      getBaseDistanceExport: "api/management/base-distance/export",
      getAllPaymentTariffType: "api/management/payment-tariff-type/",
      getAllPaymentTariffTypeList: "api/management/payment-tariff-type/list",
      getPaymentTariffTypeForSelect: "api/management/payment-tariff-type/select/list",
      getAllPaymentTariffRule: "api/management/payment-tariff-rule/",
      getAllPaymentTariffRuleList: "api/management/payment-tariff-rule/list",
      uploadPaymentTariffRuleImportWork: "api/management/payment-tariff-rule/import/work",
      uploadPaymentTariffRuleImportWorkValidation: "api/management/payment-tariff-rule/import/validation",
      uploadPaymentTariffRuleImportWorkCommit: "api/management/payment-tariff-rule/import/work/commit",
      getPaymentTariffRuleWorkList: "api/management/payment-tariff-rule/import/summary",
      getPaymentTariffRuleWorkListCount: "api/management/payment-tariff-rule/import/summary/count",
      getPaymentTariffRuleExport: "api/management/payment-tariff-rule/export",
      getAllFixedBillingRate: "api/management/fixed-billing-rate/",
      getAllFixedBillingRateList: "api/management/fixed-billing-rate/list",
      getFixedBillingWorkList: "api/management/fixed-billing-rate/import/summary",
      getFixedBillingWorkListCount: "api/management/fixed-billing-rate/import/summary/count",
      getAllFixedBillingRateHistoryList: "api/management/fixed-billing-rate/history/list/",
      getFixedBillingRateHistoryDiff: "api/management/fixed-billing-rate/history/diff/",
      getFixedBillingRateExport: "api/management/fixed-billing-rate/export",
      getAllFixedPaymentRate: "api/management/fixed-payment-rate/",
      getAllFixedPaymentRateList: "api/management/fixed-payment-rate/list",
      getFixedPaymentWorkList: "api/management/fixed-payment-rate/import/summary",
      getFixedPaymentWorkListCount: "api/management/fixed-payment-rate/import/summary/count",
      getAllFixedPaymentRateHistoryList: "api/management/fixed-payment-rate/history/list/",
      getFixedPaymentRateHistoryDiff: "api/management/fixed-payment-rate/history/diff/",
      getFixedPaymentRateExport: "api/management/fixed-payment-rate/export",
      getAllPaymentType: "api/management/payment-type/",
      getAllPaymentTypeList: "api/management/payment-type/list",
      getExportOrder: "api/order/export",
      getExportCardOrder: "api/order/export/card",
      getExistsLoadingAreaList: "api/dispatch/loading/area/",
      getExistsUnloadingAreaList: "api/dispatch/unloading/area/",
      getDispatchRemark: "api/dispatch/remark/",
      getDynamicRemark: "api/dynamic/remark/",
      getDispatchCardRemark: "api/dispatch/card/remark/",
      getDynamicCardRemark: "api/dynamic/card/remark/",
      getDeployEditable: "api/dispatch/card/deploy/editable",
      getDispatchSlideCardRemark: "api/dispatch/slide/remark/",
      getDynamicSlideCardRemark: "api/dynamic/slide/remark/",
      getDispatchLanding: "api/dispatch/landing/",
      getDispatchChangableVehicle: "api/dispatch/changeable/vehicle/",
      getClientDispatchFilter: "api/dispatch/filter/client/",
      getClientClassifiedFilter: "api/dispatch/reserve/filter/client/",
      getDispatchResult: "api/dynamic/result/driver/detail",
      getServiceContactList: "api/report/client/list",
      getTransportOrderList: "api/report/delivery-company/list",
      getServiceContactReportDownload: "api/report/service-contact/export",
      getServiceContactReportSend: "api/report/service-contact/send",
      getTransportRequestReportSend: "api/report/transport-request/send",
      getTransportInstructionReportSend: "api/report/transport-instruction/send",
      getTransportRequestReportDownload: "api/report/transport-request/export",
      getTransportInstructionReportDownload: "api/report/transport-instruction/export",
      getBillingStatementDownload: "api/finance/billing-statement/export",
      getBillingStatementDownloadFile: "api/finance/billing-statement/file/download/",
      getBillingStatementSend: "api/finance/billing-statement/send",
      getPaymentStatementDownload: "api/finance/payment-statement/export",
      getPaymentStatementDownloadFile: "api/finance/payment-statement/file/download/",
      getPaymentStatementSend: "api/finance/payment-statement/send",
      getPaymentPromptDownload: "api/finance/payment-prompt/export",
      getPaymentPromptDownloadFile: "api/finance/payment-prompt/file/download/",
      getPaymentPromptSend: "api/finance/payment-prompt/send",
      getBillingStatementBulkStatus: "api/finance/billing-statement/bulk/status",
      getAllExportTemplateList: "api/management/export-template/list",
      getAllExportTemplateListForSelect: "api/management/export-template/list/select",
      getAllExportTemplateListWithCategory: "api/management/export-template/list/with_category",
      getExportOrderCsvValidate: "api/export/validate",
      getExportOrderCsvCount: "api/export/count",
      getExportOrderCsvDownload: "api/export/order",
      getExportCsvFileDownload: "api/export/download/",
      getExportTemplate: "api/management/export-template/",
      getAllTemplateCodeList: "api/meta/template-code/list",
      getTariff: "api/management/tariff/",
      getTariffUseVehicleTypeList: "api/management/tariff/vehicleTypeList",
      getTariffTagList: "api/management/tariff/tag/list/",
      getTariffTag: "api/management/tariff/tag/",
      getAllTariffList: "api/management/tariff/list",
      getAllTariffHistoryList: "api/management/tariff/history/list/",
      getTariffHistoryDiff: "api/management/tariff/history/diff/",
      getAllUsableTariffList: "api/management/tariff/usable/list",
      getExportTariff: "api/management/tariff/export",
      getTariffWorkList: "api/management/tariff/import/summary",
      getTariffWorkListCount: "api/management/tariff/import/summary/count",
      getBillingAndPaymentList: "api/finance/billing-and-payment/list",
      getBillingAndPaymentListSum: "api/finance/billing-and-payment/list/sum",
      getDispatchCardDetailFinance: "api/finance/card/detail/",
      getOriginCardEditable: "api/finance/billing-and-payment/editable/card/",
      getOriginCardEditableBulk: "api/finance/billing-and-payment/editable/card/bulk",
      getOriginCardEditableBulkStatus: "api/finance/billing-and-payment/editable/card/bulk/status",
      getCheckBillingStatusByKey: "api/finance/billing-and-payment/billing/status/key/",
      getCheckPaymentStatusByKey: "api/finance/billing-and-payment/payment/status/key/",
      getBillingCardBasicPrice: "api/finance/billing/card/calc-basic-price",
      getBillingCard: "api/finance/billing/card/",
      getPaymentCard: "api/finance/payment/card/",
      getBillingCardHistory: "api/finance/billing/card/history",
      getAllBillingCardHistory: "api/finance/billing/history/list/",
      getBillingCardHistoryDiff: "api/finance/billing/history/diff/",
      getAllPaymentCardHistory: "api/finance/payment/history/list/",
      getPaymentCardHistoryDiff: "api/finance/payment/history/diff/",
      getBillingAndPaymentCard: "api/finance/billing-and-payment/card/",
      getBillingAndPaymentCardForMerge: "api/finance/billing-and-payment/card/merge/",
      getBillingStatementList: "api/finance/billing-statement/list/",
      getBillingStatementDetailList: "api/finance/billing-statement/detail/list/",
      getPaymentStatementList: "api/finance/payment-statement/list/",
      getPaymentStatementDetailList: "api/finance/payment-statement/detail/list/",
      getPaymentPromptList: "api/finance/payment-prompt/list/",
      getPaymentPromptDetailList: "api/finance/payment-prompt/detail/list/",
      getDispatchPatternList: "api/management/dispatch/pattern/list/",
      getDispatchPattern: "api/management/dispatch/pattern/",
      getDispatchPatternMatchedList: "api/dispatch/pattern/select/",
      getDispatchPatternMatchedDriverList: "api/dispatch/pattern/driver/",
      postEditCardRemark: "api/dispatch/card/remark/edit",
      postEditCardRemarkDynamic: "api/dynamic/card/remark/edit",
      postBjiImportOrder: "api/ccbji/import/order/edit",
      postBjiImportOrderDetail: "api/ccbji/import/order/detail/edit",
      postBillingType: "api/ccbji/import/order/billing-type/edit",
      postBjiOrder: "api/order/bji/edit",
      postGeneralOrder: "api/order/edit",
      postBjiCustomer: "api/management/customer/edit",
      postEditDriver: "api/management/driver/edit",
      postEditDriverForDispatch: "api/management/driver/edit/dispatch",
      postEditDriverScheduleForDispatch: "api/management/driver-schedule/edit/schedule/dispatch",
      postEditDriverScheduleList: "api/management/driver-schedule/schedule/list/edit",
      postEditDriverSchedule: "api/management/driver-schedule/schedule/edit",
      postEditUser: "api/management/user/add",
      postEditDeliveryCompany: "api/management/delivery-company/edit",
      postEditDeliveryCompanyForDispatch: "api/management/delivery-company/edit/dispatch",
      postEditArea: "api/management/area/edit",
      postEditBoilerplate: "api/management/boilerplate/edit",
      postEditSignature: "api/management/signature/edit",
      postEditLoadingBaseClass: "api/management/client-loading-base-class/edit",
      postEditLoadingBase: "api/management/loading-base/edit",
      postEditUnloadingBaseClass: "api/management/client-unloading-base-class/edit",
      postEditUnloadingBase: "api/management/unloading-base/edit",
      postEditGeneralClient: "api/management/general-client/edit",
      postEditItemType: "api/management/item-type/edit",
      postEditBillingType: "api/management/billing-type/edit",
      postEditClientBranch: "api/management/client-branch/edit",
      postEditAccountLock: "api/management/user/account-lock",
      postEditRole: "api/management/user-role/add",
      postEditVehicle: "api/management/vehicle/edit",
      postEditVehicleForDispatch: "api/management/vehicle/edit/dispatch",
      postEditVehicleGarage: "api/management/vehicle-garage/add",
      postEditVehicleType: "api/management/vehicle-type/edit",
      postEditVehicleCarryWeight: "api/management/vehicle-carry-weight/edit",
      postEditExportTemplate: "api/management/export-template/add",
      postEditTariff: "api/management/tariff/edit",
      uploadTariffImportWork: "api/management/tariff/import/work",
      uploadTariffImportWorkValidation: "api/management/tariff/import/validation",
      uploadTariffImportWorkCommit: "api/management/tariff/import/work/commit",
      postEditBillingItem: "api/management/billing-item/edit",
      postEditPaymentItem: "api/management/payment-item/edit",
      postEditBaseDistance: "api/management/base-distance/edit",
      uploadBaseDistanceImportWork: "api/management/base-distance/import/work",
      uploadBaseDistanceImportWorkValidation: "api/management/base-distance/import/validation",
      uploadBaseDistanceImportWorkCommit: "api/management/base-distance/import/work/commit",
      postEditPaymentTariffType: "api/management/payment-tariff-type/edit",
      postRegisterTariffTag: "api/management/tariff/register/tag",
      postUpdateTariffTag: "api/management/tariff/update/tag",
      postEditPaymentTariffRule: "api/management/payment-tariff-rule/edit",
      postEditFixedBillingRate: "api/management/fixed-billing-rate/edit",
      uploadFixedBillingRateImportWork: "api/management/fixed-billing-rate/import/work",
      uploadFixedBillingRateImportWorkValidation: "api/management/fixed-billing-rate/import/validation",
      uploadFixedBillingRateImportWorkCommit: "api/management/fixed-billing-rate/import/work/commit",
      postEditFixedPaymentRate: "api/management/fixed-payment-rate/edit",
      uploadFixedPaymentRateImportWork: "api/management/fixed-payment-rate/import/work",
      uploadFixedPaymentRateImportWorkValidation: "api/management/fixed-payment-rate/import/validation",
      uploadFixedPaymentRateImportWorkCommit: "api/management/fixed-payment-rate/import/work/commit",
      postEditPaymentType: "api/management/payment-type/edit",
      postEditDispatchPattern: "api/management/dispatch/pattern/edit",
      postResendMail: "api/dispatch/mail/send/",
      postMailChangeProcessed: "api/dispatch/mail/change/processed/",
      postAuthMail: "api/public/password-reminder/auth/mail",
      postAuthPin: "api/public/password-reminder/auth/pin",
      postSavePassword: "api/public/password-reminder/save",
      postSaveDriverName: "api/ccbji/import/save/driver-name",
      postSaveDispatchMailConfirm: "api/public/dispatch/mail/confirm",
      postSaveDispatchFavorite: "api/dispatch/favorite",
      postSetNightLoading: "api/dispatch/night-loading",
      postSetNightLoadingCommit: "api/dispatch/night-loading/commit",
      postUnSetNightLoading: "api/dispatch/night-loading/release",
      postSaveDispatchRemark: "api/dispatch/save/remark",
      postSaveDynamicRemark: "api/dynamic/save/remark",
      postSaveCardMark: "api/dispatch/card/mark",
      postSaveCardMarkDynamic: "api/dynamic/card/mark",
      postSaveCardEdit: "api/dispatch/card/edit",
      postDispatchRelay: "/api/dispatch/relay",
      postDispatchRelayRelease: "/api/dispatch/relay/release/",
      postDispatchRelayReleaseCheck: "/api/dispatch/relay/release/check/",
      postDispatchSlide: "/api/dispatch/slide",
      postDispatchSlideRelease: "/api/dispatch/slide/release/",
      postDispatchPatternDeploy: "api/dispatch/dispatch-pattern/deploy",
      postDispatchPatternEditValidationCard: "api/management/dispatch/pattern/edit/validation/card",
      postDragAndDrop: "api/dispatch/card/deploy",
      postDragAndDropRemove: "api/dispatch/card/remove",
      postCardDayExceed: "api/dispatch/card/deploy/day-exceed",
      postCardDayExceedCreateTemp: "api/dispatch/card/deploy/day-exceed/create-temp",
      postEditDispatchTempCard: "api/dispatch/edit/temp/card",
      postEditDispatchTempCardDayExceed: "api/dispatch/edit/temp/card/day-exceed",
      postSendMail: "api/dispatch/send-mail",
      postChangeVehicle: "api/dispatch/change/vehicle",
      postChangeCardVehicle: "api/dispatch/change/card/vehicle",
      postDynamicResult: "api/dynamic/result/driver",
      postDynamicResultRelease: "api/dynamic/result/driver/release",
      postServiceContactReportEditMeta: "api/report/client/edit/meta",
      postTransportOrderEditMeta: "api/report/delivery-company/edit/meta",
      postServiceContactReportBulkEditMeta: "api/report/client/bulkedit/meta",
      postTransportOrderBulkEditMeta: "api/report/delivery-company/bulkedit/meta",
      postBillingCard: "api/finance/billing/card/edit",
      postPaymentCard: "api/finance/payment/card/edit",
      postBillingBulkEdit: "api/finance/billing/bulk/edit",
      postPaymentBulkEdit: "api/finance/payment/bulk/edit",
      postBillingStatusBulkEdit: "api/finance/billing/bulk/edit/status",
      postPaymentStatusBulkEdit: "api/finance/payment/bulk/edit/status",
      postBillingBulkCalc: "api/finance/billing/bulk/calc",
      postPaymentBulkCalc: "api/finance/payment/bulk/calc",
      postBillingCardStatusEdit: "api/finance/billing/card/status/edit",
      postPaymentCardStatusEdit: "api/finance/payment/card/status/edit",
      postBillingConfirmed: "api/finance/billing/card/status/confirmed/",
      postPaymentConfirmed: "api/finance/payment/card/status/confirmed/",
      postPaymentStatementEditMeta: "api/finance/payment-statement/edit/meta",
      postPaymentStatementBulkEdit: "api/finance/payment-statement/bulk/status",
      deleteVehicle: "api/management/vehicle/delete",
      deleteDriver: "api/management/driver/delete",
      deleteArea: "api/management/area/delete",
      deleteBoilerplate: "api/management/boilerplate/delete",
      deleteSignature: "api/management/signature/delete",
      deleteLoadingBaseClass: "api/management/client-loading-base-class/delete",
      deleteUnloadingBaseClass: "api/management/client-unloading-base-class/delete",
      checkVehicleMaintenance: "api/management/vehicle-maintenance/check",
      checkDriverName: "api/management/vehicle-type/check",
      deleteUser: "api/management/user",
      deleteCustomer: "api/management/customer/delete",
      deleteOrder: "api/order/delete",
      deleteBjiImportOrder: "api/ccbji/import/order/delete",
      deleteBjiImportOrderList: "api/ccbji/import/order/delete/list",
      deleteBjiImportOrderDetail: "api/ccbji/import/order/detail/delete",
      deleteLoadingBase: "api/management/loading-base/delete",
      deleteUnloadingBase: "api/management/unloading-base/delete",
      deleteDeliveryCompany: "api/management/delivery-company/delete",
      deleteGeneralClient: "api/management/general-client/delete",
      deleteVehicleType: "api/management/vehicle-type/delete",
      deleteVehicleGarage: "api/management/vehicle-garage/delete",
      deleteItemType: "api/management/item-type/delete",
      deleteVehicleCarryWeight: "api/management/vehicle-carry-weight/delete",
      deleteClietnBranch: "api/management/client-branch/delete",
      deleteRole: "api/management/user-role/delete",
      deleteBillingType: "api/management/billing-type/delete",
      deleteExportTemplate: "api/management/export-template/delete",
      deleteTariff: "api/management/tariff/delete",
      deleteBillingItem: "api/management/billing-item/delete",
      deletePaymentItem: "api/management/payment-item/delete",
      deleteBaseDistance: "api/management/base-distance/delete",
      deletePaymentTariffType: "api/management/payment-tariff-type/delete",
      deletePaymentTariffRule: "api/management/payment-tariff-rule/delete",
      deleteFixedBillingRate: "api/management/fixed-billing-rate/delete",
      deleteFixedPaymentRate: "api/management/fixed-payment-rate/delete",
      deletePaymentType: "api/management/payment-type/delete",
      deleteTariffTag: "api/management/tariff/delete/tag/",
      deleteDispatchPattern: "api/management/dispatch/pattern/delete",
      importOrderFile: "api/ccbji/import/order",
      importOrderFileCommit: "api/ccbji/import/order/commit",
      importOrderWorkList: "api/ccbji/import/summary/",
      importProductFile: "api/ccbji/import/product",
      importShipmentNoFile: "api/ccbji/import/shipment-no",
      expensesImportFile: "api/finance/expenses-import/work",
      expensesImportWorkValidation: "api/finance/expenses-import/validation",
      expensesImportFileCommit: "api/finance/expenses-import/work/commit",
      expensesImportWorkList: "api/finance/expenses-import/summary",
      expensesImportWorkListCount: "api/finance/expenses-import/summary/count",
      dispatchMail: "api/dispatch/confirm/"
    };
  }
}
