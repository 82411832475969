<template lang="pug">
  section
    div.count-table 
      table(class="header-summary-table")
        tr
          th 合計車両数
          td(v-if="hasBjiCount || hasOrderCount")
            span(v-for="vehicle in vehicleTypeList" :key="vehicle.code")
              span(v-if="getTotalOrderVehicleCount(vehicle.code) > 0")
                span.vehicle-title {{ vehicle.label }}
                span :
                span.count {{ getTotalOrderVehicleCount(vehicle.code) }}
                span.count-unit 台
          td(v-else) 該当データなし

        tr
          th BJI取込車両数
          td(v-if="hasBjiCount")
            span(v-for="vehicle in vehicleTypeList" :key="vehicle.code")
              span(v-if="getImportVehicleCount(vehicle.code) > 0")
                span.vehicle-title {{ vehicle.label }}
                span :
                span.count {{ getImportVehicleCount(vehicle.code) }}
                span.count-unit 台
          td(v-else) 該当データなし
          
          th 手入力車両数
          td(v-if="hasOrderCount")
            span(v-for="vehicle in vehicleTypeList" :key="vehicle.code")
              span(v-if="getOrderVehicleCount(vehicle.code) > 0")
                span.vehicle-title {{ vehicle.label }}
                span :
                span.count {{ getOrderVehicleCount(vehicle.code) }}
                span.count-unit 台
          td(v-else) 該当データなし

        tr
          th BJI取込伝票検索一致数
          td(v-if="hasBjiCount")
            span(v-for="vehicle in vehicleTypeList" :key="vehicle.code")
              span(v-if="getImportSlipCount(vehicle.code) > 0")
                span.vehicle-title {{ vehicle.label }}
                span :
                span.count {{ getImportSlipCount(vehicle.code) }}
                span.count-unit 件
          td(v-else) 該当データなし
          
          th 手入力受注数
          td(v-if="hasOrderCount")
            span(v-for="vehicle in vehicleTypeList" :key="vehicle.code")
              span(v-if="getOrderCount(vehicle.code) > 0")
                span.vehicle-title {{ vehicle.label }}
                span :
                span.count {{ getOrderCount(vehicle.code) }}
                span.count-unit 件
          td(v-else) 該当データなし

</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getImportVehicleCount: "order/getImportVehicleCount",
      getOrderVehicleCount: "order/getOrderVehicleCount",
      getOrderCount: "order/getOrderCount",
      getImportSlipCount: "order/getImportSlipCount",
      getTotalOrderVehicleCount: "order/getTotalOrderVehicleCount",
      hasBjiCount: "order/hasBjiCount",
      hasOrderCount: "order/hasOrderCount"
    }),
    vehicleTypeList: {
      get() {
        return this.$store.state.site.vehicleTypes;
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.count {
  font-size: 1.3em;
}

.count-unit {
  margin-right: 8px;
}

.count-table {
  float: right;
}

table.header-summary-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
  font-size: 0.9em;
  margin-top: 8px;
  display: inline-block;

  td {
    height: 48px;
    border: solid #dcdcdc;
    border-width: 1px 0px;
    vertical-align: middle;
    padding-left: 16px;
    padding-right: 16px;
  }

  tr {
    height: 54px;
    transition: 0.3s;
  }

  th {
    background-color: #fff;
    top: 45px;
    z-index: 1;
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
    width: 170px;
  }
}
</style>
