<template lang="pug">
  v-dialog(v-model="dialog" :width="width" scrollable)
    v-card
      v-card-title(class="fixed")
        v-container(grid-list-lg)
          v-layout
            v-flex(xs6)
              span.headline {{ config.title }}
            slot(name="headerRight")
      v-card-text(@scroll="handleScroll" ref="SimpleDialog").result-content
        v-layout(row wrap)
          v-flex(xs12 md12)
            table.paging-master-select-dialog-table
              thead(v-on:keyup.enter="submit")
                tr
                  th(v-for="(header, index) in config.headers" :width="header.width")
                    slot(name="head" v-bind:head="header" v-bind:cond="searchConditions")
                      div(class="th-text font-weight-thin grey--text text--darken-1").header-text {{ header.text }}
                      template(v-if="!header.noSearch")
                        v-text-field(v-if="displayFilter && !header.select" v-model="searchConditions[header.key]" v-bind:class="header.align")
                        v-select(v-if="displayFilter && header.select" v-model="searchConditions[header.key]"
                          clearable :multiple="header.multipleSelect" :small-chips="header.multipleSelect"
                          :items="header.items" item-text="label" item-value="code")

              tbody
                template(v-if="displayData")
                  tr(v-for="item in tableDataList" v-show="tableDataList.length > 0" @click="selectLine(item)"
                    :class="{'record-disabled':isDisable(item)}"
                  )
                    slot(name="body" v-bind:body="item")
                      td(v-for="header in config.headers" v-bind:class="{'text-xs-center' : header.align, 'text-xs-right': header.rightAlign}") {{ item[header.key] }}
                  tr(v-show="tableDataList.length <= 0")
                    td(:colspan="config.headers.length") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
                slot(v-if="!displayData" name="noData")

              tfoot(class="footer")
</template>

<script>
import EventDispatcher from "@/mixin/EventDispatcher";
import _ from "lodash";
import * as Enum from "@/assets/js/enum";

export default {
  mixins: [EventDispatcher],
  props: {
    config: {
      type: Object,
      default() {
        return {
          title: "TEST選択",
          headers: [
            { text: "id", value: "id", align: "text-xs-center", search: "" },
            {
              text: "TEST",
              key: "name",
              align: "text-xs-center",
              search: ""
            }
          ]
        };
      }
    },
    displayData: {
      type: Boolean,
      default: true
    },
    getListActionName: {
      type: String,
      required: true
    },
    initSearchCond: {
      type: Function,
      required: true
    },
    displayFilter: {
      type: Boolean,
      default: true
    },
    disablePagingTrigger: {
      type: Boolean,
      default: false
    },
    disableSearchTrigger: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      searchConditions: {},
      pageCount: 0,
      records: [],
      dummy: [
        {
          code: "10",
          label: "ジョルダ"
        },
        {
          code: "20",
          label: "ローラ"
        }
      ]
    };
  },
  computed: {
    width() {
      return this.config.width || "50%";
    },
    /**
     * フィルタ済みデータをページ表示数分だけ表示する（スクロールすると表示行が増える）
     */
    tableDataList: {
      get() {
        return this.records;
      }
    }
  },
  watch: {
    dialog: function (isDisplay) {
      if (!isDisplay) {
        this.records = [];
      }
    },
    searchConditions: {
      handler: function () {
        if (!this.disableSearchTrigger) {
          this.debouncedSearchTrigger();
        }
      },
      deep: true
    }
  },
  created() {
    this.resetSearchCondition();
    this.debouncedSearchTrigger = _.debounce(this.searchTrigger, 500);
    this.debouncedPagingTrigger = _.debounce(this.pagingTrigger, 200);
  },
  methods: {
    resetPageCount() {
      this.pageCount = 0;
    },
    resetSearchCondition() {
      this.searchConditions = this.initSearchCond();
    },
    submit() {
      this.searchTrigger();
    },
    searchTrigger() {
      this.resetPageCount();
      this.loadData();
    },
    pagingTrigger() {
      this.pageCount++;
      this.loadData();
    },
    async loadData() {
      return this.$store
        .dispatch(this.getListActionName, Object.assign(this.searchConditions, { pageCount: this.pageCount }))
        .then(data => {
          if (this.pageCount === 0) {
            this.records = data;
          } else {
            this.records = this.records.concat(data);
          }
          return Promise.resolve(this.records);
        })
        .catch(reason => {
          return Promise.reject(reason);
        })
        .finally(() => {
          //一覧の検索やクリアボタンローディング制御の為にイベント発行。
          //this.dispatchCommonEvent(window.Common.Config.observer.onPagingListTableAjaxCompleted);
        });
    },
    openDialog() {
      this.resetPageCount();
      this.resetSearchCondition();
      this.records = [];
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    selectLine(prop) {
      this.$emit("select:record", prop);
      this.closeDialog();
    },
    /**
     * ターゲットがwindow(body)ではなく特定のDOMなので処理を書き換え
     */
    handleScroll(event) {
      const scroll = event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop;

      if (scroll < 5 && !this.disablePagingTrigger) {
        this.debouncedPagingTrigger();
      }
    },

    isDisable(item) {
      if (item.status === undefined) return false;

      return item.status === Enum.ActiveFlag.InActive.code;
    }
  }
};
</script>

<style lang="stylus" scoped>
.container {
  padding: 9px;
}

.v-card__text {
  padding-top: 0;
}

.fixed {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.result-content {
  height: 600px;
}

.header-text{
  padding 8px
  font-weight bold
}
</style>
