<template lang="pug">
  EditDialog(ref="EditDialog" title="乗務員備考" @save="save",
    :displayDeleteButton="false"
    :recordId="record.driverId"
    :loadingSave="loading.save"
    :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          // 備考
          v-flex(xs12)
            v-textarea(label="備考" auto-grow v-model="record.remark" :error-messages="getErrorMessages('remark')")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import EventDispatcher from "@/mixin/EventDispatcher";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm, EventDispatcher],
  data() {
    return {
      record: {
        driverId: 0
      },
      tranData: {},
      refItem: null
    };
  },
  props: {
    scheduledLoadingDate: {
      type: String,
      required: true
    },
    getRemarkAction: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * 初期データ
     */
    initData(item) {
      return {
        driverId: item.driverId,
        remark: null
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = {
        scheduledLoadingDate: this.scheduledLoadingDate,
        driverId: this.record.driverId,
        remark: this.record.remark
      };
    },
    openDialog(item) {
      this.record = this.initData(item);
      this.errors = {};
      this.refItem = item;
      const self = this;

      this.$store.dispatch(this.getRemarkAction, item.driverId).then(value => {
        Object.assign(self.record, value);
        this.$refs.EditDialog.openDialog();
      });
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.setTranData();

      this.saveRecord(this.tranData).then(value => {
        this.$emit("updateRemark", this.tranData);
        self.refItem.hasRemark = value.data.data;
        self.$refs.EditDialog.closeDialog();
        this.dispatchCommonEvent(window.Common.Config.observer.onDispatchPatternSelectDriverReload);
      });
    }
  }
};
</script>
