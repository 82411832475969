<template lang="pug">
  EditDialog(ref="EditDialog" title="スライド設定" @save="save" :displayDeleteButton="false" :hasTitleSuffix="false")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 v-if="isAllSlide")
            v-checkbox(label="後続のカード全てスライド" v-model="record.allSlide" :error-messages="getErrorMessages('allSlide')")
        v-layout(row wrap)
          v-flex(xs12)
            v-textarea(label="備考" auto-grow v-model="record.slideRemark" :error-messages="getErrorMessages('slideRemark')")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import { mapGetters } from "vuex";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      controls: {
        index: null
      },
      record: {},
      tranData: {}
    };
  },
  computed: {
    ...mapGetters("finance", {
      canOriginCardEdit: "canOriginCardEdit"
    }),
    isAllSlide() {
      if (this.record.cardId === null) {
        return false;
      }
      if (this.record.isSlide) {
        return false;
      }

      return true;
    }
  },
  methods: {
    /**
     * 初期データ
     */
    initData(data) {
      return {
        orderId: data.item.data.orderId,
        orderType: data.item.data.orderType,
        scheduledLoadingDate: data.loadingDate,
        withAfter: false,
        slideRemark: data.item.data.slideRemark,
        isSlide: data.item.data.isSlide,
        cardId: data.item.data.cardId
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = {
        orderId: this.record.orderId,
        orderType: this.record.orderType,
        scheduledLoadingDate: this.record.scheduledLoadingDate,
        withAfter: this.record.allSlide,
        remark: this.record.slideRemark,
        cardId: this.record.cardId
      };
    },
    /**
     * フォームの初期化
     * @param values
     */
    initForm(values) {
      this.record = Object.assign({}, this.initData(values));
      this.errors = {};
    },
    async openDialog(item) {
      this.initForm(item);
      await this.$store.dispatch("finance/fetchBillingAndPaymentCard", this.record.cardId);
      if (!this.canOriginCardEdit) {
        this.$emit("openErrorMsgBox", "確定済、発行済のカードが含まれるためスライド出来ません。");
        return;
      }
      this.$store.dispatch("dispatch/fetchSlideCardRemark", this.record).then(value => {
        if (value !== null) {
          this.record.slideRemark = value.remark;
        }
        this.$refs.EditDialog.openDialog();
      });
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      this.setTranData();
      this.saveRecord(this.tranData).then(() => {
        this.$refs.EditDialog.closeDialog();
        this.$emit("saved");
      });
    }
  }
};
</script>
