<template lang="pug">
  v-dialog(v-model="controls.dialog" persistent width="100%")
    v-card
      v-card-text
        v-container(grid-list-md)
          v-layout(row wrap)
            v-flex(xs12 md12)
              div(class="text-size") 確認完了したことを合通トラスコに連絡しました。
              div(v-if="isFirstDispatch" class="text-size") ※全運行は追ってメール差し上げます。
            v-flex.text-xs-center(xs6 offset-xs3)
              v-btn(@click="closeDialog" color="primary") 閉じる
</template>

<script>
export default {
  props: {
    isFirstDispatch: {
      type: Boolean,
      default: () => true,
      required: true
    }
  },
  data() {
    return {
      controls: {
        dialog: false
      },
      record: {
        mailType: "10"
      }
    };
  },
  methods: {
    closeDialog(item) {
      this.controls.dialog = false;
    },
    openDialog(item) {
      this.record = item;
      this.controls.dialog = true;
    }
  }
};
</script>

<style lang="stylus">
.text-size {
  font-size: 12px;
}
</style>
