import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  areaList: []
};

const getters = {};

const actions = {
  async fetchAllArea({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllAreaList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllAreaData");
        }
        commit("appendAllAreaData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchAllAreaList({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getAllAreaListNoConditions).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  async fetchScheduleGarageArea({ commit, state }, loadingDate) {
    return $axios.get(axiosBase.getUrls().getDispatchGarageArea + loadingDate).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  async fetchScheduleGarageAreaForDynamic({ commit, state }, loadingDate) {
    return $axios.get(axiosBase.getUrls().getDispatchGarageAreaForDynamic + loadingDate).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  async fetchArea({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getAllArea + id).then(value => {
      return Promise.resolve(value.data.data);
    });
  },
  // 実績出力
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportArea, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllAreaData(state) {
    state.areaList = [];
  },
  appendAllAreaData(state, data) {
    if (data != null) {
      state.areaList = state.areaList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
