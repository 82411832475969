<template lang="pug">
  SearchConditions(ref="SearchConditions" :initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      alert-box(ref="errorMsg")
      v-layout(wrap)
        v-flex(md2)
          MonthPicker(pickerLabel="請求年月" :initDate="conditions.billingTargetYearMonth" :inputDate.sync="conditions.billingTargetYearMonth")
        v-flex(md4)
          v-text-field(label="荷主" v-model="conditions.clientName")
        v-flex(md2)
          BillingStatusSelect(:code.sync="conditions.billingStatus" :filterPending="true" :clearable="false")
        v-flex(md2)
          FaxSendStatusSelect(:code.sync="conditions.faxSendStatus" :clearable="true")
        v-flex(md2)
          v-checkbox(label="FAX送信対象のみ表示" v-model="conditions.onlyFaxTarget" hide-details )
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import MonthPicker from "@/components/common/MonthPicker";
import BillingStatusSelect from "@/components/common/select/BillingStatusSelect";
import FaxSendStatusSelect from "@/components/common/select/FaxSendStatusSelect";
import AlertBox from "@/components/common/AlertBox";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    SearchConditions,
    MonthPicker,
    FaxSendStatusSelect,
    BillingStatusSelect,
    AlertBox
  },
  methods: {
    /**
     * Emit：検索条件 変更
     */
    onChangeSearchConditions(item) {
      this.nonDisplayMessage();
      this.$emit("onChangeSearchConditions", item);
    },
    /**
     * アラートボックス表示
     */
    displayMessage(errorMsg) {
      this.$refs.errorMsg.displayMessage(errorMsg);
    },
    /**
     * アラートボックス非表示
     */
    nonDisplayMessage() {
      this.$refs.errorMsg.nonDisplayMessage();
    },
    /**
     * 初期設定：子コンポーネント情報
     */
    initSearchCond() {
      return {
        conditions: {
          billingTargetYearMonth: window.Common.Util.getYearMonthIsoFormat(),
          clientName: "",
          billingStatus: Enum.BillingStatusType.TEMPORARY_CONFIRMED.code,
          faxSendStatus: "",
          onlyFaxTarget: false
        }
      };
    },
    /**
     * Event：子コンポーネントsubmit処理
     * ※最終的に子からemitでonChangeSearchConditions(item)に伝播される。
     */
    submit() {
      this.$refs.SearchConditions.submit();
    }
  }
};
</script>
