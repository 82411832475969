<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FilterConditions(@onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              GeneralClientTable(ref="GeneralClientTable" v-bind:prop-header="generalClientTableHeader" v-bind:prop-data="pagedGeneralClientList" detail-url="client/fetchClient" @open-detail="openDetail" :getListActionName="getListActionName" :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-left {{ body.clientCd }}
                  td.text-xs-left {{ body.clientName }}
                  td.text-xs-left {{ body.clientShortName }}
                  td.text-xs-left {{ body.zipCode }}
                  td.text-xs-left {{ body.prefName }}
                  td.text-xs-left {{ body.address }}
                  td.text-xs-left {{ body.tel }}
                  td.text-xs-left {{ body.fax }}
                  td.text-xs-left {{ getFaxTarget(body.billingStatementFaxTarget) }}
    GeneralClientEditDialog(ref="GeneralClientEditDialog" :postUrl="$config.urls.postEditGeneralClient" :deleteUrl="$config.urls.deleteGeneralClient" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('client/exportClient')")
</template>

<script>
import { mapGetters } from "vuex";
import GeneralClientEditDialog from "@/components/management/client/GeneralClientEdit";
import GeneralClientTable from "@/components/common/PagingListTable";
import FilterConditions from "@/components/management/client/GeneralClientFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    GeneralClientEditDialog,
    GeneralClientTable,
    FilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getFaxTarget: "site/faxTargetLabel"
    }),
    getListActionName: {
      get() {
        return "client/fetchAllGeneralClient";
      }
    },
    pagedGeneralClientList: {
      get() {
        return this.$store.state.client.generalClientList;
      }
    },
    generalClientTableHeader: {
      get() {
        return [
          { text: "荷主CD" },
          { text: "名称" },
          { text: "略称" },
          { text: "郵便番号" },
          { text: "都道府県" },
          { text: "住所" },
          { text: "電話番号" },
          { text: "FAX" },
          { text: "請求書FAX送信対象" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * ダイアログの表示
     */
    openAddDialog() {
      this.$refs.GeneralClientEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     */
    openDetail(item) {
      this.$refs.GeneralClientEditDialog.openDialog(item);
    },
    /**
     * 一覧データ取得
     */
    loadListData() {
      this.$refs.GeneralClientTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.GeneralClientTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件の取得
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.GeneralClientTable.setSearchConditions(searchCond);
      this.$refs.GeneralClientTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力確認ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  }
};
</script>
