<template lang="pug">
  section
    v-layout(row wrap)
      v-flex(xs6)
        v-select(
          v-model="p_code_func"
          :items="vehicleOptionFunctions"
          item-text="label"
          item-value="code"
          label="オプション(機能)"
          small-chips
          :clearable="clearable"
          :readonly="readonly"
          :error-messages="errorHandlerMethod('vehicleOptionIdList')")
      v-flex(xs6)
        v-select(
          v-model="p_code_floor"
          :items="vehicleOptionFloors"
          item-text="label"
          item-value="code"
          label="オプション(床)"
          small-chips
          :clearable="clearable"
          :readonly="readonly"
          :error-messages="errorHandlerMethod('floorSpec')"
        )


</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    // TODO:type:Arrayと思われるがnullの可能性があるため回避
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default() {
        return null;
      }
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters({
      vehicleOptionFunctions: "site/vehicleOptionFunctions",
      vehicleOptionFloors: "site/vehicleOptionFloors"
    }),
    p_code_floor_array: {
      get() {
        if (this.code instanceof Array) {
          return this.code.filter(v => v >= "3");
        }
        return [];
      }
    },
    p_code_func_array: {
      get() {
        if (this.code instanceof Array) {
          return this.code.filter(v => v <= "2");
        }
        return [];
      }
    },
    p_code_floor: {
      get() {
        if (this.code instanceof Array) {
          return this.code.filter(v => v >= "3").at(0);
        }
        return null;
      },
      set(newVal) {
        if (undefined !== newVal) {
          const data = this.p_code_func_array.concat(newVal);
          this.$emit("update:code", data);
        } else {
          this.$emit("update:code", this.p_code_func_array);
        }
      }
    },

    p_code_func: {
      get() {
        if (this.code instanceof Array) {
          return this.code.filter(v => v <= "2").at(0);
        }
        return null;
      },
      set(newVal) {
        if (undefined !== newVal) {
          const data = this.p_code_floor_array.concat(newVal);
          this.$emit("update:code", data);
        } else {
          this.$emit("update:code", this.p_code_floor_array);
        }
      }
    }
  },
  methods: {}
};
</script>

<style scoped></style>
