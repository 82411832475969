import { render, staticRenderFns } from "./DispatchAreaFilter.vue?vue&type=template&id=5094e549&scoped=true&lang=pug"
import script from "./DispatchAreaFilter.vue?vue&type=script&lang=js"
export * from "./DispatchAreaFilter.vue?vue&type=script&lang=js"
import style0 from "./DispatchAreaFilter.vue?vue&type=style&index=0&id=5094e549&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5094e549",
  null
  
)

export default component.exports