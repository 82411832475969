<template lang="pug">
  section
    EditDialog(ref="EditDialog" title="手入力受注履歴検索"
      width="90%"
      :hasTitleSuffix="false" :recordId="record.id"
      :displayDeleteButton="false"
      :enableSave="false"
      :scroll-fire="scrollBottomFire")
      template(v-slot:body )
        v-container(grid-list-md fluid)
          v-layout()
            LoadingWrapper(:isDisplay="apiLoading" :positionFixed="false").hogehoge
            v-flex(md12)
              v-card
                v-card-text
                  OrderHistorySearchFilterConditions(@onChangeSearchConditions="onChangeSearchConditions" ref="cond" )

          v-layout()
            v-flex(md12)
              v-card
                v-card-text
                  table.order-table.fixed-table
                    thead
                      th
                        div 荷主
                        div 枝番
                      th
                        div 積込予定日
                        div 納品予定日
                      th 積地
                      th 卸地
                      th(width="100px") 重量(Kg)
                      th(width="100px") 荷物車格
                      th(width="100px") 積荷種別
                      th 請求区分
                      th(width="130px") 備考
                    tbody
                      template(v-for="item in getOrderHistoryList")
                        tr(:key="item.detailId"  @dblclick="triggerSelect(item)").pointer-dblclick
                          td
                            div {{item.client.clientName}}
                            div {{item.client.clientBranchName}}
                          td.align-center
                            div {{item.loadingDate}}
                            div {{item.unloadingDate}}

                          td
                            template(v-for="base in item.loadingBaseList ")
                              div
                                span ({{base.baseCd}})
                                span {{base.baseName}}
                          td
                            template(v-for="base in item.unloadingBaseList ")
                              div
                                span ({{base.baseCd}})
                                span {{base.baseName}}
                          td.text-sm-right {{item.weight | addComma}}
                          td
                            template(v-for="name in item.vehicleTypeList ")
                              div.pl-2 {{name}}
                          td {{item.itemTypeName}}
                          td.text-sm-center {{item.billingType.billingTypeName}}

                          td()
                            TipsOneLineText(:text="item.remarks")
                      template(v-if="!getOrderHistoryList.length" )
                        tr
                          td(:colspan="8") {{getNotFoundMessage}}
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import SpecifiedTimeTypeIcon from "@/components/common/SpecifiedTimeTypeIcon";
import LoadingWrapper from "@/components/common/LoadingWrapper";
import OrderHistorySearchFilterConditions from "@/components/order/OrderHistorySearchFilterConditions";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import EventDispatcher from "@/mixin/EventDispatcher";

import { mapActions, mapGetters } from "vuex";
import TipsOneLineText from "@/components/common/TipsOneLineText.vue";

export default {
  components: {
    TipsOneLineText,
    SpecifiedTimeTypeIcon,
    EditDialog,
    OrderHistorySearchFilterConditions,
    LoadingWrapper
  },
  mixins: [ApiHandler, EditForm, EventDispatcher],
  data() {
    return {
      apiLoading: false,
      conditions: {}
    };
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      getOrderHistoryList: "order/getOrderHistoryList"
    }),
    getNotFoundMessage() {
      return this.$config.messages.Common.Warn.SearchResultNotFound;
    }
  },

  methods: {
    /** Action：Vuex */
    ...mapActions("order", {
      fetchAllGeneralOrderHistory: "fetchAllGeneralOrderHistory"
    }),
    /**
     * データの読み込み
     */
    loadListData() {
      this.setLoadingUp();
      const self = this;
      return this.fetchAllGeneralOrderHistory(this.conditions).finally(() => {
        self.dispatchCommonEvent(window.Common.Config.observer.onPagingListTableAjaxCompleted);
      });
    },
    triggerSelect(data) {
      this.$emit("historySelected", data.cardList[0].detailId);
    },
    onChangeSearchConditions(cond) {
      this.conditions = cond;
      this.loadListData();
    },
    scrollBottomFire() {
      this.conditions.pageCount++;
      return this.loadListData();
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
      if (this.getOrderHistoryList.length < 1) {
        this.$refs.cond.submit();
      }
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    setLoadingDown() {
      this.apiLoading = false;
    },
    setLoadingUp() {
      this.apiLoading = true;
    }
  },
  destroyed() {
    window.removeEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.setLoadingDown
    );
  },
  mounted() {
    window.addEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.setLoadingDown,
      false
    );
  }
};
</script>

<style scoped lang="stylus">
.align-center {
  text-align: center
}

.fixed-table {
  table-layout: fixed;
  position: relative;

  thead {
    th {
      background-color: #FFFFFF;
      position: sticky;
      top: -15px
    }
  }
}
</style>
