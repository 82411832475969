<template lang="pug">
  section.draggable-item-box(:class="[boxCss,cssSlideClass,cssTempCardClass,cssSameDayClass]",
  draggable="true" ,
  @mousedown="mouseDown",
  @dragstart="dragStart(argument,$event)",
  @dragend="dragEnd(argument,$event)",
  @dragenter.self="dragEnter(argument,$event)",
  @dragleave.self="dragLeave(argument,$event)",
  @drop="drop(argument,$event)",
  @dragover.prevent="dragOver(argument,$event)")
    slot(name="item" :item="item")
      p {{ item.text }}
</template>

<script>
import * as Enum from "@/assets/js/enum";
import Draggable from "@/mixin/draggable";
export default {
  props: {
    itemKey: {
      type: Number,
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      default() {
        return {
          dragSelectClass: "dragging-select",
          dragHoverClass: "dragging-hover",
          dragStartFlagClass: "dragging-start"
        };
      }
    },
    boxCss: {
      type: String,
      default() {
        return "v-card v-sheet theme--light";
      }
    },
    driverId: {
      type: Number,
      required: false,
      default: null
    }
  },
  mixins: [Draggable],
  data() {
    return {
      dragElement: {
        target: null,
        dragging: null
      },
      htmlBody: document.body
    };
  },
  computed: {
    argument: {
      get() {
        return { itemKey: this.itemKey, data: this.item, driverId: this.driverId };
      }
    },
    cssSlideClass() {
      return this.item.isSlide ? "slide-card-box" : "";
    },
    cssTempCardClass() {
      return this.item.orderType == Enum.OrderType.TempCard.code ? "dispatch-temp-card" : "";
    },
    cssSameDayClass() {
      return this.item.isSameDaySlide ? "slide-card-same-day" : "";
    }
  }
};
</script>
