<template lang="pug">
  EditDialog(ref="EditDialog" title="権限" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)

        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field(v-model="record.roleName" label="権限名称" :error-messages="getErrorMessages('roleName')")

        v-layout(row wrap)
          v-flex(xs12 md5)
            v-list(two-line)
              v-list-tile(v-if="record.accesses.list != []" v-for="(item, index) in functionList")
                v-list-tile-content(v-if="record.accesses != null") {{ item.name }}

          v-flex(xs12 md7)
            v-list(two-line)
              v-list-tile(v-for="(item, index) in functionList")
                v-radio-group(v-if="record.accesses.list[index]" row v-model="record.accesses.list[index].roleBit" :error-messages="getErrorMessages('accesses.list[' + index + '].roleBit')")
                  v-radio(
                    v-for="(radioButtonItem, index) in radioButtonItems"
                    :key="index"
                    v-bind="radioButtonItem"
                  )

</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "../../../mixin/ApiHandler";
import EditForm from "../../../mixin/EditForm";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {
        id: null,
        roleName: null,
        accesses: {
          list: []
        }
      },
      radioButtonItems: [
        { value: 0, label: "閲覧不可" },
        { value: 1, label: "閲覧可能" },
        { value: 2, label: "編集可能" }
      ]
    };
  },
  computed: {
    functionList: {
      get() {
        return this.$store.state.site.functionList;
      }
    }
  },
  mounted() {
    this.loadFunctionData();
  },
  methods: {
    openDialog(item) {
      this.record.accesses.list = [];
      this.initForm(item);
      this.addFunctionList(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(() => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(() => {
        self.closeDialog();
      });
    },
    addFunctionList(item) {
      if (!item && this.record.accesses.list.length === 0) {
        for (let i = 0; i < this.functionList.length; i++) {
          this.record.accesses.list.push(this.functionDefault());
          this.record.accesses.list[i].function = this.functionList[i];
        }
      } else if (this.record.accesses.list.length !== this.functionList.length) {
        for (let i = 0; i < this.functionList.length; i++) {
          // 既存権限で新規追加機能のラジオボタン表示対応
          if (!this.record.accesses.list.find(el => el.function.code === this.functionList[i].code)) {
            this.record.accesses.list.push(this.functionDefault());
            this.record.accesses.list[i].function = this.functionList[i];
          }
        }
      }
    },
    functionDefault() {
      return {
        function: { functionId: null, name: null },
        roleBit: 0
      };
    },
    async loadFunctionData() {
      await this.$store.dispatch("site/fetchAllFunction");
    }
  }
};
</script>
