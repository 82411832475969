<template lang="pug">
  v-dialog(v-model="dialog" persistent width="500" @keydown.esc="no")
    v-card
      v-toolbar(dark :color="$store.getters['site/siteColor']")
        v-icon(left color="white darken-2" large) warning
        span  {{ title }}
        v-spacer
        v-btn(flat icon @click="closeDialog")
          v-icon close

      v-card-text
        v-layout
          v-flex
            div(class="ma-3") {{ getMessage }}

      v-toolbar.footer
        v-spacer
        v-btn(color="" @click="no") キャンセル
        v-btn(color="primary" @click="yes") 変更する
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "注意"
    },
    message: {
      type: String,
      required: false,
      default: "「仮確定」のカードを変更すると状態が「未確定」に戻ります。内容を変更しますか？"
    },
    billingMode: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      text: null,
      dispMessage: ""
    };
  },
  computed: {
    ...mapGetters("finance", ["isBillingCardOverride", "isPaymentCardOverride"]),
    getMessage: {
      get() {
        return this.dispMessage ? this.dispMessage : this.message;
      }
    }
  },
  methods: {
    ...mapActions("finance", [
      "fetchOriginCardEditable",
      "fetchCheckBulk",
      "fetchCheckBulkStatus",
      "fetchCheckBillingStatusByKey",
      "fetchCheckPaymentStatusByKey"
    ]),
    async checkEditableFinanceInfo(cardId) {
      //カードIDが渡されなかったらチェックなしでOKしたものとする
      if (cardId === null || cardId === undefined) {
        this.yes();
        return;
      }

      const self = this;
      await this.fetchOriginCardEditable(cardId).then(() => {
        if (self.billingMode) {
          if (self.isBillingCardOverride) {
            self.openDialog();
          } else {
            self.yes();
          }
        } else {
          if (self.isPaymentCardOverride) {
            self.openDialog();
          } else {
            self.yes();
          }
        }
      });
    },
    async checkEditableBulk(searchCond) {
      const self = this;
      await this.fetchCheckBulk(searchCond).then(value => {
        if (self.billingMode) {
          if (value.editableExistsPublishedBillingInfo) {
            self.openDialog();
          } else if (value.editableBillingInfo) {
            self.yes();
          } else if (value.existsPublishedBillingInfo) {
            this.$emit("NotEditablePublished");
          } else if (value.existsConfirmedBillingInfo) {
            this.$emit("NotEditableConfirmed");
          }
        } else {
          if (value.editableExistsPublishedPaymentInfo) {
            self.openDialog();
          } else if (value.editablePaymentInfo) {
            self.yes();
          } else if (value.existsPublishedPaymentInfo) {
            this.$emit("NotEditablePublished");
          } else if (value.existsConfirmedPaymentInfo) {
            this.$emit("NotEditableConfirmed");
          }
        }
      });
    },
    /**
     * 一括更新用（金額等）事前確認
     */
    async checkEditableBulkNotEditPublished(searchCond) {
      const self = this;
      await this.fetchCheckBulk(searchCond).then(value => {
        if (self.billingMode) {
          if (value.editableBillingInfo) {
            self.yes();
          } else if (value.editableExistsPublishedBillingInfo || value.existsPublishedBillingInfo) {
            this.$emit("NotEditablePublished");
          } else if (value.existsConfirmedBillingInfo) {
            this.$emit("NotEditableConfirmed");
          }
        } else {
          if (value.editablePaymentInfo) {
            self.yes();
          } else if (value.editableExistsPublishedPaymentInfo || value.existsPublishedPaymentInfo) {
            this.$emit("NotEditablePublished");
          } else if (value.existsConfirmedPaymentInfo) {
            this.$emit("NotEditableConfirmed");
          }
        }
      });
    },
    async checkEditableBulkStatus(searchCond) {
      const self = this;
      await this.fetchCheckBulkStatus(searchCond).then(value => {
        if (self.billingMode) {
          if (value.existsPublishedBillingInfo) {
            self.openDialog();
          } else if (value.existsConfirmedBillingInfo) {
            this.$emit("NotEditableConfirmed");
          } else {
            self.yes();
          }
        } else {
          if (value.existsPublishedPaymentInfo) {
            self.openDialog();
          } else if (value.existsConfirmedPaymentInfo) {
            this.$emit("NotEditableConfirmed");
          } else {
            self.yes();
          }
        }
      });
    },
    async checkBillingStatusByKey(cardId) {
      //カードIDが渡されなかったらチェックなしでOKしたものとする
      if (cardId === null || cardId === undefined) {
        this.yes();
        return;
      }

      const self = this;
      await this.fetchCheckBillingStatusByKey(cardId).then(value => {
        if (value.existsConfirmedBillingInfo) {
          this.$emit("NotEditableConfirmed");
        } else if (value.existsPublishedBillingInfo) {
          self.openDialog();
        } else {
          this.yes();
        }
      });
    },
    async checkPaymentStatusByKey(cardId) {
      //カードIDが渡されなかったらチェックなしでOKしたものとする
      if (cardId === null || cardId === undefined) {
        this.yes();
        return;
      }

      const self = this;
      await this.fetchCheckPaymentStatusByKey(cardId).then(value => {
        if (value.existsConfirmedPaymentInfo) {
          this.$emit("NotEditableConfirmed");
        } else if (value.existsPublishedPaymentInfo) {
          self.openDialog();
        } else {
          this.yes();
        }
      });
    },
    openDialog() {
      this.dispMessage = "";
      this.dialog = true;
    },
    openDialogWithMessage(message) {
      this.dispMessage = message;
      this.dialog = true;
    },
    closeDialog() {
      this.$emit("close");
      this.dialog = false;
    },
    no() {
      this.$emit("no");
      this.closeDialog();
    },
    yes() {
      this.$emit("yes");
      this.dialog = false;
    }
  }
};
</script>
