<template lang="pug">
  section
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogBilling" @yes="onSaveBilling" @close="loading = false" message="" @NotEditablePublished="NotEditablePublishedBilling" @NotEditableConfirmed="NotEditableConfirmedBilling")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogPayment" @yes="onSavePayment" @close="loading = false" message="" @NotEditablePublished="NotEditablePublishedPayment" @NotEditableConfirmed="NotEditableConfirmedPayment" :billingMode="false")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogBillingStatus" @yes="onSaveBillingStatusCheck" @close="loading = false" message="発行済を変更しますがよろしいですか?" @NotEditablePublished="NotEditablePublishedBillingSelect" @NotEditableConfirmed="NotEditableConfirmedBilling")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogPaymentStatus" @yes="onSavePaymentStatusCheck" @close="loading = false" message="発行済を変更しますがよろしいですか?" @NotEditablePublished="NotEditablePublishedPaymentSelect" @NotEditableConfirmed="NotEditableConfirmedPayment" :billingMode="false")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogBillingCalc" @yes="onClickCalcBilling" @close="loading = false" message="" @NotEditablePublished="NotEditablePublishedBilling" @NotEditableConfirmed="NotEditableConfirmedBillingCalc")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogPaymentCalc" @yes="onClickCalcPayment" @close="loading = false" message="" @NotEditablePublished="NotEditablePublishedPayment" @NotEditableConfirmed="NotEditableConfirmedPaymentCalc" :billingMode="false")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogBillingStatusCheck" @yes="onSaveBillingStatus" @close="loading = false" message="同一キー(荷主・荷主枝番・請求対象年月)の発行対象カードすべて仮確定に変更しますがよろしいですか？" @NotEditableConfirmed="NotEditableConfirmedStatementBilling")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialogPaymentStatusCheck" @yes="onSavePaymentStatus" @close="loading = false" message="同一キー(1次配送委託先・支払対象年月)の発行対象カードすべて仮確定に変更しますがよろしいですか？" @NotEditableConfirmed="NotEditableConfirmedStatementPayment" :billingMode="false")
    EditDialog(ref="EditDialog" title="カード請求支払一括変更" width="70%"
      :recordId="detail.id"
      :loadingSave="loading.save"
      labelSaveButton="一括反映"
      @save=""
      :displayDeleteButton="false"
      :enableSave="false"
      :hasTitleSuffix="false")
      template(v-slot:body)
        v-card
          v-card-text
            v-container(grid-list-md)
              v-layout
                v-flex(xs6)
                  v-card
                    v-card-title.billing-bg-color
                      v-flex(xs2)
                        span.title-text 請求情報
                      v-spacer
                    v-card-text
                      AlertBox(ref="BillingAlertBox")
                      v-layout(justify-end)
                        v-flex(xs12)
                          span 請求状態の一括変更
                          v-layout
                            v-flex(xs7)
                              v-radio-group(row v-model="detail.billing.billingStatus" :error-messages="getErrorMessages()" @change="changeBillingStatus")
                                v-radio(
                                  v-for="(radioButtonItem, index) in radioButtonItemsForBilling"
                                  :key="index"
                                  v-bind="radioButtonItem"
                                )
                            v-flex(xs3)
                              v-btn(:disabled="control.isDisabledBillingStatus" color="success" @click="onSaveBillingStatusPre" :loading="loading")
                                span 状態変更

                          v-layout
                            v-flex(xs12)
                              v-card
                                v-card-text
                                  v-layout(wrap)
                                    template(v-for="(billing,index) in billingCardDetail")
                                      v-flex(xs3)
                                        DigitSeparatorsField(v-model="billing.billingItemPrice" :label="billing.billItemName" @changeItem="changeBillingCard"
                                          :errorMessages="getErrorMessages('billing.billingItemPrice')")

                      v-layout(justify-end)
                        v-flex(xs4)
                          MonthPicker(pickerLabel="請求対象年月" :initDate="detail.billing.billingTargetYearMonth" :inputDate.sync="detail.billing.billingTargetYearMonth" :clearable="true" @changeItem="changeBillingCard")
                        v-flex(xs4)
                          BillingTypeSelect(:code.sync="detail.billing.billingTypeId" :errorHandlerMethod="getErrorMessages" @changeItem="changeBillingCard")
                        v-flex(xs4)
                          VehicleTypeSelect(:code.sync="detail.billing.vehicleTypeId" label="請求車格(変更する場合)" :clearable="true" @changeItem="changeBillingCard")
                      v-layout(justify-end)
                        v-flex(xs2 v-if="billingMode")
                          ProgressBar
                        v-flex(xs3 v-if="billingMode")
                          span.ml-1 {{ getLoadingMessage }}
                        v-flex(xs2)
                          v-btn(round @click="clearInitBilling" :loading="loading").d-inline-block
                            span クリア
                        v-flex(xs2)
                          v-btn(color="success" round @click="openConfirmDialogBilling" :loading="loading" :disabled="control.isDisabledBillingSave").d-inline-block
                            span 一括更新
                        v-flex(xs2)
                          v-btn(color="info" round @click="onCalcBillingPre" :loading="loading").d-inline-block
                            span 再計算

                v-flex(xs6)
                  v-card
                    v-card-title.payment-bg-color
                      v-flex(xs2)
                        span.title-text 支払情報
                      v-spacer
                    v-card-text
                      AlertBox(ref="PaymentAlertBox")
                      v-layout(justify-end)
                        v-flex(xs12)
                          span 支払状態の一括変更
                          v-layout
                            v-flex(xs7)
                              v-radio-group(row v-model="detail.payment.paymentStatus" :error-messages="getErrorMessages()" @change="changePaymentStatus")
                                v-radio(
                                  v-for="(radioButtonItem, index) in radioButtonItemsForPayment"
                                  :key="index"
                                  v-bind="radioButtonItem"
                                )
                            v-flex(xs3)
                              v-btn(:disabled="control.isDisabledPaymentStatus" color="success" @click="onSavePaymentStatusPre" :loading="loading")
                                span 状態変更

                          v-layout
                            v-flex(xs12)
                              v-card
                                v-card-text
                                  v-layout(wrap)
                                    template(v-for="(payment,index) in paymentCardDetail")
                                      v-flex(xs3)
                                        DigitSeparatorsField(v-model="payment.paymentItemPrice" :label="payment.paymentItemName" @changeItem="changePaymentCard"
                                          :errorMessages="getErrorMessages('payment.paymentItemPrice')")

                      v-layout(justify-end)
                        v-flex(xs4)
                          MonthPicker(pickerLabel="支払対象年月" :initDate="detail.payment.paymentTargetYearMonth"  :inputDate.sync="detail.payment.paymentTargetYearMonth" :clearable="true" @changeItem="changePaymentCard")
                        v-flex(xs4)
                          PaymentTypeSelect(:code.sync="detail.payment.paymentTypeId" :errorHandlerMethod="getErrorMessages" @changeItem="changePaymentCard")
                        v-flex(xs4)
                          VehicleTypeSelect(:code.sync="detail.payment.vehicleTypeId" label="支払車格(変更する場合)" :clearable="true" @changeItem="changePaymentCard")
                      v-layout(justify-end)
                        v-flex(xs2 v-if="!billingMode")
                          ProgressBar
                        v-flex(xs3 v-if="!billingMode")
                          span.ml-1 {{ getLoadingMessage }}
                        v-flex(xs2)
                          v-btn(round @click="clearInitPayment" :loading="loading").d-inline-block
                            span クリア
                        v-flex(xs2)
                          v-btn(color="success" round @click="openConfirmDialogPayment" :loading="loading" :disabled="control.isDisabledPaymentSave").d-inline-block
                            span 一括更新
                        v-flex(xs2)
                          v-btn(color="info" round @click="onCalcPaymentPre" :loading="loading").d-inline-block
                            span 再計算

    ConfirmDialog(ref="ConfirmDialogBillingStatus" @yes="onSaveBillingStatusPre" title="一括反映確認" message="請求状態を一括で対象に設定を反映します、よろしいですか？" yesButtonTitle="反映する")
    ConfirmDialog(ref="ConfirmDialogPaymentStatus" @yes="onSavePaymentStatusPre" title="一括反映確認" message="支払状態を一括で対象に設定を反映します、よろしいですか？" yesButtonTitle="反映する")
    ConfirmDialog(ref="ConfirmDialogBilling" @yes="onSaveBillingPre" title="一括反映確認" message="請求情報を一括で対象に設定を反映します、よろしいですか？（変更後は未確定に戻ります）" yesButtonTitle="反映する")
    ConfirmDialog(ref="ConfirmDialogPayment" @yes="onSavePaymentPre" title="一括反映確認" message="支払情報を一括で対象に設定を反映します、よろしいですか？（変更後は未確定に戻ります）" yesButtonTitle="反映する")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import EditDialog from "@/components/common/dialog/EditDialog";
import SelectBoilerplate from "@/components/report/SelectBoilerplate";
import SelectSignature from "@/components/report/SelectSignature";
import ConfirmDialog from "@/components/common/dialog/ConfirmDialog";
import DigitSeparatorsField from "@/components/common/DigitSeparatorsField";
import MonthPicker from "@/components/common/MonthPicker";
import BillingTypeSelect from "@/components/common/select/BillingTypeSelect";
import PaymentTypeSelect from "@/components/common/select/PaymentTypeSelect";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import FinanceOverrideWarningDialog from "@/components/common/dialog/FinanceOverrideWarningDialog";
import AlertBox from "@/components/common/AlertBox";
import { mapActions, mapGetters } from "vuex";
import ProgressBar from "@/components/common/ProgressBar";

import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    SelectBoilerplate,
    SelectSignature,
    ConfirmDialog,
    DigitSeparatorsField,
    BillingTypeSelect,
    PaymentTypeSelect,
    MonthPicker,
    VehicleTypeSelect,
    DigitSeparatorsLabel,
    FinanceOverrideWarningDialog,
    AlertBox,
    ProgressBar
  },
  mixins: [ApiHandler],
  props: {
    searchCond: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    // 更新情報
    detail: {},
    loading: false,
    radioButtonItems: [
      { value: 0, label: "未確定" },
      { value: 1, label: "仮確定" }
    ],
    control: {
      isDisabledBillingSave: true,
      isDisabledPaymentSave: true,
      isDisabledBillingStatus: true,
      isDisabledPaymentStatus: true
    },
    billingMode: true,
    billingPublishedMessage:
      "対象に請求状態が発行済のカードが含まれています。発行済カードは変更する事ができません。選択をやり直してください",
    paymentPublishedMessage:
      "対象に支払状態が発行済のカードが含まれています。発行済カードは変更する事ができません。選択をやり直してください",
    billingPublishedSelectMessage:
      "対象に請求状態が発行済のカードが含まれています。発行済カードを一括変更するには発行済カードだけを選択してください",
    paymentPublishedSelectMessage:
      "対象に支払状態が発行済のカードが含まれています。発行済カードを一括変更するには発行済カードだけを選択してください",
    confirmedMessage:
      "対象に確定済カードが含まれています。確定済カードは状態を変更する事ができません。選択をやり直してください",
    confirmedCalcMessage:
      "対象に確定済カードが含まれています。確定済カードは再計算できません。選択をやり直してください",
    confirmedStatementMessage:
      "対象の発行単位のカードに確定済カードが含まれています。確定済カードは状態を変更する事ができません。選択をやり直してください"
  }),
  computed: {
    ...mapGetters({
      billingCardDetail: "finance/billingCardDetail",
      paymentCardDetail: "finance/paymentCardDetail",
      getLoadingMessage: "indicator/getLoadingMessage"
    }),
    canTargetBillingComplete: {
      get() {
        return (
          this.searchCond.billingStatus === Enum.BillingStatusType.TEMPORARY_CONFIRMED.code &&
          this.searchCond.billingCondition !== null &&
          this.searchCond.billingCondition[0] !== undefined &&
          this.searchCond.billingCondition[0] === Enum.FinanceType.None.code
        );
      }
    },
    canTargetPaymentComplete: {
      get() {
        return (
          this.searchCond.paymentStatus === Enum.PaymentStatusType.TEMPORARY_CONFIRMED.code &&
          this.searchCond.paymentCondition !== null &&
          this.searchCond.paymentCondition[0] !== undefined &&
          this.searchCond.paymentCondition[0] === Enum.FinanceType.None.code
        );
      }
    },
    radioButtonItemsForBilling: {
      get() {
        if (this.canTargetBillingComplete) {
          return [
            { value: Enum.BillingStatusType.PENDING.code, label: Enum.BillingStatusType.PENDING.label },
            {
              value: Enum.BillingStatusType.TEMPORARY_CONFIRMED.code,
              label: Enum.BillingStatusType.TEMPORARY_CONFIRMED.label
            },
            { value: Enum.BillingStatusType.CONFIRMED.code, label: Enum.BillingStatusType.CONFIRMED.label }
          ];
        } else {
          return [
            { value: Enum.BillingStatusType.PENDING.code, label: Enum.BillingStatusType.PENDING.label },
            {
              value: Enum.BillingStatusType.TEMPORARY_CONFIRMED.code,
              label: Enum.BillingStatusType.TEMPORARY_CONFIRMED.label
            }
          ];
        }
      }
    },
    radioButtonItemsForPayment: {
      get() {
        if (this.canTargetPaymentComplete) {
          return [
            { value: Enum.PaymentStatusType.PENDING.code, label: Enum.PaymentStatusType.PENDING.label },
            {
              value: Enum.PaymentStatusType.TEMPORARY_CONFIRMED.code,
              label: Enum.PaymentStatusType.TEMPORARY_CONFIRMED.label
            },
            { value: Enum.PaymentStatusType.CONFIRMED.code, label: Enum.PaymentStatusType.CONFIRMED.label }
          ];
        } else {
          return [
            { value: Enum.PaymentStatusType.PENDING.code, label: Enum.PaymentStatusType.PENDING.label },
            {
              value: Enum.PaymentStatusType.TEMPORARY_CONFIRMED.code,
              label: Enum.PaymentStatusType.TEMPORARY_CONFIRMED.label
            }
          ];
        }
      }
    }
  },
  methods: {
    ...mapActions("finance", {
      fetchBillingCard: "fetchBillingCard",
      fetchPaymentCard: "fetchPaymentCard",
      fetchBillingAndPaymentList: "fetchBillingAndPaymentList",
      fetchBillingAndPaymentListSum: "fetchBillingAndPaymentListSum",
      postBillingBulkEdit: "postBillingBulkEdit",
      postPaymentBulkEdit: "postPaymentBulkEdit",
      postPaymentBulkCalc: "postPaymentBulkCalc",
      postBillingBulkCalc: "postBillingBulkCalc",
      postBillingStatusBulkEdit: "postBillingStatusBulkEdit",
      postPaymentStatusBulkEdit: "postPaymentStatusBulkEdit"
    }),
    /**
     * 初期データ
     */
    initData() {
      return {
        detail: {
          billing: {
            billingTargetYearMonth: null,
            billingTypeId: null,
            vehicleTypeId: null,
            billingStatus: null
          },
          payment: {
            paymentTargetYearMonth: null,
            paymentTypeId: null,
            vehicleTypeId: null,
            paymentStatus: null
          }
        }
      };
    },
    clearInitBilling() {
      this.detail.billing = this.initData().detail.billing;
      this.fetchBillingCard(0);
      this.control.isDisabledBillingSave = true;
      this.control.isDisabledBillingStatus = true;
    },
    clearInitPayment() {
      this.detail.payment = this.initData().detail.payment;
      this.fetchPaymentCard(0);
      this.control.isDisabledPaymentSave = true;
      this.control.isDisabledPaymentStatus = true;
    },
    /**
     * Event：ダイアログを開く
     * ※親→子（このコンポーネント）→孫（slot利用）すべて参照利用。
     */
    openDialog() {
      this.$refs.BillingAlertBox.nonDisplayMessage();
      this.$refs.PaymentAlertBox.nonDisplayMessage();
      this.detail = this.initData().detail;
      this.control.isDisabledBillingSave = true;
      this.control.isDisabledPaymentSave = true;
      this.control.isDisabledBillingStatus = true;
      this.control.isDisabledPaymentStatus = true;
      //請求項目の取得。存在しないＩＤを指定して取得するとテンプレートデータ（請求項目マスタベース）を取得
      this.fetchBillingCard(0);
      //支払項目の取得。存在しないＩＤを指定して取得するとテンプレートデータ（請求項目マスタベース）を取得
      this.fetchPaymentCard(0);
      // mixin:ApiHandlerに情報を設定
      this.errors = {};
      this.$refs.EditDialog.openDialog();
    },
    openConfirmDialogBilling() {
      this.$refs.BillingAlertBox.nonDisplayMessage();
      this.$refs.ConfirmDialogBilling.openDialog();
    },
    openConfirmDialogPayment() {
      this.$refs.PaymentAlertBox.nonDisplayMessage();
      this.$refs.ConfirmDialogPayment.openDialog();
    },
    openConfirmDialogBillingStatus() {
      this.$refs.BillingAlertBox.nonDisplayMessage();
      this.$refs.ConfirmDialogBillingStatus.openDialog();
    },
    openConfirmDialogPaymentStatus() {
      this.$refs.PaymentAlertBox.nonDisplayMessage();
      this.$refs.ConfirmDialogPaymentStatus.openDialog();
    },
    /**
     * 対象が更新可能か確認
     */
    async onSaveBillingPre() {
      this.loading = true;
      const searchCondition = this.searchCond;
      this.$refs.FinanceOverrideWarningDialogBilling.checkEditableBulkNotEditPublished(searchCondition).catch(
        error => {
          this.loading = false;
        }
      );
    },
    /**
     * 対象が更新可能か確認
     */
    async onSavePaymentPre() {
      this.loading = true;
      this.billingMode = false;
      const searchCondition = this.searchCond;
      this.$refs.FinanceOverrideWarningDialogPayment.checkEditableBulkNotEditPublished(searchCondition).catch(
        error => {
          this.loading = false;
          this.billingMode = true;
        }
      );
    },
    /**
     * 対象が更新可能か確認
     */
    async onSaveBillingStatusPre() {
      this.loading = true;
      const searchCondition = this.searchCond;
      this.$refs.FinanceOverrideWarningDialogBillingStatus.checkEditableBulk(searchCondition).catch(error => {
        this.loading = false;
      });
    },
    /**
     * 対象が更新可能か確認
     */
    async onSavePaymentStatusPre() {
      this.loading = true;
      this.billingMode = false;
      const searchCondition = this.searchCond;
      this.$refs.FinanceOverrideWarningDialogPaymentStatus.checkEditableBulk(searchCondition).catch(error => {
        this.loading = false;
        this.billingMode = true;
      });
    },
    /**
     * 対象が請求書単位で更新可能か確認
     */
    async onSaveBillingStatusCheck() {
      // 仮確定選択時
      if (this.detail.billing.billingStatus === 1) {
        const searchCondition = this.searchCond;
        this.$refs.FinanceOverrideWarningDialogBillingStatusCheck.checkEditableBulkStatus(
          searchCondition
        ).catch(error => {
          this.loading = false;
        });
      } else {
        this.$refs.FinanceOverrideWarningDialogBillingStatusCheck.yes();
      }
    },
    /**
     * 対象が支払書単位で更新可能か確認
     */
    async onSavePaymentStatusCheck() {
      // 仮確定選択時
      if (this.detail.payment.paymentStatus === 1) {
        const searchCondition = this.searchCond;
        this.$refs.FinanceOverrideWarningDialogPaymentStatusCheck.checkEditableBulkStatus(
          searchCondition
        ).catch(error => {
          this.loading = false;
          this.billingMode = true;
        });
      } else {
        this.$refs.FinanceOverrideWarningDialogPaymentStatusCheck.yes();
      }
    },
    /**
     * 請求状態一括保存
     */
    async onSaveBillingStatus() {
      let searchCondition = this.searchCond;
      await this.postBillingStatusBulkEdit({ searchCondition, ...this.detail.billing })
        .then(value => {
          this.$refs.BillingAlertBox.displaySuccessMessage(value.message);
          // 更新時は再検索してダイアログ閉じる
          searchCondition.pageCount = 0;
          this.fetchBillingAndPaymentList(searchCondition);
          this.fetchBillingAndPaymentListSum(searchCondition);
          this.$refs.EditDialog.closeDialog();
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 支払状態一括保存
     */
    async onSavePaymentStatus() {
      let searchCondition = this.searchCond;
      await this.postPaymentStatusBulkEdit({ searchCondition, ...this.detail.payment })
        .then(value => {
          this.$refs.PaymentAlertBox.displaySuccessMessage(value.message);
          // 更新時は再検索してダイアログ閉じる
          searchCondition.pageCount = 0;
          this.fetchBillingAndPaymentList(searchCondition);
          this.fetchBillingAndPaymentListSum(searchCondition);
          this.$refs.EditDialog.closeDialog();
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false;
          this.billingMode = true;
        });
    },
    /**
     * 保存：一括設定用署名と定型文
     */
    async onSaveBilling() {
      let searchCondition = this.searchCond;
      await this.postBillingBulkEdit({ searchCondition, ...this.detail.billing })
        .then(value => {
          this.$refs.BillingAlertBox.displaySuccessMessage(value.message);
          // 更新時は再検索してダイアログ閉じる
          searchCondition.pageCount = 0;
          this.fetchBillingAndPaymentList(searchCondition);
          this.fetchBillingAndPaymentListSum(searchCondition);
          this.$refs.EditDialog.closeDialog();
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            self.setErrorMessages(error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 保存：一括設定用署名と定型文
     */
    async onSavePayment() {
      let searchCondition = this.searchCond;
      await this.postPaymentBulkEdit({ searchCondition, ...this.detail.payment })
        .then(value => {
          this.$refs.PaymentAlertBox.displaySuccessMessage(value.message);
          // 更新時は再検索してダイアログ閉じる
          searchCondition.pageCount = 0;
          this.fetchBillingAndPaymentList(searchCondition);
          this.fetchBillingAndPaymentListSum(searchCondition);
          this.$refs.EditDialog.closeDialog();
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            self.setErrorMessages(error);
          }
        })
        .finally(() => {
          this.loading = false;
          this.billingMode = true;
        });
    },
    /**
     * 対象が再計算可能か確認
     */
    async onCalcPaymentPre() {
      this.loading = true;
      this.billingMode = false;
      const searchCondition = this.searchCond;
      this.$refs.FinanceOverrideWarningDialogPaymentCalc.checkEditableBulkNotEditPublished(
        searchCondition
      ).catch(error => {
        this.loading = false;
        this.billingMode = true;
      });
    },
    /**
     * 対象が再計算可能か確認
     */
    async onCalcBillingPre() {
      this.loading = true;
      const searchCondition = this.searchCond;
      this.$refs.FinanceOverrideWarningDialogBillingCalc.checkEditableBulkNotEditPublished(
        searchCondition
      ).catch(error => {
        this.$refs.BillingAlertBox.displayMessage(error.response.data.message);
        this.loading = false;
      });
    },
    async onClickCalcBilling() {
      this.loading = true;
      this.$refs.BillingAlertBox.nonDisplayMessage();
      const searchCondition = this.searchCond;
      this.postBillingBulkCalc(searchCondition)
        .then(value => {
          this.$refs.BillingAlertBox.displaySuccessMessage(value.message);
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async onClickCalcPayment() {
      this.loading = true;
      this.$refs.PaymentAlertBox.nonDisplayMessage();
      const searchCondition = this.searchCond;
      this.postPaymentBulkCalc(searchCondition)
        .then(value => {
          this.$refs.PaymentAlertBox.displaySuccessMessage(value.message);
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false;
          this.billingMode = true;
        });
    },
    NotEditableConfirmedStatementBilling() {
      this.$refs.BillingAlertBox.displayMessage(this.confirmedStatementMessage);
      this.loading = false;
    },
    NotEditableConfirmedStatementPayment() {
      this.$refs.PaymentAlertBox.displayMessage(this.confirmedStatementMessage);
      this.loading = false;
      this.billingMode = true;
    },
    NotEditableConfirmedBilling() {
      this.$refs.BillingAlertBox.displayMessage(this.confirmedMessage);
      this.loading = false;
    },
    NotEditableConfirmedBillingCalc() {
      this.$refs.BillingAlertBox.displayMessage(this.confirmedCalcMessage);
      this.loading = false;
    },
    NotEditableConfirmedPayment() {
      this.$refs.PaymentAlertBox.displayMessage(this.confirmedMessage);
      this.loading = false;
      this.billingMode = true;
    },
    NotEditableConfirmedPaymentCalc() {
      this.$refs.PaymentAlertBox.displayMessage(this.confirmedCalcMessage);
      this.loading = false;
      this.billingMode = true;
    },
    NotEditablePublishedBilling() {
      this.$refs.BillingAlertBox.displayMessage(this.billingPublishedMessage);
      this.loading = false;
    },
    NotEditablePublishedPayment() {
      this.$refs.PaymentAlertBox.displayMessage(this.paymentPublishedMessage);
      this.loading = false;
      this.billingMode = true;
    },
    NotEditablePublishedBillingSelect() {
      this.$refs.BillingAlertBox.displayMessage(this.billingPublishedSelectMessage);
      this.loading = false;
    },
    NotEditablePublishedPaymentSelect() {
      this.$refs.PaymentAlertBox.displayMessage(this.paymentPublishedSelectMessage);
      this.loading = false;
      this.billingMode = true;
    },
    changeBillingCard() {
      this.control.isDisabledBillingSave = false;
    },
    changePaymentCard() {
      this.control.isDisabledPaymentSave = false;
    },
    changeBillingStatus() {
      this.control.isDisabledBillingStatus = false;
    },
    changePaymentStatus() {
      this.control.isDisabledPaymentStatus = false;
    }
  },
  beforeMount() {
    this.detail = this.initData().detail;
  }
};
</script>

<style scoped lang="stylus">
.title-text {
  font-size 16px
  font-weight bold
}
</style>
