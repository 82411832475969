<template lang="pug">
  EditDialog(ref="EditDialog" :width="width" :enableSave="false" :displayDeleteButton="false" :displayUpdateInfo="false"
    contentClass="history-diff-dialog" @closeEditDialog="terminate" :title="title" :hasTitleSuffix="false")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap).diff-dialog
          v-flex(md4)
            section.hisotry-body-section(ref="historyArea")
              slot(name="left")
                table.history-index-list
                  tr
                    th 更新日時
                    th 更新者
                  template(v-for="(rec,index) in leftData")
                    tr(@click="selectLine(rec,index)" :class="getSelectLineCss(index)").history
                      td {{formatFullDateTime(rec.updateUser.lastUpdated)}}
                      td {{rec.updateUser.lastUpdateUserName}}
          v-flex(md8)
            section(v-if="newUpdateUser")
              v-card.history-detail-card
                v-card-text
                  slot(name="rightTop")
                  section.update-user-box-spacer
                  LastUpdateUserBox(:userData="newUpdateUser")
              section.diff-spacer
                v-icon(large) arrow_drop_up
                v-icon(large) arrow_drop_up
                v-icon(large) arrow_drop_up

              v-card.history-detail-card
                v-card-text
                  slot(name="rightBottom")
                  section.update-user-box-spacer
                  LastUpdateUserBox(:userData="oldUpdateUser")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import LastUpdateUserBox from "@/components/common/LastUpdateUserBox";
import DateUtils from "@/mixin/DateUtils";
import { LimitNumber } from "@/assets/js/constants";

export default {
  mixins: [DateUtils],
  components: { EditDialog, LastUpdateUserBox },
  props: {
    title: {
      type: String,
      required: false,
      default: () => "履歴差分表示"
    },
    width: {
      type: String,
      required: false,
      default: "60%"
    },
    leftData: {
      type: Array,
      required: false,
      default: () => {}
    },
    oldUpdateUser: {
      type: Object,
      require: false,
      default: () => {}
    },
    newUpdateUser: {
      type: Object,
      require: false,
      default: () => {}
    },
    loadUrl: {
      type: String,
      default: () => ""
    },
    targetId: {
      type: Number,
      default: () => null
    }
  },
  data() {
    return {
      selectHistoryRow: null,
      progress: false
    };
  },
  computed: {},
  mounted() {
    this.$refs.historyArea.removeEventListener("scroll", this.handleScrollHistory);
    this.$refs.historyArea.addEventListener("scroll", this.handleScrollHistory);
  },
  methods: {
    openDialog() {
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    selectLine(rec, index) {
      this.selectHistoryRow = index;
      this.$emit("selectLine", rec);
    },
    initialize() {
      if (this.leftData) {
        this.selectHistoryRow = 0;
        this.selectLine(this.leftData[this.selectHistoryRow], this.selectHistoryRow);
      }
    },
    terminate() {
      this.selectHistoryRow = null;
      this.$emit("terminate");
    },
    getSelectLineCss(index) {
      return this.selectHistoryRow === index ? "select" : "";
    },
    /**
     * 履歴スクロールイベント
     */
    handleScrollHistory() {
      if (this.$refs.historyArea !== undefined && this.targetId) {
        const clientHeight = this.$refs.historyArea.clientHeight;
        const scrollTop = this.$refs.historyArea.scrollTop;
        const scrollHeight = this.$refs.historyArea.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber && !this.progress) {
          this.$emit("incrementPageCount");
          if (!this.loadUrl) return;
          this.progress = true;
          this.$store.dispatch(this.loadUrl, this.targetId).finally(() => {
            this.progress = false;
          });
        }
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.diff-spacer {
  margin 16px
  text-align center
}

.edit-dialog{
  height 100%
}

.update-user-box-spacer{
  margin-top 24px
}

.history-detail-card{
  min-height 100px
}

.diff-dialog {
  >>> table {
    width 100%

    th, td {
      padding 8px
    }

    th,td{
      border-right 1px solid #E0E0E0;
    }

    th:last-child,
    td:last-child{
      border-right none;
    }

    tr:hover.history {
      td,th{
        background-color: #f5f5f5;
        transition: 0.2s;
      }
    }

    tr:hover.select {
      td,th{
        background-color: #9e9e9e;
      }
    }

    td.price {
      text-align right
    }

    /*tr.insert,td.insertのopacityが重複するので別設定*/
    tr.insert td {
      //#4CAF50
      background-color rgba(76,175,80,0.7)
    }

    tr.change td {
      //#FFC107
      background-color rgba(255,193,7,0.7)
    }

    tr.delete td {
      //#F44336
      background-color rgba(244,67,54,0.7)
    }

    td.insert {
      //#4CAF50
      background-color rgba(76,175,80,0.7)
    }

    td.change {
      //#FFC107
      background-color rgba(255,193,7,0.7)
    }

    td.delete {
      //#F44336
      background-color rgba(244,67,54,0.7)
    }
  }
}

.history-index-list{
  tr.select{
    background-color #bfbfbf
  }
}

.hisotry-body-section {
  height 60vh
  overflow-y scroll
  overflow-x visible
}
</style>
