<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="荷主" v-model="conditions.clientName")
        v-flex(md2)
          v-text-field(label="荷主枝番" v-model="conditions.clientBranchName")
        v-flex(md2)
          v-text-field(label="積地CD" v-model="conditions.loadingBaseCd")
        v-flex(md2)
          v-text-field(label="積地名" v-model="conditions.loadingBaseName")
        v-flex(md2)
          v-text-field(label="卸地CD" v-model="conditions.unloadingBaseCd")
        v-flex(md2)
          v-text-field(label="卸地名" v-model="conditions.unloadingBaseName")
        v-flex(md2)
          DatePicker(pickerLabel="適用開始日(開始)" :initDate="conditions.applicableStartDateMin" :inputDate.sync="conditions.applicableStartDateMin" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="適用開始日(終了)" :initDate="conditions.applicableStartDateMax" :inputDate.sync="conditions.applicableStartDateMax" :isClearable="true")
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
        v-flex(md2)
          CheckOnOffSelect(label-disp="高速代フラグ" :code.sync="conditions.tollActualCostFlg" clearable)
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import StatusSelect from "@/components/common/select/StatusSelect";
import CheckOnOffSelect from "@/components/common/select/CheckOnOffSelect.vue";

export default {
  components: {
    CheckOnOffSelect,
    SearchConditions,
    DatePicker,
    StatusSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          clientBranchName: "",
          applicableStartDateMin: "",
          applicableStartDateMax: "",
          loadingBaseName: "",
          unloadingBaseName: "",
          loadingBaseCd: "",
          unloadingBaseCd: "",
          status: null
        }
      };
    }
  }
};
</script>
