<template lang="pug">
  EditDialog(ref="EditDialog" title="テンプレート" width="60%" :recordId="detail.id"
    :loadingSave="loading.save" @save="onSave" :loadingDelete="loading.delete" @delete="onDelete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field(v-model="detail.templateName" :error-messages="getErrorMessages('templateName')" label="テンプレート名" maxlength=30)
        v-layout.mt-2.mb-3(row wrap align-center justify-start fill-height)
          v-flex(xs2)
            span 利用可能権限
          v-flex.d-flex.flex-wrap(xs10)
            v-checkbox.my-1(v-for="role in roleList" :key="role.id"
              @change="onChangeRole(role.id)" :input-value="selectRoles[role.id]" :label="role.roleName" hide-details)

        v-layout(row wrap)
          v-flex(xs12 md12)
            ExportTemplateTabs(v-model="selectContents" :tabIndex="selectTabIndex" @changeTab="selectTabIndex = $event")
          v-flex(xs12 md12)
            v-textarea(v-model="detail.remark" :error-messages="getErrorMessages('remark')" label="備考" maxlength=2000)
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditDialog from "@/components/common/dialog/EditDialog";
import ExportTemplateTabs from "@/components/management/export-template/ExportTemplateTabs";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

import AxiosBase from "@/assets/js/axios-base";
const axiosBase = new AxiosBase();

export default {
  /**
   * props
   * ApiHandlerにて利用
   */
  props: {
    postUrl: {
      type: String,
      default: () => axiosBase.getUrls().postEditExportTemplate
    },
    deleteUrl: {
      type: String,
      default: () => axiosBase.getUrls().deleteExportTemplate
    }
  },
  components: {
    EditDialog,
    ExportTemplateTabs
  },
  mixins: [ApiHandler, EditForm],
  data: () => ({
    selectContents: {},
    selectTabIndex: 0,
    selectRoles: {},
    // 更新情報
    detail: {
      id: null,
      templateName: "",
      remark: "",
      outputTargets: {
        list: []
      },
      roleIds: {
        list: []
      }
    }
  }),
  computed: {
    /** GET：Vuex */
    ...mapGetters({
      roleList: "role/getList"
    })
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("site", {
      fetchAllTemplateCode: "fetchAllTemplateCode"
    }),
    ...mapActions("role", {
      fetchAllRole: "fetchAllRole"
    }),
    /**
     * 初期設定
     * ※デフォルトは空設定
     */
    initCondition(
      item = {
        id: null,
        templateName: "",
        remark: "",
        outputTargets: {
          list: []
        },
        roleIds: []
      }
    ) {
      /**
       * Function：選択テンプレート設定
       * ※ネストされたプロパティ用の対応
       */
      const functionOutputTargetsList = detailItem => {
        if (detailItem.outputTargets === null) return [];
        if (detailItem.outputTargets.list.length === 0) return [];
        else {
          let selectContents = {};
          detailItem.outputTargets.list.map(
            templateInfo =>
              (selectContents = {
                ...selectContents,
                ...{ [templateInfo.templateCode.templateCode]: true }
              })
          );
          // 詳細情報表示時の既存設定項目はここで変数設定
          this.selectContents = selectContents;
          return detailItem.outputTargets.list;
        }
      };
      const functionRoleList = detailItem => {
        if (detailItem.roleIds === null) return [];
        if (detailItem.roleIds.length === 0) return [];
        else {
          let selectRoles = {};
          detailItem.roleIds.map(
            id =>
              (selectRoles = {
                ...selectRoles,
                ...{ [id]: true }
              })
          );
          // 詳細情報表示時の既存設定項目はここで変数設定
          this.selectRoles = selectRoles;
          return detailItem.roleIds;
        }
      };

      this.selectContents = {};
      this.selectTabIndex = 0;
      this.selectRoles = {};
      this.detail = {
        id: item.id,
        templateName: item.templateName,
        remark: item.remark,
        outputTargets: {
          list: functionOutputTargetsList(item)
        },
        roleIds: functionRoleList(item)
      };
      // mixin:ApiHandlerに情報を設定
      this.errors = {};
    },
    /**
     * Event：ダイアログを開く
     * ※親→子（このコンポーネント）→孫（slot利用）すべて参照利用。
     */
    openDialog(item) {
      this.initCondition(item);
      this.$refs.EditDialog.openDialog();
    },
    /**
     * Event：登録
     */
    onSave() {
      // チェックボックス用変数をRequest用に変換
      let requestTargetsList = [];
      Object.entries(this.selectContents).forEach(([code, isChecked]) => {
        if (isChecked) requestTargetsList.push({ templateCode: { templateCode: code } });
      });
      // 権限チェックボックス用変数をRequest用に変換
      let requestRoleIdTargetsList = [];
      Object.entries(this.selectRoles).forEach(([id, isChecked]) => {
        if (isChecked) requestRoleIdTargetsList.push(id);
      });

      this.detail.outputTargets.list = requestTargetsList;
      this.detail.roleIds = requestRoleIdTargetsList;
      // mixin:ApiHandlerに情報を設定
      this.record = this.detail;
      const refsDialog = this.$refs.EditDialog;
      this.saveRecord().then(value => {
        refsDialog.closeDialog();
      });
    },
    /**
     * Event：削除
     */
    onDelete() {
      if (this.detail.id === null) return;
      // mixin:ApiHandlerに情報を設定
      this.record = this.detail;
      const refsDialog = this.$refs.EditDialog;
      this.deleteRecord().then(value => {
        refsDialog.closeDialog();
      });
    },
    /**
     * Event：チェックボックス切替
     */
    onChangeRole(roleId) {
      let copyRoles = { ...this.selectRoles };
      if (roleId in copyRoles) copyRoles[roleId] = !copyRoles[roleId];
      else copyRoles[roleId] = true;
      this.selectRoles = copyRoles;
    }
  },
  created() {
    this.fetchAllTemplateCode();
    this.fetchAllRole();
  }
};
</script>
