<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="荷主名" v-model="conditions.clientName" hideDetails)
        v-flex(md2)
          v-text-field(label="荷主枝番" v-model="conditions.clientBranchName" hideDetails)
        v-flex(md2)
          v-text-field(label="積地CD" v-model="conditions.loadingBaseCd" hideDetails)
        v-flex(md3)
          v-text-field(label="積地名" v-model="conditions.loadingBaseName" hideDetails)
        v-flex(md2)
          v-text-field(label="積地エリア名" v-model="conditions.loadingBaseAreaName" hideDetails)
      v-layout(wrap)
        v-flex(md2  offset-xs4)
          v-checkbox(label="第一運行必須指定" v-model="conditions.isOnlyFirst" hideDetails)

      v-layout(wrap)
        v-flex(md2 offset-xs4)
          v-text-field(label="卸地CD" v-model="conditions.unloadingBaseCd" hideDetails)
        v-flex(md3)
          v-text-field(label="卸地名" v-model="conditions.unloadingBaseName" hideDetails)
        v-flex(md2)
          v-text-field(label="卸地エリア名" v-model="conditions.unloadingBaseAreaName" hideDetails)
      v-layout(wrap)
        v-flex(md2  offset-xs4)
          v-checkbox(label="最終運行必須指定" v-model="conditions.isOnlyLast" hideDetails)
      v-layout(wrap)
        v-flex(sm3 xl2)
          VehicleTypeMultiSelect(labelDisp="車格" :code.sync="conditions.vehicleTypeIdList" :clearable="true")
        v-flex(sm2 xl1)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import StatusSelect from "@/components/common/select/StatusSelect.vue";
import VehicleTypeMultiSelect from "@/components/common/select/VehicleTypeMultiSelect.vue";

export default {
  components: {
    VehicleTypeMultiSelect,
    StatusSelect,
    SearchConditions
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          name: "",
          clientName: "",
          clientBranchName: "",
          loadingBaseCd: "",
          loadingBaseName: "",
          loadingBaseAreaName: "",
          unloadingBaseCd: "",
          unloadingBaseName: "",
          unloadingBaseAreaName: "",
          vehicleTypeIdList: [],
          isOnlyFirst: false,
          isOnlyLast: false,
          status: null
        }
      };
    }
  }
};
</script>
