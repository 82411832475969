<template lang="pug">
  section
    FinanceMasterImport(
      backUrlPath="/tms/management/tariff"
      :uploadFileUrl="getUploadFileUrl"
      :uploadValidationUrl="getUploadValidationUrl"
      :commit-import-url="getCommitImportUrl"
      :fetchWorkListUrl="fetchWorkListUrl"
      :target-count-url="fetchWorkListCountUrl"
      :uploaded-at-action="uploadedAtAction"
      :isFilterError="isFilterError"
      :toggleFilterAction="toggleFilter"
    )
      template(v-slot:table)
        TariffImportWorkListTable(ref="WorkTable" :getListActionName="getListActionName" :isFilterError="isFilterError")
</template>

<script>
import TariffImportWorkListTable from "@/components/management/tariff/TariffImportWorkListTable";
import AxiosBase from "@/assets/js/axios-base";
import FinanceMasterImport from "@/components/import/FinanceMasterImport";
import { mapActions, mapGetters } from "vuex";
const axiosBase = new AxiosBase();

export default {
  components: {
    TariffImportWorkListTable,
    FinanceMasterImport
  },
  methods: {
    ...mapActions("financeImport", {
      toggleErrorOnly: "toggleErrorOnly"
    }),
    uploadedAtAction() {
      this.toggleErrorOnly(false);
      this.$refs.WorkTable.loadUploadData();
    },
    toggleFilter() {
      this.toggleErrorOnly(!this.isFilterError);
      this.$refs.WorkTable.loadUploadData();
    }
  },
  computed: {
    ...mapGetters("financeImport", {
      isFilterError: "isFilterError"
    }),
    getListActionName: () => "financeImport/fetchWorkList",
    getUploadFileUrl: () => axiosBase.getUrls().uploadTariffImportWork,
    getUploadValidationUrl: () => axiosBase.getUrls().uploadTariffImportWorkValidation,
    getCommitImportUrl: () => axiosBase.getUrls().uploadTariffImportWorkCommit,
    fetchWorkListUrl: () => axiosBase.getUrls().getTariffWorkList,
    fetchWorkListCountUrl: () => axiosBase.getUrls().getTariffWorkListCount
  }
};
</script>
