<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力
                  v-btn(color="info" to="/tms/management/tariff/import") インポート

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      TariffFilterConditions(@onChangeSearchConditions="onChangeSearchConditions" ref="TariffFilterConditions")

            v-card-text
              TariffTable(ref="TariffTable" :prop-header="tableHeader" :prop-data="dataList" @open-detail="openDetail" detail-url="tariff/fetchOne" :getListActionName="getListActionName" displaySlotBody)
                template(v-slot:body="{body}")
                  td {{ body.tariffCd }}
                  td {{ body.tariffName }}
                  td {{ body.tariffNameShort }}
                  td {{ body.applicableStartDate }}
                  td
                    template(v-for="tag in body.tariffTagList")
                      v-chip(@click="openTagContextMenu(tag, $event)") {{tag.tagName}}
                  td
                    v-tooltip(top)
                      template(v-slot:activator="{ on }")
                        p(v-on="on").remark.whitespace-pre {{ body.remark }}
                      span.whitespace-pre {{ body.remark }}

    TagContextMenu(ref="TagContextMenu" @updateTag="updateTag" @deleteTag="openDeleteConfirm")
    TariffEditDialog(ref="TariffEditDialog" :postUrl="$config.urls.postEditTariff" :deleteUrl="$config.urls.deleteTariff" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('tariff/export')")
    DeleteConfirmDialog(ref="DeleteConfirmDialog" @yes="deleteTag", title="一括削除" message="タグを一括削除しますか？")
    TagEditDialog(ref="TagEditDialog" @updatedTag="loadListData")
</template>

<script>
import TariffEditDialog from "@/components/management/tariff/TariffEdit";
import TariffTable from "@/components/common/PagingListTable";
import TariffFilterConditions from "@/components/management/tariff/TariffFilterConditions";
import TagContextMenu from "@/components/management/tariff/TagContextMenu";
import TagEditDialog from "@/components/management/tariff/TagEditDialog";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import DeleteConfirmDialog from "@/components/common/dialog/DeleteConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    TariffEditDialog,
    TariffTable,
    TariffFilterConditions,
    ExportConfirmDialog,
    TagContextMenu,
    TagEditDialog,
    DeleteConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {
      deleteTargetTag: {}
    };
  },
  computed: {
    dataList: {
      get() {
        return this.$store.state.tariff.tariffList;
      }
    },
    getListActionName: {
      get() {
        return "tariff/fetchAll";
      }
    },
    tableHeader: {
      get() {
        return [
          { text: "タリフCD", value: "tariffCd", centerAlign: false },
          { text: "タリフ名", value: "tariffName", centerAlign: false },
          { text: "タリフ略称", value: "tariffNameShort", centerAlign: false },
          { text: "適用開始日", value: "applicableStartDate", centerAlign: false },
          { text: "タグ", value: "tag", centerAlign: false },
          { text: "備考", value: "remark", centerAlign: false }
        ];
      }
    }
  },
  mounted() {
    //初期設定検索条件で検索する為にイベントをキックする
    this.$refs.TariffFilterConditions.fireSubmit();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.TariffEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.TariffEditDialog.openDialog(item);
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.TariffTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.TariffTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.TariffTable.setSearchConditions(searchCond);
      this.$refs.TariffTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    },
    /**
     * タグコンテキストメニュー表示
     */
    openTagContextMenu(tag, event) {
      this.$refs.TagContextMenu.open(tag, event);
    },
    updateTag(tag) {
      this.$refs.TagEditDialog.openDialog(tag);
    },
    openDeleteConfirm(tag) {
      this.deleteTargetTag = tag;
      this.$refs.DeleteConfirmDialog.openDialog();
    },
    deleteTag() {
      this.$store
        .dispatch("tariff/deleteTariffTag", this.deleteTargetTag)
        .then(() => {
          this.deleteTargetTag = {};
          this.loadListData();
        })
        .catch(error => {
          this.dispatchCommonEventWithParam(window.Common.Config.observer.onErrorResponse400, {
            message: error.response.data.message
          });
        });
    }
  }
};
</script>
