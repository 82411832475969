<template lang="pug">
  section
    DriverShiftPicker(ref="DriverShiftPicker" @selectShiftType="shiftSelect")
    table.schedule-table(v-if="driverMonthlySchedule.length > 0")
      tr
        th(v-for="header in weekHeaders" :class="header.class" :key="header.label") {{ header.label }}
      tr(v-for="(week, index) in driverMonthlySchedule" :key="index")
        td(
          v-for="day in week"
          v-bind:key="day.id"
          :class="[day.holiday ? css.sundayClass : css.otherDayCss,day.isTargetMonth ? css.targetCellCss : css.unTargetCellCss]"
        )
          span.day-label {{ getDay(day.targetDate) }}
          div.switch-box(v-if="day.targetMonth")
            v-icon(:color="getShiftType(day.shiftType).color",
              @click="openShiftMenu($event,day)") {{ getShiftType(day.shiftType).icon }}
</template>

<script>
import DriverShiftPicker from "@/components/common/DriverShiftPicker";
import ShiftTypeMenu from "@/mixin/ShiftTypeMenu";

export default {
  mixins: [ShiftTypeMenu],
  components: {
    DriverShiftPicker
  },
  props: {
    driverMonthlySchedule: {
      type: Array,
      required: true
    }
  },
  data() {
    let sundayCss = "red lighten-5 sunDay";
    let otherDayCss = "grey lighten-5 otherDay";
    return {
      weekHeaders: [
        { label: "日", class: sundayCss },
        { label: "月", class: otherDayCss },
        { label: "火", class: otherDayCss },
        { label: "水", class: otherDayCss },
        { label: "木", class: otherDayCss },
        { label: "金", class: otherDayCss },
        { label: "土", class: otherDayCss }
      ],
      css: {
        sundayClass: sundayCss,
        otherDayCss: otherDayCss,
        targetCellCss: "",
        unTargetCellCss: "un-target-cell"
      }
    };
  },
  methods: {
    /**
     * 日付を取得
     * @param 年月日
     */
    getDay(date) {
      return date.split("-")[2];
    },
    /**
     * シフトメニュー変更時に保存処理
     */
    shiftSelect(selectItem) {
      this.target.record.shiftType = selectItem.code;
      this.$emit("save", this.target.record);
    },
    /**
     * シフトメニュー開く
     * @param event
     * @param shift
     */
    openShiftMenu(event, shift) {
      this.target.record = shift;
      this.$refs.DriverShiftPicker.open(event);
    }
  }
};
</script>

<style scoped lang="stylus">
$color = rgba(0, 0, 0, 0.54);

.schedule-table {
  min-width: 300px;
  min-height: 300px;
  color: $color;

  th {
    text-align: center;
  }

  td {
    width: 80px;
    height: 50px;
    border: 1px solid $color;
    text-align: left;
    vertical-align: top;
  }

  .sunDay {
    color: #EF5350;
  }

  .otherDay {
  }

  .un-target-cell {
    color: rgba(0, 0, 0, 0.2);
  }

  .day-label {
    display: inline-block;
    margin: 8px;
  }

  .switch-box {
    text-align: center;
    margin-bottom: 8px;
  }
}
</style>
