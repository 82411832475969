import * as Enum from "@/assets/js/enum";
export default {
  methods: {
    isReach(liftType) {
      return liftType === Enum.LiftType.Reach.code;
    },
    isCounter(liftType) {
      return liftType === Enum.LiftType.Counter.code;
    },
    isBoth(liftType) {
      return liftType === Enum.LiftType.Both.code;
    },
    isUnManaged(liftType) {
      return liftType === Enum.LiftType.UnManaged.code;
    },
    isNone(liftType) {
      return liftType === Enum.LiftType.None.code;
    },
    getLiftTypeLabel(code) {
      return Enum.Utils.findLabel(code, Enum.LiftType);
    }
  }
};
