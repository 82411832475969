<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力
            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              RoleTable(ref="RoleTable" :prop-header="roleTableHeader" :prop-data="roleList" detail-url="role/fetchRole" @open-detail="openDetail" :getListActionName="getListActionName")
    RoleEditDialog(ref="RoleEditDialog" :postUrl="$config.urls.postEditRole" :deleteUrl="$config.urls.deleteRole" @updated="itemUpdated" @deleted="itemUpdated") 
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('role/export')")
</template>

<script>
import RoleEditDialog from "@/components/management/role/RoleEdit";
import RoleTable from "@/components/common/PagingListTable";
import FilterConditions from "@/components/management/role/RoleFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    RoleEditDialog,
    RoleTable,
    FilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    getListActionName: {
      get() {
        return "role/fetchAll";
      }
    },
    roleList: {
      get() {
        return this.$store.state.role.roleList;
      }
    },
    roleTableHeader: {
      get() {
        return [
          {
            text: "権限名称",
            value: "roleName",
            centerAlign: ""
          }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openAddDialog() {
      this.$refs.RoleEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     */
    openDetail(item) {
      this.$refs.RoleEditDialog.openDialog(item);
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.RoleTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 一覧データの取得
     */
    loadListData() {
      this.$refs.RoleTable.loadData();
    },
    /**
     * 検索条件の取得
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.RoleTable.setSearchConditions(searchCond);
      this.$refs.RoleTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  }
};
</script>
