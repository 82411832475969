import { render, staticRenderFns } from "./DispatchMailListTable.vue?vue&type=template&id=96fd5e38&scoped=true&lang=pug"
import script from "./DispatchMailListTable.vue?vue&type=script&lang=js"
export * from "./DispatchMailListTable.vue?vue&type=script&lang=js"
import style0 from "./DispatchMailListTable.vue?vue&type=style&index=0&id=96fd5e38&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96fd5e38",
  null
  
)

export default component.exports