import * as Enum from "@/assets/js/enum";
export default {
  methods: {
    dispatchStatusLabel(record) {
      if (record.dispatched) {
        if (record.dispatchComplete) return Enum.DispatchStatusList.Delivered.label;
        return Enum.DispatchStatusList.Dispatched.label;
      } else if (record.relayed) {
        return Enum.DispatchStatusList.Relayed.label;
      } else {
        return Enum.DispatchStatusList.None.label;
      }
    }
  }
};
