<template lang="pug">
  section(v-if="isDisplayOptionBox").base-box
    div.lift-box(v-if="isDisplayLiftInfo")
      LiftTypeIcon(:liftType="item.requireLiftType")
      span {{ getLiftTypeLabel(item.requireLiftType) }}
    div.option-box(v-if="item.requiredOptions != null")
      v-icon(flat) local_shipping
      div {{ requiredOptionNames }}
</template>

<script>
import LiftTypeHandler from "@/mixin/LiftTypeHandler";
import LiftTypeIcon from "@/components/dispatch/LiftTypeIcon";
export default {
  mixins: [LiftTypeHandler],
  components: {
    LiftTypeIcon
  },
  computed: {
    isDisplayOptionBox() {
      return !(this.isNone(this.item.requireLiftType) && this.item.requiredOptions == null);
    },
    requiredOptionNames() {
      let names = [];
      this.item.requiredOptions.map(option => {
        names.push(option.optionName);
      });
      return names.join(" / ");
    },
    isDisplayLiftInfo() {
      return !(this.isNone(this.item.requireLiftType) || this.isUnManaged(this.item.requireLiftType));
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="stylus">
.base-box {
  padding: 8px 0;

  .lift-box .icon-wrapper {
    display: inline-block;
    margin-right: 4px;
  }
}

.option-box, .lift-box {
  display: flex;
  align-items: center;

  .v-icon {
    margin-right: 2px;
    font-size: 22px;
  }

  margin-left: 16px;
}

.lift-box {
  margin-bottom: 4px;
}
</style>
