<template lang="pug">
  section(v-on:keyup.enter="submit")
    slot(name="conditions" v-bind:conditions="conditions")
    v-layout(wrap)
      slot(name="progress")
        v-spacer
      v-flex(md3 sm4).text-xs-right
        v-btn(@click="clear" :loading="loadingComputed") クリア
        v-btn(@click="submit" :loading="loadingComputed" color="primary" v-show="!triggerDisable") 検索
</template>

<script>
import _ from "lodash";

export default {
  props: {
    initSearchCond: {
      type: Function,
      default: () => {
        return {
          conditions: {}
        };
      }
    },
    triggerDisable: {
      type: Boolean,
      default: false
    },
    autoTrigger: {
      type: Boolean,
      default: true
    },
    isValid: {
      type: Boolean,
      default: false
    },
    customLoading: {
      type: Boolean,
      default: false
    },
    enableCustomLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return Object.assign(
      {
        loading: false
      },
      this.initData()
    );
  },
  watch: {
    conditions: {
      handler: function (newValue) {
        this.$emit("onChangeSearchConditionsTriggerBefore", newValue);
        if (!this.autoTrigger) {
          return;
        }
        this.debouncedTrigger(newValue);
      },
      deep: true
    }
  },
  computed: {
    loadingComputed: {
      get() {
        if (this.enableCustomLoading) {
          return this.customLoading;
        } else {
          return this.loading;
        }
      }
    }
  },
  created() {
    this.debouncedTrigger = _.debounce(this.trigger, 500);
  },
  destroyed() {
    window.removeEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.setLoadingDown
    );
  },
  mounted() {
    window.addEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.setLoadingDown,
      false
    );
  },
  methods: {
    setLoadingDown() {
      this.loading = false;
    },
    setLoadingUp() {
      this.loading = true;
    },
    initData() {
      return this.initSearchCond();
    },
    submit() {
      this.trigger(this.conditions);
    },
    trigger(conditions) {
      this.$emit("onChangeSearchConditions", conditions);
      if (this.triggerDisable) {
        return;
      }
      if (!this.isValid) {
        this.loading = true;
      }
    },
    clear() {
      Object.assign(this.$data, this.initData());
    }
  }
};
</script>
