<template lang="pug">
  v-content#dynamic-schedule
    EmergencyErrorDialog(ref="errorMsg")
    DispatchRemarkEditDialog(ref="DispatchRemarkEditDialog" :postUrl="$config.urls.postSaveDynamicRemark"
    :scheduledLoadingDate="getScheduledLoadingDate" :getRemarkAction="'dynamic/fetchScheduleRemark'" @updateRemark="onSaveRemark")
    ClassifiedCardRemarkEditDialog(ref="ClassifiedCardRemarkEditDialog" :postUrl="$config.urls.postEditCardRemarkDynamic"
      :getRemarkAction="'dynamic/fetchCardRemark'")
    ClassifiedCardContextMenu(ref="ClassifiedCardContextMenu" @openCardDetailDialog="openCardDetailDialog" @openDynamicResultDialog="openDynamicResultDialog" @dynamicResultRelease="dynamicResultRelease")
    DriverContextMenu(ref="DriverContextMenu" @openDriverDetailDialog="openDriverDetailDialog" @openDynamicResultDialog="openDynamicResultDialog" @dynamicResultRelease="dynamicResultRelease")
    ClassifiedCardMarkMenu(ref="ClassifiedCardMarkMenu" @error="openErrorMsgBox" :isDynamic="true")
    DriverDetailDialog(ref="DriverDetailDialog")
    CardDetailDialog(ref="CardDetailDialog" @saved="reloadSchedule" :postUrl="$config.urls.postSaveCardEdit" :isDynamic="true")
    DynamicResult(ref="DynamicResult" :postUrl="$config.urls.postDynamicResult" @saved="savedResult")
    ClassifiedCardPopUp(ref="ClassifiedCardPopUp" :isDynamic="true")
    v-container(fluid grid-list-md fill-height)
      v-layout(row wrap ref="WrapLayout")
        v-flex.schedule-flex-layout(ref="ScheduleLayout")
          v-card(height="100%")
            section.schedule-wrapper
              LoadingWrapper(:isDisplay="isDisplayScheduleProgress" :positionFixed="false")
              section.schedule-header-section
                v-layout.schedule-filter-header(row wrap align-center justify-start)
                  v-flex(xs3)
                    v-layout(fill-height align-center justify-start)
                      div.d-inline-block
                        v-icon(v-on:click="changeScheduledLoadingDate(-1)") arrow_back_ios
                      DatePicker(:inputDate.sync="dynamicConditions.scheduledLoadingDate" pickerLabel="配車日")
                      div.d-inline-block
                        v-icon(v-on:click="changeScheduledLoadingDate(1)") arrow_forward_ios
                  v-flex(xs6)
                    div.hanrei-container
                      div.hanrei
                        TrackingCardIcon.tracking-icon-inline(arrivalStatus="00")
                        span {{getArrivalStatusLabel("00")}}
                      div.hanrei
                        TrackingCardIcon.tracking-icon-inline(arrivalStatus="10")
                        span {{getArrivalStatusLabel("10")}}
                      div.hanrei
                        TrackingCardIcon.tracking-icon-inline(arrivalStatus="20")
                        span {{getArrivalStatusLabel("20")}}
                      div.hanrei
                        TrackingCardIcon.tracking-icon-inline(arrivalStatus="30")
                        span {{getArrivalStatusLabel("30")}}
                      div.hanrei
                        TrackingCardIcon.tracking-icon-inline(arrivalStatus="40")
                        span {{getArrivalStatusLabel("40")}}
                      div.hanrei
                        TrackingCardIcon.tracking-icon-inline(arrivalStatus="50")
                        span {{getArrivalStatusLabel("50")}}
                  v-flex(xs3)
                    v-layout(fill-height align-center justify-end)
                      ReloadButton(@callReload="reloadSchedule")
                      v-btn(flat icon color="red")
                        v-icon(@click="clearScheduleConditions" ripple light) clear

                      v-menu(v-model="scheduleFilterMenu" :close-on-content-click="false" max-width="60%" left)
                        v-btn(flat icon slot="activator" @click="clickScheduleConditions")
                          v-icon(v-if="isScheduleFilter" color="blue") filter_list
                          v-icon(v-else) filter_list
                        ScheduleFilterBox(ref="ScheduleFilterBox" :isDynamic="true" :clientList="generalClientList" :areaList="garageAreaList" @onChangeScheduleConditions="onChangeScheduleConditions")

                v-layout.schedule-header(row)
                  div.driver-box-header
                    span 乗務員情報 {{ driverTotalCount }}

                  div.classified-order-card-row
                    div.classified-order-card-normal-area
                      div.driver-schedule-header(v-for="(n, index) in driverScheduleListMaxCount" :key="index")
                        p {{ n }}
                    div.classified-order-card-day-exceed-area
                      div.driver-schedule-header
                        p (日跨ぎ用)
              section.schedule-body-section(ref="ScheduleArea")
                v-layout.driver-schedule-row(row align-start justify-start v-for="(schedule, scheduleIndex) in scheduleList" :key="scheduleIndex")
                  DriverBox.driver-box(:driver="schedule" :driverIndex="scheduleIndex"
                    @openDriverContextMenu="openDriverContextMenu"
                    @openRemarkEditDialog="openDriverRemarkEditDialog")
                  div.classified-order-card-row
                    div.classified-order-card-normal-area
                      div.classified-order-card-normal-box()
                        section.between-box
                          div.content-box(v-if="schedule.firstCard")
                            DispatchFlowIcon(:item="schedule.firstCard")
                        section.driver-schedule-box.v-card(:class="[(schedule.firstCard !== null && schedule.firstCard.isSlide) ? 'slide-card-box' : '', cssTempCardClass(schedule.firstCard),cssSameDayClass(schedule.firstCard), NightLoadingCss(schedule.firstCard)]")
                          ClassifiedCard.content-box(:item="schedule.firstCard" ,
                          @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu"
                          @triggerOpenPopUpCard="triggerOpenPopUpCard"
                          @openMarkMenu="openMarkMenu"
                          @triggerClosePopUpCard="triggerClosePopUpCard")
                      div.classified-order-card-normal-box(v-for="(card , index) in schedule.cardList" :key="index" v-if="!card.isBlank")
                        section.between-box
                          div.content-box(v-if="card")
                            DispatchFlowIcon(:item="card")
                        section.driver-schedule-box.v-card(:class="[card.isSlide ? 'slide-card-box' : '', cssTempCardClass(card),cssSameDayClass(card), NightLoadingCss(card)]")
                          ClassifiedCard.content-box(:item="card" ,
                          @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu"
                          @triggerOpenPopUpCard="triggerOpenPopUpCard"
                          @openMarkMenu="openMarkMenu"
                          @triggerClosePopUpCard="triggerClosePopUpCard")
                    div.classified-order-card-day-exceed-area(v-if="schedule.dayExceedCard !== null")
                      section.between-box
                        div.content-box(v-if="schedule.dayExceedCard")
                          DispatchFlowIcon(:item="schedule.dayExceedCard")
                      section.driver-schedule-box.v-card(:class="[schedule.dayExceedCard.isSlide ? 'slide-card-box' : '', cssTempCardClass(schedule.dayExceedCard),cssSameDayClass(schedule.dayExceedCard), NightLoadingCss(schedule.dayExceedCard)]")
                        ClassifiedCard.content-box(:item="schedule.dayExceedCard" ,
                        @openRemarkEditDialog="openRemarkEditDialog" @openCardContextMenu="openCardContextMenu"
                        @triggerOpenPopUpCard="triggerOpenPopUpCard"
                        @openMarkMenu="openMarkMenu"
                        @triggerClosePopUpCard="triggerClosePopUpCard")

</template>

<script>
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import ToggleSearchButton from "@/components/common/SearchBoxButton";
import ScheduleFilterBox from "@/components/dispatch/ScheduleFilterBox";
import DriverBox from "@/components/dynamic/DriverBox";
import DriverDetailDialog from "@/components/dispatch/DriverDetail";
import ClassifiedCard from "@/components/dynamic/ClassifiedCard";
import CardDetailDialog from "@/components/dispatch/DispatchDetail";
import DynamicResult from "@/components/dynamic/DynamicResult";
import DynamicResultRelease from "@/components/dynamic/DynamicResultRelease";
import ClassifiedCardMarkMenu from "@/components/dispatch/ClassifiedCardMarkMenu";
import ReloadButton from "@/components/common/buttons/ReloadButton";
import DispatchRemarkEditDialog from "@/components/dispatch/DispatchRemarkEditDialog";
import ClassifiedCardRemarkEditDialog from "@/components/dispatch/ClassifiedCardRemarkEditDialog";
import ClassifiedCardContextMenu from "@/components/dynamic/ClassifiedCardContextMenu";
import DriverContextMenu from "@/components/dynamic/DriverContextMenu";
import TrackingCardIcon from "@/components/dynamic/TrackingCardIcon";
import DispatchFlowIcon from "@/components/dispatch/DispatchFlowIcon";
import ClassifiedCardPopUp from "@/components/dispatch/ClassifiedCardPopUp";
import LoadingWrapper from "@/components/common/LoadingWrapper";
import DatePicker from "@/components/common/DatePicker";
import { mapMutations, mapGetters, mapActions } from "vuex";
import * as Enum from "@/assets/js/enum";
export default {
  components: {
    EmergencyErrorDialog,
    ToggleSearchButton,
    ScheduleFilterBox,
    DriverBox,
    DriverDetailDialog,
    ClassifiedCard,
    DynamicResult,
    DynamicResultRelease,
    ReloadButton,
    DispatchRemarkEditDialog,
    ClassifiedCardRemarkEditDialog,
    ClassifiedCardContextMenu,
    DriverContextMenu,
    DatePicker,
    CardDetailDialog,
    TrackingCardIcon,
    DispatchFlowIcon,
    ClassifiedCardPopUp,
    LoadingWrapper,
    ClassifiedCardMarkMenu
  },
  data() {
    return {
      flags: {
        scheduleFilterDisplay: false
      },
      htmlBody: {},
      records: {
        generalClientList: [],
        garageAreaList: []
      },
      cnt: {
        driverScheduleListMax: 100
      },
      //配車表検索条件
      dynamicConditions: {
        scheduledLoadingDate: window.Common.Util.getYearMonthDayIsoFormat(),
        specifiedTime: false,
        vehicleType: [],
        vehicleCarryWeight: [],
        vehicleOption: [],
        driverShift: [],
        isFavorite: false,
        operatingConditions: [],
        liftType: [],
        contractType: [],
        firstMailType: [],
        allMailType: [],
        isDisplayRestDriver: false,
        shipmentNo: "",
        isComplete: null,
        mark: [],
        clientId: [],
        vehiclGarageArea: []
      },
      isScheduleFilter: false,
      scheduleFilterMenu: false,
      //ローディング制御
      progress: {
        schedule: false
      }
    };
  },
  computed: {
    ...mapGetters({
      getScheduledLoadingDate: "dynamic/getScheduledLoadingDate"
    }),
    scheduleList: {
      get() {
        return this.$store.state.dynamic.scheduleList;
      }
    },
    driverTotalCount: {
      get() {
        const count = this.$store.getters["dynamic/getDriverCount"];
        if (count < 1) {
          return "";
        }

        return "(" + count + "名)";
      }
    },
    generalClientList: {
      get() {
        return this.records.generalClientList;
      }
    },
    garageAreaList: {
      get() {
        return this.records.garageAreaList;
      }
    },
    driverScheduleListMaxCount: {
      get() {
        return this.cnt.driverScheduleListMax;
      }
    },
    cssTempCardClass: function () {
      return function (item) {
        if (item) {
          return item.orderType == Enum.OrderType.TempCard.code ? "dispatch-temp-card" : "";
        }
        return "";
      };
    },
    cssSameDayClass: function () {
      return function (card) {
        if (card) {
          return card.isSameDaySlide ? "slide-card-same-day" : "";
        }
        return "";
      };
    },
    //配車表ローディング表示可否
    isDisplayScheduleProgress() {
      return this.progress.schedule;
    }
  },
  created() {
    this.setScheduledLoadingDate(this.dynamicConditions.scheduledLoadingDate);
    this.setDispatchScheduledLoadingDate(this.dynamicConditions.scheduledLoadingDate);
    // this.reload(); // 他条件初期化に伴い、リロードされるのでここでは不要
  },
  mounted() {
    this.htmlBody = document.getElementById("dynamic-schedule");
  },
  methods: {
    ...mapMutations({
      setScheduledLoadingDate: "dynamic/setScheduledLoadingDate",
      setDispatchScheduledLoadingDate: "dispatch/setScheduledLoadingDate"
    }),
    ...mapActions({
      mergeDriverRemark: "dynamic/mergeDriverRemark"
    }),
    onSaveRemark(data) {
      this.mergeDriverRemark(data);
    },
    getArrivalStatusLabel(cd) {
      return Enum.Utils.findLabel(cd, Enum.ArrivalStatusType);
    },
    /**
     * エラーメッセージ
     */
    openErrorMsgBox(message) {
      this.$refs.errorMsg.open(message);
    },
    /**
     * 備考ダイアログ
     * @param item
     */
    openDriverRemarkEditDialog(item) {
      this.$refs.DispatchRemarkEditDialog.openDialog(item);
    },
    /**
     * 備考ダイアログを表示
     * @param item
     */
    openRemarkEditDialog(item) {
      this.$refs.ClassifiedCardRemarkEditDialog.openDialog({
        item: item.item,
        loadingDate: this.dynamicConditions.scheduledLoadingDate
      });
    },
    /**
     * カード詳細ダイアログを表示
     * @param item
     */
    openCardDetailDialog(item) {
      this.$refs.CardDetailDialog.openDialog(item);
    },
    /**
     * カードのコンテキストメニューを表示
     * @param item
     */
    openCardContextMenu(item) {
      this.$refs.ClassifiedCardContextMenu.open(item);
    },
    /**
     * 乗務員のコンテキストメニューを表示
     * @param item
     */
    openDriverContextMenu(item) {
      this.$refs.DriverContextMenu.open(item);
    },
    /**
     * 乗務員詳細ダイアログを表示
     * @param item
     */
    openDriverDetailDialog(item) {
      this.$refs.DriverDetailDialog.openDialog(item);
    },
    /**
     * 実績登録用ダイアログを表示
     * @param item
     * */
    openDynamicResultDialog(item) {
      this.$refs.DynamicResult.openDialog({
        item: item,
        scheduledLoadingDate: this.dynamicConditions.scheduledLoadingDate
      });
    },
    /**
     * 実績解除
     * @param item
     * */
    dynamicResultRelease(item) {
      if (item != null) {
        this.$store
          .dispatch("dynamic/postDispatchRelayRelease", item)
          .then(value => {
            this.reload();
          })
          .catch(reason => {
            this.openErrorMsgBox(reason.message);
          });
      }
    },
    reload() {
      this.reloadSchedule();
    },
    /**
     * 配車表用DBデータのリロード
     */
    reloadSchedule() {
      this.progress.schedule = true;
      this.$store.dispatch("dynamic/fetchScheduleList", this.dynamicConditions).then(() => {
        let cnt = 0;
        if (this.$store.state.dynamic.scheduleList.length > 0) {
          cnt = this.$store.state.dynamic.scheduleList[0].driverTotalCount;
        }
        this.$store.commit("dynamic/setDriverCount", cnt);
        this.progress.schedule = false;
      });
    },
    /**
     * 実績確定後処理
     * @param item
     * */
    savedResult() {
      this.reloadSchedule();
    },
    /**
     * 配車表 表示起点日の変更
     * @param changeDays
     */
    changeScheduledLoadingDate(changeDays) {
      const date = new Date(this.dynamicConditions.scheduledLoadingDate);
      date.setDate(date.getDate() + changeDays);
      this.dynamicConditions.scheduledLoadingDate = window.Common.Util.getYearMonthDayIsoFormat(date);
    },
    /**
     * 配車フィルタ用荷主のリロード
     */
    reloadDispatchedClient() {
      this.$store
        .dispatch("dynamic/fetchClientDispatchFilter", this.dynamicConditions.scheduledLoadingDate)
        .then(value => {
          this.records.generalClientList = value;
        });
    },
    /**
     * 配車フィルタ用車庫エリアのリロード
     */
    reloadGarageArea() {
      this.$store
        .dispatch("area/fetchScheduleGarageAreaForDynamic", this.dynamicConditions.scheduledLoadingDate)
        .then(value => {
          this.records.garageAreaList = value;
        });
    },
    clickScheduleConditions() {
      this.reloadDispatchedClient();
      this.reloadGarageArea();
    },
    onChangeScheduleConditions() {
      this.dynamicConditions = this.$refs.ScheduleFilterBox.addSearchCond(this.dynamicConditions);
      this.reloadSchedule();

      // フィルタアイコンの色変更処理
      this.isScheduleFilter = false;
      let keys = Object.keys(this.dynamicConditions);
      for (let i = 0, len = keys.length; i < len; i++) {
        if (keys[i] == "scheduledLoadingDate") {
          continue;
        }
        if (Array.isArray(this.dynamicConditions[keys[i]])) {
          if (this.dynamicConditions[keys[i]].length > 0) {
            this.isScheduleFilter = true;
            break;
          }
        } else if (this.dynamicConditions[keys[i]] != "" && this.dynamicConditions[keys[i]] != null) {
          this.isScheduleFilter = true;
          break;
        }
      }
    },
    clearScheduleConditions() {
      this.isScheduleFilter = false;
      this.dynamicConditions = this.$refs.ScheduleFilterBox.clearSearchCond(this.dynamicConditions);
      this.reloadSchedule();
    },
    triggerOpenPopUpCard(args) {
      this.$refs.ClassifiedCardPopUp.openTrigger(args);
    },
    /**
     * マーク用メニューを開く
     * @param item
     */
    openMarkMenu(item) {
      this.$refs.ClassifiedCardMarkMenu.open(item);
    },
    triggerClosePopUpCard(args) {
      this.$refs.ClassifiedCardPopUp.closeTrigger(args);
    },
    isNightLoadingDest(dispatchType) {
      const dest = [Enum.DispatchType.NightLoadingDest.code];
      return dest.includes(dispatchType);
    },
    isNightLoadingFrom(dispatchType) {
      const from = [Enum.DispatchType.NightLoading.code, Enum.DispatchType.NightLoadingAndDayExceed.code];
      return from.includes(dispatchType);
    },
    /**
     * @return {string}
     */
    NightLoadingCss(card) {
      if (card === undefined || card === null || card.dispatchType === undefined) {
        return "";
      }
      const dest = [Enum.DispatchType.NightLoadingDest.code];
      const from = [Enum.DispatchType.NightLoading.code, Enum.DispatchType.NightLoadingAndDayExceed.code];

      if (from.includes(card.dispatchType)) {
        return "night-loading-from-card";
      }

      if (dest.includes(card.dispatchType)) {
        return "night-loading-dest-card";
      }

      return "";
    }
  },
  watch: {
    //配車日付を更新したら配車表のリロード
    "dynamicConditions.scheduledLoadingDate": function (value) {
      this.setScheduledLoadingDate(value);
      this.setDispatchScheduledLoadingDate(value);
      this.reloadSchedule();
    },
    scheduleFilterMenu: function (value) {
      if (value === false) {
        this.$refs.ScheduleFilterBox.model = "tab1";
      }
    }
  },
  beforeCreate: function () {
    document.body.className = "dynamic";
  }
};
</script>

<style lang="stylus" scoped>
.hanrei-container {
  padding: 16px;
  border: grey 1px solid;
  text-align: center;
}

.hanrei {
  display: inline-block;
  margin-right: 16px;
}

.tracking-icon-inline {
  display: inline-block;
}
</style>
