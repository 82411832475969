<template lang="pug">
  v-text-field.text-align-right(
    v-model="inputData"
    :label="label"
    @focusin="editing = true"
    @focusout="editing = false"
    :maxlength="maxlength"
    :error-messages="errorMessages"
    :readonly="readOnly"
    :box="readOnly"
    :prefix="displayPrefix"
    :suffix="suffix"
    v-on:change="changeItem"
    )
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    label: {
      type: [String],
      default: null
    },
    maxlength: {
      type: Number,
      default: 10
    },
    errorMessages: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: "￥"
    },
    suffix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editing: false
    };
  },
  computed: {
    inputData: {
      get() {
        if (this.editing) {
          return this.value;
        } else {
          return this.value != null ? this.value.toLocaleString() : "";
        }
      },
      set(newVal) {
        if (this.value != newVal) {
          this.$emit("input", newVal);
        } else if (this.value === 0 && newVal === "") {
          this.$emit("input", "");
        }
      }
    },
    displayPrefix: {
      get() {
        return this.inputData ? this.prefix : "";
      }
    }
  },
  methods: {
    changeItem() {
      this.$emit("changeItem");
    }
  }
};
</script>
