<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="label"
    item-value="code"
    label="荷役区分"
    :error-messages="errorHandlerMethod('handlingUnitType')")
</template>

<script>
import * as Enum from "@/assets/js/enum";
export default {
  props: {
    // TODO:type:String 必須でありながらNullがはいる可能性があるため回避。（※初期値）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default: () => null
    }
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return Enum.Utils.enumToList(Enum.HandlingUnitType);
      }
    }
  },
  methods: {}
};
</script>

<style scoped></style>
