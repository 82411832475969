<template lang="pug">
  v-icon(v-if="isNone(markType)" color="gray") filter_none
  v-icon(v-else-if="isMark1(markType)" color="red") filter_1
  v-icon(v-else-if="isMark2(markType)" color="yellow darken-2") filter_2
  v-icon(v-else-if="isMark3(markType)" color="green") filter_3
  v-icon(v-else-if="isMark4(markType)" color="blue") filter_4
</template>

<script>
import CardMarkTypeHandler from "@/mixin/CardMarkTypeHandler";
export default {
  mixins: [CardMarkTypeHandler],
  props: {
    markType: {
      type: String,
      default: "00"
    }
  }
};
</script>
