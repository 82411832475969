<template lang="pug">
  v-dialog(v-model="dialog" persistent width="500")
    v-card
      v-toolbar(dark color="red darken-3")
        v-icon(left color="white darken-2" large) error
        span エラーが発生しました
        v-spacer
        v-btn(flat icon @click="clickClose")
          v-icon() close
      v-card-text
        v-layout
          v-flex
            div(class="ma-3 wrap") {{ text }}
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      text: "原因不明のエラーが発生しました"
    };
  },
  methods: {
    open(text) {
      this.dialog = true;
      if (text) {
        this.text = text;
      } else {
        this.text = window.Common.Util.getErrorResponseMessage();
      }
    },
    clickClose() {
      this.$emit("clickClose");
      this.dialog = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.wrap {
  white-space: pre-wrap;
}
</style>
