<template lang="pug">
  section
    v-text-field(
      clearable
      readonly
      v-model="p_label"
      label="署名"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      @click="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem" :disablePagingTrigger="true"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="'signature/fetchSelectList'")
      template(v-slot:body="{ body }")
        td.whitespace-pre-line(v-for="header in config.headers" v-bind:class="[{'text-xs-center' : header.align, 'text-xs-right': header.rightAlign}, header.css]") {{ body[header.key] }}
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  mixins: [ApiHandler, MasterSelectDialog],
  components: { PagingMasterSelectDialog },
  computed: {
    // 読取専用テキスト表示用
    displayName: {
      get() {
        return this.selectName;
      },
      set(changeValue) {
        this.$emit("input", changeValue);
      }
    },
    config: () => {
      return {
        title: "署名選択",
        headers: [
          {
            text: "タイトル",
            key: "title",
            align: "",
            width: "300px",
            noSearch: true
          },
          {
            text: "署名本文",
            key: "body",
            css: "config-body",
            rightAlign: true,
            noSearch: true
          }
        ]
      };
    }
  },
  data() {
    return {
      initSearchCond: () => {
        return {
          fullNumberDisp: "",
          vehicleTypeName: "",
          carryWeightDisp: "",
          vehicleGarageName: ""
        };
      }
    };
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      let isChanged = this.p_code !== data.id;
      this.p_code = data.id;
      this.p_label = data.title;
      if (isChanged) this.$emit("changeItem", data);
    }
  }
};
</script>

<style lang="stylus" scoped>
.config-body {
  font-family: Verdana, JPAPGothic, sans-serif;
  font-size: 11px;
  max-width: 558px;
}
</style>
