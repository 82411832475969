<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md4)
          v-text-field(label="荷主" v-model="conditions.clientName")
        v-flex(md2)
          v-text-field(label="積地分類" v-model="conditions.clientLoadingBaseClassName")
        v-flex(md2)
          v-text-field(label="卸地分類" v-model="conditions.clientUnloadingBaseClassName")
        v-flex(md4)
          v-text-field(label="荷主枝番" v-model="conditions.branchName")
      v-layout(wrap row)
        v-flex(md2)
          FaxTargetSelect(labelDisp="車番連絡表FAX送信対象" :code.sync="conditions.serviceContactFaxTarget" :clearable="true")
        v-flex(md2)
          FaxTargetSelect(labelDisp="請求書FAX送信対象" :code.sync="conditions.billingStatementFaxTarget" :clearable="true")
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import StatusSelect from "@/components/common/select/StatusSelect";
import FaxTargetSelect from "@/components/common/select/FaxTargetSelect";

export default {
  components: {
    SearchConditions,
    StatusSelect,
    FaxTargetSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          clientLoadingBaseClassName: "",
          clientUnloadingBaseClassName: "",
          branchName: "",
          serviceContactFaxTarget: "",
          billingStatementFaxTarget: "",
          status: ""
        }
      };
    }
  }
};
</script>
