<template lang="pug">
  EditDialog(ref="EditDialog" title="配車仮カード" :recordId="record.orderId" @save="save",
    :loadingSave="loading.save" :displayDeleteButton="false")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md3)
            DispatchTempCardTypeSelect(:code.sync="record.dispatchTempCardType" :errorHandlerMethod="getErrorMessages")
          // 積日
          v-flex(xs12 md3)
            DatePicker(:pickerLabel="labelName.LoadingSchedule" :initDate.sync="getInitLoadingDate" :inputDate.sync="record.loadingDate" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'loadingDate'" :isReadonly="true")
          // 納品日
          v-flex(xs12 md3)
            DatePicker(:pickerLabel="labelName.UnloadingSchedule" :initDate.sync="getInitUnloadingDate" :inputDate.sync="record.unloadingDate" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'unloadingDate'")

        v-layout(row wrap)
          v-flex(xs12 md5)
            v-text-field(label="積地" v-model="record.loadingBase" :error-messages="getErrorMessages('loadingBase')")
          v-flex(xs12 md5)
            v-text-field(label="卸地" v-model="record.unloadingBase" :error-messages="getErrorMessages('unloadingBase')")

        v-layout(row wrap)
          // 備考
          v-flex(xs12)
            v-textarea(label="備考" auto-grow v-model="record.remark" :error-messages="getErrorMessages('remark')")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import DatePicker from "@/components/common/DatePicker";
import DispatchTempCardTypeSelect from "@/components/common/select/DispatchTempCardTypeSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import { LabelName } from "@/assets/js/constants";

import { mapGetters } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";

export default {
  components: {
    EditDialog,
    DatePicker,
    DispatchTempCardTypeSelect
  },
  mixins: [ApiHandler, EditForm, EventDispatcher],
  data() {
    return {
      labelName: LabelName,
      controls: {
        index: null
      },
      record: {},
      tranData: {}
    };
  },
  computed: {
    ...mapGetters({
      getScheduledLoadingDate: "dispatch/getScheduledLoadingDate"
    }),
    getPostUrl: {
      get() {
        if (!this.tranData.cardOrder) {
          return this.$config.urls.postEditDispatchTempCardDayExceed;
        } else {
          return this.postUrl;
        }
      }
    }
  },
  methods: {
    /**
     * 初期データ
     */
    initData(data) {
      return {
        orderId: null,
        cardId: null,
        dispatchTempCardType: null,
        loadingBase: null,
        unloadingBase: null,
        loadingDate: this.getInitLoadingDate(),
        unloadingDate: this.getInitUnloadingDate(),
        remark: null,
        driverId: data.driverId,
        cardOrder: data.cardOrder,
        scheduledLoadingDate: this.getScheduledLoadingDate
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = Object.assign({}, this.record);
    },
    /**
     * フォームの初期化
     * @param values
     */
    initForm(values) {
      this.record = Object.assign({}, this.initData(values));
      this.setInitDate();
      this.errors = {};
    },
    /**
     * 日付の初期値設定
     */
    setInitDate() {
      if (!this.record.loadingDate) this.record.loadingDate = this.getInitLoadingDate();
      if (!this.record.unloadingDate) this.record.unloadingDate = this.getInitUnloadingDate();
    },
    getInitOrderDate() {
      return window.Common.Util.getYearMonthDayIsoFormat();
    },
    getInitLoadingDate() {
      return this.getScheduledLoadingDate;
    },
    getInitUnloadingDate() {
      return this.getScheduledLoadingDate;
    },

    openDialog(item) {
      this.initForm(item);
      if (item.orderType === null || item.orderId === null) {
        this.$refs.EditDialog.openDialog();
      } else {
        this.$store
          .dispatch("dispatch/fetchDispatchCardDetail", { orderType: item.orderType, orderId: item.orderId })
          .then(value => {
            if (value) {
              this.record.orderId = item.orderId;
              this.record.dispatchTempCardType = value.dispatchTempCardType;
              this.record.loadingBase = value.loadingBaseList[0].baseName;
              this.record.unloadingBase = value.unloadingBaseList[0].baseName;
              this.record.loadingDate = value.loadingDate;
              this.record.unloadingDate = value.unloadingDate;
              this.record.remark = value.remark;
            }
            this.$refs.EditDialog.openDialog();
          });
      }
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      this.setTranData();

      this.saveRecord(this.tranData).then(() => {
        this.$refs.EditDialog.closeDialog();
        this.$emit("saved");
        this.dispatchCommonEvent(window.Common.Config.observer.onDispatchPatternSelectDriverReload);
      });
    }
  }
};
</script>
