<template lang="pug">
  v-chip.selectable-chip(:selected="false" @click="toggleActive" :outline="!active" :color="activeChipColor",
  :text-color="getTextColor") {{ chipName }}

</template>

<script>
export default {
  props: {
    activeChipColor: {
      type: String,
      default: "teal lighten-1",
      required: false
    },
    activeTextColor: {
      type: String,
      default: "white",
      required: false
    },
    chipName: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      control: {
        active: false
      }
    };
  },
  computed: {
    getTextColor() {
      return this.active ? this.activeTextColor : "";
    }
  },
  methods: {
    toggleActive(e) {
      console.log(e);
      e.stopPropagation();
      this.control.active = !this.control.active;
      this.$emit("changeFilter", this.control.active);
    }
  },
  mounted() {
    this.control.active = this.active;
  }
};
</script>
