<template lang="pug">
  v-dialog(v-model="dialog" persistent width="600" @keydown.esc="no")
    v-card
      v-toolbar(dark :color="$store.getters['site/siteColor']")
        v-icon(left color="white darken-2" large) warning
        span  {{ title }}
        v-spacer
        v-btn(flat icon @click="closeDialog")
          v-icon close

      v-card-text
        v-layout
          v-flex
            div(class="ma-3").table-comment {{ message }}

      v-toolbar.footer
        v-btn(color="" @click="no") キャンセル
        v-spacer
        v-btn(color="primary" @click="yes") 変更
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "注意"
    },
    message: {
      type: String,
      required: false,
      default:
        "手入力の基本運賃が登録されています。\r\n配車を変更しても自動計算される基本運賃は利用されず手入力の基本運賃が優先されます。また状態が仮確定の場合は未確定に変更されます。\r\n配車を変更しますか？"
    }
  },
  data() {
    return {
      dialog: false,
      text: null
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    no() {
      this.$emit("no");
      this.closeDialog();
    },
    yes() {
      this.$emit("yes");
      this.closeDialog();
    }
  }
};
</script>
