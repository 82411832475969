<template lang="pug">
  v-dialog(v-model="dialog" :width="width" scrollable)
    v-card
      v-card-title(class="fixed")
        v-container(grid-list-lg)
          v-layout
            div
              span.headline {{ config.title }}
      v-card-text(@scroll="handleScroll" ref="SimpleDialog").result-content
        v-layout(row wrap)
          v-flex(xs12 md12)
            table
              thead
                tr
                  th(v-for="(header, index) in config.headers")
                    slot(name="head" v-bind:head="header")
                      div(class="th-text font-weight-thin grey--text text--darken-1") {{ header.text }}
                      v-text-field(v-model="config.headers[index].search" v-bind:class="header.align")

              tbody
                template(v-if="displayData")
                  tr(v-for="item in getTableDataList" @click="selectLine(item)")
                    slot(name="body" v-bind:body="item")
                      td(v-for="header in config.headers" v-bind:class="{'text-xs-center' : header.align}") {{ item[header.value] }}
                  tr(v-show="getTableDataList.length <= 0")
                    td(:colspan="config.headers.length") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
                slot(v-if="!displayData" name="noData")

              tfoot(class="footer")
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {
          title: "企業選択",
          headers: [
            { text: "id", value: "id", align: "text-xs-center", search: "" },
            {
              text: "企業名",
              value: "name",
              align: "text-xs-center",
              search: ""
            }
          ],
          initDispatch: "itemType/fetchAllItemType",
          initDispatchParam: {},
          getStore() {
            return [];
          }
        };
      }
    },
    displayData: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      pageCount: 1,
      records: []
    };
  },
  computed: {
    width() {
      return this.config.width || "50%";
    },
    tableItems: {
      get() {
        //TODO 現在STOREを利用したダイアログデータ表示をしているが、これらをすべてClientSelect同様ローカル（records）を参照した処理にかえたい
        if (this.config.getStore === undefined) {
          return this.records;
        }
        return this.config.getStore.apply(this);
      }
    },
    /**
     * テーブルの各カラムに入力された文字列を利用して
     * テーブルデータをフィルタする
     */
    filteredDataList: {
      get() {
        const header = this.config.headers;
        return this.tableItems.filter(function (row) {
          return Object.keys(row).every(function (key) {
            const filterWord = header
              .filter(rec => {
                return rec.value === key;
              })
              .pop();

            if (filterWord === undefined) {
              return true;
            }

            /**
             * 配車画面の車両選択が誤ってこの「SimpleMasterSelectDialog」を利用してる。本来は「PagingMasterSelectDialog」
             * を使うのが正しいのだが、今の時点で実装するのは大変なので以下のようにハックして対応
             * ※ ge があれば値以上というフィルタにする
             * */
            if (filterWord.filterOption !== undefined) {
              if (filterWord.filterOption === "ge") {
                const filterValue = parseFloat(filterWord.search.replace(",", ""));
                if (isNaN(filterValue)) {
                  return true;
                }

                const target = String(row[key]).replace(",", "").toLowerCase();

                return parseFloat(target) >= filterValue;
              }
            } else {
              return (
                //カンマ(,)は無視してフィルタする
                String(row[key])
                  .replace(",", "")
                  .toLowerCase()
                  .indexOf(filterWord.search.replace(",", "").toLowerCase()) > -1
              );
            }
          });
        });
      }
    },
    /**
     * フィルタ済みデータをページ表示数分だけ表示する（スクロールすると表示行が増える）
     */
    getTableDataList: {
      get() {
        return this.filteredDataList.filter((value, index) => {
          return index < this.pageCount * 10;
        });
      }
    }
  },
  mounted() {
    this.loadData();
    // window.addEventListener("scroll", this.handleScroll);
    // this.$refs.SimpleDialog.$on("scroll", this.handleScroll);
  },
  methods: {
    openDialog(item) {
      this.pageCount = 1;
      this.dialog = true;
    },
    selectLine(prop) {
      this.$emit("select:record", prop);
      this.dialog = false;
    },
    /**
     * スクロール操作を監視して、末尾まで行くと
     * ページング用変数の値をカウントアップ
     */
    handleScroll(event) {
      const scroll = event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop;
      if (scroll < 1) {
        this.pageCount++;
      }
    },
    loadData() {
      const self = this;
      this.$store.dispatch(this.config.initDispatch, this.config.initDispatchParam).then(value => {
        self.records = value;
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.container {
  padding: 9px;
}

.v-card__text {
  padding-top: 0;
}

.fixed {
  position: sticky;
  top: 0;
  background-color: #fff;
}

thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

tbody tr:hover {
  background-color: #f5f5f5;
  transition: 0.2s;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
}

.th-text {
  font-size: 13px;
}

.v-text-field {
  padding-top: 8px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 14px;
  font-weight: 400;
}

.result-content {
  height: 550px;
}
</style>
