import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login";
import ForgetPassword from "./views/ForgetPassword";
import Main from "./views/admin/Main";
import ImportOrder from "./views/admin/order/ImportBjiOrder";
import ImportShipmentNo from "./views/admin/order/ImportShipmentNo";
import OrderList from "./views/admin/order/OrderList";
import OrderDeleteList from "./views/admin/order/OrderDeleteList";
import VehicleList from "./views/admin/management/vehicle/VehicleList";
import VehicleTypeList from "./views/admin/management/vehicle-type/VehicleTypeList";
import VehicleGarageList from "./views/admin/management/vehicle-garage/VehicleGarageList";
import VehicleCarryWeightList from "./views/admin/management/vehicle-carry-weight/VehicleCarryWeightList";
import DriverList from "./views/admin/management/driver/DriverList";
import DriverSchedule from "./views/admin/management/driver/DriverSchedule";
import DispatchSchedule from "./views/admin/dispatch/Schedule";
import DynamicTracking from "./views/admin/dynamic/Tracking";
import OperationResource from "./views/admin/management/operation-resource/OperationResource";
import UserList from "./views/admin/management/user/UserList";
import DeliveryCompanyList from "./views/admin/management/delivery-company/DeliveryCompanyList";
import AreaList from "./views/admin/management/area/AreaList";
import BoilerplateList from "./views/admin/management/boilerplate/BoilerplateList";
import SignatureList from "./views/admin/management/signature/SignatureList";
import ItemTypeList from "./views/admin/management/item/ItemTypeList";
import GeneralClientList from "./views/admin/management/client/GeneralClientList";
import RoleList from "./views/admin/management/role/RoleList";
import LoadingBaseList from "./views/admin/management/base/LoadingBaseList";
import UnloadingBaseList from "./views/admin/management/base/UnloadingBaseList";
import BillingTypeList from "./views/admin/management/billing-type/BillingTypeList";
import ClientBranchList from "./views/admin/management/client-branch/ClientBranchList";
import LoadingBaseClassList from "./views/admin/management/base-class/LoadingBaseClassList";
import UnloadingBaseClassList from "./views/admin/management/base-class/UnloadingBaseClassList";
import ExportTemplateList from "./views/admin/management/export-template/ExportTemplateList";
import TariffList from "./views/admin/management/tariff/TariffList";
import TariffImport from "./views/admin/management/tariff/import/TariffImport";
import BillingItemList from "./views/admin/management/billing-item/BillingItemList";
import PaymentItemList from "./views/admin/management/payment-item/PaymentItemList";
import BaseDistanceList from "./views/admin/management/base-distance/BaseDistanceList";
import BaseDistanceImport from "./views/admin/management/base-distance/import/BaseDistanceImport";
import PaymentTariffTypeList from "./views/admin/management/payment-tariff-type/PaymentTariffTypeList";
import PaymentTariffRuleList from "./views/admin/management/payment-tariff-rule/PaymentTariffRuleList";
import PaymentTariffRuleImport from "./views/admin/management/payment-tariff-rule/import/PaymentTariffRuleImport";
import FixedBillingRateList from "./views/admin/management/fixed-billing-rate/FixedBillingRateList";
import FixedBillingRateImport from "./views/admin/management/fixed-billing-rate/import/FixedBillingRateImport";
import FixedPaymentRateList from "./views/admin/management/fixed-payment-rate/FixedPaymentRateList";
import FixedPaymentRateImport from "./views/admin/management/fixed-payment-rate/import/FixedPaymentRateImport";
import DispatchConfirm from "./views/admin/dispatch/Confirm";
import DispatchMailList from "./views/admin/dispatch/DispatchMailList";
import Forbidden from "./views/admin/error/403";
import ServiceContactReport from "./views/admin/report/ServiceContactReport";
import TransportOrderReport from "./views/admin/report/TransportOrderReport";
import ExportOrderAndCard from "./views/admin/export/ExportOrderAndCard";
import BillingAndPayment from "./views/admin/finance/BillingAndPayment";
import PaymentTypeList from "./views/admin/management/payment-type/PaymentTypeList";
import ExpensesImport from "./views/admin/finance/expenses-import/ExpensesImport";
import ExportBillingStatement from "./views/admin/report/ExportBillingStatement";
import ExportPaymentStatement from "./views/admin/report/ExportPaymentStatement";
import ExportPaymentPrompt from "./views/admin/report/ExportPaymentPrompt";
import DispatchPatternList from "./views/admin/management/dispatch-pattern/DispatchPatternList";

import store from "./store";
import * as Enum from "@/assets/js/enum";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "",
        requireAuth: false
      }
    },
    {
      path: "/forget-password",
      name: "ForgetPassword",
      component: ForgetPassword,
      meta: {
        title: "",
        requireAuth: false
      }
    },
    {
      path: "/tms",
      name: "dashBoard",
      component: Main,
      meta: {
        title: "",
        requireAuth: true
      },
      children: [
        {
          path: "ccbji/order/import",
          name: Enum.FunctionList.ImportOrder.code,
          component: ImportOrder,
          meta: {
            title: Enum.FunctionList.ImportOrder.label,
            requireAuth: true,
            parent: "order"
          }
        },
        {
          path: "order/list",
          name: Enum.FunctionList.OrderList.code,
          component: OrderList,
          meta: {
            title: Enum.FunctionList.OrderList.label,
            requireAuth: true,
            parent: "order"
          }
        },
        {
          path: "order/delete/list",
          name: Enum.FunctionList.OrderDeleteList.code,
          component: OrderDeleteList,
          meta: {
            title: Enum.FunctionList.OrderDeleteList.label,
            requireAuth: true,
            parent: "order"
          }
        },
        {
          path: "shipment-no/import",
          name: Enum.FunctionList.ImportShipmentNo.code,
          component: ImportShipmentNo,
          meta: {
            title: Enum.FunctionList.ImportShipmentNo.label,
            requireAuth: true,
            parent: "order"
          }
        },
        {
          path: "management/vehicle",
          name: Enum.FunctionList.VehicleList.code,
          component: VehicleList,
          meta: {
            title: Enum.FunctionList.VehicleList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/vehicle-type",
          name: Enum.FunctionList.VehicleTypeList.code,
          component: VehicleTypeList,
          meta: {
            title: Enum.FunctionList.VehicleTypeList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/vehicle-garage",
          name: Enum.FunctionList.VehicleGarageList.code,
          component: VehicleGarageList,
          meta: {
            title: Enum.FunctionList.VehicleGarageList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/vehicle-carry-weight",
          name: Enum.FunctionList.VehicleCarryWeightList.code,
          component: VehicleCarryWeightList,
          meta: {
            title: Enum.FunctionList.VehicleCarryWeightList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/driver",
          name: Enum.FunctionList.DriverList.code,
          component: DriverList,
          meta: {
            title: Enum.FunctionList.DriverList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/user",
          name: Enum.FunctionList.UserList.code,
          component: UserList,
          meta: {
            title: Enum.FunctionList.UserList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/schedule/driver",
          name: Enum.FunctionList.DriverSchedule.code,
          component: DriverSchedule,
          meta: {
            title: Enum.FunctionList.DriverSchedule.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/operation-possible-resource",
          name: Enum.FunctionList.OperationResource.code,
          component: OperationResource,
          meta: {
            title: Enum.FunctionList.OperationResource.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/loading-base",
          name: Enum.FunctionList.LoadingBaseList.code,
          component: LoadingBaseList,
          meta: {
            title: Enum.FunctionList.LoadingBaseList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/client-branch",
          name: Enum.FunctionList.ClientBranch.code,
          component: ClientBranchList,
          meta: {
            title: Enum.FunctionList.ClientBranch.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/unloading-base",
          name: Enum.FunctionList.UnloadingBaseList.code,
          component: UnloadingBaseList,
          meta: {
            title: Enum.FunctionList.UnloadingBaseList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/delivery-company",
          name: Enum.FunctionList.DeliveryCompany.code,
          component: DeliveryCompanyList,
          meta: {
            title: Enum.FunctionList.DeliveryCompany.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/area",
          name: Enum.FunctionList.AreaList.code,
          component: AreaList,
          meta: {
            title: Enum.FunctionList.AreaList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/boilerplate",
          name: Enum.FunctionList.BoilerplateList.code,
          component: BoilerplateList,
          meta: {
            title: Enum.FunctionList.BoilerplateList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/signature",
          name: Enum.FunctionList.SignatureList.code,
          component: SignatureList,
          meta: {
            title: Enum.FunctionList.SignatureList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/item",
          name: Enum.FunctionList.ItemTypeList.code,
          component: ItemTypeList,
          meta: {
            title: Enum.FunctionList.ItemTypeList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/billing-type",
          name: Enum.FunctionList.BillingTypeList.code,
          component: BillingTypeList,
          meta: {
            title: Enum.FunctionList.BillingTypeList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/role",
          name: Enum.FunctionList.RoleList.code,
          component: RoleList,
          meta: {
            title: Enum.FunctionList.RoleList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/client",
          name: Enum.FunctionList.GeneralClientList.code,
          component: GeneralClientList,
          meta: {
            title: Enum.FunctionList.GeneralClientList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/client-loading-base-class",
          name: Enum.FunctionList.ClientLoadingBaseClass.code,
          component: LoadingBaseClassList,
          meta: {
            title: Enum.FunctionList.ClientLoadingBaseClass.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/client-unloading-base-class",
          name: Enum.FunctionList.ClientUnloadingBaseClass.code,
          component: UnloadingBaseClassList,
          meta: {
            title: Enum.FunctionList.ClientUnloadingBaseClass.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/export-template",
          name: Enum.FunctionList.ExportTemplateList.code,
          component: ExportTemplateList,
          meta: {
            title: Enum.FunctionList.ExportTemplateList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/tariff",
          name: Enum.FunctionList.TariffList.code,
          component: TariffList,
          meta: {
            title: Enum.FunctionList.TariffList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/billing-item",
          name: Enum.FunctionList.BillingItemList.code,
          component: BillingItemList,
          meta: {
            title: Enum.FunctionList.BillingItemList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/payment-item",
          name: Enum.FunctionList.PaymentItemList.code,
          component: PaymentItemList,
          meta: {
            title: Enum.FunctionList.PaymentItemList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/base-distance",
          name: Enum.FunctionList.BaseDistanceList.code,
          component: BaseDistanceList,
          meta: {
            title: Enum.FunctionList.BaseDistanceList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/payment-tariff-type",
          name: Enum.FunctionList.PaymentTariffTypeList.code,
          component: PaymentTariffTypeList,
          meta: {
            title: Enum.FunctionList.PaymentTariffTypeList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/payment-tariff-rule",
          name: Enum.FunctionList.PaymentTariffRuleList.code,
          component: PaymentTariffRuleList,
          meta: {
            title: Enum.FunctionList.PaymentTariffRuleList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/fixed-billing-rate",
          name: Enum.FunctionList.FixedBillingRateList.code,
          component: FixedBillingRateList,
          meta: {
            title: Enum.FunctionList.FixedBillingRateList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/fixed-payment-rate",
          name: Enum.FunctionList.FixedPaymentRateList.code,
          component: FixedPaymentRateList,
          meta: {
            title: Enum.FunctionList.FixedPaymentRateList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/fixed-billing-rate/import",
          name: Enum.FunctionList.FixedBillingRateImport.code,
          component: FixedBillingRateImport,
          meta: {
            title: Enum.FunctionList.FixedBillingRateImport.label,
            requireAuth: true,
            authName: Enum.FunctionList.FixedBillingRateList.code,
            parent: "maintenance"
          }
        },
        {
          path: "management/fixed-payment-rate/import",
          name: Enum.FunctionList.FixedPaymentRateImport.code,
          component: FixedPaymentRateImport,
          meta: {
            title: Enum.FunctionList.FixedPaymentRateImport.label,
            requireAuth: true,
            authName: Enum.FunctionList.FixedPaymentRateList.code,
            parent: "maintenance"
          }
        },
        {
          path: "management/tariff/import",
          name: Enum.FunctionList.TariffImport.code,
          component: TariffImport,
          meta: {
            title: Enum.FunctionList.TariffImport.label,
            requireAuth: true,
            authName: Enum.FunctionList.TariffList.code,
            parent: "maintenance"
          }
        },
        {
          path: "management/base-distance/import",
          name: Enum.FunctionList.BaseDistanceImport.code,
          component: BaseDistanceImport,
          meta: {
            title: Enum.FunctionList.BaseDistanceImport.label,
            requireAuth: true,
            authName: Enum.FunctionList.BaseDistanceList.code,
            parent: "maintenance"
          }
        },
        {
          path: "management/payment-tariff-rule/import",
          name: Enum.FunctionList.PaymentTariffRuleImport.code,
          component: PaymentTariffRuleImport,
          meta: {
            title: Enum.FunctionList.PaymentTariffRuleImport.label,
            requireAuth: true,
            authName: Enum.FunctionList.PaymentTariffRuleList.code,
            parent: "maintenance"
          }
        },
        {
          path: "management/payment-type",
          name: Enum.FunctionList.PaymentTypeList.code,
          component: PaymentTypeList,
          meta: {
            title: Enum.FunctionList.PaymentTypeList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "management/dispatch-pattern",
          name: Enum.FunctionList.DispatchPatternList.code,
          component: DispatchPatternList,
          meta: {
            title: Enum.FunctionList.DispatchPatternList.label,
            requireAuth: true,
            parent: "maintenance"
          }
        },
        {
          path: "dispatch/schedule",
          name: Enum.FunctionList.DispatchSchedule.code,
          component: DispatchSchedule,
          meta: {
            title: Enum.FunctionList.DispatchSchedule.label,
            requireAuth: true,
            parent: "dispatch"
          }
        },
        {
          path: "dispatch/mail",
          name: Enum.FunctionList.DispatchMailList.code,
          component: DispatchMailList,
          meta: {
            title: Enum.FunctionList.DispatchMailList.label,
            requireAuth: true,
            parent: "dispatch"
          }
        },
        {
          path: "dynamic/tracking",
          name: Enum.FunctionList.DynamicTracking.code,
          component: DynamicTracking,
          meta: {
            title: Enum.FunctionList.DynamicTracking.label,
            requireAuth: true,
            parent: "dynamic"
          }
        },
        {
          path: "error/403",
          name: "Forbidden",
          component: Forbidden,
          meta: {
            title: "エラー(Forbidden)",
            requireAuth: true
          }
        },
        {
          path: "report/service-contact",
          name: Enum.FunctionList.ReportServiceContact.code,
          component: ServiceContactReport,
          meta: {
            title: Enum.FunctionList.ReportServiceContact.label,
            requireAuth: true,
            parent: "report"
          }
        },
        {
          path: "report/transport-order",
          name: Enum.FunctionList.TransportOrderReport.code,
          component: TransportOrderReport,
          meta: {
            title: Enum.FunctionList.TransportOrderReport.label,
            requireAuth: true,
            parent: "report"
          }
        },
        {
          path: "report/billing-statement",
          name: Enum.FunctionList.ExportBillingStatement.code,
          component: ExportBillingStatement,
          meta: {
            title: Enum.FunctionList.ExportBillingStatement.label,
            requireAuth: true,
            parent: "report"
          }
        },
        {
          path: "report/payment-statement",
          name: Enum.FunctionList.ExportPaymentStatement.code,
          component: ExportPaymentStatement,
          meta: {
            title: Enum.FunctionList.ExportPaymentStatement.label,
            requireAuth: true,
            parent: "report"
          }
        },
        {
          path: "report/payment-prompt",
          name: Enum.FunctionList.ExportPaymentPrompt.code,
          component: ExportPaymentPrompt,
          meta: {
            title: Enum.FunctionList.ExportPaymentPrompt.label,
            requireAuth: true,
            parent: "report"
          }
        },
        {
          path: "export/order-and-card",
          name: Enum.FunctionList.ExportOrderAndCard.code,
          component: ExportOrderAndCard,
          meta: {
            title: Enum.FunctionList.ExportOrderAndCard.label,
            requireAuth: true,
            parent: "export"
          }
        },
        {
          path: "finance/billing_and_payment",
          name: Enum.FunctionList.BillingAndPayment.code,
          component: BillingAndPayment,
          meta: {
            title: Enum.FunctionList.BillingAndPayment.label,
            requireAuth: true,
            parent: "finance"
          }
        },
        {
          path: "finance/expenses-import",
          name: Enum.FunctionList.ExpensesImport.code,
          component: ExpensesImport,
          meta: {
            title: Enum.FunctionList.ExpensesImport.label,
            requireAuth: true,
            parent: "finance"
          }
        }
      ]
    },
    { path: "*", redirect: "/tms" },
    {
      path: "/tms/dispatch/mail/confirm",
      name: "DispatchConfirm",
      component: DispatchConfirm
    }
  ]
});

router.beforeResolve((to, from, next) => {
  const systemName = "DisPatch one";
  if (to.meta.title !== undefined && to.meta.title !== "") {
    store.commit("site/setPageTitle", to.meta.title);
    document.title = to.meta.title + " | " + systemName;
  } else {
    document.title = systemName;
  }

  // 他のViewで下までスクロールしてた時、継ぎのViewでもそのスクロール位置が引き継がれるのでリセット
  window.scrollTo(0, 0);

  if (to.meta.requireAuth) {
    const context = {
      from,
      next,
      router,
      to
    };

    let auth = ({ next, router, to }) => {
      const app = router.app;

      if (to.name === "dashBoard" || to.name === "Forbidden") {
        return true;
      } else {
        const name = to.meta.authName ? to.meta.authName : to.name;
        return app.$store.getters["site/canReadFeature"](name);
      }
    };

    initialize({ ...context })
      .then(() => {
        //アクセス可能か
        if (auth({ ...context })) {
          return next();
        } else {
          return next("/tms/error/403");
        }
      })
      .catch(reason => {
        return next("/login");
      });
  } else {
    return next();
  }
});

async function initialize({ next, router }) {
  const app = router.app;
  return app.$store
    .dispatch("site/fetchInitData")
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export default router;
