<template lang="pug">
  section
    v-layout(row wrap class="file-upload-box")
      v-flex(xs12 md4)
        FileUploadBox(v-bind:selected-file.sync="uploadFile" v-bind:errors="getErrors" :loading="getLoadingStatus")

      v-flex(xs12 md4)
        v-btn(color="success" @click="upload" :loading="getLoadingStatus") アップロード

    AlertBox(ref="errorMsg")
</template>

<script>
import FileUploadBox from "@/components/common/FileUploadBox";
import ApiHandler from "@/mixin/ApiHandler";
import AlertBox from "@/components/common/AlertBox";
import { LabelName } from "@/assets/js/constants";
import AxiosBase from "assets/js/axios-base";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FileUploadBox,
    AlertBox
  },
  mixins: [ApiHandler],
  data: function () {
    return {
      labelName: LabelName,
      files: [],
      uploadFile: {
        file: "",
        fileName: "",
        fileUrl: ""
      },
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      getLoadingStatus: "indicator/getLoadingStatus"
    }),
    getErrors() {
      return this.errors;
    }
  },
  methods: {
    ...mapActions("site", ["uploadFileCheck"]),
    selectedFile: function (e) {
      // 選択された File の情報を保存しておく
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
    },
    upload: async function () {
      await this.uploadFileCheck()
        .then(value => {
          this.$emit("upload");
          this.$refs.errorMsg.nonDisplayMessage();
          this.errors = {};
          // FormData を利用して File を POST する
          let self = this;
          let viewData = self.form;
          let formData = new FormData();
          if (this.uploadFile.file !== "") {
            formData.append("uploadFile", this.uploadFile.file);
          }
          Object.keys(self.form).forEach(function (key) {
            formData.append(key, viewData[key]);
          });

          const payload = {
            data: formData,
            uri: new AxiosBase().getUrls().expensesImportFile
          };

          this.$store
            .dispatch("indicator/indicate", payload)
            .then(token => {
              self.$store.dispatch("finance/writeToken", token).then(() => {
                self.$store.dispatch("finance/uploadValidation").finally(() => {
                  self.$emit("importedFile");
                });
              });
            })
            .catch(error => {
              console.log(error);
              if (error["fieldErrors"]) {
                this.errors = error["fieldErrors"];
              } else {
                this.openErrorMsgBox(error.message);
              }
            });
        })
        .catch(reason => {
          console.log(reason);
          const event = new CustomEvent(window.Common.Config.observer.onErrorResponse400, {
            detail: {
              message: reason
            }
          });
          window.dispatchEvent(event);
        });
    },
    openErrorMsgBox(message) {
      this.$refs.errorMsg.displayMessage(message);
    }
  }
};
</script>

<style lang="stylus">
.file-upload-box {
  align-items: baseline;
}
</style>
