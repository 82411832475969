import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  clientLoadingBaseClassList: [],
  clientUnloadingBaseClassList: []
};

const actions = {
  // 荷主積地分類の情報リストを検索取得
  async fetchAllLoadingBaseClassList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllLoadingBaseClassList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearLoadingList");
        }
        commit("appendLoadingList", value.data.data == null ? [] : value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  // 選択された荷主積地分類の情報を取得
  async fetchLoadingBaseClassOne({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getLoadingBaseClass + id).then(value => {
      return Promise.resolve(value.data.data);
    });
  },

  // 荷主卸地分類の情報リストを検索取得
  async fetchAllUnloadingBaseClassList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllUnloadingBaseClassList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearUnloadingList");
        }
        commit("appendUnloadingList", value.data.data == null ? [] : value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  // 選択された荷主卸地分類の情報を取得
  async fetchUnloadingBaseClassOne({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getUnloadingBaseClass + id).then(value => {
      return Promise.resolve(value.data.data);
    });
  }
};

const mutations = {
  clearLoadingList(state) {
    state.clientLoadingBaseClassList = [];
  },
  appendLoadingList(state, data) {
    if (data != null) {
      state.clientLoadingBaseClassList = state.clientLoadingBaseClassList.concat(data);
    }
  },

  clearUnloadingList(state) {
    state.clientUnloadingBaseClassList = [];
  },
  appendUnloadingList(state, data) {
    if (data != null) {
      state.clientUnloadingBaseClassList = state.clientUnloadingBaseClassList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
