<template lang="pug">
  div.one-char-chip
    span {{ text }}
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="stylus" scoped>
$size = 24px;

.one-char-chip {
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: 50%;
  text-align: center;
  line-height: $size;
  overflow hidden
}
</style>
