<template lang="pug">
  v-flex(xs12)
    EmergencyErrorDialog(ref="ServerErrorDialog")
    table.accordion-table(class="sticky-table order-table")
      thead
        tr
          th(class="toggle-icon-cell").no-right-border
          th No
          th(width="75px") 配車状況
          th PB No
          th ルートNo
          th 車格
          th 積地
          th(class="time-icon-cell").no-right-border
          th 卸地
          th(width="110px") {{ labelName.LoadingSchedule }}
          th(width="110px") {{ labelName.UnloadingSchedule }}
          th(width="110px") 受注日
          th 距離区分
          th 合計数量
          th 合計重量(Kg)
          th(width="150px").no-right-border 備考
          th
            v-menu(bottom left :close-on-content-click="false")
              v-btn(@click="" icon color="warning" slot="activator")
                v-icon() delete
              v-list(dense)
                v-subheader 選択
                v-list-tile(@click="selectAll(orderListSummary)")
                  v-list-tile-action
                    v-icon() done_all
                  v-list-tile-content 全選択
                v-list-tile(@click="clearAll(orderListSummary)")
                  v-list-tile-action
                    v-icon() clear
                  v-list-tile-content 全解除

                v-divider
                v-subheader 削除
                v-list-tile(@click="deleteConfirm").delete-menu
                  v-list-tile-action
                    v-icon().delete-menu delete_forever
                  v-list-tile-content 削除
      tbody
        template(v-for="(routeRec, indexParent ) in orderListSummary" v-if="orderListSummary.length")
          tr.pointer-dblclick(:class="{ selected : routeRec.isDisplayOrderList, unableEdit: isUnableEdit(routeRec)}"
            @dblclick="openOrderRemarkDialog(routeRec)" class="parent toggle-cell")
            td(class="toggle-cell toggle-icon-cell" @click="toggleDetail(routeRec)").no-right-border
              v-btn(flat icon v-show="!routeRec.isDisplayOrderList")
                v-icon() keyboard_arrow_down
              v-btn(flat icon v-show="routeRec.isDisplayOrderList")
                v-icon() keyboard_arrow_up
            td.text-sm-center() {{ indexParent + 1 }}
            td.text-sm-left() {{ dispatchStatusLabel(routeRec) }}
            td.text-sm-left() {{ routeRec.planBoardNo }}
            td.text-sm-left() {{ routeRec.routeNo }} - {{ routeRec.routeNoSeq }}
            td() {{ routeRec.vehicleCarryWeight.vehicleTypeName }}
            td.text-whitespace-pre() {{ routeRec.details.loadingBaseNames | commaToNr }}
            td(class="time-icon-cell").no-right-border
              SpecifiedTimeTypeIcon.left(v-if="!isNoneSpecifiedTime(routeRec.details.specifiedTimeType)" :specifiedTimeType="routeRec.details.specifiedTimeType")
            td.text-whitespace-pre() {{ routeRec.orderUnloadingBases.unloadingBaseNames | commaToNr}}
            td.text-sm-center() {{ routeRec.loadingDate }}
            td.text-sm-center() {{ routeRec.unloadingDate }}
            td.text-sm-center() {{ routeRec.orderDate }}
            td.text-sm-left() {{ getDistance(routeRec.distanceType) }}
            td.text-sm-right() {{ routeRec.totalInventoryHoldingUnit | addComma }}
            td.text-sm-right() {{ routeRec.totalGrossWeight  }}
            td.no-right-border
              template
                div.remark-box
                  v-tooltip(top)
                    template(v-slot:activator="{ on }")
                      p(v-on="on").remark.whitespace-pre {{ routeRec.remark }}
                    span.whitespace-pre {{ routeRec.remark }}
                  v-tooltip(top :disabled="!routeRec.lastSlideRemark")
                    template(v-slot:activator="{ on }")
                      v-icon(v-if="routeRec.slide" v-on="on").warning-box-icon warning
                    span.whitespace-pre {{ routeRec.lastSlideRemark }}
            td
              v-checkbox.targetSelectCell(:disabled="isUnableEdit(routeRec)" v-model="routeRec.isDeleteTarget")
          tr(v-show="routeRec.isDisplayOrderList")
            td(:colspan="17" class="inner-cell")
              table(class="order-table")
                thead(:class="{ selected : routeRec.isDisplayOrderList }")
                  tr
                    th(width="80px") No
                    th(width="120px") 伝票番号
                    th 荷主枝番
                    th 請求区分
                    th 積地
                    th(class="time-icon-cell").no-right-border
                    th 卸地
                    th 数量
                    th 重量(Kg)
                tbody
                  tr.pointer-dblclick(v-for="(orderRec , indexChild) in routeRec.details.list" ,
                    @dblclick="openOrderRecordDialog(orderRec)").pointer-cursor
                    td.text-sm-center {{ indexParent + 1 }} - {{ indexChild + 1 }}
                    td.text-sm-left {{ orderRec.slipNo }}
                    td.text-sm-left {{ orderRec.clientBranch.branchName }}
                    td.text-sm-left {{ orderRec.billingType.billingTypeName }}
                    td {{ orderRec.loadingBase.displayBaseName }}
                    td(class="time-icon-cell").no-right-border
                      SpecifiedTimeTypeIcon(v-if="!isNoneSpecifiedTime(orderRec.unloadingSpecifiedTime.specifiedTimeType)" :isSmall="true" :specifiedTimeType="orderRec.unloadingSpecifiedTime.specifiedTimeType")
                    td {{ orderRec.unloadingBase.displayBaseName }}
                    td.text-sm-right {{ orderRec.inventoryHoldingUnit | addComma }}
                    td.text-sm-right {{ orderRec.convertedWeight  }}
        template(v-if="!orderListSummary.length" )
          tr
            td(:colspan="17") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
    BjiImportOrderRecordEditDialog(ref="orderDialog" @updated="updateOrder" @deleted="updateOrder",
      :postUrl="$config.urls.postBjiImportOrder" :deleteUrl="$config.urls.deleteBjiImportOrder")
    BjiImportOrderDetailRecordEditDialog(ref="detailDialog" @updated="updateOrder" @deleted="updateOrder",
      :postUrl="$config.urls.postBjiImportOrderDetail" :deleteUrl="$config.urls.deleteBjiImportOrderDetail")
    DeleteConfirmDialog(ref="DeleteConfirmDialog" @yes="deleteRecord"
    , title="削除確認" message="削除しますか？")
</template>

<script>
import { mapGetters } from "vuex";
import EditDialog from "@/components/common/dialog/EditDialog";
import BjiImportOrderDetailRecordEditDialog from "@/components/order/BjiImportOrderDetailRecordEditDialog";
import BjiImportOrderRecordEditDialog from "@/components/order/BjiImportOrderRecordEditDialog";
import SpecifiedTimeTypeIcon from "@/components/common/SpecifiedTimeTypeIcon";
import DeleteConfirmDialog from "@/components/common/dialog/DeleteConfirmDialog";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";

import ScrollHandler from "@/mixin/InfiniteScrollHandler";
import DispatchStatusLabelHandler from "@/mixin/DispatchStatsuLabelHandler";
import Axios from "@/assets/js/axios";
import { LabelName } from "@/assets/js/constants";

let $http = Axios.http;

export default {
  components: {
    SpecifiedTimeTypeIcon,
    EditDialog,
    BjiImportOrderDetailRecordEditDialog,
    BjiImportOrderRecordEditDialog,
    DeleteConfirmDialog,
    EmergencyErrorDialog
  },
  data() {
    return { labelName: LabelName };
  },
  mixins: [ScrollHandler, DispatchStatusLabelHandler],
  methods: {
    /**
     * DriverNameに紐付くルートNoリストの表示非表示を切り替える
     * @param rec
     */
    toggleDetail(rec) {
      rec.isDisplayOrderList = !rec.isDisplayOrderList;
    },
    /**
     * 受注ダイアログ（備考入力）を開く
     * */
    openOrderRemarkDialog(routeRec) {
      this.$refs.orderDialog.openDialog(routeRec.id);
    },
    /**
     * 明細行押下時に詳細を開く
     * @param orderRec
     */
    openOrderRecordDialog(orderRec) {
      this.$refs.detailDialog.openDialog(orderRec.id);
    },
    updateOrder() {
      this.$emit("updatedOrder");
    },
    /**
     * 受注データ取得
     **/
    loadOrders() {
      this.$store.dispatch("order/fetchAllBjiOrderCount", this.searchConditions);
      return this.$store.dispatch(
        "order/fetchAllBjiOrder",
        Object.assign(this.searchConditions, { pageCount: this.pageCount })
      );
    },
    deleteRecord() {
      return $http
        .delete(this.$config.urls.deleteBjiImportOrderList, {
          data: {
            importOrderIds: this.getDeleteTargetList
          }
        })
        .then(() => {
          this.resetPageCount();
          this.loadOrders();
        })
        .catch(error => {
          if (error.response.data.message !== "") {
            this.$refs.ServerErrorDialog.open(error.response.data.message);
          }
        });
    },
    deleteConfirm() {
      this.$refs.DeleteConfirmDialog.openDialog();
    },
    selectAll(orderListSummary) {
      orderListSummary
        .filter(data => !data.dispatched && !data.relayed)
        .map(data => (data.isDeleteTarget = true));
    },
    clearAll(orderListSummary) {
      orderListSummary.map(data => (data.isDeleteTarget = false));
    },
    isNoneSpecifiedTime(value) {
      return value === "00";
    }
  },
  computed: {
    ...mapGetters({
      getDistance: "site/distanceTypeStringFromKey"
    }),
    orderListSummary: {
      get() {
        return this.$store.state.order.bjiOrderList.orderList;
      }
    },
    getDeleteTargetList: {
      get() {
        return this.orderListSummary.filter(data => data.isDeleteTarget).map(v => v.id);
      }
    },
    hasDeleteTarget: {
      get() {
        return this.getDeleteTargetList.length > 0;
      }
    },
    isUnableEdit: function () {
      return function (routeRec) {
        return routeRec.dispatched || routeRec.relayed;
      };
    }
  },
  mounted() {
    this.$store.state.order.bjiOrderList.orderList = [];
  },
  destroyed() {
    this.$store.state.order.bjiOrderList.orderList = [];
  }
};
</script>

<style scoped lang="stylus">
.accordion-table {
  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }
}

.icon-relay-specified {
  $iconSize = 18px;
  display: inline-block;
  width: $iconSize;
  height: $iconSize;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  background-color: red;

  .v-icon {
    font-size: 15px;
    color: white;
  }
}

table.order-table .remark {
  display: inline-block;
  vertical-align: middle;
}

.warning-box-icon {
  color: #FF5722;
}

.targetSelectCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-menu {
  /* background-color red */
  color: red;
}

>>>.v-input--selection-controls__input {
  margin-right: 0;
}
</style>
