<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      :label="labelDisp"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="actionName")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler.js";
import MasterSelectDialog from "@/mixin/MasterSelectDialog.js";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    labelDisp: {
      type: String,
      default: "乗務員"
    },
    errorHandlerMethod: {
      type: Function,
      required: false,
      default() {
        return null;
      }
    },
    actionName: {
      type: String,
      default: () => "driver/fetchAllDriver"
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "driverId",
      config: {
        title: "乗務員",
        headers: [
          {
            text: "氏名",
            key: "name",
            align: false
          },
          {
            text: "所属配送会社",
            key: "deliveryCompanyName",
            align: false
          }
        ]
      },
      initSearchCond: () => {
        return {
          name: "",
          deliveryCompanyName: ""
        };
      }
    };
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      let isChanged = this.p_code !== data.id;
      this.p_code = data.id;
      this.p_label = data.name;
      if (isChanged) this.$emit("changeItem", data);
    }
  }
};
</script>

<style lang="stylus" scoped>
.container {
  padding: 9px;
}

.v-card__text {
  padding-top: 0;
}

.fixed {
  position: sticky;
  top: 0;
  background-color: #fff;
}

thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 55px;
  z-index: 1;
}

table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
}

tbody tr:hover {
  background-color: #f5f5f5;
  transition: 0.2s;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
}

.th-text {
  font-size: 13px;
}

.v-text-field {
  padding-top: 8px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 14px;
  font-weight: 400;
}
</style>
