import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import * as Enum from "@/assets/js/enum";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  global: {
    ccBjiClientId: 1,
    TRASCOCompanyId: 1,
    billingTypeNormalId: 1000,
    billingTypeNoneId: 1002,
    paymentTypeNormalId: 1,
    responseError: {
      code: "",
      message: ""
    },
    bjiImport: {
      maxUploadFileSize: 300 * 1024
    }
  },
  pageInfo: {
    title: ""
  },
  //ENUMではなくDBの値なのでAPIで取得する
  vehicleOptionTypes: [],
  bjiTransportTypeIds: [],
  billingTypes: [],
  paymentTypes: [],
  functionList: [],
  vehicleTypes: [],
  primaryVehicleTypes: [],
  vehicleTypeIds: [],
  vehicleCarryWeightList: [],
  templateCodeList: [],
  loginUserInfo: {},
  authorities: [],
  // 共通アラート用メッセージ
  commonAlertMessage: "",
  signalCount: 0,
  //ファイルアップロードINPUTで利用する
  uploadTargetFile: {}
};

const getters = {
  bjiMaxUploadFileSize: state => state.global.bjiImport.maxUploadFileSize,
  /**
   * ページタイトルの取得
   */
  pageTitle: state => {
    return state.pageInfo.title;
  },
  siteTitle: () => {
    return process.env.VUE_APP_SITE_TITLE;
  },
  siteColor: () => {
    return process.env.VUE_APP_SITE_COLOR;
  },
  authorities: state => {
    return state.authorities;
  },
  distanceTypeStringFromKey: state => key => {
    return Enum.Utils.findLabel(key, Enum.DistanceType);
  },
  canReadFeature: state => routeName => {
    let auth = state.authorities.find(value => value.functionCode === routeName);
    if (auth === undefined) {
      return false;
    } else {
      return auth.roleBit > 0;
    }
  },
  canEditFeature: state => routeName => {
    let auth = state.authorities.find(value => value.functionCode === routeName);
    if (auth === undefined) {
      return false;
    } else {
      return auth.roleBit > 1;
    }
  },
  vehicleTypeLabelFromWeightId: state => id => {
    if (state.vehicleCarryWeightList == null) {
      return "";
    } else {
      const data = state.vehicleCarryWeightList.find(rec => {
        return rec.id === id;
      });

      return data == null ? "不明" : data.vehicleTypeName;
    }
  },
  mailTypeStringFromKey: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.MailType);
  },
  mailStatusTypeStringFromKey: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.MailStatusType);
  },
  floorSpecFromCd: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.VehicleFloorType, "不明");
  },
  shiftTypeFromCd: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.ShiftType, "不明");
  },
  contractTypeFromCd: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.ContractType, "不明");
  },
  statusFromCd: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.ActiveFlag, "不明");
  },
  contractSignedFromCd: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.ExistType, "不明");
  },
  vehicleMaintenanceTypeFromCd: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.VehicleMaintenanceType, "不明");
  },
  importResultTypeStringFromKey: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.ImportResultType);
  },
  importTargetTypeStringFromKey: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.ImportTargetType);
  },
  // リフトCDからリフトのラベルを取得
  liftTypeLabel: state => key => {
    return Enum.Utils.findLabel(key, Enum.LiftType, "不明");
  },
  //車両オプションIDからラベルを取得
  vehicleOptionLabel: state => key => {
    const ret = state.vehicleOptionTypes.find(value => {
      return value.code === String(key);
    });
    return ret === undefined ? "" : ret.label;
  },
  vehicleOptionFunctions: state => {
    return state.vehicleOptionTypes.filter(value => value.code <= "2");
  },
  vehicleOptionFloors: state => {
    return state.vehicleOptionTypes.filter(value => value.code >= "3");
  },
  loginUserName: state => {
    return state.loginUserInfo.loginUserName;
  },
  getVehicleIdByCd: state => code => {
    const data = state.vehicleTypes.find(vehicleType => vehicleType.code === code);
    return data == null ? null : parseInt(data.id);
  },
  financeTypeLabel: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.FinanceType);
  },
  saleTaxTargetLabel: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.SaleTaxTargetType);
  },
  faxTargetLabel: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.FaxTargetFlag);
  },
  /** GET：テンプレートタブ情報リスト */
  getTemplateTabList: state => {
    /**
     * 処理：サブカテゴリーリストの作成
     * ※サブカテゴリーIDに紐づく詳細（sortedTemplateCodes）の設定
     * @param {Object} element メインカテゴリー1件
     */
    const functionSubCategoryList = element => {
      const copySubCategoryList = Array.isArray(element.subCategoryList)
        ? [...element.subCategoryList]
        : [element.subCategoryList];
      const result = copySubCategoryList.map(subCategory => {
        // ※メインカテゴリー単位で処理されるため、メインカテゴリーIDの比較はなし
        const subCategoryItems = element.sortedTemplateCodes.filter(
          templateItem => templateItem.subCategory.id === subCategory.id
        );
        return { ...subCategory, sortedTemplateCodes: subCategoryItems };
      });
      return result;
    };

    if ("groupByMainCategoryList" in state.templateCodeList) {
      const mainCategoryList = state.templateCodeList.groupByMainCategoryList;
      const result = mainCategoryList.map(item => ({
        id: item.mainCategory.id,
        name: item.mainCategory.name,
        subCategoryList: functionSubCategoryList(item)
      }));
      return result;
    }
  },
  /**
   * サーバーからJSONBで扱われるValidationErrorデータに1件以上データが存在する場合にTrueを返す
   * @returns {function(*=): number}
   */
  isValidError: function () {
    return function (validationError) {
      return Object.keys(validationError).length;
    };
  },
  /**
   *
   * @param state
   * @return {{true: string, false: string}}
   */
  capitalRegisterStatus: state => {
    return {
      true: "登録済",
      false: "未登録"
    };
  }
};

const actions = {
  async fetchInitData({ commit, state }) {
    return $axios
      .get(axiosBase.getUrls().getMeta)
      .then(value => {
        commit("writeMeta", value.data.data);
        return value;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  async fetchAllFunction({ commit, state }) {
    return $axios
      .get(axiosBase.getUrls().getAllFunctionList)
      .then(value => {
        commit("writeAllFunctionData", value.data.data);
        return value.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  async fetchAllVehicleCarryWeight({ commit, state }) {
    return $axios
      .get(axiosBase.getUrls().getAllVehicleCarryWeightList, {
        params: {
          unlimited: true
        }
      })
      .then(value => {
        commit("writeVehicleCarryWeightList", value.data.data);
        return value.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  async fetchAllTemplateCode({ commit, state }) {
    return $axios
      .get(axiosBase.getUrls().getAllTemplateCodeList)
      .then(value => {
        commit("writeAllTemplateCodeList", value.data.data);
        return value.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  async fetchAllBillingType({ commit, state }) {
    return $axios
      .get(axiosBase.getUrls().getAllBillingTypeList)
      .then(value => {
        commit("writeBillingType", value.data.data);
        return value.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  async fetchAllPaymentType({ commit, state }) {
    return $axios
      .get(axiosBase.getUrls().getAllPaymentTypeList)
      .then(value => {
        commit("writePaymentType", value.data.data);
        return value.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  async uploadFileCheck({ state, commit }) {
    let message;
    if (state.uploadTargetFile.size !== undefined) {
      const f = state.uploadTargetFile;

      if (f.size === 0 && Math.abs(new Date().getTime() - f.lastModified) < 1000) {
        message = "ファイルの削除が行われました。ファイルを再度選択してください";
      }

      const hasError = await f
        .slice(0, 1)
        .text()
        .then(() => false)
        .catch(() => true);
      // エラーの有無で処理をハンドリング
      if (hasError) {
        message = "ファイルの更新か削除が行われました。ファイルを再度選択してください";
      }
    }

    return new Promise((resolve, reject) => {
      if (message) {
        reject(message);
      } else {
        resolve();
      }
    });
  }
};

const mutations = {
  setPageTitle(state, title) {
    state.pageInfo.title = title;
  },
  setErrorResponse(state, response) {
    state.global.responseError = response;
  },
  writeMeta(state, data) {
    state.authorities = data.authorities;
    state.vehicleOptionTypes = data.vehicleOptionType;
    state.primaryVehicleTypes = data.primaryVehicleType;
    state.vehicleTypeIds = data.vehicleType;
    state.vehicleTypes = data.vehicleType;
    state.loginUserInfo = data.loginUserInfo;
    state.bjiTransportTypeIds = data.bjiTransportType;
  },
  writeBillingType(state, data) {
    state.billingTypes = data;
  },
  writePaymentType(state, data) {
    state.paymentTypes = data;
  },
  writeAllFunctionData(state, data) {
    state.functionList = data;
  },
  writeVehicleCarryWeightList(state, data) {
    state.vehicleCarryWeightList = data;
  },
  writeAllTemplateCodeList(state, data) {
    state.templateCodeList = data;
  },
  setAlertMessage(state, msg) {
    state.commonAlertMessage = msg;
  },
  countUpSignal(state) {
    state.signalCount++;
  },
  countDownSignal(state) {
    state.signalCount--;
  },
  setUploadTargetFile(state, file) {
    state.uploadTargetFile = file;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
