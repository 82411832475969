import { render, staticRenderFns } from "./DriverBox.vue?vue&type=template&id=07b2f291&scoped=true&lang=pug"
import script from "./DriverBox.vue?vue&type=script&lang=js"
export * from "./DriverBox.vue?vue&type=script&lang=js"
import style0 from "./DriverBox.vue?vue&type=style&index=0&id=07b2f291&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b2f291",
  null
  
)

export default component.exports