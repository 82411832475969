import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  tariffList: [],
  tariffHistoryList: [],
  tariffHistoryDiff: null,
  //ページング用
  paging: {
    historyTablePageCount: 0
  },
  uploadToken: null,
  workVehicleList: [],
  workList: [],
  workListAllCount: 0,
  workListErrorCount: 0
};

const getters = {
  tariffHistoryList: () => state.tariffHistoryList,
  tariffHistoryDiff: () => state.tariffHistoryDiff,
  workList: () => state.workList,
  workVehicleList: () => state.workVehicleList,
  workListAllCount: () => state.workListAllCount,
  workListErrorCount: () => state.workListErrorCount
};

const actions = {
  /**
   * タリフ一覧を取得してStoreする
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<*[] | *>}
   */
  async fetchAll({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllTariffList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllAreaData");
        }
        commit("appendAllAreaData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * 特定タリフIDのタリフ履歴を一括で取得する
   *
   * @param commit
   * @param state
   * @param tariffId
   * @returns {Promise<*[] | *>}
   */
  async fetchTariffHistoryList({ commit, state }, tariffId) {
    let getParams = {};
    const mergedParams = Object.assign(getParams, {
      pageCount: state.paging.historyTablePageCount
    });
    return $axios
      .get(axiosBase.getUrls().getAllTariffHistoryList + tariffId, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.historyTablePageCount === 0) {
          commit("clearAllTariffHistoryList");
        }
        commit("appendTariffHistoryList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * タリフ履歴IDを元にそれとその直前タリフの履歴差分データを取得する
   * @param commit
   * @param state
   * @param tariffHistoryId
   * @returns {Promise<*[] | *>}
   */
  async fetchTariffDiff({ commit, state }, tariffHistoryId) {
    return $axios.get(axiosBase.getUrls().getTariffHistoryDiff + tariffHistoryId).then(value => {
      let data = value.data.data == null ? [] : value.data.data;
      commit("saveTariffHistoryDiff", data);
      return Promise.resolve(data);
    });
  },
  /**
   * 履歴表示ダイアログ用のデータをクリア
   * @param commit
   * @returns {Promise<void>}
   */
  async clearHistoryData({ commit }) {
    commit("clearTariffHistoryDiff");
    commit("clearTariffHistoryList");
  },
  async fetchAllUsable({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllUsableTariffList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllAreaData");
        }
        commit("appendAllAreaData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchOne({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getTariff + id).then(value => {
      return Promise.resolve(value.data.data);
    });
  },
  async fetchTariffUseVehicleTypeList({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getTariffUseVehicleTypeList).then(value => {
      return Promise.resolve(value.data.data);
    });
  },
  async fetchTariffTagList({ commit, state }, name) {
    const paramName = name ? name : "";
    return $axios.get(axiosBase.getUrls().getTariffTagList + paramName).then(value => {
      return Promise.resolve(value.data.data != null ? value.data.data : []);
    });
  },
  async fetchTariffTagOne({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getTariffTag + id).then(value => {
      return Promise.resolve(value.data.data);
    });
  },
  async saveTariffTag({ commit, state }, tagName) {
    return $axios
      .post(axiosBase.getUrls().postRegisterTariffTag, {
        tagName: tagName
      })
      .then(value => {
        return Promise.resolve(value.data.data);
      });
  },
  async updateTariffTag({ commit, state }, tag) {
    return $axios.post(axiosBase.getUrls().postUpdateTariffTag, tag).then(value => {
      return Promise.resolve(value.data.data);
    });
  },
  async deleteTariffTag({ commit, state }, tag) {
    return $axios
      .delete(axiosBase.getUrls().deleteTariffTag + tag.id)
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  async uploadImportFile({ commit, state }, data) {
    let config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    return $axios
      .post(axiosBase.getUrls().uploadTariffImportWork, data, config)
      .then(value => {
        commit("writeUploadToken", value.data.data);
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async uploadValidation({ commit, state }) {
    return $axios
      .post(axiosBase.getUrls().uploadTariffImportWorkValidation, { ...{ token: state.uploadToken } })
      .then(() => {
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async fetchTariffWorkList({ state, commit }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getTariffWorkList, {
        params: { ...getParams, ...{ token: state.uploadToken } }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllWorkData");
          commit("initVehicleTypeList", value.data.data);
        }
        commit("appendAllWorkData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchTariffWorkListCount({ state, commit }) {
    return $axios
      .get(axiosBase.getUrls().getTariffWorkListCount, {
        params: { ...{ token: state.uploadToken } }
      })
      .then(value => {
        commit("writeWorkCount", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async commitImport({ commit, state }) {
    return $axios
      .post(axiosBase.getUrls().uploadTariffImportWorkCommit, { ...{ token: state.uploadToken } })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async clearWorkData({ commit }) {
    commit("clearAllWorkData");
  },
  // 実績出力
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportTariff, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllAreaData(state) {
    state.tariffList = [];
  },
  appendAllAreaData(state, data) {
    if (data != null) {
      state.tariffList = state.tariffList.concat(data);
    }
  },
  clearAllTariffHistoryList(state) {
    state.tariffHistoryList = [];
  },
  appendTariffHistoryList(state, data) {
    if (data != null) {
      state.tariffHistoryList = state.tariffHistoryList.concat(data);
    }
  },
  saveTariffHistoryDiff(state, data) {
    state.tariffHistoryDiff = data;
  },
  clearTariffHistoryList(state) {
    state.tariffHistoryList = [];
  },
  clearTariffHistoryDiff(state) {
    state.tariffHistoryDiff = null;
  },
  incrementHistoryTablePageCount(state) {
    state.paging.historyTablePageCount++;
  },
  clearHistoryTablePageCount(state) {
    state.paging.historyTablePageCount = 0;
  },
  writeUploadToken(state, token) {
    state.uploadToken = token;
  },
  clearAllWorkData(state) {
    state.workList = [];
  },
  writeWorkCount(state, data) {
    state.workListAllCount = data.allCount;
    state.workListErrorCount = data.errorCount;
  },
  initVehicleTypeList(state, data) {
    if (data) {
      state.workVehicleList = data[0].detailWorkLIst;
    }
  },
  appendAllWorkData(state, data) {
    if (data != null) {
      state.workList = state.workList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
