<template lang="pug">
  v-flex(xs12 v-show="hasData" mt-4)
    v-alert(type="info" value="true") {{ records.resultMessage }}
    table.paging-table(class="order-table")
      thead
        tr
          th(width="80px") 処理結果
          th(width="90px") 該当Excel行
          th メッセージ
          th(colspan=2 width="300px") エラー項目
          th(colspan=2 width="300px") キー項目
      tbody
        tr(v-for="record in records.list")
          td.text-md-left {{ getImportResultTypeLabel(record.resultType) }}
          td.text-md-right {{ record.lineNo }}
          td.text-md-left {{ displayMessage(record) }}
          td.no-right-border.text-whitespace-pre.text-md-left {{ getFieldErrorKeys(record.fieldErrors) | commaToNr }}
          td.text-whitespace-pre.text-md-left {{ Object.values(record.fieldErrors) | commaToNr }}
          //- td.text-md-left {{ fieldErrorJoin(record.fieldErrors) }}
          td.no-right-border.text-md-left.text-whitespace-pre.vertical-align-top {{ subjectKey | commaToNr }}
          td.text-md-left.text-whitespace-pre.vertical-align-top {{ record.subject | commaToNr }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      subjectKey: "プランボード番号,ルートNo＋SEQ,シップメントNo:積地CD",
      records: {
        list: [],
        resultMessage: ""
      }
    };
  },
  methods: {
    loadData(data) {
      this.records = data;
    },
    fieldErrorJoin(data) {
      let errors = [];
      for (var key in data) {
        errors.push(key + ":" + data[key]);
      }
      return errors.join(",");
    },
    displayMessage(record) {
      if (record.updateDifferenceMessage) {
        return record.message + "(" + record.updateDifferenceMessage + ")";
      }
      return record.message;
    },
    getFieldErrorKeys(fieldErrors) {
      let newList = [];
      Object.keys(fieldErrors).forEach(key => {
        let value = fieldErrors[key];
        newList.push(key);
        if (value.length > 1) {
          newList.push(" ");
        }
      });
      return newList;
    }
  },
  computed: {
    ...mapGetters({
      getImportResultTypeLabel: "site/importResultTypeStringFromKey"
    }),
    hasData() {
      if (this.records == null) {
        return false;
      }
      return this.records.list.length > 0;
    }
  }
};
</script>

<style lang="stylus" scoped>
table.paging-table {
  tbody tr:hover {
    td, th {
      background-color: #f5f5f5;
      transition: 0.2s;
    }
  }

  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;

  td {
    height: 48px;
    border: solid #dcdcdc;
    border-width: 1px 0px;
    vertical-align: middle;
    padding-left: 8px;
    padding-right: 8px;
  }

  thead {
    tr {
      height: 54px;
    }

    th {
      background-color: #fff;
      position: sticky;
      top: 45px;
      z-index: 1;
    }
  }

  tbody {
    tr {
      transition: 0.3s;
    }

    tr.selected {
      background: #ECEFF1;

      td {
        background: none;
      }
    }
  }

  .record-result {
    width: 80px;
  }
}

.vertical-align-top {
  vertical-align: top;
}
</style>
