<template lang="pug">
  section
    v-text-field(
      readonly
      :value="displayName"
      :label="formLabel"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :error-messages="errorMessages")
    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="'boilerplate/fetchSelectList'")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    title: {
      type: String,
      default: () => ""
    },
    selectName: {
      type: String,
      default: () => ""
    },
    selectItems: {
      type: Array,
      required: true
    },
    formLabel: {
      type: String,
      required: true
    },
    errorMessages: {
      type: String,
      default: () => null
    }
  },
  components: { PagingMasterSelectDialog },
  computed: {
    // 読取専用テキスト表示用
    displayName: {
      get() {
        return this.selectName;
      },
      set(changeValue) {
        this.$emit("input", changeValue);
      }
    },
    // 選択項目ダイアログ設定
    selectConfig: {
      get() {
        const config = {
          title: this.title,
          width: "30%",
          headers: [{ text: "タイトル", key: "title", align: "" }]
        };
        return config;
      }
    },
    config: () => {
      return {
        title: "基本車両選択",
        headers: [
          {
            text: "タイトル",
            key: "title",
            align: ""
          },
          {
            text: "定型文本文",
            key: "body",
            align: "",
            noSearch: true
          }
        ]
      };
    }
  },
  data() {
    return {
      initSearchCond: () => {
        return {
          fullNumberDisp: "",
          vehicleTypeName: "",
          carryWeightDisp: "",
          vehicleGarageName: ""
        };
      }
    };
  },
  methods: {}
};
</script>
