<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="label"
    item-value="code"
    :clearable="clearable"
    label="床仕様"
    :error-messages="errorHandlerMethod('floorSpec')")
</template>

<script>
import * as Enum from "@/assets/js/enum";

export default {
  props: {
    // TODO:type:String 必須でありながらNullがはいる可能性があるため回避。（※clearable）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default() {
        return null;
      }
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return Enum.Utils.enumToList(Enum.VehicleFloorType);
      }
    }
  },
  methods: {}
};
</script>
