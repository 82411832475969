export default {
  data() {
    return {
      loading: {
        export: false
      },
      searchCond: {}
    };
  },
  methods: {
    exportData(exportUrl) {
      let self = this;
      self.loading.export = true;
      return this.$store
        .dispatch(exportUrl, self.searchCond)
        .catch(error => {
          let event;
          event = document.createEvent("Event");
          if (error.response.status === 404) {
            event.initEvent(window.Common.Config.observer.onCommonErrorResponse404, false, true);
          }
          // レスポンスがblob形式のため400エラーの時は中のメッセージが存在するかチェック
          else if (error.response.status === 400 && error.response.data) {
            let reader = new FileReader();
            reader.readAsText(error.response.data);
            reader.onload = function (evt) {
              const data = reader.result;
              const json = JSON.parse(data);
              if (json.message) {
                event = new CustomEvent(window.Common.Config.observer.onErrorResponse400, {
                  detail: json
                });
                window.dispatchEvent(event);
              }
            };
            return;
          } else if (error.response.status === 403 && error.response.data) {
            let reader = new FileReader();
            reader.readAsText(error.response.data);
            reader.onload = function (evt) {
              const data = reader.result;
              const json = JSON.parse(data);
              if (json.message) {
                event = new CustomEvent(window.Common.Config.observer.onErrorResponse403, {
                  detail: json
                });
                window.dispatchEvent(event);
              }
            };
            return;
          } else {
            event.initEvent(window.Common.Config.observer.onCommonErrorResponse400, false, true);
          }
          window.dispatchEvent(event);
          return Promise.reject(error);
        })
        .then(value => {
          return Promise.resolve(value);
        })
        .finally(() => {
          self.loading.export = false;
        });
    }
  }
};
