<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="支払CD" v-model="conditions.paymentCd")
        v-flex(md2)
          v-text-field(label="名称" v-model="conditions.paymentTypeName")
        v-flex(md2)
          FinanceTypeSelect(:code.sync="conditions.financeType" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import FinanceTypeSelect from "@/components/common/select/FinanceTypeSelect";

export default {
  components: {
    SearchConditions,
    FinanceTypeSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          paymentCd: "",
          paymentTypeName: "",
          financeType: ""
        }
      };
    }
  }
};
</script>
