import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  loadingBaseList: [],
  loadingBaseClassList: [],
  unloadingBaseList: [],
  unloadingBaseClassList: []
};

const actions = {
  /**
   * 積地マスタ全件対象の検索用APIから取得
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<any>}
   */
  async fetchAllLoadingBase({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllLoadingBaseList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllLoadingBaseData");
        }
        commit("appendAllLoadingBaseData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * 積地セレクト用
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<T>}
   */
  async fetchLoadingBaseForSelect({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getLoadingBaseForSelect, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async fetchAllLoadingBaseClass({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getAllLoadingBaseClass).then(value => {
      commit("writeAllLoadingBaseClassData", value.data.data);
    });
  },
  async fetchLoadingBase({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getAllLoadingBase + id).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },

  /**
   * 卸地マスタメンテ一覧
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<T>}
   */
  async fetchAllUnloadingBase({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllUnloadingBaseList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllUnloadingBaseData");
        }
        commit("appendAllUnloadingBaseData", value.data.data);

        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * 卸地セレクト用
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<T>}
   */
  async fetchUnloadingBaseForSelect({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getUnloadingBaseForSelect, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async fetchAllUnloadingBaseClass({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getAllUnloadingBaseClass).then(value => {
      commit("writeAllUnloadingBaseClassData", value.data.data);
    });
  },
  async fetchUnloadingBase({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getAllUnloadingBase + id).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 積地実績出力
  async exportLoadingBase({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportLoadingBase, {
      params: getParams
    });
  },
  // 卸地実績出力
  async exportUnloadingBase({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportUnloadingBase, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllLoadingBaseData(state) {
    state.loadingBaseList = [];
  },
  appendAllLoadingBaseData(state, data) {
    if (data != null) {
      state.loadingBaseList = state.loadingBaseList.concat(data);
    }
  },
  writeAllLoadingBaseClassData(state, data) {
    if (data == null) return;
    state.loadingBaseClassList = data;
  },
  clearAllUnloadingBaseData(state) {
    state.unloadingBaseList = [];
  },
  appendAllUnloadingBaseData(state, data) {
    if (data != null) {
      state.unloadingBaseList = state.unloadingBaseList.concat(data);
    }
  },
  writeAllUnloadingBaseClassData(state, data) {
    if (data == null) return;
    state.unloadingBaseClassList = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
