import { render, staticRenderFns } from "./YenTipLabel.vue?vue&type=template&id=28628828&scoped=true&lang=pug"
import script from "./YenTipLabel.vue?vue&type=script&lang=js"
export * from "./YenTipLabel.vue?vue&type=script&lang=js"
import style0 from "./YenTipLabel.vue?vue&type=style&index=0&id=28628828&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28628828",
  null
  
)

export default component.exports