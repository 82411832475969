<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="label"
    item-value="code"
    :label="labelDisp"
    multiple
    small-chips
    :clearable="clearable"
    :box="readonly"
    :readonly="readonly"
    :error-messages="errorHandlerMethod('billingTypeCondition')"
    v-on:blur="changeItem")
</template>

<script>
import * as Enum from "@/assets/js/enum";

export default {
  props: {
    // TODO:type:Arrayと思われるがnullの可能性があるため回避
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default() {
        return null;
      }
    },
    labelDisp: {
      type: String,
      default: "支払区分条件"
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    clearable: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return Enum.Utils.enumToList(Enum.FinanceType);
      }
    }
  },
  methods: {
    changeItem() {
      this.$emit("changeItem", this.p_code);
    }
  }
};
</script>

<style scoped></style>
