import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  fixedBillingRateList: [],
  fixedBillingRateHistoryList: [],
  fixedBillingRateHistoryDiff: null,
  //ページング用
  paging: {
    historyTablePageCount: 0
  }
};

const getters = {
  fixedBillingRateHistoryList: () => state.fixedBillingRateHistoryList,
  fixedBillingRateHistoryDiff: () => state.fixedBillingRateHistoryDiff
};

const actions = {
  async fetchAll({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllFixedBillingRateList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllFixedBillingRateData");
        }
        commit("appendAllFixedBillingRateData", value.data.data);
      });
  },
  async fetchFixedBillingRate({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllFixedBillingRate + id).then(value => {
      return value.data.data;
    });
  },
  /**
   * 特定固定請求料金IDの履歴を一括で取得する
   *
   * @param commit
   * @param state
   * @param fixedBillingRateId
   * @returns {Promise<*[] | *>}
   */
  async fetchFixedBillingRateHistoryList({ commit, state }, fixedBillingRateId) {
    let getParams = {};
    const mergedParams = Object.assign(getParams, {
      pageCount: state.paging.historyTablePageCount
    });
    return $axios
      .get(axiosBase.getUrls().getAllFixedBillingRateHistoryList + fixedBillingRateId, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.historyTablePageCount === 0) {
          commit("clearAllFixedBillingRateHistoryList");
        }
        commit("appendFixedBillingRateHistoryList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * 固定請求料金履歴IDを元にそれとその直前固定請求料金の履歴差分データを取得する
   * @param commit
   * @param state
   * @param fixedBillingRateHistoryId
   * @returns {Promise<*[] | *>}
   */
  async fetchFixedBillingRateDiff({ commit, state }, fixedBillingRateHistoryId) {
    return $axios
      .get(axiosBase.getUrls().getFixedBillingRateHistoryDiff + fixedBillingRateHistoryId)
      .then(value => {
        let data = value.data.data == null ? [] : value.data.data;
        commit("saveFixedBillingRateHistoryDiff", data);
        return Promise.resolve(data);
      });
  },
  /**
   * 履歴表示ダイアログ用のデータをクリア
   * @param commit
   * @returns {Promise<void>}
   */
  async clearHistoryData({ commit }) {
    commit("clearFixedBillingRateHistoryDiff");
    commit("clearFixedBillingRateHistoryList");
  },
  // 実績出力
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getFixedBillingRateExport, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllFixedBillingRateData(state) {
    state.fixedBillingRateList = [];
  },
  appendAllFixedBillingRateData(state, data) {
    if (data != null) {
      state.fixedBillingRateList = state.fixedBillingRateList.concat(data);
    }
  },
  clearAllFixedBillingRateHistoryList(state) {
    state.fixedBillingRateHistoryList = [];
  },
  appendFixedBillingRateHistoryList(state, data) {
    if (data != null) {
      state.fixedBillingRateHistoryList = state.fixedBillingRateHistoryList.concat(data);
    }
  },
  saveFixedBillingRateHistoryDiff(state, data) {
    state.fixedBillingRateHistoryDiff = data;
  },
  clearFixedBillingRateHistoryList(state) {
    state.fixedBillingRateHistoryList = [];
  },
  clearFixedBillingRateHistoryDiff(state) {
    state.fixedBillingRateHistoryDiff = null;
  },
  incrementHistoryTablePageCount(state) {
    state.paging.historyTablePageCount++;
  },
  clearHistoryTablePageCount(state) {
    state.paging.historyTablePageCount = 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
