import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

/** STATE */
const state = {
  exportTemplateList: []
};

/** GETTERS */
const getters = {
  /** GETTER：取得済みリスト */
  getExportTemplateList: state => state.exportTemplateList
};

/** ACTIONS */
const actions = {
  /**
   * 出力テンプレート一覧を検索
   * @param {*} param0 Store
   * @param {*} getParams 検索条件
   */
  async fetchAll({ commit }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllExportTemplateListWithCategory, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllExportTemplateData");
        }
        commit("appendAllExportTemplateData", value.data.data);
      });
  },
  /**
   * 出力テンプレート一覧を検索
   * @param {*} param0 Store
   * @param {*} getParams 検索条件
   */
  async fetchAllForSelect({ commit }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getAllExportTemplateListForSelect, {
        params: getParams
      })
      .then(value => {
        commit("clearAllExportTemplateData");
        commit("appendAllExportTemplateData", value.data.data);
      });
  },
  /**
   * 選択された出力テンプレートの情報を取得
   * @param {*} param0 Store
   * @param {Number} id テンプレートID
   */
  async fetchExportTemplate({ _commit }, id) {
    return await $axios.get(axiosBase.getUrls().getExportTemplate + id).then(value => {
      return value.data.data;
    });
  }
};

/** MUTATIONS */
const mutations = {
  /**
   * COMMIT：リスト初期化
   * @param {*} state Store
   */
  clearAllExportTemplateData(state) {
    state.exportTemplateList = [];
  },
  /**
   * COMMIT：テンプレート情報リスト追加
   * @param {*} state Store
   * @param {*} data テンプレート情報リスト
   */
  appendAllExportTemplateData(state, data) {
    if (data != null) {
      state.exportTemplateList = state.exportTemplateList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
