import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  clientBranchList: []
};

const getters = {};

const actions = {
  // 荷主枝番一覧を検索
  async fetchAll({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllClientBranchList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllClientBranchData");
        }
        commit("appendAllClientBranchData", value.data.data);
      });
  },
  // 選択された荷主枝番の情報を取得
  async fetchClientBranch({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllClientBranch + id).then(value => {
      return value.data.data;
    });
  },
  // 実績出力
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportClientBranch, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllClientBranchData(state) {
    state.clientBranchList = [];
  },
  appendAllClientBranchData(state, data) {
    if (data != null) {
      state.clientBranchList = state.clientBranchList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
