export default {
  props: {
    code: {
      required: true
    },
    label: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      required: true
    },
    errorHandlerMethodArg: {
      type: String,
      default() {
        return null;
      }
    },
    openDialogCallback: {
      type: Function,
      required: false
    },
    selectItemCallback: {
      type: Function,
      required: false
    }
  },
  methods: {
    openDialog(data) {
      if (this.openDialogCallback != null) {
        this.openDialogCallback(data);
      }
      this.$refs.dialog.openDialog(data);
    },
    selectItem(data) {
      this.p_code = data.id;
      this.p_label = data.name;
    }
  },
  computed: {
    p_label: {
      get() {
        return this.label;
      },
      set(newVal) {
        if (this.label !== newVal) {
          this.$emit("update:label", newVal);
          if (newVal == null) {
            this.$emit("update:code", newVal);
          }
        }
      },
      required: true
    },
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    errorHandlerMethodArgComputed: {
      get() {
        if (this.errorHandlerMethodArg == null) {
          return this.defaultErrorHandlerMethodArg;
        }
        return this.errorHandlerMethodArg;
      }
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: ""
    };
  }
};
