import axios from "axios";

const FileDownload = require("js-file-download");

const fireAxiosIndicateStart = () => {
  const event = document.createEvent("Event");
  event.initEvent(window.Common.Config.observer.onAxiosStart, false, true);
  window.dispatchEvent(event);
};

const fireAxiosIndicateEnd = () => {
  const event = document.createEvent("Event");
  event.initEvent(window.Common.Config.observer.onAxiosEnd, false, true);
  window.dispatchEvent(event);
};

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    // "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: true
});

const download = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-type": "application/x-www-form-urlencoded",
    "Content-Disposition": 'attach; filename="report.xlsx"'
  },
  responseType: "blob",
  dataType: "binary",
  data: {},
  withCredentials: true
});

// Add a request interceptor
http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    fireAxiosIndicateStart();
    return config;
  },
  function (error) {
    // Do something with request error
    fireAxiosIndicateStart();
    return Promise.reject(error);
  }
);

let errorHandlerFunc = function (error) {
  fireAxiosIndicateEnd();
  /**
   * 通信出来なかった時にエラーダイアログを表示して強制的にログイン画面に戻す
   */
  const event = document.createEvent("Event");

  if (error.response === undefined) {
    window.Common.Util.setErrorResponse(window.Common.Messages.Common.Error.NetworkClientError);
    event.initEvent(window.Common.Config.observer.onErrorResponseNetworkError, false, true);
    window.dispatchEvent(event);
  } else {
    if (error.response.status === 403) {
      return Promise.reject(error);
    }
    if (error.response.status === 400) {
      return Promise.reject(error);
    }

    window.Common.Util.setErrorResponse(
      error.response.data.message
        ? error.response.data.message
        : window.Common.Messages.Common.Error.InternalServerError
    );

    if (error.response.status === 500) {
      event.initEvent(window.Common.Config.observer.onErrorResponse500, false, true);
      window.dispatchEvent(event);
    }
    if (error.response.status === 401) {
      event.initEvent(window.Common.Config.observer.onErrorResponse401, false, true);
      window.dispatchEvent(event);
    }
  }

  return Promise.reject(error);
};

http.interceptors.response.use(
  function (response) {
    fireAxiosIndicateEnd();
    return response;
  },
  function (error) {
    return errorHandlerFunc(error);
  }
);

download.interceptors.response.use(
  function (response) {
    // ファイル名取得エクセル出力
    const contentDisposition = response.headers["content-disposition"];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      const fileName = matches[1].replace(/['"]/g, "").slice(5);
      FileDownload(response.data, decodeURI(fileName));
      fireAxiosIndicateEnd();
      return response.status;
    }
  },
  function (error) {
    return errorHandlerFunc(error);
  }
);

export default {
  http,
  download,
  fireAxiosIndicateStart,
  fireAxiosIndicateEnd
};
