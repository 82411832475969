<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      VehicleFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              VehicleTypeListTable(
                ref="VehicleTypeListTable"
                :prop-header="vehicleTypeTableHeader"
                :prop-data="vehicleTypeList"
                :getListActionName="getListActionName"
                detail-url="vehicle/fetchVehicleType"
                @open-detail="openDetail")

    VehicleTypeEditDialog(ref="VehicleTypeEditDialog" :postUrl="$config.urls.postEditVehicleType" :deleteUrl="$config.urls.deleteVehicleType" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import VehicleTypeEditDialog from "@/components/management/vehicle-type/VehicleTypeEdit";
import VehicleTypeListTable from "@/components/common/PagingListTable";
import VehicleFilterConditions from "@/components/management/vehicle-type/VehicleTypeFilterConditions";

import _ from "lodash";

export default {
  components: {
    VehicleTypeEditDialog,
    VehicleTypeListTable,
    VehicleFilterConditions
  },
  data() {
    return {
      searchCond: {},
      vehicleTypeTableHeader: [
        { text: "車格CD", value: "vehicleTypeCd", centerAlign: "" },
        { text: "名称", value: "vehicleTypeName", centerAlign: "" },
        { text: "シャーシ利用", value: "useChassisLabel", centerAlign: "" },
        { text: "リソース表示対象", value: "displayResourceTargetLabel", centerAlign: "" },
        { text: "表示順", value: "displayOrder", rightAlign: "true" }
      ]
    };
  },
  computed: {
    getListActionName: {
      get() {
        return "vehicle/fetchAllVehicleType";
      }
    },
    loadingBaseList: {
      get() {
        return this.$store.state.base.loadingBaseList;
      }
    },
    vehicleTypeList: {
      get() {
        if (_.isNil(this.$store.state.vehicle.vehicleTypeList)) return [];
        return this.$store.state.vehicle.vehicleTypeList;
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.VehicleTypeEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.VehicleTypeEditDialog.openDialog(item);
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     **/
    itemUpdated(response, data) {
      this.$refs.VehicleTypeListTable.resetPageCount();
      this.loadListData();
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.VehicleTypeListTable.loadData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.VehicleTypeListTable.setSearchConditions(searchCond);
      this.$refs.VehicleTypeListTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>
