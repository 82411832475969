<template lang="pug">
  EditDialog(ref="EditDialog" title="請求区分" :disabledDelete="record.mainBillingType" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md6)
            v-text-field(v-model="record.billingCd" label="請求CD" :error-messages="getErrorMessages('billingCd')")

          v-flex(xs12 md12)
            v-text-field(v-model="record.billingTypeName" label="名称" :error-messages="getErrorMessages('billingTypeName')")

          v-flex(xs12 md3)
            FinanceTypeSelect(:code.sync="record.financeType" :readonly="record.mainBillingType" :errorHandlerMethod="getErrorMessages")

        v-layout(row wrap)
          v-flex(xs12 md12 v-if="record.mainBillingType")
            div ※主要な請求区分の場合、会計種別の変更、請求区分の削除はできません。
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import FinanceTypeSelect from "@/components/common/select/FinanceTypeSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    FinanceTypeSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: this.initData()
    };
  },
  methods: {
    initData() {
      return {
        id: null,
        billingCd: null,
        billingTypeName: null,
        financeType: Enum.FinanceType.Normal.code
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
