import * as Enum from "@/assets/js/enum";
export default {
  methods: {
    isPending(status) {
      return status === Enum.PaymentStatusType.PENDING.code;
    },
    isTemp(status) {
      return status === Enum.PaymentStatusType.TEMPORARY_CONFIRMED.code;
    },
    isPublished(status) {
      return status === Enum.PaymentStatusType.PUBLISHED.code;
    },
    isConfirmed(status) {
      return status === Enum.PaymentStatusType.CONFIRMED.code;
    }
  }
};
