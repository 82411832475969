<template lang="pug">
  v-menu(v-model="display" :position-x="x",:position-y="y" bottom left)
    v-list(dense)
      v-list-tile(@click="openChangeVehicleDialog")
        v-list-tile-action
          v-icon() local_shipping
        v-list-tile-content 車両変更
      v-list-tile(@click="openDriverDetailDialog")
        v-list-tile-action
          v-icon() info
        v-list-tile-content 乗務員詳細

</template>

<script>
export default {
  data() {
    return {
      display: false,
      x: 0,
      y: 0,
      item: {}
    };
  },
  methods: {
    open(data) {
      this.item = data.item;
      // TODO tickイベント実装？（既に開いていると別のが開かない。二回押下で開くので）
      this.display = false;
      this.x = data.event.clientX;
      this.y = data.event.clientY;
      this.display = true;
    },
    openDriverDetailDialog(event) {
      this.$emit("openDriverDetailDialog", this.item);
    },
    openChangeVehicleDialog(event) {
      this.$emit("openChangeVehicleDialog", { item: this.item, driverId: this.item.driverId });
    }
  }
};
</script>
