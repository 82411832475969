<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="荷主名称" v-model="conditions.clientName")
        v-flex(md2)
          v-text-field(label="積地分類名" v-model="conditions.baseClassName")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";

export default {
  components: {
    SearchConditions
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          baseClassName: ""
        }
      };
    }
  }
};
</script>
