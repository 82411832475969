<template lang="pug">
  section
    EditDialog(ref="EditDialog" title="BJI受注卸順指定" @save="save" @delete="erase"
      :displayDeleteButton="!isDispatched(record) && canEditStatus(record)"
      :enableSave="canEditStatus(record)"
      :loadingDelete="loading.delete" :recordId="record.id")
      template(v-slot:body)
        v-container(grid-list-md)
          v-layout(row v-for="base, index in record.orderUnloadingBases.sortedList" :key="index")
            v-flex(xs12 md11)
              v-text-field(readonly box label="卸地" v-model="base.unloadingOrderBase.displayBaseName")
            v-flex(md1)
              v-text-field.text-align-right(:readonly="!canEditStatus(record)" :box="!canEditStatus(record)" label="卸順" v-model="base.unloadingOrder" :error-messages="getErrorMessages('unloadingOrderList[' + index + '].unloadingOrder')")
          v-layout(row wrap)
            v-flex(md3)
              BillingTypeSelect(:readonly="!canEditStatus(record)" ref="BillingTypeSelect" :code.sync="record.billingType.id" :errorHandlerMethod="getErrorMessages")
            v-flex(md3)
              v-btn(:disabled="!canEditStatus(record)" color="success" round @click="saveBillingTypePreFlight")
                span 保存
          v-layout(row wrap)
            v-flex(xs12)
              v-textarea(:readonly="!canEditStatus(record)" :box="!canEditStatus(record)" label="備考" v-model="record.remark" :error-messages="getErrorMessages('remark')")
              v-textarea(:readOnly="!canEditStatus(record)" :box="!canEditStatus(record)" label="会計備考",auto-grow,v-model="record.financeRemark" :error-messages="getErrorMessages('financeRemark')")
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialog" @yes="saveBillingType")
</template>

<script>
import { mapActions } from "vuex";
import EditDialog from "../common/dialog/EditDialog";
import BillingTypeSelect from "../common/select/BillingTypeSelect";
import ApiHandler from "../../mixin/ApiHandler";
import TimePicker from "@/components/common/TimePicker";
import FinanceOverrideWarningDialog from "@/components/common/dialog/FinanceOverrideWarningDialog";
import * as Enum from "@/assets/js/enum";

export default {
  components: { EditDialog, BillingTypeSelect, TimePicker, FinanceOverrideWarningDialog },
  mixins: [ApiHandler],
  data() {
    return {
      record: this.initData(),
      tranData: {},
      clientId: 0
    };
  },
  methods: {
    ...mapActions("order/", {
      postBillingType: "postBillingType"
    }),
    clear() {
      Object.assign(this.$data.record, this.initData());
      this.errors = {};
    },
    initData() {
      return {
        cardId: "",
        orderId: "",
        remark: "",
        billingType: {
          id: ""
        },
        orderUnloadingBases: {
          list: []
        },
        id: null
      };
    },
    openDialog(orderId) {
      this.clear();
      this.$store
        .dispatch("order/fetchOrder", orderId)
        .then(value => {
          Object.assign(this.$data.record, value);
          this.$refs.EditDialog.openDialog();
        })
        .catch(reason => {
          const event = document.createEvent("Event");
          event.initEvent(window.Common.Config.observer.onErrorResponse404, false, true);
          window.dispatchEvent(event);
        });
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.setTranData();
      this.saveRecord(this.tranData).then(value => {
        self.$refs.EditDialog.closeDialog();
      });
    },
    saveBillingTypePreFlight() {
      this.setTranData();
      this.$refs.FinanceOverrideWarningDialog.checkEditableFinanceInfo(this.tranData.cardId);
    },
    async saveBillingType() {
      const self = this;
      this.setTranData();
      return await this.postBillingType(this.tranData)
        .then(() => {
          const savedMessage = "請求区分を保存しました。";
          this.$refs.EditDialog.openSuccessMsgBox(savedMessage);
          this.$store.dispatch("order/fetchOrder", this.tranData.orderId).then(value => {
            Object.assign(this.$data.record, value);
          });
        })
        .catch(error => {
          this.savedMessage = "";
          if (error.response.data["fieldErrors"]) {
            self.setErrorMessages(error);
          } else {
            this.$refs.EditDialog.openErrorMsgBox(error.response.data.message);
          }
        });
    },
    erase() {
      const self = this;
      this.deleteRecord(this.record.id).then(value => {
        self.$refs.EditDialog.closeDialog();
      });
    },
    setTranData() {
      const bases = [];
      this.record.orderUnloadingBases.sortedList.forEach(value => {
        bases.push({
          baseId: value.unloadingBaseId,
          unloadingOrder: value.unloadingOrder
        });
      });

      Object.assign(this.tranData, {
        orderId: this.record.id,
        cardId: this.record.cardId,
        unloadingOrderList: bases,
        remark: this.record.remark,
        financeRemark: this.record.financeRemark,
        billingTypeId: this.record.billingType.id,
        updatedAt: this.record.updatedAt,
        cardUpdatedAt: this.record.cardUpdatedAt
      });
    },
    openErrorMsgBox(message) {
      this.$refs.EditDialog.openErrorMsgBox(message);
    }
  },
  computed: {
    isDispatched: function () {
      return function (record) {
        return record.dispatched || record.relayed;
      };
    },
    canEditStatus: function () {
      return function (record) {
        if (
          record.billingStatus !== Enum.BillingStatusType.CONFIRMED.code &&
          record.billingStatus !== Enum.BillingStatusType.PUBLISHED.code &&
          record.paymentStatus !== Enum.PaymentStatusType.CONFIRMED.code &&
          record.paymentStatus !== Enum.PaymentStatusType.PUBLISHED.code
        ) {
          return true;
        }
        return false;
      };
    }
  }
};
</script>
