<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      SignatureFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              SignatureTable.whitespace-pre(
                ref="SignatureTable"
                :prop-header="signatureTableHeader"
                :prop-data="signatureList"
                @open-detail="openDetail"
                detail-url="signature/fetchSignature"
                :displayFilter="false"
                :getListActionName="getListActionName"
                :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td(width="200px") {{ body.title }}
                  td.align-center
                    span(v-if="body.billingStatementUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.paymentStatementUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.paymentPromptUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.billingStatementCoveringLetterUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.paymentStatementCoveringLetterUseDefault")
                      v-icon(color="green") check_circle
                  td.align-center
                    span(v-if="body.paymentPromptReportCoveringLetterUseDefault")
                      v-icon(color="green") check_circle
                  td.max-text.text-sm-right {{ body.body | ommitedText }}

    SignatureEditDialog(ref="SignatureEditDialog" :postUrl="$config.urls.postEditSignature" :deleteUrl="$config.urls.deleteSignature" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import SignatureEditDialog from "@/components/management/signature/SignatureEdit";
import SignatureTable from "@/components/common/PagingListTable";
import SignatureFilterConditions from "@/components/management/signature/SignatureFilterConditions";
import { LimitNumber } from "@/assets/js/constants";

export default {
  components: {
    SignatureEditDialog,
    SignatureTable,
    SignatureFilterConditions
  },
  data() {
    return {};
  },
  filters: {
    ommitedText(text) {
      return text.length > LimitNumber.LengthNumber ? text.slice(0, LimitNumber.LengthNumber) + "..." : text;
    }
  },
  computed: {
    signatureList: {
      get() {
        return this.$store.state.signature.signatureList;
      }
    },
    getListActionName: {
      get() {
        return "signature/fetchAllSignature";
      }
    },
    signatureTableHeader: {
      get() {
        return [
          { text: "タイトル", value: "title", width: "300px" },
          { text: "請求書デフォルト利用", value: "billingStatementUseDefault", width: "200px" },
          { text: "支払書デフォルト利用", value: "paymentStatementUseDefault", width: "200px" },
          { text: "支払書速報デフォルト利用", value: "paymentPromptUseDefault", width: "200px" },
          {
            text: "請求書送付状デフォルト利用",
            value: "billingStatementCoveringLetterUseDefault",
            width: "200px"
          },
          {
            text: "支払書送付状デフォルト利用",
            value: "paymentStatementCoveringLetterUseDefault",
            width: "200px"
          },
          {
            text: "支払書速報送付状デフォルト利用",
            value: "paymentPromptReportCoveringLetterUseDefault",
            width: "200px"
          },
          { text: "内容", value: "body" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.SignatureEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.SignatureEditDialog.openDialog(item);
    },
    /**
     * データ読み込み
     * @param params
     */
    loadListData() {
      this.$refs.SignatureTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.SignatureTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.SignatureTable.setSearchConditions(searchCond);
      this.$refs.SignatureTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>

<style lang="stylus" scoped>
.max-text {
  white-space: pre-wrap;
}
.align-center{
  text-align center
}
</style>
