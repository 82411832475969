<template lang="pug">
  section
    v-text-field(class="file-upload-box" type="text" prepend-icon="attach_file" v-model="selectedFile.fileName" ,
      readonly :error-messages="getErrorMessage()")
      template(v-slot:append-outer)
        v-btn(color="primary" @click="selectFile" :loading="loading") ファイル選択
    input(@input="onFilePicked" @click="(e) => { e.target.value = '' }" :loading="loading" type="file" name="inputFile" ref="fileSelect" style="display: none")
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    // TODO:type:any 判別ができないため回避。
    // eslint-disable-next-line
    errors: {},
    itemKey: {
      type: String,
      default() {
        return "uploadFile";
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      selectedFile: {
        file: "",
        fileName: "",
        fileUrl: ""
      }
    };
  },
  methods: {
    ...mapMutations("site", ["setUploadTargetFile"]),
    getErrorMessage() {
      if (this.errors[this.itemKey]) {
        return this.errors[this.itemKey];
      } else {
        return "";
      }
    },
    selectFile(e) {
      this.$refs.fileSelect.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      this.setUploadTargetFile(files[0]);
      // ファイルが選択されたとき、取得する
      if (files[0] !== undefined) {
        this.selectedFile.fileName = files[0].name;
        if (this.selectedFile.fileName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.selectedFile.fileUrl = fr.result;
          this.selectedFile.file = files[0];
          this.$emit("update:selectedFile", this.selectedFile);
        });
      } else {
        this.selectedFile.fileName = "";
        this.selectedFile.file = "";
        this.selectedFile.fileUrl = "";

        this.$emit("update:selectedFile", this.selectedFile);
      }
    }
  }
};
</script>

<style scoped>
.file-upload-box {
  align-items: baseline;
}
</style>
