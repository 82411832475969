<template lang="pug">
  section.inner-body-section(ref="InnerBodySection")
    table.order-table
      thead(:class="{ selected : contents.isDisplayCell }")
        tr
          th(rowspan=2 width="80px") No
          th(rowspan=2) 荷主
          th(rowspan=2) 荷主枝番
          th(rowspan=2 width="120px") 積込日 / 納品日
          th(rowspan=2) 積地
          th(rowspan=2) 卸地
          th(rowspan=2 width="100px") 荷量/車番
          th(rowspan=2) shipmentNo
          th(rowspan=1) 運賃
          th(rowspan=1) 積込料
          th(rowspan=1) 取卸料
          th(rowspan=2) 運賃料合計
          th(rowspan=2) 通行料
        tr
          th(rowspan=1) 待機時間料
          th(rowspan=1) 付帯作業料
          th(rowspan=1) その他料金
      template(v-for="(detail, index) in paymentPromptDetailList(contents.primaryDeliveryCompanyId)")
        tbody
          tr
            td.text-sm-center(rowspan=2) {{ index + 1 }}
            td.text-whitespace-pre(rowspan=2) {{ detail.clientName }}
            td.text-whitespace-pre(rowspan=2) {{ detail.clientBranchName }}
            td.text-whitespace-pre.text-sm-center(rowspan=2) {{ getCombineDate(detail.shipmentDate, detail.deliveryDate) | commaToNr }}
            td.text-whitespace-pre(rowspan=2) {{ detail.loadingBases | commaToNr }}
            td.text-whitespace-pre(rowspan=2) {{ detail.unloadingBases | commaToNr }}
            td.text-whitespace-pre(rowspan=2) {{ detail.paymentVehicleDisplay }}
            td.text-whitespace-pre(rowspan=2) {{ detail.shipmentNo | commaToNr }}
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.paymentCard.fare" )
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.paymentCard.loadingCharge" )
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.paymentCard.unloadingCharge" )
            td.text-whitespace-pre.text-sm-right(rowspan=2)
              DigitSeparatorsLabel(:value="detail.paymentCard.totalFare" )
            td.text-whitespace-pre.text-sm-right(rowspan=2)
              DigitSeparatorsLabel(:value="detail.paymentCard.toll" )
          tr
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.paymentCard.waitingFee" )
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.paymentCard.additionalFee" )
            td.text-whitespace-pre.text-sm-right.right-boder
              DigitSeparatorsLabel(:value="detail.paymentCard.other" )
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import ConbineUtils from "@/mixin/ConbineUtils";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import { LimitNumber } from "@/assets/js/constants";

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    contents: {
      type: Object,
      required: true
    },
    searchConditions: {
      type: Object,
      required: true
    }
  },
  mixins: [EventDispatcher, ConbineUtils],
  data() {
    return {
      pageCount: 0,
      progress: false
    };
  },
  components: {
    DigitSeparatorsLabel
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters("statement", {
      paymentPromptDetailList: "getPaymentPromptDetailList"
    }),
    actionName: () => ""
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("statement", {
      fetchPaymentPromptDetailList: "fetchPaymentPromptDetailList"
    }),
    async loadDetail() {
      this.progress = true;
      this.clearPageCount();
      const searchCondition = this.searchConditions;
      const addSearchCond = {
        primaryDeliveryCompanyId: this.contents.primaryDeliveryCompanyId,
        pageCount: this.pageCount
      };
      await this.fetchPaymentPromptDetailList({ ...searchCondition, ...addSearchCond }).finally(() => {
        this.progress = false;
      });
    },
    clearPageCount() {
      if (!this.paymentPromptDetailList(this.contents.primaryDeliveryCompanyId)) {
        this.pageCount = 0;
      }
    },
    /**
     * 詳細スクロールイベント
     */
    async handleScrollInner() {
      if (this.$refs.InnerBodySection !== undefined) {
        const clientHeight = this.$refs.InnerBodySection.clientHeight;
        const scrollTop = this.$refs.InnerBodySection.scrollTop;
        const scrollHeight = this.$refs.InnerBodySection.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber && !this.progress) {
          this.pageCount++;
          this.loadDetail();
        }
      }
    }
  },
  mounted() {
    // イベントリスナー
    this.$refs.InnerBodySection.removeEventListener("scroll", this.handleScrollInner);
    this.$refs.InnerBodySection.addEventListener("scroll", this.handleScrollInner);
    this.pageCount = 0;
  }
};
</script>

<style lang="stylus" scoped>
.order-table {
  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }

  tbody:hover {
    background-color #ECEFF1
  }
}

.inner-body-section {
  th:last-child, td:last-child {
    border-right: 1px solid #E0E0E0;
  }

  thead {
    top 0
  }

  th {
    position: static
    top 0
  }
  max-height: 500px
  overflow-y scroll;
}
</style>
