<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions" ref="SearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="タリフCD" v-model="conditions.tariffCd")
        v-flex(md2)
          v-text-field(label="タリフ名" v-model="conditions.tariffName")
        v-flex(md2)
          DatePicker(pickerLabel="適用開始日(開始)" :initDate="conditions.applicableStartDateFrom" :inputDate.sync="conditions.applicableStartDateFrom" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="適用開始日(終了)" :initDate="conditions.applicableStartDateTo" :inputDate.sync="conditions.applicableStartDateTo" :isClearable="true")
        v-flex(md2)
          v-text-field(label="タグ名" v-model="conditions.tagName")
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import StatusSelect from "@/components/common/select/StatusSelect";

export default {
  components: {
    SearchConditions,
    DatePicker,
    StatusSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          tariffCd: null,
          tariffName: null,
          tagName: null,
          applicableStartDateFrom: null,
          applicableStartDateTo: null
        }
      };
    },
    fireSubmit() {
      this.$refs.SearchConditions.submit();
    }
  }
};
</script>
