<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          DatePicker(:inputDate.sync="conditions.loadingDate" :pickerLabel="labelName.VehicleDispatch")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import { LabelName } from "@/assets/js/constants";

export default {
  components: {
    SearchConditions,
    DatePicker
  },
  data: () => ({
    labelName: LabelName
  }),
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          loadingDate: window.Common.Util.getYearMonthDayIsoFormat()
        }
      };
    }
  }
};
</script>
