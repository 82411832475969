import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

let sse = null;

const state = {
  //マスタメンテの一覧
  dataList: [],
  /**
   * 配車画面用
   */
  //配車画面でのマッチングパターンカード一覧(配車画面と共通)
  dispatchMatchedList: [],
  //配車画面でのマッチングパターン乗務員一覧
  dispatchMatchedDriverList: [],
  //配車画面の配車日(配車表)
  scheduledLoadingDate: null,
  //配車画面の積込予定日(区分表)
  loadingDate: null,
  //配車画面からの配車パターン選択画面で選択した乗務員情報
  selectedDriverInfo: {},
  selectedCardInfo: [],
  //上記で選択した乗務員の実配車データをプレビューの為に取得して保持
  selectedDispatchDriverInfo: [],
  dispatchPatternEmptyPageList: [],
  dispatchPatternTotalCount: 0,
  dispatchPatternCurrentPage: 0,
  dispatchPatternPageSize: 0,
  progress: {
    dispatchPatternSelect: false,
    dispatchPatternComplete: false
  }
};

const getters = {
  getList: state => state.dataList,
  getMatchedList: state => state.dispatchMatchedList,
  getMatchedDriverList: state => state.dispatchMatchedDriverList,
  getLoadingDate: state => state.loadingDate,
  getScheduledLoadingDate: state => state.scheduledLoadingDate,
  getSelectedCardInfo: state => state.selectedCardInfo,
  getSelectedDriverInfo: state => state.selectedDriverInfo,
  getDispatchPatternEmptyPageList: state => state.dispatchPatternEmptyPageList,
  getSelectedMatchedCard: state => {
    if (state.selectedCardInfo.cards === undefined) {
      return null;
    }

    if (state.selectedCardInfo.cards instanceof Array) {
      return state.selectedCardInfo.cards.map(card => {
        return card.matchCard;
      });
    } else {
      return [];
    }
  },
  getSelectedDispatchDriverInfo: state => state.selectedDispatchDriverInfo,
  getDispatchPatternPageSize: state => state.dispatchPatternPageSize,
  getDispatchPatternCurrentPage: state => state.dispatchPatternCurrentPage,
  getDispatchPatternTotalCount: state => state.dispatchPatternTotalCount,
  getDispatchPatternProgress: state => {
    if (state.progress.dispatchPatternComplete) {
      return 100;
    }
    if (state.dispatchPatternTotalCount === 0) {
      return 0;
    }
    const progress =
      (state.dispatchPatternPageSize * state.dispatchPatternCurrentPage) / state.dispatchPatternTotalCount;

    return progress * 100;
  },
  getProgressDispatchPatternSelect: state => state.progress.dispatchPatternSelect,
  getProgressDispatchPatternComplete: state => state.progress.dispatchPatternComplete
};

const actions = {
  /**
   * 配車パターンマスタメンテ一覧取得用
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<*[] | *>}
   */
  async fetchAll({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getDispatchPatternList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllListData");
        }
        commit("appendAllListData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * 配車パターンマスタメンテ1件取得用
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<unknown>}
   */
  async fetchOne({ commit, state }, id) {
    return $axios.get(axiosBase.getUrls().getDispatchPattern + id).then(value => {
      return Promise.resolve(value.data.data);
    });
  },
  /**
   * 配車画面での配車パターンマッチングリスト取得用
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<*[] | *>}
   */
  async fetchAllMatchedList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getDispatchPatternMatchedList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllMatchedListData");
        }
        commit("appendAllMatchedListData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },

  async fetchAllMatchedListSse({ commit, state, getters }, searchCondition) {
    //TRASCO-3491 画面操作には影響がない模様だが、配送パターン選択APIのエラーログが出る
    //原因不明だがダイアログをユーザーが開いた時に発生しているようではない（予期せぬところでこのイベントが発火されてる？）
    //そもそもパラメーターがNULLなら発火させることはないので、ここでリターンしておく
    if (state.loadingDate == null) {
      return;
    }

    commit("clearProgressValues");
    commit("setDispatchPatternSelectProgress", true);
    let pageCount = state.dispatchPatternCurrentPage;
    searchCondition.pageCount = pageCount;
    searchCondition.loadingDate = state.loadingDate;
    if (pageCount === 0) {
      commit("clearAllMatchedListData");
    }

    // searchCondition.emptyPageNoList = getters.getDispatchPatternEmptyPageList;
    const params = qs.stringify(searchCondition);
    const url = process.env.VUE_APP_API_URL + axiosBase.getUrls().getDispatchPatternMatchedList;
    if (sse != null) {
      sse.close();
    }
    sse = new EventSource(url + "?" + params.toString(), {
      withCredentials: true
    });

    //初期化工程

    sse.addEventListener("message", ev => {
      // console.log(ev.data);
    });

    sse.addEventListener("emptyPage", ev => {
      console.log("emptyPage:" + ev.data);
      commit("addEmptyPageNo", ev.data);
    });

    sse.addEventListener("patternCount", ev => {
      console.log("patternCount:" + ev.data);
      commit("setDispatchPatternTotalCount", ev.data);
    });

    sse.addEventListener("currentPage", ev => {
      console.log("currentPage:" + ev.data);
      commit("setDispatchPatternCurrentPage", ev.data);
    });

    sse.addEventListener("pageSize", ev => {
      console.log("pageSize:" + ev.data);
      commit("setDispatchPatternPageSize", ev.data);
    });

    //パターンマスタのデータが見つからない、または最後のページまで検索した
    sse.addEventListener("patternNotFound", ev => {
      console.log("patternNotFound:" + ev.data);
      commit("setDispatchPatternComplete");
    });

    sse.addEventListener("data", ev => {
      commit("appendAllMatchedListData", JSON.parse(ev.data));
    });

    sse.addEventListener("error", ev => {
      if (sse.readyState === sse.CLOSED) {
        return;
      }

      const event = new CustomEvent(window.Common.Config.observer.onErrorResponse400, {
        detail: {
          message: ev.data
        }
      });
      window.dispatchEvent(event);
    });

    sse.onerror = function (evt) {
      commit("setDispatchPatternSelectProgress", false);
      sse.close();
    };

    sse.addEventListener("complete", ev => {
      commit("setDispatchPatternSelectProgress", false);
      sse.close();
    });
  },

  /**
   * 配車画面での配車パターン用の乗務員マッチングリスト取得用
   * @param commit
   * @param state
   * @param getParams
   * @returns {Promise<*[] | *>}
   */
  async fetchAllPatternMatchedDriverList({ commit, state, getters }, getParams) {
    //TRASCO-3491 画面操作には影響がない模様だが、配送パターン選択APIのエラーログが出る
    //原因不明だがダイアログをユーザーが開いた時に発生しているようではない（予期せぬところでこのイベントが発火されてる？）
    //そもそもパラメーターがNULLなら発火させることはないので、ここでリターンしておく
    if (state.scheduledLoadingDate == null) {
      return;
    }

    let pageCount = getParams.pageCount;

    const params = Object.assign({}, getParams, {
      scheduledLoadingDate: state.scheduledLoadingDate,
      patternId: state.selectedCardInfo.id,
      list: getters.getSelectedMatchedCard
    });

    return $axios.post(axiosBase.getUrls().getDispatchPatternMatchedDriverList, params).then(value => {
      if (pageCount === 0) {
        commit("clearAllMatchedDriverListData");
      }
      commit("appendAllMatchedDriverListData", value.data.data);
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  /**
   * 配車パターンに従って、該当の区分けカードたちを一斉配車する
   *
   * @param dispatch
   * @param commit
   * @param state
   * @param getters
   * @returns {Promise<unknown>}
   */
  async postDispatchPatternDeploy({ dispatch, commit, state, getters }) {
    const params = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      driverId: state.selectedDriverInfo.driverId,
      patternId: state.selectedCardInfo.id,
      list: getters.getSelectedMatchedCard
    };

    return $axios
      .post(axiosBase.getUrls().postDispatchPatternDeploy, params)
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },

  async postDispatchPatternCardValidate({ dispatch, commit, state, getters }, card) {
    return $axios.post(axiosBase.getUrls().postDispatchPatternEditValidationCard, card);
  },

  async setSelectedCardInfo({ commit, state }, data) {
    commit("setSelectedCardInfo", data);
    return Promise.resolve();
  },
  async setSelectedDriverInfo({ commit, state }, data) {
    commit("setSelectedDriverInfo", data);
    return Promise.resolve();
  },
  async setSelectedDispatchDriverInfo({ commit, state }, data) {
    commit("setSelectedDispatchDriverInfo", data);
    return Promise.resolve();
  },
  async setDateInfo({ commit, state }, data) {
    commit("setScheduledLoadingDate", data.scheduledLoadingDate);
    commit("setLoadingDate", data.loadingDate);
    return Promise.resolve();
  },

  async removeCacheAllData({ commit }) {
    commit("clearAllMatchedListData");
  },
  async removeDispatchPatternDriverData({ commit }) {
    commit("clearAllMatchedDriverListData");
  },
  async removeSelectedDispatchDriverInfo({ commit }) {
    commit("clearSelectedDispatchDriverInfo");
  },
  async removeDispatchPatternProgressData({ commit }) {
    commit("clearProgressValues");
  },
  removeEmptyPageNo({ commit }) {
    commit("clearEmptyPageNo");
  },
  async removeSse() {
    sse.close();
  }
};

const mutations = {
  clearAllListData(state) {
    state.dataList = [];
  },
  appendAllListData(state, data) {
    if (data != null) {
      state.dataList = state.dataList.concat(data);
    }
  },
  /**
   * 配車パターンデータをクリア
   * @param state
   */
  clearAllMatchedListData(state) {
    state.dispatchMatchedList = [];
  },
  appendAllMatchedListData(state, data) {
    if (data != null) {
      state.dispatchMatchedList = state.dispatchMatchedList.concat(data);
    }
  },

  clearAllMatchedDriverListData(state) {
    state.dispatchMatchedDriverList = [];
  },
  appendAllMatchedDriverListData(state, data) {
    if (data != null) {
      state.dispatchMatchedDriverList = state.dispatchMatchedDriverList.concat(data);
    }
  },

  setSelectedCardInfo(state, data) {
    state.selectedCardInfo = data;
  },
  setSelectedDriverInfo(state, data) {
    state.selectedDriverInfo = data;
  },
  setScheduledLoadingDate(state, data) {
    state.scheduledLoadingDate = data;
  },
  setLoadingDate(state, data) {
    state.loadingDate = data;
  },
  setSelectedDispatchDriverInfo(state, data) {
    state.selectedDispatchDriverInfo = data;
  },
  setDispatchPatternTotalCount(state, data) {
    state.dispatchPatternTotalCount = data;
  },
  setDispatchPatternPageSize(state, data) {
    state.dispatchPatternPageSize = data;
  },
  setDispatchPatternCurrentPage(state, data) {
    state.dispatchPatternCurrentPage = data;
  },
  addEmptyPageNo(state, data) {
    if (!state.dispatchPatternEmptyPageList.includes(data)) {
      state.dispatchPatternEmptyPageList.push(data);
    }
  },
  setDispatchPatternComplete(state) {
    state.progress.dispatchPatternComplete = true;
  },
  clearEmptyPageNo(state) {
    state.dispatchPatternEmptyPageList = [];
  },
  clearSelectedDispatchDriverInfo(state) {
    state.selectedDispatchDriverInfo = [];
  },
  clearDispatchPatternComplete(state) {
    state.progress.dispatchPatternComplete = false;
  },
  clearProgressValues(state) {
    state.dispatchPatternPageSize = 0;
    state.dispatchPatternTotalCount = 0;
    state.progress.dispatchPatternComplete = false;
  },
  setDispatchPatternSelectProgress(state, flg) {
    state.progress.dispatchPatternSelect = flg;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
