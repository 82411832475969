<template lang="pug">
  v-content
    v-alert(
      :value="true" type="error" dismissible v-if="hasWarning"
    ) このシステムは本ブラウザではご利用できません。 Google Chrome 最新版 をご利用いただきますよう、お願いいたします。

    v-container.login-container
      v-layout(align-center justify-center row fill-height)
        v-flex(d-inline-block)
          v-card.px-4.py-3
            v-card-text
              p.login-logo
                img(alt="logo" src="@/assets/logo/main.png")
              alert-box(ref="errorMsg")
              v-text-field(
                ref="login_id"
                label="ログインID"
                prepend-icon="person"
                v-model="inputs.loginCd"
              )
              v-text-field(
                label="パスワード"
                prepend-icon="lock"
                v-model="inputs.password"
                :append-icon="isShowPassword ? 'visibility_off' : 'visibility'"
                :type="isShowPassword ? 'text' : 'password'"
                name="input-10-1"
                @click:append="isShowPassword = !isShowPassword"
              )
              v-btn.mt-4(block color="primary" dark @click="login" :loading="loading") ログイン
              p.password-remind-box
                a(@click="goForgetPassword") パスワードを忘れた場合はこちら
</template>

<script>
import AlertBox from "@/components/common/AlertBox";

export default {
  name: "LoginForm",
  components: {
    "alert-box": AlertBox
  },
  data() {
    return {
      inputs: {
        loginCd: "",
        password: ""
      },
      isShowPassword: false,
      loading: false
    };
  },
  created() {
    window.addEventListener(
      window.Common.Config.observer.onErrorResponseNetworkError,
      () => this.displayErrorMessage(window.Common.Messages.Common.Error.NetworkError),
      false
    );

    window.addEventListener(
      window.Common.Config.observer.onErrorResponse500,
      () => this.displayErrorMessage(window.Common.Messages.Common.Error.InternalServerError),
      false
    );

    this.$nextTick(() => this.$refs.login_id.focus());
  },
  destroyed() {
    window.removeEventListener(window.Common.Config.observer.onErrorResponseNetworkError, () =>
      this.displayErrorMessage(window.Common.Messages.Common.Error.NetworkError)
    );
    window.removeEventListener(window.Common.Config.observer.onErrorResponse500, () =>
      this.displayErrorMessage(window.Common.Messages.Common.Error.NetworkError)
    );
  },
  computed: {
    hasWarning() {
      const userAgent = window.navigator.userAgent;
      return !userAgent.match(/Chrome/);
    }
  },
  methods: {
    displayErrorMessage(message) {
      this.$refs.errorMsg.displayMessage(message);
    },
    login: function () {
      this.loading = true;
      let loginCd = this.inputs.loginCd;
      let password = this.inputs.password;
      this.$store
        .dispatch("auth/login", { loginCd, password })
        .then(() => this.$router.push(window.Common.Config.cmsContextPath))
        .catch(error => {
          if (error.response !== undefined) {
            this.$refs.errorMsg.displayMessage(error.response.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goForgetPassword() {
      this.$router.push({ name: "ForgetPassword" });
    }
  }
};
</script>

<style scoped lang="stylus">
.password-remind-box {
  margin-top: 32px;
  text-align: right;
}

.login-logo {
  text-align: center;

  img {
    width: 50%;
  }
}

$boxSize = 550px;

.login-container {
  @media (max-width: $boxSize) {
    width: 90%;
  }

  @media (min-width: $boxSize) {
    width: $boxSize;
  }

  height: 100%;
}
</style>
