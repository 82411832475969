<template lang="pug">
  EditDialog(ref="EditDialog" title="配車パターンカード" :recordId="999" @delete="erase" @save="save" width="85%"
    deleteDialogTitle="カード削除確認"
    deleteDialogMessage="カードを削除しますか？<br/>ここで削除してもまだ本削除されません。配車パターンダイアログで保存押下した時点でサーバーへ保存操作が送信されます。"
    :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md fluid)
        v-layout(row wrap)
          v-flex(xs4)
            ClientSelect(:label.sync="record.clientName" :code.sync="record.clientId"
              :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'clientId'")
          v-flex(xs4)
            ClientBranchSelect(:clientId="record.clientId" :clientBranchId="record.clientBranchId"
              :code.sync="record.clientBranchId" isSelectBjiAll
            @updateLabel="value => record.clientBranchName = value" :errorHandlerMethod="getErrorMessages")
          v-flex(xs2)
            VehicleTypeSelect(:code.sync="record.vehicleTypeId" :vehicleName.sync="record.vehicleTypeName" :clearable="true" :errorHandlerMethod="getErrorMessages")

        v-layout(row wrap)
          v-flex(xs12)
            v-card
              v-card-text

                v-layout(row wrap)
                  v-flex(xs5)
                    template(v-for="(loading, index) in bases.loadingBaseList")
                      //積地
                      v-layout(row wrap)
                        v-flex(xs8)
                          LoadingBaseSelect(:excludeBaseIdList="loadingBaseIdList"
                            :clientId="record.clientId"
                            :clientBranchId="record.clientBranchId"
                            :enableDelete="(index == 0 ? false :true)"
                            :label.sync="loading.nameAndCd"
                            :code.sync="loading.baseId"
                            :errorHandlerMethod="getErrorMessages"
                            :errorHandlerMethodArg="'loadingBaseList[' + index + '].baseId'"
                            @deleteList="deleteLoadingBaseList(index)"
                            @setSelectData="data => selectLoadingBase(loading,data)")

                        v-flex(xs2)
                          v-text-field(box readonly v-model="loading.areaName" label="エリア")

                        v-flex(xs2)
                          DispatchPatternDecisionTypeSelect(:code.sync="loading.decisionType")

                  v-flex(xs1)
                    v-layout(align-content-center justify-center)
                      v-icon(x-large) keyboard_arrow_right

                  v-flex(xs6)
                    v-layout(column)
                      template(v-for="(unloading, index) in bases.unloadingBaseList")
                        //卸地
                        v-layout(row wrap)
                          v-flex(xs8)
                            UnloadingBaseSelect(:excludeBaseIdList="unloadingBaseIdList"
                              :clientId="record.clientId"
                              :clientBranchId="record.clientBranchId"
                              :enableDelete="(index == 0 ? false :true)"
                              :label.sync="unloading.nameAndCd"
                              :code.sync="unloading.baseId"
                              :errorHandlerMethod="getErrorMessages"
                              :errorHandlerMethodArg="'unloadingBaseList[' + index + '].baseId'"
                              @deleteList="deleteUnloadingBaseList(index)"
                              @setSelectData="data => selectUnloadingBase(unloading, data)")

                          v-flex(xs2)
                            v-text-field(box readonly v-model="unloading.areaName" label="エリア")

                          v-flex(xs2)
                            DispatchPatternDecisionTypeSelect(:code.sync="unloading.decisionType")

                v-layout(row wrap)
                  v-btn(color="success" falt @click.stop="addLoadingBase" v-bind:disabled ="loadingBaseLimit") 積地を追加する
                  v-btn(color="success" falt @click.stop="addUnloadingBase" v-bind:disabled ="unloadingBaseLimit") 卸地を追加する

</template>

<script>
import ClientSelect from "@/components/common/select/ClientSelect";
import ClientBranchSelect from "@/components/common/select/ClientBranchSelect";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";
import EditDialog from "@/components/common/dialog/EditDialog";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

import LoadingBaseAndUnloadingBaseSelect from "@/mixin/LoadingBaseAndUnloadingBaseSelect";
import LoadingBaseSelect from "@/components/common/select/LoadingBaseSelect";
import UnloadingBaseSelect from "@/components/common/select/UnloadingBaseSelect";
import DispatchPatternDecisionTypeSelect from "@/components/common/select/DispatchPatternDecisionTypeSelect.vue";
import * as Enum from "@/assets/js/enum";
import { mapActions } from "vuex";

export default {
  components: {
    DispatchPatternDecisionTypeSelect,
    EditDialog,
    ClientSelect,
    ClientBranchSelect,
    VehicleTypeSelect,
    LoadingBaseSelect,
    UnloadingBaseSelect
  },
  mixins: [ApiHandler, EditForm, LoadingBaseAndUnloadingBaseSelect],
  watch: {
    "record.clientId": function (newValue, oldValue) {
      this.clearBases();
    },
    "record.clientBranchId": function (newValue, oldValue) {
      this.clearBases();
    }
  },
  data() {
    return {
      record: {
        id: null,
        clientId: null,
        clientName: null,
        clientBranchId: null,
        clientBranchName: null,
        vehicleTypeId: null,
        vehicleTypeName: null
      },
      initValues: {
        id: null,
        clientId: null,
        clientName: null,
        clientBranchId: null,
        clientBranchName: null,
        vehicleTypeId: null,
        vehicleTypeName: null
      }
    };
  },
  methods: {
    ...mapActions({
      postDispatchPatternCardValidate: "dispatchPattern/postDispatchPatternCardValidate"
    }),
    openDialog(item) {
      this.initForm(item);
      if (item !== undefined) {
        //ClientIDなどのWatchが動くのでその後にセット
        this.$nextTick(() => {
          this.bases.loadingBaseList = item.loadingBaseList;
          this.bases.unloadingBaseList = item.unloadingBaseList;
        });
      }
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    /**
     * 保存ボタン押下
     */
    save() {
      const workCard = Object.assign({}, this.record);
      const workBase = Object.assign({}, this.bases);
      workCard.loadingBaseList = workBase.loadingBaseList;
      workCard.unloadingBaseList = workBase.unloadingBaseList;

      const self = this;

      this.postDispatchPatternCardValidate(workCard)
        .then(() => {
          self.$emit("saveCard", workCard);
          self.closeDialog();
        })
        .catch(reason => {
          if (reason.response.data["fieldErrors"]) {
            self.setErrorMessages(reason);
          }
        });
    },
    /**
     * 削除ボタン押下
     */
    erase() {
      this.$emit("deleteCard", this.record);
      this.closeDialog();
    },
    /**
     * 積地初期値(配送パターン判定種別の初期値の為にOverride)
     */
    loadingDefault() {
      return {
        id: null,
        baseId: null,
        baseCd: null,
        baseName: null,
        decisionType: Enum.DispatchPatternDecisionType.Base.code
      };
    },
    /**
     * 卸地初期値(配送パターン判定種別の初期値の為にOverride)
     */
    unloadingDefault() {
      return {
        id: null,
        baseId: null,
        baseCd: null,
        baseName: null,
        specifiedTime: {
          from: null,
          to: null
        },
        unloadingOrder: 1,
        decisionType: Enum.DispatchPatternDecisionType.Base.code
      };
    },
    selectLoadingBase(loading, data) {
      loading.baseCd = data.baseCd;
      loading.baseName = data.baseName;
      loading.areaName = data.area.name;
    },
    selectUnloadingBase(unloading, data) {
      unloading.baseCd = data.baseCd;
      unloading.baseName = data.baseName;
      unloading.areaName = data.area.name;
    }
  }
};
</script>

<style scoped lang="stylus"></style>
