<template lang="pug">
  v-card(elevation=0 :class="{'driver-rest':isDriverRest, 'driver-result':hasDispatchResult, 'remark-driver':driver.hasRemark}").driver-card
    v-layout(justify-start column fill-height)
        v-flex(xs12)
          v-layout(align-center justify-start row fill-height)
            v-flex.text-xs-center(xs2)
              v-icon(small) fas fa-user
            v-flex.text-truncate(xs8)
              span
                span.driver-seq-no {{ driverIndex + 1 }}
                span {{ driver.driverName }}
            v-flex.text-xs-right(xs1)
              LiftTypeIcon(:liftType="driver.handlableLiftType" :displayTypeNone="false")
        v-flex( xs12)
          v-layout(align-center justify-start row fill-height)
            v-flex.text-xs-center(xs2)
              v-icon(small) fas fa-building
            v-flex.text-truncate(xs10)
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  p(v-on="on").company-name.whitespace-pre.text-truncate {{ driver.companyName }} {{ driver.department }}
                span.whitespace-pre {{ driver.companyName }} {{ driver.department }}

        v-flex(xs12)
          v-layout(align-center justify-start row fill-height)
            v-flex.text-xs-center(xs2)
              OneChip.vehicle-color(:text="vehicleOneChar" :class="getVehicleColorClass(driver.currentVehicleTypeCd)")
            v-flex(xs5)
              span {{ driver.currentCarryWeightWithUnit }} ({{ driver.currentVehicleNumber }})
            v-flex.text-xs-right.icons(xs5)
              v-btn(icon flat color="grey" small @click="toggleRemarkEditDialog" v-show="!driver.hasRemark")
                RemarkStatusIcon(:hasRemark="driver.hasRemark")
              v-btn(icon flat color="grey" small slot="activator")
                v-icon(@click="toggleDriverContextMenu") more_vert

        v-flex(v-show="driver.hasRemark" xs12 )
          v-layout(align-center justify-start row fill-height)
            v-flex.text-xs-center(xs2)
              v-btn(icon flat color="grey" small @click="toggleRemarkEditDialog")
                RemarkStatusIcon(:hasRemark="driver.hasRemark")
            v-flex.text-xs-left(xs10)
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  span(v-on="on" style="margin-left:5px").remark-box.whitespace-nowrap {{ driver.remark }}
                span {{ driver.remark }}
</template>

<script>
import OneChip from "../common/OneCharChipLabel";
import LiftTypeIcon from "@/components/dispatch/LiftTypeIcon";
import RemarkStatusIcon from "@/components/dispatch/RemarkStatusIcon";

export default {
  components: { OneChip, LiftTypeIcon, RemarkStatusIcon },
  props: {
    driver: {
      type: Object,
      required: true
    },
    driverIndex: {
      type: Number,
      required: false,
      default: () => null
    }
  },
  computed: {
    vehicleOneChar() {
      return this.driver.currentVehicleTypeName == null ? "未" : this.driver.currentVehicleTypeName.charAt();
    },
    vehicleTypeCode() {
      return this.driver.currentVehicleTypeCd;
    },
    areaOneChar() {
      return "";
    },
    isDriverRest() {
      return this.driver.shiftType === "00";
    },
    hasDispatchResult() {
      return this.driver.hasDispatchResult;
    }
  },
  methods: {
    getAreaColorClass(id) {
      return "area-color-" + id;
    },
    getVehicleColorClass(code) {
      return "vehicle-color-" + code;
    },
    toggleDriverContextMenu(event) {
      event.preventDefault();
      this.$emit("openDriverContextMenu", {
        item: this.driver,
        event: event
      });
    },
    toggleRemarkEditDialog(event) {
      event.preventDefault();
      this.$emit("openRemarkEditDialog", this.driver);
    }
  }
};
</script>

<style lang="stylus" scoped>
.driver-rest {
  background-color #B0BEC5 !important
}

.driver-result {
  border 1px solid #4CAF50
  background-color #C8E6C9 !important
}

.company-name {
  margin 0
}

.remark-box {
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.dynamic {
  #dynamic-schedule {
    .driver-box.remark-driver {
      height: 150px;
    }
  }
}
</style>
