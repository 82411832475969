<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      VehicleFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              VehicleListTable(
                ref="VehicleListTable"
                :prop-header="tableHeader"
                :prop-data="vehicleList"
                :getListActionName="getListActionName"
                detail-url="vehicle/fetchVehicle"
                @open-detail="openDetail"
                :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-left {{ body.deliveryCompanyName }}
                  td.text-xs-left {{ body.vehicleTypeName }}
                  td.text-xs-left {{ body.fullNumberDisp }}
                  td.text-xs-right(v-if="body.carryWeight != 0") {{ body.carryWeight | addComma }}kg
                  td(v-else)
                  td.text-xs-right(v-if="body.maxCarryWeight != 0") {{ body.maxCarryWeight | addComma }}kg
                  td(v-else)
                  td.text-xs-left
                    v-chip(v-for="(optionName, index) in body.optionNameList" :key="index") {{ optionName }}
                  td.text-xs-left {{ body.vehicleGarageName }}

    VehicleEditDialog(ref="VehicleEditDialog" :postUrl="$config.urls.postEditVehicle" :deleteUrl="$config.urls.deleteVehicle" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('vehicle/export')")
</template>

<script>
import { mapGetters } from "vuex";
import VehicleEditDialog from "@/components/management/vehicle/VehicleEdit";
import VehicleListTable from "@/components/common/PagingListTable";
import VehicleFilterConditions from "@/components/management/vehicle/VehicleFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

import _ from "lodash";

export default {
  components: {
    VehicleEditDialog,
    VehicleListTable,
    VehicleFilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {
      searchCond: {},
      tableHeader: [
        { text: "所属企業" },
        { text: "車格" },
        { text: "車番" },
        { text: "積載重量" },
        { text: "最大積載重量" },
        { text: "オプション" },
        { text: "車庫名" }
      ]
    };
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.VehicleEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.VehicleEditDialog.openDialog(item);
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     **/
    itemUpdated(response, data) {
      this.$refs.VehicleListTable.resetPageCount();
      this.loadListData();
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.VehicleListTable.loadData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.VehicleListTable.setSearchConditions(searchCond);
      this.$refs.VehicleListTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力確認ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  },
  computed: {
    ...mapGetters({
      getFloorSpec: "site/floorSpecFromCd",
      getStatus: "site/statusFromCd"
    }),
    getListActionName: {
      get() {
        return "vehicle/fetchAllVehicle";
      }
    },
    vehicleList: {
      get() {
        if (_.isNil(this.$store.state.vehicle.vehicleList)) return [];
        return this.$store.state.vehicle.vehicleList;
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  filters: {
    addComma: function (val) {
      return val.toLocaleString();
    }
  }
};
</script>
