import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  driverList: [],
  driverListForSchedule: [],
  driverScheduleList: {
    currentMonth: [],
    nextMonth: []
  },
  shiftDataList: [],
  mailSendDriverList: []
};

const getters = {};

const actions = {
  /**
   * 検索条件から乗務員一覧取得
   */
  async fetchAllDriver({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllDriverList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllDriverData");
        }
        commit("appendAllDriverData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * 乗務員スケジュール用の乗務員選択
   */
  async fetchAllDriverForSchedule({ commit, state }, getParams) {
    // let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllDriverListForSchedule, {
        params: getParams
      })
      .then(value => {
        // if (pageCount === 0) {
        //   commit("clearAllDriverData");
        // }
        // commit("appendAllDriverData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * 配車用検索条件から乗務員一覧取得
   */
  async fetchAllDriverForDispatch({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getAllDriverListForDispatch, {
        params: getParams
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * 配車用の乗務員を直接選択するための乗務員一覧取得
   */
  async fetchAllDriverForDirectDispatch({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getAllDriverListForDirectDispatch, {
        params: getParams
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * 配車用検索条件から乗務員一覧取得
   */
  async fetchOneDriverSchedule({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getOneDriverScheduleForDispatch, {
        params: getParams
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data.driverShiftList);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * メール送信可能な乗務員一覧を取得
   */
  async fetchMailSendDriver({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getMailSendDriverList, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("appendMailSendDriverData", value.data.data);
        return state.mailSendDriverList;
      });
  },
  /**
   * 選択した乗務員情報を取得
   */
  async fetchDriver({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllDriver + id).then(value => {
      return value.data.data;
    });
  },
  /**
   * 乗務員ごとの勤怠スケジュールを取得
   * @param commit
   * @param state
   * @param param
   * @returns {Promise<any>}
   */
  async fetchScheduleList({ commit, state }, param) {
    let pageCount = param.pageCount;
    const [year, month, day] = param.baseDate.split("-");
    return await $axios
      .get(axiosBase.getUrls().getAllDriverScheduleList + `/${year}/${month}/${day}`, {
        params: param
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearDriverListForScheduleData");
        }
        commit("appendDriverListForScheduleData", value.data.data);
      });
  },
  async fetchSchedule({ commit, state }, param) {
    return await $axios
      .get(axiosBase.getUrls().getAllDriverSchedule + `/${param.driverId}/${param.year}/${param.month}`)
      .then(value => {
        commit("writeScheduleData", value.data.data);
      });
  },
  saveShift({ commit, state }, data) {
    commit("addShift", data);
  },
  /**
   * 実績出力
   */
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportDriver, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllDriverData(state) {
    state.driverList = [];
  },
  appendAllDriverData(state, data) {
    if (data != null) {
      state.driverList = state.driverList.concat(data);
    }
  },
  appendMailSendDriverData(state, data) {
    state.mailSendDriverList = data == null ? [] : data;
  },
  writeScheduleData(state, data) {
    state.driverScheduleList = data;
  },
  clearDriverListForScheduleData(state) {
    state.driverListForSchedule = [];
  },
  appendDriverListForScheduleData(state, data) {
    if (data != null) {
      state.driverListForSchedule = state.driverListForSchedule.concat(data);
    }
  },
  addShift(state, data) {
    state.shiftDataList.push(data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
