<template lang="pug">
  EditDialog(ref="EditDialog" title="定型文" :recordId="record.id" @delete="erase" @save="save"
        :loadingSave="loading.save" :loadingDelete="loading.delete" width="800px")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field( v-model="record.title" label="タイトル" :error-messages="getErrorMessages('title')")
          v-flex(xs12 md12)
            v-textarea.edit-area(rows="20" v-model="record.body" :error-messages="getErrorMessages('body')")
          v-flex(xs12 md6)
            v-checkbox(label="車番連絡デフォルト利用" v-model="record.serviceContactUseDefault" hide-details)
            v-checkbox(label="輸送依頼デフォルト利用" v-model="record.transportUseDefault")
          v-flex(xs12 md6)
            v-checkbox(label="請求書送付状デフォルト利用" v-model="record.billingStatementCoveringLetterUseDefault" hide-details)
            v-checkbox(label="支払書送付状デフォルト利用" v-model="record.paymentStatementCoveringLetterUseDefault" hide-details)
            v-checkbox(label="支払書速報送付状デフォルト利用" v-model="record.paymentPromptReportCoveringLetterUseDefault" hide-details)

        v-layout(row wrap)
          v-flex(xs12 md2)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")
</template>

<script>
import StatusSelect from "@/components/common/select/StatusSelect";
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "../../../mixin/ApiHandler";
import EditForm from "../../../mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    StatusSelect,
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      title: "定型文編集",
      record: {
        id: null,
        boilerplateCd: null,
        title: null,
        body: null,
        serviceContactUseDefault: false,
        transportUseDefault: false,
        status: null
      }
    };
  },
  methods: {
    openDialog(item) {
      this.initForm(item);
      if (!item) this.record.status = Enum.ActiveFlag.Active.code;
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.edit-area{
  font-family: Verdana, JPAPGothic, sans-serif;
  font-size: 9px;
  max-width 558px
  margin auto
  border 1px solid lightgrey
  padding 16px
  box-sizing content-box
}
</style>
