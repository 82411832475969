<template lang="pug">
  EditDialog(ref="EditDialog" title="メンテナンス編集" :recordId="record.index" @delete="erase" @save="save",
    :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md4)
            BaseDatePicker(:inputDate.sync="record.startDate" picker-label="開始"  :errorHandlerMethod="getErrorMessages" errorHandlerMethodParam="startDate")
          v-flex(xs12 md4)
            BaseDatePicker(:inputDate.sync="record.endDate" picker-label="終了"  :errorHandlerMethod="getErrorMessages" errorHandlerMethodParam="endDate")
          v-flex(xs12 md4)
                v-select(
                  v-model="record.reason"
                  :items="vehicleMaintenanceTypes"
                  item-text="label"
                  item-value="code"
                  label="理由"
                  :error-messages="getErrorMessages('reason')")
          v-flex(xs12 md12)
            v-textarea(
              auto-grow
              rows="1"
              v-model="record.remark"
              label="備考"
              :error-messages="getErrorMessages('remark')")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import BaseDatePicker from "@/components/common/DatePicker";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

import * as Enum from "@/assets/js/enum";
export default {
  components: {
    EditDialog,
    BaseDatePicker
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      controls: {
        index: null
      },
      record: {},
      tranData: {}
    };
  },
  computed: {
    vehicleMaintenanceTypes: {
      get() {
        return Enum.Utils.enumToList(Enum.VehicleMaintenanceType);
      }
    }
  },
  methods: {
    /**
     * 初期データ
     */
    initData(data) {
      if (data !== null) {
        return data;
      }
      return {
        startDate: null,
        endDate: null,
        reason: null,
        remark: null,
        index: null
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = Object.assign({}, this.record);
    },
    /**
     * フォームの初期化
     * @param values
     */
    initForm(values) {
      this.record = Object.assign({}, this.initData(values));
      this.errors = {};
    },

    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      this.setTranData();
      this.saveRecord(this.tranData).then(() => {
        this.$refs.EditDialog.closeDialog();
        this.$emit("save", this.record);
      });
    },
    erase() {
      this.$refs.EditDialog.closeDialog();
      this.$emit("delete", this.record);
    }
  }
};
</script>
