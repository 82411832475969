<template lang="pug">
  SearchConditions(ref="SearchConditions" :initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      alert-box(ref="errorMsg")
      v-layout(wrap)
        v-flex(md4)
          v-layout(wrap)
            v-flex(md4)
              DatePicker(pickerLabel="配車日" :initDate="conditions.scheduledLoadingDate" :inputDate.sync="conditions.scheduledLoadingDate" :isClearable="true")
            v-flex(md4)
              DatePicker(pickerLabel="積込日" initDate="" :inputDate.sync="conditions.shipmentDate" :isClearable="true")
            v-flex(md4)
              DatePicker(pickerLabel="納品日" initDate="" :inputDate.sync="conditions.deliveryDate" :isClearable="true")
        v-flex(md3)
          v-layout(column)
            v-flex(md6)
              v-text-field(label="1次委託先会社" v-model="conditions.deliveryCompanyName" maxlength=80)
            v-flex(md6)
              v-checkbox.mt-0(v-model="conditions.faxTarget" label="FAX送付対象のみ表示" hide-details)
            v-flex(md6)
              v-layout
                v-flex(md6)
                  CheckOnOffSelect(label-disp="下請法該当フラグ" :code.sync="conditions.subcontractingLawFlg" clearable)
                v-flex(md6)
                  CheckOnOffSelect(label-disp="資本金登録" :code.sync="conditions.capitalBlank" :label-dic="capitalRegisterStatus" clearable )
        v-flex(md5)
          v-layout(wrap)
            v-flex(md3)
              v-layout(column)
                v-flex(md6)
                  VehicleTypeSelect(label="車両車格" :code.sync="conditions.vehicleTypeId" :clearable="true")
                v-flex(md6)
                  v-checkbox.mt-0(v-model="conditions.onlyTemporaryDriver" label="仮乗務員のみ表示" hide-details)
                  v-checkbox.mt-0(v-model="conditions.notTemporaryDriver" label="仮乗務員以外を表示" hide-details)
            v-flex(md3)
              v-text-field(label="車庫エリア" v-model="conditions.vehicleGarageArea" maxlength=30)
            v-flex(md2)
              ReportOutputStatusTypeSelect(:code.sync="conditions.reportStatus" :clearable="true")
            v-flex(md3)
              v-layout(column)
                v-flex(md2)
                  FaxSendStatusSelect(:code.sync="conditions.faxSendStatus" :clearable="true")
                v-flex(md2)
                  StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";
import ReportOutputStatusTypeSelect from "@/components/common/select/ReportOutputStatusTypeSelect";
import StatusSelect from "@/components/common/select/StatusSelect";
import FaxSendStatusSelect from "@/components/common/select/FaxSendStatusSelect";
import AlertBox from "@/components/common/AlertBox";
import * as Enum from "@/assets/js/enum";
import CheckOnOffSelect from "@/components/common/select/CheckOnOffSelect.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CheckOnOffSelect,
    SearchConditions,
    DatePicker,
    VehicleTypeSelect,
    StatusSelect,
    FaxSendStatusSelect,
    ReportOutputStatusTypeSelect,
    AlertBox
  },
  computed: {
    ...mapGetters("site", ["capitalRegisterStatus"])
  },
  methods: {
    /**
     * Emit：検索条件 変更
     */
    onChangeSearchConditions(item) {
      this.nonDisplayMessage();
      this.$emit("onChangeSearchConditions", item);
    },
    /**
     * アラートボックス表示
     */
    displayMessage(errorMsg) {
      this.$refs.errorMsg.displayMessage(errorMsg);
    },
    /**
     * アラートボックス非表示
     */
    nonDisplayMessage() {
      this.$refs.errorMsg.nonDisplayMessage();
    },
    /**
     * 初期設定：子コンポーネント情報
     */
    initSearchCond() {
      return {
        conditions: {
          scheduledLoadingDate: window.Common.Util.getTomorrowYearMonthDayIsoFormat(),
          shipmentDate: "",
          deliveryDate: "",
          deliveryCompanyName: "",
          vehicleNumber: "",
          vehicleTypeId: "",
          vehicleGarageArea: "",
          reportStatus: "",
          status: Enum.ActiveFlag.Active.code,
          faxTarget: true,
          faxSendStatus: null,
          onlyTemporaryDriver: false,
          notTemporaryDriver: false
        }
      };
    },
    /**
     * Event：子コンポーネントsubmit処理
     * ※最終的に子からemitでonChangeSearchConditions(item)に伝播される。
     */
    submit() {
      this.$refs.SearchConditions.submit();
    }
  }
};
</script>
