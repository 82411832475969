<template lang="pug">
  v-content
    EmergencyErrorDialog(ref="ServerErrorDialog")
    v-container(fluid grid-list-md)
      v-layout
        v-flex(md12)
          v-card
            v-card-text
              BillingAndPaymentFilterConditions(ref="BillingAndPaymentFilterConditions" @onChangeSearchConditions="onChangeSearchConditions")
      v-layout(wrap row)
        v-flex(md5)
          FinanceSum

      v-card
        v-card-text
          v-flex(xs12)
            BillingAndPaymentListTable(ref="BillingAndPaymentListTable")
</template>

<script>
import EventDispatcher from "@/mixin/EventDispatcher";
import ExportHandler from "@/mixin/ExportHandler";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import BillingAndPaymentListTable from "@/components/finance/BillingAndPaymentListTable";
import BillingAndPaymentFilterConditions from "@/components/finance/BillingAndPaymentFilterConditions";
import FinanceSum from "@/components/finance/FinanceSum";

export default {
  components: {
    BillingAndPaymentFilterConditions,
    EmergencyErrorDialog,
    BillingAndPaymentListTable,
    FinanceSum
  },
  mixins: [EventDispatcher, ExportHandler],
  data: () => ({
    // 検索エラーフラグ
    isError: false
  }),
  methods: {
    /**
     * Event：検索条件 変更
     */
    onChangeSearchConditions(searchCondition) {
      // ExportHandler条件設定
      this.searchCond = searchCondition;
      // InfiniteScrollHandler処理
      this.$refs.BillingAndPaymentListTable.setSearchConditions(searchCondition);
      this.$refs.BillingAndPaymentListTable.resetPageCount();
      // データ取得
      this.$refs.BillingAndPaymentListTable.loadData()
        .then(() => (this.isError = false))
        .catch(error => {
          this.isError = true;
          // alertBox表示
          this.$refs.BillingAndPaymentFilterConditions.displayMessage(error.response.data.message);
        });
      this.$refs.BillingAndPaymentListTable.resetAllSelect();
      this.$refs.BillingAndPaymentListTable.loadSum();
    }
  },
  mounted() {
    // 初期ロード時に初期検索情報にて結果を表示
    this.$store.dispatch("finance/clearFetchList");
    this.$refs.BillingAndPaymentFilterConditions.submit();
  }
};
</script>
