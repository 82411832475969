<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="支払タリフ区分名" v-model="conditions.name")
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import StatusSelect from "@/components/common/select/StatusSelect";

export default {
  components: {
    SearchConditions,
    StatusSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          name: "",
          status: null
        }
      };
    }
  }
};
</script>
