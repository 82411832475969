<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      BaseClassFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              BaseClassTable(ref="BaseClassTable" :prop-header="areaTableHeader"
                :prop-data="areaList" @open-detail="openDetail" detail-url="baseClass/fetchUnloadingBaseClassOne"
                :getListActionName="getListActionName")

    BaseClassEdit(ref="BaseClassEdit" :postUrl="$config.urls.postEditUnloadingBaseClass"
      :deleteUrl="$config.urls.deleteUnloadingBaseClass" @updated="itemUpdated" @deleted="itemUpdated")

</template>

<script>
import BaseClassEdit from "@/components/management/base-class/UnloadingBaseClassEdit";
import BaseClassTable from "@/components/common/PagingListTable";
import BaseClassFilterConditions from "@/components/management/base-class/UnloadingBaseClassFilterConditions";

export default {
  components: {
    BaseClassEdit,
    BaseClassTable,
    BaseClassFilterConditions
  },
  data() {
    return {};
  },
  computed: {
    areaList: {
      get() {
        return this.$store.state.baseClass.clientUnloadingBaseClassList;
      }
    },
    getListActionName: {
      get() {
        return "baseClass/fetchAllUnloadingBaseClassList";
      }
    },
    areaTableHeader: {
      get() {
        return [
          { text: "荷主", value: "client.clientDisplayName", centerAlign: false },
          { text: "卸地分類名", value: "baseClassName", centerAlign: false }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.BaseClassEdit.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.BaseClassEdit.openDialog(item);
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.BaseClassTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.BaseClassTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.BaseClassTable.setSearchConditions(searchCond);
      this.$refs.BaseClassTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>
