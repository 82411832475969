// グローバルスコープへ共通関数の定義
import "@/assets/js/common";
import "@fortawesome/fontawesome-free/css/all.css";

import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify";
import router from "./router";
import axios from "./assets/js/axios";
import store from "./store";
import VueAxios from "vue-axios";

import japanese from "vuetify/es5/locale/ja";

window.Vue = Vue;

require("./filters/global_filters");

const AxiosBase = require("@/assets/js/axios-base");
// eslint-disable-next-line
let axiosInstance = new AxiosBase.default();

Vue.use(VueAxios, axios);
Vue.use(Vuetify, {
  lang: {
    locales: { japanese },
    current: "japanese"
  }
});

Vue.config.productionTip = false;
Vue.prototype.$config = {
  urls: axiosInstance.getUrls(),
  messages: window.Common.Messages
};
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
