<template lang="pug">
  v-select(
    v-on:change="change"
    v-model="p_code"
    :items="masterList"
    :readonly="readonly"
    :box="readonly"
    item-text="paymentTypeName"
    item-value="id"
    label="支払区分"
    :error-messages="errorHandlerMethod('paymentTypeId')")
</template>

<script>
export default {
  props: {
    // TODO:type:Number 必須でありながらNullがはいる可能性があるため回避。（※初期値）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    financeSetting: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      paymentTypes: []
    };
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return this.paymentTypes;
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const self = this;
      await this.$store.dispatch("site/fetchAllPaymentType").then(value => {
        self.paymentTypes = value;
      });
    },
    change(value) {
      if (this.financeSetting) {
        this.$emit("changeFinance", value);
      }
      this.$emit("changeItem");
    }
  }
};
</script>
