import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  /**
   * 乗務員と乗務員に紐付くデータ
   */
  scheduleList: [],
  /**
   * エリア情報
   */
  loadingAreaList: [],
  unloadingAreaList: [],
  //配車表の日付
  scheduledLoadingDate: null,
  paging: {
    scheduleTablePageCount: 0
  },
  driverCount: 0
};

const getters = {
  allData: (state, getters, rootState) => {
    return state.driverScheduleList;
  },
  getScheduledLoadingDate: state => state.scheduledLoadingDate,
  getDriverCount: state => state.driverCount
};

const actions = {
  // 配車実績取得
  async getDispatchResultDetail({ commit, state }, params) {
    return $axios
      .get(axiosBase.getUrls().getDispatchResult, {
        params: {
          scheduledLoadingDate: params.scheduledLoadingDate,
          driverId: params.driverId
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? null : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車画面の左側。乗務員と乗務員に紐付くカードを取得
  async fetchScheduleList({ commit, state }, params) {
    const mergedParams = Object.assign(params, {
      pageCount: state.paging.scheduleTablePageCount
    });

    return $axios
      .get(axiosBase.getUrls().getDynamicTrackingList, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("writeDispatchSchedule", value.data.data);
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 動態管理用カード情報を取得
  async fetchDynamicCardDetail({ commit, state }, { orderType, orderId }) {
    return $axios
      .get(axiosBase.getUrls().getDynamicCardDetail, {
        params: {
          scheduledLoadingDate: state.scheduledLoadingDate,
          orderId: orderId,
          orderType: orderType
        },
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  // 荷主一覧を取得
  async fetchGeneralClientList({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getAllGeneralClient).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 配車画面左側。フィルタ荷主一覧取得
  async fetchClientDispatchFilter({ commit, state }, scheduleDate) {
    return $axios.get(axiosBase.getUrls().getClientDispatchFilter + scheduleDate).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  // 配車乗務員備考の取得
  async fetchScheduleRemark({ dispatch, commit, state }, driverId) {
    const params = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      driverId: driverId
    };

    return $axios
      .get(axiosBase.getUrls().getDynamicRemark, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車スライドカード備考の取得
  async fetchSlideCardRemark({ dispatch, commit, state }, params) {
    const mergedParams = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      orderType: params.orderType,
      orderId: params.orderId
    };

    return $axios
      .get(axiosBase.getUrls().getDynamicSlideCardRemark, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 配車カード備考の取得
  async fetchCardRemark({ dispatch, commit, state }, params) {
    const getUrl = axiosBase.getUrls().getDynamicCardRemark + params.orderType + "/" + params.orderId;

    return $axios
      .get(getUrl)
      .then(value => {
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 実績解除設定
  async postDispatchRelayRelease({ commit, state }, data) {
    const postData = {
      scheduledLoadingDate: state.scheduledLoadingDate,
      driverId: "driverId" in data ? data.driverId : null
    };
    return $axios
      .post(axiosBase.getUrls().postDynamicResultRelease, postData)
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  /**
   * カードのマークを行う
   */
  async saveCardMark({ commit, state }, data) {
    return $axios
      .post(
        axiosBase.getUrls().postSaveCardMarkDynamic,
        Object.assign(data, {
          scheduledLoadingDate: state.scheduledLoadingDate
        })
      )
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason.response.data);
      });
  },
  async mergeDriverRemark({ commit, state }, data) {
    commit("mergeDriverRemark", data);
  }
};

const mutations = {
  mergeDriverRemark(state, data) {
    if (state.scheduleList != null && state.scheduleList.length > 0) {
      const schedule = state.scheduleList.filter(line => line.driverId === data.driverId).pop();
      if (schedule) {
        schedule.remark = data.remark;
      }
    }
  },
  writeDispatchSchedule(state, data) {
    state.scheduleList = data == null ? [] : data;
  },
  writeLoadingArea(state, data) {
    state.loadingAreaList = data == null ? [] : data;
  },
  writeUnloadingArea(state, data) {
    state.unloadingAreaList = data == null ? [] : data;
  },
  setScheduledLoadingDate: (state, data) => {
    state.scheduledLoadingDate = data;
  },
  setDriverCount(state, count) {
    state.driverCount = count;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
