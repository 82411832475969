import * as Enum from "@/assets/js/enum";
export default {
  methods: {
    getContractTypeLabel(code) {
      return Enum.Utils.findLabel(code, Enum.ContractType);
    },
    getLiftTypeLabel(code) {
      return Enum.Utils.findLabel(code, Enum.LiftType);
    },
    getShiftTypeLabel(code) {
      return Enum.Utils.findLabel(code, Enum.ShiftType);
    },
    getFloorSpecLabel(code) {
      return Enum.Utils.findLabel(code, Enum.VehicleFloorType);
    },
    billingStatusLabel: state => code => {
      return Enum.Utils.findLabel(code, Enum.BillingStatusType);
    },
    paymentStatusLabel: state => code => {
      return Enum.Utils.findLabel(code, Enum.PaymentStatusType);
    }
  }
};
