<template lang="pug">
  v-menu(v-model="display" :position-x="x",:position-y="y" bottom left)
    v-list(dense)
      v-list-tile(@click="openDriverDetailDialog")
        v-list-tile-action
          v-icon() info
        v-list-tile-content 乗務員詳細
      v-list-tile(@click="openDynamicResultDialog" v-show="!hasDispatchResult")
        v-list-tile-action
          v-icon() work
        v-list-tile-content 業務完了登録
      v-list-tile(@click="openDynamicResultDialog" v-show="hasDispatchResult")
        v-list-tile-action
          v-icon() work
        v-list-tile-content 業務完了変更
      v-list-tile(@click="dynamicResultRelease" v-show="hasDispatchResult")
        v-list-tile-action
          v-icon() work_off
        v-list-tile-content 業務完了解除
</template>

<script>
export default {
  data() {
    return {
      display: false,
      x: 0,
      y: 0,
      item: {}
    };
  },
  computed: {
    hasDispatchResult() {
      return this.item.hasDispatchResult;
    }
  },
  methods: {
    open(data) {
      this.item = data.item;
      // TODO:tickイベント実装？（既に開いていると別のが開かない。二回押下で開くので）
      this.display = false;
      this.x = data.event.clientX;
      this.y = data.event.clientY;
      this.display = true;
    },
    openDriverDetailDialog(event) {
      this.$emit("openDriverDetailDialog", this.item);
    },
    openDynamicResultDialog(event) {
      this.$emit("openDynamicResultDialog", this.item);
    },
    dynamicResultRelease(event) {
      this.$emit("dynamicResultRelease", this.item);
    }
  }
};
</script>
