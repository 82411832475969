<template lang="pug">
  EditDialog(ref="EditDialog" title="支払タリフルール" :recordId="record.id" @delete="erase" @save="save" :loadingSave="loading.save" :loadingDelete="loading.delete" @closeEditDialog="closeEditDialog")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md6)
            DeliveryCompanySelect(labelDisp="1次配送委託先" :label.sync="record.primaryDeliveryCompany.nameAndCd" :isDispCd="true" :code.sync="record.primaryDeliveryCompany.id" :readonly="false" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="primaryDeliveryCompany.id")
          v-flex(xs12 md6)
            PaymentTariffTypeSelect(:label.sync="record.paymentTariffType.name" :code.sync="record.paymentTariffType.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'paymentTariffType.id'")
          v-flex(xs12 md6)
            TariffSelect(:label.sync="record.paymentTariff.tariffName" :code.sync="record.paymentTariff.tariffCd" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'paymentTariff.tariffCd'")
          v-flex(xs12 md6)
            DatePicker(pickerLabel="適用開始日" :initDate="record.applicableStartDate" :inputDate.sync="record.applicableStartDate" :isClearable="false" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'applicableStartDate'")
          v-flex(xs12 md4)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-textarea(v-model="record.remark" label="備考" :error-messages="getErrorMessages('remark')" maxlength=1000)
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import DeliveryCompanySelect from "@/components/common/select/DeliveryCompanySelect";
import PaymentTariffTypeSelect from "@/components/common/select/PaymentTariffTypeSelect";
import TariffSelect from "@/components/common/select/TariffSelect";
import DatePicker from "@/components/common/DatePicker";
import StatusSelect from "@/components/common/select/StatusSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    DeliveryCompanySelect,
    PaymentTariffTypeSelect,
    TariffSelect,
    DatePicker,
    StatusSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: this.initData()
    };
  },
  methods: {
    initData() {
      return {
        id: null,
        primaryDeliveryCompany: {
          id: null,
          companyName: null
        },
        paymentTariffType: {
          id: null
        },
        paymentTariff: {
          tariffCd: null,
          tariffName: null
        },
        applicableStartDate: null,
        status: Enum.ActiveFlag.Active.code,
        remark: null
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    closeEditDialog() {
      this.record = this.initData();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  }
};
</script>
