<template lang="pug">
  div
    template(v-if="!isReadonly")
      v-menu(
        ref="menu"
        :close-on-content-click="false"
        v-model="menu"
        :nudge-right="nudgeRight"
        lazy
        transition="scale-transition"
        offset-overflow
        full-width
        min-width="290px"
        max-width="290px"
      )
        v-text-field.date-input(
          slot="activator"
          v-model="dateAndWeek"
          :readonly="!isEditable"
          @blur="parseDate()"
          prepend-icon="event"
          :clearable="isClearable"
          :label="pickerLabel"
          :error-messages="errorHandlerMethod(errorHandlerMethodParam)"
          v-bind:class="{'sunday':isSunday, 'saturday':isSaturday}"
        )
        v-date-picker(
          locale="jp-ja"
          :day-format="date => new Date(date).getDate()"
          v-model="date"
          @input="menu = false"
          scrollable
        )
    template(v-else)
      v-text-field.date-input(
        slot="activator"
        v-model="dateAndWeek"
        readonly
        box
        :label="pickerLabel"
        :error-messages="errorHandlerMethod(errorHandlerMethodParam)"
        v-bind:class="{'sunday':isSunday, 'saturday':isSaturday}"
      )
</template>

<script>
export default {
  data: () => ({
    menu: false,
    modal: false,
    menu2: false,
    setDate: null
  }),
  props: {
    pickerLabel: {
      type: String,
      default: "日時"
    },
    // TODO:type: String,default:()=>""と思われるが、日時要素のため回避
    // eslint-disable-next-line
    initDate: {
      default: window.Common.Util.getYearMonthDayIsoFormat()
    },
    // TODO:type: String,default:()=>""と思われるが、日時要素のため回避
    // eslint-disable-next-line
    inputDate: {},
    // TODO:利用されていない可能性が高いが、名称変更でimportされている可能性があるため保留。
    // eslint-disable-next-line
    errorMessages: {},
    errorHandlerMethod: {
      type: Function,
      required: false,
      default() {
        return this.getErrorMessages();
      }
    },
    errorHandlerMethodParam: {
      type: String,
      required: false,
      default() {
        return "date";
      }
    },
    dispWeek: {
      type: Boolean,
      default: true
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
    isClearable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    nudgeRight: {
      type: Number,
      default: 40
    }
  },
  computed: {
    date: {
      get() {
        return this.inputDate;
      },
      set(newVal) {
        if (this.inputDate !== newVal) this.$emit("update:inputDate", newVal);
      },
      required: true
    },
    week: {
      get() {
        let convertDate = new Date(this.inputDate);
        let dayOfWeek = convertDate.getDay();
        return ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"][dayOfWeek];
      }
    },
    isSunday: {
      get() {
        let convertDate = new Date(this.inputDate);
        let dayOfWeek = convertDate.getDay();
        return dayOfWeek === 0;
      }
    },
    isSaturday: {
      get() {
        let convertDate = new Date(this.inputDate);
        let dayOfWeek = convertDate.getDay();
        return dayOfWeek === 6;
      }
    },
    dateAndWeek: {
      get() {
        if (this.isEditable) {
          return this.setDate ? this.setDate : this.inputDate ? this.inputDate + " " + this.week : "";
        }
        return this.inputDate ? this.inputDate + " " + this.week : "";
      },
      set(newVal) {
        if (!newVal) {
          this.$emit("update:inputDate", null);
        } else if (this.isEditable) {
          this.setDate = newVal;
        }
      }
    }
  },
  mounted() {
    this.date = this.initDate;
    this.setDate = null;
  },
  methods: {
    getErrorMessages() {
      return "";
    },
    parseDate() {
      if (!this.setDate) return;
      const date = this.substringDate();
      if (!isNaN(date.getDate())) {
        const year = date.getFullYear();
        const strYear = year.toString();
        const mm = ("0" + (date.getMonth() + 1)).slice(-2);
        const dd = ("0" + date.getDate()).slice(-2);
        if (strYear.length < 4) {
          this.updateInputDate(null);
          return;
        }
        const inputDate = year + "-" + mm + "-" + dd;
        this.updateInputDate(inputDate);
        return;
      }
      this.updateInputDate(null);
    },
    updateInputDate(date) {
      this.$emit("update:inputDate", date);
      this.setDate = null;
    },
    substringDate() {
      const date = this.setDate.replace(/[^0-9]/g, "");
      let parseDate = this.setDate;
      if (date.length === 8) {
        parseDate = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8);
      }
      return new Date(parseDate);
    }
  }
};
</script>

<style lang="stylus">
.theme--light.v-input.date-input.sunday input {
  color: red !important;
}

.theme--light.v-input.date-input.saturday input {
  /* color: blue !important; */
}

.v-date-picker-table.v-date-picker-table--date {
  height: auto !important;
}
</style>
