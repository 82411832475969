<template lang="pug">
  v-card
    v-card-title
      v-flex(xs7)
        v-btn(color="info" @click.stop="openGeneralAddOrder") 新規注文追加
        v-btn(color="success" @click.stop="openGeneralOrderHistorySearch") 履歴から複製元を検索

      v-card-text
        table(class="sticky-table order-table")
          thead
            tr
              th No
              th 受付番号
              th 配車状況
              th 依頼主
              th 受注日
              th {{ labelName.LoadingSchedule }}<br/>{{ labelName.UnloadingSchedule }}
              th 積地
              th 卸地
              th 積荷種別
              th 車種
              th 重量(Kg)
              th.no-right-border 備考
              th 複製
          tbody
            template(v-for="(rec, index ) in orderList" v-if="orderList.length")
              tr(v-for="(detail, detailIndex) in rec.details.list" @dblclick="openDetail(detail.id)" :class="{unableEdit : isUnableEdit(detail)}").pointer-dblclick
                td.text-sm-center {{ detail.displayIndex }}
                td.text-sm-center.whitespace-pre {{ detail.receiptNo }}
                td.text-sm-left {{ dispatchStatusLabel(detail) }}
                td
                  span.d-block {{ rec.client.clientDisplayName }}
                  span.d-inline-block {{ detail.clientBranchName }}
                td.text-sm-center {{ rec.orderDate }}
                td(v-html="getCombineDate(rec.loadingDate,rec.unloadingDate)").text-sm-center
                td.text-whitespace-pre() {{ rec.orderLoadings.loadingBaseNames | commaToNr }}
                td.text-whitespace-pre()
                  template(v-for="orderUnloading in rec.orderUnloadings.sortedList")
                    div
                      SpecifiedTimeTypeIcon.pr-1(v-if="!isNoneSpecifiedTime(orderUnloading.specifiedTime.specifiedTimeType)" :isSmall="true" :specifiedTimeType="orderUnloading.specifiedTime.specifiedTimeType")
                      span.time-icon-margin(v-if="isNoneSpecifiedTime(orderUnloading.specifiedTime.specifiedTimeType)")
                      span {{ orderUnloading.unloadingBase.displayBaseName }}
                td {{ rec.itemType.name }}
                td {{ detail.cardVehicleType.vehicleTypeName }}
                td.text-right {{ rec.weight | addComma }}
                td.no-right-border
                  template
                    div.remark-box
                      v-tooltip(top)
                        template(v-slot:activator="{ on }")
                          p(v-on="on").remark.whitespace-pre {{ detail.remark }}
                        span.whitespace-pre {{ detail.remark }}
                      template.remark-box
                        v-tooltip(top :disabled="!detail.lastSlideRemark")
                          template(v-slot:activator="{ on }")
                            v-icon(v-if="detail.slide" v-on="on").warning-box-icon warning
                          span.whitespace-pre {{ detail.lastSlideRemark }}
                td.text-md-center(@click.stop="openCopyOrder(detail.id)")
                  v-btn(flat icon)
                    v-icon() file_copy
            template(v-if="!orderList.length" )
              tr
                td(:colspan="14") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}


    ManualGeneralOrderEdit(ref="generalOrderEditDialog" v-on:updated="onUpdateOrder" @deleted="onUpdateOrder"
      @openBillingHistory="openBillingHistory"
      :deleteUrl="$config.urls.deleteOrder" :postUrl="$config.urls.postGeneralOrder")
    ManualOrderHistoryDialog(ref="ManualOrderHistoryDialog")
    ManualGeneralOrderHistorySearch(ref="ManualGeneralOrderHistorySearch"
     @historySelected="historySelected")
</template>

<script>
import ManualGeneralOrderEdit from "@/components/order/ManualGeneralOrderEdit";
import ManualOrderHistoryDialog from "@/components/order/ManualOrderHistoryDialog";
import ManualGeneralOrderHistorySearch from "@/components/order/ManualGeneralOrderHistorySearch";
import { mapGetters } from "vuex";
import ScrollHandler from "@/mixin/InfiniteScrollHandler";
import SpecifiedTimeTypeIcon from "@/components/common/SpecifiedTimeTypeIcon";
import RerayIcon from "@/components/dispatch/RerayIcon";
import DispatchStatusLabelHandler from "@/mixin/DispatchStatsuLabelHandler";
import { LabelName, FixedDisplay } from "@/assets/js/constants";
export default {
  components: {
    RerayIcon,
    SpecifiedTimeTypeIcon,
    ManualGeneralOrderEdit,
    ManualOrderHistoryDialog,
    ManualGeneralOrderHistorySearch
  },
  data: () => ({
    labelName: LabelName,
    fixedDisplay: FixedDisplay
  }),
  mixins: [ScrollHandler, DispatchStatusLabelHandler],
  computed: {
    ...mapGetters({
      getDistance: "site/distanceTypeStringFromKey"
    }),
    orderList: {
      get() {
        return this.$store.state.order.generalOrderList;
      }
    },
    isUnableEdit: function () {
      return function (detail) {
        return detail.dispatched || detail.relayed;
      };
    },
    dispatchStatuslabel: function () {
      return function (detail) {
        if (detail.dispatched) {
          if (detail.dispatchComplete) return "配送済";
          return "配車済";
        } else if (detail.relayed) {
          return "中継済";
        } else {
          return "未配送";
        }
      };
    }
  },
  methods: {
    /**
     * 受注データ取得
     **/
    loadOrders() {
      this.$store.dispatch("order/fetchAllGeneralOrderCount", this.searchConditions);
      return this.$store.dispatch(
        "order/fetchAllGeneralOrder",
        Object.assign(this.searchConditions, { pageCount: this.pageCount })
      );
    },
    /**
     * 受注詳細ダイアログを開く
     **/
    openDetail(id) {
      const self = this;
      this.$store
        .dispatch("order/fetchGeneralOrderForEdit", id)
        .then(value => {
          self.$refs.generalOrderEditDialog.openDialog(value);
        })
        .catch(reason => {
          const event = document.createEvent("Event");
          event.initEvent(window.Common.Config.observer.onErrorResponse404, false, true);
          window.dispatchEvent(event);
        });
    },
    /**
     * 一般受注追加画面を開く
     */
    openGeneralAddOrder() {
      this.$refs.generalOrderEditDialog.openDialog();
    },
    /**
     * 履歴から複製元を検索ダイアログを開く
     */
    openGeneralOrderHistorySearch() {
      this.$refs.ManualGeneralOrderHistorySearch.openDialog();
    },
    /**
     * 受注明細IDを元に、複製して新規作成ダイアログを開く
     * @param id 受注明細ID
     */
    openCopyOrder(id) {
      const self = this;
      this.$store
        .dispatch("order/fetchGeneralOrderForEdit", id)
        .then(value => {
          self.$refs.generalOrderEditDialog.openDialog(this.clearCopyData(value));
        })
        .catch(reason => {
          const event = document.createEvent("Event");
          event.initEvent(window.Common.Config.observer.onErrorResponse404, false, true);
          window.dispatchEvent(event);
        });
    },
    clearCopyData(value) {
      //複製時は下記項目をクリアして渡す
      value.id = null;
      value.orderDate = "";
      value.loadingDate = "";
      value.unloadingDate = "";
      value.vehicleQuantity = "";
      value.detail.cardId = undefined;
      value.detail.receiptNo = undefined;
      value.detail.financeRemark = "";
      value.detail.remark = "";
      value.copy = true;

      //時間指定もマスタ指定デフォルトに戻してやる
      for (let i = 0; i < value.orderUnloadings.sortedList.length; i++) {
        const base = value.orderUnloadings.sortedList[i];
        base.specifiedTime.from = base.specifiedTime.fromOriginal;
        base.specifiedTime.to = base.specifiedTime.toOriginal;
      }

      return value;
    },
    onUpdateOrder() {
      this.$emit("updatedOrder");
    },
    isNoneSpecifiedTime(value) {
      return value === "00";
    },
    /**
     * 日付2つを受け取って、同じならひとつ、異なるならふたつを改行して表示
     * @param date1
     * @param date2
     * @return {string|*}
     */
    getCombineDate(date1, date2) {
      if (date1 === date2) {
        return date1;
      } else {
        return date1 + "<br/>" + date2;
      }
    },
    /**
     * 処理：受付番号 ツールチップ表示内容作成
     * @param { receiptNoList } 受付番号リスト
     * @return {*} ツールチップ 表示内容
     */
    getReceiptNoTooltipList(receiptNoList) {
      return receiptNoList.join(FixedDisplay.NewLine);
    },
    openBillingHistory() {
      this.$refs.ManualOrderHistoryDialog.openDialog();
    },
    /**
     * 履歴ダイアログの街頭行をDB設計クリックしてきた時のイベント
     * @param detailId
     */
    historySelected(detailId) {
      //履歴ダイアログ閉じる
      this.$refs.ManualGeneralOrderHistorySearch.closeDialog();
      //複製での新規作成を開く
      this.openCopyOrder(detailId);
    }
  },
  mounted() {
    this.$store.state.order.generalOrderList = [];
  }
};
</script>

<style lang="stylus" scoped>
.remark-box {
  max-width: 200px;

  .warning-box-icon {
    color: #FF5722;
  }
}

.order-table {
  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }
}

table.order-table .remark {
  display: inline-block;
  vertical-align: middle;
}

.time-icon-margin {
  margin-right: 20px;
}

.icon-relay-specified {
  $iconSize = 18px;
  display: inline-block;
  width: $iconSize;
  height: $iconSize;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  background-color: red;

  .v-icon {
    font-size: 15px;
    color: white;
  }
}

.text-right {
  text-align: right !important;
}
</style>
