import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  /**
   * 区分け対象となる集計した注文データリストテーブル用
   */
  baseClassifyList: [],
  /**
   * 上記リストから中継用に選択された特定の１件
   */
  targetRelayData: {},
  /**
   * 中継ダイアログで表示する左列リスト
   */
  baseRelayOrderList: [],
  /**
   * 中継用に選択された中継拠点
   */
  selectedRelayBase: {},
  /**
   * 区分け済みの受注データリスト
   */
  classifiedOrderList: []
};

const getters = {
  allData: (state, getters, rootState) => {
    return state.baseClassifyList;
  },
  baseRelayOrderList: (state, getters, rootState) => {
    return state.baseRelayOrderList;
  },
  getClassifiedOrder: (state, getters, rootState) => {
    return state.classifiedOrderList;
  }
};

const actions = {
  fetchAll({ commit, state }) {
    $axios.get(axiosBase.getUrls().getPreClassifyOrders).then(value => {
      value.data.map(v => {
        v.favorite = false;
      });
      commit("writeAll", value.data);
    });
  },
  selectRelayItem({ commit, state }, item) {
    $axios.get(axiosBase.getUrls().getEditedOrderList + item.orderIds.join()).then(value => {
      value.data.map(v => {
        v.favorite = false;
      });
      commit("writeRelayBaseOrders", value.data);
    });

    commit("writeRelayItem", item);
  },
  fetchAllClassifiedOrder({ commit, state }) {
    $axios.get(axiosBase.getUrls().getClassifiedOrder).then(value => {
      value.data.map(v => {
        v.favorite = false;
      });
      commit("writeAllClassified", value.data);
    });
  }
};

const mutations = {
  writeAll(state, data) {
    state.baseClassifyList = data;
  },
  writeAllClassified(state, data) {
    state.classifiedOrderList = data;
  },
  writeRelayItem(state, data) {
    state.targetRelayData = data;
  },
  writeRelayBaseOrders(state, list) {
    state.baseRelayOrderList = list;
  },
  writeBackBaseOrderQuantity(state, payload) {
    state.baseRelayOrderList[payload.index].quantity = payload.num;
  },
  writeRelayBase(state, base) {
    state.selectedRelayBase = base;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
