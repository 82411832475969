<template lang="pug">
  div.warning-box(v-if="hasWarning")
    v-tooltip(top color="#F44336")
      template(v-slot:activator="{on}")
        v-icon(v-on="on").warning-box-icon warning
      span.whitespace-pre {{ warningMessage }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      vehicleOptionLabel: "site/vehicleOptionLabel",
      liftTypeLabel: "site/liftTypeLabel"
    }),
    hasWarning() {
      return this.card.warningMessageList !== null && this.card.warningMessageList.length > 0;
    },
    warningMessage() {
      if (this.hasWarning) {
        return this.card.warningMessageList.join("\n");
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.warning-box{
  position absolute
  width 100px
  height 32px
  top -17px
  left -20px
  z-index 1

  .warning-box-icon{
    font-size 29px;
    color #F44336
  }
}
</style>
