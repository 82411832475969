import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  billingItemList: []
};

const getters = {
  getList: state => state.billingItemList
};

const actions = {
  async fetchAllBillingItem({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllBillingItemList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllBillingItemData");
        }
        commit("appendAllBillingItemData", value.data.data);
      });
  },
  async fetchAllDisplayBillingItem({ commit, state }) {
    return $axios.get(axiosBase.getUrls().getAllDisplayBillingItemList).then(value => {
      commit("clearAllBillingItemData");
      commit("appendAllBillingItemData", value.data.data);
    });
  },
  async fetchBillingItem({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllBillingItem + id).then(value => {
      return value.data.data;
    });
  }
};

const mutations = {
  clearAllBillingItemData(state) {
    state.billingItemList = [];
  },
  appendAllBillingItemData(state, data) {
    if (data != null) {
      state.billingItemList = state.billingItemList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
