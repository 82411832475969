<template lang="pug">
  section
    EditDialog(ref="EditDialog" title="乗務員" :recordId="record.id" @delete="erase" @save="savePre"
        :loadingSave="loading.save" :loadingDelete="loading.delete")
      template(v-slot:body)
        v-container(grid-list-md)
          v-layout(row wrap)
            // 氏名
            v-flex(xs12 md4)
              v-text-field(v-model="record.name" label="氏名" :error-messages="getErrorMessages('name')")

          v-layout(row wrap)
            // 契約種別
            v-flex(xs12 md4)
              ContractTypeSelect(:code.sync="record.contractType" :errorHandlerMethod="getErrorMessages"
                errorHandlerMethodArg="contractType" @changeItem="changeContractType"
                :readonly="dispatchRegisterMode")

            // 所属部署
            v-flex(v-if="isEmployee" xs12 md4)
              v-text-field(v-model="record.department" label="所属部署" :error-messages="getErrorMessages('department')")

          v-layout(row wrap)
            // 電話番号
            v-flex(xs12 md4)
              v-text-field(v-model="record.mobile" label="電話番号" :error-messages="getErrorMessages('mobile')")

            // メールアドレス
            v-flex(xs12 md4)
              v-text-field(v-model="record.email" label="メールアドレス" :error-messages="getErrorMessages('email')")

          v-layout(row wrap)
            // 上流契約委託先会社
            v-flex(v-if="isExclusive || isSpot" xs12 md12)
              v-card
                v-card-text
                  v-container(grid-list-md)
                    v-layout(row wrap)

                      // 上流契約委託配送会社
                      template(v-for="(deliveryCompanyStructure, index) in record.deliveryCompanyStructureList")
                        v-flex(xs12 md12)
                          DeliveryCompanySelect(:excludeCompanyIdList="exclusionCompanyIdList"
                            :readonly="dispatchRegisterMode"
                            :labelDisp="(deliveryCompanyStructure.structureSeq) + '次請け'"
                            :label.sync="deliveryCompanyStructure.deliveryCompanyName"
                            :code.sync="deliveryCompanyStructure.deliveryCompanyId"
                            :errorHandlerMethod="getErrorMessages"
                            :errorHandlerMethodArg="'deliveryCompanyStructureList[' + index + '].deliveryCompanyId'"
                            :prependIcon="DeleteDeliveryCompanyIcon(index)"
                            @prependIconClick="deleteDeliveryCompany(index)")

                      // 所属配送会社
                      v-flex(xs12 md12)
                        DeliveryCompanySelect(:excludeCompanyIdList="exclusionCompanyIdList" labelDisp="所属配送会社"
                          :readonly="dispatchRegisterMode"
                          :label.sync="record.deliveryCompanyName"
                          :code.sync="record.deliveryCompanyId"
                          :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="deliveryCompanyId"
                          @changeItem="changeDeliveryCompany")

                    v-btn(v-if="isDisplayAddDeliveryCompanyStructureButton" color="info" falt
                      @click.stop="addDeliveryCompany") {{ record.deliveryCompanyStructureList.length + 1 }}次請けを追加する

          v-layout(row wrap)
            // 運転可能車種
            v-flex(xs12 md8)
              VehicleTypeMultiSelect(:code.sync="record.drivableVehicleTypeIdList" :errorHandlerMethod="getErrorMessages" @changeItem="changeDrivableVehicleType")

            // 取扱可能リフト区分
            v-flex(xs12 md4)
              LiftTypeSelect(:code.sync="record.handlableLiftType" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="handlableLiftType")

          v-layout(row wrap)
            // 基本車両
            v-flex(xs12 md4)
              RegularUseVehicleSelect(:label.sync="record.regularUseVehicleFullNumberDisp" :code.sync="record.regularUseVehicleId" :errorHandlerMethod="getErrorMessages" :companyId="record.deliveryCompanyId" :drivableVehicleTypeIdList="record.drivableVehicleTypeIdList" @changeItem="changeRegularUseVehicle")

            // ナビッピGPS製造番号
            v-flex(xs12 md4)
              v-text-field(v-model="record.gpsProductNo" label="ナビッピGPS製造番号" :error-messages="getErrorMessages('gpsProductNo')")

          v-layout(row wrap)
            // ステータス
            v-flex(xs12 md4)
              StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

            // 仮乗務員
            v-flex(xs12 md4)
              v-checkbox(v-model="record.useTemporary" label="仮乗務員" :error-messages="getErrorMessages('useTemporary')")
    ConfirmDialog(ref="ConfirmDialog" @yes="save" title="更新確認" message="1次委託先が変更される場合、対象乗務員の確定済以外の配車カードは支払状態が未確定に戻ります。" yesButtonTitle="更新する")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ContractTypeSelect from "@/components/common/select/ContractTypeSelect";
import DeliveryCompanySelect from "@/components/common/select/DeliveryCompanySelect";
import RegularUseVehicleSelect from "@/components/common/select/RegularUseVehicleSelect";
import LiftTypeSelect from "@/components/common/select/LiftTypeSelect";
import StatusSelect from "@/components/common/select/StatusSelect";
import VehicleTypeMultiSelect from "@/components/common/select/VehicleTypeMultiSelect";
import ConfirmDialog from "@/components/common/dialog/ConfirmDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";
import { mapGetters } from "vuex";

export default {
  components: {
    EditDialog,
    ContractTypeSelect,
    DeliveryCompanySelect,
    RegularUseVehicleSelect,
    LiftTypeSelect,
    StatusSelect,
    VehicleTypeMultiSelect,
    ConfirmDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      title: "乗務員編集",
      TRASCOCompanyId: this.$store.state.site.global.TRASCOCompanyId,
      record: {}
    };
  },
  props: {
    entryDataCallBack: {
      type: Function,
      required: false,
      default: record => {}
    },
    dispatchRegisterMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getVehicleIdByCd: "site/getVehicleIdByCd"
    }),
    /**
     * 選択された契約が正社員か判定する
     */
    isEmployee: {
      get() {
        return Enum.ContractType.Employee.code === this.record.contractType;
      }
    },
    /**
     * 選択された契約が専属か判定する
     */
    isExclusive: {
      get() {
        return Enum.ContractType.Exclusive.code === this.record.contractType;
      }
    },
    /**
     * 選択された契約がスポットか判定する
     */
    isSpot: {
      get() {
        return Enum.ContractType.Spot.code === this.record.contractType;
      }
    },
    exclusionCompanyIdList: {
      get() {
        let exclusionDeliveryCompanyIdList = [];
        exclusionDeliveryCompanyIdList.push(this.TRASCOCompanyId);
        if (this.record.deliveryCompanyId) exclusionDeliveryCompanyIdList.push(this.record.deliveryCompanyId);
        this.record.deliveryCompanyStructureList.map(deliveryCompany => {
          if (deliveryCompany.deliveryCompanyId)
            exclusionDeliveryCompanyIdList.push(deliveryCompany.deliveryCompanyId);
        });
        return exclusionDeliveryCompanyIdList;
      }
    },
    isDisplayAddDeliveryCompanyStructureButton: {
      get() {
        if (this.dispatchRegisterMode) {
          return false;
        }

        if (this.record.deliveryCompanyStructureList.length === 0) return true;
        const firstDeliveryCompanyStructure = this.record.deliveryCompanyStructureList[0];
        if (
          this.record.deliveryCompanyStructureList.length < 2 &&
          firstDeliveryCompanyStructure.deliveryCompanyId != null
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    isChangedPrimaryDeliveryCompany: {
      get() {
        if (this.record.id === null) return false;
        if (this.record.deliveryCompanyStructureList.length > 0) {
          return (
            this.record.deliveryCompanyStructureList[0].deliveryCompanyId !==
            this.record.firstStructuredCompanyId
          );
        } else {
          return this.record.deliveryCompanyId !== this.record.firstStructuredCompanyId;
        }
      }
    }
  },
  methods: {
    initData() {
      return {
        id: null,
        name: null,
        contractType: Enum.ContractType.Spot.code,
        department: null,
        deliveryCompanyId: null,
        deliveryCompanyName: null,
        regularUseVehicleId: null,
        regularUseVehicleTypeId: null,
        regularUseVehicleFullNumberDisp: null,
        mobile: null,
        email: null,
        gpsProductNo: null,
        handlableLiftType: Enum.LiftType.UnManaged.code,
        status: Enum.ActiveFlag.Active.code,
        useTemporary: false,
        deliveryCompanyStructureList: [],
        drivableVehicleTypeIdList: [this.getVehicleIdByCd(Enum.PrimaryVehicleType.IncreaseTon.code)]
      };
    },
    /**
     * 編集ダイアログを表示
     */
    openDialog(item) {
      this.initForm(item);
      if (item === undefined) {
        this.entryDataCallBack(this.record);
      }

      this.$refs.EditDialog.openDialog();
    },
    /**
     * 編集ダイアログを閉じる
     */
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    /**
     * 保存前確認
     */
    savePre() {
      if (this.isChangedPrimaryDeliveryCompany) {
        this.$refs.ConfirmDialog.openDialog();
      } else {
        this.save();
      }
    },
    /**
     * 保存
     */
    save() {
      const self = this;
      this.preSave();
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    /**
     * 削除
     */
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    },
    /**
     * 乗務員を保存
     */
    preSave() {
      if (this.isEmployee) {
        // 所属配送会社
        this.record.deliveryCompanyId = this.TRASCOCompanyId;
        // 上流契約委託配送会社ID
        this.record.deliveryCompanyStructureList = [];
      }
      if (this.record.deliveryCompanyStructureList.length) {
        this.record.deliveryCompanyStructureList.map(
          (deliveryCompanyStructure, index) => (deliveryCompanyStructure.structureSeq = index + 1)
        );
      }
    },
    /**
     * 契約変更時の処理
     */
    changeContractType() {
      if (this.isEmployee) {
        this.record.deliveryCompanyId = this.TRASCOCompanyId;
        this.record.regularUseVehicleFullNumberDisp = null;
        this.record.regularUseVehicleId = null;
      } else {
        if (this.record.deliveryCompanyId == this.TRASCOCompanyId) {
          this.record.deliveryCompanyId = null;
          this.record.deliveryCompanyName = null;
          this.record.regularUseVehicleFullNumberDisp = null;
          this.record.regularUseVehicleId = null;
          this.record.department = "";
        }
      }
    },
    /**
     * 基本車両変更時の処理
     */
    changeRegularUseVehicle(data) {
      this.record.regularUseVehicleTypeId = data.vehicleTypeId;
    },
    /**
     * 運転可能車種変更時の処理
     */
    changeDrivableVehicleType(data) {
      var selectRegularUseVehicleTypeId =
        this.record.regularUseVehicleTypeId != null
          ? this.record.regularUseVehicleTypeId
          : this.record.regularUseVehicle === undefined
          ? null
          : this.record.regularUseVehicle.vehicleTypeId;
      var isDrivableVehicleTypeId = data.some(drivableVehicleTypeId => {
        return drivableVehicleTypeId == selectRegularUseVehicleTypeId;
      });
      if (!isDrivableVehicleTypeId) {
        this.record.regularUseVehicleFullNumberDisp = null;
        this.record.regularUseVehicleId = null;
      }
    },
    /**
     * 配送委託先会社変更時の処理
     */
    changeDeliveryCompany() {
      this.record.regularUseVehicleFullNumberDisp = null;
      this.record.regularUseVehicleId = null;
    },
    /**
     * 上流配送委託先会社の追加
     */
    addDeliveryCompany() {
      this.record.deliveryCompanyStructureList.push({
        id: null,
        driverId: this.record.id,
        deliveryCompanyId: null,
        structureSeq: this.record.deliveryCompanyStructureList.length + 1
      });
    },
    /**
     * 上流配送委託先会社の削除
     */
    deleteDeliveryCompany(index) {
      this.record.deliveryCompanyStructureList.splice(index, 1);
    },
    /**
     * @return {string}
     */
    DeleteDeliveryCompanyIcon(index) {
      if (this.dispatchRegisterMode) {
        return "";
      }
      if (index === 0 && this.record.deliveryCompanyStructureList.length > 1) {
        return "";
      }
      return "close";
    }
  },
  created() {
    this.record = this.initData();
  }
};
</script>
