<template lang="pug">
  v-icon(v-if="isUnsent(mailStatus)") fas fa-envelope
  v-icon(v-else-if="isSent(mailStatus)") far fa-envelope
  v-icon(v-else-if="isRead(mailStatus)") fas fa-envelope-open-text
  v-icon(v-else-if="isConfirmed(mailStatus)") fas fa-envelope-open
  v-icon(v-else-if="isProcessed(mailStatus)") check_circle_outline
  span.fa-stack(v-else-if="isSendFailed(mailStatus)")
    v-icon.fa-stack-1x(color="grey") fas fa-envelope
    v-icon.fa-stack-2x.fa-pull-right.icon-send-failed(color="red") fas fa-exclamation-circle
</template>

<script>
import MailStatusHandler from "@/mixin/MailStatusHandler";
export default {
  name: "DriverMailStatus",
  mixins: [MailStatusHandler],
  props: {
    mailStatus: {
      type: String,
      default: "00"
    }
  }
};
</script>

<style lang="stylus" scoped>
.icon-send-failed {
  font-size: 15px;
}
</style>
