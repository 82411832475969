<template>
  <v-btn flat icon :color="getColor">
    <v-icon @click="toggle">filter_list</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "SearchBoxButton",
  data() {
    return {
      status: false
    };
  },
  computed: {
    getColor() {
      return !this.status ? "gray" : "green";
    }
  },
  methods: {
    toggle() {
      this.status = !this.status;
      this.$emit("toggle", this.status);
    }
  }
};
</script>
