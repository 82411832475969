<template lang="pug">
  section
    v-btn(@click="openDialog" color="success") 乗務員から直接選択
    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond"
      :getListActionName="DispatchName")
      template(v-slot:body="{body}")
        td.text-xs-left {{ body.name }}
        td {{ body.firstStructuredCompanyName }}
        td {{ body.deliveryCompanyName }}
        td {{ getVehicleAndChassisNumber(body) }}
        td {{ body.drivableVehicleTypes }}
        td {{ getLiftLabel(body.handlableLiftType) }}
        td {{ getContractTypeLabel(body.contractType) }}

</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";
import * as Enum from "@/assets/js/enum";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    companyId: {
      type: Number,
      required: false,
      default: () => null
    },
    primaryDeliveryCompanyId: {
      type: Number,
      required: false,
      default: () => null
    },
    titleLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "regularUseVehicleId",
      initSearchCond: () => {
        return {
          name: null,
          contractType: null,
          mobile: null,
          liftType: null,
          drivableVehicleTypeCd: null,
          deliveryCompanyId: this.companyId,
          primaryDeliveryCompanyId: this.primaryDeliveryCompanyId
        };
      }
    };
  },
  computed: {
    config() {
      return {
        title: this.titleLabel,
        width: "80%",
        headers: [
          {
            text: "氏名",
            key: "name",
            align: ""
          },
          {
            text: "1次請け委託先",
            key: "firstStructuredCompanyName",
            align: ""
          },
          {
            text: "所属企業",
            key: "deliveryCompanyName",
            align: ""
          },
          {
            text: "車番(シャーシ)",
            key: "vehicleNumber",
            align: ""
          },
          {
            text: "運転可能車格",
            key: "drivableVehicleTypeCd",
            align: "",
            select: true,
            items: this.$store.state.site.primaryVehicleTypes
          },
          {
            text: "リフト",
            key: "liftType",
            rightAlign: "true",
            align: "text-align-right",
            select: true,
            items: Enum.Utils.enumToList(Enum.LiftType)
          },
          {
            text: "契約",
            key: "contractType",
            align: "",
            select: true,
            items: Enum.Utils.enumToList(Enum.ContractType)
          }
        ]
      };
    },
    /**
     * @return {string}
     */
    DispatchName() {
      return "driver/fetchAllDriverForDirectDispatch";
    }
  },
  methods: {
    //名称のフィールド名(code)が異なるのでオーバーライド
    selectItem(data) {
      this.p_code = data.id;
      this.p_label = data.name;
      this.$emit("selectItem", data);
    },
    clear() {
      this.$emit("clear");
    },
    getContractTypeLabel(code) {
      return Enum.Utils.findLabel(code, Enum.ContractType);
    },
    getLiftLabel(code) {
      return Enum.Utils.findLabel(code, Enum.LiftType);
    },
    findResult() {
      return this.$store.dispatch(
        this.DispatchName,
        Object.assign({}, this.initSearchCond(), {
          unlimited: true
        })
      );
    },
    getPrimeDeliveryCompanyName(data) {
      if (data.firstStructuredCompanyId === data.deliveryCompanyId) {
        return "-";
      } else {
        return data.firstStructuredCompanyName;
      }
    },
    getVehicleAndChassisNumber(data) {
      const vehicle = data.regularUseVehicle;
      let numberString = vehicle.entryNumber;
      if (vehicle.chassisNumber !== null) {
        numberString += "(" + vehicle.chassisNumber + ")";
      }

      return numberString;
    }
  }
};
</script>
