<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      BillingItemFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              BillingItemTable(ref="BillingItemTable" :prop-header="billingItemTableHeader" :prop-data="billingItemList" @open-detail="openDetail" detail-url="billingItem/fetchBillingItem" :getListActionName="getListActionName" :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-left {{ body.billItemName }}
                  td.text-xs-left {{ getSaleTaxTarget(body.saleTaxTarget) }}
                  td.text-xs-left {{ body.sortOrder }}
                  td.text-xs-left {{ displayFlagLabel(body.displayTarget) }}

    BillingItemEditDialog(ref="BillingItemEditDialog" :postUrl="$config.urls.postEditBillingItem" :deleteUrl="$config.urls.deleteBillingItem" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import { mapGetters } from "vuex";
import BillingItemEditDialog from "@/components/management/billing-item/BillingItemEdit";
import BillingItemTable from "@/components/common/PagingListTable";
import BillingItemFilterConditions from "@/components/management/billing-item/BillingItemFilterConditions";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    BillingItemEditDialog,
    BillingItemTable,
    BillingItemFilterConditions
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getSaleTaxTarget: "site/saleTaxTargetLabel"
    }),
    getListActionName: {
      get() {
        return "billingItem/fetchAllBillingItem";
      }
    },
    billingItemList: {
      get() {
        return this.$store.state.billingItem.billingItemList;
      }
    },
    billingItemTableHeader: {
      get() {
        return [{ text: "請求項目名" }, { text: "消費税対象" }, { text: "表示順" }, { text: "表示対象" }];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    openAddDialog() {
      this.$refs.BillingItemEditDialog.openDialog();
    },
    openDetail(item) {
      this.$refs.BillingItemEditDialog.openDialog(item);
    },
    loadListData() {
      this.$refs.BillingItemTable.loadData();
    },
    itemUpdated(response, data) {
      this.$refs.BillingItemTable.resetPageCount();
      this.loadListData();
    },
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.BillingItemTable.setSearchConditions(searchCond);
      this.$refs.BillingItemTable.resetPageCount();
      this.loadListData();
    },
    displayFlagLabel(value) {
      return Enum.Utils.findLabel(value, Enum.DisplayFlag);
    }
  }
};
</script>
