<template lang="pug">
  v-menu(v-model="display" :position-x="x",:position-y="y")
    v-list(dense v-show="hasData")
      v-list-tile(@click="openVehicleSelectDialog" v-show="isDisplayVehicleChange")
        v-list-tile-action
          v-icon() local_shipping
        v-list-tile-content 車両変更
      v-list-tile(@click="openSlideEditDialog" v-show="isDisplaySetSlide")
        v-list-tile-action
          v-icon() fast_forward
        v-list-tile-content スライド設定
      v-list-tile(@click="openSlideReleaseDialog" v-show="isDisplayUnSetSlide")
        v-list-tile-action
          v-icon() fast_rewind
        v-list-tile-content スライド解除
      v-list-tile(@click="openCardDetailDialog" v-show="!isTempCard")
        v-list-tile-action
          v-icon() info
        v-list-tile-content カード詳細
      v-list-tile(@click="openWildCardEditDialog" v-show="isTempCard")
        v-list-tile-action
          v-icon() info
        v-list-tile-content カード詳細
      v-list-tile(@click="setNightLoading" v-show="isDisplaySetNightLoading")
        v-list-tile-action
          v-icon() timelapse
        v-list-tile-content 宵積み設定
      v-list-tile(@click="unSetNightLoading" v-show="isDisplayUnSetNightLoading")
        v-list-tile-action
          v-icon() timelapse
        v-list-tile-content 宵積み解除
      v-list-tile(@click="cancelRelay" v-show="isDisplayUnsetRelay")
        v-list-tile-action
          v-icon() remove_circle
        v-list-tile-content 中継解除
    v-list(dense v-show="!hasData && !isRelayRow")
      v-list-tile(@click="openWildCardEditDialog")
        v-list-tile-action
          v-icon() add_circle_outline
        v-list-tile-content カード作成
</template>

<script>
import * as Enum from "@/assets/js/enum";
export default {
  data() {
    return {
      display: false,
      x: 0,
      y: 0,
      item: {},
      itemKey: null,
      driverId: null,
      isRelayRow: false,
      isRecentlyReturned: false
    };
  },
  computed: {
    hasData() {
      return this.item.isBlank === false;
    },
    isTempCard() {
      return this.item.orderType === Enum.OrderType.TempCard.code;
    },
    isDispatch() {
      return this.item.dispatchDetailId !== null;
    },
    isRelay() {
      return this.item.isRelay;
    },
    isDayExceedDest() {
      return this.item.dispatchType === Enum.DispatchType.DayExceedDest.code;
    },
    isNightLoadingDest() {
      return this.item.dispatchType === Enum.DispatchType.NightLoadingDest.code;
    },
    isDisplayVehicleChange() {
      if (!this.isDispatch) {
        return false;
      }

      if (this.isDayExceedDest) {
        return false;
      }

      if (this.isNightLoadingDest) {
        return false;
      }

      return true;
    },
    isDisplaySetSlide() {
      if (this.isDayExceedDest) {
        return false;
      }

      if (this.isNightLoadingDest) {
        return false;
      }

      if (!this.item.canSlide) {
        return false;
      }

      if (this.isRecentlyReturned) {
        return false;
      }

      return true;
    },
    isDisplayUnSetSlide() {
      if (!this.item.canSlideRelease) {
        return false;
      }

      if (this.isRecentlyReturned) {
        return false;
      }

      return true;
    },
    isDisplaySetNightLoading() {
      if (!this.isDispatch) {
        return false;
      }

      if (this.isDayExceedDest) {
        return false;
      }

      if (this.isNightLoadingDest) {
        return false;
      }

      if (!this.item.canNightLoading) {
        return false;
      }

      if (this.item.isNightLoading) {
        return false;
      }

      return true;
    },
    isDisplayUnSetNightLoading() {
      if (!this.isDispatch) {
        return false;
      }

      if (this.isDayExceedDest) {
        return false;
      }

      if (this.isNightLoadingDest) {
        return false;
      }

      if (!this.item.isNightLoading) {
        return false;
      }

      return true;
    },
    isDisplayUnsetRelay() {
      if (!this.isRelay) {
        return false;
      }

      if (this.isDispatch) {
        return false;
      }

      if (this.isDayExceedDest) {
        return false;
      }

      if (this.isNightLoadingDest) {
        return false;
      }

      return true;
    }
  },
  methods: {
    open(data) {
      this.item = data.item;
      this.itemKey = data.itemKey;
      this.driverId = data.driverId;
      this.isRelayRow = data.isRelayRow;
      this.isRecentlyReturned = data.isRecentlyReturned;
      // TODO tickイベント実装？（既に開いていると別のが開かない。二回押下で開くので）
      this.display = false;
      this.x = data.event.clientX - 20;
      this.y = data.event.clientY - 20;
      this.display = true;
    },
    openVehicleSelectDialog(event) {
      this.$emit("openVehicleSelectDialog", { item: this.item, driverId: this.driverId });
    },
    openSlideEditDialog(event) {
      this.$emit("openSlideEditDialog", { data: this.item, itemKey: this.itemKey });
    },
    openSlideReleaseDialog(event) {
      this.$emit("openSlideReleaseDialog", { data: this.item, itemKey: this.itemKey });
    },
    cancelRelay(event) {
      this.$emit("cancelRelay", this.item);
    },
    setNightLoading(event) {
      this.$emit("setNightLoading", {
        card: this.item,
        driverId: this.driverId
      });
    },
    unSetNightLoading(event) {
      this.$emit("unSetNightLoading", {
        card: this.item,
        driverId: this.driverId
      });
    },
    openCardDetailDialog(event) {
      this.$emit("openCardDetailDialog", this.item);
    },
    openWildCardEditDialog(event) {
      this.$emit("openWildCardEditDialog", { item: this.item, driverId: this.driverId });
    }
  }
};
</script>
