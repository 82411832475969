import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  paymentItemList: []
};

const getters = {};

const actions = {
  async fetchAllPaymentItem({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllPaymentItemList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllPaymentItemData");
        }
        commit("appendAllPaymentItemData", value.data.data);
      });
  },
  async fetchPaymentItem({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllPaymentItem + id).then(value => {
      return value.data.data;
    });
  }
};

const mutations = {
  clearAllPaymentItemData(state) {
    state.paymentItemList = [];
  },
  appendAllPaymentItemData(state, data) {
    if (data != null) {
      state.paymentItemList = state.paymentItemList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
