<template>
  <v-alert
    v-show="message"
    v-model="alert"
    :value="true"
    :type="type"
    dismissible
    outline
    transition="fade-transition"
    class="my-3"
    >{{ message }}</v-alert
  >
</template>

<script>
export default {
  name: "AlertBox",
  data() {
    return {
      message: "",
      alert: false,
      type: "error"
    };
  },
  methods: {
    displayMessage(message) {
      this.type = "error";
      this.alert = true;
      this.message = message;
    },
    nonDisplayMessage() {
      this.message = "";
      this.alert = false;
    },
    displaySuccessMessage(message) {
      this.type = "success";
      this.alert = true;
      this.message = message;
    }
  }
};
</script>
