<template lang="pug">
  v-tooltip(top)
    template(v-slot:activator="{ on }")
      span(v-on="on").remark-box.whitespace-nowrap {{ p_text }}
    span {{ p_text }}
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Array],
      required: false,
      default: ""
    }
  },
  computed: {
    p_text: {
      get() {
        if (this.text instanceof Array) {
          return this.text[0];
        } else {
          return this.text;
        }
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.remark-box {
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
</style>
