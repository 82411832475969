<template lang="pug">
  section.table-scroll-x.sticky-container(ref="container")
    table.paging-table(class="import_sticky_table")
      thead
        tr
          th(v-if="getTableDataList.length").blank Excel行
          th(v-if="getTableDataList.length").blank2
          th 荷主CD
          th 荷主名
          th 荷主枝番ID
          th 荷主枝番
          th 積地CD
          th 積地
          th 卸地CD
          th 卸地
          th 適用開始日
          th 状態
          th 高速代フラグ
          th 備考
          template(v-for="idx in getFilterDataListFirstDetail")
            th {{idx.vehicleTypeName}}

      tbody
        tr(v-if="getTableDataList.length" v-for="item in getTableDataList"
          :active="selected" :class="{'record-disabled':isValidError(item.validationError)}")

          td.text-xs-center(:class="{'record-disabled':isValidError(item.validationError)}") {{ item.lineNo }}
          td.text-xs-center(:class="{'record-disabled':isValidError(item.validationError)}")
            ValidationErrorTips(:validation-error="item.validationError")
          td.text-xs-left {{ item.clientCd }}
          td.text-xs-left {{ item.clientName }}
          td.text-xs-right {{ item.clientBranchId }}
          td.text-xs-left {{ item.clientBranchName }}
          td.text-xs-left {{ item.loadingBaseCd }}
          td.text-xs-left {{ item.loadingBaseName }}
          td.text-xs-left {{ item.unloadingBaseCd }}
          td.text-xs-left {{ item.unloadingBaseName }}
          td.text-xs-center(width="130px") {{ item.applicableStartDate }}
          td.text-xs-left {{ item.statusInput }}
          td.text-xs-left {{ item.tollActualCostFlgInput }}
          td.text-xs-left
            div
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  p(v-on="on").remark.whitespace-pre {{ item.remark }}
                span.whitespace-pre {{ item.remark }}

          template(v-for="idx in item.detailWorkLIst" )
            td.text-xs-right
              DigitSeparatorsLabel(:value="idx.price")

        tr(v-if="!getTableDataList.length")
          td(:colspan="13") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import EventDispatcher from "@/mixin/EventDispatcher";
import InfiniteScrollHandler from "@/mixin/InfiniteScrollHandler";
import ValidationErrorTips from "@/components/common/ValidationErrorTips";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import * as Enum from "@/assets/js/enum";
import { mapGetters } from "vuex";
import AxiosBase from "@/assets/js/axios-base";
import { LimitNumber } from "@/assets/js/constants";

let axiosBase = new AxiosBase();

export default {
  components: {
    ValidationErrorTips,
    DigitSeparatorsLabel
  },
  mixins: [EventDispatcher, InfiniteScrollHandler],
  props: {
    isFilterError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
      dialog: false,
      fetchActionParams: {
        url: axiosBase.getUrls().getFixedBillingWorkList
      },
      oldScrollTop: null
    };
  },
  computed: {
    ...mapGetters("site", {
      isValidError: "isValidError"
    }),
    ...mapGetters("financeImport", {
      getTableDataList: "workList",
      workVehicleList: "workVehicleList"
    }),
    tableHeader: {
      get() {
        return [
          { text: "行番号", value: "lineNo", centerAlign: false },
          { text: "タリフCD", value: "tariffCd", centerAlign: false },
          { text: "タリフ名", value: "tariffName", centerAlign: false },
          { text: "タリフ略称", value: "tariffShortName", centerAlign: false },
          { text: "適用開始日", value: "applicableStartDateMin", centerAlign: false },
          { text: "距離", value: "distance", centerAlign: false },
          { text: "状態", value: "status", centerAlign: false },
          { text: "備考", value: "remark", centerAlign: false }
        ];
      }
    },
    getFilterDataListFirstDetail: {
      get() {
        if (this.getTableDataList[0]) {
          return this.getTableDataList[0].detailWorkLIst;
        } else {
          return [];
        }
      }
    }
  },
  mounted() {
    this.$refs.container.removeEventListener("scroll", this.handleScroll);
    this.$refs.container.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    isDisabled(item) {
      if (item.status === undefined) return false;
      return item.status === Enum.ActiveFlag.InActive.code;
    },
    loadUploadData() {
      this.$refs.container.scrollTop = 0;
      this.resetPageCount();
      this.loadData();
    },
    handleScroll() {
      if (this.$refs.container !== undefined) {
        const clientHeight = this.$refs.container.clientHeight;
        const scrollTop = this.$refs.container.scrollTop;
        const scrollHeight = this.$refs.container.scrollHeight;
        if (this.oldScrollTop === scrollTop) return;
        // 横スクロール用にスクロールトップ退避
        this.oldScrollTop = scrollTop;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber && !this.getProgress()) {
          this.addPageCount();
          this.loadData();
        }
      }
    }
  }
};
</script>

<style lang="stylus" scoped>

table.paging-table {
  tbody tr:hover {
    td, th {
      background-color: #f5f5f5;
      transition: 0.2s;
    }
  }

  th, td {
    border-right 1px solid #E0E0E0;
    height: 48px;
  }

  th:last-child,
  td:last-child {
    border-right none;
  }
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.th-text {
  margin-top: 20px;
  font-size: 13px;
}

.v-text-field {
  padding-top: 8px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 14px;
  font-weight: 400;
}
</style>
