<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    return-value.sync="time"
    lazy
    transition="fade-transition"
    offset-overflow
    full-width
    max-width="290px"
    min-width="290px"
  >
    <v-text-field
      slot="activator"
      v-model="time"
      prepend-icon="access_time"
      clearable
      readonly
      :label="pickerLabel"
      :error-messages="errorHandlerMethod(errorHandlerMethodParam)"
    ></v-text-field>
    <v-time-picker
      v-if="menu"
      v-model="time"
      format="24hr"
      :allowed-minutes="allowedStep"
      @click:minute="$refs.menu.save"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import ApiHandler from "../../mixin/ApiHandler";
export default {
  mixins: [ApiHandler],
  props: {
    pickerLabel: {
      type: String,
      default: "時刻"
    },
    // TODO:type: String,default:()=>""と思われるが、日時要素のため回避
    // eslint-disable-next-line
    inputTime: {},
    errorHandlerMethod: {
      type: Function,
      required: false,
      default() {
        return this.getErrorMessages;
      }
    },
    errorHandlerMethodParam: {
      type: String,
      required: false,
      default() {
        return "time";
      }
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    time: {
      get() {
        return this.inputTime;
      },
      set(newVal) {
        if (this.inputTime !== newVal) this.$emit("update:inputTime", newVal);
      },
      required: true
    }
  },
  methods: {
    allowedStep: m => m % 15 === 0
  }
};
</script>
