<template lang="pug">
  section
    EditDialog(ref="EditDialog" title="一括反映" width="500px"
      :recordId="detail.id"
      :loadingSave="loading.save"
      labelSaveButton="一括反映"
      @save="openConfirmDialog"
      :disabledSave="!enabledSave"
      :displayDeleteButton="false"
      :hasTitleSuffix="false")
      template(v-slot:body)
        v-container(grid-list-md)
          v-layout(row wrap)
            v-flex(xs12 md6)
              v-checkbox(color="green" :disabled="isNotPublished" label="確定済に変更" v-model="detail.statusConfirmed" v-on:change="changeStatus")
          v-layout(row wrap)
            v-flex(xs12 md6)
              DatePicker(pickerLabel="送信期限" :isReadonly="detail.statusConfirmed" :initDate="detail.faxSendPeriod" :inputDate.sync="detail.faxSendPeriod" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'faxSendPeriod'")
            v-flex(xs12 md6)
              DatePicker(pickerLabel="必着期限" :isReadonly="detail.statusConfirmed" :initDate="detail.arrivePeriod" :inputDate.sync="detail.arrivePeriod" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'arrivePeriod'")
    ConfirmDialog(ref="ConfirmDialog" @yes="onSave" title="一括反映確認" message="一括で対象に設定を反映します、よろしいですか？" yesButtonTitle="反映する")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import EditDialog from "@/components/common/dialog/EditDialog";
import SelectBoilerplate from "@/components/report/SelectBoilerplate";
import SelectSignature from "@/components/report/SelectSignature";
import ConfirmDialog from "@/components/common/dialog/ConfirmDialog";
import DatePicker from "@/components/common/DatePicker";

export default {
  components: {
    EditDialog,
    SelectBoilerplate,
    SelectSignature,
    ConfirmDialog,
    DatePicker
  },
  mixins: [ApiHandler],
  data: () => ({
    // 更新情報
    detail: {}
  }),
  props: {
    isNotPublished: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    enabledSave: {
      get() {
        return this.detail.statusConfirmed || (this.detail.faxSendPeriod && this.detail.arrivePeriod);
      }
    }
  },
  methods: {
    /**
     * 初期データ
     */
    initData() {
      return {
        detail: {
          statusConfirmed: false,
          faxSendPeriod: null,
          arrivePeriod: null
        }
      };
    },
    /**
     * Event：ダイアログを開く
     * ※親→子（このコンポーネント）→孫（slot利用）すべて参照利用。
     */
    openDialog() {
      this.detail = this.initData().detail;
      // mixin:ApiHandlerに情報を設定
      this.errors = {};
      this.$refs.EditDialog.openDialog();
    },
    openConfirmDialog() {
      this.$refs.ConfirmDialog.openDialog();
    },
    changeStatus() {
      if (this.detail.statusConfirmed) {
        this.detail.faxSendPeriod = null;
        this.detail.arrivePeriod = null;
      }
    },
    /**
     * 保存：一括設定用
     */
    async onSave() {
      this.$emit("onSave", this.detail);
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    setFieldError(error) {
      this.setErrorMessages(error);
    }
  },
  beforeMount() {
    this.detail = this.initData().detail;
  }
};
</script>
