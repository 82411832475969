// TODO 項目が全部ベタ書き
<template lang="pug">
  section
    v-tabs(color="primary darken-2" dark slider-color="yellow darken-2" v-model="model")
      v-tab(href="#tab1")
        div 状態
      v-tab(href="#tab2")
        div 荷主
      v-tab(href="#tab3")
        div 車庫エリア
    v-tabs-items(v-model="model")
      v-tab-item(value="tab1")
        v-card(flat)
          v-card-text(v-if="!isDynamic")
            v-layout(row wrap align-center justify-start fill-height)
              v-flex(xs12)
                v-layout(row wrap align-center justify-start fill-height)
                  v-flex.subheading(xs6)
                    span 乗務員条件
                  v-flex.subheading(xs6)
                    span カード条件
            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 運転可能車格
              v-flex(xs4)
                template(v-for="vehicleType in searchCondItem.drivableVehicleType")
                  BaseFilterChip(:chipName="vehicleType.label" :active="vehicleType.active" @changeFilter="(value) => vehicleType.active = value")

              v-flex.item-name(xs2)
                span 積載条件
              v-flex(xs4)
                BaseFilterChip(chipName="時間指定" :active="searchCondItem.specifiedTime" @changeFilter="(value) => searchCondItem.specifiedTime = value")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 車両積載重量
              v-flex(xs4)
                BaseFilterChip(chipName="～11トン" :active="searchCondItem.vehicleCarryWeight.w0_11.active" @changeFilter="(value) => searchCondItem.vehicleCarryWeight.w0_11.active = value")
                BaseFilterChip(chipName="11～13トン" :active="searchCondItem.vehicleCarryWeight.w11_13.active" @changeFilter="(value) => searchCondItem.vehicleCarryWeight.w11_13.active = value")
                BaseFilterChip(chipName="13～14トン" :active="searchCondItem.vehicleCarryWeight.w13_14.active" @changeFilter="(value) => searchCondItem.vehicleCarryWeight.w13_14.active = value")
                BaseFilterChip(chipName="14トン～" :active="searchCondItem.vehicleCarryWeight.w14_o.active" @changeFilter="(value) => searchCondItem.vehicleCarryWeight.w14_o.active = value")

              v-flex.item-name(xs2)
                span 車両車格
              v-flex(xs4)
                template(v-for="vehicleType in searchCondItem.vehicleType")
                  BaseFilterChip(:chipName="vehicleType.label" :active="vehicleType.active" @changeFilter="(value) => vehicleType.active = value")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 運転可能リフト
              v-flex(xs4)
                BaseFilterChip(chipName="リーチ" :active="searchCondItem.liftType.reach.active" @changeFilter="(value) => searchCondItem.liftType.reach.active = value")
                BaseFilterChip(chipName="リーチ・カウンター両方" :active="searchCondItem.liftType.both.active" @changeFilter="(value) => searchCondItem.liftType.both.active = value")
                BaseFilterChip(chipName="カウンター" :active="searchCondItem.liftType.counter.active" @changeFilter="(value) => searchCondItem.liftType.counter.active = value")

              v-flex.item-name(xs2)
                span 拠点要求オプション
              v-flex(xs4)
                template(v-for="option in searchCondItem.vehicleOption")
                  BaseFilterChip(:chipName="option.label" :active="option.active" @changeFilter="(value) => option.active = value")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 契約条件
              v-flex(xs4)
                BaseFilterChip(chipName="直接雇用" :active="searchCondItem.contractType.employee.active" @changeFilter="(value) => searchCondItem.contractType.employee.active = value")
                BaseFilterChip(chipName="専属" :active="searchCondItem.contractType.exclusive.active" @changeFilter="(value) => searchCondItem.contractType.exclusive.active = value")
                BaseFilterChip(chipName="スポット" :active="searchCondItem.contractType.spot.active" @changeFilter="(value) => searchCondItem.contractType.spot.active = value")
              v-flex.item-name(xs2)
                span マーク
              v-flex(xs4)
                BaseFilterChip(chipName="マーク1" :active="searchCondItem.mark.mark1.active" @changeFilter="(value) => searchCondItem.mark.mark1.active = value")
                BaseFilterChip(chipName="マーク2" :active="searchCondItem.mark.mark2.active" @changeFilter="(value) => searchCondItem.mark.mark2.active = value")
                BaseFilterChip(chipName="マーク3" :active="searchCondItem.mark.mark3.active" @changeFilter="(value) => searchCondItem.mark.mark3.active = value")
                BaseFilterChip(chipName="マーク4" :active="searchCondItem.mark.mark4.active" @changeFilter="(value) => searchCondItem.mark.mark4.active = value")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 1次委託先会社
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.primaryContractorName")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 所属会社
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.deliveryCompanyName")
              v-flex(xs1)
              v-flex.item-name(xs2)
                span 車番
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.vehicleNumber" maxlength="4")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 乗務員名
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.driverName")
              v-flex(xs1)
              v-flex.item-name(xs2)
                span シップ/受付/中継番号
              v-flex(xs3)
                v-textarea(v-model="searchCondItem.shipmentNo" maxlength=700 rows="1")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 積地名
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.loadingBaseName")
              v-flex(xs1)
              v-flex.item-name(xs2)
                span 卸地名
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.unloadingBaseName")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span メール配信(第1運行)
              v-flex(xs4)
                BaseFilterChip(chipName="未配信" :active="searchCondItem.firstMailType.unsent.active" @changeFilter="(value) => searchCondItem.firstMailType.unsent.active = value")
                BaseFilterChip(chipName="配信済" :active="searchCondItem.firstMailType.sent.active" @changeFilter="(value) => searchCondItem.firstMailType.sent.active = value")
                BaseFilterChip(chipName="開封済" :active="searchCondItem.firstMailType.read.active" @changeFilter="(value) => searchCondItem.firstMailType.read.active = value")
                BaseFilterChip(chipName="承認済" :active="searchCondItem.firstMailType.confirmed.active" @changeFilter="(value) => searchCondItem.firstMailType.confirmed.active = value")
              v-flex.item-name(xs2)
                span 配車明細ID
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.dispatchDetailIds" maxlength="30")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span メール配信(全運行)
              v-flex(xs4)
                BaseFilterChip(chipName="未配信" :active="searchCondItem.allMailType.unsent.active" @changeFilter="(value) => searchCondItem.allMailType.unsent.active = value")
                BaseFilterChip(chipName="配信済" :active="searchCondItem.allMailType.sent.active" @changeFilter="(value) => searchCondItem.allMailType.sent.active = value")
                BaseFilterChip(chipName="開封済" :active="searchCondItem.allMailType.read.active" @changeFilter="(value) => searchCondItem.allMailType.read.active = value")
                BaseFilterChip(chipName="承認済" :active="searchCondItem.allMailType.confirmed.active" @changeFilter="(value) => searchCondItem.allMailType.confirmed.active = value")
              v-flex.item-name(xs2)
                span 運行状態
              v-flex(xs4)
                BaseFilterChip(chipName="第1運行未設定" :active="searchCondItem.operatingConditions.isNotFirst.active" @changeFilter="(value) => searchCondItem.operatingConditions.isNotFirst.active = value")
                BaseFilterChip(chipName="宵積みあり" :active="searchCondItem.operatingConditions.isNightLoading.active" @changeFilter="(value) => searchCondItem.operatingConditions.isNightLoading.active = value")


            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span お気に入り
              v-flex(xs4)
                BaseFilterChip(chipName="あり" :active="searchCondItem.isFavoriteOn" @changeFilter="(value) => searchCondItem.isFavoriteOn = value")
                BaseFilterChip(chipName="なし" :active="searchCondItem.isFavoriteOff" @changeFilter="(value) => searchCondItem.isFavoriteOff = value")
              v-flex.item-name(xs2)
                span 空車回送カード設定
              v-flex(xs4)
                BaseFilterChip(chipName="適用" :active="searchCondItem.isEmptyCard" @changeFilter="(value) => searchCondItem.isEmptyCard = value")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 勤務シフト
              v-flex(xs4)
                BaseFilterChip(chipName="日勤" :active="searchCondItem.driverShift.day.active" @changeFilter="(value) => searchCondItem.driverShift.day.active = value")
                BaseFilterChip(chipName="夜勤" :active="searchCondItem.driverShift.night.active" @changeFilter="(value) => searchCondItem.driverShift.night.active = value")
                BaseFilterChip(chipName="非番" :active="searchCondItem.driverShift.rest.active" @changeFilter="(value) => searchCondItem.driverShift.rest.active = value")

          v-card-text(v-if="isDynamic")
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.subheading(xs6)
                span 乗務員条件
              v-flex.subheading(xs6)
                span カード条件
            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 運転可能車格
              v-flex(xs4)
                template(v-for="vehicleType in searchCondItem.drivableVehicleType")
                  BaseFilterChip(:chipName="vehicleType.label" :active="vehicleType.active" @changeFilter="(value) => vehicleType.active = value")

              v-flex.item-name(xs2)
                span 車両車格
              v-flex(xs4)
                template(v-for="vehicleType in searchCondItem.vehicleType")
                  BaseFilterChip(:chipName="vehicleType.label" :active="vehicleType.active" @changeFilter="(value) => vehicleType.active = value")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 1次委託先会社
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.primaryContractorName")

              v-flex(xs1)
              v-flex.item-name(xs2)
                span マーク
              v-flex(xs4)
                BaseFilterChip(chipName="マーク1" :active="searchCondItem.mark.mark1.active" @changeFilter="(value) => searchCondItem.mark.mark1.active = value")
                BaseFilterChip(chipName="マーク2" :active="searchCondItem.mark.mark2.active" @changeFilter="(value) => searchCondItem.mark.mark2.active = value")
                BaseFilterChip(chipName="マーク3" :active="searchCondItem.mark.mark3.active" @changeFilter="(value) => searchCondItem.mark.mark3.active = value")
                BaseFilterChip(chipName="マーク4" :active="searchCondItem.mark.mark4.active" @changeFilter="(value) => searchCondItem.mark.mark4.active = value")


            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 所属会社
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.deliveryCompanyName")
              v-flex(xs1)
              v-flex.item-name(xs2)
                span 車番
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.vehicleNumber" maxlength="4")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 乗務員名
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.driverName")
              v-flex(xs1)
              v-flex.item-name(xs2)
                span シップ/受付/中継番号
              v-flex(xs3)
                v-textarea(v-model="searchCondItem.shipmentNo" maxlength=700 rows="1")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 積地名
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.loadingBaseName")
              v-flex(xs1)
              v-flex.item-name(xs2)
                span 卸地名
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.unloadingBaseName")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 契約条件
              v-flex(xs4)
                BaseFilterChip(chipName="直接雇用" :active="searchCondItem.contractType.employee.active" @changeFilter="(value) => searchCondItem.contractType.employee.active = value")
                BaseFilterChip(chipName="専属" :active="searchCondItem.contractType.exclusive.active" @changeFilter="(value) => searchCondItem.contractType.exclusive.active = value")
                BaseFilterChip(chipName="スポット" :active="searchCondItem.contractType.spot.active" @changeFilter="(value) => searchCondItem.contractType.spot.active = value")
              v-flex.item-name(xs2)
                span 配車明細ID
              v-flex(xs3)
                v-text-field(v-model="searchCondItem.dispatchDetailIds" maxlength="30")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 配送状態
              v-flex(xs4)
                BaseFilterChip(chipName="未配送" :active="searchCondItem.isComplete === '0'" @changeFilter="(value) =>  searchCondItem.isComplete === (value ? '0' : '1') ? searchCondItem.isComplete = null : searchCondItem.isComplete = '0'")
                BaseFilterChip(chipName="配送完了" :active="searchCondItem.isComplete === '1'" @changeFilter="(value) => searchCondItem.isComplete === (value ? '1' : '0') ? searchCondItem.isComplete = null : searchCondItem.isComplete = '1'")
              v-flex.item-name(xs2)
                span ナビッピ対象のみ
              v-flex(xs4)
                BaseFilterChip(chipName="適用" :active="searchCondItem.onlyGps === true" @changeFilter="(value) => searchCondItem.onlyGps = value")

            // line
            v-layout(row wrap align-center justify-start fill-height)
              v-flex.item-name(xs2)
                span 勤務シフト
              v-flex(xs4)
                BaseFilterChip(chipName="日勤" :active="searchCondItem.driverShift.day.active" @changeFilter="(value) => searchCondItem.driverShift.day.active = value")
                BaseFilterChip(chipName="夜勤" :active="searchCondItem.driverShift.night.active" @changeFilter="(value) => searchCondItem.driverShift.night.active = value")

      v-tab-item(value="tab2")
        v-card(flat)
          v-card-text.dispatch_filter_card
            DispatchClientFilter(ref="DispatchClientFilter" v-bind:prop-data="clientList" @click="(value) => searchCondItem.clientId = value" @clickBranch="(value) => searchCondItem.clientBranchId = value")
      v-tab-item(value="tab3")
        v-card(flat)
          v-card-text.dispatch_filter_card
            DispatchAreaFilter(ref="DispatchAreaFilter" v-bind:prop-data="areaList" @click="(value) => searchCondItem.vehicleGarageArea = value")
</template>

<script>
import BaseFilterChip from "@/components/common/BaseFilterChip";
import DispatchClientFilter from "@/components/dispatch/DispatchClientFilter";
import DispatchAreaFilter from "@/components/dispatch/DispatchAreaFilter";
import _ from "lodash";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    BaseFilterChip,
    DispatchClientFilter,
    DispatchAreaFilter
  },
  data() {
    return {
      model: "tab1",
      conditions: {
        displayAbsentDriver: false
      },
      searchCondItem: {},
      searchCondItemDef: {
        loadingDate: null,
        specifiedTime: false,
        vehicleType: [],
        vehicleCarryWeight: {
          w0_11: { value: Enum.CarryWeightType.CarryWeightType10.code, active: false },
          w11_13: { value: Enum.CarryWeightType.CarryWeightType20.code, active: false },
          w13_14: { value: Enum.CarryWeightType.CarryWeightType30.code, active: false },
          w14_o: { value: Enum.CarryWeightType.CarryWeightType40.code, active: false }
        },
        vehicleOption: [],
        drivableVehicleType: [],
        driverShift: {
          day: { value: Enum.ShiftType.Day.code, active: false },
          night: { value: Enum.ShiftType.Night.code, active: false },
          rest: { value: Enum.ShiftType.Rest.code, active: false }
        },
        isFavoriteOn: false,
        isFavoriteOff: false,
        operatingConditions: {
          isNotFirst: { value: Enum.operatingConditionList.isNotFirst.code, active: false },
          isNightLoading: { value: Enum.operatingConditionList.isNightLoading.code, active: false }
        },
        liftType: {
          reach: { value: Enum.LiftType.Reach.code, active: false },
          counter: { value: Enum.LiftType.Counter.code, active: false },
          both: { value: Enum.LiftType.Both.code, active: false }
        },
        contractType: {
          employee: { value: Enum.ContractType.Employee.code, active: false },
          exclusive: { value: Enum.ContractType.Exclusive.code, active: false },
          spot: { value: Enum.ContractType.Spot.code, active: false }
        },
        firstMailType: {
          unsent: { value: Enum.MailStatusType.Unsent.code, active: false },
          sent: { value: Enum.MailStatusType.Sent.code, active: false },
          read: { value: Enum.MailStatusType.Read.code, active: false },
          confirmed: { value: Enum.MailStatusType.Confirm.code, active: false }
        },
        allMailType: {
          unsent: { value: Enum.MailStatusType.Unsent.code, active: false },
          sent: { value: Enum.MailStatusType.Sent.code, active: false },
          read: { value: Enum.MailStatusType.Read.code, active: false },
          confirmed: { value: Enum.MailStatusType.Confirm.code, active: false }
        },
        isEmptyCard: false,
        deliveryCompanyName: "",
        primaryContractorName: "",
        driverName: "",
        vehicleNumber: "",
        shipmentNo: "",
        dispatchDetailIds: "",
        loadingBaseName: "",
        unloadingBaseName: "",
        mark: {
          mark1: { value: Enum.CardMarkType.MarkOne.code, active: false },
          mark2: { value: Enum.CardMarkType.MarkTwo.code, active: false },
          mark3: { value: Enum.CardMarkType.MarkThree.code, active: false },
          mark4: { value: Enum.CardMarkType.MarkFour.code, active: false }
        },
        isComplete: null,
        onlyGps: false,
        clientId: [],
        clientBranchId: [],
        vehicleGarageArea: []
      }
    };
  },
  computed: {
    vehicleTypeList: {
      get() {
        let vehicleTypeList = this.$store.state.site.vehicleTypeIds;
        vehicleTypeList.map(value => {
          value.active = false;
        });
        return vehicleTypeList;
      }
    },
    drivableVehicleTypeList: {
      get() {
        let drivableVehicleTypeList = _.cloneDeep(this.$store.state.site.vehicleTypeIds);
        drivableVehicleTypeList.map(value => {
          value.active = false;
        });
        return drivableVehicleTypeList;
      }
    },
    vehicleOptionList: {
      get() {
        let vehicleOptionList = this.$store.state.site.vehicleOptionTypes;
        vehicleOptionList.map(value => {
          value.active = false;
        });
        return vehicleOptionList;
      }
    },
    vehicleType: {
      get() {
        let vehicleTypeList = [];
        this.searchCondItem.vehicleType.map(value => {
          if (value.active) vehicleTypeList.push(value.id);
        });
        return vehicleTypeList;
      }
    },
    vehicleCarryWeight: {
      get() {
        let vehicleCarryWeightList = [];
        if (_.get(this.searchCondItem.vehicleCarryWeight, "w0_11.active") === true)
          vehicleCarryWeightList.push(this.searchCondItem.vehicleCarryWeight.w0_11.value);
        if (_.get(this.searchCondItem.vehicleCarryWeight, "w11_13.active") === true)
          vehicleCarryWeightList.push(this.searchCondItem.vehicleCarryWeight.w11_13.value);
        if (_.get(this.searchCondItem.vehicleCarryWeight, "w13_14.active") === true)
          vehicleCarryWeightList.push(this.searchCondItem.vehicleCarryWeight.w13_14.value);
        if (_.get(this.searchCondItem.vehicleCarryWeight, "w14_o.active") === true)
          vehicleCarryWeightList.push(this.searchCondItem.vehicleCarryWeight.w14_o.value);
        return vehicleCarryWeightList;
      }
    },
    vehicleOption: {
      get() {
        let vehicleOptionList = [];
        this.searchCondItem.vehicleOption.map(value => {
          if (value.active) vehicleOptionList.push(value.code);
        });
        return vehicleOptionList;
      }
    },
    drivableVehicleType: {
      get() {
        let drivableVehicleTypeList = [];
        this.searchCondItem.drivableVehicleType.map(value => {
          if (value.active) drivableVehicleTypeList.push(value.id);
        });
        return drivableVehicleTypeList;
      }
    },
    driverShift: {
      get() {
        let driverShiftList = [];
        if (_.get(this.searchCondItem.driverShift, "day.active") === true)
          driverShiftList.push(this.searchCondItem.driverShift.day.value);
        if (_.get(this.searchCondItem.driverShift, "night.active") === true)
          driverShiftList.push(this.searchCondItem.driverShift.night.value);
        if (_.get(this.searchCondItem.driverShift, "rest.active") === true)
          driverShiftList.push(this.searchCondItem.driverShift.rest.value);
        return driverShiftList;
      }
    },
    operatingConditions: {
      get() {
        let operatingConditionList = [];
        if (_.get(this.searchCondItem.operatingConditions, "isNotFirst.active") === true)
          operatingConditionList.push(this.searchCondItem.operatingConditions.isNotFirst.value);
        if (_.get(this.searchCondItem.operatingConditions, "isNightLoading.active") === true)
          operatingConditionList.push(this.searchCondItem.operatingConditions.isNightLoading.value);
        return operatingConditionList;
      }
    },
    liftType: {
      get() {
        let liftTypeList = [];
        if (_.get(this.searchCondItem.liftType, "reach.active") === true)
          liftTypeList.push(this.searchCondItem.liftType.reach.value);
        if (_.get(this.searchCondItem.liftType, "counter.active") === true)
          liftTypeList.push(this.searchCondItem.liftType.counter.value);
        if (_.get(this.searchCondItem.liftType, "both.active") === true)
          liftTypeList.push(this.searchCondItem.liftType.both.value);
        return liftTypeList;
      }
    },
    contractType: {
      get() {
        let contractTypeList = [];
        if (_.get(this.searchCondItem.contractType, "employee.active") === true)
          contractTypeList.push(this.searchCondItem.contractType.employee.value);
        if (_.get(this.searchCondItem.contractType, "exclusive.active") === true)
          contractTypeList.push(this.searchCondItem.contractType.exclusive.value);
        if (_.get(this.searchCondItem.contractType, "spot.active") === true)
          contractTypeList.push(this.searchCondItem.contractType.spot.value);
        return contractTypeList;
      }
    },
    firstMailType: {
      get() {
        let firstMailType = [];
        if (_.get(this.searchCondItem.firstMailType, "unsent.active") === true)
          firstMailType.push(this.searchCondItem.firstMailType.unsent.value);
        if (_.get(this.searchCondItem.firstMailType, "sent.active") === true)
          firstMailType.push(this.searchCondItem.firstMailType.sent.value);
        if (_.get(this.searchCondItem.firstMailType, "read.active") === true)
          firstMailType.push(this.searchCondItem.firstMailType.read.value);
        if (_.get(this.searchCondItem.firstMailType, "confirmed.active") === true)
          firstMailType.push(this.searchCondItem.firstMailType.confirmed.value);
        return firstMailType;
      }
    },
    allMailType: {
      get() {
        let allMailTypeType = [];
        if (_.get(this.searchCondItem.allMailType, "unsent.active") === true)
          allMailTypeType.push(this.searchCondItem.allMailType.unsent.value);
        if (_.get(this.searchCondItem.allMailType, "sent.active") === true)
          allMailTypeType.push(this.searchCondItem.allMailType.sent.value);
        if (_.get(this.searchCondItem.allMailType, "read.active") === true)
          allMailTypeType.push(this.searchCondItem.allMailType.read.value);
        if (_.get(this.searchCondItem.allMailType, "confirmed.active") === true)
          allMailTypeType.push(this.searchCondItem.allMailType.confirmed.value);
        return allMailTypeType;
      }
    },
    mark: {
      get() {
        let markList = [];
        if (_.get(this.searchCondItem.mark, "mark1.active") === true)
          markList.push(this.searchCondItem.mark.mark1.value);
        if (_.get(this.searchCondItem.mark, "mark2.active") === true)
          markList.push(this.searchCondItem.mark.mark2.value);
        if (_.get(this.searchCondItem.mark, "mark3.active") === true)
          markList.push(this.searchCondItem.mark.mark3.value);
        if (_.get(this.searchCondItem.mark, "mark4.active") === true)
          markList.push(this.searchCondItem.mark.mark4.value);
        return markList;
      }
    }
  },
  methods: {
    addSearchCond(data) {
      data.specifiedTime = this.searchCondItem.specifiedTime;
      data.vehicleType = this.vehicleType;
      data.vehicleCarryWeight = this.vehicleCarryWeight;
      data.vehicleOption = this.vehicleOption;
      data.drivableVehicleType = this.drivableVehicleType;
      data.driverShift = this.driverShift;
      data.isFavorite = this.searchCondItem.isFavorite;
      data.isFavoriteOn = this.searchCondItem.isFavoriteOn;
      data.isFavoriteOff = this.searchCondItem.isFavoriteOff;
      data.loadingBaseName = this.searchCondItem.loadingBaseName;
      data.unloadingBaseName = this.searchCondItem.unloadingBaseName;
      data.operatingConditions = this.operatingConditions;
      data.liftType = this.liftType;
      data.contractType = this.contractType;
      data.firstMailType = this.firstMailType;
      data.allMailType = this.allMailType;
      data.isEmptyCard = this.searchCondItem.isEmptyCard;
      data.driverName = this.searchCondItem.driverName;
      data.deliveryCompanyName = this.searchCondItem.deliveryCompanyName;
      data.primaryContractorName = this.searchCondItem.primaryContractorName;
      data.vehicleNumber = this.searchCondItem.vehicleNumber;
      data.shipmentNo = this.searchCondItem.shipmentNo;
      data.dispatchDetailIds = this.searchCondItem.dispatchDetailIds;
      data.mark = this.mark;
      data.isComplete = this.searchCondItem.isComplete;
      data.onlyGps = this.searchCondItem.onlyGps;
      data.clientId = this.searchCondItem.clientId;
      data.clientBranchId = this.searchCondItem.clientBranchId;
      data.vehicleGarageArea = this.searchCondItem.vehicleGarageArea;
      return data;
    },
    clearSearchCond(data) {
      this.searchCondItem = _.cloneDeep(this.searchCondItemDef);
      this.$refs.DispatchClientFilter.clearSelected();
      this.$refs.DispatchAreaFilter.clearSelected();
      return this.addSearchCond(data);
    },
    setDefaultSelect() {
      if (!this.isDynamic) {
        this.searchCondItem.driverShift.day.active = true;
        this.searchCondItem.driverShift.night.active = true;
      }
    },
    trigger() {
      this.$emit("onChangeScheduleConditions", this.searchCondItem);
    },
    loadingDown() {
      this.loading = false;
    },
    setApiData() {
      this.searchCondItemDef.vehicleType = this.vehicleTypeList;
      this.searchCondItemDef.drivableVehicleType = this.drivableVehicleTypeList;
      this.searchCondItemDef.vehicleOption = this.vehicleOptionList;
    }
  },
  watch: {
    searchCondItem: {
      handler: function (newValue) {
        this.debouncedTrigger(newValue);
      },
      deep: true
    }
  },
  created() {
    this.debouncedTrigger = _.debounce(this.trigger, 500);
    this.setApiData();
    this.searchCondItem = _.cloneDeep(this.searchCondItemDef);
    this.setDefaultSelect();
  },
  mounted() {
    window.addEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.loadingDown,
      false
    );
  },
  destroyed() {
    window.removeEventListener(
      window.Common.Config.observer.onPagingListTableAjaxCompleted,
      this.loadingDown
    );
  },
  props: {
    clientList: {
      type: Array,
      required: true
    },
    areaList: {
      type: Array,
      required: true
    },
    isDynamic: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
};
</script>

<style lang="stylus" scoped>
.dispatch_filter_card {
  max-height: 650px;
  min-width: 800px;
  overflow-y: scroll;
  padding-top: 0;
}

.item-name {
  padding-left: 8px;
}
</style>
