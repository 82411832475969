<template lang="pug">
  SearchConditions(ref="SearchConditions" :initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="積地" v-model="conditions.loadingPlace")
        v-flex(md2)
          v-text-field(label="卸地" v-model="conditions.unloadingPlace")
        v-flex(md2)
          v-text-field(label="請求区分" v-model="conditions.billingTypeName")
        v-flex(md2)
          DistanceTypeSelect(:code.sync="conditions.distanceType" :clearable="true")
        v-flex(md2)
          DeleteTypeSelect(:code.sync="conditions.deleteType" :clearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="削除日" initDate="" :inputDate.sync="conditions.orderDate" :isClearable="true")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="PB No" v-model="conditions.planBoardNo")
        v-flex(md2)
          v-text-field(label="ルートNo" v-model="conditions.routeNo")
        v-flex(md2)
          v-text-field(label="伝票番号" v-model="conditions.slipNo")
        v-flex(md2)
          DatePicker(:pickerLabel="labelName.LoadingSchedule" :initDate="initLoadingDate" :inputDate.sync="conditions.loadingDate")
        v-flex(md2)
          DatePicker(:pickerLabel="labelName.UnloadingSchedule" initDate="" :inputDate.sync="conditions.unloadingDate" :isClearable="true")

</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import DistanceTypeSelect from "@/components/common/select/DistanceTypeSelect";
import DeleteTypeSelect from "@/components/common/select/DeleteTypeSelect";
import { LabelName } from "@/assets/js/constants";

export default {
  components: {
    SearchConditions,
    DatePicker,
    DistanceTypeSelect,
    DeleteTypeSelect
  },
  data() {
    return { labelName: LabelName };
  },
  computed: {
    initLoadingDate: {
      get() {
        return window.Common.Util.getTomorrowYearMonthDayIsoFormat();
      }
    }
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          loadingDate: this.initLoadingDate,
          loadingPlace: "",
          unloadingDate: "",
          unloadingPlace: "",
          orderDate: "",
          billingTypeName: "",
          distanceType: "",
          deleteType: "",
          planBoardNo: "",
          slipNo: "",
          routeNo: ""
        }
      };
    }
  },
  mounted() {
    this.$refs.SearchConditions.submit();
  }
};
</script>
