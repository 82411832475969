import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

import _ from "lodash";

import qs from "qs";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  vehicleList: [],
  maintenanceDataList: [],
  vehicleTypeList: [],
  vehicleCarryWeightList: [],
  vehicleGarageList: []
};

const getters = {
  getVehicleTypeListForOperationPossibleResource: state => {
    return state.vehicleTypeList.filter(vehicleType => vehicleType.displayResourceTarget);
  },
  getVehicleCarryWeightListByVehicleTypeId: state => VehicleTypeId => {
    if (VehicleTypeId) {
      return state.vehicleCarryWeightList.filter(
        vehicleCarryWeight => vehicleCarryWeight.vehicleTypeId === VehicleTypeId
      );
    }
    return state.vehicleCarryWeightList;
  },
  useChassisFromVehicleTypeId: state => vehicleTypeId => {
    if (state.vehicleTypeList == null) {
      return "";
    } else {
      const data = state.vehicleTypeList.find(vehicleType => vehicleType.id == vehicleTypeId);
      return data == null ? "不明" : data.useChassis;
    }
  }
};

const actions = {
  // 車両一覧を検索
  async fetchAllVehicle({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllVehicleList, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllVehicleData");
        }
        commit("appendAllVehicleData", value.data.data);
      });
  },
  // 利用可能な車両一覧を取得
  async fetchAllUsableVehicle({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getAllUsableVehicleList, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 利用可能な車両一覧を取得(配車用)
  async fetchAllUsableVehicleForDispatch({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getAllUsableVehicleListForDispatch, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 選択された車両の情報を取得
  async fetchVehicle({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllVehicle + id).then(value => {
      return value.data.data;
    });
  },
  // 車格を検索
  async fetchAllVehicleType({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllVehicleTypeList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllVehicleTypeData");
        }
        commit("appendAllVehicleTypeData", value.data.data);
      });
  },
  // 選択された車格の情報を取得
  async fetchVehicleType({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllVehicleType + id).then(value => {
      return value.data.data;
    });
  },
  // 車両積載重量を検索
  async fetchAllVehicleCarryWeight({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllVehicleCarryWeightList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllVehicleCarryWeightData");
        }
        commit("appendAllVehicleCarryWeightData", value.data.data);
        let recordData = [];
        if (value.data.data != null) {
          recordData = _.cloneDeep(value.data.data);
          for (let i = 0; i < recordData.length; i++) {
            recordData[i]["carryWeightDisplay"] = recordData[i]["carryWeight"].toLocaleString() + "kg";
            recordData[i]["maxCarryWeightDisplay"] = recordData[i]["maxCarryWeight"].toLocaleString() + "kg";
          }
        }
        return Promise.resolve(recordData);
      });
  },
  //車両マスタメンテ画面のデフォルト社格積載重量選択用
  async fetchDefaultVehicleCarryWeight({ state }, params) {
    return $axios
      .get(axiosBase.getUrls().getAllVehicleCarryWeightList, {
        params: params
      })
      .then(value => {
        let ret = null;
        if (value.data.data != null) {
          ret = value.data.data[0];
        }
        return Promise.resolve(ret);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  // 選択された積載重量の情報を取得
  async fetchVehicleCarryWeight({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllVehicleCarryWeight + id).then(value => {
      return value.data.data;
    });
  },
  // 車庫一覧を検索
  async fetchAllVehicleGarage({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllVehicleGarage, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllVehicleGarageData");
        }
        commit("appendAllVehicleGarageData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  // 選択された車庫の情報を取得
  async fetchVehicleGarage({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getVehicleGarage + id).then(value => {
      return value.data.data;
    });
  },
  saveMaintenance({ commit, state }, data) {
    commit("addMaintenance", data);
  },
  /**
   * 実績出力
   */
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportVehicle, {
      params: getParams,
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
  }
};

const mutations = {
  clearAllVehicleData(state) {
    state.vehicleList = [];
  },
  appendAllVehicleData(state, data) {
    if (data != null) {
      state.vehicleList = state.vehicleList.concat(data);
    }
  },
  clearAllVehicleTypeData(state) {
    state.vehicleTypeList = [];
  },
  appendAllVehicleTypeData(state, data) {
    if (data != null) {
      state.vehicleTypeList = state.vehicleTypeList.concat(data);
    }
  },
  clearAllVehicleCarryWeightData(state) {
    state.vehicleCarryWeightList = [];
  },
  appendAllVehicleCarryWeightData(state, data) {
    if (data != null) {
      state.vehicleCarryWeightList = state.vehicleCarryWeightList.concat(data);
    }
  },
  clearAllVehicleGarageData(state) {
    state.vehicleGarageList = [];
  },
  appendAllVehicleGarageData(state, data) {
    if (data != null) {
      state.vehicleGarageList = state.vehicleGarageList.concat(data);
    }
  },
  addMaintenance(state, data) {
    state.maintenanceDataList.push(data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
