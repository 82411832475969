<template lang="pug">
  EditDialog(ref="EditDialog" title="出力情報" width="40%" :recordId="detail.id" :loadingSave="loading.save" @save="onSave" :displayDeleteButton="false")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          template
            v-flex(xs12 md6)
              DatePicker(pickerLabel="送信期限" :initDate="detail.faxSendPeriod" :inputDate.sync="detail.faxSendPeriod" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'faxSendPeriod'")
            v-flex(xs12 md6)
              DatePicker(pickerLabel="必着期限" :initDate="detail.arrivePeriod" :inputDate.sync="detail.arrivePeriod" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'arrivePeriod'")
</template>

<script>
import { mapActions } from "vuex";
import ApiHandler from "@/mixin/ApiHandler";
import EventDispatcher from "@/mixin/EventDispatcher";
import EditDialog from "@/components/common/dialog/EditDialog";
import DatePicker from "@/components/common/DatePicker";

export default {
  props: {
    // 登録時のStore Actions reportメソッド名
    actionName: {
      type: String,
      requird: true,
      default: () => ""
    }
  },
  components: {
    EditDialog,
    DatePicker
  },
  mixins: [ApiHandler, EventDispatcher],
  data: () => ({
    // 更新情報
    detail: {}
  }),
  methods: {
    /** Action：Vuex */
    ...mapActions({
      postReportEditMeta(dispatch, payload) {
        return dispatch("statement/" + this.actionName, payload);
      }
    }),
    /**
     * Event：ダイアログを開く
     * ※親→子（このコンポーネント）→孫（slot利用）すべて参照利用。
     */
    openDialog(detail) {
      // mixin:ApiHandlerに情報を設定
      this.errors = {};
      this.detail = this.initData().detail;
      // 選択された行情報を取得/設定
      this.detail = detail;
      this.$refs.EditDialog.openDialog();
    },
    /**
     * 初期データ
     */
    initData() {
      return {
        detail: {
          faxSendPeriod: null,
          arrivePeriod: null
        }
      };
    },
    /**
     * 保存：署名・定型文・手書きメモ
     */
    async onSave() {
      this.loading.save = true;
      const refsDialog = this.$refs.EditDialog;
      // mixin:ApiHandler.saveRecordを利用するとcommit処理がキャンセルされるため利用しない。
      await this.postReportEditMeta(this.detail)
        .then(() => {
          this.errors = {};
          refsDialog.closeDialog();
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            // mixin:ApiHandlerに情報を設定
            this.setErrorMessages(error);
          } else {
            // mixin:EventDispatcherに情報を設定
            this.dispatchCommonEventWithParam(window.Common.Config.observer.onErrorResponse400, {
              message: error.response.data.message
            });
          }
        })
        .finally(() => {
          this.loading.save = false;
        });
    }
  },
  beforeMount() {
    this.detail = this.initData().detail;
  }
};
</script>
