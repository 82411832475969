<template lang="pug">
  EditDialog(ref="EditDialog" title="配車ライン" @save="save"
    :loadingSave="control.loadingSave" :disabledSave="isDisableSaveButton")
    template(v-slot:body)
      v-layout
        DispatchDriverDirectSearch(ref="DispatchDriverDirectSearch"
          :label.sync="record.directSelectDriver.name"
          :code.sync="record.directSelectDriver.id"
          :errorHandlerMethod="getErrorMessages"
          @selectItem="selectDirectSelectDriver"
        )
      v-container
        v-layout(row wrap).delivery-company-box
          //v-flex(xs12)
          v-flex(xs12)
            v-card
              v-card-text
                v-container(grid-list-md).specified-info
                  // 1次請け
                  v-layout(row wrap align-center)
                    v-flex(xs8)
                      DispatchDeliveryCompanySelect(titleLabel="1次請け委託先（必須）"
                        ref="PrimaryDeliveryCompanySelect"
                        :label.sync="record.primaryDeliveryCompany.name"
                        :code.sync="record.primaryDeliveryCompany.id"
                        :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="primaryDeliveryCompanyId"
                        @selectItem="selectPrimaryDeliveryCompany"
                        @clear="clearPrimaryDeliveryCompany"
                        @openAddDialog="openPrimaryDeliveryCompanyEdit")
                    v-flex(xs2)
                      span.hit-count()
                        span.label {{ control.cnt.primaryDeliveryCompany }}
                    v-flex(xs2)
                      v-btn(color="primary" @click="openPrimaryDeliveryCompanyEdit") 新規追加

                  // 2次請け
                  v-layout(row wrap align-center v-show="isDisplaySecondaryDeliveryCompanyField")
                    v-flex(xs12)
                      v-checkbox(v-model="control.filterPrimaryDeliveryCompany"
                        label="1次請けで対象を絞る" hide-details @clear="clearSecondaryDeliveryCompany")
                    v-flex(xs8)
                      DispatchDeliveryCompanySelect(titleLabel="2次請け委託先（オプション）"
                        ref="SecondaryDeliveryCompanySelect"
                        :filterPrimaryMode="control.filterPrimaryDeliveryCompany"
                        :label.sync="record.secondaryDeliveryCompany.name"
                        :code.sync="record.secondaryDeliveryCompany.id"
                        :readonly="isSecondaryDeliveryCompanySelectReadOnly"
                        :primaryDeliveryCompanyId="primaryDeliveryCompanyIdForSecondary"
                        :excludeCompanyIdList="excludeSecondaryDeliveryCompanyIDs"
                        :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="secondaryDeliveryCompanyId"
                        @selectItem="selectSecondaryDeliveryCompany"
                        @clear="clearSecondaryDeliveryCompany"
                        @openAddDialog="openSecondaryDeliveryCompanyEdit")
                    v-flex(xs2)
                      span.hit-count(:class="{zero:control.cnt.secondaryDeliveryCompany<1}")
                        span.label {{ control.cnt.secondaryDeliveryCompany }}
                    v-flex(xs2)
                      v-btn(color="primary" @click="openSecondaryDeliveryCompanyEdit"
                        :disabled="isPrimaryDeliveryCompanyIsTrasco") 新規追加

        //車両以下
        v-container(grid-list-md).specified-info
          v-layout(row wrap)
            v-flex(xs12)
              v-alert(:value="isDisplayVehicleField" type="info" ).company-name-label {{ selectedDeliveryCompanyName }}
            v-flex(xs12).rel-company-box
              // 車両
              v-layout(row wrap align-center v-show="isDisplayVehicleField")
                v-flex(xs8)
                  DispatchVehicleSelect(titleLabel="利用車両（必須）"
                    ref="DispatchVehicleSelect"
                    :label.sync="record.vehicle.name"
                    :code.sync="record.vehicle.id"
                    :errorHandlerMethod="getErrorMessages"
                    :companyId="selectedDeliveryCompanyId"
                    @selectItem="selectRegularUseVehicle"
                    @clear="clearVehicle"
                    @openAddDialog="openVehicleEdit")
                v-flex(xs2)
                  span.hit-count(:class="{zero:control.cnt.vehicle<1}")
                    span.label {{ control.cnt.vehicle }}
                v-flex(xs2)
                  v-btn(color="primary" @click="openVehicleEdit") 新規追加

              // 乗務員
              v-layout(row wrap align-center v-show="isDisplayDriverField")
                v-flex(xs8)
                  DispatchDriverSelect(titleLabel="乗務員（必須）"
                    ref="DispatchDriverSelect"
                    :label.sync="record.driver.name"
                    :code.sync="record.driver.id"
                    :errorHandlerMethod="getErrorMessages"
                    :companyId="selectedDeliveryCompanyId"
                    :primaryDeliveryCompanyId="primaryDeliveryCompanyId"
                    :vehicleTypeId="record.vehicle.typeId"
                    @selectItem="selectDriver" @clear="clearDriver"
                    @openAddDialog="openDriverEdit")
                v-flex(xs2)
                  span.hit-count(:class="{zero:control.cnt.driver<1}")
                    span.label {{ control.cnt.driver }}
                v-flex(xs2)
                  v-btn(color="primary" @click="openDriverEdit") 新規追加


              v-layout(row wrap v-if="isDisplaySchedule")
                v-flex(xs10)
                  table.shift-table
                    thead
                      tr
                        th.shift-header-label
                        th.shift-header-label(v-for="header in shiftDayList" :class="{holiday:header.holiday,scheduleLoadingDate:isLoadingDate(header.date)}")
                          span {{ header.text }}
                    tbody
                      tr
                        td.shift-header-label
                          span 編集内容
                        td.shift-edit(v-for="item in record.shiftData" :class="{holiday:item.holiday,scheduleLoadingDate:isLoadingDate(item.date)}"
                          @click="openShiftContextMenu($event,item)").shit-icon-navi
                          v-icon(:color="getShiftType(item.shiftType).color",
                            @click="openShiftContextMenu($event,item)") {{ getShiftType(item.shiftType).icon }}

                      tr.shift-template
                        td.shift-header-label
                          span 現在の設定
                        td.shift-ref(v-for="item in record.shiftRefData" :class="{holiday:item.holiday,scheduleLoadingDate:isLoadingDate(item.targetDate)}")
                          v-icon(:color="getShiftType(item.shiftType,'grey lighten-1').lightColor")  {{ getShiftType(item.shiftType).icon }}

      DriverShiftPicker(ref="DriverShiftPicker" @selectShiftType="shiftSelectCallBack")
      DeliveryCompanyEdit(ref="PrimaryDeliveryCompanyEdit"
        :postUrl="$config.urls.postEditDeliveryCompanyForDispatch"
        @updated="primaryDeliveryCompanyRegisteredCallBack")
      DeliveryCompanyEdit(ref="SecondaryDeliveryCompanyEdit"
        :postUrl="$config.urls.postEditDeliveryCompanyForDispatch"
        @updated="secondaryDeliveryCompanyRegisteredCallBack")
      VehicleEdit(ref="VehicleEdit" :postUrl="$config.urls.postEditVehicleForDispatch"
        :entryDataCallBack="entryVehicleDataCallback"
        :dispatchRegisterMode="true"
        @updated="vehicleRegisteredCallBack")
      DriverEdit(ref="DriverEdit" :postUrl="$config.urls.postEditDriverForDispatch"
        :entryDataCallBack="entryDriverDataCallback"
        :dispatchRegisterMode="true"
        @updated="driverRegisteredCallBack")


</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import DispatchDeliveryCompanySelect from "@/components/dispatch/DispatchDeliveryCompanySelect";
import DispatchVehicleSelect from "@/components/dispatch/DispatchVehicleSelect";
import DispatchDriverSelect from "@/components/dispatch/DispatchDriverSelect";
import DispatchDriverDirectSearch from "@/components/dispatch/DispatchDriverDirectSearch";
import DeliveryCompanyEdit from "@/components/management/delivery-company/DeliveryCompanyEdit";
import VehicleEdit from "@/components/management/vehicle/VehicleEdit";
import DriverEdit from "@/components/management/driver/DriverEdit";

import DriverShiftPicker from "@/components/common/DriverShiftPicker";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import CalendarUtils from "@/mixin/CalendarUtils";
import ShiftTypeMenu from "@/mixin/ShiftTypeMenu";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    DispatchDeliveryCompanySelect,
    DispatchVehicleSelect,
    DispatchDriverSelect,
    DriverShiftPicker,
    DeliveryCompanyEdit,
    VehicleEdit,
    DriverEdit,
    DispatchDriverDirectSearch
  },
  mixins: [ApiHandler, EditForm, CalendarUtils, ShiftTypeMenu],
  data() {
    return {
      control: {},
      record: {}
    };
  },
  props: {
    afterSavedCallBack: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    /**
     * @return Array
     * */
    shiftDayList: {
      get() {
        if (this.control.startDate == null) return [];
        return this.calcShiftDay(window.Common.Util.getYearMonthDayIsoFormat(this.control.startDate));
      }
    },
    primaryDeliveryCompanyId: {
      get() {
        if (this.record.primaryDeliveryCompany.id != null) {
          return this.record.primaryDeliveryCompany.id;
        } else {
          return null;
        }
      }
    },
    primaryDeliveryCompanyIdForSecondary() {
      if (this.primaryDeliveryCompanyId == null || !this.control.filterPrimaryDeliveryCompany) {
        return null;
      } else {
        return this.primaryDeliveryCompanyId;
      }
    },
    selectedDeliveryCompanyId() {
      if (this.record.secondaryDeliveryCompany.id) {
        return this.record.secondaryDeliveryCompany.id;
      } else if (this.record.primaryDeliveryCompany.id) {
        return this.record.primaryDeliveryCompany.id;
      } else {
        return null;
      }
    },
    selectedDeliveryCompanyName() {
      if (this.record.secondaryDeliveryCompany.name) {
        return this.record.secondaryDeliveryCompany.name;
      } else if (this.record.primaryDeliveryCompany.name) {
        return this.record.primaryDeliveryCompany.name;
      } else {
        return null;
      }
    },
    isDisplaySecondaryDeliveryCompanyField() {
      return this.record.primaryDeliveryCompany.id;
    },
    isDisplayVehicleField() {
      return this.primaryDeliveryCompanyId;
    },
    isDisplayDriverField() {
      return this.record.vehicle.id;
    },
    isDisplaySchedule() {
      return this.record.driver.id;
    },
    isDisableSaveButton() {
      return !this.isDisplaySchedule;
    },
    isPrimaryDeliveryCompanyIsTrasco() {
      return this.selectedDeliveryCompanyId === this.$store.state.site.global.TRASCOCompanyId;
    },
    excludeSecondaryDeliveryCompanyIDs() {
      return [this.primaryDeliveryCompanyId, this.$store.state.site.global.TRASCOCompanyId];
    },
    isSecondaryDeliveryCompanySelectReadOnly() {
      return this.isPrimaryDeliveryCompanyIsTrasco;
    }
  },
  methods: {
    initShiftData() {
      //当日は出勤。
      return this.shiftDayList.map((value, index) => {
        const currentDateString = window.Common.Util.getYearMonthDayIsoFormat(new Date(value.date));
        const loadingScheduleDateString = window.Common.Util.getYearMonthDayIsoFormat(
          this.control.scheduledLoadingDate
        );
        return {
          shiftType: currentDateString === loadingScheduleDateString ? Enum.ShiftType.Day.code : null,
          date: value.date,
          holiday: value.holiday
        };
      });
    },
    /**
     * 自身の開く処理
     */
    openDialog(scheduledLoadingDate) {
      this.control = this.initControl();
      this.record = this.initData();

      this.control.scheduledLoadingDate = new Date(scheduledLoadingDate);
      //配車積日を基準に前後3日を表示する設定を行う
      this.control.startDate = new Date(
        new Date(scheduledLoadingDate).setDate(this.control.scheduledLoadingDate.getDate() - 3)
      );
      this.control.endDate = new Date(
        new Date(scheduledLoadingDate).setDate(this.control.scheduledLoadingDate.getDate() + 3)
      );
      //初期値設定
      this.clearDriverSchedule();
      this.$refs.EditDialog.openDialog();

      //ダイアログ開いたときに、1次請け候補(全運送会社)の件数取得
      this.fetchPrimaryDeliveryCompanyCount();
    },
    /**
     * 自身を閉じる
     */
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    selectDirectSelectDriver(data) {
      this.clearPrimaryDeliveryCompany();
      this.$nextTick(() => {
        this.fetchSecondaryDeliveryCompanyCount();
      });
      //一次請けが存在するか
      if (data.firstStructuredCompanyId !== data.deliveryCompanyId) {
        this.record.primaryDeliveryCompany.id = data.firstStructuredCompanyId;
        this.record.primaryDeliveryCompany.name = data.firstStructuredCompanyName;
        this.record.secondaryDeliveryCompany.id = data.deliveryCompanyId;
        this.record.secondaryDeliveryCompany.name = data.deliveryCompanyName;
      } else {
        this.record.primaryDeliveryCompany.id = data.deliveryCompanyId;
        this.record.primaryDeliveryCompany.name = data.deliveryCompanyName;
      }

      //基本車両
      if (data.regularUseVehicleId != null) {
        this.record.vehicle.id = data.regularUseVehicleId;
        this.record.vehicle.name = data.regularUseVehicleFullNumberDisp;
        this.record.vehicle.typeId = data.regularUseVehicle.vehicleTypeId;
      }

      //乗務員
      if (data.id != null) {
        this.record.driver.id = data.id;
        this.record.driver.name = data.name;
        this.fetchSchedule();
      }
    },
    /**
     * 1次委託先会社変更時の処理
     */
    selectPrimaryDeliveryCompany() {
      this.clearSecondaryDeliveryCompany();
      //DOM更新後、2次請け候補(全運送会社)、車両、乗務員候補の件数取得
      this.$nextTick(() => {
        this.fetchSecondaryDeliveryCompanyCount();
        this.fetchVehicleCount();
        this.fetchDriverCount();
      });
    },
    /**
     * 2次委託先会社変更時の処理
     */
    selectSecondaryDeliveryCompany() {
      this.clearVehicle();
      //DOM更新後、車両、乗務員候補の件数取得
      this.$nextTick(() => {
        this.fetchVehicleCount();
        this.fetchDriverCount();
      });
    },
    /**
     * 基本車両変更時の処理
     */
    selectRegularUseVehicle(data) {
      this.record.vehicle.typeId = data.vehicleTypeId;
      this.clearDriver();
      this.$nextTick(() => {
        this.fetchDriverCount();
      });
    },
    /**
     * 乗務員変更時の処理
     */
    selectDriver() {
      this.clearDriverSchedule();
      this.$nextTick(() => {
        this.fetchSchedule();
      });
    },
    /**
     * シフト選択のコンテキストメニューの表示
     */
    openShiftContextMenu(event, shift) {
      this.control.currentSelectShift = shift;
      this.$refs.DriverShiftPicker.open(event);
    },
    /**
     * シフト選択コンテキストメニューの選択後のCB
     */
    shiftSelectCallBack(selectItem) {
      this.control.currentSelectShift.shiftType = selectItem.code;
    },
    /**
     * 1次請け委託先新規追加ダイアログを開く
     */
    openPrimaryDeliveryCompanyEdit() {
      this.$refs.PrimaryDeliveryCompanyEdit.openDialog();
    },
    /**
     * 2次請け委託先新規追加ダイアログを開く
     */
    openSecondaryDeliveryCompanyEdit() {
      this.$refs.SecondaryDeliveryCompanyEdit.openDialog();
    },
    /**
     * 車両新規追加ダイアログを開く
     */
    openVehicleEdit() {
      this.$refs.VehicleEdit.openDialog();
    },
    /**
     * 乗務員新規追加ダイアログを開く
     */
    openDriverEdit() {
      this.$refs.DriverEdit.openDialog();
    },
    /**
     * 1次請け委託先保存完了後のCallBack
     */
    primaryDeliveryCompanyRegisteredCallBack(response) {
      if (response.data.data !== null) {
        this.record.primaryDeliveryCompany.id = response.data.data.id;
        this.record.primaryDeliveryCompany.name = response.data.data.companyName;
        this.clearSecondaryDeliveryCompany();
        this.$nextTick(() => {
          this.fetchPrimaryDeliveryCompanyCount();
          this.fetchSecondaryDeliveryCompanyCount();
          this.fetchVehicleCount();
          this.fetchDriverCount();
        });
        this.$refs.PrimaryDeliveryCompanySelect.closeDialog();
      }
    },
    /**
     * 2次請け委託先保存完了後のCallBack
     */
    secondaryDeliveryCompanyRegisteredCallBack(response) {
      if (response.data.data !== null) {
        this.record.secondaryDeliveryCompany.id = response.data.data.id;
        this.record.secondaryDeliveryCompany.name = response.data.data.companyName;
        this.clearVehicle();
        this.$nextTick(() => {
          this.fetchPrimaryDeliveryCompanyCount();
          this.fetchSecondaryDeliveryCompanyCount();
          this.fetchVehicleCount();
          this.fetchDriverCount();
        });
        this.$refs.SecondaryDeliveryCompanySelect.closeDialog();
      }
    },
    /**
     * 車両保存完了後のCallBack
     */
    vehicleRegisteredCallBack(response) {
      if (response.data.data !== null) {
        this.record.vehicle.id = response.data.data.id;
        this.record.vehicle.name = response.data.data.vehicleNumber;
        this.record.vehicle.typeId = response.data.data.vehicleTypeId;
        this.clearDriver();
        this.$nextTick(() => {
          this.fetchVehicleCount();
          this.fetchDriverCount();
        });
      }
      this.$refs.DispatchVehicleSelect.closeDialog();
    },
    /**
     * 乗務員保存完了後のCallBack
     */
    driverRegisteredCallBack(response) {
      if (response.data.data !== null) {
        this.record.driver.id = response.data.data.id;
        this.record.driver.name = response.data.data.name;
        this.clearDriverSchedule();
        this.$nextTick(() => {
          this.fetchSchedule();
          this.fetchDriverCount();
        });
      }
      this.$refs.DispatchDriverSelect.closeDialog();
    },
    clearPrimaryDeliveryCompany() {
      this.record.primaryDeliveryCompany.id = null;
      this.record.primaryDeliveryCompany.name = null;
      this.clearSecondaryDeliveryCompany();
    },
    clearSecondaryDeliveryCompany() {
      this.record.secondaryDeliveryCompany.id = null;
      this.record.secondaryDeliveryCompany.name = null;
      this.clearVehicle();
      this.$nextTick(() => {
        if (this.primaryDeliveryCompanyId != null) {
          this.fetchVehicleCount();
          this.fetchDriverCount();
        }
      });
    },
    clearVehicle() {
      this.record.vehicle.id = null;
      this.record.vehicle.name = null;
      this.record.vehicle.typeId = null;
      this.clearDriver();
    },
    clearDriver() {
      this.record.driver.id = null;
      this.record.driver.name = null;
      this.clearDriverSchedule();
    },
    clearDriverSchedule() {
      this.record.shiftRefData = [];
      this.record.shiftData = this.initShiftData();
    },
    /**
     * 車両新規追加ダイアログ開いたときの初期データ構築用CallBack
     */
    entryVehicleDataCallback(data) {
      data.deliveryCompanyId = this.selectedDeliveryCompanyId;
      data.deliveryCompanyName = this.selectedDeliveryCompanyName;
    },
    /**
     * 乗務員新規追加ダイアログ開いたときの初期データ構築用CallBack
     */
    entryDriverDataCallback(data) {
      data.regularUseVehicleId = this.record.vehicle.id;
      data.regularUseVehicleFullNumberDisp = this.record.vehicle.name;

      //車両がトレーラなら運転可能車格に増トンを加える
      if (this.record.vehicle.typeId === Enum.PrimaryVehicleType.Trailer.id) {
        data.drivableVehicleTypeIdList = [
          Enum.PrimaryVehicleType.IncreaseTon.id,
          Enum.PrimaryVehicleType.Trailer.id
        ];
      } else {
        data.drivableVehicleTypeIdList = [this.record.vehicle.typeId];
      }

      if (this.isPrimaryDeliveryCompanyIsTrasco) {
        data.contractType = Enum.ContractType.Employee.code;
      }

      if (this.record.secondaryDeliveryCompany.id) {
        data.deliveryCompanyStructureList.push({
          id: null,
          deliveryCompanyId: this.record.primaryDeliveryCompany.id,
          deliveryCompanyName: this.record.primaryDeliveryCompany.name,
          structureSeq: 1
        });
        data.deliveryCompanyId = this.record.secondaryDeliveryCompany.id;
        data.deliveryCompanyName = this.record.secondaryDeliveryCompany.name;
      } else {
        data.deliveryCompanyId = this.record.primaryDeliveryCompany.id;
        data.deliveryCompanyName = this.record.primaryDeliveryCompany.name;
      }
    },
    fetchSchedule() {
      const self = this;
      this.$store
        .dispatch("driver/fetchOneDriverSchedule", {
          driverId: this.record.driver.id,
          startDate: window.Common.Util.getYearMonthDayIsoFormat(this.control.startDate),
          endDate: window.Common.Util.getYearMonthDayIsoFormat(this.control.endDate)
        })
        .then(list => {
          self.record.shiftRefData = list;
          self.record.shiftData.forEach((schedule, index) => {
            const ret = list.find(element => element.targetDate === schedule.date);
            if (ret === undefined) {
              schedule.shiftType = null;
            } else {
              //DBにシフトの保存がない場合、当日分は日勤として初期値いれておく
              if (ret.shiftType != null) {
                schedule.shiftType = ret.shiftType;
              }
              schedule.id = ret.id;
              schedule.updatedAt = ret.updatedAt;
            }
          });
        });
    },
    save() {
      const self = this;
      this.record.scheduledLoadingDate = window.Common.Util.getYearMonthDayIsoFormat(
        this.control.scheduledLoadingDate
      );
      this.saveRecord(this.record).then(value => {
        self.closeDialog();
        this.afterSavedCallBack();
      });
    },
    initControl: () => {
      return {
        scheduledLoadingDate: null,
        startDate: null,
        endDate: null,
        currentSelectShift: null,
        filterPrimaryDeliveryCompany: false,
        loadingSave: false,
        cnt: {
          primaryDeliveryCompany: 0,
          secondaryDeliveryCompany: 0,
          vehicle: 0,
          driver: 0
        }
      };
    },
    initData: () => {
      return {
        shiftData: [],
        shiftRefData: [],
        primaryDeliveryCompany: {
          id: null,
          name: null
        },
        secondaryDeliveryCompany: {
          id: null,
          name: null
        },
        vehicle: {
          id: null,
          name: null,
          typeId: null
        },
        driver: {
          id: null,
          name: null
        },
        directSelectDriver: {
          id: null,
          name: null
        }
      };
    },
    fetchPrimaryDeliveryCompanyCount() {
      this.$refs.PrimaryDeliveryCompanySelect.findResult().then(value => {
        this.control.cnt.primaryDeliveryCompany = value.length;
      });
    },
    fetchSecondaryDeliveryCompanyCount() {
      this.$refs.SecondaryDeliveryCompanySelect.findResult().then(value => {
        this.control.cnt.secondaryDeliveryCompany = this.isPrimaryDeliveryCompanyIsTrasco ? 0 : value.length;
      });
    },
    fetchVehicleCount() {
      this.$refs.DispatchVehicleSelect.findResult().then(value => {
        this.control.cnt.vehicle = value.length;
      });
    },
    fetchDriverCount() {
      this.$refs.DispatchDriverSelect.findResult().then(value => {
        this.control.cnt.driver = value.length;
      });
    },
    isLoadingDate(date) {
      const currentDate = window.Common.Util.getYearMonthDayIsoFormat(
        new Date(this.control.scheduledLoadingDate)
      );
      return currentDate === date;
    }
  },
  watch: {
    "control.filterPrimaryDeliveryCompany": function (val) {
      this.$nextTick(() => {
        this.fetchSecondaryDeliveryCompanyCount();
      });
    }
  },
  created() {
    this.control = this.initControl();
    this.record = this.initData();
  }
};
</script>

<style scoped lang="stylus">
.shift-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-left 24px

  border 1px solid #9E9E9E

  CURRENT_COLOR = #8BC34A;
  CURRENT_BG_COLOR = rgba(139,195,74,0.2);

  tr.shift-template {
    background-color #CFD8DC

    td.holiday {
      background-color: #CFD8DC
    }
  }

  td.shift-header-label {
    text-align left
  }

  td.shift-edit{
    &.scheduleLoadingDate{
      border 2px solid CURRENT_COLOR
      border-top 1px solid #9E9E9E
      border-bottom 1px solid #9E9E9E

      background-color CURRENT_BG_COLOR
    }
  }

  td.shift-ref{
    &.scheduleLoadingDate{
      border 2px solid CURRENT_COLOR
      border-top 1px solid #9E9E9E
      background-color CURRENT_BG_COLOR
    }
  }

  th.shift-header-label {
    padding 8px
    font-weight normal
    border-right 1px solid #9E9E9E

    span{
      padding 4px
    }

    &.scheduleLoadingDate{
      span{
        background-color CURRENT_COLOR
        color whitesmoke
        border-radius 5%
      }

      border 2px solid CURRENT_COLOR
      border-bottom 1px solid #9E9E9E
      background-color CURRENT_BG_COLOR
    }

    &:last-child {
      border-right none
    }
  }

  th.holiday, td.holiday {
    background-color: #ffebee
  }

  td {
    white-space nowrap
    text-align center
    padding 8px
    border-top 1px solid #9E9E9E
    border-right 1px solid #9E9E9E

    &:last-child {
      border-right none
    }

    &.shit-icon-navi {
      position relative

      &:after {
        display inline-block
        font-family: "Material Icons"
        content: "\e5c5"
        position absolute
      }
    }
  }

  th.scheduleLoadingDate{
    span {

    }
  }

  th.scheduleLoadingDate,
  td.scheduleLoadingDate{
    /*border 2px solid #757575*/
  }
}

.delivery-company-box {
  margin-bottom 12px
}

.rel-company-box {
  margin 12px 0 0 24px
}

.company-name-label {
  height 40px
}

.hit-count{
  display inline-block

  &.zero{
    color #D32F2F
  }

  &:before{
    display inline-block
    content  "該当"
    font-size 1.0em
    margin-right 8px
  }

  &:after{
    display inline-block
    content  "件"
    font-size 1.0em
    margin-left 4px
  }

  .label{
    font-size 1.4em;
  }
}

.specified-info{
  padding-top 8px
  padding-bottom  0px
}
</style>
