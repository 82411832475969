<template lang="pug">
  table.operation-resource-table
    thead
      tr
        th.title(colspan="22") {{ title }}
      tr
        th.left-space
        th.day-title(v-for="item in orderQuantityList" colspan="3" :class="getWeekClass(item)")
          span {{ item.formatDate + "(" + item.dayOfWeekLabel + ")" }}
      tr
        th
        template(v-for="item in orderQuantityList")
          th(v-for="shift in shiftTypeList" :class="getWeekClass(item)")
            span {{ shift.label }}
    tbody
      tr
        th.left-header 乗務員
        template(v-for="item in orderQuantityList")
          td(v-for="shift in shiftTypeList" :key="shift.code + item.targetDate" :class="getWeekClass(item)")
            div {{ getDriverCount({targetDate:item.targetDate,shiftCd:shift.code,contractCd:contractTypeCd}) }}
      tr(v-for="vehicle in vehicleTypeList" :key="vehicle.id")
        th.left-header {{ vehicle.vehicleTypeName }}(運転可)
        template(v-for="item in orderQuantityList")
          td(v-for="shift in shiftTypeList" :key="shift.label + item.targetDate" :class="getWeekClass(item)")
            div
              span ({{ getVehicleCount({targetDate:item.targetDate,shiftCd:shift.code,contractCd:contractTypeCd,vehicleCd:vehicle.vehicleTypeCd}) }})
</template>

<script>
import { mapGetters } from "vuex";
import OperationResource from "@/mixin/OperationResource";
import * as Enum from "@/assets/js/enum";

export default {
  mixins: [OperationResource],
  components: {},
  props: {
    contractTypeCd: {
      type: String,
      default() {
        return null;
      }
    },
    title: {
      default() {
        return "乗務員数(合計)";
      },
      type: String
    }
  },
  data() {
    let headerCss = "font-weight-thin grey--text text--darken-1";
    let sundayCss = "sunDay";
    let otherDayCss = "otherDay";
    return {
      css: {
        headerClass: headerCss,
        sundayClass: sundayCss,
        otherDayClass: otherDayCss
      }
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      getDriverCount: "operationResource/getDriverCount",
      getVehicleCount: "operationResource/getDrivableVehicleCount",
      getExceedCount: "operationResource/getExceedCount"
    }),
    shiftTypeList: {
      get() {
        return Enum.Utils.enumToList(Enum.ShiftType);
      }
    },
    orderQuantityList: {
      get() {
        return this.$store.state.operationResource.orderQuantityList;
      }
    },
    vehicleTypeList: {
      get() {
        return this.$store.state.operationResource.displayVehicleTypeList;
      }
    }
  }
};
</script>
