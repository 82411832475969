import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  uploadToken: null,
  workVehicleList: [],
  workList: [],
  workListAllCount: 0,
  workListErrorCount: 0,
  errorOnly: false
};

const getters = {
  workList: () => state.workList,
  workVehicleList: () => state.workVehicleList,
  workListAllCount: () => state.workListAllCount,
  workListErrorCount: () => state.workListErrorCount,
  isFilterError: () => state.errorOnly
};

const actions = {
  /////////////
  async uploadImportFile({ dispatch, commit, state }, data) {
    const payLoad = {
      data: data.data,
      uri: data.url
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        commit("writeUploadToken", value);
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  ////
  async uploadValidation({ commit, state }, data) {
    return $axios
      .post(data.url, { ...{ token: state.uploadToken } })
      .then(() => {
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  ////
  async fetchWorkList({ state, commit }, getParams) {
    let url = getParams.url;
    let pageCount = getParams.pageCount;
    return $axios
      .get(url, {
        params: { ...getParams, ...{ token: state.uploadToken }, ...{ errorOnly: state.errorOnly } }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearWorkList");
          commit("initVehicleTypeList", value.data.data);
        }
        commit("appendAllWorkData", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  ///////
  async fetchWorkListCount({ state, commit }, getParams) {
    let url = getParams.url;
    return $axios
      .get(url, {
        params: { ...{ token: state.uploadToken } }
      })
      .then(value => {
        commit("writeWorkCount", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  ///////
  async fetchTariffWorkListCount({ state, commit }) {
    return $axios
      .get(axiosBase.getUrls().getTariffWorkListCount, {
        params: { ...{ token: state.uploadToken } }
      })
      .then(value => {
        commit("writeWorkCount", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  ///////
  async commitImport({ dispatch, commit, state }, url) {
    const payLoad = {
      data: JSON.stringify({ token: state.uploadToken }),
      uri: url,
      headers: {
        "Content-Type": "application/json"
      }
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        return Promise.resolve(value == null ? [] : value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  ///////
  async clearWorkData({ commit }) {
    commit("clearAllWorkData");
  },
  // 実績出力
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getExportTariff, {
      params: getParams
    });
  },
  async toggleErrorOnly({ commit }, toggle) {
    commit("toggleErrorOnly", toggle);
  }
};

const mutations = {
  clearAllAreaData(state) {
    state.tariffList = [];
  },
  appendAllAreaData(state, data) {
    if (data != null) {
      state.tariffList = state.tariffList.concat(data);
    }
  },
  clearAllTariffHistoryList(state) {
    state.tariffHistoryList = [];
  },
  appendTariffHistoryList(state, data) {
    if (data != null) {
      state.tariffHistoryList = state.tariffHistoryList.concat(data);
    }
  },
  saveTariffHistoryDiff(state, data) {
    state.tariffHistoryDiff = data;
  },
  clearTariffHistoryList(state) {
    state.tariffHistoryList = [];
  },
  clearTariffHistoryDiff(state) {
    state.tariffHistoryDiff = null;
  },
  incrementHistoryTablePageCount(state) {
    state.paging.historyTablePageCount++;
  },
  clearHistoryTablePageCount(state) {
    state.paging.historyTablePageCount = 0;
  },
  writeUploadToken(state, token) {
    state.uploadToken = token;
  },
  clearWorkList(state) {
    state.workList = [];
  },
  clearAllWorkData(state) {
    state.workList = [];
    state.workListAllCount = 0;
    state.workListErrorCount = 0;
  },
  writeWorkCount(state, data) {
    state.workListAllCount = data.allCount;
    state.workListErrorCount = data.errorCount;
  },
  initVehicleTypeList(state, data) {
    if (data) {
      // state.workVehicleList = data[0].detailWorkLIst;
    }
  },
  appendAllWorkData(state, data) {
    if (data != null) {
      state.workList = state.workList.concat(data);
    }
  },
  toggleErrorOnly(state, toggle) {
    state.errorOnly = toggle;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
