<template lang="pug">
  div.icon-wrapper(:class="className" v-if="isDisplay")
    v-icon fas fa-dolly-flatbed
</template>

<script>
import LiftTypeHandler from "@/mixin/LiftTypeHandler";

export default {
  mixins: [LiftTypeHandler],
  props: {
    liftType: {
      type: String,
      default: "00"
    },
    displayTypeNone: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    isDisplay: function () {
      return !(
        (this.isNone(this.liftType) || this.isUnManaged(this.liftType)) &&
        this.displayTypeNone === false
      );
    },
    className: {
      get() {
        if (this.isNone(this.liftType)) {
          return "none";
        } else if (this.isReach(this.liftType)) {
          return "reach";
        } else if (this.isCounter(this.liftType)) {
          return "counter";
        } else if (this.isBoth(this.liftType)) {
          return "both";
        } else if (this.isUnManaged(this.liftType)) {
          return "un-managed";
        } else {
          return "";
        }
      }
    }
  }
};
</script>

<style lang="stylus">
.icon-wrapper{
  $iconSize = 22px
  width $iconSize
  height $iconSize

  border-radius 50%
  text-align center
  line-height $iconSize -1 px

  &.none{
    background-color #9E9E9E
    .v-icon{
      color #FAFAFA
    }
  }

  &.reach{
    background-color #FF9800
    .v-icon{
      color #FFF8E1
    }
  }

  &.counter{
    background-color #03A9F4
    .v-icon{
      color #E1F5FE
    }
  }

  &.both{
    background-color #4CAF50
    .v-icon{
      color #E8F5E9
    }
  }

  &.un-managed{
    background-color #9E9E9E
    .v-icon{
      color #FAFAFA
    }
  }

  .v-icon{
    font-size 13px
  }
}
</style>
