<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="label"
    :item-value="itemValue"
    :label="labelDisp"
    small-chips
    multiple
    :clearable="clearable"
    :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")
</template>

<script>
import * as Enum from "@/assets/js/enum";
export default {
  props: {
    // TODO:type:Boolean 必須でありながらNullがはいる可能性があるため回避。（※clearable影響）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default() {
        return null;
      }
    },
    errorHandlerMethodArg: {
      type: String,
      default() {
        return null;
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },
    labelDisp: {
      type: String,
      default: "シフト"
    },
    clearable: {
      type: Boolean,
      default() {
        return true;
      }
    },
    withoutNone: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "status"
    };
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return this.withoutNone ? this.masterListWithoutNone : Enum.Utils.enumToList(Enum.ShiftType);
      }
    },
    masterListWithoutNone: {
      get() {
        return Enum.Utils.enumToList(Enum.ShiftType).filter(value => {
          return value.shift;
        });
      }
    },
    errorHandlerMethodArgComputed: {
      get() {
        if (this.errorHandlerMethodArg == null) {
          return this.defaultErrorHandlerMethodArg;
        }
        return this.errorHandlerMethodArg;
      }
    },
    itemValue: {
      get() {
        if (this.useFilter) {
          return "label";
        } else {
          return "code";
        }
      }
    }
  },
  methods: {}
};
</script>
