import Sse from "sse.js";
import Axios from "@/assets/js/axios";
import qs from "qs";

const state = {
  totalCount: 0,
  currentCount: 0,
  loading: false,
  loadingMessage: "",
  token: null,
  isComplete: false
};

/** GETTERS */
const getters = {
  getLoadingStatus: state => state.loading,
  getTotalCount: state => state.totalCount,
  getCurrentCount: state => state.currentCount,
  getLoadingMessage: state => state.loadingMessage
};

/** ACTIONS */
const actions = {
  async indicate({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      //初期化
      commit("initIndicate");
      //ローディング開始
      commit("changeLoadingProgress", true);

      const url = payload.uri.startsWith("http") ? payload.uri : process.env.VUE_APP_API_URL + payload.uri;

      var sse;
      if (payload.sendGet) {
        const params = qs.stringify(payload.data, { allowDots: true });
        sse = new Sse.SSE(url + "?" + params.toString(), {
          withCredentials: true
        });
      } else {
        // const url = process.env.VUE_APP_API_URL + payload.uri;
        sse = new Sse.SSE(url, {
          withCredentials: true,
          payload: payload.data,
          headers: payload.headers ? payload.headers : {}
        });
      }

      sse.addEventListener("totalCount", function (e) {
        commit("writeTotalCount", e.data);
        console.log(e.data);
        payload.onGotTotalCount !== undefined ? payload.onGotTotalCount() : null;
      });

      sse.addEventListener("message", function (e) {
        commit("setLoadingMessage", e.data);
      });

      sse.addEventListener("progress", function (e) {
        commit("writeCurrentCount", e.data);

        console.log(e.data + "/" + state.totalCount);

        payload.onProgress !== undefined ? payload.onProgress() : null;
      });

      sse.addEventListener("progressIgnore", function (e) {
        console.log(e.data);
      });

      sse.addEventListener("error", function (e) {
        if (state.isComplete) {
          return;
        }
        sse.close();

        console.log(e);

        if (e.data === undefined) {
          const event = new CustomEvent(window.Common.Config.observer.onErrorResponse400, {
            detail: {
              message: window.Common.Messages.Common.Error.NetworkClientError
            }
          });
          window.dispatchEvent(event);

          //ローディング終了
          commit("changeLoadingProgress", false);
          Axios.fireAxiosIndicateEnd();
          reject(window.Common.Messages.Common.Error.NetworkClientError);
        } else {
          const responseJson = JSON.parse(e.data);

          if (responseJson["fieldErrors"]) {
            payload.onError !== undefined ? payload.onError(responseJson) : null;
          } else {
            let message;

            if (responseJson["message"]) {
              message = responseJson["message"];
            } else {
              message = e.data;
            }

            const event = new CustomEvent(window.Common.Config.observer.onErrorResponse400, {
              detail: {
                message: message
              }
            });
            window.dispatchEvent(event);

            //ローディング終了
            commit("changeLoadingProgress", false);
            commit("setLoadingMessage", "");
            Axios.fireAxiosIndicateEnd();
            reject(responseJson);
          }
        }
      });

      sse.addEventListener("complete", e => {
        commit("writeComplete", true);
        sse.close();
        payload.onComplete !== undefined ? payload.onComplete(e.data) : null;
        //ローディング終了
        commit("changeLoadingProgress", false);
        commit("setLoadingMessage", "");
        Axios.fireAxiosIndicateEnd();
        resolve(e.data ? e.data : null);
      });

      try {
        commit("writeComplete", false);
        Axios.fireAxiosIndicateStart();
        sse.stream();
      } catch (e) {
        console.log(e);
      }
    });
  }
};

const mutations = {
  initIndicate(state) {
    state.totalCount = 0;
    state.currentCount = 0;
    state.loading = false;
    state.loadingMessage = null;
    state.token = null;
  },
  writeToken(state, data) {
    state.token = data;
  },
  writeComplete(state, flg) {
    state.isComplete = flg;
  },
  writeCurrentCount(state, data) {
    state.currentCount = data;
  },
  writeTotalCount(state, data) {
    state.totalCount = data;
  },
  changeLoadingProgress(state, flag) {
    state.loading = flag;
  },
  setLoadingMessage(state, data) {
    state.loadingMessage = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
