<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="templateName"
    item-value="id"
    :label="label"
    :readonly="readonly"
    :clearable="clearable"
    :append-icon="appendIcon"
    :error-messages="errorHandlerMethod('ExportTemplateId')"
    v-on:change="changeItem")
</template>

<script>
export default {
  props: {
    // TODO:必須でありながらNullがはいる。（※clearableによる処理時など）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    label: {
      type: String,
      default: () => "テンプレート"
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    errorHandlerMethod: {
      type: Function,
      default() {
        return null;
      }
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return this.$store.state.exportTemplate.exportTemplateList;
      }
    },
    appendIcon: {
      get() {
        return this.readonly ? "" : "$vuetify.icons.dropdown";
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    changeItem() {
      this.$emit("changeItem");
    },
    /**
     * データの読み込み
     */
    loadListData() {
      this.$store.dispatch("exportTemplate/fetchAllForSelect");
    }
  }
};
</script>
