<template lang="pug">
  v-content
    EmergencyErrorDialog(ref="ServerErrorDialog")
    v-container(fluid grid-list-md)
      v-layout
        v-flex(md12)
          v-card
            v-card-text
              ServiceContactFilterConditions(ref="ServiceContactFilterConditions" @onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              v-btn(@click="exportConfirm(exportType.serviceContact.dispatchUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll") {{ exportType.serviceContact.labelName }}
              v-btn(@click="exportConfirm(exportType.serviceContact.sendUrl)" color="info" :loading="loading.export" :disabled="isDeselectedAll || !searchCond.faxTarget") {{ exportType.serviceContact.sendLabel }}
              ExportConfirmDialog(ref="ExportConfirmDialog" :message="confirmMessage" @yes="exportReport")
      v-card
        v-flex(xs12)
          ServiceContractListTable(ref="ServiceContractListTable")
</template>

<script>
import { mapGetters } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import ExportHandler from "@/mixin/ExportHandler";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import ServiceContactFilterConditions from "@/components/report/ServiceContactFilterConditions";
import ServiceContractListTable from "@/components/report/ServiceContractListTable";

export default {
  components: {
    ServiceContractListTable,
    ServiceContactFilterConditions,
    EmergencyErrorDialog,
    ExportConfirmDialog
  },
  mixins: [EventDispatcher, ExportHandler],
  data: () => ({
    // 検索エラーフラグ
    isError: false,
    confirmMessage: "現在の選択内容を出力しますか？",
    deselectedSignatureMessage: "署名を選択してから出力してください。",
    // ExportHandler処理用Store URL
    dispatchUrl: "",
    exportType: {
      serviceContact: {
        labelName: "車番連絡表出力",
        sendLabel: "車番連絡表送信",
        dispatchUrl: "report/downloadServiceContactReport",
        sendUrl: "report/sendServiceContactReport"
      }
    }
  }),
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      isDeselectedAll: "report/getIsDeselectedAll",
      isDeselectedSignature: "report/getIsDeselectedSignature"
    })
  },
  methods: {
    /**
     * Event：検索条件 変更
     */
    onChangeSearchConditions(searchCondition) {
      // ExportHandler条件設定
      this.searchCond = searchCondition;
      // InfiniteScrollHandler処理
      this.$refs.ServiceContractListTable.setSearchConditions(searchCondition);
      this.$refs.ServiceContractListTable.resetPageCount();
      // データ取得
      this.$refs.ServiceContractListTable.loadData()
        .then(result => (this.isError = false))
        .catch(error => {
          this.isError = true;
          // alertBox表示
          this.$refs.ServiceContactFilterConditions.displayMessage(error.response.data.message);
        });
    },
    /**
     * Event：出力ダイアログの表示
     * ※PDFダウンロード
     */
    exportConfirm(url) {
      this.$refs.ServiceContactFilterConditions.nonDisplayMessage();
      if (this.isError) return;
      // 署名確認
      if (this.isDeselectedSignature) {
        this.$refs.ServiceContactFilterConditions.displayMessage(this.deselectedSignatureMessage);
        return;
      }
      this.dispatchUrl = url;
      this.$refs.ExportConfirmDialog.openDialog();
    },
    exportReport() {
      this.exportData(this.dispatchUrl).then(() => {
        this.$refs.ServiceContactFilterConditions.submit();
      });
    }
  },
  mounted() {
    // 初期ロード時に初期検索情報にて結果を表示
    this.$store.dispatch("report/clearFetchList");
    this.$refs.ServiceContactFilterConditions.submit();
  }
};
</script>
