<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="車格CD" v-model="conditions.vehicleTypeCd")
        v-flex(md2)
          v-text-field(label="名称" v-model="conditions.vehicleTypeName")
        v-flex(md2)
          UseSelect(:code.sync="conditions.useChassis" labelDisp="シャーシ利用" :clearable="true")
        v-flex(md2)
          UseSelect(:code.sync="conditions.displayResourceTarget" labelDisp="リソース表示対象" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import UseSelect from "@/components/common/select/UseSelect";

export default {
  components: {
    SearchConditions,
    UseSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          vehicleTypeCd: "",
          vehicleTypeName: "",
          useChassis: "",
          displayResourceTarget: "",
          displayOrder: ""
        }
      };
    }
  }
};
</script>
