<template lang="pug">
  section
    EditDialog(ref="EditDialog" title="カード詳細" :recordId="record.id" :enableSave="!isDynamic && selectTab === 'tab1' && canOriginCardEdit" :displayDeleteButton="false" :hasTitleSuffix="false" width="75%" @save="save" labelSaveButton="更新",
      :loadingSave="loading.save")
      template(v-slot:body)
        v-tabs(color="primary darken-2" dark slider-color="yellow darken-2" v-model="selectTab" )
          v-tab(href="#tab1") カード情報
          v-tab(href="#tab2" :disabled="!isCanAccess()") 請求支払情報
          v-tab-item(value="tab1")
            v-card
              v-card-text
                v-container(grid-list-md)
                  v-layout(v-if="record.orderType !== '40' && record.isDispatched" row wrap)
                    //乗務員車両車格
                    v-flex(xs12 md3)
                      v-text-field(readonly box label="乗務員車両車格" v-model="record.vehicleType")

                    //車番
                    v-flex(xs12 md4)
                      v-text-field(readonly box label="車番" v-model="record.vehicleNumber")

                    //車両積載重量
                    v-flex(xs12 md2)
                      v-text-field.text-align-right(readonly box label="車両積載重量" v-model="record.carryWeight" suffix="kg")

                    //車両オプション
                    v-flex(xs12 md3)
                      VehicleOptionMultiSelect(:readonly="true" :clearable="false" :code.sync="getVehicleOptionCodes" :errorHandlerMethod="getErrorMessages")

                  v-layout(v-if="record.orderType !== '40'" row wrap)
                    //カード車格
                    v-flex(xs12 md3)
                      v-text-field(readonly box label="カード必要車格" v-model="record.requiredVehicleType")

                    //カード積載重量
                    v-flex(xs12 md2)
                      v-text-field.text-align-right(readonly box label="カード積載重量" v-model="record.requiredCarryWeight" suffix="kg")

                    v-flex(xs12 md3 v-if="record.orderType === '30'" )
                      v-text-field(readonly box label="中継番号" v-model="record.relayNo")
                    v-flex(xs12 md1 v-if="record.isDispatched")
                      v-text-field.text-align-right(readonly box label="配車明細ID" v-model="record.detailId")
                    v-flex(xs12 md1)
                      v-text-field.text-align-right(readonly box label="カードID" v-model="getCardIdForView")

                  v-layout(v-if="record.orderType !== '30' && record.orderType !== '40'" row wrap)
                    //荷主
                    v-flex(xs12 md6)
                      v-text-field(readonly box label="荷主" v-model="record.clientName")

                    //荷主枝番
                    v-flex(xs12 md6)
                      v-text-field(readonly box label="荷主枝番" v-model="record.clientBranchName")

                  v-layout(v-if="record.isDispatched" row wrap)
                    //委託先
                    v-flex(xs12 md6)
                      v-text-field(readonly box label="1次委託先" v-model="record.primaryDeliveryCompanyNameAndCd")

                  v-layout(row wrap)
                    v-flex(xs12 md2 v-if="record.orderType === '30'")
                      //作成日
                      v-text-field(readonly box label="作成日" v-model="record.createDate")

                    v-flex(xs12 md4 v-if="record.orderType === '40'")
                      DispatchTempCardTypeSelect(:readonly="true" box  :code.sync="record.dispatchTempCardType" :errorHandlerMethod="getErrorMessages")
                    v-flex(xs12 md2)
                      v-text-field(readonly box :label="labalName.LoadingSchedule" v-model="record.loadingDate")
                    v-flex(xs12 md2)
                      v-text-field(readonly box :label="labalName.UnloadingSchedule" v-model="record.unloadingDate")
                    v-flex(xs12 md2 v-if="record.isDispatched")
                      v-text-field.text-align-right(readonly box label="配車明細ID" v-model="record.detailId")
                    v-flex(xs12 md2 v-if="record.receiptNo !== undefined")
                      v-text-field(readonly box label="受付番号" v-model="record.receiptNo")

                  v-layout(row wrap)
                    //積地
                    v-flex(xs12 md3)
                      template(v-for="item in record.loadingBaseList")
                        v-layout(row wrap)
                          v-flex(xs12 style="position:relative")
                            TrackingCardIcon(v-if="findBaseArrivalStatus(item.baseId,true)" :arrivalStatus="findBaseArrivalStatus(item.baseId,true)"
                              @click="openLoadingTrackingDetail(item.baseId)").tracking-icon-base
                            v-text-field(hide-details readonly box label="積地" v-model="item.nameAndCd")
                            BaseLiftAndOptionInfo.lift-option-box(:item="item")
                            div.shipment-box(v-if="findShipmentNo(item.baseId) != null")
                              span.shipment-box-title シップメントNo
                              span.shipment-box-no {{ findShipmentNo(item.baseId) }}

                    v-flex(xs1 md1)
                      v-layout(align-center justify-center row fill-height)
                        v-icon(x-large) keyboard_arrow_right

                    v-flex(xs12 md8)
                      template(v-for="(item,index) in record.unloadingBaseList")
                        v-layout(row wrap)
                          //卸地
                          v-flex(xs12 md5 style="position:relative")
                            TrackingCardIcon(v-if="findBaseArrivalStatus(item.baseId,false)" :arrivalStatus="findBaseArrivalStatus(item.baseId,false)"
                              @click="openUnloadingTrackingDetail(item.baseId)").tracking-icon-base
                            v-text-field(hide-details readonly box label="卸地" v-model="item.nameAndCd")
                            BaseLiftAndOptionInfo(:item="item")

                          //卸順
                          v-flex(v-if="record.orderType !== '40'" xs12 md1)
                            v-text-field.text-align-right(label="卸順" v-model="item.unloadingOrder" :box="isDynamic || !canOriginCardEdit" :readonly="isDynamic || !canOriginCardEdit" :errorHandlerMethod="getErrorMessages"  :error-messages="getErrorMessages('itemList[' + index + '].unloadingOrder')")

                          //時間指定from
                          v-flex(v-if="record.orderType !== '40'" xs12 md3)
                            TimePicker(v-if="!isDynamic && canOriginCardEdit" pickerLabel="時間指定(開始)" :initTime="null" :inputTime.sync="item.specifiedTime.from" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'itemList[' + index + '].specifiedTimeFrom'")
                            v-text-field(v-if="isDynamic || !canOriginCardEdit" hide-details readonly box label="時間指定(開始)" v-model="item.specifiedTime.from" prepend-inner-icon="access_time")

                          //時間指定to
                          v-flex(v-if="record.orderType !== '40'" xs12 md3)
                            TimePicker(v-if="!isDynamic && canOriginCardEdit" pickerLabel="時間指定(終了)" :initTime="null" :inputTime.sync="item.specifiedTime.to" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'itemList[' + index + '].specifiedTimeTo'")
                            v-text-field(v-if="isDynamic || !canOriginCardEdit" hide-details readonly box label="時間指定(終了)" v-model="item.specifiedTime.to" prepend-inner-icon="access_time")

                          //必要リフト免許
                          //v-flex(xs12 md3)
                            v-select(readonly box label="必要リフト免許" v-model="item.requireLiftType" :items="liftTypes" item-text="label" item-value="code")

                  v-layout(v-if="record.orderType !== '40'" row wrap)
                    v-flex(xs12 md2)
                      //インポート受注日
                      v-text-field(v-if="record.orderType === '10'" readonly box label="受注日" v-model="record.importDate")
                      //受注日
                      v-text-field(v-if="record.orderType === '20'" readonly box label="受注日" v-model="record.orderDate")

                    v-flex(xs12 md2)
                      //プランボード番号
                      v-text-field(v-if="record.orderType === '10'" readonly box label="プランボード番号" v-model="record.planBoardNo")
                      //積荷種別
                      v-text-field(v-if="record.orderType === '20'" readonly box label="積荷種別" v-model="record.itemType")

                    v-flex(xs12 md2)
                      //ルートNO
                      v-text-field(v-if="record.orderType === '10'" readonly box label="ルートNo" v-model="record.routeNoWithSeq")
                      //荷役区分
                      v-text-field(v-if="record.orderType === '20'" readonly box label="荷役区分" v-model="record.handlingUnitType")

                    //距離区分
                    v-flex(xs12 md2)
                      v-text-field(v-if="record.orderType !== '30'" readonly box label="距離区分" v-model="record.distanceType")

                    //合計数量
                    v-flex(v-if="record.orderType === '10'" xs12 md2)
                      v-text-field.text-align-right(readonly box label="合計数量" v-model="record.totalQuantity")

                    //合計重量
                    v-flex(xs12 md2)
                      v-text-field.text-align-right(v-if="record.orderType !== '30'" readonly box label="合計重量" :value="record.totalGrossWeight|addComma" @input="value=>record.totalGrossWeight=value" suffix="kg")

                  v-layout(v-if="record.orderType !== '40'" row wrap)
                    v-card(v-if="record.orderType === '10'")
                      v-container(grid-list-md)
                          template(v-for="(item, index) in record.slipList")
                            v-layout(row wrap)
                              //No
                              v-flex(xs12 md1)
                                v-text-field.text-align-right(readonly box label="No" v-bind:value="index+1")

                              //伝票番号
                              v-flex(xs12 md1)
                                v-text-field(readonly box label="伝票番号" v-model="item.slipNo")

                              //請求区分
                              v-flex(xs12 md1)
                                v-text-field(readonly box label="請求区分" v-model="item.billingType")

                              //積地
                              v-flex(xs12 md3)
                                v-text-field(readonly box label="積地" :value="item.loadingNameAndCd")

                              //卸地
                              v-flex(xs12 md3)
                                  v-text-field(readonly box label="卸地" :value="item.unloadingNameAndCd")

                              //数量
                              v-flex(xs12 md1)
                                v-text-field.text-align-right(readonly box label="数量" v-model="item.quantity")

                              //重量
                              v-flex(xs12 md2)
                                v-text-field.text-align-right(readonly box label="重量" :value="item.weight|addComma" @input="value=>item.weight=value" suffix="kg")
          v-tab-item(value="tab2")
            v-card
              v-card-text
                FinanceCardDetail(ref="FinanceCardDetail" :requiredVehicleUseChassis="record.requiredVehicleUseChassis" :isRelaySource="record.isRelaySource" :isFinance="isFinance")
    TrackingDetail(ref="TrackingDetail")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import TimePicker from "@/components/common/TimePicker";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";
import VehicleOptionMultiSelect from "@/components/common/select/VehicleOptionMultiSelect";
import BaseLiftAndOptionInfo from "@/components/dispatch/BaseLiftAndOptionInfo";
import DispatchTempCardTypeSelect from "@/components/common/select/DispatchTempCardTypeSelect";
import TrackingDetail from "@/components/dynamic/TrackingDetail";
import TrackingCardIcon from "@/components/dynamic/TrackingCardIcon";
import FinanceCardDetail from "@/components/finance/FinanceCardDetail";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";
import { LabelName } from "@/assets/js/constants";
import { mapGetters } from "vuex";

export default {
  components: {
    EditDialog,
    TimePicker,
    VehicleTypeSelect,
    VehicleOptionMultiSelect,
    BaseLiftAndOptionInfo,
    DispatchTempCardTypeSelect,
    TrackingDetail,
    TrackingCardIcon,
    FinanceCardDetail
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {},
      orderId: null,
      cardId: null,
      requestCardId: null,
      tranData: {},
      liftTypes: [],
      labalName: LabelName,
      selectTab: "tab1"
    };
  },
  props: {
    isDynamic: {
      type: Boolean,
      default: () => false
    },
    isFinance: {
      type: Boolean,
      default: () => false
    }
  },
  mounted() {
    this.liftTypes = Enum.Utils.enumToList(Enum.LiftType);
  },
  computed: {
    ...mapGetters("finance", {
      canOriginCardEdit: "canOriginCardEdit"
    }),
    getVehicleOptionCodes: {
      get: function () {
        let vehicleOptionCodes = [];
        if (this.record.vehicleOptionList) {
          for (let key in this.$store.state.site.vehicleOptionTypes) {
            if (
              this.record.vehicleOptionList.indexOf(this.$store.state.site.vehicleOptionTypes[key].label) >= 0
            ) {
              vehicleOptionCodes.push(this.$store.state.site.vehicleOptionTypes[key].code);
            }
          }
        }
        return vehicleOptionCodes;
      }
    },
    cardDetailAction: {
      get() {
        if (this.isDynamic) {
          return "dynamic/fetchDynamicCardDetail";
        } else if (this.isFinance) {
          return "finance/fetchDispatchCardDetailByCardIdFinance";
        } else {
          return "dispatch/fetchDispatchCardDetailByCardId";
        }
      }
    },
    getCardIdForView() {
      return this.requestCardId;
    }
  },
  methods: {
    /**
     * 初期データ
     */
    initData(data) {
      if (data !== null) {
        return data;
      }
      return {
        driverName: null,
        index: null
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      const bases = [];
      this.record.unloadingBaseList.forEach(value => {
        bases.push({
          unloadingBaseId: value.baseId,
          unloadingOrder: value.unloadingOrder,
          specifiedTimeFrom: value.specifiedTime.from,
          specifiedTimeTo: value.specifiedTime.to
        });
      });
      this.tranData = {
        orderId: this.orderId,
        orderType: this.record.orderType,
        itemList: bases
      };
    },
    /**
     * フォームの初期化
     * @param values
     */
    initForm(values) {
      // スライド蘭から開く場合の制御
      const cardId = values.slideCardId ? values.slideCardId : values.cardId;
      this.$store
        .dispatch(this.cardDetailAction, {
          orderType: values.orderType,
          orderId: values.orderId,
          cardId: cardId
        })
        .then(value => {
          if (value) {
            if (this.isDynamic) {
              this.dynamicData = value;
              this.record = this.dynamicData.detail;
            } else {
              this.record = value;
            }

            this.orderId = values.orderId;
            this.cardId = value.cardId;
            this.$refs.FinanceCardDetail.initData(cardId);
          }
          this.$refs.EditDialog.openDialog();
        })
        .catch(error => {
          const event = document.createEvent("Event");
          event.initEvent(window.Common.Config.observer.onErrorResponse404, false, true);
          window.dispatchEvent(event);
        });
      this.errors = {};
    },

    openDialog(item) {
      this.initForm(item);
      this.requestCardId = item.slideCardId && item.isSameDaySlide ? item.slideCardId : item.cardId;
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    findShipmentNo(baseId) {
      if (this.record.slipList !== undefined && this.record.slipList.length > 0) {
        const findBase = this.record.slipList.filter(value => value.loadingBaseId === baseId).pop();

        return findBase !== undefined ? findBase.shipmentNo : null;
      } else {
        return null;
      }
    },
    save() {
      this.setTranData();
      // const vue = this;
      this.saveRecord(this.tranData).then(() => {
        this.$refs.EditDialog.closeDialog();
        this.$emit("saved", this.tranData);
      });
    },
    openLoadingTrackingDetail(baseId) {
      const trackingData = this.findBaseTrackingData(baseId, true);
      if (trackingData !== null) {
        this.$refs.TrackingDetail.openDialog(trackingData);
      }
    },
    openUnloadingTrackingDetail(baseId) {
      const trackingData = this.findBaseTrackingData(baseId, false);
      if (trackingData !== null) {
        this.$refs.TrackingDetail.openDialog(trackingData);
      }
    },
    findBaseArrivalStatus(baseId, isLoading) {
      const data = this.findBaseTrackingData(baseId, isLoading);
      return data == null ? null : data.arrivalStatus;
    },
    findBaseTrackingData(baseId, isLoading) {
      const baseRef = isLoading ? "loadingBaseTrackingList" : "unloadingBaseTrackingList";
      if (
        this.dynamicData !== undefined &&
        this.dynamicData[baseRef] !== undefined &&
        this.dynamicData[baseRef] != null
      ) {
        const trackingData = this.dynamicData[baseRef]
          .filter(rec => {
            return rec.baseId === baseId;
          })
          .pop();

        return trackingData === undefined ? null : trackingData;
      } else {
        return null;
      }
    },
    isCanAccess() {
      return (
        this.$store.getters["site/canReadFeature"](Enum.FunctionList.BillingAndPayment.code) &&
        !this.isDynamic
      );
    }
  }
};
</script>

<style lang="stylus" scoped>
.lift-option-box {
  float: left;
}

.shipment-box {
  float: right;
  z-index: 2;
  padding: 8px 0 8px 0;

  .shipment-box-title {
    margin-right: 8px;
  }
}

.tracking-icon-base {
  position: absolute;
  top: -8px;
  left: -6px;
  width: 32px;
  height: 24px;
  cursor: pointer;
  z-index: 3;
}
</style>
