<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

          v-card
            v-tabs(color="primary darken-2" dark slider-color="yellow darken-2")
              v-tab(ripple) 第一運行
              v-tab(ripple) 全運行
              v-tab-item
                DispatchMailTable(:prop-header="confirmTableHeader" :prop-data="listOfDriversForFirst" ,
                  detail-url="dispatch/fetchMailByDriver" @open-detail="openDetail" :loadingDate="loadingDate")
              v-tab-item
                DispatchMailTable(:prop-header="confirmTableHeader" :prop-data="listOfDriversForAll" ,
                  detail-url="dispatch/fetchMailByDriver" @open-detail="openDetail" :loadingDate="loadingDate")
    DispatchMailDetail(ref="DispatchMailDetail" :loadingDate="loadingDate" @send="loadListData")
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import ApiHandler from "@/mixin/ApiHandler";
import DispatchMailTable from "@/components/dispatch/DispatchMailListTable";
import DispatchMailDetail from "@/components/dispatch/DispatchMailDetail";
import FilterConditions from "@/components/dispatch/DispatchMailFilterConditions";
import EventDispatcher from "@/mixin/EventDispatcher";

export default {
  components: {
    DatePicker,
    DispatchMailTable,
    DispatchMailDetail,
    FilterConditions
  },
  mixins: [ApiHandler, EventDispatcher],
  data() {
    return {
      searchCond: {},
      loadingDate: window.Common.Util.getYearMonthDayIsoFormat(),
      firstMailLoading: false,
      allMailLoading: false
    };
  },
  computed: {
    // 第一運行の配車メール
    listOfDriversForFirst: {
      get() {
        return this.$store.state.dispatch.dispatchMailList.listOfDriversForFirst;
      }
    },
    // 全運行の配車メール
    listOfDriversForAll: {
      get() {
        return this.$store.state.dispatch.dispatchMailList.listOfDriversForAll;
      }
    },
    confirmTableHeader: {
      get() {
        return [
          { text: "乗務員", value: "driverName", search: "" },
          {
            text: "最新メールの開封",
            value: "lastMailStatusLabel",
            centerAlign: "true",
            search: ""
          },
          {
            text: "状態更新日時",
            value: "lastUpdateAt",
            centerAlign: "true",
            search: ""
          },
          {
            text: "メール送信日時",
            value: "lastMailSendAt",
            centerAlign: "true",
            search: ""
          }
        ];
      }
    },
    mailLoading: {
      get() {
        return this.firstMailLoading || this.allMailLoading;
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  watch: {
    mailLoading: function (value) {
      if (value === false) {
        //一覧の検索やクリアボタンローディング制御の為にイベント発行。
        this.dispatchCommonEvent(window.Common.Config.observer.onPagingListTableAjaxCompleted);
      }
    }
  },
  methods: {
    loadListData() {
      this.firstMailLoading = this.allMailLoading = true;
      this.$store
        .dispatch("dispatch/fetchAllMailFirst", this.loadingDate)
        .finally(() => (this.firstMailLoading = false));
      this.$store
        .dispatch("dispatch/fetchAllMailAll", this.loadingDate)
        .finally(() => (this.allMailLoading = false));
    },
    openDetail(item) {
      this.$refs.DispatchMailDetail.openDialog(item);
    },
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.loadingDate = searchCond.loadingDate;
      this.loadListData();
    }
  }
};
</script>
