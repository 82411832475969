<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout
        v-flex(xs12)
          v-btn(color="info" :to="backUrlPath") 戻る
          FileUploadForm(:upload-action="uploadFunction" @importedFile="importedFile")

      v-layout
        v-flex(xs12)
          v-card
            v-card-text
              v-layout(wrap)
                v-flex(xs12)
                  alert-box(ref="errorMsg")
                  v-btn(v-if="!commited" color="success" @click="commit" :disabled="isDisableCommit") 取込確定
                v-flex(xs4)
                  span 件数：{{ workListAllCount }} 件
                  span.ml-2 エラー件数：{{ workListErrorCount }} 件
                  v-checkbox.mt-0(label="エラーのみ表示" :value="isFilterError" v-on:change="toggleFilterAction" hide-details)
                v-flex(xs12)
                  slot(name="table")
</template>

<script>
import ImportForm from "@/components/finance/expenses-import/ImportForm";
import FileUploadForm from "@/components/common/form/FileUploadForm";
import ExpensesImportWorkList from "@/components/finance/expenses-import/ExpensesImportWork";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ValidationErrorTips from "@/components/common/ValidationErrorTips";
import TariffImportWorkListTable from "@/components/management/tariff/TariffImportWorkListTable";
import EventDispatcher from "@/mixin/EventDispatcher";
import AlertBox from "@/components/common/AlertBox";

export default {
  props: {
    backUrlPath: {
      type: String,
      required: true
    },
    uploadFileUrl: {
      type: String,
      required: true
    },
    uploadValidationUrl: {
      type: String,
      required: true
    },
    targetCountUrl: {
      type: String,
      required: true
    },
    commitImportUrl: {
      type: String,
      required: true
    },
    fetchWorkListUrl: {
      type: String,
      required: true
    },
    uploadedAtAction: {
      type: Function,
      required: false,
      default: () => null
    },
    isFilterError: {
      type: Boolean,
      default: false
    },
    toggleFilterAction: {
      type: Function,
      required: false,
      default: () => null
    }
  },
  mixins: [EventDispatcher],
  components: {
    TariffImportWorkListTable,
    ImportForm,
    FileUploadForm,
    ExpensesImportWorkList,
    ValidationErrorTips,
    AlertBox
  },
  data() {
    return {
      commited: false
    };
  },
  methods: {
    ...mapActions("financeImport", {
      uploadFile: "uploadImportFile",
      uploadValidation: "uploadValidation",
      commitImport: "commitImport",
      clearWorkData: "clearWorkData",
      fetchWorkListCount: "fetchWorkListCount"
    }),
    ...mapMutations("financeImport", ["clearAllWorkData"]),
    async uploadFunction(data) {
      this.closeMessage();
      await this.clearWorkData();
      await this.uploadFile({
        data: data,
        url: this.uploadFileUrl
      });
    },
    async importedFile() {
      this.closeMessage();
      this.commited = false;
      await this.uploadedAtAction();
      await this.fetchWorkListCount({ url: this.targetCountUrl });
    },
    commit() {
      const self = this;
      this.commitImport(this.commitImportUrl)
        .catch(error => {
          this.dispatchCommonEventWithParam(window.Common.Config.observer.onErrorResponse400, {
            message: error.response.data.message
          });
        })
        .then(() => {
          self.openSuccessMsgBox("インポートが完了しました。");
          this.commited = true;
        });
    },
    openSuccessMsgBox(message) {
      this.$refs.errorMsg.displaySuccessMessage(message);
    },
    closeMessage() {
      this.$refs.errorMsg.nonDisplayMessage();
    }
  },
  computed: {
    ...mapGetters("financeImport", {
      workListAllCount: "workListAllCount",
      workListErrorCount: "workListErrorCount"
    }),
    isDisableCommit() {
      return this.workListAllCount === this.workListErrorCount;
    },
    getListActionName: () => "tariff/fetchWorkList"
  },
  mounted() {
    this.clearAllWorkData();
  }
};
</script>
