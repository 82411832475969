<template lang="pug">
  v-card
    v-tabs(v-model="selectTabIndex" dark color="primary darken-2" slider-color="yellow darken-2")
      v-tab(v-for="mainCategory in tabList" :key="mainCategory.id") {{ mainCategory.name }}
      v-tabs-items(v-model="selectTabIndex")
        v-tab-item(v-for="mainCategory in tabList" :key="mainCategory.id")
          v-card(flat)
            v-card-title.pb-0
              v-spacer
              v-btn(color="primary" @click="onSwitchAll(mainCategory, true)") 全選択
              v-btn(@click="onSwitchAll(mainCategory, false)") 全解除
            v-card-text
              v-layout(v-for="subCategory in mainCategory.subCategoryList" :key="subCategory.id" row wrap align-center justify-start fill-height)
                v-flex(xs2)
                  span {{ subCategory.name }}
                v-flex.d-flex.flex-wrap(xs10)
                  v-checkbox.flex-grow-0.pr-3.my-1(v-for="templateCode in subCategory.sortedTemplateCodes" :key="templateCode.id"
                    @change="onChange(templateCode.templateCode)" :input-value="selectContents[templateCode.templateCode]" :label="templateCode.codeName" hide-details)
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
      required: false
    },
    tabIndex: {
      type: Number,
      default: () => 0,
      required: false
    }
  },
  computed: {
    /** GET：Vuex */
    ...mapGetters({
      tabList: "site/getTemplateTabList"
    }),
    /** IMMUTABLE：選択テンプレート設定 */
    selectContents: {
      get() {
        return this.value;
      },
      set(changeValue) {
        this.$emit("input", changeValue);
      }
    },
    /** IMMUTABLE：選択タブ設定 */
    selectTabIndex: {
      get() {
        return this.tabIndex;
      },
      set(changeTab) {
        this.$emit("changeTab", changeTab);
      }
    }
  },
  methods: {
    /**
     * Event：全選択/全解除
     */
    onSwitchAll(mainCategory, isChecked) {
      let copyContents = { ...this.selectContents };
      mainCategory.subCategoryList.map(subCategory => {
        subCategory.sortedTemplateCodes.map(templateItem => {
          copyContents[templateItem.templateCode] = isChecked;
        });
      });
      this.selectContents = copyContents;
    },
    /**
     * Event：チェックボックス切替
     */
    onChange(templateCode) {
      let copyContents = { ...this.selectContents };
      if (templateCode in copyContents) copyContents[templateCode] = !copyContents[templateCode];
      else copyContents[templateCode] = true;
      this.selectContents = copyContents;
    }
  }
};
</script>

<style lang="stylus" scoped>
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
</style>
