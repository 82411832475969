<template lang="pug">
  EditDialog(ref="EditDialog" title="DriverName" :recordId="record.index" :displayDeleteButton="false" @save="save",
    :loadingSave="loading.save")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md12)
            v-text-field(v-model="record.driverName" label="DriverName" :error-messages="getErrorMessages('driverName')")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {},
      tranData: {}
    };
  },
  methods: {
    /**
     * 初期データ
     */
    initData(data) {
      if (data !== null) {
        return data;
      }
      return {
        driverName: null,
        index: null
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = Object.assign({}, this.record);
    },
    /**
     * フォームの初期化
     * @param values
     */
    initForm(values) {
      this.record = Object.assign({}, this.initData(values));
      this.errors = {};
    },

    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      this.setTranData();
      this.saveRecord(this.tranData).then(() => {
        this.$refs.EditDialog.closeDialog();
        this.$emit("save", this.record);
      });
    }
  }
};
</script>
