<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FilterConditions(@onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              UserTable(ref="UserTable" v-bind:prop-header="userTableHeader" v-bind:prop-data="userList" detail-url="user/fetchUser" @open-detail="openDetail" :displayFilter="false" :getListActionName="getListActionName")
    UserEditDialog(ref="UserEditDialog" :postUrl="$config.urls.postEditUser" :deleteUrl="$config.urls.deleteUser" @updated="itemUpdated" @deleted="itemUpdated" @unlocked="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('user/export')")
</template>

<script>
import UserEditDialog from "@/components/management/user/UserEdit";
import UserTable from "@/components/common/PagingListTable";
import FilterConditions from "@/components/management/user/UserFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    UserEditDialog,
    UserTable,
    FilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    getListActionName: {
      get() {
        return "user/fetchAll";
      }
    },
    userList: {
      get() {
        return this.$store.state.user.userList;
      }
    },
    userTableHeader: {
      get() {
        return [
          {
            text: "ログインID",
            value: "loginCd",
            centerAlign: ""
          },
          { text: "氏名", value: "name", centerAlign: "" },
          {
            text: "メールアドレス",
            value: "mail",
            centerAlign: ""
          },
          {
            text: "ロール",
            value: "role.roleName"
          },
          {
            text: "アカウントロック状態",
            value: "lockedLabel",
            centerAlign: ""
          }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * ダイアログの表示
     */
    openEditDialog() {
      this.$refs.UserEditDialog.openDialog();
    },
    /**
     * 詳細の表示
     */
    openDetail(item) {
      this.$refs.UserEditDialog.openDialog(item);
    },
    /**
     * 表示データの取得
     */
    loadListData() {
      this.$refs.UserTable.loadData();
    },
    itemUpdated(response, data) {
      this.$refs.UserTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件の設定
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.UserTable.setSearchConditions(searchCond);
      this.$refs.UserTable.resetPageCount();
      this.loadListData();
    },
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  }
};
</script>
