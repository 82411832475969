<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
            v-card-text
              v-layout(row wrap)
                v-spacer
                v-flex(xs4)
                  v-layout(row wrap fill-height align-center)
                    v-flex(xs1)
                      v-icon(v-on:click="changeBaseDate(-1)") arrow_back_ios
                    v-flex(xs10)
                      BaseDatePicker(
                        :inputDate.sync="baseDate"
                        picker-label="表示起点日"
                      )
                    v-flex(xs1)
                      v-icon.ml-3(v-on:click="changeBaseDate(1)") arrow_forward_ios

              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      section
                        OrderQuantityTable
                      section
                        VehicleQuantityTable
                      section
                        OperationPossibleResourceTable

                      section(v-for="type in contractTypeList")
                        OperationPossibleResourceTable(:contractTypeCd="type.code" :title="'乗務員数(' + type.label + ')'")
</template>

<script>
import { mapGetters } from "vuex";

import BaseDatePicker from "@/components/common/DatePicker";
import OperationPossibleResourceTable from "@/components/management/operation-possible-resource/OperationPossibleResourceTable";
import OrderQuantityTable from "@/components/management/operation-possible-resource/OrderQuantityTable";
import VehicleQuantityTable from "@/components/management/operation-possible-resource/VehicleQuantityTable";
import * as Enum from "@/assets/js/enum";

import _ from "lodash";

export default {
  components: {
    BaseDatePicker,
    OperationPossibleResourceTable,
    OrderQuantityTable,
    VehicleQuantityTable
  },
  data() {
    return {
      baseDate: ""
    };
  },
  methods: {
    /**
     * 表示起点日の変更
     * @param changeDays
     */
    changeBaseDate(changeDays) {
      const date = new Date(this.baseDate);
      date.setDate(date.getDate() + changeDays);
      this.baseDate = window.Common.Util.getYearMonthDayIsoFormat(date);
    },
    /**
     * データの読み込み
     */
    loadListData() {
      const startDate = new Date(this.baseDate);
      const endDate = _.cloneDeep(startDate);
      endDate.setDate(startDate.getDate() + 6);

      this.$store.dispatch("operationResource/fetchAllOperationResource", {
        startDate: window.Common.Util.getYearMonthDayIsoFormat(startDate),
        endDate: window.Common.Util.getYearMonthDayIsoFormat(endDate)
      });
    }
  },
  computed: {
    ...mapGetters({
      vehicleTypeList: "operationResource/getVehicleTypeList"
    }),
    vehicleResourceList: {
      get() {
        return this.$store.state.operationResource.vehicleResourceList;
      }
    },
    orderQuantityList: {
      get() {
        return this.$store.state.operationResource.orderQuantityList;
      }
    },
    contractTypeList: {
      get() {
        return Enum.Utils.enumToList(Enum.ContractType);
      }
    },
    shiftTypeList: {
      get() {
        return Enum.Utils.enumToList(Enum.ShiftType);
      }
    }
  },
  watch: {
    baseDate: {
      handler: function () {
        this.loadListData();
      }
    }
  }
};
</script>
