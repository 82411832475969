<template lang="pug">
  section
    v-list(dense)
      v-list-tile(v-for="(item, index) in baseNameList" :key="index")
        v-list-tile-content {{ item }}

</template>

<script>
export default {
  components: {},
  props: {
    baseList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    baseNameList: {
      get() {
        return this.baseList;
      }
    }
  },
  methods: {}
};
</script>
