<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="vehicleTypeName"
    item-value="id"
    :label="label"
    :readonly="readonly"
    :box="readonly"
    :clearable="clearable"
    :append-icon="appendIcon"
    :error-messages="errorHandlerMethod('vehicleTypeId')"
    v-on:change="changeItem")
</template>

<script>
export default {
  props: {
    // TODO:必須でありながらNullがはいる。（※clearableによる処理時など）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    vehicleName: {
      type: String,
      required: false,
      default: () => ""
    },
    label: {
      type: String,
      default: () => "車格"
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    errorHandlerMethod: {
      type: Function,
      default() {
        return null;
      }
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    p_label: {
      get() {
        const select = this.masterList.filter(v => v.id === this.selectId).pop();
        return select ? select.vehicleTypeName : null;
      },
      set(newVal) {
        if (this.vehicleName !== newVal) {
          this.$emit("update:vehicleName", newVal);
        }
      }
    },
    masterList: {
      get() {
        return this.$store.state.vehicle.vehicleTypeList;
      }
    },
    appendIcon: {
      get() {
        return this.readonly ? "" : "$vuetify.icons.dropdown";
      }
    }
  },
  data() {
    return {
      selectId: null
    };
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    changeItem(code) {
      const select = this.masterList.filter(v => v.id === code).pop();
      this.p_label = select ? select.vehicleTypeName : null;
      this.$emit("changeItem");
    },
    /**
     * データの読み込み
     */
    loadListData() {
      this.$store.dispatch("vehicle/fetchAllVehicleType", {});
    }
  }
};
</script>
