import { render, staticRenderFns } from "./BjiOrderWorkList.vue?vue&type=template&id=bb8a6a62&scoped=true&lang=pug"
import script from "./BjiOrderWorkList.vue?vue&type=script&lang=js"
export * from "./BjiOrderWorkList.vue?vue&type=script&lang=js"
import style0 from "./BjiOrderWorkList.vue?vue&type=style&index=0&id=bb8a6a62&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb8a6a62",
  null
  
)

export default component.exports