<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      AreaFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              AreaTable(ref="AreaTable" :prop-header="areaTableHeader" :prop-data="areaList" @open-detail="openDetail" detail-url="area/fetchArea" :getListActionName="getListActionName")

    AreaEditDialog(ref="AreaEditDialog" :postUrl="$config.urls.postEditArea" :deleteUrl="$config.urls.deleteArea" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('area/export')")
</template>

<script>
import AreaEditDialog from "@/components/management/area/AreaEdit";
import AreaTable from "@/components/common/PagingListTable";
import AreaFilterConditions from "@/components/management/area/AreaFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    AreaEditDialog,
    AreaTable,
    AreaFilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    areaList: {
      get() {
        return this.$store.state.area.areaList;
      }
    },
    getListActionName: {
      get() {
        return "area/fetchAllArea";
      }
    },
    areaTableHeader: {
      get() {
        return [{ text: "エリア名", value: "name", centerAlign: false }];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openEditDialog() {
      this.$refs.AreaEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.AreaEditDialog.openDialog(item);
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.AreaTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.AreaTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.AreaTable.setSearchConditions(searchCond);
      this.$refs.AreaTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  }
};
</script>
