<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md4)
          v-text-field(label="1次委託先会社(部分一致)" v-model="conditions.firstStructuredCompanyName")
        v-flex(md4)
          v-text-field(label="所属企業(部分一致)" v-model="conditions.companyName")
        v-flex(md3)
          v-text-field(label="氏名(部分一致)" v-model="conditions.name")
        v-flex(md1)
          ContractTypeSelect(:code.sync="conditions.contractType" labelDisp="契約" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import ContractTypeSelect from "@/components/common/select/ContractTypeSelect";

export default {
  components: {
    SearchConditions,
    ContractTypeSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          name: "",
          contractType: "",
          companyName: "",
          firstStructuredCompanyName: ""
        }
      };
    }
  }
};
</script>
