<template lang="pug">
  EditDialog(ref="EditDialog" title="スライド解除" @save="save" :displayDeleteButton="false" :hasTitleSuffix="false")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 v-if="record.cardId !== null")
            v-checkbox(label="後続のカード全てスライド解除" v-model="record.allSlide" :error-messages="getErrorMessages('allSlide')")
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      controls: {
        index: null
      },
      record: {},
      tranData: {}
    };
  },
  methods: {
    /**
     * 初期データ
     */
    initData(data) {
      return {
        orderId: data.item.data.orderId,
        orderType: data.item.data.orderType,
        scheduledLoadingDate: data.loadingDate,
        withAfter: false,
        cardId: data.item.data.cardId
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = {
        orderId: this.record.orderId,
        orderType: this.record.orderType,
        scheduledLoadingDate: this.record.scheduledLoadingDate,
        withAfter: this.record.allSlide,
        cardId: this.record.cardId
      };
    },
    /**
     * フォームの初期化
     * @param values
     */
    initForm(values) {
      this.record = Object.assign({}, this.initData(values));
      this.errors = {};
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      this.setTranData();
      this.saveRecord(this.tranData).then(() => {
        this.$refs.EditDialog.closeDialog();
        this.$emit("saved");
      });
    }
  }
};
</script>
