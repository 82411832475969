<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-card
        v-card-text
          v-layout()
            v-flex(md12)
              v-card
                v-card-text
                  OrderDeleteFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

      v-card
        BjiOrderDeleteListTable(ref="BjiOrderDeleteListTable" getListActionName="order/fetchAllBjiOrderDelete")

</template>

<script>
import BjiOrderDeleteListTable from "@/components/order/BjiOrderDeleteListTable";
import OrderDeleteFilterConditions from "@/components/order/OrderDeleteFilterConditions";

export default {
  components: {
    BjiOrderDeleteListTable,
    OrderDeleteFilterConditions
  },
  data() {
    return {
      displayConditions: false
    };
  },
  methods: {
    onChangeSearchConditions(searchCond) {
      this.$refs.BjiOrderDeleteListTable.resetPageCount();
      this.$refs.BjiOrderDeleteListTable.setSearchConditions(searchCond);
      this.$refs.BjiOrderDeleteListTable.loadOrders(searchCond);
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          transportType: "",
          loadingDate: null,
          loadingPlace: "",
          unloadingDate: null,
          unloadingPlace: "",
          orderDate: null,
          billingTypeName: "",
          distanceTypeName: ""
        }
      };
    }
  }
};
</script>

<style lang="stylus"></style>
