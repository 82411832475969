import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;
let $download = Axios.download;

const state = {
  paymentTariffRuleList: []
};

const getters = {};

const actions = {
  async fetchAllPaymentTariffRule({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getAllPaymentTariffRuleList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearAllPaymentTariffRuleData");
        }
        commit("appendAllPaymentTariffRuleData", value.data.data);
      });
  },
  async fetchPaymentTariffRule({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getAllPaymentTariffRule + id).then(value => {
      return value.data.data;
    });
  },
  // 実績出力
  async export({ commit, state }, getParams) {
    return $download.get(axiosBase.getUrls().getPaymentTariffRuleExport, {
      params: getParams
    });
  }
};

const mutations = {
  clearAllPaymentTariffRuleData(state) {
    state.paymentTariffRuleList = [];
  },
  appendAllPaymentTariffRuleData(state, data) {
    if (data != null) {
      state.paymentTariffRuleList = state.paymentTariffRuleList.concat(data);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
