<template lang="pug">
  v-dialog(v-model="controls.dialog" fullscreen persistent :width="width" @keydown.esc="closeDialog")
    v-card
      v-toolbar.header(fixed dark :color="$store.getters['site/siteColor']")
        v-toolbar-title 中継設定
        v-spacer
        v-toolbar-items
          v-btn(icon dark @click="closeDialog")
            v-icon close
      alert-box(ref="errorMsg")
      v-layout#schedule-relay-menu(justify-start row fill-height align-space-between ref="WrapLayout")
        v-flex.left-box
          v-card(height="100%")
            section.left-wrapper
              section.left-header-section
                v-toolbar.header(fixed dark color="grey")
                  v-toolbar-title 元カード
                  v-spacer
              section.left-body-section
                template(v-for="(base , index) in listBase")
                  v-card.relay-card(v-show="base.classifiedId != 0")
                    v-card-text
                      v-container(grid-list-md)
                        v-layout(justify-start row align-space-between)
                          v-flex.relay-option(xs12 md6)
                            v-text-field(readonly box label="車格" :value="base.requiredVehicleType + '(' + base.requiredCarryWeight + 'Kg)'")
                        v-layout(justify-start row align-space-between)
                          v-flex.relay-option(xs12 md5)
                            template(v-for="loadingBase in base.loadingBaseList")
                              v-layout(row wrap)
                                v-flex(xs12 style="position:relative")
                                  v-text-field(readonly box label="積地" :value="loadingBase.nameAndCd" hide-details)
                                  div.shipment-box(v-if="card[index] && findShipmentNo(card[index], loadingBase.id)")
                                    span.shipment-box-title シップメントNo
                                    span.shipment-box-no {{ findShipmentNo(card[index], loadingBase.id) }}

                          v-flex.relay-option(xs12 md1)
                            v-layout(align-center justify-center row fill-height)
                              v-icon(flat) arrow_forward_ios
                          v-flex.relay-option(xs12 md6)
                            template(v-for="unloadingBase in base.unloadingBaseNameAndCdList")
                              v-text-field(readonly box label="卸地" :value="unloadingBase")
                        v-layout(v-if="card[index]" justify-start row align-space-between)
                          //請求区分
                          v-flex.relay-option(xs12 md2)
                            BillingTypeSelect(ref="BillingTypeSelect" :code.sync="card[index].billingTypeId" :financeSetting="true" @changeFinance="changeBillingSrc" :errorHandlerMethod="getErrorMessages")
                          v-flex(md3)
                            DatePicker(pickerLabel="請求基準日" :inputDate.sync="card[index].billingBaseDate" initDate="" :isClearable="true" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'billingBaseDate'")
                          v-flex(xs3)
                            v-text-field(label="請求書用車番" v-model="card[index].manualEntryNo" maxlength="4"
                              :error-messages="getErrorMessages('srcItemList[' + index + '].manualEntryNo')")
                          v-flex(v-if="base.requiredVehicleUseChassis" xs3)
                            v-text-field(label="請求書用シャーシ番号" v-model="card[index].manualChassisNumber" maxlength="4"
                              :error-messages="getErrorMessages('srcItemList[' + index + '].manualChassisNumber')")
                          v-flex.relay-option(xs12 md2)
                            // 支払なし固定
                            v-text-field(readonly box label="支払区分" value="なし")
                        v-layout(v-if="card[index]" justify-start row align-space-between)
                          v-flex(md6)
                            v-text-field(readonly box label="荷主" :value="card[index].client.clientDisplayName")
                          v-flex(md6)
                            v-text-field(readonly box label="荷主枝番" :value="card[index].clientBranchName")

                        v-layout(v-if="card[index]" justify-start row align-space-between)
                          // カード備考
                          v-flex.relay-option(xs6)
                            v-textarea(label="カード備考" auto-grow v-model="card[index].remark" :error-messages="getErrorMessages('remark')")
                          // 会計備考
                          v-flex.relay-option(xs6)
                            v-textarea(label="会計備考" auto-grow v-model="card[index].financeRemark" :error-messages="getErrorMessages('financeRemark')")

        v-flex.center-box.justify-center
          v-icon(flat) arrow_forward_ios
          v-icon(flat) arrow_forward_ios
          v-icon(flat) arrow_forward_ios
        v-flex.right-box
          v-card(height="100%")
            section.right-wrapper
              section.right-header-section
                v-toolbar.header(fixed dark color="grey")
                  v-toolbar-title 先カード
                  v-spacer
              section.right-body-section(ref="RightBoxBody" )
                template(v-for="(record , index) in tranData")
                  v-card.relay-card
                    v-btn.btn-remove(v-show="index != 0" fab dark small color="red" @click="removeTran(index)")
                      v-icon(dark) remove
                    v-card-text
                      v-container(grid-list-md)

                        v-layout(justify-start row)
                          v-flex(xs12 md4)
                          v-flex(xs12 md8)
                            v-checkbox.mt-0(v-model="controls.filterSrc" label="元カードに依存" hide-details)
                        v-layout(justify-start row align-space-between)
                          //車格積載重量・車両オプション
                          v-flex.relay-option(xs12 md4)
                            VehicleCarryWeightSelect(:label.sync="record.details.firstItem.vehicleCarryWeight.carryWeight"
                            ,:code.sync="record.details.firstItem.vehicleCarryWeight.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'destItemList[' + index + '].vehicleCarryWeightId'")

                          v-flex.relay-option(xs12 md4)
                            ClientSelect(@changeItem="clearBaseList(index)" :filterSrc="controls.filterSrc" :clientIdList="getFilteredClientIdList" :label.sync="record.details.firstItem.client.clientDisplayName" :code.sync="record.details.firstItem.client.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'destItemList[' + index + '].clientId'")
                          v-flex.relay-option(xs12 md4)
                            ClientBranchSelect(@update:code="clearBaseList(index)" :filterSrc="controls.filterSrc" :clientBranchIdList="srcClientBranchFilterList" :isSelectBjiAll="true" :clientId="record.details.firstItem.client.id" :clientBranchId="record.details.firstItem.clientBranchId" :code.sync="record.details.firstItem.clientBranchId" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'destItemList[' + index + '].clientBranchId'")

                        div(v-for="(loading, index2) in record.orderLoadings.list")
                          v-layout(row wrap)
                            //積地
                            v-flex(xs12 md12)
                              LoadingBaseSelect(:clientId="record.details.firstItem.client.id" :clientBranchId="record.details.firstItem.clientBranchId" :excludeBaseIdList="loadingBaseIdList(index)" :enableDelete="(index2 == 0 ? false :true)" :label.sync="loading.loadingBase.baseName" :code.sync="loading.loadingBase.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'destItemList[' + index + '].loadingBaseList[' + index2 + '].baseId'" @deleteList="deleteLoadingBaseList(index, index2)")

                        v-layout(row wrap)
                          v-flex(xs12 md6)
                          v-icon keyboard_arrow_down

                        div(v-for="(unloading, index2) in record.orderUnloadings.list")
                          v-layout(row wrap)
                            //卸地
                            v-flex(xs12 md6)
                              UnloadingBaseSelect(:clientId="record.details.firstItem.client.id" :clientBranchId="record.details.firstItem.clientBranchId" :excludeBaseIdList="unloadingBaseIdList(index)" :enableDelete="(index2 == 0 ? false :true)" :label.sync="unloading.unLoadingBase.baseName" :code.sync="unloading.unLoadingBase.id" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'destItemList[' + index + '].unloadingBaseList[' + index2 + '].baseId'" @deleteList="deleteUnloadingBaseList(index, index2)" @setSelectData="data => unloading.specifiedTime = data.specifiedTime")

                            //時間指定(開始)
                            v-flex(xs12 md3)
                              TimePicker(pickerLabel="時間指定(開始)" :initTime="null" :inputTime.sync="unloading.specifiedTime.from" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'destItemList[' + index + '].unloadingBaseList[' + index2 + '].specifiedTimeFrom'" )

                            //時間指定(終了)
                            v-flex(xs12 md3)
                              TimePicker(pickerLabel="時間指定(終了)" :initTime="null" :inputTime.sync="unloading.specifiedTime.to" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodParam="'destItemList[' + index + '].unloadingBaseList[' + index2 + '].specifiedTimeTo'" )

                      v-btn(color="info" falt @click="addLoadingBase(index)" v-bind:disabled="loadingBaseLimit(index)") 積地を追加する
                      v-btn(color="info" falt @click="addUnloadingBase(index)" v-bind:disabled="unloadingBaseLimit(index)") 卸地を追加する
                      v-container(grid-list-md)
                        v-layout
                          v-flex(xs12 md2)
                            BillingTypeSelect(ref="BillingTypeSelect" :code.sync="record.details.firstItem.billingTypeId" :financeSetting="true" @changeFinance="changeBillingTran" :errorHandlerMethod="getErrorMessages" :errorHandlerMethodArg="'destItemList[' + index + '].billingTypeId'")
                          v-flex(xs12 md2)
                            PaymentTypeSelect(ref="PaymentTypeSelect" :code.sync="record.details.firstItem.paymentTypeId" :financeSetting="true" @changeFinance="changePaymentTran" :errorHandlerMethod="getErrorMessages")

                        v-layout(justify-start row align-space-between)
                          // カード備考
                          v-flex.relay-option(xs6)
                            v-textarea(label="カード備考" auto-grow v-model="record.details.firstItem.remark" :error-messages="getErrorMessages('remark')")
                          // 会計備考
                          v-flex.relay-option(xs6)
                            v-textarea(label="会計備考" auto-grow v-model="record.details.firstItem.financeRemark" :error-messages="getErrorMessages('financeRemark')")
                v-btn.btn-add(fab dark :color="$store.getters['site/siteColor']" @click="addTran")
                  v-icon(dark) add

      v-toolbar.footer
        v-spacer
        v-spacer
        v-btn.btn-save(color="green" dark @click="saveDialog") 中継作成

</template>

<script>
import TimePicker from "@/components/common/TimePicker";
import VehicleCarryWeightSelect from "@/components/common/select/VehicleCarryWeightSelect";
import LoadingBaseSelect from "@/components/common/select/LoadingBaseSelect";
import UnloadingBaseSelect from "@/components/common/select/UnloadingBaseSelect";
import BillingTypeSelect from "../common/select/BillingTypeSelect";
import PaymentTypeSelect from "../common/select/PaymentTypeSelect";
import ClientSelect from "@/components/common/select/ClientSelect";
import ClientBranchSelect from "@/components/common/select/ClientBranchSelect";
import DatePicker from "@/components/common/DatePicker";
import AlertBox from "@/components/common/AlertBox";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

import Axios from "@/assets/js/axios";
let $http = Axios.http;

export default {
  components: {
    TimePicker,
    VehicleCarryWeightSelect,
    LoadingBaseSelect,
    UnloadingBaseSelect,
    BillingTypeSelect,
    PaymentTypeSelect,
    AlertBox,
    ClientSelect,
    ClientBranchSelect,
    DatePicker
  },
  mixins: [ApiHandler, EditForm],
  props: {
    width: {
      type: String,
      required: false,
      default: "100%"
    }
  },
  data() {
    return {
      controls: {
        dialog: false,
        index: null,
        filterSrc: false
      },
      listBase: {},
      card: [],
      record: this.initData(),
      tranData: [],
      srcCardLength: 0,
      srcClientFilterList: [],
      srcClientBranchFilterList: []
    };
  },
  computed: {
    getFilteredClientIdList() {
      const list = this.srcClientFilterList.map(v => {
        return v.id;
      });
      return list;
    }
  },
  methods: {
    /**
     * 初期データ
     */
    initData() {
      return {
        orderLoadings: {
          list: []
        },
        orderUnloadings: {
          list: []
        },
        vehicleCarryWeightId: null,
        loadingBaseList: [],
        unloadingBaseList: [],
        details: {
          firstItem: {
            vehicleCarryWeight: {
              id: "",
              vehicleTypeName: ""
            },
            client: {
              id: null,
              clientDisplayName: null
            },
            clientBranchId: null,
            remark: null,
            financeRemark: null,
            billingTypeId: this.$store.state.site.global.billingTypeNoneId,
            paymentTypeId: this.$store.state.site.global.paymentTypeNormalId
          }
        }
      };
    },
    /**
     * 初期化処理
     */
    setTranDatas() {
      var retData = [];
      for (var i = 0; i < this.tranData.length; i++) {
        var record = this.tranData[i];
        retData[i] = {
          vehicleCarryWeightId: record.details.firstItem.vehicleCarryWeight.id,
          loadingBaseList: record.orderLoadings.list.map(value => {
            return {
              baseId: value.loadingBase.id,
              baseName: value.loadingBase.baseName
            };
          }),
          unloadingBaseList: record.orderUnloadings.list.map(value => {
            return {
              baseId: value.unLoadingBase.id,
              baseName: value.unLoadingBase.baseName,
              specifiedTimeFrom: value.specifiedTime.from,
              specifiedTimeTo: value.specifiedTime.to
            };
          }),
          remark: record.details.firstItem.remark,
          financeRemark: record.details.firstItem.financeRemark,
          clientId: record.details.firstItem.client.id,
          clientBranchId: record.details.firstItem.clientBranchId,
          billingTypeId: record.details.firstItem.billingTypeId,
          paymentTypeId: record.details.firstItem.paymentTypeId
        };
      }
      return retData;
    },
    /**
     * 積地初期値
     */
    loadingDefault() {
      return {
        id: null,
        loadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        }
      };
    },
    /**
     * 卸地初期値
     */
    unloadingDefault() {
      return {
        id: null,
        unLoadingBase: {
          id: null,
          baseCd: null,
          baseName: null
        },
        specifiedTime: {
          from: null,
          to: null
        },
        loadingDate: null
      };
    },
    /**
     * 積地フォームの追加
     */
    addLoadingBase(index) {
      this.tranData[index].orderLoadings.list.push(this.loadingDefault());
      this.tranData.splice();
    },
    /**
     * 積地フォームの追加
     */
    addUnloadingBase(index) {
      this.tranData[index].orderUnloadings.list.push(this.unloadingDefault());
      this.tranData.splice();
    },
    /**
     * 積地フォームの削除
     */
    deleteLoadingBaseList(index, index2) {
      this.tranData[index].orderLoadings.list.splice(index2, 1);
      this.tranData.splice();
    },
    /**
     * 卸地フォームの削除
     */
    deleteUnloadingBaseList(index, index2) {
      this.tranData[index].orderUnloadings.list.splice(index2, 1);
      this.tranData.splice();
    },
    /**
     * 荷主変更後、積地卸地クリア
     */
    clearBaseList(index) {
      this.clearLoadingBaseList(index);
      this.clearUnloadingBaseList(index);
    },
    clearLoadingBaseList(index) {
      this.tranData[index].orderLoadings.list = [];
      this.addLoadingBase(index);
    },
    clearUnloadingBaseList(index) {
      this.tranData[index].orderUnloadings.list = [];
      this.addUnloadingBase(index);
    },
    loadingBaseIdList(index) {
      return this.tranData[index].orderLoadings.list
        .filter(loading => loading.loadingBase.id != null)
        .map(loading => loading.loadingBase.id);
    },
    unloadingBaseIdList(index) {
      return this.tranData[index].orderUnloadings.list
        .filter(unloading => unloading.unLoadingBase.id != null)
        .map(unloading => unloading.unLoadingBase.id);
    },
    loadingBaseLimit(index) {
      return this.tranData[index].orderLoadings.list.length >= 5;
    },
    unloadingBaseLimit(index) {
      return this.tranData[index].orderUnloadings.list.length >= 5;
    },
    changeBillingTran(id) {
      this.tranData.forEach(v => (v.details.firstItem.billingTypeId = id));
      this.card.forEach(v => (v.billingTypeId = this.$store.state.site.global.billingTypeNoneId));
    },
    changeBillingSrc(id) {
      this.card.map(v => (v.billingTypeId = id));
      this.tranData.map(
        v => (v.details.firstItem.billingTypeId = this.$store.state.site.global.billingTypeNoneId)
      );
    },
    changePaymentTran(id) {
      this.tranData.forEach(v => (v.details.firstItem.paymentTypeId = id));
    },
    canEditStatus(list) {
      var filtered = list.filter(
        v =>
          v.billingStatus === Enum.BillingStatusType.CONFIRMED.code ||
          v.billingStatus === Enum.BillingStatusType.PUBLISHED.code ||
          v.paymentStatus === Enum.PaymentStatusType.CONFIRMED.code ||
          v.paymentStatus === Enum.PaymentStatusType.PUBLISHED.code
      );
      return filtered.length === 0;
    },
    /**
     * フォームの初期化
     */
    initForm() {
      this.tranData = [];
      this.addTran();

      this.errors = {};
      this.$refs.errorMsg.nonDisplayMessage();
    },
    openDialog(listBase, loadingDate) {
      this.initForm();
      this.listBase = listBase;
      this.loadingDate = loadingDate;
      // 元カード情報取得
      this.getRelayDetail(listBase, listBase.length);
    },
    async getRelayDetail(listBase, cardLength) {
      let list = [];
      await Promise.all(
        listBase.map(async base => {
          await this.$store
            .dispatch("dispatch/fetchDispatchRelayCardDetail", {
              orderType: base.orderType,
              orderId: base.orderId
            })
            .then(value => {
              if (value) {
                //デフォルト車番値セット
                value.manualEntryNo = "0000";
                if (base.requiredVehicleUseChassis) {
                  value.isTrailer = true;
                  value.manualChassisNumber = "0000";
                }
                this.card.push(value);
                list.push(value);
                this.sortCardDetail();
                this.addSrcClientAndBranchList(this.tranData.length - 1, cardLength);
              }
            });
        })
      );
      if (!this.canEditStatus(list)) {
        this.$emit("openErrorMsgBox", "確定済、発行済のカードが含まれるため中継出来ません。");
        this.controls.dialog = false;
      } else {
        this.controls.dialog = true;
      }
    },
    sortCardDetail() {
      if (!this.card) return;
      var sortedCard = [];
      for (var i in this.listBase) {
        for (var idx in this.card) {
          if (
            this.listBase[i].orderType === this.card[idx].orderType &&
            this.listBase[i].orderId === this.card[idx].id
          ) {
            sortedCard.push(this.card[idx]);
          }
        }
      }
      this.card = sortedCard;
    },
    closeDialog() {
      this.card = [];
      this.controls.dialog = false;
      this.srcCardLength = 0;
      this.srcClientFilterList = [];
      this.srcClientBranchFilterList = [];
    },
    saveDialog() {
      let item = this.setTranDatas();
      if (item != null && item.length > 0) {
        let i = 0;
        let relaySrcParams = [];
        for (i = 0; i < this.listBase.length; i++) {
          relaySrcParams.push({
            cardId: this.listBase[i].cardId,
            orderId: this.listBase[i].orderId,
            orderType: this.listBase[i].orderType,
            remark: this.card[i].remark,
            financeRemark: this.card[i].financeRemark,
            billingBaseDate: this.card[i].billingBaseDate ? this.card[i].billingBaseDate : null,
            manualEntryNo: this.card[i].manualEntryNo ? this.card[i].manualEntryNo : null,
            manualChassisNumber: this.card[i].manualChassisNumber ? this.card[i].manualChassisNumber : null,
            // シャーシ必須検証用
            vehicleType: this.card[i].isTrailer ? this.listBase[i].requiredVehicleType : null,
            billingTypeId: this.card[i].billingTypeId
          });
        }
        let relayDestParams = [];
        for (i = 0; i < item.length; i++) {
          let j = 0;
          let loadingBaseList = [];
          let unloadingBaseList = [];
          for (j = 0; j < item[i].loadingBaseList.length; j++) {
            loadingBaseList.push({
              baseId: item[i].loadingBaseList[j].baseId
            });
          }
          for (j = 0; j < item[i].unloadingBaseList.length; j++) {
            unloadingBaseList.push({
              baseId: item[i].unloadingBaseList[j].baseId,
              specifiedTimeFrom: item[i].unloadingBaseList[j].specifiedTimeFrom,
              specifiedTimeTo: item[i].unloadingBaseList[j].specifiedTimeTo,
              unloadingOrder: j + 1
            });
          }
          relayDestParams.push({
            vehicleCarryWeightId: item[i].vehicleCarryWeightId,
            remark: item[i].remark,
            financeRemark: item[i].financeRemark,
            loadingDate: this.loadingDate,
            unloadingDate: this.loadingDate,
            loadingBaseList: loadingBaseList,
            unloadingBaseList: unloadingBaseList,
            billingTypeId: item[i].billingTypeId,
            paymentTypeId: item[i].paymentTypeId,
            clientId: item[i].clientId,
            clientBranchId: item[i].clientBranchId
          });
        }
        let postData = {
          scheduledLoadingDate: this.loadingDate,
          srcItemList: relaySrcParams,
          destItemList: relayDestParams
        };
        const self = this;
        $http
          .post(self.$config.urls.postDispatchRelay, postData)
          .then(() => {
            this.$emit("saved");
            this.tranData = [];
            this.controls.dialog = false;
            this.$emit("hideRelay", true);
          })
          .catch(error => {
            if (error.response.data["fieldErrors"]) {
              self.setErrorMessages(error);
            } else {
              this.$refs.errorMsg.displayMessage(error.response.data.message);
            }
          });
      }
    },
    addTran() {
      this.tranData.push(this.initData());
      this.addLoadingBase(this.tranData.length - 1);
      this.addUnloadingBase(this.tranData.length - 1);
      this.addDefaultClientAndBranch(this.tranData.length - 1);
      this.$nextTick(() => {
        this.$refs.RightBoxBody.scrollTop = 0;
      });
    },
    removeTran(index) {
      this.tranData.splice(index, 1);
    },
    joinVehicleName(name, weight) {
      return name + weight;
    },
    findShipmentNo(card, baseId) {
      if (!card.details) return null;
      if (card.details.list.length > 0) {
        const findBase = card.details.list.filter(value => value.loadingBase.id === baseId).pop();

        return findBase !== undefined ? findBase.shipmentNo : null;
      } else {
        return null;
      }
    },
    addSrcClientAndBranchList(index, cardLength) {
      if (!this.card) return null;
      this.srcCardLength = this.srcCardLength + 1;
      this.card.forEach(data => {
        if (this.srcClientFilterList.filter(client => client.id === data.client.id).length < 1) {
          this.srcClientFilterList.push(Object.assign({}, data.client));
        }
        if (this.srcClientBranchFilterList.filter(id => id === data.clientBranchId).length < 1) {
          this.srcClientBranchFilterList.push(data.clientBranchId);
        }
      });
      // 全元カード取得してから
      if (cardLength === this.srcCardLength) {
        this.addDefaultClientAndBranch(index);
      }
    },
    addDefaultClientAndBranch(index) {
      if (this.srcClientFilterList.length === 1) {
        this.tranData[index].details.firstItem.client = this.srcClientFilterList[0];
        this.tranData.splice();
      }
      if (this.srcClientBranchFilterList.length === 1) {
        this.tranData[index].details.firstItem.clientBranchId = this.srcClientBranchFilterList[0];
        this.tranData.splice();
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.header {
  position: sticky;
  top: 0;
}
.footer {
  position: fixed;
  bottom: 0;
}

.shipment-box {
  float: right;
  z-index: 2;
  padding: 8px 0 8px 0;

  .shipment-box-title {
    margin-right: 8px;
  }

  .shipment-box-no{
    font-size 1.2em
    font-weight bold
    color rgba(0,0,0,0.65)
  }
}
</style>
