<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      PaymentTypeFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-title
              PaymentTypeTable(ref="PaymentTypeTable" v-bind:prop-header="paymentTypeTableHeader"  v-bind:prop-data="paymentTypeList" detail-url="paymentType/fetchPaymentType" @open-detail="openDetail" :getListActionName="getListActionName" :displaySlotBody="true")
                template(v-slot:body="{ body }")
                    td.text-xs-left {{ body.paymentCd }}
                    td.text-xs-left {{ body.paymentTypeName }}
                    td.text-xs-left {{ getFinanceType(body.financeType) }}

    PaymentTypeEditDialog(ref="PaymentTypeEditDialog" :postUrl="$config.urls.postEditPaymentType" :deleteUrl="$config.urls.deletePaymentType" @updated="itemUpdated" @deleted="itemUpdated")
</template>

<script>
import PaymentTypeEditDialog from "@/components/management/payment-type/PaymentTypeEdit";
import PaymentTypeTable from "@/components/common/PagingListTable";
import PaymentTypeFilterConditions from "@/components/management/payment-type/PaymentTypeFilterConditions";
import { mapGetters } from "vuex";

export default {
  components: {
    PaymentTypeEditDialog,
    PaymentTypeTable,
    PaymentTypeFilterConditions
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getFinanceType: "site/financeTypeLabel"
    }),
    getListActionName: {
      get() {
        return "paymentType/fetchAllPaymentType";
      }
    },
    paymentTypeList: {
      get() {
        return this.$store.state.paymentType.paymentTypeList;
      }
    },
    paymentTypeTableHeader: {
      get() {
        return [
          { text: "支払CD", value: "paymentCd", centerAlign: "" },
          { text: "名称", value: "paymentTypeName", centerAlign: "" },
          { text: "会計種別", value: "financeType", centerAlign: "" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openAddDialog() {
      this.$refs.PaymentTypeEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.PaymentTypeEditDialog.openDialog(item);
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.PaymentTypeTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.PaymentTypeTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.PaymentTypeTable.setSearchConditions(searchCond);
      this.$refs.PaymentTypeTable.resetPageCount();
      this.loadListData();
    }
  }
};
</script>
