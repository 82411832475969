import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";

let axiosBase = new AxiosBase();
let $axios = Axios.http;

const state = {
  // 運行リソース表示対象の車格
  displayVehicleTypeList: [],
  orderQuantityList: [],
  vehicleResourceQuantityList: [],
  driverVehicleQuantityList: [],
  driverQuantityList: [],
  exceedQuantityList: []
};

const methods = {
  filterList: (list, targetDate) => {
    return list
      .filter(driver => {
        return driver.targetDate === targetDate;
      })
      .map(item => {
        return item.list;
      })
      .pop();
  },
  filterQuantities: (list, contractCd) => {
    return list
      .filter(data => {
        if (contractCd != null) {
          return String(data.contractType) === String(contractCd);
        } else {
          return data.contractType !== null;
        }
      })
      .map(rec => {
        return rec.quantities;
      })
      .flat();
  },
  filterShift: (list, shiftCd) => {
    return list.filter(data => {
      return String(data.shiftType) === String(shiftCd);
    });
  },
  summaryQuantity: list => {
    return list.reduce((accumulator, currentValue) => {
      accumulator += currentValue.quantity;
      return accumulator;
    }, 0);
  }
};

const getters = {
  getVehicleTypeList: state => {
    return state.displayVehicleTypeList;
  },
  getOrderQuantityFromDateAndVehicleType:
    state =>
    ({ targetDate, vehicleCd }) => {
      return state.orderQuantityList
        .filter(order => {
          return order.targetDate === targetDate;
        })
        .map(order => {
          return order.quantities
            .filter(item => {
              return String(item.vehicleTypeCd) === String(vehicleCd);
            })
            .map(order => {
              return order.quantity;
            })
            .pop();
        })
        .pop();
    },
  getDriverCount:
    state =>
    ({ targetDate, shiftCd, contractCd }) => {
      const filteredDriver = methods.filterList(state.driverQuantityList, targetDate);
      const filteredQuantities = methods.filterQuantities(filteredDriver, contractCd);

      const shiftQuantities = methods.filterShift(filteredQuantities, shiftCd);

      return methods.summaryQuantity(shiftQuantities);
    },

  getVehicleCount:
    state =>
    ({ targetDate, vehicleCd }) => {
      const filteredDriver = state.vehicleResourceQuantityList
        .filter(value => {
          return value.targetDate === targetDate;
        })
        .pop();

      const filteredQuantities = filteredDriver.quantities.filter(data => {
        return String(data.vehicleTypeCd) === String(vehicleCd);
      });

      return methods.summaryQuantity(filteredQuantities);
    },

  getDrivableVehicleCount:
    state =>
    ({ targetDate, shiftCd, contractCd, vehicleCd }) => {
      const filteredDriver = methods.filterList(state.driverVehicleQuantityList, targetDate);
      const filteredQuantities = methods.filterQuantities(filteredDriver, contractCd);
      const shiftQuantities = methods.filterShift(filteredQuantities, shiftCd);

      const filteredVehicles = shiftQuantities
        .map(data => {
          return data.quantities;
        })
        .flat()
        .filter(data => {
          return String(data.vehicleTypeCd) === String(vehicleCd);
        });

      return methods.summaryQuantity(filteredVehicles);
    },

  getExceedCount:
    state =>
    ({ targetDate, contractCd, vehicleCd }) => {
      const filteredDriver = methods.filterList(state.exceedQuantityList, targetDate);
      const filteredQuantities = methods.filterQuantities(filteredDriver, contractCd);

      const filteredVehicles = filteredQuantities.filter(data => {
        return String(data.vehicleTypeCd) === String(vehicleCd);
      });

      return methods.summaryQuantity(filteredVehicles);
    }
};

const actions = {
  async fetchAllOperationResource({ commit, state }, getParams) {
    return $axios
      .get(axiosBase.getUrls().getAllOperationResourceList, {
        params: getParams
      })
      .then(value => {
        commit("writeOperationResourceData", value.data.data);
      });
  }
};

const mutations = {
  writeOperationResourceData(state, data) {
    //表示対象の車格リストの取得
    state.displayVehicleTypeList = data.vehicleTypes == null ? [] : data.vehicleTypes.list;
    state.orderQuantityList = data.combineOrderQuantity == null ? [] : data.combineOrderQuantity;
    state.vehicleResourceQuantityList =
      data.vehicleResourceQuantity == null ? [] : data.vehicleResourceQuantity;

    state.driverVehicleQuantityList = data.driverVehicleQuantity == null ? [] : data.driverVehicleQuantity;
    state.driverQuantityList = data.driverQuantity == null ? [] : data.driverQuantity;
    state.exceedQuantityList = data.exceedQuantity == null ? [] : data.exceedQuantity;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
