<template lang="pug">
  v-layout(row wrap)
    EmergencyErrorDialog(ref="errorMsg")
    DriverShiftPicker(ref="DriverShiftPicker" @selectShiftType="shiftSelect")
    v-flex(xs12)
      v-card
        v-card-title
          v-layout(row wrap)
            v-spacer
            v-flex(xs4)
              v-layout(row wrap fill-height align-center)
                v-flex(xs2)
                  ReloadButton(@callReload="clickReloadButtonForSchedule")
                v-flex(xs1)
                  v-icon(v-on:click="changeBaseDate(-1)") arrow_back_ios
                v-flex(xs8)
                  baseDatePicker(
                    :inputDate.sync="baseDate"
                    picker-label="表示起点日"
                  )
                v-flex(xs1)
                  v-icon.ml-3(v-on:click="changeBaseDate(1)") arrow_forward_ios

          v-flex(xs12)
            v-card
              v-card-text
                DriverScheduleListFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

        v-card-text
          table
            thead
              tr
                th(v-for="(header) in shiftHeader")
                  div(:class="[css.headerClass, header.holiday ? css.sundayClass : css.otherDayClass]") {{ header.text }}

            tbody(v-show="driverList.length")
              tr(v-for="driverData in driverList" :class="{'record-disabled':isDisabled(driverData)}")
                td.text-xs-left {{ getContractType(driverData.contractType) }}
                td.text-xs-left {{ driverData.drivableVehicleTypes }}
                td.text-xs-left {{ driverData.firstStructuredCompanyName }}
                td.text-xs-left {{ driverData.deliveryCompanyName }}
                td.text-xs-left {{ driverData.name }}
                td.text-xs-center(
                  v-for="driverShiftData in driverData.driverShiftList"
                  :key="driverShiftData.id"
                  :class="[driverShiftData.holiday ? css.sundayClass : css.otherDayClass]"
                  )
                  v-icon(:color="getShiftType(driverShiftData.shiftType).color",
                    @click="openShiftMenu($event,driverShiftData,driverData)") {{ getShiftType(driverShiftData.shiftType).icon }}
            tbody(v-show="!driverList.length")
              tr
                td(:colspan="shiftHeader.length") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import { mapGetters } from "vuex";
import ListTable from "@/components/common/ListTable";
import BaseDatePicker from "@/components/common/DatePicker";
import DriverScheduleListFilterConditions from "@/components/management/driver/schedule/driver-list/DriverScheduleListFilterConditions";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";
import DriverShiftPicker from "@/components/common/DriverShiftPicker";
import ApiHandler from "@/mixin/ApiHandler";
import EventDispatcher from "@/mixin/EventDispatcher";
import InfiniteScrollHandler from "@/mixin/InfiniteScrollHandler";
import ReloadButton from "@/components/common/buttons/ReloadButton";

import _ from "lodash";
import ShiftTypeMenu from "@/mixin/ShiftTypeMenu";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    ListTable,
    BaseDatePicker,
    DriverScheduleListFilterConditions,
    DriverShiftPicker,
    EmergencyErrorDialog,
    ReloadButton
  },
  mixins: [ApiHandler, EventDispatcher, InfiniteScrollHandler, ShiftTypeMenu],
  data() {
    let headerCss = "py-3 th-text font-weight-thin grey--text text--darken-1";
    let sundayCss = "sunDay";
    let otherDayCss = "otherDay";
    return {
      baseDate: "",
      shiftDayList: [],
      searchCond: {},
      target: {
        record: {}
      },
      css: {
        headerClass: headerCss,
        sundayClass: sundayCss,
        otherDayClass: otherDayCss
      }
    };
  },
  methods: {
    shiftSelect(selectItem) {
      this.target.record.shiftType = selectItem.code;
      const self = this;
      this.saveRecord(this.target.record).then(() => {
        self.loadData();
      });
    },
    openShiftMenu(event, shift, driver) {
      this.target.record = shift;
      //シフトが存在しない場合シフトのdriverIDがNULLなので補完する
      if (!this.target.record.driverId) {
        this.target.record.driverId = driver.id;
      }
      this.$refs.DriverShiftPicker.open(event);
    },
    /**
     * 表示起点日の変更
     * @param changeDays
     */
    changeBaseDate(changeDays) {
      const date = new Date(this.baseDate);
      date.setDate(date.getDate() + changeDays);
      this.baseDate = window.Common.Util.getYearMonthDayIsoFormat(date);
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.resetPageCount();
      this.setSearchConditions(
        Object.assign(searchCond, {
          baseDate: this.baseDate
        })
      );
      this.loadData();
    },
    /**
     * 保存処理
     */
    save(record, driverId) {
      const self = this;
      record.driverId = driverId;
      this.saveRecord(record).then(() => {
        self.loadData();
      });
    },
    openErrorMsgBox(message) {
      this.$refs.errorMsg.open(message);
      this.loadData();
    },
    /**
     * リロードボタン押下
     */
    clickReloadButtonForSchedule() {
      this.loadData();
    },
    isDisabled(item) {
      if (item.status === undefined) return false;

      return item.status === Enum.ActiveFlag.InActive.code;
    }
  },
  computed: {
    ...mapGetters({
      getContractType: "site/contractTypeFromCd"
    }),
    shiftHeader: {
      get() {
        const head = [
          { text: "契約" },
          { text: "運転可能車格" },
          { text: "1次委託先会社" },
          { text: "所属企業" },
          { text: "氏名" }
        ];
        this.shiftDayList.forEach(shiftDay => head.push(shiftDay));
        return head;
      }
    },
    calcShiftDay: {
      get() {
        const shiftDayList = [];
        if (_.isEmpty(this.baseDate)) return shiftDayList;

        const currentDate = new Date(this.baseDate);
        for (let i = 0; i < 7; i++) {
          const shiftDay = {
            text:
              currentDate.getMonth() +
              1 +
              "/" +
              currentDate.getDate() +
              "(" +
              "日月火水木金土"[currentDate.getDay()] +
              ")",
            holiday: currentDate.getDay() === 0
          };
          shiftDayList.push(shiftDay);
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return shiftDayList;
      }
    },
    driverList: {
      get() {
        return this.$store.state.driver.driverListForSchedule;
      }
    }
  },
  mounted() {
    this.shiftDayList = this.calcShiftDay;
  },
  watch: {
    baseDate() {
      this.pageCount = 0;
      this.searchConditions = Object.assign(this.searchConditions, {
        baseDate: this.baseDate
      });
      this.loadData().finally(() => {
        this.shiftDayList = this.calcShiftDay;
      });
    }
  }
};
</script>

<style scoped lang="stylus">
thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 45px;
  z-index: 1;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
}

tr:not(.record-disabled){
  .sunDay {
    background-color: #ffebee !important;
  }
}

.otherDay {
}
</style>
