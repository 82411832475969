<template lang="pug">
  EditDialog(ref="EditDialog" title="確定済履歴" :hasTitleSuffix="false" width="70%"
    :displayDeleteButton="false" :enableSave="false"
    @closeEditDialog="closeEditDialog")
    template(v-slot:body)
      v-layout(row wrap)
        v-flex(xs12)
          v-container(grid-list-md)
            v-layout(row wrap)
              v-flex(xs6)
                v-text-field(:value="getManualOrderSelectHistoryBase.clientName + '/' + getManualOrderSelectHistoryBase.clientBranchName" label="荷主/荷主枝番" box readonly hide-details)
            v-layout(row wrap)
              v-flex(xs6)
                template(v-for="loadingBase in getManualOrderSelectHistoryBase.loadingBaseNames")
                  v-text-field.whitespace-pre.mb-2(v-if="loadingBase" :value="loadingBase" label="積地" box readonly hide-details)
              v-flex(xs6)
                template(v-for="unloadingBase in getManualOrderSelectHistoryBase.unloadingBaseNames")
                  v-text-field.whitespace-pre.mb-2(v-if="unloadingBase" :value="unloadingBase" label="卸地" box readonly hide-details)

        v-flex(xs12)
          section.history-scroll(ref="historyArea")
            table.finance-summary-table(class="sticky_table order-table")
              thead
                th 請求年月
                th 車格
                th 積込日
                template(v-for="header in getBillingItemList")
                  th {{ header.billItemName }}
              tbody
                template(v-for="item in getBillingHistoryList" )
                  tr(@dblclick="selectRow(item.billingCardDetails)").pointer-dblclick
                    td.text-xs-center {{ item.billingTargetYearMonth }}
                    td {{ item.billingVehicleTypeName ? item.billingVehicleTypeName : item.cardVehicleTypeName }}
                    td.text-xs-center {{ item.shipmentDate }}
                    template(v-for="detail in item.billingCardDetails")
                      td.text-xs-right
                        DigitSeparatorsLabel(:value="detail.billingItemPrice")
                tr(v-show="getBillingHistoryList.length === 0")
                  td.text-xs-left(:colspan="3 + getBillingItemList.length") {{ getNoResultMessage }}


</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import { mapActions, mapGetters, mapMutations } from "vuex";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import { LimitNumber } from "@/assets/js/constants";

export default {
  components: {
    EditDialog,
    DigitSeparatorsLabel
  },
  data() {
    return {
      progress: false
    };
  },
  computed: {
    ...mapGetters("finance", {
      getBillingHistoryHeader: "getBillingHistoryHeader",
      getBillingHistoryList: "getBillingHistoryList",
      getManualOrderSelectHistoryBase: "getManualOrderSelectHistoryBase"
    }),
    ...mapGetters("billingItem", {
      getBillingItemList: "getList"
    }),
    getNoResultMessage: {
      get() {
        return this.$config.messages.Common.Warn.SearchResultNotFound;
      }
    }
  },
  mounted() {
    this.$refs.historyArea.removeEventListener("scroll", this.handleScrollHistory);
    this.$refs.historyArea.addEventListener("scroll", this.handleScrollHistory);
  },
  methods: {
    ...mapActions("finance", {
      fetchBillingCardHistory: "fetchBillingCardHistory"
    }),
    ...mapMutations("finance", {
      mergeBillingDetailPrice: "mergeBillingDetailPrice",
      incrementHistoryTablePageCount: "incrementHistoryTablePageCount",
      clearHistoryTablePageCount: "clearHistoryTablePageCount"
    }),
    openDialog() {
      this.$refs.EditDialog.openDialog();
      this.clearHistoryTablePageCount();
      this.fetchBillingCardHistory();
      this.$store.dispatch("billingItem/fetchAllDisplayBillingItem");
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    closeEditDialog() {
      this.$refs.historyArea.scrollTop = 0;
    },
    selectRow(data) {
      this.mergeBillingDetailPrice(data);
      this.closeDialog();
    },
    /**
     * 履歴スクロールイベント
     */
    handleScrollHistory() {
      if (this.$refs.historyArea !== undefined) {
        const clientHeight = this.$refs.historyArea.clientHeight;
        const scrollTop = this.$refs.historyArea.scrollTop;
        const scrollHeight = this.$refs.historyArea.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber && !this.progress) {
          this.progress = true;
          this.incrementHistoryTablePageCount();
          this.fetchBillingCardHistory().finally(() => {
            this.progress = false;
          });
        }
      }
    }
  }
};
</script>

<style scoped lang="stylus">
.history-scroll {
  max-height 500px;
  overflow-y scroll
}

.sticky_table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.order-table {
  th, td {
    padding 8px
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }

  th {
    text-align center
    font-size 1.1em
  }

  td {
    width 180px
  }
}
</style>
