<template lang="pug">
  v-icon(v-if="!hasSlideRemark && hasRemark" color="blue darken-3") event_note
  v-icon(v-else-if="hasSlideRemark" color="#FF5722") event_note
  v-icon(v-else) event_note
</template>

<script>
export default {
  props: {
    hasRemark: {
      type: Boolean,
      default: () => false
    },
    hasSlideRemark: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>
