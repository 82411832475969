<template lang="pug">
  div(v-if="isDisplay")
    v-tooltip(top)
      template(v-slot:activator="{ on }")
        v-icon(v-on="on").warning-box-icon warning
      span.whitespace-pre {{ displayValidate(validationError) }}
</template>

<script>
export default {
  props: {
    validationError: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  methods: {
    displayValidate(value) {
      let list = [];
      Object.entries(value).forEach(value => {
        list.push(value[0] + " : " + value[1]);
      });
      return list.join("\n");
    }
  },
  computed: {
    isDisplay() {
      return Object.keys(this.validationError).length > 0;
    }
  }
};
</script>

<style scoped lang="stylus">
.warning-box-icon {
  color: #FF5722;
}
</style>
