<template lang="pug">
  v-dialog(v-model="dialog" persistent width="500" @keydown.esc="closeDialog")
    v-card
      v-toolbar(dark :color="$store.getters['site/siteColor']")
        v-icon(left color="white darken-2" large) help
        span  {{ title }}
        v-spacer
        v-btn(flat icon @click="closeDialog")
          v-icon close

      v-card-text
        v-layout
          v-flex
            div(class="ma-3" v-html="message" )

      v-toolbar.footer
        v-spacer
        v-btn(color="" @click="no") キャンセル
        v-btn(color="error" @click="yes") {{buttonTitle}}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "削除確認"
    },
    message: {
      type: String,
      required: false,
      default: "削除しますか？"
    },
    buttonTitle: {
      type: String,
      required: false,
      default: "削除する"
    }
  },
  data() {
    return {
      dialog: false,
      text: null
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    no() {
      this.$emit("no");
      this.closeDialog();
    },
    yes() {
      this.$emit("yes");
      this.closeDialog();
    }
  }
};
</script>
