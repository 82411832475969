<template lang="pug">
  v-flex(xs12 v-show="hadData")
    table(class="sticky_table" v-if="getValidationErrorList.errors.length != 0")
      tbody
        tr
          v-alert(type="warning" value="true" @click="toggleErrorData()") {{ "取り込みデータのエラー" }}
        tr(v-show="validationErrorList.isDisplay")
          td(colspan="3")
            section.detail_box
              table(class="order-table")
                thead
                  tr
                    th エクセル行番号
                    th エラー項目
                    th 内容
                tbody
                  tr(v-for="validError in validationErrorList.errors")
                    td.text-md-left {{ validError.lineNo + "行目" }}
                    td.text-whitespace-pre.text-md-left {{ Object.keys(validError.error) | commaToNr }}
                    td.text-whitespace-pre.text-md-left {{ displayValue(validError.error) }}

    AlertBox(ref="errorMsg")
    v-card
      v-card-title(class="header-summary")
        table(class="header-summary-table")
          tr
            th 荷主枝番
            td {{ records.clientBranchName }}
            th 距離区分
            td {{ records.distanceTypeLabel }}
            th {{ labelName.LoadingSchedule }}
            td {{ records.loadingDate }}
            th 伝票数
            td {{ getShipCount }}
            th DriverName数
            td {{ getDriverNameCount }}
            th ルートNo数
            td {{ getRouteNoCount }}
            th ケース数
            td {{ getCaseCount }}
            th(colspan=12 align='right')
              v-btn(color="success" @click="commit" class="commit-btn" :loading="loading.commit") 取込確定
      v-card-text
        table(class="sticky_table")
          thead
            tr
              th.no-right-border(width="20px")
              th(width="25px") No
              th DriverName
              th(width="65px") 取込対象
          tbody
            template(v-for="(driverRec, indexParent) in driverNameSummary" )
              tr(:class="{ selected : driverRec.isImportTarget }")
                td.no-right-border(class="toggleCell" @click="toggleDetail(driverRec)")
                  v-btn(flat icon v-show="!driverRec.isDisplayOrderList")
                    v-icon() keyboard_arrow_up
                  v-btn(flat icon v-show="driverRec.isDisplayOrderList")
                    v-icon() keyboard_arrow_down
                td(@click="toggleDetail(driverRec)") {{ indexParent + 1 }}
                td(@click="toggleDetail(driverRec)")
                  section(class="detail-title")
                    span {{ driverRec.driverName }}
                td
                  v-checkbox.targetSelectCell(v-model="driverRec.isImportTarget")
              tr(v-show="driverRec.isDisplayOrderList" )
                td.detail-td(colspan="4")
                  section.detail_box
                    table(class="order-table")
                      thead
                        tr
                          th No
                          th 車格
                          th 距離区分
                          th {{ labelName.LoadingSchedule }}
                          th {{ labelName.UnloadingSchedule }}
                          th プランボード番号
                          th ルート番号
                          th 積地
                          th 卸地
                      tbody
                        tr(v-for="(orderRec,indexChild) in driverRec.list" @click="openDriverNameEditDialog(orderRec)")
                          td.text-md-center {{ indexParent + 1 }} - {{ indexChild + 1 }}
                          td.text-md-left {{ getVehicleTypeLabel(orderRec.calcVehicleCarryWeightId) }}
                          td.text-md-left {{ getDistance( orderRec.distanceType ) }}
                          td.text-md-center {{ orderRec.loadingDate }}
                          td.text-md-center {{ orderRec.unloadingDate }}
                          td.text-md-left {{ orderRec.planBoardNo }}
                          td.text-md-left {{ orderRec.routeNo }} - {{ orderRec.routeNoSeq }}
                          td.text-whitespace-pre.text-md-left {{ orderRec.details.shippingNames | commaToNr }}
                          td.text-whitespace-pre.text-md-left {{ orderRec.details.clientNames | commaToNr }}

            DriverNameEditDialog(ref="DriverNameEditDialog" :postUrl="$config.urls.postSaveDriverName" @updated="loadData")
</template>

<script>
import { mapGetters } from "vuex";
import { LabelName } from "@/assets/js/constants";
import DriverNameEditDialog from "@/components/import/DriverNameEdit";
import AlertBox from "@/components/common/AlertBox";

export default {
  components: {
    DriverNameEditDialog,
    AlertBox
  },
  data() {
    return {
      labelName: LabelName,
      loading: {
        commit: false
      },
      validationErrorList: {
        isDisplay: true,
        errors: []
      },
      records: {
        driverNameSummary: [],
        clientBranchName: null,
        distanceTypeLabel: null,
        list: []
      },
      driverNameEditItem: {
        planBoardNo: "",
        routeNo: "",
        routeNoSeq: "",
        driverName: "",
        totalGrossWeight: ""
      },
      exclusiveError: {
        message: null
      }
    };
  },
  methods: {
    /**
     * DriverNameに紐付くルートNoリストの表示非表示を切り替える
     * @param rec
     */
    toggleDetail(rec) {
      rec.isDisplayOrderList = !rec.isDisplayOrderList;
    },
    toggleErrorData() {
      this.validationErrorList.isDisplay = !this.validationErrorList.isDisplay;
    },
    displayValue(value) {
      let list = [];
      Object.values(value).forEach(value => {
        list.push(value.join(","));
      });
      return list.join("\n");
    },
    loadData() {
      const self = this;
      this.$store.dispatch("order/fetchAllImportWorkOrder").then(value => {
        if (value == null) {
          return;
        }
        self.records = value;
      });
      this.$store.dispatch("site/fetchAllVehicleCarryWeight");
    },
    /**
     * 取込確定ボタン押下。サーバーへ確定リクエストを投げる
     */
    commit() {
      const driverNameList = this.driverNameSummary
        .filter(value => {
          return value.isImportTarget === true;
        })
        .map(value => value.driverName);

      const self = this;
      self.loading.commit = true;
      this.$store
        .dispatch("order/commitImportRequest", driverNameList)
        .then(value => {
          self.clearWorkData();
          this.$emit("commitedFile", value.data.data);
        })
        .catch(error => {
          this.$refs.errorMsg.displayMessage(error.response.data.message);
        })
        .finally(() => {
          self.loading.commit = false;
        });
    },
    clearWorkData() {
      this.records = {
        driverNameSummary: [],
        clientBranchName: null,
        distanceTypeLabel: null,
        list: []
      };
      this.exclusiveError.message = null;
      this.$refs.errorMsg.nonDisplayMessage();
    },
    openDriverNameEditDialog(orderRec) {
      if (orderRec.driverName == "") {
        this.driverNameEditItem.planBoardNo = orderRec.planBoardNo;
        this.driverNameEditItem.routeNo = orderRec.routeNo;
        this.driverNameEditItem.routeNoSeq = orderRec.routeNoSeq;
        this.driverNameEditItem.totalGrossWeight = orderRec.totalGrossWeight;
        this.$refs.DriverNameEditDialog.openDialog(this.driverNameEditItem);
      }
    },
    /**
     * 処理：getValidationErrorList
     * ※computedで他要素を操作することをESLintは許容していないため
     */
    executePushError() {
      this.validationErrorList.errors = [];
      this.list.forEach(driverName => {
        driverName.details.list.forEach(data => {
          if (Object.keys(data.validationError).length) {
            this.validationErrorList.errors.push({
              lineNo: data.lineNo,
              error: data.validationError
            });
          }
        });
      });
      return this.validationErrorList;
    }
  },
  computed: {
    ...mapGetters({
      getDistance: "site/distanceTypeStringFromKey",
      getVehicleTypeLabel: "site/vehicleTypeLabelFromWeightId"
    }),
    list: {
      get() {
        return this.records.list;
      }
    },
    driverNameSummary: {
      get() {
        return this.records.driverNameSummary;
      }
    },
    checkedDriverNameSummary: {
      get() {
        return this.driverNameSummary.filter(value => {
          return value.isImportTarget === true;
        });
      }
    },
    hadData() {
      if (this.records == null) {
        return false;
      }
      return this.records.driverNameSummary.length > 0;
    },
    getCaseCount() {
      let caseCount = 0;
      this.checkedDriverNameSummary.forEach(driverName => {
        driverName.list.forEach(data => {
          data.details.list.forEach(detail => {
            caseCount = caseCount + parseInt(detail.inventoryHoldingUnit);
          });
        });
      });
      return caseCount.toLocaleString();
    },
    getRouteNoCount: {
      get() {
        const routeNoList = [];
        this.checkedDriverNameSummary.forEach(driverName => {
          driverName.list.forEach(data => {
            if (data.routeNoSeq == "1") {
              routeNoList.push(data.routeNo);
            }
          });
        });
        return routeNoList.length;
      }
    },
    getShipCount() {
      let shipCount = 0;
      this.checkedDriverNameSummary.forEach(driverName => {
        this.list
          .filter(record => record.driverName === driverName.driverName)
          .forEach(list => {
            shipCount = shipCount + list.slipNoList.length;
          });
      });
      return shipCount;
    },
    getDriverNameCount() {
      return this.checkedDriverNameSummary.length;
    },
    getValidationErrorList() {
      return this.executePushError();
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style lang="stylus" scoped>
.header-summary {
  display: flex;
  justify-content: flex-end;
}

.header-summary-table {
  width: auto;
}

.no_index {
  width: 32px;
  display: inline-block;
}

.detail_box {
  padding-left: 48px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;

  td {
    height: 48px;
    border: solid #dcdcdc;
    border-width: 1px 0px;
    vertical-align: middle;
    padding-left: 8px;
    padding-right: 8px;
  }

  thead {
    tr {
      height: 54px;
    }

    th {
      border-right: 1px solid #E0E0E0;
      background-color: #fff;
      position: sticky;
      top: 45px;
      height: 54px;
      z-index: 1;
    }
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }

  tbody {
    tr {
      transition: 0.3s;
    }

    td {
      border-right: 1px solid #E0E0E0;
    }

    tr.selected {
      background: #ECEFF1;

      td {
        background: none;
      }
    }
  }

  .detail-td {
    padding-right: 0;
  }
}

.order-table {
  border-collapse: separate;
  border-spacing: 0;

  td {
    border-bottom: none;
  }
}

.commit-btn {
  margin: 0 8px 0 40px;
}

.toggleCell {
  cursor: pointer;
  vertical-align: top;
  height: 69px;
  line-height: 69px;
}

.targetSelectCell {
  vertical-align: top;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-title {
  height: 69px;
  line-height: 69px;
  vertical-align: middle;
  padding-right: 25px;
  cursor: pointer;
}

.th-text {
  margin-top: 20px;
  font-size: 13px;
}

.v-text-field {
  padding-top: 8px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 14px;
  font-weight: 400;
}
</style>
