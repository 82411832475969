<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="荷主CD" v-model="conditions.clientCd")
        v-flex(md2)
          v-text-field(label="名称" v-model="conditions.clientName")
        v-flex(md2)
          v-text-field(label="郵便番号" v-model="conditions.zipCode")
        v-flex(md2)
          v-text-field(label="都道府県" v-model="conditions.prefName")
        v-flex(md2)
          v-text-field(label="住所" v-model="conditions.address")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="電話番号" v-model="conditions.tel")
        v-flex(md2)
          v-text-field(label="FAX" v-model="conditions.fax")
        v-flex(md2)
          FaxTargetSelect(labelDisp="請求書FAX送信対象" :code.sync="conditions.billingStatementFaxTarget" :clearable="true")
        v-flex(md2)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import StatusSelect from "@/components/common/select/StatusSelect";
import FaxTargetSelect from "@/components/common/select/FaxTargetSelect";

export default {
  components: {
    SearchConditions,
    StatusSelect,
    FaxTargetSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          clientCd: "",
          clientName: "",
          clientShortName: "",
          zipCode: "",
          prefName: "",
          address: "",
          tel: "",
          fax: "",
          billingStatementFaxTarget: "",
          status: null
        }
      };
    }
  }
};
</script>
