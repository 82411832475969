<template lang="pug">
  EditDialog(ref="EditDialog" title="車両" :recordId="record.id"
    @delete="erase" @save="save" width="90%"
      :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md fluid)
        v-layout(row wrap)
          v-flex(xs12 md6)
            v-card
              v-toolbar(dark :color="$store.getters['site/siteColor']")
                v-toolbar-title 車両情報
              v-card-text
                v-container(grid-list-md)
                  v-layout(row wrap)
                    v-flex(xs12 md12)
                      DeliveryCompanySelect(:label.sync="record.deliveryCompanyName"
                        :code.sync="record.deliveryCompanyId" :excludeOwnCompanyId="false"
                        :readonly="dispatchRegisterMode"
                        :errorHandlerMethod="getErrorMessages")

                  v-layout(row wrap)
                    v-flex(xs12 md3)
                      VehicleTypeSelect(:readonly="record.id != null" :code.sync="record.vehicleTypeId" :errorHandlerMethod="getErrorMessages" @changeItem="changeVehicleType")

                    v-flex(xs12 md6)
                      VehicleCarryWeightSelect(:label.sync="record.carryWeight" :code.sync="record.vehicleCarryWeightId" :displayMaxWeight="true" labelDisp="車格 / 積載重量 / 最大積載重量"
                        :vehicleTypeId="record.vehicleTypeId" :vehicleTypeName="record.vehicleTypeName" :errorHandlerMethod="getErrorMessages")

                  v-layout(row wrap)
                    v-flex(xs12 md3 v-if="isTRASCO")
                      MonthPicker(pickerLabel="初年度登録" :inputDate.sync="record.firstRegistered" :initDate="record.firstRegistered" :errorHandlerMethod="getErrorMessages" errorHandlerMethodParam="firstRegistered")

                    v-flex(xs12 md6)
                      VehicleGarageSelect(:label.sync="record.vehicleGarageName" :code.sync="record.vehicleGarageId" :errorHandlerMethod="getErrorMessages")

                  v-layout(row wrap)
                    v-flex(xs12 md3)
                      v-text-field(v-model="record.entryNumber" label="車番4ケタ" :error-messages="getErrorMessages('entryNumber')")

                    v-flex(xs12 md3)
                      v-text-field(v-model="record.entryLocation" label="車番地区" :error-messages="getErrorMessages('entryLocation')")

                    v-flex(xs12 md3)
                      v-text-field(v-model="record.entryKana" label="車番かな" :error-messages="getErrorMessages('entryKana')")

                    v-flex(xs12 md3)
                      v-text-field(v-model="record.entryControlNumber" label="車番枝番" :error-messages="getErrorMessages('entryControlNumber')")

                  v-layout(row wrap v-if="useChassis")
                    v-flex(xs12 md3)
                      v-text-field(v-model="record.chassisNumber" label="車番(シャーシ) 4ケタ" :error-messages="getErrorMessages('chassisNumber')")

                    v-flex(xs12 md3)
                      v-text-field(v-model="record.chassisLocation" label="車番(シャーシ) 地区" :error-messages="getErrorMessages('chassisLocation')")

                    v-flex(xs12 md3)
                      v-text-field(v-model="record.chassisKana" label="車番(シャーシ) かな" :error-messages="getErrorMessages('chassisKana')")

                    v-flex(xs12 md3)
                      v-text-field(v-model="record.chassisControlNumber" label="車番(シャーシ) 枝番" :error-messages="getErrorMessages('chassisControlNumber')")

                  v-layout(row wrap)
                    v-flex(xs12 md3)
                      v-text-field.text-align-right(v-model="record.width" label="車幅(m)" :error-messages="getErrorMessages('width')")

                    v-flex(xs12 md3)
                      v-text-field.text-align-right(v-model="record.height" label="車高(m)" :error-messages="getErrorMessages('height')")

                    v-flex(xs12 md3)
                      v-text-field.text-align-right(v-model="record.length" label="全長(m)" :error-messages="getErrorMessages('length')")

                    v-flex(xs12 md3)
                      v-text-field.text-align-right(v-model="record.groundHeight" label="地上高(m)" :error-messages="getErrorMessages('groundHeight')")

                  v-layout(row wrap)
                    v-flex(xs12 md6)
                      VehicleOptionSeparateSelect(:code.sync="record.optionIdList"  :errorHandlerMethod="getErrorMessages" :clearable="true")

                    v-flex(xs12 md3)
                      StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

          v-flex(xs12 md6)
            v-card
              v-toolbar(dark :color="$store.getters['site/siteColor']")
                v-toolbar-title メンテナンススケジュール

              v-card-text
                v-container(grid-list-md)
                  v-btn(color="primary" @click="openVehicleMaintenanceEdit") 追加
                  VehicleMaintenanceTable(:prop-data="record.vehicleMaintenanceList" @editMaintenance="openVehicleMaintenanceEdit" @deleteMaintenance="deleteVehicleMaintenance")

        VehicleMaintenanceEdit(ref="VehicleMaintenanceEdit" @save="saveVehicleMaintenance" @delete="deleteVehicleMaintenance" :postUrl="$config.urls.checkVehicleMaintenance")

</template>

<script>
import { mapGetters } from "vuex";
import EditDialog from "@/components/common/dialog/EditDialog";
import DeliveryCompanySelect from "@/components/common/select/DeliveryCompanySelect";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";
import FloorTypeSelect from "@/components/common/select/FloorTypeSelect";
import VehicleCarryWeightSelect from "@/components/common/select/VehicleCarryWeightSelect";
import VehicleOptionSelect from "@/components/common/select/VehicleOptionSelect";
import VehicleOptionSeparateSelect from "@/components/common/select/VehicleOptionSeparateSelect";
import MonthPicker from "@/components/common/MonthPicker";
import VehicleGarageSelect from "@/components/common/select/VehicleGarageSelect";
import StatusSelect from "@/components/common/select/StatusSelect";

import VehicleMaintenanceEdit from "@/components/management/vehicle/VehicleMaintenanceEdit";
import VehicleMaintenanceTable from "@/components/management/vehicle/VehicleMaintenanceTable";
import DeleteConfirmDialog from "@/components/common/dialog/DeleteConfirmDialog";
import EmergencyErrorDialog from "@/components/common/EmergencyErrorDialog";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    EditDialog,
    DeliveryCompanySelect,
    VehicleTypeSelect,
    FloorTypeSelect,
    VehicleCarryWeightSelect,
    VehicleOptionSelect,
    VehicleOptionSeparateSelect,
    MonthPicker,
    VehicleGarageSelect,
    StatusSelect,
    VehicleMaintenanceEdit,
    VehicleMaintenanceTable,
    DeleteConfirmDialog,
    EmergencyErrorDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      TRASCOCompanyId: this.$store.state.site.global.TRASCOCompanyId,
      defaultCarryWeightId: null,
      record: {}
    };
  },
  props: {
    entryDataCallBack: {
      type: Function,
      required: false,
      default: record => {}
    },
    dispatchRegisterMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    initData() {
      return {
        id: null,
        entryNumber: null,
        entryLocation: null,
        entryKana: null,
        entryControlNumber: null,
        vehicleTypeId: this.getVehicleIdByCd(Enum.PrimaryVehicleType.IncreaseTon.code),
        vehicleTypeName: null,
        vehicleCarryWeightId: null,
        carryWeight: null,
        width: null,
        height: null,
        length: null,
        groundHeight: null,
        vehicleGarageId: null,
        vehicleGarageName: null,
        deliveryCompanyId: null,
        deliveryCompanyName: null,
        firstRegistered: null,
        status: Enum.ActiveFlag.Active.code,

        useChassis: false,
        chassisNumber: null,
        chassisLocation: null,
        chassisKana: null,
        chassisControlNumber: null,

        optionIdList: [],
        vehicleMaintenanceList: []
      };
    },
    openDialog(item) {
      this.initForm(item);
      //JavaからはごっそりVehicleオブジェクトで返却され、付帯するVehicleOptionもIntで帰ってくる
      //文字列でないとセレクト要素がマッチしないので文字列に変換する（Java側改修は工数がかかるので）
      if (this.record.optionIdList != null) {
        for (let i = 0; i < this.record.optionIdList.length; i++) {
          this.record.optionIdList[i] = this.record.optionIdList[i] + "";
        }
      }
      //新規登録画面開く時、車格積載重量IDは通信しないと取得できないのでここでやむを得ずセットする
      if (item === undefined) {
        this.$store
          .dispatch("vehicle/fetchDefaultVehicleCarryWeight", {
            vehicleTypeId: this.getVehicleIdByCd(Enum.PrimaryVehicleType.IncreaseTon.code),
            carryWeightMin: 12500,
            carryWeightMax: 12500
          })
          .then(value => {
            this.record.vehicleCarryWeightId = value.id;
            this.entryDataCallBack(this.record);
            this.$refs.EditDialog.openDialog();
          });
      } else {
        this.$refs.EditDialog.openDialog();
      }
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      this.preSave();
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    },
    /**
     * 車両を保存
     */
    preSave() {
      if (!this.record.useChassis) {
        this.record.chassisControlNumber = null;
        this.record.chassisLocation = null;
        this.record.chassisKana = null;
        this.record.chassisNumber = null;
      }
      if (!this.isTRASCO) {
        this.record.firstRegistered = null;
      }
    },

    /**
     * 車格変更時の処理
     */
    changeVehicleType() {
      this.record.vehicleCarryWeightId = null;
      this.record.carryWeight = null;
      this.record.useChassis = this.getUseChassis(this.record.vehicleTypeId);
    },

    /**
     * 車両メンテナンスダイアログを表示
     */
    openVehicleMaintenanceEdit(item) {
      this.$refs.VehicleMaintenanceEdit.openDialog(item);
    },

    /**
     * 車両メンテナンスを保存
     */
    saveVehicleMaintenance(item) {
      if (item.index == null) {
        // 追加
        this.record.vehicleMaintenanceList.push(item);
      } else {
        // 更新
        this.$set(this.record.vehicleMaintenanceList, item.index, item);
      }
    },

    /**
     * 車両メンテナンスを削除
     */
    deleteVehicleMaintenance(item) {
      this.record.vehicleMaintenanceList.splice(item.index, 1);
    },
    /**
     * 変更：record.useChassis
     * ※computedで他要素を操作することをESLintは許容していないため
     * @return {String} record.useChassis
     */
    changeUseChassis(data) {
      this.record.useChassis = this.getUseChassis(data);
      return this.record.useChassis;
    }
  },
  computed: {
    ...mapGetters({
      getUseChassis: "vehicle/useChassisFromVehicleTypeId",
      getVehicleIdByCd: "site/getVehicleIdByCd"
    }),
    /**
     * 選択された所属企業が自社(合通TRASCO)か判定する
     */
    isTRASCO: {
      get() {
        return this.record.deliveryCompanyId === this.TRASCOCompanyId;
      }
    },
    useChassis: {
      get() {
        if (this.record.vehicleTypeId) return this.changeUseChassis(this.record.vehicleTypeId);
        return false;
      }
    }
  },
  created() {
    this.record = this.initData();
  }
};
</script>
