<template lang="pug">
  v-icon(v-if="isNothing(specifiedTimeType)" :small="isSmall" color="grey") timer
  v-icon(v-else-if="isSpecified(specifiedTimeType)" :small="isSmall" color="red") timer
  v-icon(v-else-if="isMorning_Designation(specifiedTimeType)" :small="isSmall" color="yellow darken-2") timer
  v-icon(v-else-if="isNoon_Designation(specifiedTimeType)" :small="isSmall" color="green") timer
  v-icon(v-else-if="isAfternoon_Designation(specifiedTimeType)" :small="isSmall" color="blue") timer
</template>

<script>
import SpecifiedTimeTypeHandler from "@/mixin/SpecifiedTimeTypeHandler";

export default {
  mixins: [SpecifiedTimeTypeHandler],
  name: "SpecifiedTimeTypeIcon",
  props: {
    specifiedTimeType: {
      type: String,
      default: "00"
    },
    isSmall: {
      required: false,
      type: Boolean,
      default: false
    }
  }
};
</script>
