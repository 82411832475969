<template lang="pug">
  SearchConditions(ref="SearchConditions" :initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      alert-box(ref="errorMsg")
      v-layout(wrap)
        v-flex(md1)
          MonthPicker(pickerLabel="請求対象年月" :initDate="conditions.billingTargetYearMonth"  :inputDate.sync="conditions.billingTargetYearMonth" :clearable="true")
        v-flex(md1)
          MonthPicker(pickerLabel="支払対象年月" :initDate="conditions.paymentTargetYearMonth"  :inputDate.sync="conditions.paymentTargetYearMonth" :clearable="true")
        v-flex(md1)
          v-textarea(label="シップ/受付/中継番号" v-model="conditions.shipmentNo" maxlength=700 rows="1")
        v-flex(md2)
          v-text-field(label="荷主" v-model="conditions.clientName" maxlength=100)
        v-flex(md1)
          v-text-field(label="荷主枝番" v-model="conditions.clientBranchName" maxlength=100)
        v-flex(md2)
          v-text-field(label="1次委託先会社" v-model="conditions.primaryDeliveryCompanyName" maxlength=100)
        v-flex(md1)
          v-text-field(label="実運行会社名" v-model="conditions.driverDeliveryCompanyName" maxlength=100)
        v-flex(md1)
          v-text-field(label="乗務員名" v-model="conditions.driverName" maxlength=100)
        v-flex(md1)
          v-text-field(label="車番" v-model="conditions.vehicleNumber" maxlength=100)
        v-flex(md1)
          VehicleTypeSelect(label="カード車格" :code.sync="conditions.vehicleTypeId" :clearable="true")
      v-layout(wrap)
        v-flex(md4)
          v-layout(wrap)
            v-flex(md4)
              DatePicker(pickerLabel="配車日" :initDate="conditions.scheduledLoadingDate" :inputDate.sync="conditions.scheduledLoadingDate" :isClearable="true")
            v-flex(md4)
              DatePicker(pickerLabel="積込日" :initDate="conditions.shipmentDate" :inputDate.sync="conditions.shipmentDate" :isClearable="true")
            v-flex(md4)
              DatePicker(pickerLabel="納品日" :initDate="conditions.deliveryDate" :inputDate.sync="conditions.deliveryDate" :isClearable="true")

        v-flex(md3)
          v-layout(wrap)
            v-flex(md6)
              v-text-field(label="積地" v-model="conditions.loadingBaseName" maxlength=100)
            v-flex(md6)
              v-text-field(label="卸地" v-model="conditions.unloadingBaseName" maxlength=100)

        v-flex(md2)
          v-layout(wrap)
            v-flex(md6)
              BillingTypeConditionSelect(:code.sync="conditions.billingCondition")
            v-flex(md6)
              PaymentTypeConditionSelect(:code.sync="conditions.paymentCondition")

        v-flex(md3)
          v-layout(wrap)
            v-flex(md6)
              BillingStatusSelect(:code.sync="conditions.billingStatus" :clearable="true")
            v-flex(md6)
              PaymentStatusSelect(:code.sync="conditions.paymentStatus" :clearable="true")

      v-layout(wrap)
        v-flex(md2 offset-md7)
          v-checkbox.no-top-margin(label="会計備考入力ありのみ" v-model="conditions.onlyFinanceRemarkEntry" hide-details )
          v-checkbox.no-top-margin(label="会計備考入力なしのみ" v-model="conditions.onlyFinanceRemarkNotEntry" hide-details )
          //v-checkbox.no-top-margin(label="スライド元のみ" v-model="conditions.onlySlideFromCard" hide-details )
          v-checkbox.no-top-margin(label="スライド元と先のみ" v-model="conditions.onlySlideFromDestCard" hide-details )
          v-checkbox.no-top-margin(label="中継元と先のみ" v-model="conditions.onlyRelayFromDestCard" hide-details )
        v-flex(md3)
          v-layout(wrap)
            v-flex(md6)
              v-checkbox.no-top-margin(label="請求基本運賃未登録のみ" v-model="conditions.onlyBillingBasicPriceEntry" hide-details)
            v-flex(md6)
              v-layout(column)
                v-flex
                  v-checkbox.no-top-margin(label="支払基本運賃未登録のみ" v-model="conditions.onlyPaymentBasicPriceEntry" hide-details)
                v-flex
                  v-checkbox.no-top-margin(label="支払速報発行済のみ" v-model="conditions.onlyPaymentPromptPublished" hide-details)
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import MonthPicker from "@/components/common/MonthPicker";
import BillingStatusSelect from "@/components/common/select/BillingStatusSelect";
import PaymentStatusSelect from "@/components/common/select/PaymentStatusSelect";
import AlertBox from "@/components/common/AlertBox";
import VehicleTypeSelect from "@/components/common/select/VehicleTypeSelect";
import BillingTypeConditionSelect from "@/components/common/select/BillingTypeConditionSelect";
import PaymentTypeConditionSelect from "@/components/common/select/PaymentTypeConditionSelect";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    SearchConditions,
    DatePicker,
    MonthPicker,
    BillingStatusSelect,
    PaymentStatusSelect,
    AlertBox,
    VehicleTypeSelect,
    BillingTypeConditionSelect,
    PaymentTypeConditionSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.nonDisplayMessage();
      this.$emit("onChangeSearchConditions", item);
    },
    /**
     * アラートボックス表示
     */
    displayMessage(errorMsg) {
      this.$refs.errorMsg.displayMessage(errorMsg);
    },
    /**
     * アラートボックス非表示
     */
    nonDisplayMessage() {
      this.$refs.errorMsg.nonDisplayMessage();
    },
    initSearchCond() {
      return {
        conditions: {
          billingTargetYearMonth: window.Common.Util.getYearMonthIsoFormat(),
          paymentTargetYearMonth: null,
          shipmentNo: "",
          clientName: "",
          clientBranchName: "",
          primaryDeliveryCompanyName: "",
          driverDeliveryCompanyName: "",
          driverName: "",
          vehicleNumber: "",
          vehicleTypeId: "",
          scheduledLoadingDate: null,
          shipmentDate: null,
          deliveryDate: null,
          loadingBaseName: "",
          unloadingBaseName: "",
          billingCondition: null,
          paymentCondition: null,
          billingStatus: Enum.BillingStatusType.PENDING.code,
          paymentStatus: null,
          onlyFinanceRemarkEntry: null,
          onlyFinanceRemarkNotEntry: null,
          onlyBillingBasicPriceEntry: null,
          onlyPaymentBasicPriceEntry: null,
          onlyPaymentPromptPublished: null,
          onlySlideFromCard: null,
          onlySlideFromDestCard: null,
          onlyRelayFromDestCard: null
        }
      };
    },
    submit() {
      this.$refs.SearchConditions.submit();
    }
  }
};
</script>
