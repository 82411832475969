export default {
  data() {
    return {
      css: {
        start: false,
        over: false
      }
    };
  },
  methods: {
    dragStart(argument, e) {
      this.css.start = true;
      this.$emit("dragStart", argument, e);
    },
    dragEnd(argument, e) {
      this.css.start = false;
      this.css.over = false;
      this.$emit("dragEnd", argument, e);
    },
    dragEnter(argument, e) {
      this.css.over = true;
      this.$emit("dragEnter", argument, e);
    },
    dragLeave(argument, e) {
      this.css.over = false;
      this.$emit("dragLeave", argument, e);
    },
    dragOver(argument, e) {
      this.$emit("dragOver", argument, e);
    },
    drop(argument, e) {
      this.css.start = false;
      this.css.over = false;
      this.$emit("drop", argument, e);
    },
    mouseDown() {
      let sel = window.getSelection ? window.getSelection() : document.selection;
      if (sel) {
        if (sel.removeAllRanges) {
          sel.removeAllRanges();
        } else if (sel.empty) {
          sel.empty();
        }
      }
    },
    setClass(target, className) {
      if (target.classList.contains(className)) {
        return;
      }
      target.classList.toggle(className);
    },
    removeClass(target, className) {
      if (target.classList.contains(className)) {
        target.classList.toggle(className);
      }
    }
  }
};
