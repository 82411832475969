<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-card
        v-card-text
          v-layout(justify-end wrap row)
            v-flex(md2)
              v-btn(@click="exportConfirm" color="success" :loading="loading.export") 伝票明細出力
              v-btn(@click="exportCardConfirm" color="success" :loading="cardExport") カード明細出力
            v-flex(md10)
              OrderCount

          v-layout()
            v-flex(md12)
              v-card
                v-card-text
                  OrderFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

      v-card
        v-tabs(color="primary darken-2" dark slider-color="yellow darken-2")
          v-tab(ripple @click="setActiveTab") BJIインポート
          v-tab(ripple @click="setActiveTab") 手動登録
          v-tab-item
            BjiOrderSummaryListTable(ref="BjiOrderListTable" @updatedOrder="itemUpdated" getListActionName="order/fetchAllBjiOrder" :activeTab="activeTab.bji")
          v-tab-item
            ManualOrderListTable(ref="ManualOrderListTable" @updatedOrder="itemUpdated" getListActionName="order/fetchAllGeneralOrder" :activeTab="activeTab.manual")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('order/exportOrder')")
    ExportConfirmDialog(ref="CardExportConfirmDialog" @yes="exportCardData('order/exportCardOrder')")

</template>

<script>
import ManualOrderListTable from "@/components/order/ManualOrderListTable";
import OrderFilterConditions from "@/components/order/OrderFilterConditions";
import ReloadButton from "@/components/common/buttons/ReloadButton";
import BjiOrderSummaryListTable from "@/components/order/BjiOrderSummaryListTable";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import OrderCount from "@/components/order/OrderCount";

import ExportHandler from "@/mixin/ExportHandler";
import EventDispatcher from "@/mixin/EventDispatcher";

export default {
  components: {
    ManualOrderListTable,
    OrderFilterConditions,
    ReloadButton,
    BjiOrderSummaryListTable,
    ExportConfirmDialog,
    OrderCount
  },
  mixins: [ExportHandler, EventDispatcher],
  data() {
    return {
      cardExport: false,
      displayConditions: false,
      bjiLoading: false,
      manualLoading: false,
      activeTab: {
        bji: true,
        manual: false
      }
    };
  },
  computed: {
    isDataLoading: {
      get() {
        return this.bjiLoading || this.manualLoading;
      }
    }
  },
  watch: {
    isDataLoading: function (value) {
      if (value === false) {
        //一覧の検索やクリアボタンローディング制御の為にイベント発行。
        this.dispatchCommonEvent(window.Common.Config.observer.onPagingListTableAjaxCompleted);
      }
    }
  },
  methods: {
    itemUpdated() {
      this.$refs.BjiOrderListTable.resetPageCount();
      this.$refs.ManualOrderListTable.resetPageCount();
      this.reLoadOrders();
    },
    reLoadOrders() {
      this.manualLoading = this.bjiCustomer = true;
      this.$refs.BjiOrderListTable.loadOrders().finally(() => (this.bjiLoading = false));
      this.$refs.ManualOrderListTable.loadOrders().finally(() => (this.manualLoading = false));
    },
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.BjiOrderListTable.setSearchConditions(searchCond);
      this.$refs.BjiOrderListTable.resetPageCount();
      this.$refs.ManualOrderListTable.setSearchConditions(searchCond);
      this.$refs.ManualOrderListTable.resetPageCount();
      this.reLoadOrders();
    },
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    },
    exportCardConfirm() {
      this.$refs.CardExportConfirmDialog.openDialog();
    },
    exportCardData(exportUrl) {
      this.cardExport = true;
      this.$store
        .dispatch(exportUrl, this.searchCond)
        .catch(error => {
          const event = document.createEvent("Event");
          if (error.response.status === 404) {
            event.initEvent(window.Common.Config.observer.onCommonErrorResponse404, false, true);
          }
          if (error.response.status === 400) {
            event.initEvent(window.Common.Config.observer.onCommonErrorResponse400, false, true);
          }
          window.dispatchEvent(event);
        })
        .finally(() => {
          this.cardExport = false;
        });
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          transportType: "",
          loadingDate: null,
          loadingPlace: "",
          unloadingDate: null,
          unloadingPlace: "",
          orderDate: null,
          billingTypeName: "",
          distanceType: "",
          dispatchStatusList: "",
          specifiedTimeType: ""
        }
      };
    },
    setActiveTab() {
      this.activeTab.bji = !this.activeTab.bji;
      this.activeTab.manual = !this.activeTab.manual;
    }
  }
};
</script>
