export default {
  methods: {
    dispatchCommonEvent(eventName) {
      const event = document.createEvent("Event");
      event.initEvent(eventName, false, true);
      window.dispatchEvent(event);
    },
    dispatchCommonEventWithParam(eventName, data) {
      const event = new CustomEvent(eventName, {
        detail: data
      });
      window.dispatchEvent(event);
    }
  }
};
