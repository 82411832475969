<template lang="pug">
  section.inner-body-section(ref="InnerBodySection")
    table.order-table
      template(v-for="(detail, index) in billingDetailList(contents.clientId, contents.clientBranchId, contents.billingTargetYearMonth)")
        thead(:class="{ selected : contents.isDisplayCell }" v-if="detail.breakPage")
          tr
            th(rowspan=2 width="80px") No
            th(rowspan=2) 荷主枝番
            th(rowspan=2 width="120px") 積込日 / 納品日
            th(rowspan=2) 積地
            th(rowspan=2) 卸地
            th(rowspan=2 width="100px") 荷量/車番
            th(rowspan=2) shipmentNo
            th(rowspan=1) 運賃
            th(rowspan=1) 積込料
            th(rowspan=1) 取卸料
            th(rowspan=2) 運賃料合計
            th(rowspan=2) 通行料
          tr
            th(rowspan=1) 待機時間料
            th(rowspan=1) 付帯作業料
            th(rowspan=1) その他料金
        tbody
          tr
            td.text-sm-center(rowspan=2) {{ index + 1 }}
            td.text-whitespace-pre(rowspan=2) {{ detail.clientBranchName }}
            td.text-whitespace-pre.text-sm-center(rowspan=2) {{ getCombineDate(getShipmentDate(detail), getDeliveryDate(detail)) | commaToNr }}
            td.text-whitespace-pre(rowspan=2) {{ detail.loadingBases | commaToNr}}
            td.text-whitespace-pre(rowspan=2) {{ detail.unloadingBases | commaToNr }}
            td.text-whitespace-pre(rowspan=2) {{ detail.billingVehicleDisplay }}
            td.text-whitespace-pre(rowspan=2) {{ detail.shipmentNo | commaToNr }}
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.billingCard.fare" )
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.billingCard.loadingCharge" )
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.billingCard.unloadingCharge" )
            td.text-whitespace-pre.text-sm-right(rowspan=2)
              DigitSeparatorsLabel(:value="detail.billingCard.totalFare" )
            td.text-whitespace-pre.text-sm-right(rowspan=2)
              DigitSeparatorsLabel(:value="detail.billingCard.toll" )
          tr
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.billingCard.waitingFee" )
            td.text-whitespace-pre.text-sm-right
              DigitSeparatorsLabel(:value="detail.billingCard.additionalFee" )
            td.text-whitespace-pre.text-sm-right.right-boder
              DigitSeparatorsLabel(:value="detail.billingCard.other" )
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EventDispatcher from "@/mixin/EventDispatcher";
import ConbineUtils from "@/mixin/ConbineUtils";
import DigitSeparatorsLabel from "@/components/common/DigitSeparatorsLabel";
import { LimitNumber } from "@/assets/js/constants";

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    contents: {
      type: Object,
      required: true
    },
    searchConditions: {
      type: Object,
      required: true
    }
  },
  mixins: [EventDispatcher, ConbineUtils],
  data() {
    return {
      pageCount: 0,
      progress: false
    };
  },
  components: {
    DigitSeparatorsLabel
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters("statement", {
      billingDetailList: "getBillingDetailList"
    }),
    actionName: () => ""
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("statement", {
      fetchBillingDetailList: "fetchBillingDetailList"
    }),
    async loadDetail() {
      this.progress = true;
      this.clearPageCount();
      const searchCondition = this.searchConditions;
      const addSearchCond = {
        clientId: this.contents.clientId,
        clientBranchId: this.contents.clientBranchId,
        billingTargetYearMonth: this.contents.billingTargetYearMonth,
        pageCount: this.pageCount
      };
      await this.fetchBillingDetailList({ ...searchCondition, ...addSearchCond }).finally(() => {
        this.progress = false;
      });
    },
    clearPageCount() {
      if (
        !this.billingDetailList(
          this.contents.clientId,
          this.contents.clientBranchId,
          this.contents.billingTargetYearMonth
        )
      ) {
        this.pageCount = 0;
      }
    },
    /**
     * 詳細スクロールイベント
     */
    async handleScrollInner() {
      if (this.$refs.InnerBodySection !== undefined) {
        const clientHeight = this.$refs.InnerBodySection.clientHeight;
        const scrollTop = this.$refs.InnerBodySection.scrollTop;
        const scrollHeight = this.$refs.InnerBodySection.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber && !this.progress) {
          this.pageCount++;
          this.loadDetail();
        }
      }
    },
    getShipmentDate(detail) {
      return detail.shipmentDate ? detail.shipmentDate : detail.billingCard.loadingDate;
    },
    getDeliveryDate(detail) {
      return detail.deliveryDate ? detail.deliveryDate : detail.billingCard.unloadingDate;
    }
  },
  mounted() {
    // イベントリスナー
    this.$refs.InnerBodySection.removeEventListener("scroll", this.handleScrollInner);
    this.$refs.InnerBodySection.addEventListener("scroll", this.handleScrollInner);
    this.pageCount = 0;
  }
};
</script>

<style lang="stylus" scoped>
.order-table {
  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }

  tbody:hover {
    background-color #ECEFF1
  }
}

.inner-body-section {
  th:last-child, td:last-child {
    border-right: 1px solid #E0E0E0;
  }

  thead {
    top 0
  }

  th {
    position: static
    top 0
  }
  max-height: 500px
  overflow-y scroll;
}
</style>
