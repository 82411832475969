<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      FilterConditions(@onChangeSearchConditions="onChangeSearchConditions")
            v-card-text
              UnloadingBaseTable(ref="UnloadingBaseTable" :prop-header="customerTableHeader" :prop-data="unloadingBaseList",
                :getListActionName="getListActionName" detail-url="base/fetchUnloadingBase" @open-detail="openDetail" :displaySlotBody="true")
                template(v-slot:body="{ body }")
                  td.text-xs-left {{ body.client.clientDisplayName }}
                  td.text-xs-left {{ body.baseClassName }}
                  td.text-xs-left {{ body.baseCd }}
                  td.text-xs-left {{ body.baseName }}
                  td.text-xs-left {{ body.area.name }}
                  td.text-xs-left {{ body.prefecture.name }}
                  td.text-xs-left {{ body.city }}
                  td.text-xs-left
                    v-chip(v-for="(optionName, index) in body.optionNameList" :key="index") {{ optionName }}
                  td.text-xs-left {{ formatYmd(body.createdAt)  }}
                  td.text-xs-left {{ formatYmd(body.updatedAt) }}

    UnloadingBaseEditDialog(ref="UnloadingBaseEditDialog" :postUrl="$config.urls.postEditUnloadingBase" :deleteUrl="$config.urls.deleteUnloadingBase" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('base/exportUnloadingBase')")
</template>

<script>
import UnloadingBaseEditDialog from "@/components/management/base/UnloadingBaseEdit";
import UnloadingBaseTable from "@/components/common/PagingListTable";
import FilterConditions from "@/components/management/base/UnloadingBaseFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    UnloadingBaseEditDialog,
    UnloadingBaseTable,
    FilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    getListActionName: {
      get() {
        return "base/fetchAllUnloadingBase";
      }
    },
    unloadingBaseList: {
      get() {
        return this.$store.state.base.unloadingBaseList;
      }
    },
    customerTableHeader: {
      get() {
        return [
          { text: "荷主" },
          { text: "卸地分類" },
          { text: "卸地CD" },
          { text: "卸地名" },
          { text: "エリア名" },
          { text: "都道府県" },
          { text: "市区町村" },
          { text: "オプション" },
          { text: "登録日" },
          { text: "更新日" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    /**
     * 新規作成ダイアログの表示
     */
    openAddDialog() {
      this.$refs.UnloadingBaseEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     */
    openDetail(item) {
      this.$refs.UnloadingBaseEditDialog.openDialog(item);
    },
    /**
     * 一覧データ取得
     */
    loadListData() {
      this.$refs.UnloadingBaseTable.loadData();
    },
    /**
     * レコード追加・更新・削除時に検索同様ページ番号0にして再取得
     */
    itemUpdated(response, data) {
      this.$refs.UnloadingBaseTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件の取得
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.UnloadingBaseTable.setSearchConditions(searchCond);
      this.$refs.UnloadingBaseTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力確認ダイアログ
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    },
    formatYmd(dateTimeString) {
      return window.Common.Util.getYearMonthDayIsoFormat(new Date(Date.parse(dateTimeString)));
    }
  }
};
</script>
