<template lang="pug">
  div(@click="clickBtn")
    v-icon(v-if="isNone(arrivalStatus)").tracking-icon.tracking-icon-omw navigate_next
    v-icon(v-if="isRed(arrivalStatus)").tracking-icon.tracking-icon-delayed clear
    v-icon(v-if="isBlue(arrivalStatus)").tracking-icon.tracking-icon-arrived navigate_next
    v-icon(v-if="isOrange(arrivalStatus)").tracking-icon.tracking-icon-late-arrived done
    v-icon(v-if="isGreen(arrivalStatus)").tracking-icon.tracking-icon-all-arrived done
    v-icon(v-if="isYellow(arrivalStatus)").tracking-icon.tracking-icon-seems-late warning
</template>

<script>
import ArrivalStatusHandler from "@/mixin/ArrivalStatusHandler";
export default {
  mixins: [ArrivalStatusHandler],
  props: {
    arrivalStatus: {
      type: String,
      required: false,
      default: () => null
    }
  },
  methods: {
    clickBtn() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped lang="stylus">

.tracking-icon {
  $iconSize = 22px
  font-size $iconSize
  width $iconSize
  height $iconSize
  border-radius 50%
  text-align center
  line-height $iconSize -1 px
  margin-right 4px
}

.tracking-icon-all-arrived {
  background-color #4CAF50
  color #E3F2FD !important
}

.tracking-icon-arrived {
  background-color #03A9F4
  color #E3F2FD !important
}

.tracking-icon-late-arrived {
  background-color #D50000
  color #FBE9E7 !important
}

.tracking-icon-omw{
  background-color #BDBDBD
  color #FBE9E7 !important
}

.tracking-icon-arrived-and-departed {
  background-color #4CAF50
  color #F1F8E9 !important
}

.tracking-icon-seems-late {
  $iconSize = 25px !important
  font-size $iconSize
  color #FF5722!important
  background none
  //color #FBE9E7 !important
}

.tracking-icon-delayed {
  background-color #D50000
  color #FFEBEE !important
}
</style>
