<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="label"
    item-value="code"
    label="リフト"
    :small-chips="readonly"
    :box="readonly"
    :readonly="readonly"
    :clearable="clearable"
    :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")
</template>

<script>
import * as Enum from "@/assets/js/enum";
export default {
  props: {
    // TODO:type:String 必須でありながらNullがはいる可能性があるため回避。（※初期値）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    errorHandlerMethod: {
      type: Function,
      default: () => null
    },
    errorHandlerMethodArg: {
      type: String,
      default() {
        return null;
      }
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "requireLiftType"
    };
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) this.$emit("update:code", newVal);
      },
      required: true
    },
    masterList: {
      get() {
        return Enum.Utils.enumToList(Enum.LiftType);
      }
    },
    errorHandlerMethodArgComputed: {
      get() {
        if (this.errorHandlerMethodArg == null) {
          return this.defaultErrorHandlerMethodArg;
        }
        return this.errorHandlerMethodArg;
      }
    }
  },
  methods: {}
};
</script>

<style scoped></style>
