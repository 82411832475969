<template lang="pug">
  v-card
    v-card-text
      v-flex(xs12)
        table(class="sticky-table order-table")
          thead
            tr
              th.no-right-border(class="toggle-icon-cell")
              th No
              th PB No
              th ルートNo
              th 車格
              th 積地
              th 卸地
              th {{ labelName.LoadingSchedule }}
              th {{ labelName.UnloadingSchedule }}
              th 受注日
              th 距離区分
              th 合計数量
              th 合計重量(Kg)
          tbody
            template(v-for="(routeRec, indexParent ) in orderDeleteListSummary" v-if="orderDeleteListSummary.length")
              tr(:class="{ selected : accordions[routeRec.id] }" class="parent toggle-cell")
                td.no-right-border(class="toggle-cell toggle-icon-cell" @click="toggleDetail(routeRec)")
                  v-btn(flat icon v-show="!accordions[routeRec.id]")
                    v-icon() keyboard_arrow_up
                  v-btn(flat icon v-show="accordions[routeRec.id]")
                    v-icon() keyboard_arrow_down
                td.text-sm-center {{ indexParent + 1 }}
                td.text-sm-left() {{ routeRec.planBoardNo }}
                td.text-sm-left() {{ routeRec.routeNo }} - {{ routeRec.routeNoSeq }}
                td() {{ routeRec.vehicleCarryWeight.vehicleTypeName }}
                td.text-whitespace-pre() {{ routeRec.details.loadingBaseNames | commaToNr }}
                td.text-whitespace-pre() {{ routeRec.details.unloadingBaseNames | commaToNr}}
                td.text-sm-center() {{ routeRec.loadingDate }}
                td.text-sm-center() {{ routeRec.unloadingDate }}
                td.text-sm-center {{ routeRec.orderDate }}
                td.text-sm-left() {{ getDistance(routeRec.distanceType) }}
                td.text-sm-right() {{ routeRec.totalInventoryHoldingUnit | addComma }}
                td.text-sm-right() {{ routeRec.totalGrossWeight  }}
              tr(v-show="accordions[routeRec.id]")
                td(colspan="13" class="inner-cell")
                  table(class="order-table")
                    thead(:class="{ selected : accordions[routeRec.id] }")
                      tr
                        th No
                        th 伝票番号
                        th 荷主枝番
                        th 請求区分
                        th 積地
                        th 卸地
                        th 数量
                        th 重量(Kg)
                    tbody
                      tr(v-for="(orderRec , indexChild) in routeRec.details.list")
                        td.text-sm-center {{ indexParent + 1 }} - {{ indexChild + 1 }}
                        td.text-sm-left {{ orderRec.slipNo }}
                        td.text-sm-left {{ orderRec.clientBranch.branchName }}
                        td.text-sm-left {{ orderRec.billingType.billingTypeName }}
                        td {{ orderRec.loadingBase.displayBaseName }}
                        td {{ orderRec.unloadingBase.displayBaseName }}
                        td.text-sm-right {{ orderRec.inventoryHoldingUnit | addComma }}
                        td.text-sm-right {{ orderRec.convertedWeight  }}
            template(v-if="!orderDeleteListSummary.length")
              tr
                td(:colspan="13") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
        BjiImportOrderRecordEditDialog(ref="orderDialog" @updated="updateOrder" ,
          :postUrl="$config.urls.postBjiImportOrder" :deleteUrl="$config.urls.deleteBjiImportOrder")
        BjiImportOrderDetailRecordEditDialog(ref="detailDialog" @updated="updateOrder" ,
          :postUrl="$config.urls.postBjiImportOrderDetail" :deleteUrl="$config.urls.deleteBjiImportOrderDetail")
</template>

<script>
import { mapGetters } from "vuex";
import { LabelName } from "@/assets/js/constants";
import EditDialog from "@/components/common/dialog/EditDialog";
import BjiImportOrderDetailRecordEditDialog from "@/components/order/BjiImportOrderDetailRecordEditDialog";
import BjiImportOrderRecordEditDialog from "@/components/order/BjiImportOrderRecordEditDialog";
import EventDispatcher from "@/mixin/EventDispatcher";
import ScrollHandler from "@/mixin/InfiniteScrollHandler";

export default {
  mixins: [ScrollHandler, EventDispatcher],
  components: {
    EditDialog,
    BjiImportOrderDetailRecordEditDialog,
    BjiImportOrderRecordEditDialog
  },
  data() {
    return {
      labelName: LabelName,
      loading: {
        commit: false
      },
      accordions: { ...Object }
    };
  },
  methods: {
    /**
     * DriverNameに紐付くルートNoリストの表示非表示を切り替える
     * @param rec
     */
    toggleDetail(rec) {
      this.accordions[rec.id] = !this.accordions[rec.id];
    },
    updateOrder() {
      this.$emit("updateOrder");
    },
    /**
     * 受注データ取得
     **/
    async loadOrders() {
      const self = this;
      return this.$store
        .dispatch(
          "order/fetchAllBjiOrderDelete",
          Object.assign(this.searchConditions, { pageCount: this.pageCount })
        )
        .then(() => {
          this.$store.state.order.bjiOrderDeleteList.orderList.forEach(rec => {
            if (self.accordions[rec.id] === undefined) {
              this.$set(self.accordions, rec.id, false);
            }
          });
        })
        .finally(() => {
          //一覧の検索やクリアボタンローディング制御の為にイベント発行。
          this.dispatchCommonEvent(window.Common.Config.observer.onPagingListTableAjaxCompleted);
        });
    },

    onUpdateOrder() {
      this.loadOrders();
    }
  },
  computed: {
    ...mapGetters({
      getDistance: "site/distanceTypeStringFromKey"
    }),
    orderDeleteListSummary: {
      get() {
        return this.$store.state.order.bjiOrderDeleteList.orderList;
      }
    }
  },
  mounted() {
    this.$store.state.order.bjiOrderDeleteList.orderList = [];
  },
  destroyed() {
    this.$store.state.order.bjiOrderDeleteList.orderList = [];
  }
};
</script>

<style lang="stylus" scoped>
.order-table {
  th, td {
    border-right: 1px solid #E0E0E0;
  }

  th:last-child, td:last-child {
    border-right: none;
  }

  .no-right-border {
    border-right: none;
  }
}
</style>
