<template lang="pug">
  v-select(
    v-model="p_code"
    :items="masterList"
    item-text="branchName"
    item-value="id"
    label="荷主枝番"
    :clearable="clearable"
    :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")
</template>

<script>
import * as Enum from "@/assets/js/enum";

export default {
  props: {
    // TODO:type:String 必須でありながらNullがはいる可能性があるため回避。（※初期値）
    // eslint-disable-next-line vue/require-prop-types
    code: {
      required: true
    },
    // TODO:type:Number 必須でありながらNullがはいる可能性があるため回避。（※初期値）
    // eslint-disable-next-line vue/require-prop-types
    clientId: {
      required: true
    },
    clientBranchId: {
      type: Number,
      default: null
    },
    filterSrc: {
      type: Boolean,
      default() {
        return false;
      }
    },
    clientBranchIdList: {
      type: Array,
      required: false,
      default: () => []
    },
    errorHandlerMethod: {
      type: Function,
      default: () => null
    },
    errorHandlerMethodArg: {
      type: String,
      default() {
        return "clientBranchId";
      }
    },
    isSelectBjiAll: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      records: [],
      defaultErrorHandlerMethodArg: ""
    };
  },
  computed: {
    p_code: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (this.code !== newVal) {
          this.$emit("update:code", newVal);
          var data = this.masterList.filter(value => value.id === newVal);
          this.$emit("updateLabel", data[0].branchName);
        }
      },
      required: true
    },
    masterList: {
      get() {
        return this.records;
      }
    },
    errorHandlerMethodArgComputed: {
      get() {
        if (this.errorHandlerMethodArg == null) {
          return this.defaultErrorHandlerMethodArg;
        }
        return this.errorHandlerMethodArg;
      }
    }
  },
  watch: {
    clientId(value) {
      this.loadData(value);
    },
    filterSrc(value) {
      this.loadData(this.clientId);
    }
  },
  methods: {
    loadData(clientId) {
      if (clientId == null || clientId === "") {
        this.records = [];
        return;
      }
      const self = this;
      if (this.isSelectBjiAll) {
        this.$store.dispatch("client/fetchAllBranchTypeByClientIdAll", clientId).then(data => {
          // 登録済みデータの選択中荷主枝番と状態：有効のみ選択可
          self.records = data.filter(
            record => record.status === Enum.ActiveFlag.Active.code || self.clientBranchId === record.id
          );
          if (this.filterSrc) {
            self.records = self.records.filter(record => this.clientBranchIdList.includes(record.id));
          }
        });
      } else {
        this.$store.dispatch("client/fetchAllBranchTypeByClientId", clientId).then(data => {
          // 登録済みデータの選択中荷主枝番と状態：有効のみ選択可
          self.records = data.filter(
            record => record.status === Enum.ActiveFlag.Active.code || self.clientBranchId === record.id
          );
        });
      }
    }
  },
  mounted() {
    this.loadData(this.clientId);
  }
};
</script>
