<template lang="pug">
  table(class="sticky_table")
    thead
      tr
        th(v-for="(header, index) in propHeader")
          div(class="py-3 th-text font-weight-thin grey--text text--darken-1") {{ header.text }}

    tbody
      tr(v-if="getTableDataList.length" v-for="item in getTableDataList" :active="selected" @click="openDetail(item.id)")
        td(v-if="!displaySlotBody" v-for="header in propHeader" v-bind:class="{'text-xs-center': header.centerAlign}") {{ getItemValue(item, header) }}
        slot(v-if="displaySlotBody" name="body" v-bind:body="item")

      tr(v-if="!getTableDataList.length")
        td(:colspan="propHeader.length") {{ this.$config.messages.Common.Warn.SearchResultNotFound }}
</template>

<script>
import _ from "lodash";

export default {
  props: {
    propHeader: {
      type: Array,
      required: true
    },
    propData: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    detailUrl: {
      type: String,
      default: () => ""
    },
    displaySlotBody: {
      type: Boolean,
      default() {
        return false;
      }
    },
    usePaging: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      dataHeader: [],
      selected: [],
      dialog: false,
      pageCount: 1
    };
  },
  computed: {
    getTableDataList: {
      get() {
        if (this.usePaging) {
          return this.propData;
        }

        /**
         * フィルタ済みデータをページ表示数分だけ表示する（スクロールすると表示行が増える）ダミー実装
         */
        return this.propData.filter((value, index) => {
          return index < this.pageCount * 100;
        });
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.dataHeader = this.propHeader;
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    /**
     * 詳細ダイアログを開く
     **/
    openDetail(id) {
      if (_.isEmpty(this.detailUrl)) return;

      this.$store
        .dispatch(this.detailUrl, id)
        .then(value => {
          this.$emit("open-detail", value);
        })
        .catch(reason => {
          const event = document.createEvent("Event");
          event.initEvent(window.Common.Config.observer.onErrorResponse404, false, true);
          window.dispatchEvent(event);
        });
    },
    /**
     * スクロール操作を監視して、末尾まで行くと
     * ページング用変数の値をカウントアップ
     */
    handleScroll() {
      if (this.getScrollBottom() < 1) {
        this.pageCount++;
        this.$emit("pageUpdated", this.pageCount);
      }
    },
    /**
     * ブラウザのスクロールバーの位置を取得
     * @returns {number}
     */
    getScrollBottom() {
      const body = window.document.body;
      const html = window.document.documentElement;
      const scrollTop = body.scrollTop || html.scrollTop;
      return html.scrollHeight - html.clientHeight - scrollTop;
    },
    getDataFromKey(item, key) {
      let rslt = item;
      const keyArray = key.split(".");
      for (let i = 0; i < keyArray.length; i++) {
        if (rslt == null) break;
        rslt = rslt[keyArray[i]];
      }
      return rslt == null ? "" : rslt;
    },
    getItemValue(item, header) {
      if (header.displayFunc != null && header.displayFunc instanceof Function) {
        return header.displayFunc(item, header);
      } else {
        return this.getDataFromKey(item, header.value);
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 1;
}

table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
}

tbody tr:hover {
  background-color: #f5f5f5;
  transition: 0.2s;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0px;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
}

.th-text {
  margin-top: 20px;
  font-size: 13px;
}

.v-text-field {
  padding-top: 8px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 14px;
  font-weight: 400;
}
</style>
