<template lang="pug">
  EditDialog(ref="EditDialog" title="配送委託先会社" :recordId="record.id" @delete="erase" @save="save"  :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)

        v-layout(row wrap)
        v-layout(row wrap)
          // 配送委託先会社名
          v-flex(xs12 md6)
            v-text-field(
              v-model="record.companyName"
              label="配送委託先会社名"
              :error-messages="getErrorMessages('companyName')")

          // 配送委託先会社名
          v-flex(xs12 md6)
            v-text-field(
              v-model="record.companyNameOfficial"
              label="配送委託先会社名(正式名称)"
              :error-messages="getErrorMessages('companyNameOfficial')")

        v-layout(row wrap)
          // 郵便番号
          v-flex(xs12 md3)
            v-text-field(
              v-model="record.zipCode"
              label="郵便番号"
              :error-messages="getErrorMessages('zipCode')")

          // 住所
          v-flex(xs12 md9)
            v-text-field(
              v-model="record.headOfficeAddress"
              label="住所"
              :error-messages="getErrorMessages('headOfficeAddress')")

          // 契約書有無
          v-flex(xs12 md3)
            ContractExistsSelect(:code.sync="record.contractSigned" :errorHandlerMethod="getErrorMessages")

          // 契約年月日
          v-flex(xs12 md3)
            DatePicker(:inputDate.sync="record.contractDate" pickerLabel="契約年月日" :nudgeRight="200" :isEditable="true" :isClearable="true" :errorHandlerMethod="getErrorMessages" errorHandlerMethodParam="contractDate")

          // 配送委託先会社コード
          v-flex(xs12 md3 v-if="record.id != null")
            v-text-field(
              v-model="record.companyCd" box readonly
              label="配送委託先会社コード"
              :error-messages="getErrorMessages('companyCd')")

          v-flex(xs12)
            v-card
              v-card-text
                v-layout(row wrap)
                  // 担当者（輸送依頼書）
                  v-flex(xs12 md6)
                    v-text-field(
                      v-model="record.transportStaff"
                      label="担当者（輸送依頼書）"
                      :error-messages="getErrorMessages('transportStaff')")

                  // メール（輸送依頼書）
                  v-flex(xs12 md6)
                    v-text-field(
                      v-model="record.transportMail"
                      label="メール（輸送依頼書）"
                      :error-messages="getErrorMessages('transportMail')")

                  // 電話番号（輸送依頼書）
                  v-flex(xs12 md4)
                    v-text-field(
                      v-model="record.transportTel"
                      label="電話番号（輸送依頼書）"
                      :error-messages="getErrorMessages('transportTel')")

                  // FAX（輸送依頼書）
                  v-flex(xs12 md4)
                    v-text-field(
                      v-model="record.transportFax"
                      label="FAX（輸送依頼書）"
                      :error-messages="getErrorMessages('transportFax')")

                  // FAX送信対象
                  v-flex(xs12 md4)
                    FaxTargetSelect(labelDisp="輸送依頼書FAX送信対象" :code.sync="record.transportFaxTarget" :errorHandlerMethod="getErrorMessages")


                  // 下請法該当フラグアラートメッセージ
                  v-flex(xs12)
                    v-alert(:value="true" type="warning" outline) {{ $config.messages.Common.Warn.SubcontractingLawFlgAutoSave }}
                  // 資本金
                  v-flex(xs12 md4)
                    DigitSeparatorsField(v-model="record.capital" label="資本金" suffix="万円"
                      :error-messages="getErrorMessages('capital')"
                    )
                  // 下請法該当フラグ
                  v-flex(xs12 md4)
                    v-checkbox(label="下請法該当フラグ" v-model="record.subcontractingLawFlg" hide-details :disabled="isSubcontractingLawFlgDisable")
          v-flex(xs12)
            v-card
              v-card-text
                v-layout(row wrap)
                  // 担当者（支払）
                  v-flex(xs12 md6)
                    v-text-field(
                      v-model="record.paymentStaff"
                      label="担当者（支払）"
                      :error-messages="getErrorMessages('paymentStaff')")

                  // メール（支払）
                  v-flex(xs12 md6)
                    v-text-field(
                      v-model="record.paymentMail"
                      label="メール（支払）"
                      :error-messages="getErrorMessages('paymentMail')")

                  // 電話番号（支払）
                  v-flex(xs12 md6)
                    v-text-field(
                      v-model="record.paymentTel"
                      label="電話番号（支払）"
                      :error-messages="getErrorMessages('paymentTel')")

                  // FAX（支払）
                  v-flex(xs12 md6)
                    v-text-field(
                      v-model="record.paymentFax"
                      label="FAX（支払）"
                      :error-messages="getErrorMessages('paymentFax')")

                  v-flex(xs12 md3)
                    CutOffDateTypeSelect(labelDisp="支払締日" :code.sync="record.paymentCutOffDateType" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="paymentCutOffDateType")

                  v-flex(xs12 md3)
                    BaseDateTypeSelect(labelDisp="支払基準日" :code.sync="record.paymentBaseDateType" :errorHandlerMethod="getErrorMessages" errorHandlerMethodArg="paymentBaseDateType")

                  // FAX送信対象
                  v-flex(xs12 md3)
                    FaxTargetSelect(labelDisp="支払書FAX送信対象" :code.sync="record.paymentStatementFaxTarget" :errorHandlerMethod="getErrorMessages")

        v-layout(row wrap)
          // ステータス
          v-flex(xs12 md3)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")

        v-layout(row wrap)
          // 備考
          v-flex(xs12 md12)
            v-textarea(v-model="record.remark" label="備考" :error-messages="getErrorMessages('remark')" maxlength=2000)

</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ContractExistsSelect from "@/components/common/select/ContractExistsSelect";
import StatusSelect from "@/components/common/select/StatusSelect";
import DatePicker from "@/components/common/DatePicker";
import FaxTargetSelect from "@/components/common/select/FaxTargetSelect";
import CutOffDateTypeSelect from "@/components/common/select/CutOffDateTypeSelect";
import BaseDateTypeSelect from "@/components/common/select/BaseDateTypeSelect";

import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";
import DigitSeparatorsField from "@/components/common/DigitSeparatorsField.vue";

export default {
  components: {
    DigitSeparatorsField,
    EditDialog,
    ContractExistsSelect,
    StatusSelect,
    DatePicker,
    FaxTargetSelect,
    CutOffDateTypeSelect,
    BaseDateTypeSelect
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {}
    };
  },
  computed: {
    isSubcontractingLawFlgDisable() {
      if (this.record.capital == null) {
        return false;
      }
      return String(this.record.capital).length > 0;
    }
  },
  methods: {
    initData: () => {
      return {
        id: null,
        companyCd: null,
        companyName: null,
        companyNameOfficial: null,
        zipCode: null,
        headOfficeAddress: "管理外",
        contractSigned: false,
        contractDate: null,
        trasnportStaff: null,
        transportMail: null,
        transportTel: null,
        transportFax: null,
        transportFaxTarget: Enum.FaxTargetFlag.Exclusion.code,
        paymentStaff: null,
        paymentMail: null,
        paymentTel: null,
        paymentFax: null,
        paymentCutOffDateType: Enum.CutOffDateType.EndNextMonth.code,
        paymentBaseDateType: Enum.BaseDateType.ShippingDate.code,
        paymentStatementFaxTarget: Enum.FaxTargetFlag.Unset.code,
        remark: null,
        status: Enum.ActiveFlag.Active.code,
        capital: null,
        subcontractingLawFlg: false
      };
    },
    openDialog(item) {
      this.initForm(item);
      this.$refs.EditDialog.openDialog();
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(value => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(value => {
        self.closeDialog();
      });
    }
  },
  created() {
    this.record = this.initData();
  }
};
</script>
