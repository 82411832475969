<template lang="pug">
  SearchConditions(:initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      v-layout(wrap)
        v-flex(md2)
          v-text-field(label="支払項目名" v-model="conditions.paymentItemName")
        v-flex(md2)
          SaleTaxTargetSelect(:code.sync="conditions.saleTaxTarget" :clearable="true")
        v-flex(md2)
          v-text-field(label="表示順" v-model="conditions.sortOrder")
        v-flex(xs12 md3)
          v-checkbox(label="表示対象のみ" v-model="conditions.displayTarget" hide-details)
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import SaleTaxTargetSelect from "@/components/common/select/SaleTaxTargetSelect";

export default {
  components: {
    SearchConditions,
    SaleTaxTargetSelect
  },
  methods: {
    onChangeSearchConditions(item) {
      this.$emit("onChangeSearchConditions", item);
    },
    initSearchCond() {
      return {
        conditions: {
          financialCd: "",
          paymentItemName: "",
          saleTaxTarget: "",
          sortOrder: ""
        }
      };
    }
  }
};
</script>
