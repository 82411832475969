<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力
                  v-btn(color="info" to="/tms/management/base-distance/import") インポート

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      BaseDistanceFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              BaseDistanceTable(ref="BaseDistanceTable" :prop-header="baseDistanceTableHeader" :prop-data="baseDistanceList" @open-detail="openDetail",
                :getListActionName="getListActionName" detailUrl="baseDistance/fetchBaseDistance" :displaySlotBody="true")
                  template(v-slot:body="{ body }")
                    td.text-xs-left {{ body.client.clientDisplayName }}
                    td.text-xs-left {{ body.clientBranch.branchName }}
                    td.text-xs-left {{ body.loadingBase.nameAndCd }}
                    td.text-xs-left {{ body.unloadingBase.nameAndCd }}
                    td.text-xs-center {{ body.applicableStartDate }}
                    td.text-xs-right {{ body.distance }}
                    td.text-xs-left {{ body.paymentTariffType.name }}
                    td.text-xs-left
                      span.d-block {{ body.billingTariff.tariffCd }}
                      span.d-block {{ body.billingTariff.tariffNameDisplay }}
                    td
                      v-tooltip(top)
                        template(v-slot:activator="{ on }")
                          p(v-on="on").remark.whitespace-pre {{ body.remark }}
                        span.whitespace-pre {{ body.remark }}

    BaseDistanceEditDialog(ref="BaseDistanceEditDialog" :postUrl="$config.urls.postEditBaseDistance" :deleteUrl="$config.urls.deleteBaseDistance" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('baseDistance/export')")
</template>

<script>
import BaseDistanceEditDialog from "@/components/management/base-distance/BaseDistanceEdit";
import BaseDistanceTable from "@/components/common/PagingListTable";
import BaseDistanceFilterConditions from "@/components/management/base-distance/BaseDistanceFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    BaseDistanceEditDialog,
    BaseDistanceTable,
    BaseDistanceFilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    getListActionName: {
      get() {
        return "baseDistance/fetchAllBaseDistance";
      }
    },
    baseDistanceList: {
      get() {
        return this.$store.state.baseDistance.baseDistanceList;
      }
    },
    baseDistanceTableHeader: {
      get() {
        return [
          { text: "荷主" },
          { text: "荷主枝番" },
          { text: "積地" },
          { text: "卸地" },
          { text: "適用開始日" },
          { text: "距離" },
          { text: "支払タリフ区分" },
          { text: "請求タリフCD" },
          { text: "備考" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    openAddDialog() {
      this.$refs.BaseDistanceEditDialog.openDialog();
    },
    openDetail(item) {
      this.$refs.BaseDistanceEditDialog.openDialog(item);
    },
    loadListData() {
      this.$refs.BaseDistanceTable.loadData();
    },
    itemUpdated(response, data) {
      this.$refs.BaseDistanceTable.resetPageCount();
      this.loadListData();
    },
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.BaseDistanceTable.setSearchConditions(searchCond);
      this.$refs.BaseDistanceTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  }
};
</script>
