import { render, staticRenderFns } from "./DispatchDetail.vue?vue&type=template&id=38761165&scoped=true&lang=pug"
import script from "./DispatchDetail.vue?vue&type=script&lang=js"
export * from "./DispatchDetail.vue?vue&type=script&lang=js"
import style0 from "./DispatchDetail.vue?vue&type=style&index=0&id=38761165&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38761165",
  null
  
)

export default component.exports