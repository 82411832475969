<template lang="pug">
  span
    v-btn(flat icon v-if="status == 0" @click="checkAll")
      v-icon check_box_outline_blank
    v-btn(flat icon v-if="status == 1" @click="unCheckAll")
      v-icon(color="primary") check_box
    v-btn(flat icon v-if="status == 2" @click="unCheckAll")
      v-icon indeterminate_check_box
</template>

<script>
export default {
  data: () => ({
    status: 0
  }),
  methods: {
    checkAll() {
      this.status = 1;
      this.$emit("checkAll");
    },
    unCheckAll() {
      this.status = 0;
      this.$emit("unCheckAll");
    },
    onIndeterminate(isIndeterminate) {
      if (isIndeterminate) {
        this.status = 2;
      } else {
        this.status = 1;
      }
    }
  }
};
</script>

<style scoped></style>
