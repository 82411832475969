<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      label="エリア"
      append-outer-icon="search"
      @click:append-outer="openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem"
      :config="config" :initSearchCond="initSearchCond" :getListActionName="'area/fetchAllArea'")
</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  data() {
    return {
      defaultErrorHandlerMethodArg: "areaId",
      config: {
        title: "エリア選択",
        headers: [
          {
            text: "エリア名",
            key: "name",
            align: ""
          }
        ],
        width: "300px"
      },
      initSearchCond: () => {
        return {
          name: ""
        };
      }
    };
  }
};
</script>
