export default {
  methods: {
    /** 日付2つを受け取って、同じならひとつ、異なるならふたつを改行して表示 */
    getCombineDate(date1, date2) {
      // nullの日付は出力しない
      if (!date1 && !date2) return "";
      if (date1 === date2) {
        return date1;
      } else if (date1 && date2) {
        return date1 + "," + date2;
      } else if (!date1) {
        return date2;
      } else {
        date1;
      }
    }
  }
};
