<template lang="pug">
  transition(name="fade" mode="out-in")
    section.loading-wrapper(v-if="isDisplay" :class="[getClass]")
      v-progress-circular(v-show="isCircle && isShow" indeterminate color="grey" :size="size" :width="4").loading-circular
      v-progress-linear(v-show="!isCircle" indeterminate color="grey" :size="size" :width="4").loading-liner
</template>

<script>
export default {
  props: {
    isDisplay: {
      type: Boolean,
      default: () => false
    },
    isShow: {
      type: Boolean,
      default: () => true
    },
    isCircle: {
      type: Boolean,
      default: () => true
    },
    positionFixed: {
      type: Boolean,
      default: () => true
    },
    size: {
      type: Number,
      default: () => 200
    }
  },
  computed: {
    getClass() {
      return this.positionFixed ? "loading-fixed" : "loading-absolute";
    }
  }
};
</script>

<style scoped lang="stylus">

//ローディングクルクル表示用のラッパー
.loading-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(100, 100, 100, 0.1);
  z-index: 5;

  .loading-circular {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin 0 auto
  }

  .loading-liner {
    margin 0
  }
}

.loading-fixed {
  position: fixed;
}

.loading-absolute {
  position: absolute;
}
</style>
