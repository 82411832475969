<template lang="pug">
  section
    v-alert(type="warning" value="true" v-if="errorMessage !== ''" outline) {{ errorMessage }}
    table(class="sticky_table")
      tbody
        tr(v-if="propData.length" v-for="(item, index) in propData")
          td.text-sm-left {{ item.driverName }}
          td.text-sm-center
            v-btn(icon flat @click="deleteDriverName(index)")
              v-icon(color="grey") delete

</template>

<script>
export default {
  props: {
    propData: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      required: false,
      default: () => ""
    }
  },
  methods: {
    deleteDriverName(index) {
      this.$emit("delete", index);
    }
  }
};
</script>

<style lang="stylus" scoped>
.sticky_table thead th {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 45px;
  z-index: 1;
}

table.sticky_table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: auto;
}

tbody tr:hover {
  background-color: #f5f5f5;
  transition: 0.2s;
}

td {
  height: 48px;
  border: solid #dcdcdc;
  border-width: 1px 0;
  vertical-align: middle;
  padding-left: 25px;
  padding-right: 25px;
}

.th-text {
  margin-top: 20px;
  font-size: 13px;
}
</style>
