<template lang="pug">
  EditDialog(ref="EditDialog" title="BJI受注明細"  @save="save" @delete="erase"
    :displayDeleteButton="!isDispatched(record) && canEditStatus(record)"
    :enableSave="canEditStatus(record)"
    :loadingDelete="loading.delete" :recordId="record.details.firstItem.id")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          v-flex(xs12 md2)
            v-text-field(readonly box label="プランボードNo" v-model="record.planBoardNo")
          v-flex(xs12 md2)
            v-text-field(readonly box label="ルートNo" v-model="record.routeNoWithSeq")
          v-flex(xs12 md2)
            v-text-field(readonly box label="伝票番号" v-model="record.details.firstItem.slipNo")
          v-flex(xs12 md3)
            v-text-field(readonly box label="DriverName" v-model="record.driverName")
        v-layout(row)
          v-flex(xs12 md6)
            v-text-field(readonly box label="積地" v-model="record.details.firstItem.loadingBase.displayBaseName")
        v-layout(row)
          v-flex(xs12 md6)
            v-text-field(readonly box label="卸地" v-model="record.details.firstItem.unloadingBase.displayBaseName")
          v-flex(md3 v-if="canEditStatus(record)")
            TimePicker(pickerLabel="時間指定(開始)" initTime="" :inputTime.sync="record.details.firstItem.unloadingSpecifiedTime.from",
              :errorHandlerMethod="getErrorMessages" errorHandlerMethodParam="unloadingSpecifiedTimeFrom" )
          v-flex(md3 v-else)
            v-text-field(readonly box label="時間指定(開始)" v-model="record.details.firstItem.unloadingSpecifiedTime.from")
          v-flex(md3 v-if="canEditStatus(record)")
            TimePicker(pickerLabel="時間指定(終了)" initTime="" :inputTime.sync="record.details.firstItem.unloadingSpecifiedTime.to",
              :errorHandlerMethod="getErrorMessages" errorHandlerMethodParam="unloadingSpecifiedTimeTo" )
          v-flex(md3 v-else)
            v-text-field(readonly box label="時間指定(終了)" v-model="record.details.firstItem.unloadingSpecifiedTime.to")


        v-layout(row wrap)
          v-flex(xs12 md2)
            v-text-field(readonly box :label="labelName.LoadingSchedule" v-model="record.loadingDate")
          v-flex(xs12 md2)
            v-text-field(readonly box :label="labelName.UnloadingSchedule" v-model="record.unloadingDate")
          v-flex(xs12 md2)
            v-text-field.text-align-right(readonly box label="在庫保持単位" v-model="record.details.firstItem.inventoryHoldingUnit")
          v-flex(xs12 md2)
            v-text-field.text-align-right(readonly box label="総重量" v-model="record.details.firstItem.convertedWeight" suffix="kg")
          v-flex(xs12 md4)
            v-text-field.text-align-left(readonly box label="車格" v-model="dispVehicleCarryWeight")

        v-layout(row wrap)
          v-flex(xs12 md2)
            v-select(readonly box v-model="record.distanceType" :items="distanceTypes" item-text="label" item-value="code" label="距離区分")

          v-flex(xs12 md10)
            v-text-field(readonly box label="荷主枝番" v-model="record.details.firstItem.clientBranch.branchName")
</template>

<script>
import EditDialog from "../common/dialog/EditDialog";
import BillingTypeSelect from "../common/select/BillingTypeSelect";
import ApiHandler from "../../mixin/ApiHandler";
import TimePicker from "@/components/common/TimePicker";
import SpecifiedTimeTypeIcon from "@/components/common/SpecifiedTimeTypeIcon";

import * as Enum from "@/assets/js/enum";
import { LabelName } from "@/assets/js/constants";

export default {
  components: { SpecifiedTimeTypeIcon, EditDialog, BillingTypeSelect, TimePicker },
  mixins: [ApiHandler],
  data() {
    return {
      labelName: LabelName,
      record: this.initData(),
      tranData: {},
      clientId: 0
    };
  },
  computed: {
    routeNoWithSeq: {
      get() {
        return this.record.routeNo + " - " + this.record.routeNoSeq;
      }
    },
    distanceTypes: function () {
      return Enum.Utils.enumToList(Enum.DistanceType);
    },
    isDispatched: function () {
      return function (record) {
        return record.dispatched || record.relayed;
      };
    },
    canEditStatus: function () {
      return function (record) {
        if (
          record.billingStatus !== Enum.BillingStatusType.CONFIRMED.code &&
          record.billingStatus !== Enum.BillingStatusType.PUBLISHED.code &&
          record.paymentStatus !== Enum.PaymentStatusType.CONFIRMED.code &&
          record.paymentStatus !== Enum.PaymentStatusType.PUBLISHED.code
        ) {
          return true;
        }
        return false;
      };
    },
    dispVehicleCarryWeight: {
      get() {
        return (
          this.record.vehicleCarryWeight.vehicleTypeName +
          "/" +
          this.record.vehicleCarryWeight.carryWeight +
          "kg"
        );
      }
    }
  },
  methods: {
    clear() {
      Object.assign(this.$data.record, this.initData());
      this.errors = {};
    },
    initData() {
      return {
        clientId: "1",
        driverName: "",
        orderDate: "",
        loadingDate: "",
        unloadingDate: "",
        planBoardNo: "",
        routeNo: "",
        routeNoSeq: "",
        totalGrossWeight: "",
        totalInventoryHoldingUnit: "",
        distanceTypeName: "",
        details: {
          firstItem: {
            detailId: null,
            slipNo: "",
            inventoryHoldingUnit: "",
            grossWeight: "",
            clientBranch: {
              branchName: ""
            },
            billingType: {
              id: ""
            },
            unloadingSpecifiedTime: {
              from: "",
              to: ""
            },
            loadingBase: {
              baseName: ""
            },
            unloadingBase: {
              baseName: ""
            }
          },
          loadingBaseNames: "",
          clientNames: ""
        },
        vehicleCarryWeight: {
          vehicleTypeName: "",
          carryWeightDisp: ""
        }
      };
    },
    openDialog(detailId) {
      this.clear();
      this.$store
        .dispatch("order/fetchDetailOrder", detailId)
        .then(value => {
          Object.assign(this.$data.record, value);
          this.$refs.EditDialog.openDialog();
        })
        .catch(reason => {
          const event = document.createEvent("Event");
          event.initEvent(window.Common.Config.observer.onErrorResponse404, false, true);
          window.dispatchEvent(event);
        });
    },
    save() {
      const self = this;
      this.setTranData();
      this.saveRecord(this.tranData).then(value => {
        self.$refs.EditDialog.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord(this.record.details.firstItem.id).then(value => {
        self.$refs.EditDialog.closeDialog();
      });
    },
    setTranData() {
      Object.assign(this.tranData, {
        detailId: this.record.details.firstItem.id,
        unloadingSpecifiedTimeFrom: this.record.details.firstItem.unloadingSpecifiedTime.from,
        unloadingSpecifiedTimeTo: this.record.details.firstItem.unloadingSpecifiedTime.to,
        updatedAt: this.record.details.firstItem.updatedAt
      });
    },
    openErrorMsgBox(message) {
      this.$refs.EditDialog.openErrorMsgBox(message);
    }
  }
};
</script>
