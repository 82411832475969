<template lang="pug">
  section
    v-text-field(
      readonly
      :box="readonly"
      v-model="p_label"
      label="都道府県"
      :append-outer-icon="SearchIcon"
      @click:append-outer="_openDialog"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    Dialog(ref="dialog" v-on:select:record="selectItem" :config="config")
</template>

<script>
import ApiHandler from "../../../mixin/ApiHandler";
import MasterSelectDialog from "../../../mixin/MasterSelectDialog";
import Dialog from "../SimpleMasterSelectDialog";

export default {
  components: { Dialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "prefId",
      config: {
        title: "都道府県選択",
        headers: [
          {
            text: "都道府県",
            value: "name",
            align: "",
            search: ""
          }
        ],
        initDispatch: "prefecture/fetchAll",
        getStore: function () {
          return this.$store.state.prefecture.prefectureList;
        },
        width: "300px"
      }
    };
  },
  computed: {
    /**
     * @return {string}
     */
    SearchIcon() {
      return this.readonly ? "" : "search";
    }
  },
  methods: {
    _openDialog() {
      for (let i = 0; i < this.config.headers.length; i++) {
        this.config.headers[i].search = "";
      }
      this.openDialog();
    }
  }
};
</script>
