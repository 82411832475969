<template lang="pug">
  section
    template(v-if="!isReadonly")
      v-text-field(
        readonly
        v-model="getLabel"
        :label="labelDisp"
        append-outer-icon="search"
        @click:append-outer="openDialog"
        :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

      PagingMasterSelectDialog(ref="dialog" v-on:select:record="selectItem" :displayFilter="false"
        :config="config" :initSearchCond="initSearchCond" :getListActionName="'vehicle/fetchAllVehicleCarryWeight'")
    template(v-else)
      v-text-field(
        readonly
        box
        v-model="getLabel"
        :label="labelDisp")

</template>

<script>
import ApiHandler from "@/mixin/ApiHandler";
import MasterSelectDialog from "@/mixin/MasterSelectDialog";
import PagingMasterSelectDialog from "@/components/common/PagingMasterSelectDialog";

export default {
  components: { PagingMasterSelectDialog },
  mixins: [ApiHandler, MasterSelectDialog],
  props: {
    labelDisp: {
      type: String,
      default: "車格 / 積載重量"
    },
    errorHandlerMethod: {
      type: Function,
      required: false,
      default() {
        return null;
      }
    },
    vehicleTypeId: {
      type: [String, Number],
      default: null
    },
    vehicleTypeName: {
      type: String,
      default: null
    },
    displayMaxWeight: {
      type: Boolean,
      default: () => false
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
    selectVehicleTypeId: {
      type: [Number],
      default: null
    }
  },
  data() {
    return {
      defaultErrorHandlerMethodArg: "vehicleCarryWeightId",
      config: {
        title: "車格積載重量",
        headers: [
          {
            text: "車格",
            key: "vehicleTypeName",
            align: ""
          },
          {
            text: "積載重量",
            key: "carryWeightDisplay",
            rightAlign: "true",
            search: "carryWeight"
          },
          {
            text: "最大積載重量",
            key: "maxCarryWeightDisplay",
            rightAlign: "true",
            search: "maxCarryWeight"
          }
        ]
      },
      initSearchCond: () => {
        return {
          vehicleTypeId: this.vehicleTypeId
        };
      },
      carryWeightAllList: []
    };
  },
  computed: {
    getLabel: {
      get() {
        let data = this.carryWeightAllList
          .filter(value => {
            if (value.id === this.p_code) return true;
          })
          .pop();

        if (data === undefined) {
          return "";
        }

        if (this.displayMaxWeight) {
          return data.vehicleTypeName + " / " + data.carryWeight + "kg" + " / " + data.maxCarryWeight + "kg";
        } else {
          return data.vehicleTypeName + " / " + data.carryWeight + "kg";
        }
      }
    },
    p_selectVehicleTypeId: {
      get() {
        return this.selectVehicleTypeId;
      },
      set(newVal) {
        if (this.selectVehicleTypeId !== newVal) this.$emit("update:selectVehicleTypeId", newVal);
      },
      required: true
    }
  },
  methods: {
    selectItem(data) {
      this.p_code = data.id;
      this.p_selectVehicleTypeId = data.vehicleTypeId;
      this.$emit("setSelectData", data);
    }
  },
  created() {
    this.$store
      .dispatch("vehicle/fetchAllVehicleCarryWeight", {
        pageCount: 0,
        unlimited: true
      })
      .then(value => {
        this.carryWeightAllList = value;
      });
  }
};
</script>
