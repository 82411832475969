<template lang="pug">
  span
    FinanceOverrideWarningDialog(ref="FinanceOverrideWarningDialog" @yes="changeToTemporaryConfirm" @close="loading = false" @NotEditableConfirmed="NotEditableConfirmedPayment" :message="message.published")
    template(v-if="temporaryConfirmedMode" )
      v-btn(:disabled="disabled" color="success" v-if="isPending(paymentCard.paymentStatus)" @click="changeToTemporaryConfirmPre" :loading="loading")
        span 仮確定を行う
      v-btn(color="info" v-if="isTemp(paymentCard.paymentStatus)" @click="changeToPending" :loading="loading")
        span 未確定に戻す
    template(v-if="publishMode" )
      v-btn(color="warning" v-if="isPublished(paymentCard.paymentStatus)" )
        span 仮確定に戻す
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as Enum from "@/assets/js/enum";
import PaymentStatusTypeHandler from "@/mixin/PaymentStatusTypeHandler";
import FinanceOverrideWarningDialog from "@/components/common/dialog/FinanceOverrideWarningDialog";

export default {
  props: {
    temporaryConfirmedMode: {
      type: Boolean,
      default: false
    },
    publishMode: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cardId: {
      type: Number,
      default: null
    }
  },
  mixins: [PaymentStatusTypeHandler],
  components: {
    FinanceOverrideWarningDialog
  },
  data() {
    return {
      message: {
        published:
          "同一キー(1次配送委託先・支払対象年月)のカードに発行済のカードが存在します。すべて仮確定に変更しますか？",
        confirmed:
          "同一キー(1次配送委託先・支払対象年月)のカードに確定済カードが含まれています。確定済カードは状態を変更する事ができません。"
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters("finance", {
      paymentCard: "paymentCard",
      paymentCardDetail: "paymentCardDetail",
      paymentCardBasicPrice: "paymentCardBasicPrice",
      paymentCardToll: "paymentCardToll",
      paymentCardSummaryWithoutToll: "paymentCardSummaryWithoutToll"
    })
  },
  methods: {
    ...mapActions("finance", {
      postPaymentCardStatus: "postPaymentCardStatus"
    }),
    NotEditableConfirmedPayment() {
      this.$emit("openErrorMessage", this.message.confirmed);
      this.loading = false;
    },
    changeToTemporaryConfirmPre() {
      this.loading = true;
      this.$refs.FinanceOverrideWarningDialog.checkPaymentStatusByKey(this.cardId);
    },
    async changeToTemporaryConfirm() {
      return await this.postPaymentCardStatus(Enum.PaymentStatusType.TEMPORARY_CONFIRMED.code)
        .then(() => {
          this.$emit("financeUpdated");
        })
        .catch(error => {
          this.$emit("openErrorMessage", error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async changeToPending() {
      this.loading = true;
      return await this.postPaymentCardStatus(Enum.PaymentStatusType.PENDING.code)
        .then(() => {
          this.$emit("financeUpdated");
        })
        .catch(error => {
          this.$emit("openErrorMessage", error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
