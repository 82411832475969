<template lang="pug">
  EditDialog(ref="EditDialog" title="カード備考" @save="save",
    :displayDeleteButton="false"
    :enableSave="canOriginCardEdit"
    :recordId="record.orderId"
    :loadingSave="loading.save"
    :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(grid-list-md)
        v-layout(row wrap)
          // カード備考
          v-flex(xs12)
            v-textarea(:readonly="!canOriginCardEdit" :box="!canOriginCardEdit" label="カード備考" auto-grow v-model="record.remark" :error-messages="getErrorMessages('remark')")

          //  スライド備考
          v-flex(xs12)
            v-textarea(v-if="refItem.isSlide" :readonly="!canOriginCardEdit" :box="!canOriginCardEdit" label="スライド備考" auto-grow v-model="record.slideRemark" :error-messages="getErrorMessages('slideRemark')")

          // 会計備考
          v-flex(v-if="!isOrderTypeTempCard(record.orderType)" xs12)
            v-textarea(:readonly="!canOriginCardEdit" :box="!canOriginCardEdit" label="会計備考" auto-grow v-model="record.financeRemark" :error-messages="getErrorMessages('financeRemark')")

</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";
import * as Enum from "@/assets/js/enum";
import { mapGetters } from "vuex";

export default {
  components: {
    EditDialog
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {
        orderId: 0
      },
      tranData: {},
      refItem: {}
    };
  },
  props: {
    getRemarkAction: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters("finance", {
      canOriginCardEdit: "canOriginCardEdit"
    })
  },
  methods: {
    /**
     * 初期データ
     */
    initData(item) {
      return {
        cardId: item.item.cardId,
        orderType: item.item.orderType,
        orderId: item.item.orderId,
        remark: null,
        updatedAt: null,
        slideRemark: null,
        slideId: null,
        slideUpdatedAt: null,
        financeRemark: null,
        cardUpdatedAt: null,
        scheduledLoadingDate: item.loadingDate
      };
    },
    /**
     * 初期化処理
     */
    setTranData() {
      this.tranData = {
        cardId: this.record.cardId,
        orderType: this.record.orderType,
        orderId: this.record.orderId,
        remark: this.record.remark,
        orderUpdatedAt: this.record.updatedAt,
        slideId: this.record.slideId,
        slideRemark: this.record.slideRemark,
        slideUpdatedAt: this.record.slideUpdatedAt,
        financeRemark: this.record.financeRemark,
        cardUpdatedAt: this.record.cardUpdatedAt
      };
    },
    openDialog(item) {
      this.record = this.initData(item);
      this.errors = {};
      this.refItem = item.item;
      const self = this;

      const params = {
        orderType: item.item.orderType,
        orderId: item.item.orderId
      };

      this.$store.dispatch(this.getRemarkAction, params).then(value => {
        Object.assign(self.record, value);
        this.$store.dispatch("dispatch/fetchSlideCardRemark", this.record).then(value => {
          if (value !== null) {
            self.record.slideRemark = value.remark;
            self.record.slideId = value.id;
            self.record.slideUpdatedAt = value.updatedAt;
          }
        });
        this.$store.dispatch("finance/fetchBillingAndPaymentCard", this.refItem.cardId).then(value => {
          self.record.financeRemark = value.card.financeRemark;
          self.record.cardUpdatedAt = value.card.updatedAt;
        });
        this.$refs.EditDialog.openDialog();
      });
    },
    closeDialog() {
      this.$refs.EditDialog.closeDialog();
    },
    save() {
      const self = this;
      this.setTranData();

      this.saveRecord(this.tranData).then(value => {
        self.refItem.hasRemark = value.data.data.remark.length != 0;
        if (value.data.data.slideRemark) {
          self.refItem.hasSlideRemark = value.data.data.slideRemark.length != 0;
        } else {
          self.refItem.hasSlideRemark = false;
        }
        self.$refs.EditDialog.closeDialog();
      });
    },
    isOrderTypeTempCard(orderType) {
      return orderType === Enum.OrderType.TempCard.code;
    }
  }
};
</script>
