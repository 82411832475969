<template lang="pug">
  section
    v-layout(row wrap)
      v-flex(xs12 md2)
        v-select(
          v-model="form.clientBranchId"
          :items="transportTypes"
          item-text="branchName"
          item-value="id"
          label="荷主枝番"
          :error-messages="getErrorMessages('clientBranchId')")

      v-flex(xs12 md2)
        v-select(
          v-model="form.distanceType"
          :items="distanceTypes"
          item-text="label"
          item-value="code"
          label="距離区分"
          :error-messages="getErrorMessages('distanceType')")

      v-flex(xs12 md2)
        DatePicker(:initDate="form.loadingDate" :inputDate.sync="form.loadingDate" :pickerLabel="labelName.LoadingSchedule")

    v-layout(row wrap class="file-upload-box")
      v-flex(xs12 md4)
        FileUploadBox(v-bind:selected-file.sync="uploadFile" v-bind:errors="getErrors")

      v-flex(xs12 md4)
        v-btn(color="success" @click="upload" :loading="loading.upload" ) アップロード

    AlertBox(ref="errorMsg")
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import FileUploadBox from "@/components/common/FileUploadBox";
import ApiHandler from "@/mixin/ApiHandler";
import AlertBox from "@/components/common/AlertBox";
import { LabelName } from "@/assets/js/constants";

import * as Enum from "@/assets/js/enum";
import { mapGetters } from "vuex";
export default {
  components: {
    DatePicker,
    FileUploadBox,
    AlertBox
  },
  mixins: [ApiHandler],
  data: function () {
    return {
      labelName: LabelName,
      files: [],
      uploadFile: {
        file: "",
        fileName: "",
        fileUrl: ""
      },
      form: {
        clientBranchId: "",
        distanceType: "",
        loadingDate: window.Common.Util.getTomorrowYearMonthDayIsoFormat()
      },
      loading: {
        upload: false
      },
      bjiImportBranchList: []
    };
  },
  computed: {
    ...mapGetters({
      bjiMaxUploadFileSize: "site/bjiMaxUploadFileSize"
    }),
    transportTypes: function () {
      return this.bjiImportBranchList;
    },
    distanceTypes: function () {
      return Enum.Utils.enumToList(Enum.DistanceType);
    },
    getErrors() {
      return this.errors;
    }
  },
  mounted() {
    const self = this;
    this.$store.dispatch("client/fetchAllBjiImportBranchType").then(value => {
      self.bjiImportBranchList = value;
    });
  },
  methods: {
    selectedFile: function (e) {
      // 選択された File の情報を保存しておく
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
    },
    upload: function () {
      if (this.uploadFile.file.size > this.bjiMaxUploadFileSize) {
        this.openErrorMsgBox(window.Common.Messages.Common.Error.bjiUploadFileMaxError);
        return;
      }

      this.$emit("upload");
      this.$refs.errorMsg.nonDisplayMessage();
      this.errors = {};
      this.loading.upload = true;
      // FormData を利用して File を POST する
      let self = this;
      let viewData = self.form;
      let formData = new FormData();
      if (this.uploadFile.file !== "") {
        formData.append("uploadFile", this.uploadFile.file);
      }
      Object.keys(self.form).forEach(function (key) {
        formData.append(key, viewData[key]);
      });

      this.$store
        .dispatch("order/uploadImportOrderFile", formData)
        .then(value => {
          self.$emit("importedFile");
        })
        .catch(error => {
          if (error.response.data["fieldErrors"]) {
            this.errors = error.response.data["fieldErrors"];
          } else {
            this.openErrorMsgBox(error.response.data.message);
          }
        })
        .finally(() => {
          self.loading.upload = false;
        });
    },
    openErrorMsgBox(message) {
      this.$refs.errorMsg.displayMessage(message);
    }
  }
};
</script>

<style lang="stylus">
.file-upload-box {
  align-items: baseline;
}
</style>
