(function (window) {
  const Common = {
    Util: {
      setErrorResponse(errorMessage) {
        window.axios.errorMessage = errorMessage;
      },
      getErrorResponseMessage() {
        return window.axios.errorMessage;
      },
      getDateTimeObject(date) {
        const now = date !== undefined ? date : new Date();

        return {
          year: now.getFullYear(),
          month: String(now.getMonth() + 1).padStart(2, "0"),
          day: String(now.getDate()).padStart(2, "0"),
          hour: String(now.getHours()).padStart(2, "0"),
          min: String(now.getMinutes()).padStart(2, "0"),
          sec: String(now.getSeconds()).padStart(2, "0")
        };
      },
      getFullDateTimeIsoFormat(date) {
        if (date.toString() === "Invalid Date") return "";
        const now = this.getDateTimeObject(date);
        return `${now.year}-${now.month}-${now.day} ${now.hour}:${now.min}:${now.sec}`;
      },
      getTodayYearMonthDayIsoFormat() {
        let date = new Date();
        date.setDate(date.getDate());
        const _date = this.getDateTimeObject(date);
        return `${_date.year}-${_date.month}-${_date.day}`;
      },
      getTomorrowYearMonthDayIsoFormat() {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        const _date = this.getDateTimeObject(date);
        return `${_date.year}-${_date.month}-${_date.day}`;
      },
      getYearMonthDayIsoFormat(date) {
        const now = this.getDateTimeObject(date);
        return `${now.year}-${now.month}-${now.day}`;
      },
      getYearMonthIsoFormat(date) {
        const now = this.getDateTimeObject(date);
        return `${now.year}-${now.month}`;
      }
    },
    Messages: {
      Common: {
        Error: {
          NetworkError: "サーバーに接続出来ませんでした。接続状態を確認してからもう一度お試しください。",
          NetworkClientError:
            "通信時にエラーが発生しました。画面をリロードした後に操作をやり直してください。",
          InternalServerError:
            "サーバー側で予期せぬエラーが発生しました。恐れ入りますが管理者へご連絡ください。",
          bjiUploadFileMaxError: "ファイルサイズが300KBを超えているためアップロード出来ません。"
        },
        Info: {
          NightLoadingMultiSelectable: "宵積み対象の配車日と乗務員を選択してください。"
        },
        Warn: {
          DataNotFound:
            "指定されたデータが見つかりませんでした。他のユーザによって削除された可能性があります。最新のデータを取得して再度ご確認ください。",
          CommonDataNotFound:
            "指定されたデータが見つかりませんでした。最新のデータを取得して再度ご確認ください。",
          ExportLimit: "件数が多いため検索条件を設定してからもう一度お試しください。",
          SearchResultNotFound: "検索条件に該当するデータが見つかりませんでした。",
          DispatchInPast: "過去日付に対して配車しようとしています",
          SubcontractingLawFlgAutoSave:
            "入力された資本金（1,000万円を基準）から、保存時に自動で下請法該当フラグを切り替えます。",
          CapitalBlankDeliveryCompany:
            "以下の1次委託会社は資本金が未入力です。通行料の適格な表示のために資本金を入力してください。",
          CapitalBlankDeliveryCompanyShort: "資本金が未入力です"
        }
      }
    }
  };

  Common.Config = {
    cmsContextPath: "/tms/",
    observer: {
      onErrorResponse401: "onErrorResponse401",
      onErrorResponse400: "onErrorResponse400",
      onErrorResponse403: "onErrorResponse403",
      onErrorResponse500: "onErrorResponse500",
      onErrorResponseNetworkError: "onErrorResponseNetworkError",
      onErrorResponse404: "onErrorResponse404",
      onCommonErrorResponse404: "onCommonErrorResponse404",
      onAxiosStart: "onAxiosStart",
      onAxiosEnd: "onAxiosEnd",
      onPagingListTableAjaxCompleted: "onPagingListTableAjaxCompleted",
      onRequireDispatchReload: "onRequireDispatchReload",
      onDispatchPatternSelectDriverReload: "onDispatchPatternSelectDriverReload",
      onRequireDispatchPatternSelectReload: "onRequireDispatchPatternSelectReload"
    }
  };

  window.Common = Common;

  window.axios = {
    response: {},
    errorMessage: ""
  };
})(window);
