<template lang="pug">
  v-dialog(v-model="controls.dialog" persistent :width="width" :content-class="contentClass" scrollable @keydown.esc="closeDialog")
    v-card.v-card-box
      v-toolbar.header(fixed dark :color="$store.getters['site/siteColor']" dense)
        v-toolbar-title {{ dialogTitle }}
        v-spacer
        v-toolbar-items
          v-btn(icon dark @click="closeDialog")
            v-icon close
      v-card-text(ref="scrollTarget").v-card-text
        alert-box(ref="errorMsg")
        slot(name="body")
        LastUpdateUserBox(:userData="updateUser" v-show="displayUpdateInfo"
          :displayHistoryButton="displayHistoryButton" @openHistoryDialog="openHistoryDialog")
      v-btn(fab dark :color="$store.getters['site/siteColor']" :class="{ 'btn-hidden': pageTopBtnStatus.isHidden }" @click="$vuetify.goTo(0, { container: $refs.scrollTarget })").to-top-btn
        v-icon(dark) keyboard_arrow_up
      v-toolbar.footer(v-if="displayFooter")
        v-btn(v-if="enableDelete" color="error" @click="deleteConfirm" :loading="loadingDelete" :disabled="disabledDelete") {{ labelDeleteButton }}
        v-spacer
        v-btn(v-if="enableSave" color="primary" @click="saveRecord" :loading="loadingSave" :disabled="disabledSave") {{ labelSaveButton }}
    DeleteConfirmDialog(ref="DeleteConfirmDialog" @yes="deleteCommit"
    , :title="deleteDialogTitle" :message="deleteDialogMessage")
</template>

<script>
import DeleteConfirmDialog from "@/components/common/dialog/DeleteConfirmDialog";
import AlertBox from "@/components/common/AlertBox";
import LastUpdateUserBox from "@/components/common/LastUpdateUserBox";
import { LimitNumber } from "@/assets/js/constants";
import _ from "lodash";

export default {
  components: { DeleteConfirmDialog, AlertBox, LastUpdateUserBox },
  props: {
    title: {
      type: String,
      required: false,
      default: () => ""
    },
    contentClass: {
      type: String,
      required: false,
      default: () => "edit-dialog"
    },
    hasTitleSuffix: {
      type: Boolean,
      default: () => true
    },
    displayFooter: {
      type: Boolean,
      default: () => true
    },
    enableSave: {
      type: Boolean,
      default: () => true
    },
    displayDeleteButton: {
      type: Boolean,
      default: () => true
    },
    labelSaveButton: {
      type: String,
      required: false,
      default: "保存"
    },
    labelDeleteButton: {
      type: String,
      required: false,
      default: "削除"
    },
    disabledSave: {
      type: Boolean,
      default: () => false
    },
    disabledDelete: {
      type: Boolean,
      default: () => false
    },
    deleteDialogTitle: {
      type: String,
      required: false,
      default: "削除確認"
    },
    deleteDialogMessage: {
      type: String,
      required: false,
      default: "削除しますか？"
    },
    width: {
      type: String,
      required: false,
      default: "50%"
    },
    loadingSave: {
      type: Boolean,
      default: () => false
    },
    loadingDelete: {
      type: Boolean,
      default: () => false
    },
    recordId: {
      type: Number,
      required: false,
      default: () => {
        return null;
      }
    },
    updateUser: {
      type: Object,
      require: false,
      default: () => {}
    },
    displayHistoryButton: {
      type: Boolean,
      default: false
    },
    displayUpdateInfo: {
      type: Boolean,
      default: () => true
    },
    scrollFire: {
      type: Function,
      default: () => Promise.resolve()
    },
    enableScrollFire: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      controls: {
        dialog: false,
        trigger: null
      },
      pageTopBtnStatus: {
        isHidden: true
      }
    };
  },
  computed: {
    dialogTitle: function () {
      return this.hasTitleSuffix ? this.title + (this.recordId !== null ? "変更" : "追加") : this.title;
    },
    enableDelete: function () {
      if (this.displayDeleteButton === false) {
        return false;
      }
      return !!this.recordId;
    }
  },
  methods: {
    openDialog() {
      this.$refs.scrollTarget.removeEventListener("scroll", this.handleScroll);
      this.$refs.scrollTarget.addEventListener("scroll", this.handleScroll);

      this.controls.dialog = true;
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    closeDialog() {
      this.$refs.errorMsg.nonDisplayMessage();
      this.controls.dialog = false;
      this.$emit("closeEditDialog");
    },
    saveRecord() {
      this.closeErrorMsgBox();
      this.$emit("save");
    },
    deleteConfirm() {
      this.$refs.DeleteConfirmDialog.openDialog();
    },
    deleteCommit() {
      this.$emit("delete");
    },
    openErrorMsgBox(message) {
      this.$refs.errorMsg.displayMessage(message);
      this.scrollToTop();
    },
    openSuccessMsgBox(message) {
      this.$refs.errorMsg.displaySuccessMessage(message);
      this.scrollToTop();
    },
    closeErrorMsgBox() {
      this.$refs.errorMsg.nonDisplayMessage();
    },
    scrollToTop() {
      this.$refs.scrollTarget.scrollTop = 0;
    },
    openHistoryDialog() {
      this.$emit("openHistoryDialog");
    },
    handleScroll() {
      if (this.$refs.scrollTarget !== undefined && this.enableScrollFire) {
        const clientHeight = this.$refs.scrollTarget.clientHeight;
        const scrollTop = this.$refs.scrollTarget.scrollTop;
        const scrollHeight = this.$refs.scrollTarget.scrollHeight;

        if (scrollHeight - clientHeight - scrollTop < LimitNumber.ScrollNumber && !this.progress) {
          this.progress = true;
          this.controls.trigger();
        }
        //ページトップボタンの表示制御
        this.pageTopBtnStatus.isHidden = scrollTop <= 200;
      }
    },
    onTrigger() {
      this.scrollFire().finally(() => {
        this.progress = false;
      });
    }
  },
  mounted() {
    this.controls.trigger = _.debounce(this.onTrigger, 150);
  }
};
</script>

<style lang="stylus" scoped>
.header {
  position: sticky;
  top: 0;
}

.footer {
  position: sticky;
  bottom: 0;
}

.v-card-box {
  position: relative;
}

.to-top-btn{
  position: absolute;
  bottom: 70px;
  right: 24px;
}

.btn-hidden {
  opacity: 0;
  pointer-events: none;
}
</style>
