<template lang="pug">
  section
    v-text-field(
      readonly
      v-model="p_label"
      label="DriverName"
      append-outer-icon="search"
      @click:append-outer="open"
      :error-messages="errorHandlerMethod(errorHandlerMethodArgComputed)")

    Dialog(ref="dialog" v-on:select:record="selectItem" :config="config")
</template>

<script>
import ApiHandler from "../../../mixin/ApiHandler";
import MasterSelectDialog from "../../../mixin/MasterSelectDialog";
import Dialog from "../SimpleMasterSelectDialog";

export default {
  components: { Dialog },
  mixins: [ApiHandler, MasterSelectDialog],
  data() {
    return {
      config: {
        title: "DriverName選択",
        headers: [
          {
            text: "DriverName",
            value: "driverName",
            align: false,
            search: ""
          }
        ],
        width: "30%",
        initDispatch: "driverName/fetchAllDriverName",
        initDispatchParam: 0,
        getStore: function () {
          return this.$store.state.driverName.driverNameList;
        }
      }
    };
  },
  props: {
    totalGrossWeight: {
      type: Number,
      default: () => null
    }
  },
  methods: {
    selectItem(data) {
      this.p_code = data.driverName;
      this.p_label = data.driverName;
    },
    open() {
      this.config.initDispatchParam = this.totalGrossWeight;
      this.config.headers.map(v => {
        v.search = "";
      });
      this.$refs.dialog.loadData();
      this.$refs.dialog.openDialog();
    }
  }
};
</script>
