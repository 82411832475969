import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import * as Enum from "@/assets/js/enum";
import { ReportExtractor } from "@/plugins/report-extractor";

import qs from "qs";

const axiosBase = new AxiosBase();
const $axios = Axios.http;

const state = {
  list: [],
  listSum: {},
  sumLoading: false,
  statusMapList: {},
  isSelectedAll: false,
  isDeselectedAll: false,
  cardId: null,
  cardUpdatedAt: null,
  originCard: {},
  billingCard: {},
  paymentCard: {},
  billingCardBasicPrice: {},
  paymentCardBasicPrice: {},
  expensesImportToken: "",
  errorOnly: false,
  expensesSse: {
    totalCount: 0,
    currentCount: 0,
    loading: false,
    loadingMessage: ""
  },
  manualOrderSelectHistoryBase: {
    clientName: null,
    clientBranchName: null,
    loadingBaseNames: [],
    unloadingBasesName: []
  },
  manualOrderHistoryRequestParam: {},
  billingHistoryList: [],
  history: {
    billingCardHistoryList: [],
    billingCardHistoryDiff: null,
    paymentCardHistoryList: [],
    paymentCardHistoryDiff: null
  },
  originCardEditableInfo: {},
  workList: [],
  noWorkImportRow: false,
  workResultList: [],
  workListAllCount: 0,
  workListErrorCount: 0,
  paging: {
    historyTablePageCount: 0,
    // 会計画面請求金額履歴
    billingHistoryTablePageCount: 0,
    // 会計画面支払金額履歴
    paymentHistorytablePageCount: 0
  }
};

const methods = {
  getCardToll: (cards, propName) => {
    if (cards === undefined) {
      return null;
    }

    const item = cards.filter(v => v.controlCd === Enum.FinanceControlCodeType.TOLL.code).pop();
    if (item && item[propName]) {
      const str = item[propName].toString();
      const newVal = str.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });

      return isNaN(parseInt(newVal)) ? null : newVal;
    } else {
      return null;
    }
  },
  getCardSummaryWithoutToll: (cards, propName, basicPrice) => {
    if (cards === undefined) {
      return null;
    }

    let sum = 0;
    let priceFlg = false;

    //手入力された基本運賃を取得
    const basic = cards.filter(v => v.controlCd === Enum.FinanceControlCodeType.BASIC_RATE.code).pop();
    if (!basic || !basic[propName]) {
      if (basicPrice && basic[propName] !== 0) {
        sum = basicPrice;
        priceFlg = true;
      }
    }

    const items = cards.filter(v => v.controlCd !== Enum.FinanceControlCodeType.TOLL.code);
    if (items) {
      items.forEach(value => {
        if (!value[propName] && value[propName] !== 0) {
          return;
        }

        const str = value[propName].toString();
        const newVal = str.replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        });
        sum = sum + (isNaN(parseInt(newVal)) ? 0 : parseInt(newVal));
        priceFlg = true;
      });
      return priceFlg ? sum : null;
    } else {
      return null;
    }
  },
  /**
   * FETCH：選択状態切替
   * @param { commit } param0 Store
   * @param { Boolean } isSelected 選択フラグ
   */
  async fetchToggleSelected({ commit }, isSelected) {
    await commit("toggleSelected", isSelected);
  }
};

/** GETTERS */
const getters = {
  /** GETTER：取得済みリスト（※スクロール状況によってデータが追加されていく） */
  getList: state => state.list,
  getListSum: state => state.listSum,
  hasListSum: state => {
    return Object.keys(state.listSum).length;
  },
  getSumCardCount: state => {
    return state.listSum.cardCount !== undefined ? state.listSum.cardCount : 0;
  },
  getSumLoading: state => state.sumLoading,
  originCard: state => state.originCard,
  billingCard: state => state.billingCard,
  paymentCard: state => state.paymentCard,
  billingCardBasicPrice: state => state.billingCardBasicPrice,
  billingSaleTaxSubtotalPrice: state => state.billingCard.saleTaxSubtotalPrice,
  billingCardBasicPriceValue: (state, getters) => {
    const basic = getters.billingCardBasicPrice;
    return basic ? basic.basicPrice : null;
  },
  paymentCardBasicPrice: state => state.paymentCardBasicPrice,
  paymentCardBasicPriceValue: (state, getters) => {
    const basic = getters.paymentCardBasicPrice;
    return basic ? basic.basicPrice : null;
  },
  billingCardDetail: state => {
    if (state.billingCard === undefined || state.billingCard === null) {
      return [];
    }
    return state.billingCard.billingCardDetails;
  },
  paymentCardDetail: state => {
    if (state.paymentCard === undefined || state.paymentCard === null) {
      return [];
    }
    return state.paymentCard.paymentCardDetails;
  },
  billingCardToll: (state, getters) => {
    return methods.getCardToll(getters.billingCardDetail, "billingItemPrice");
  },
  billingCardSummaryWithoutToll: (state, getters) => {
    return methods.getCardSummaryWithoutToll(
      getters.billingCardDetail,
      "billingItemPrice",
      getters.billingCardBasicPriceValue
    );
  },
  billingCardTotal: (state, getters) => {
    return getters.billingCardToll + getters.billingCardSummaryWithoutToll;
  },
  paymentCardToll: (state, getters) => {
    return methods.getCardToll(getters.paymentCardDetail, "paymentItemPrice");
  },
  billingStatusLabel: state => code => {
    return Enum.Utils.findLabel(code, Enum.BillingStatusType);
  },
  paymentStatusLabel: state => code => {
    return Enum.Utils.findLabel(code, Enum.PaymentStatusType);
  },
  canBillingEdit: (state, getters) => {
    const status = getters.billingCard.billingStatus;
    if (status === undefined) {
      return true;
    }

    if (
      Enum.BillingStatusType.CONFIRMED.code === status ||
      Enum.BillingStatusType.PUBLISHED.code === status
    ) {
      return false;
    }

    return true;
  },
  canBillingConfirmed: (state, getters) => {
    const status = getters.billingCard.billingStatus;
    const billingFinanceType = getters.originCard ? getters.originCard.billingFinanceType : null;
    if (status === undefined) {
      return true;
    }

    if (
      Enum.BillingStatusType.TEMPORARY_CONFIRMED.code === status &&
      Enum.FinanceType.None.code === billingFinanceType
    ) {
      return true;
    }

    return false;
  },
  canPaymentEdit: (state, getters) => {
    const status = getters.paymentCard.paymentStatus;
    if (status === undefined) {
      return true;
    }

    if (
      Enum.PaymentStatusType.CONFIRMED.code === status ||
      Enum.PaymentStatusType.PUBLISHED.code === status
    ) {
      return false;
    }

    return true;
  },
  canPaymentConfirmed: (state, getters) => {
    const status = getters.paymentCard.paymentStatus;
    const paymentFinanceType = getters.originCard ? getters.originCard.paymentFinanceType : null;
    if (status === undefined) {
      return true;
    }

    if (
      Enum.PaymentStatusType.TEMPORARY_CONFIRMED.code === status &&
      Enum.FinanceType.None.code === paymentFinanceType
    ) {
      return true;
    }

    return false;
  },
  canOriginCardEdit: (state, getters) => {
    if (!state.originCard) return true;
    const billingStatus = state.originCard.billingStatus;
    const paymentStatus = state.originCard.paymentStatus;
    if (billingStatus === undefined || paymentStatus === undefined) {
      return true;
    }
    if (
      state.originCard.billingStatus !== Enum.BillingStatusType.CONFIRMED.code &&
      state.originCard.billingStatus !== Enum.BillingStatusType.PUBLISHED.code &&
      state.originCard.paymentStatus !== Enum.PaymentStatusType.CONFIRMED.code &&
      state.originCard.paymentStatus !== Enum.PaymentStatusType.PUBLISHED.code
    ) {
      return true;
    }
    return false;
  },
  paymentCardSummaryWithoutToll: (state, getters) => {
    return methods.getCardSummaryWithoutToll(
      getters.paymentCardDetail,
      "paymentItemPrice",
      getters.paymentCardBasicPriceValue
    );
  },
  /** GETTER：全選択状態フラグ */
  getIsSelectedAll: state => state.list.length != 0 && state.list.every(element => element.selectTarget),
  getStateSelectAll: state => state.isSelectedAll,
  /** GETTER：全解除状態フラグ */
  getIsDeselectedAll: state => state.list.length == 0 || state.list.every(element => !element.selectTarget),
  getToken: state => state.expensesImportToken,
  getBillingHistoryList: state => state.billingHistoryList,
  getBillingHistoryHeader: state => {
    if (state.billingHistoryList.length > 0) {
      return state.billingHistoryList[0].billingCardDetails;
    } else {
      return [];
    }
  },
  getManualOrderSelectHistoryBase: state => state.manualOrderSelectHistoryBase,
  billingCardHistoryList: () => state.history.billingCardHistoryList,
  billingCardHistoryDiff: () => state.history.billingCardHistoryDiff,
  paymentCardHistoryList: () => state.history.paymentCardHistoryList,
  paymentCardHistoryDiff: () => state.history.paymentCardHistoryDiff,
  /**
   * 請求状態が仮確定ならTrueを返す
   * @param state
   * @returns {boolean}
   */
  isBillingCardOverride: state => {
    return state.originCardEditableInfo.billingStatus === Enum.BillingStatusType.TEMPORARY_CONFIRMED.code;
  },
  /**
   * 支払状態が仮確定ならTrueを返す
   * @param state
   * @returns {boolean}
   */
  isPaymentCardOverride: state => {
    return state.originCardEditableInfo.paymentStatus === Enum.PaymentStatusType.TEMPORARY_CONFIRMED.code;
  },
  workListAllCount: () => state.workListAllCount,
  workListErrorCount: () => state.workListErrorCount,
  noWorkImportRow: () => state.noWorkImportRow,
  errorOnly: () => state.errorOnly,
  getLoadingProgress: state => state.expensesSse.loading,
  getTotalCount: state => state.expensesSse.totalCount,
  getCurrentCount: state => state.expensesSse.currentCount,
  getLoadingMessage: state => state.expensesSse.loadingMessage
};

/** ACTIONS */
const actions = {
  /**
   * カードの請求状態と支払状態を取得する
   * @param commit
   * @param cardId
   * @returns {Promise<unknown>}
   */
  async fetchOriginCardEditable({ commit }, cardId) {
    return await $axios.get(axiosBase.getUrls().getOriginCardEditable + cardId).then(value => {
      commit("writeEditableInfo", value.data.data);
      return Promise.resolve(value.data.data);
    });
  },
  // 運行情報を取得
  async fetchDispatchCardDetailByCardIdFinance({ commit, state }, { cardId }) {
    return $axios.get(axiosBase.getUrls().getDispatchCardDetailFinance + cardId).then(value => {
      return Promise.resolve(value.data.data == null ? [] : value.data.data);
    });
  },
  /**
   * GET：請求と支払いカードデータを検索して取得
   * @param { commit } param0 Store
   * @param {*} getParams 検索条件
   */
  async fetchBillingAndPaymentList({ commit }, getParams) {
    let pageCount = getParams.pageCount;
    return await $axios
      .get(axiosBase.getUrls().getBillingAndPaymentList, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearList");
        }
        commit("writeList", value.data.data);
      });
  },
  /**
   * GET：請求と支払いカードデータを検索して取得
   * @param { commit } param0 Store
   * @param {*} getParams 検索条件
   */
  async fetchBillingAndPaymentListSum({ commit }, getParams) {
    commit("toggleSumLoading", true);
    return await $axios
      .get(axiosBase.getUrls().getBillingAndPaymentListSum, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("clearListSum");
        commit("writeListSum", value.data.data);
      })
      .finally(() => commit("toggleSumLoading", false));
  },
  async fetchBillingCardBasicPrice({ commit }, getParams) {
    return await $axios
      .get(axiosBase.getUrls().getBillingCardBasicPrice, {
        params: getParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("writeBillingCardBasicPrice", value.data.data);
      });
  },
  async clearBillingCard({ commit }) {
    await commit("clearBillingCard");
  },
  /**
   * FETCH：レポートリスト初期化
   * @param { commit } param0 Store
   */
  async fetchClearList({ commit }) {
    await commit("clearList");
  },
  /**
   * 請求カードの明細情報を取得する
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async fetchBillingCard({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getBillingCard + id).then(value => {
      const response = value.data.data;
      const responseBilling = response.billingCard;
      commit("writeOriginCard", response.card);
      commit("writeBillingCard", response.billingCard);
      commit("writeBillingCardBasicPrice", responseBilling.billingBasicPriceView);
      return Promise.resolve(value.data.data);
    });
  },
  async fetchPaymentCard({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getPaymentCard + id).then(value => {
      const response = value.data.data;
      const responsePayment = response.paymentCard;
      commit("writeOriginCard", response.card);
      commit("writePaymentCard", response.paymentCard);
      commit("writePaymentCardBasicPrice", responsePayment.paymentBasicPriceView);
      return Promise.resolve(value.data.data);
    });
  },
  async fetchBillingCardHistory({ commit, state }) {
    const mergedParams = Object.assign(state.manualOrderHistoryRequestParam, {
      pageCount: state.paging.historyTablePageCount
    });
    return await $axios
      .get(axiosBase.getUrls().getBillingCardHistory, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.historyTablePageCount === 0) {
          commit("clearBillingHistory");
        }
        commit("appendBillingHistory", value.data.data);
      })
      .catch(reason => {
        commit("clearBillingHistory");
      });
  },
  /**
   * カード請求と支払情報を取得する
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async fetchBillingAndPaymentCard({ commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getBillingAndPaymentCard + id).then(value => {
      const response = value.data.data;
      commit("writeOriginCard", response.card);
      commit("writeBillingCard", response.billingCard);
      commit("writeBillingCardBasicPrice", response.billingCard.billingBasicPriceView);
      commit("writePaymentCard", response.paymentCard);
      commit("writePaymentCardBasicPrice", response.paymentCard.paymentBasicPriceView);
      return value.data.data;
    });
  },
  /**
   * カード請求と支払情報を取得する
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  async fetchBillingAndPaymentCardAndMerge({ dispatch, commit, state }, id) {
    return await $axios.get(axiosBase.getUrls().getBillingAndPaymentCardForMerge + id).then(value => {
      const response = value.data.data;
      commit("mergeCardToList", response);
      return value.data.data;
    });
  },
  /**
   * GET：請求と支払いカードデータを検索して取得
   * @param { commit } param0 Store
   * @param {*} getParams 検索条件
   */
  async fetchCheckBulk({ dispatch, state, getters, commit }, searchCondition) {
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingAndPayment(element));
    const condition = {
      searchCondition: searchCondition,
      list: targetList,
      selectAll: getters.getIsSelectedAll
    };

    const payLoad = {
      data: condition,
      uri: axiosBase.getUrls().getOriginCardEditableBulk,
      sendGet: true
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        var data = value == null ? [] : JSON.parse(value);
        commit("writeEditableInfo", data);

        return Promise.resolve(data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * GET：請求と支払カードデータを発行単位で検索して状態確認
   * @param { commit } param0 Store
   * @param {*} getParams 検索条件
   */
  async fetchCheckBulkStatus({ dispatch, state, getters, commit }, searchCondition) {
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingAndPayment(element));
    const condition = {
      searchCondition: searchCondition,
      list: targetList,
      selectAll: getters.getIsSelectedAll
    };
    const payLoad = {
      data: condition,
      uri: axiosBase.getUrls().getOriginCardEditableBulkStatus,
      sendGet: true
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        var data = value == null ? [] : JSON.parse(value);
        commit("writeEditableInfo", data);
        return Promise.resolve(data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * 請求書単位でカードの請求状態を取得する
   * @param commit
   * @param cardId
   * @returns {Promise<unknown>}
   */
  async fetchCheckBillingStatusByKey({ commit }, cardId) {
    return await $axios.get(axiosBase.getUrls().getCheckBillingStatusByKey + cardId).then(value => {
      commit("writeEditableInfo", value.data.data);
      return Promise.resolve(value.data.data);
    });
  },
  /**
   * 支払書単位でカードの支払状態を取得する
   * @param commit
   * @param cardId
   * @returns {Promise<unknown>}
   */
  async fetchCheckPaymentStatusByKey({ commit }, cardId) {
    return await $axios.get(axiosBase.getUrls().getCheckPaymentStatusByKey + cardId).then(value => {
      commit("writeEditableInfo", value.data.data);
      return Promise.resolve(value.data.data);
    });
  },
  async postBillingCard({ commit, state, dispatch }) {
    return await $axios
      .post(axiosBase.getUrls().postBillingCard, {
        billingCard: state.billingCard,
        paymentCard: null,
        originCard: state.originCard
      })
      .then(value => {
        dispatch("fetchBillingCard", state.originCard.id);
        return Promise.resolve(value.data);
      });
  },
  async postPaymentCard({ commit, state, dispatch }) {
    return await $axios
      .post(axiosBase.getUrls().postPaymentCard, {
        billingCard: null,
        paymentCard: state.paymentCard,
        originCard: state.originCard
      })
      .then(value => {
        dispatch("fetchPaymentCard", state.originCard.id);
        return Promise.resolve(value.data);
      });
  },
  async postBillingCardStatus({ commit, getters, state }, destStatus) {
    return await $axios
      .post(axiosBase.getUrls().postBillingCardStatusEdit, {
        cardId: getters.originCard.id,
        status: destStatus,
        updatedAt: getters.originCard.updatedAt
      })
      .then(value => {
        const response = value.data.data;
        commit("writeOriginCard", response.card);
        commit("writeBillingCardStatus", response.card);
      });
  },
  async postPaymentCardStatus({ commit, getters, state }, destStatus) {
    return await $axios
      .post(axiosBase.getUrls().postPaymentCardStatusEdit, {
        cardId: getters.originCard.id,
        status: destStatus,
        updatedAt: getters.originCard.updatedAt
      })
      .then(value => {
        const response = value.data.data;
        commit("writeOriginCard", response.card);
        commit("writePaymentCardStatus", response.card);
      });
  },
  /**
   * POST：請求なしデータの確定処理
   * @param {*} param0 Store
   */
  async postBillingConfirmed({ commit, getters }) {
    return await $axios.post(axiosBase.getUrls().postBillingConfirmed + getters.originCard.id).then(value => {
      const response = value.data.data;
      commit("writeOriginCard", response.card);
      commit("writeBillingCardStatus", response.card);
    });
  },
  /**
   * POST：支払なしデータの確定処理
   * @param {*} param0 Store
   */
  async postPaymentConfirmed({ commit, getters }) {
    return await $axios.post(axiosBase.getUrls().postPaymentConfirmed + getters.originCard.id).then(value => {
      const response = value.data.data;
      commit("writeOriginCard", response.card);
      commit("writePaymentCardStatus", response.card);
    });
  },
  /**
   * POST：請求バルク更新
   * @param {*} param0 Store
   * @param {*} data 更新情報
   */
  async postBillingBulkEdit({ dispatch, getters, state }, { searchCondition, ...updateCond }) {
    // 取得：登録対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingAndPayment(element));
    let billingCard = state.billingCard;
    billingCard.billingTargetYearMonth = updateCond.billingTargetYearMonth;
    billingCard.billingTypeId = updateCond.billingTypeId;
    billingCard.billingVehicleTypeId = updateCond.vehicleTypeId;
    const condition = JSON.stringify({
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll,
      billingCard: billingCard
    });

    const payLoad = {
      data: condition,
      uri: axiosBase.getUrls().postBillingBulkEdit,
      headers: {
        "Content-Type": "application/json"
      }
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        return Promise.resolve(value == null ? [] : value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：支払バルク更新
   * @param {*} param0 Store
   * @param {*} data 更新情報
   */
  async postPaymentBulkEdit({ dispatch, getters, state }, { searchCondition, ...updateCond }) {
    // 取得：登録対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingAndPayment(element));
    let paymentCard = state.paymentCard;
    paymentCard.paymentTargetYearMonth = updateCond.paymentTargetYearMonth;
    paymentCard.paymentTypeId = updateCond.paymentTypeId;
    paymentCard.paymentVehicleTypeId = updateCond.vehicleTypeId;
    const condition = JSON.stringify({
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll,
      paymentCard: paymentCard
    });

    const payLoad = {
      data: condition,
      uri: axiosBase.getUrls().postPaymentBulkEdit,
      headers: {
        "Content-Type": "application/json"
      }
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        return Promise.resolve(value == null ? [] : value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：請求状態バルク更新
   * @param {*} param0 Store
   * @param {*} data 更新情報
   */
  async postBillingStatusBulkEdit({ dispatch, getters, state }, { searchCondition, ...updateCond }) {
    // 取得：登録対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingAndPayment(element));

    let billingCard = state.billingCard;
    billingCard.billingStatus = updateCond.billingStatus;

    const condition = JSON.stringify({
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll,
      billingCard: billingCard
    });

    const payLoad = {
      data: condition,
      uri: axiosBase.getUrls().postBillingStatusBulkEdit,
      headers: {
        "Content-Type": "application/json"
      }
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        return Promise.resolve(value == null ? [] : value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：支払状態バルク更新
   * @param {*} param0 Store
   * @param {*} data 更新情報
   */
  async postPaymentStatusBulkEdit({ dispatch, getters, state }, { searchCondition, ...updateCond }) {
    // 取得：登録対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingAndPayment(element));
    let paymentCard = state.paymentCard;
    paymentCard.paymentStatus = updateCond.paymentStatus;

    const condition = JSON.stringify({
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll,
      paymentCard: paymentCard
    });

    const payLoad = {
      data: condition,
      uri: axiosBase.getUrls().postPaymentStatusBulkEdit,
      headers: {
        "Content-Type": "application/json"
      }
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        return Promise.resolve(value == null ? [] : value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },

  /**
   * POST：対象請求カード再計算
   * @param {*} param0 Store
   * @param {*} data 更新情報
   */
  async postBillingBulkCalc({ dispatch, getters, state }, searchCondition) {
    // 取得：登録対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingAndPayment(element));
    const condition = JSON.stringify({
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    });

    const payLoad = {
      data: condition,
      uri: axiosBase.getUrls().postBillingBulkCalc,
      headers: {
        "Content-Type": "application/json"
      }
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        return Promise.resolve(value == null ? [] : JSON.parse(value).body);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：対象支払カード再計算
   * @param {*} param0 Store
   * @param {*} data 更新情報
   */
  async postPaymentBulkCalc({ dispatch, getters, state }, searchCondition) {
    // 取得：登録対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingAndPayment(element));
    const condition = JSON.stringify({
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    });

    const payLoad = {
      data: condition,
      uri: axiosBase.getUrls().postPaymentBulkCalc,
      headers: {
        "Content-Type": "application/json"
      }
    };

    return dispatch("indicator/indicate", payLoad, { root: true })
      .then(value => {
        return Promise.resolve(value == null ? [] : JSON.parse(value).body);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * FETCH：選択状態切替
   * @param { commit } param0 Store
   * @param { Boolean } isSelected 選択フラグ
   */
  async fetchToggleSelected({ commit }, isSelected) {
    await commit("toggleSelected", isSelected);
  },
  /**
   * 一覧用リストと状態（アコーディオンの開閉トグルやチェックボックスの選択状態）を一括クリア
   *
   * @param commit
   * @returns {Promise<void>}
   */
  async clearFetchList({ commit }) {
    await commit("clearList");
  },
  async uploadValidation({ commit, state }, data) {
    return $axios
      .post(axiosBase.getUrls().expensesImportWorkValidation, { token: state.expensesImportToken })
      .then(() => {
        return Promise.resolve();
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async fetchAllExpensesImportWork({ commit, state }, getParams) {
    if (state.expensesImportToken === "") {
      return null;
    }
    let pageCount = getParams.pageCount;
    const setParams = {
      token: state.expensesImportToken,
      pageCount: getParams.pageCount,
      errorOnly: state.errorOnly
    };
    return $axios
      .get(axiosBase.getUrls().expensesImportWorkList, {
        params: setParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        commit("clearWorkResultList");
        if (pageCount === 0) {
          commit("clearWorkList");
        }
        commit("writeWorkList", value.data.data);
        if (state.workList.length < 1) {
          commit("writeWorkImportRow", true);
        } else {
          commit("writeWorkImportRow", false);
        }
        return Promise.resolve(value.data.data);
      })
      .catch(reason => {
        commit("writeExpensesImportWorkToken", "");
        return Promise.reject(reason);
      });
  },
  async writeToken({ commit, state }, token) {
    commit("writeExpensesImportWorkToken", token);
  },
  async fetchExpensesImportWorkCount({ commit, state }) {
    if (state.expensesImportToken === "") {
      return null;
    }
    return $axios
      .get(axiosBase.getUrls().expensesImportWorkListCount, {
        params: { ...{ token: state.expensesImportToken } }
      })
      .then(value => {
        commit("writeWorkCount", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async commitImportRequest({ dispatch, commit, state }, keyList) {
    const payLoad = {
      data: JSON.stringify({
        cardIdList: keyList,
        token: state.expensesImportToken
      }),
      headers: {
        "Content-Type": "application/json"
      },
      uri: axiosBase.getUrls().expensesImportFileCommit
    };

    dispatch("indicator/indicate", payLoad, { root: true }).then(data => {
      commit("clearWorkList");
      commit("writeWorkResultList", JSON.parse(data));
      commit("writeExpensesImportWorkToken", "");
    });
  },
  /**
   * 特定カードIDの請求金額履歴を一括で取得する
   *
   * @param commit
   * @param state
   * @param tariffId
   * @returns {Promise<*[] | *>}
   */
  async fetchBillingHistoryList({ commit, state }, cardId) {
    let getParams = {};
    const mergedParams = Object.assign(getParams, {
      pageCount: state.paging.billingHistoryTablePageCount
    });
    return $axios
      .get(axiosBase.getUrls().getAllBillingCardHistory + cardId, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.billingHistoryTablePageCount === 0) {
          commit("clearBillingCardHistoryList");
        }
        commit("appendBillingCardHistoryList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * 請求カード履歴IDを元にそれとその直前請求カードの履歴差分データを取得する
   * @param commit
   * @param state
   * @param billingCardHistoryId
   * @returns {Promise<*[] | *>}
   */
  async fetchBillingDiff({ commit, state }, billingCardHistoryId) {
    return $axios.get(axiosBase.getUrls().getBillingCardHistoryDiff + billingCardHistoryId).then(value => {
      let data = value.data.data == null ? [] : value.data.data;
      commit("saveBillingCardHistoryDiff", data);
      return Promise.resolve(data);
    });
  },
  /**
   * 履歴表示ダイアログ用のデータをクリア
   * @param commit
   * @returns {Promise<void>}
   */
  async clearBillingHistoryData({ commit }) {
    commit("clearBillingCardHistoryList");
    commit("clearBillingCardHistoryDiff");
  },
  /**
   * 特定カードIDの支払金額履歴を一括で取得する
   *
   * @param commit
   * @param state
   * @param tariffId
   * @returns {Promise<*[] | *>}
   */
  async fetchPaymentHistoryList({ commit, state }, cardId) {
    let getParams = {};
    const mergedParams = Object.assign(getParams, {
      pageCount: state.paging.paymentHistoryTablePageCount
    });
    return $axios
      .get(axiosBase.getUrls().getAllPaymentCardHistory + cardId, {
        params: mergedParams,
        paramsSerializer: params => {
          return qs.stringify(params);
        }
      })
      .then(value => {
        if (state.paging.paymentHistoryTablePageCount === 0) {
          commit("clearPaymentCardHistoryList");
        }
        commit("appendPaymentCardHistoryList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * 支払カード履歴IDを元にそれとその直前支払カードの履歴差分データを取得する
   * @param commit
   * @param state
   * @param paymentCardHistoryId
   * @returns {Promise<*[] | *>}
   */
  async fetchPaymentDiff({ commit, state }, paymentCardHistoryId) {
    return $axios.get(axiosBase.getUrls().getPaymentCardHistoryDiff + paymentCardHistoryId).then(value => {
      let data = value.data.data == null ? [] : value.data.data;
      commit("savePaymentCardHistoryDiff", data);
      return Promise.resolve(data);
    });
  },
  /**
   * 履歴表示ダイアログ用のデータをクリア
   * @param commit
   * @returns {Promise<void>}
   */
  async clearPaymentHistoryData({ commit }) {
    commit("clearPaymentCardHistoryList");
    commit("clearPaymentCardHistoryDiff");
  },
  /**
   * FETCH：ワークリスト初期化
   * @param { commit } param0 Store
   */
  async fetchClearWorkList({ commit }) {
    await commit("clearWorkAndResult");
    await commit("clearWorkCount");
  },
  async toggleExpensesImportErrorOnly({ commit }, toggle) {
    commit("writeExpensesImportWorkErrorOnly", toggle);
  },
  async mergeBillingCardVehicleTypeTrailer({ commit }, toggle) {
    commit("mergeBillingCardVehicleTypeTrailer", toggle);
  }
};

const mutations = {
  clearList(state) {
    state.list = [];
  },
  /**
   * COMMIT：リスト追加
   * @param {*} state Store
   * @param {*} data 追加データ
   */
  writeList(state, data) {
    if (data != null) {
      data.forEach(rec => {
        if (state.isSelectedAll) {
          rec.selectTarget = true;
        }
      });
      state.list = state.list.concat(data);
    }
  },
  writeOriginCard(state, card) {
    state.originCard = card;
  },
  writeCardId(state, cardId) {
    state.cardId = cardId;
  },
  writeCardUpdatedAt(state, cardUpdatedAt) {
    state.cardUpdatedAt = cardUpdatedAt;
  },
  writeBillingCard(state, data) {
    state.billingCard = data;
  },
  writePaymentCard(state, data) {
    state.paymentCard = data;
  },
  writeBillingCardBasicPrice(state, data) {
    if (data === null) {
      state.billingCardBasicPrice = {};
      return;
    }

    state.billingCardBasicPrice = data;

    if (
      state.billingCard.billingCardDetails === undefined ||
      !data ||
      data.multiDeliveryPrice === undefined
    ) {
      return;
    }

    const multi = state.billingCard.billingCardDetails.filter(data => data.controlCd === "MULTI_DELI").pop();

    if (multi.billingItemPrice === undefined || multi.billingItemPrice || multi.billingItemPrice === 0) {
      return;
    }

    multi.billingItemPrice = data.multiDeliveryPrice;
  },
  writePaymentCardBasicPrice(state, data) {
    if (data === null) {
      state.paymentCardBasicPrice = {};
      return;
    }
    state.paymentCardBasicPrice = data;
  },
  writeExpensesImportWorkToken(state, token) {
    state.expensesImportToken = token;
  },
  writeExpensesImportWorkErrorOnly(state, toggle) {
    state.errorOnly = toggle;
  },
  writeBillingCardStatus(state, originCard) {
    if (state.billingCard) {
      state.billingCard.billingStatus = originCard.billingStatus;
    }
  },
  writePaymentCardStatus(state, originCard) {
    if (state.paymentCard) {
      state.paymentCard.paymentStatus = originCard.paymentStatus;
    }
  },
  writeWorkList(state, data) {
    if (data != null) {
      state.workList = state.workList.concat(data.list);
    }
  },
  writeWorkImportRow(state, data) {
    state.noWorkImportRow = data;
  },
  writeWorkResultList(state, data) {
    if (data != null) {
      state.workResultList = data;
    }
  },
  clearWorkList(state) {
    state.workList = [];
  },
  clearWorkCount(state) {
    state.workListAllCount = 0;
    state.workListErrorCount = 0;
  },
  clearWorkResultList(state) {
    state.workResultList = [];
  },
  clearWorkAndResult(state) {
    state.workList = [];
    state.workResultList = [];
  },
  clearBillingCard(state) {
    state.billingCard = {};
    state.billingCardBasicPrice = null;
  },
  incrementHistoryTablePageCount(state) {
    state.paging.historyTablePageCount++;
  },
  clearHistoryTablePageCount(state) {
    state.paging.historyTablePageCount = 0;
  },
  incrementBillingHistoryTablePageCount(state) {
    state.paging.billingHistoryTablePageCount++;
  },
  clearBillingHistoryTablePageCount(state) {
    state.paging.billingHistoryTablePageCount = 0;
  },
  incrementPaymentHistoryTablePageCount(state) {
    state.paging.paymentHistoryTablePageCount++;
  },
  clearPaymentHistoryTablePageCount(state) {
    state.paging.paymentHistoryTablePageCount = 0;
  },
  writeWorkCount(state, data) {
    state.workListAllCount = data.allCount;
    state.workListErrorCount = data.errorCount;
  },
  clearListSum(state) {
    state.listSum = [];
  },
  toggleSumLoading(state, loading) {
    state.sumLoading = loading;
  },
  /**
   * COMMIT：リスト追加
   * @param {*} state Store
   * @param {*} data 追加データ
   */
  writeListSum(state, data) {
    if (data != null) {
      state.listSum = data;
    }
  },
  /**
   * COMMIT：取得済みリスト 選択状態全変更
   * @param { Store } state Store
   * @param { Boolean } isSelected true:全選択 false:全解除
   */
  toggleSelected(state, isSelected) {
    state.isSelectedAll = isSelected;
    const toggleList = state.list.map(element => {
      return { ...element, ...{ selectTarget: isSelected } };
    });
    state.list = toggleList;
  },
  writeManualOrderSelectHistoryBase(state, data) {
    state.manualOrderSelectHistoryBase = data;
  },
  writeManualOrderHistoryRequestParam(state, data) {
    state.manualOrderHistoryRequestParam = data;
  },
  appendBillingHistory(state, data) {
    if (data != null) {
      state.billingHistoryList = state.billingHistoryList.concat(data);
    }
  },
  clearBillingHistory(state) {
    state.billingHistoryList = [];
  },
  appendBillingCardHistoryList(state, data) {
    if (data != null) {
      state.history.billingCardHistoryList = state.history.billingCardHistoryList.concat(data);
    }
  },
  clearBillingCardHistoryList(state) {
    state.history.billingCardHistoryList = [];
  },
  saveBillingCardHistoryDiff(state, data) {
    state.history.billingCardHistoryDiff = data;
  },
  clearBillingCardHistoryDiff(state) {
    state.history.billingCardHistoryDiff = null;
  },
  appendPaymentCardHistoryList(state, data) {
    if (data != null) {
      state.history.paymentCardHistoryList = state.history.paymentCardHistoryList.concat(data);
    }
  },
  clearPaymentCardHistoryList(state) {
    state.history.paymentCardHistoryList = [];
  },
  savePaymentCardHistoryDiff(state, data) {
    state.history.paymentCardHistoryDiff = data;
  },
  clearPaymentCardHistoryDiff(state) {
    state.history.paymentCardHistoryDiff = null;
  },
  writeEditableInfo(state, data) {
    state.originCardEditableInfo = data;
  },
  mergeBillingDetailPrice(state, historyDetailData) {
    if (state.billingCard && state.billingCard.billingCardDetails) {
      state.billingCard.billingCardDetails.forEach(v => {
        const item = historyDetailData.filter(h => h.billingItemId === v.billingItemId).pop();
        if (item) {
          v.billingItemPrice = item.billingItemPrice;
        }
      });
    }
  },
  writeCurrentCount(state, data) {
    state.expensesSse.currentCount = data;
  },
  writeTotalCount(state, data) {
    state.expensesSse.totalCount = data;
  },
  changeLoadingProgress(state, flag) {
    state.expensesSse.loading = flag;
  },
  setLoadingMessage(state, data) {
    state.expensesSse.loadingMessage = data;
  },
  clearDownloadData(state) {
    state.expensesSse.totalCount = 0;
    state.expensesSse.currentCount = 0;
    state.expensesSse.loadingMessage = "";
  },
  /**
   * 会計一覧データリストへ新しく取得してきた乗務員のカードをマージする
   */
  mergeCardToList(state, card) {
    let targetIndex = null;
    state.list
      .filter((value, index) => {
        if (value.cardId === card.cardId) {
          targetIndex = index;
        }
        return value.cardId === card.id;
      })
      .pop();
    if (targetIndex !== null) {
      Object.assign(state.list[targetIndex], card);
      Object.assign(state.list[targetIndex].billingCard, card.billingCard);
      Object.assign(state.list[targetIndex].paymentCard, card.paymentCard);
    }
  },
  mergeBillingCardVehicleTypeTrailer(state, cardVehicleTypeTrailer) {
    state.billingCard.cardVehicleTypeTrailer = cardVehicleTypeTrailer;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
