<template lang="pug">
  SearchConditions(ref="SearchConditions" :initSearchCond="initSearchCond" @onChangeSearchConditions="onChangeSearchConditions")
    template(v-slot:conditions="{ conditions }")
      alert-box(ref="errorMsg")
      v-layout(wrap)
        v-flex(md2)
          DatePicker(pickerLabel="配車日" :initDate="conditions.scheduledLoadingDate" :inputDate.sync="conditions.scheduledLoadingDate" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="積込日" initDate="" :inputDate.sync="conditions.shipmentDate" :isClearable="true")
        v-flex(md2)
          DatePicker(pickerLabel="納品日" initDate="" :inputDate.sync="conditions.deliveryDate" :isClearable="true")
        v-flex(md1)
          v-text-field(label="荷主" v-model="conditions.clientName" maxlength=100)
        v-flex(md1)
          v-text-field(label="荷主枝番" v-model="conditions.clientBranchName" maxlength=100)
        v-flex(md1)
          ReportOutputStatusTypeSelect(:code.sync="conditions.reportStatus" :clearable="true")
        v-flex(md2)
          v-layout(column)
            v-flex(md6)
              FaxSendStatusSelect(:code.sync="conditions.faxSendStatus" :clearable="true")
            v-flex(md6)
              v-checkbox.mt-0(v-model="conditions.faxTarget" label="FAX送付対象のみ表示" hide-details)
        v-flex(md1)
          StatusSelect(:code.sync="conditions.status" :clearable="true")
</template>

<script>
import SearchConditions from "@/components/common/search/SearchConditions";
import DatePicker from "@/components/common/DatePicker";
import ReportOutputStatusTypeSelect from "@/components/common/select/ReportOutputStatusTypeSelect";
import StatusSelect from "@/components/common/select/StatusSelect";
import FaxSendStatusSelect from "@/components/common/select/FaxSendStatusSelect";
import AlertBox from "@/components/common/AlertBox";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    SearchConditions,
    DatePicker,
    ReportOutputStatusTypeSelect,
    StatusSelect,
    FaxSendStatusSelect,
    AlertBox
  },
  methods: {
    onChangeSearchConditions(item) {
      this.nonDisplayMessage();
      this.$emit("onChangeSearchConditions", item);
    },
    /**
     * アラートボックス表示
     */
    displayMessage(errorMsg) {
      this.$refs.errorMsg.displayMessage(errorMsg);
    },
    /**
     * アラートボックス非表示
     */
    nonDisplayMessage() {
      this.$refs.errorMsg.nonDisplayMessage();
    },
    initSearchCond() {
      return {
        conditions: {
          clientName: "",
          clientBranchName: "",
          scheduledLoadingDate: window.Common.Util.getTomorrowYearMonthDayIsoFormat(),
          shipmentDate: "",
          deliveryDate: "",
          reportStatus: "",
          status: Enum.ActiveFlag.Active.code,
          faxTarget: true,
          faxSendStatus: null
        }
      };
    },
    submit() {
      this.$refs.SearchConditions.submit();
    }
  }
};
</script>
