import AxiosBase from "@/assets/js/axios-base";
import Axios from "@/assets/js/axios";
import { ReportExtractor } from "@/plugins/report-extractor";

import qs from "qs";

const axiosBase = new AxiosBase();
const $axios = Axios.http;
const $download = Axios.download;

// const $download = Axios.download;
import * as Enum from "@/assets/js/enum";

const methods = {
  getBillingDetailKey: (clientId, clientBranchId, billingTargetYearMonth) => {
    let key = "";
    if (clientId) {
      key = key + String(clientId) + "_";
    }
    if (clientBranchId) {
      key = key + String(clientBranchId) + "_";
    } else {
      key = key + String(0) + "_";
    }
    if (billingTargetYearMonth) {
      key = key + String(billingTargetYearMonth);
    }
    return key;
  },
  getPaymentDetailKey: (primaryDeliveryCompanyId, paymentTargetYearMonth) => {
    let key = "";
    if (primaryDeliveryCompanyId) {
      key = key + String(primaryDeliveryCompanyId);
    }
    if (paymentTargetYearMonth) {
      key = key + String(paymentTargetYearMonth);
    }
    return key;
  },
  getPaymentPromptDetailKey: primaryDeliveryCompanyId => {
    let key = "";
    if (primaryDeliveryCompanyId) {
      key = key + String(primaryDeliveryCompanyId);
    }
    return key;
  }
};

/** STATE */
const state = {
  list: [],
  detailMapList: {},
  detailList: [],
  statusMapList: {},
  isSelectedAll: false,
  isDeselectedAll: false
};

/** GETTERS */
const getters = {
  /** GETTER：取得済みリスト（※スクロール状況によってデータが追加されていく） */
  getList: state => state.list,
  getBillingDetailList: state => (clientId, clientBranchId, billingTargetYearMonth) => {
    const key = methods.getBillingDetailKey(clientId, clientBranchId, billingTargetYearMonth);
    return state.detailMapList[key];
  },
  getPaymentDetailList: state => (primaryDeliveryCompanyId, paymentTargetYearMonth) => {
    const key = methods.getPaymentDetailKey(primaryDeliveryCompanyId, paymentTargetYearMonth);
    return state.detailMapList[key];
  },
  getPaymentPromptDetailList: state => primaryDeliveryCompanyId => {
    const key = methods.getPaymentPromptDetailKey(primaryDeliveryCompanyId);
    return state.detailMapList[key];
  },
  /** GETTER：全選択状態フラグ */
  getIsSelectedAll: state => state.list.length != 0 && state.list.every(element => element.selectTarget),
  getStateSelectAll: state => state.isSelectedAll,
  /** GETTER：全解除状態フラグ */
  getIsDeselectedAll: state => state.list.length == 0 || state.list.every(element => !element.selectTarget),

  getBillingOutputUnitTypeLabel: state => cd => {
    return Enum.Utils.findLabel(cd, Enum.BillingOutputUnitType);
  },
  getIsDeselectedPeriod: (state, getters) => {
    if (getters.getIsDeselectedAll) return false;
    var filteredList = state.list.filter(
      record => record.selectTarget && !record.faxSendPeriod && !record.arrivePeriod
    );
    return filteredList.length !== 0;
  }
};

/** ACTIONS */
const actions = {
  async fetchBillingList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getBillingStatementList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearList");
          commit("clearDetailAll");
        }
        commit("appendList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchBillingDetailList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getBillingStatementDetailList, {
        params: getParams
      })
      .then(value => {
        const data = {
          data: value.data.data,
          clientId: getParams.clientId,
          clientBranchId: getParams.clientBranchId,
          billingTargetYearMonth: getParams.billingTargetYearMonth
        };

        if (pageCount === 0) {
          commit("clearDetailList", data);
        }

        commit("appendDetailList", data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * Download：請求書PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadBillingStatement({ state, getters, dispatch }, searchCondition) {
    // 取得：出力対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingStatement(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    };
    const payload = {
      data: condition,
      uri: axiosBase.getUrls().getBillingStatementDownload,
      sendGet: true
    };
    await dispatch("indicator/indicate", payload, { root: true })
      .then(value => {
        return this.dispatch("statement/downloadFileBillingStatement", value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * Download：請求書PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadFileBillingStatement({ state, getters, dispatch }, filePath) {
    return await $download.get(axiosBase.getUrls().getBillingStatementDownloadFile + filePath);
  },
  /**
   * download：請求書FAX
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async sendBillingStatement({ state, getters, dispatch }, searchCondition) {
    // 取得：出力対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingStatement(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    };
    const payload = {
      data: condition,
      uri: axiosBase.getUrls().getBillingStatementSend,
      sendGet: true
    };
    await dispatch("indicator/indicate", payload, { root: true })
      .then(value => {
        return this.dispatch("statement/downloadFileBillingStatement", value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async fetchPaymentList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getPaymentStatementList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearList");
          commit("clearDetailAll");
        }
        commit("appendList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchPaymentDetailList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getPaymentStatementDetailList, {
        params: getParams
      })
      .then(value => {
        const data = {
          data: value.data.data,
          primaryDeliveryCompanyId: getParams.primaryDeliveryCompanyId,
          paymentTargetYearMonth: getParams.paymentTargetYearMonth
        };

        if (pageCount === 0) {
          commit("clearPaymentDetailList", data);
        }

        commit("appendPaymentDetailList", data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchPaymentPromptList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getPaymentPromptList, {
        params: getParams
      })
      .then(value => {
        if (pageCount === 0) {
          commit("clearList");
          commit("clearDetailAll");
        }
        commit("appendList", value.data.data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  async fetchPaymentPromptDetailList({ commit, state }, getParams) {
    let pageCount = getParams.pageCount;
    return $axios
      .get(axiosBase.getUrls().getPaymentPromptDetailList, {
        params: getParams
      })
      .then(value => {
        const data = {
          data: value.data.data,
          primaryDeliveryCompanyId: getParams.primaryDeliveryCompanyId
        };

        if (pageCount === 0) {
          commit("clearPaymentDetailList", data);
        }

        commit("appendPaymentDetailList", data);
        return Promise.resolve(value.data.data == null ? [] : value.data.data);
      });
  },
  /**
   * Download：支払書PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadPaymentStatement({ state, getters, dispatch }, searchCondition) {
    // 取得：出力対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatPaymentStatement(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    };
    const payload = {
      data: condition,
      uri: axiosBase.getUrls().getPaymentStatementDownload,
      sendGet: true
    };
    await dispatch("indicator/indicate", payload, { root: true })
      .then(value => {
        return this.dispatch("statement/downloadFilePaymentStatement", value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * Download：支払書PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadFilePaymentStatement({ state, getters, dispatch }, filePath) {
    return await $download.get(axiosBase.getUrls().getPaymentStatementDownloadFile + filePath);
  },
  /**
   * Download：支払書FAX
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async sendPaymentStatement({ state, getters, dispatch }, searchCondition) {
    // 取得：出力対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatPaymentStatement(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    };
    const payload = {
      data: condition,
      uri: axiosBase.getUrls().getPaymentStatementSend,
      sendGet: true
    };
    await dispatch("indicator/indicate", payload, { root: true })
      .then(value => {
        return this.dispatch("statement/downloadFilePaymentStatement", value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * Download：支払書速報PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadPaymentPrompt({ state, getters, dispatch }, searchCondition) {
    // 取得：出力対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatPaymentPrompt(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    };
    const payload = {
      data: condition,
      uri: axiosBase.getUrls().getPaymentPromptDownload,
      sendGet: true
    };
    await dispatch("indicator/indicate", payload, { root: true })
      .then(value => {
        return this.dispatch("statement/downloadFilePaymentPrompt", value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * Download：支払書PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async downloadFilePaymentPrompt({ state, getters, dispatch }, filePath) {
    return await $download.get(axiosBase.getUrls().getPaymentPromptDownloadFile + filePath);
  },
  /**
   * Download：支払書速報PDF
   * @param { state, dispatch } param0 Store
   * @param { Object } searchCondition 抽出条件
   */
  async sendPaymentPrompt({ state, getters, dispatch }, searchCondition) {
    // 取得：出力対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatPaymentPrompt(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    };
    const payload = {
      data: condition,
      uri: axiosBase.getUrls().getPaymentPromptSend,
      sendGet: true
    };
    await dispatch("indicator/indicate", payload, { root: true })
      .then(value => {
        return this.dispatch("statement/downloadFilePaymentPrompt", value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：支払書の送信期限、必着期限の登録を行う
   * @param {*} param0 Store
   * @param {*} data 更新情報
   */
  async postPaymentStatementEditMeta({ commit }, data) {
    await $axios
      .post(
        axiosBase.getUrls().postPaymentStatementEditMeta,
        ReportExtractor.formatPaymentStatementMeta(data)
      )
      .then(value => {
        const commitCondition = {
          searchFunction: ReportExtractor.searchPaymentStatement,
          mappingFunction: ReportExtractor.formatPaymentMeta
        };
        commit("mergeList", { response: value.data.data, commitCondition });
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * POST：対象支払書リストの送信期限、必着期限の登録を行う
   * @param {*} param0 Store
   * @param {*} data 更新情報
   */
  async postPaymentStatementBulkEdit({ state, getters, commit }, { searchCondition, ...updateCondition }) {
    // 取得：登録対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatPaymentStatement(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll,
      ...updateCondition
    };
    await $axios
      .post(axiosBase.getUrls().postPaymentStatementBulkEdit, condition)
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  async bulkSetBillingStatementStatus({ state, getters, commit }, searchCondition) {
    // 取得：登録対象
    let targetList = state.list
      .filter(item => item.selectTarget)
      .map(element => ReportExtractor.formatBillingStatement(element));
    const condition = {
      list: targetList,
      searchCondition: searchCondition,
      selectAll: getters.getIsSelectedAll
    };
    await $axios
      .get(axiosBase.getUrls().getBillingStatementBulkStatus, {
        params: condition,
        paramsSerializer: params => {
          return qs.stringify(params, { allowDots: true });
        }
      })
      .then(value => {
        return Promise.resolve(value);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  /**
   * FETCH：レポートリスト初期化
   * @param { commit } param0 Store
   */
  async fetchClearList({ commit }) {
    await commit("clearList");
  },
  /**
   * FETCH：選択状態切替
   * @param { commit } param0 Store
   * @param { Boolean } isSelected 選択フラグ
   */
  async fetchToggleSelected({ commit }, isSelected) {
    await commit("toggleSelected", isSelected);
  },
  /**
   * 一覧用リストと状態（アコーディオンの開閉トグルやチェックボックスの選択状態）を一括クリア
   *
   * @param commit
   * @returns {Promise<void>}
   */
  async clearFetchList({ commit }) {
    await commit("clearList");
    await commit("clearDetailAll");
    await commit("clearBackUpStatus");
  }
};

/** MUTATIONS */
const mutations = {
  /**
   * COMMIT：リスト初期化
   * @param {*} state Store
   */
  clearList(state) {
    state.list = [];
  },
  appendList(state, data) {
    if (data != null) {
      data.forEach(rec => {
        rec.isDisplayCell = false;
        if (state.isSelectedAll) {
          rec.selectTarget = true;
        }
      });

      state.list = state.list.concat(data);
    }
  },
  clearDetailList(state, data) {
    const key = methods.getBillingDetailKey(data.clientId, data.clientBranchId, data.billingTargetYearMonth);
    state.detailMapList[key] = [];
  },
  clearPaymentDetailList(state, data) {
    const key = methods.getPaymentDetailKey(data.primaryDeliveryCompanyId, data.paymentTargetYearMonth);
    state.detailMapList[key] = [];
  },
  clearDetailAll(state) {
    state.detailMapList = [];
  },
  appendDetailList(state, data) {
    if (data.data != null) {
      const key = methods.getBillingDetailKey(
        data.clientId,
        data.clientBranchId,
        data.billingTargetYearMonth
      );

      let branchId = -1;

      if (state.detailMapList[key] && state.detailMapList[key].length > 0) {
        branchId = state.detailMapList[key][state.detailMapList[key].length - 1].clientBranchId;
      }

      data.data.forEach((rec, index) => {
        if (rec.statementDetailSeparated) {
          if (branchId !== rec.clientBranchId) {
            rec.breakPage = true;
            branchId = rec.clientBranchId;
          }
        } else if (state.detailMapList[key].length === 0 && index === 0) {
          rec.breakPage = true;
          return;
        }
      });

      state.detailMapList[key] = state.detailMapList[key]
        ? state.detailMapList[key].concat(data.data)
        : [].concat(data.data);

      // 変更検知させるため再代入
      state.detailMapList = { ...state.detailMapList };
    }
  },
  appendPaymentDetailList(state, data) {
    if (data.data != null) {
      const key = methods.getPaymentDetailKey(data.primaryDeliveryCompanyId, data.paymentTargetYearMonth);
      let branchId = -1;

      if (state.detailMapList[key] && state.detailMapList[key].length > 0) {
        branchId = state.detailMapList[key][state.detailMapList[key].length - 1].clientBranchId;
      }

      for (const rec of data.data) {
        if (branchId !== rec.clientBranchId) {
          rec.breakPage = true;
          branchId = rec.clientBranchId;
        }
      }
      state.detailMapList[key] = state.detailMapList[key]
        ? state.detailMapList[key].concat(data.data)
        : [].concat(data.data);
      // 変更検知させるため再代入
      state.detailMapList = { ...state.detailMapList };
    }
  },
  appendPaymentPromptDetailList(state, data) {
    if (data.data != null) {
      const key = methods.getPaymentPromptDetailKey(data.primaryDeliveryCompanyId);
      let branchId = -1;

      if (state.detailMapList[key] && state.detailMapList[key].length > 0) {
        branchId = state.detailMapList[key][state.detailMapList[key].length - 1].clientBranchId;
      }

      for (const rec of data.data) {
        if (branchId !== rec.clientBranchId) {
          rec.breakPage = true;
          branchId = rec.clientBranchId;
        }
      }
      state.detailMapList[key] = state.detailMapList[key]
        ? state.detailMapList[key].concat(data.data)
        : [].concat(data.data);
      // 変更検知させるため再代入
      state.detailMapList = { ...state.detailMapList };
    }
  },
  /**
   * アコーディオンの開閉トグルやチェックボックスの選択状態をバックアップしたものをクリア
   * @param state
   */
  clearBackUpStatus() {
    state.statusMapList = {};
  },
  /**
   * COMMIT：リスト追加
   * @param {*} state Store
   * @param {*} data 追加データ
   */
  writeList(state, data) {
    if (data != null) {
      data.list.forEach(rec => {
        if (state.statusMapList[rec.uniqueKey] !== undefined) {
          rec.isDisplayCell = state.statusMapList[rec.uniqueKey].isDisplayCell;
          rec.selectTarget = state.statusMapList[rec.uniqueKey].selectTarget;
        }
      });
      state.list = state.list.concat(data.list);
    }
  },
  mergeList(
    state,
    {
      response,
      commitCondition = {
        searchFunction: ReportExtractor.searchSerial,
        mappingFunction: ReportExtractor.deleteSelectTarget
      }
    }
  ) {
    state.list = ReportExtractor.mergeList(
      state.list,
      "list" in response ? response.list : response,
      commitCondition
    );
  },
  /**
   * COMMIT：取得済みリスト 選択状態全変更
   * @param { Store } state Store
   * @param { Boolean } isSelected true:全選択 false:全解除
   */
  toggleSelected(state, isSelected) {
    state.isSelectedAll = isSelected;
    const toggleList = state.list.map(element => {
      return { ...element, ...{ selectTarget: isSelected } };
    });
    state.list = toggleList;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
