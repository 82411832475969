<template lang="pug">
  EditDialog(ref="Self" title="配車パターン" :recordId="record.id" @delete="erase" @save="save" width="90%"
    delete-dialog-title="配車パターン削除確認"
    :loadingSave="loading.save" :loadingDelete="loading.delete")
    template(v-slot:body)
      v-container(fluid grid-list-md )
        v-layout(row wrap)
          v-flex(xs12)
            v-alert(type="error" :value="true" v-show="getErrorMessages('cards')") {{getErrorMessages('cards')}}
        v-layout(row wrap)
          v-flex(xs12)
            v-btn(@click="openCardEditDialog" color="primary") カード追加
        v-layout(row wrap)
          v-flex(xs12)
            section.pattern-card-list-holder
              template(v-for="(card,index) in record.cards" )
                div.separate-allow(v-if="index !== 0")
                  v-icon() arrow_forward_ios
                PatternCard(:card="card" editable @activate="onCardActivated" :index="index"
                  @dragStart="dragStart"
                  @drop="drop"
                ).card
        v-layout(row wrap)
          v-flex(xs1)
            v-text-field(v-model="record.priority" label="優先度" :error-messages="getErrorMessages('priority')").text-align-right
          v-flex(xs2)
            StatusSelect(:code.sync="record.status" :errorHandlerMethod="getErrorMessages")
        v-layout(row wrap)
          v-flex(xs12)
            v-textarea(label="備考" rows=1  auto-grow v-model="record.remark" :error-messages="getErrorMessages('remark')")

        section
          DispatchPatternCardEdit(ref="cardEditDialog"
            :postUrl="$config.urls.postEditDispatchPattern" :deleteUrl="$config.urls.deleteDispatchPattern"
            @saveCard="saveCard" @deleteCard="deleteCard"
          )
</template>

<script>
import EditDialog from "@/components/common/dialog/EditDialog";
import PatternCard from "@/components/dispatch-pattern/PatternCard.vue";
import ApiHandler from "@/mixin/ApiHandler";
import EditForm from "@/mixin/EditForm";

import DispatchPatternCardEdit from "@/components/management/dispatch-pattern/DispatchPatternCardEdit";
import StatusSelect from "@/components/common/select/StatusSelect.vue";
import * as Enum from "@/assets/js/enum";

export default {
  components: {
    StatusSelect,
    EditDialog,
    PatternCard,
    DispatchPatternCardEdit
  },
  mixins: [ApiHandler, EditForm],
  data() {
    return {
      record: {
        id: null,
        priority: 1,
        remark: null,
        cards: []
      },
      draggingCard: {},
      openedCard: null
    };
  },
  methods: {
    initValues() {
      return {
        id: null,
        priority: 1,
        remark: null,
        status: Enum.ActiveFlag.Active.code,
        cards: []
      };
    },
    openCardEditDialog(item) {
      this.$refs.cardEditDialog.openDialog(item);
    },
    openDialog(item) {
      this.initForm(item === undefined ? this.initValues() : item);
      this.$refs.Self.openDialog();
    },
    closeDialog() {
      this.$refs.Self.closeDialog();
    },
    save() {
      const self = this;
      this.saveRecord().then(() => {
        self.closeDialog();
      });
    },
    erase() {
      const self = this;
      this.deleteRecord().then(() => {
        self.closeDialog();
      });
    },
    /**
     * カードを追加または編集して保存ボタン押下した際のコールバック
     * @param card
     */
    saveCard(card) {
      //新規追加したカード
      if (card.id === undefined || card.id === null) {
        if (this.openedCard === null) {
          this.record.cards.push(card);
        }
      } else {
        const idx = this.record.cards.findIndex(v => v.id === card.id);
        if (idx >= 0) {
          this.record.cards.splice(idx, 1, card);
        }
      }
      this.openedCard = null;
    },
    deleteCard(card) {
      const idx = this.record.cards.findIndex(v => v.id === card.id);
      if (idx >= 0) {
        this.record.cards.splice(idx, 1);
      }
    },
    /**
     * カードのダブルクリック
     * @param card
     */
    onCardActivated(card) {
      this.openedCard = card;
      this.openCardEditDialog(card);
    },
    dragStart(card, event) {
      this.draggingCard = card;
    },
    drop(arg, event) {
      const fromIndex = this.draggingCard.index;
      const toIndex = arg.index;

      this.swapList(this.record.cards, fromIndex, toIndex);
    },
    swapList(array, p1, p2) {
      array[p1] = array.splice(p2, 1, array[p1])[0];
    }
  }
};
</script>

<style scoped lang="stylus">
.card-holder
  position relative
  display flex
</style>
