export default {
  methods: {
    calcShiftDay(baseDate) {
      const shiftDayList = [];
      const currentDate = new Date(baseDate);
      for (let i = 0; i < 7; i++) {
        const shiftDay = {
          text:
            currentDate.getMonth() +
            1 +
            "/" +
            currentDate.getDate() +
            "(" +
            "日月火水木金土"[currentDate.getDay()] +
            ")",
          holiday: currentDate.getDay() === 0,
          date: window.Common.Util.getYearMonthDayIsoFormat(currentDate)
        };
        shiftDayList.push(shiftDay);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return shiftDayList;
    }
  }
};
