<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout
        v-flex(md12)
          v-card
            v-card-text
              ExportOrderAndCardFilterConditions(ref="ExportOrderAndCardFilterConditions" @onChangeSearchConditions="onChangeSearchConditions" @onChangeSearchConditionsTriggerBefore="onChangeSearchConditionsTriggerBefore")
            v-card-text
              v-layout
                v-flex(xs12 md2)
                  span.count-text 該当カード数：{{ getCsvSearchCountText() }}

              v-layout.file-export-box(row wrap)
                v-flex(xs12 md2)
                  ExportTemplateSelect(:code.sync="exportTemplateId" :clearable="true" :errorHandlerMethod="getErrorMessages")
                v-flex(xs12 md2)
                  v-btn(@click="exportConfirm" color="info" :loading="getLoadingProgress") {{ exportType.serviceContact.labelName }}
                v-flex(v-if="getLoadingProgress")
                  v-progress-circular.progress-bar(:value="getProgressPercent" :rotate="-90" size="64" width=8 color="teal") {{getProgressPercent}} %
                  span.progress-text {{getLoadingMessage}}  ({{getCurrentCount}} / {{getTotalCount}})
              ExportConfirmDialog(ref="ExportConfirmDialog" :message="confirmMessage" @yes="doExport")
</template>

<script>
import ExportOrderAndCardFilterConditions from "@/components/export/ExportOrderAndCardFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";
import EventDispatcher from "@/mixin/EventDispatcher";
import ExportHandler from "@/mixin/ExportHandler";
import ExportOrderAndCardListTable from "@/components/export/ExportOrderAndCardListTable";
import ExportTemplateSelect from "@/components/common/select/ExportTemplateSelect";
import ApiHandler from "@/mixin/ApiHandler";
import { mapGetters } from "vuex";

export default {
  components: {
    ExportOrderAndCardFilterConditions,
    ExportConfirmDialog,
    ExportOrderAndCardListTable,
    ExportTemplateSelect
  },
  mixins: [EventDispatcher, ExportHandler, ApiHandler],
  methods: {
    /**
     * Event：検索条件 変更
     */
    onChangeSearchConditions(searchCondition) {
      // ExportHandler条件設定
      this.searchCond = searchCondition;
      this.$store
        .dispatch("exportCsv/downloadSlipDetailCsvCount", this.searchCond)
        .catch(error => {
          // alertBox表示
          this.$refs.ExportOrderAndCardFilterConditions.displayMessage(error.response.data.message);
        })
        .finally(() => {
          //一覧の検索やクリアボタンローディング制御の為にイベント発行。
          this.dispatchCommonEvent(window.Common.Config.observer.onPagingListTableAjaxCompleted);
        });
    },
    onChangeSearchConditionsTriggerBefore(searchCondition) {
      this.searchCond = searchCondition;
      this.$store.dispatch("exportCsv/resetCsvCount");
    },
    /**
     * Event：出力ダイアログの表示
     * ※PDFダウンロード
     */
    exportConfirm() {
      if (this.isError || this.isDeselectedAll) return;
      if (!this.exportTemplateId) {
        this.$refs.ExportOrderAndCardFilterConditions.displayMessage("テンプレートが未選択です。");
        return;
      }
      // 出力時にテンプレートidを検索条件に設定
      this.searchCond.exportTemplateId = this.exportTemplateId;
      this.$refs.ExportConfirmDialog.openDialog();
    },
    doExport() {
      this.loading.export = true;
      this.$store.dispatch("exportCsv/downloadSlipDetailCsv", this.searchCond).catch(error => {
        // alertBox表示
        this.$refs.ExportOrderAndCardFilterConditions.displayMessage(error.response.data.message);
      });
    },
    getCsvSearchCountText() {
      if (this.getCsvSearchCount === 0) {
        return "";
      } else {
        return this.getCsvSearchCount;
      }
    }
  },
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      getLoadingProgress: "exportCsv/getLoadingProgress",
      getTotalCount: "exportCsv/getTotalCount",
      getCurrentCount: "exportCsv/getCurrentCount",
      getCsvFileSize: "exportCsv/getCsvFileSize",
      getLoadingMessage: "exportCsv/getLoadingMessage",
      getCsvSearchCount: "exportCsv/getCsvSearchCount"
    }),
    getProgressPercent() {
      if (this.getTotalCount < 1) {
        return 0;
      }
      return Math.floor((this.getCurrentCount / this.getTotalCount) * 100);
    }
  },
  data() {
    return {
      // 検索エラーフラグ
      isError: false,
      confirmMessage: "現在の選択内容を出力しますか？",
      // ExportHandler処理用Store URL
      exportType: {
        serviceContact: {
          labelName: "CSVダウンロード",
          dispatchUrl: "exportCsv/downloadSlipDetailCsv"
        }
      },
      // テンプレート選択が位置的にFilterConditionsの外になるので、別で保持
      exportTemplateId: "",
      serverMessage: null,
      totalCount: 0,
      currentCount: 0,
      csvFileSize: 0
    };
  },
  mounted() {
    // 初期ロード時に初期検索情報にて結果を表示
    this.$refs.ExportOrderAndCardFilterConditions.submit();
  },
  destroyed() {
    if (this.serverMessage != null) {
      this.serverMessage.close();
    }
  }
};
</script>

<style lang="stylus" scoped>
.file-export-box {
  align-items: baseline;
}

.progress-div {
  display: inline-block;
}

.progress-text {
  margin-left: 16px;
  display: inline-block;
}

.count-text {
  font-size: 18px;
}
</style>
