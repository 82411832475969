<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      ImportShipmentNoForm(@imported="showResult")
      ImportShipmentNoResult(ref="ImportResult")
</template>

<script>
import ImportShipmentNoForm from "@/components/import/ImportShipmentNoForm";
import ImportShipmentNoResult from "@/components/import/ImportShipmentNoResult";
export default {
  components: {
    ImportShipmentNoForm,
    ImportShipmentNoResult
  },
  data: function () {
    return {};
  },
  methods: {
    showResult(data) {
      this.$refs.ImportResult.loadData(data);
    }
  }
};
</script>
