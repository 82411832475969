<template lang="pug">
  section
    StatementMetaApplyTargetDialog(ref="StatementMetaApplyTargetDialog" actionName="" @onSave="onClickYes")
    SelectAllButton(ref="SelectAllButton" @checkAll="onSelectAll()" @unCheckAll="onDeselectAll()")
    v-btn(v-show="isShow" flat icon @click="onClickOpenApplyTargetDialog" :disabled="isDeselectedAll || isDisabled")
      v-icon(color="green") fact_check
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectAllButton from "@/components/report/SelectAllButton";
import StatementMetaApplyTargetDialog from "@/components/report/StatementMetaApplyTargetDialog";

export default {
  components: {
    SelectAllButton,
    StatementMetaApplyTargetDialog
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: true
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({}),
  computed: {
    /** Getter：Vuex */
    ...mapGetters({
      isDeselectedAll: "statement/getIsDeselectedAll",
      isSelectedAll: "statement/getIsSelectedAll",
      isStateSelectAll: "statement/getStateSelectAll"
    })
  },
  methods: {
    /** Action：Vuex */
    ...mapActions("statement", {
      fetchToggleSelected: "fetchToggleSelected"
    }),
    /**
     * Event：一括更新 確認ダイアログ（Yes）
     */
    onClickYes(detail) {
      this.$emit("onClickEvent", detail);
    },
    /**
     * Event：全選択
     */
    onSelectAll() {
      this.fetchToggleSelected(true);
    },
    /**
     * Event：全解除
     */
    onDeselectAll() {
      this.fetchToggleSelected(false);
    },
    /**
     * 選択対象に対して一括設定するダイアログを開く
     */
    onClickOpenApplyTargetDialog() {
      this.$refs.StatementMetaApplyTargetDialog.openDialog();
    },
    onIndeterminate() {
      if (this.isStateSelectAll) {
        this.$refs.SelectAllButton.onIndeterminate(!this.isSelectedAll);
      }
    },
    resetSelectAll() {
      this.$refs.SelectAllButton.unCheckAll();
    }
  }
};
</script>
