<script src="../../../../stores/base-distance.js"></script>
<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openAddDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力
                  v-btn(color="info" to="/tms/management/payment-tariff-rule/import") インポート

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      PaymentTariffRuleFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              PaymentTariffRuleTable(ref="PaymentTariffRuleTable" :prop-header="paymentTariffRuleTableHeader" :prop-data="paymentTariffRuleList" @open-detail="openDetail" detail-url="paymentTariffRule/fetchPaymentTariffRule" :getListActionName="getListActionName" displaySlotBody)
               template(v-slot:body="{body}")
                td {{ body.primaryDeliveryCompany.nameAndCd }}
                td {{ body.paymentTariffType.name }}
                td {{ body.paymentTariff.tariffCd }}
                td {{ body.paymentTariff.tariffName }}
                td {{ body.applicableStartDate }}
                td(width="150px")
                  v-tooltip(top)
                    template(v-slot:activator="{ on }")
                      p(v-on="on").remark.whitespace-pre {{ body.remark }}
                    span.whitespace-pre {{ body.remark }}

    PaymentTariffRuleEditDialog(ref="PaymentTariffRuleEditDialog" :postUrl="$config.urls.postEditPaymentTariffRule" :deleteUrl="$config.urls.deletePaymentTariffRule" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('paymentTariffRule/export')")
</template>

<script>
import PaymentTariffRuleEditDialog from "@/components/management/payment-tariff-rule/PaymentTariffRuleEdit";
import PaymentTariffRuleTable from "@/components/common/PagingListTable";
import PaymentTariffRuleFilterConditions from "@/components/management/payment-tariff-rule/PaymentTariffRuleFilterConditions";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    PaymentTariffRuleEditDialog,
    PaymentTariffRuleTable,
    PaymentTariffRuleFilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  computed: {
    getListActionName: {
      get() {
        return "paymentTariffRule/fetchAllPaymentTariffRule";
      }
    },
    paymentTariffRuleList: {
      get() {
        return this.$store.state.paymentTariffRule.paymentTariffRuleList;
      }
    },
    paymentTariffRuleTableHeader: {
      get() {
        return [
          { text: "1次配送委託先", value: "primaryDeliveryCompany.companyName", centerAlign: "" },
          { text: "支払タリフ区分", value: "paymentTariffType.name", centerAlign: "" },
          { text: "支払タリフCD", value: "paymentTariff.tariffCd", centerAlign: "" },
          { text: "支払タリフ名", value: "paymentTariff.tariffName", centerAlign: "" },
          { text: "適用開始日", value: "applicableStartDate", centerAlign: true },
          { text: "備考", value: "remark", centerAlign: "" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  },
  methods: {
    openAddDialog() {
      this.$refs.PaymentTariffRuleEditDialog.openDialog();
    },
    openDetail(item) {
      this.$refs.PaymentTariffRuleEditDialog.openDialog(item);
    },
    loadListData() {
      this.$refs.PaymentTariffRuleTable.loadData();
    },
    itemUpdated(response, data) {
      this.$refs.PaymentTariffRuleTable.resetPageCount();
      this.loadListData();
    },
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.PaymentTariffRuleTable.setSearchConditions(searchCond);
      this.$refs.PaymentTariffRuleTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 出力ダイアログの表示
     */
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    }
  }
};
</script>
