<template lang="pug">
  div.icon-wrapper.both
    v-tooltip(top)
      template(v-slot:activator="{ on }")
        //v-icon(v-on="on") fas fa-yen-sign
        v-icon(v-on="on" @mouseenter="triggerOpenYenTip") fas fa-money-bill
      span.whitespace-pre {{ Math.floor(landing / 1000) }}

</template>

<script>
export default {
  data() {
    return {
      landing: null
    };
  },
  methods: {
    triggerOpenYenTip(event) {
      event.preventDefault();
      this.$emit("triggerOpenYenTip", event);
    },
    openTrigger(args) {
      this.fetchData(args.item.driverId);
    },
    async fetchData(driverId) {
      await this.$store.dispatch("dispatch/fetchDispatchLanding", driverId).then(value => {
        this.landing = value;
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.icon-wrapper{
  $iconSize = 22px
  width $iconSize
  height $iconSize

  border-radius 50%
  text-align center
  line-height $iconSize -1 px

  &.none{
    background-color #9E9E9E
    .v-icon{
      color #FAFAFA
    }
  }

  &.reach{
    background-color #FF9800
    .v-icon{
      color #FFF8E1
    }
  }

  &.counter{
    background-color #03A9F4
    .v-icon{
      color #E1F5FE
    }
  }

  &.both{
    background-color #4CAF50
    .v-icon{
      color #E8F5E9
    }
  }

  &.un-managed{
    background-color #9E9E9E
    .v-icon{
      color #FAFAFA
    }
  }

  .v-icon{
    font-size 13px
  }
}
</style>
