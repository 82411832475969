<template lang="pug">
  v-content
    v-container(fluid grid-list-md)
      v-layout(row wrap)
        v-flex(xs12)
          v-card
            v-card-title
              v-layout(row wrap)
                v-flex(xs12)
                  v-btn(@click="openEditDialog" color="primary") 新規追加
                  v-btn(@click="exportConfirm" color="success" :loading="loading.export") 出力

            v-card-text
              v-layout(row wrap)
                v-flex(xs12)
                  v-card
                    v-card-text
                      DeliveryCompanyFilterConditions(@onChangeSearchConditions="onChangeSearchConditions")

            v-card-text
              DeliveryCompanyTable(ref="DeliveryCompanyTable" :prop-header="deliveryCompanyTableHeader" :prop-data="deliveryCompanyList" @open-detail="openDetail",
                 :getListActionName="getListActionName" detailUrl="deliveryCompany/fetchDeliveryCompany"
                 :displaySlotBody="true")
                  template(v-slot:body="{ body }")
                    td.text-xs-left {{ body.companyCd }}
                    td.text-xs-left {{ body.companyName }}
                    td.text-xs-left {{ body.headOfficeAddress }}
                    td.text-xs-left.whitespace-pre {{ getText(body.transportTel) }}<br/>{{ getText(body.paymentTel) }}
                    td.text-xs-left.whitespace-pre {{ getText(body.transportFax) }}<br/>{{ getText(body.paymentFax) }}
                    td.text-xs-left {{ getFaxTarget(body.transportFaxTarget) }}
                    td.text-xs-left {{ getFaxTarget(body.paymentStatementFaxTarget) }}
                    td
                      v-tooltip(top)
                        template(v-slot:activator="{ on }")
                          p(v-on="on").remark.whitespace-pre {{ body.remark }}
                        span.whitespace-pre {{ body.remark }}

    DeliveryCompanyEditDialog(ref="DeliveryCompanyEditDialog" :postUrl="$config.urls.postEditDeliveryCompany" :deleteUrl="$config.urls.deleteDeliveryCompany" @updated="itemUpdated" @deleted="itemUpdated")
    ExportConfirmDialog(ref="ExportConfirmDialog" @yes="exportData('deliveryCompany/export')")
</template>

<script>
import { mapGetters } from "vuex";
import DeliveryCompanyEditDialog from "@/components/management/delivery-company/DeliveryCompanyEdit";
import DeliveryCompanyTable from "@/components/common/PagingListTable";
import DeliveryCompanyFilterConditions from "@/components/management/delivery-company/DeliveryCompanyFilterConditions.vue";
import ExportConfirmDialog from "@/components/common/dialog/ExportConfirmDialog";

import ExportHandler from "@/mixin/ExportHandler";

export default {
  components: {
    DeliveryCompanyEditDialog,
    DeliveryCompanyTable,
    DeliveryCompanyFilterConditions,
    ExportConfirmDialog
  },
  mixins: [ExportHandler],
  data() {
    return {};
  },
  methods: {
    /**
     * ダイアログの表示
     */
    openEditDialog() {
      this.$refs.DeliveryCompanyEditDialog.openDialog();
    },
    /**
     * 詳細ダイアログの表示
     * @param item
     */
    openDetail(item) {
      this.$refs.DeliveryCompanyEditDialog.openDialog(item);
    },
    /**
     * データの読み込み
     * @param params
     */
    loadListData() {
      this.$refs.DeliveryCompanyTable.loadData();
    },
    itemUpdated(response, data) {
      this.$refs.DeliveryCompanyTable.resetPageCount();
      this.loadListData();
    },
    /**
     * 検索条件欄に入力されたら発火
     * @param searchCond
     */
    onChangeSearchConditions(searchCond) {
      this.searchCond = searchCond;
      this.$refs.DeliveryCompanyTable.setSearchConditions(searchCond);
      this.$refs.DeliveryCompanyTable.resetPageCount();
      this.loadListData();
    },
    exportConfirm() {
      this.$refs.ExportConfirmDialog.openDialog();
    },
    // 改行して上下詰めないよう設定
    getText(item) {
      return item ? item : " ";
    }
  },
  computed: {
    ...mapGetters({
      getStatus: "site/statusFromCd",
      contractSigned: "site/contractSignedFromCd",
      getFaxTarget: "site/faxTargetLabel"
    }),
    deliveryCompanyList: {
      get() {
        return this.$store.state.deliveryCompany.deliveryCompanyList;
      }
    },
    getListActionName: {
      get() {
        return "deliveryCompany/fetchAllDeliveryCompany";
      }
    },
    deliveryCompanyTableHeader: {
      get() {
        return [
          { text: "配送委託先会社コード" },
          { text: "配送委託先会社名" },
          { text: "住所" },
          { text: "電話番号(輸送依頼書)\r\n電話番号(支払)" },
          { text: "FAX(輸送依頼書)\r\nFAX(支払)" },
          { text: "輸送依頼書FAX送信対象" },
          { text: "支払書FAX送信対象" },
          { text: "備考" }
        ];
      }
    }
  },
  mounted() {
    this.loadListData();
  }
};
</script>
<style lang="stylus" scoped>
.remark{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin 8px 0
  max-width: 200px;
}
</style>
